import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from 'react';
import styled, { css } from 'styled-components';
import { Button, message } from 'antd';
import TextArea from 'rc-textarea';
import useTranslation from '../../../../translations';
import { formatDateTime } from '../../../../utils';
import CommentUserInfo from './CommentUserInfo';
import { IComment } from './commentPlugin';
import CommentActionButtons from './CommentActionButtons';

type EditComment = {
  text: string;
};

interface Props {
  comment: IComment;
  active: boolean;
  isOwnedByUser: boolean;
  onUpdateNote: (commentId: string, value: string) => void;
  onDeleteNote: (commentId: string) => void;
}

const CommentItem = forwardRef<HTMLDivElement, Props>(
  ({ comment, isOwnedByUser, onUpdateNote, onDeleteNote, active }, ref) => {
    const { id, text, fullName, modifiedOn, createdOn, resolved } = comment;

    const t = useTranslation();
    const inputRef = useRef<TextArea>(null);
    const hasStaredEditing = useRef<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const [userComment, setUserComment] = useState<EditComment>({
      text,
    });

    const handleEditRequest = () => {
      setIsEditing(true);
      const outerRef = ref as React.MutableRefObject<HTMLDivElement | null>;
      outerRef?.current?.scrollIntoView();
    };

    const stopEdit = () => setIsEditing(false);

    const handleCommentChange = useCallback(
      (value: Partial<EditComment>) =>
        setUserComment((prev) => ({ ...prev, ...value })),
      []
    );

    useEffect(() => {
      if (!isEditing) {
        handleCommentChange({
          text: comment.text,
        });
      }
    }, [comment, isEditing, handleCommentChange]);

    useEffect(() => {
      if (!isEditing) {
        hasStaredEditing.current = false;
        return;
      }
      if (!hasStaredEditing.current) {
        hasStaredEditing.current = true;
        const textLength = userComment.text.length;
        inputRef.current?.resizableTextArea.textArea.setSelectionRange(
          textLength,
          textLength
        );
        inputRef.current?.focus();
      }
    }, [isEditing, userComment.text]);

    const handleDeleteRequest = () => {
      onDeleteNote(id);
    };

    const handleSaveRequest = () => {
      onUpdateNote(id, userComment.text);
      stopEdit();
    };

    const handleEditCancel = () => {
      handleCommentChange({
        text,
      });
      stopEdit();
    };

    const handleCopyToClipboard = async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(text);
        message.success(t('COPIED_TO_CLIPBOARD'));
      } catch {
        message.error(t('FAILED_TO_COPY_CLIPBOARD'));
      }
    };

    const handleMouseEnter = (e: React.MouseEvent) => {
      e.stopPropagation();
      setHover(true);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
      e.stopPropagation();
      setHover(false);
    };

    return (
      <Outer
        ref={ref}
        active={active}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InfoRow>
          <CommentUserInfo fullName={fullName} />
          <CommentActionButtons
            isOwnedByUser={isOwnedByUser}
            active={hover}
            onCopyToClipboard={handleCopyToClipboard}
            onDeleteRequest={handleDeleteRequest}
            onEditRequest={handleEditRequest}
          />
        </InfoRow>
        <TextAreaWrapper edit={isEditing}>
          <StyledTextArea
            autoSize
            ref={inputRef}
            edit={isEditing}
            disabled={!isEditing}
            value={isEditing ? userComment.text : text}
            onChange={(e) => handleCommentChange({ text: e.target.value })}
          />
          <EditPanel hide={!isEditing}>
            <EditButtons>
              <CancelButton type="default" onClick={handleEditCancel}>
                {t('ACTION.CANCEL')}
              </CancelButton>
              <SaveButton
                type="primary"
                disabled={userComment.text.trim() === ''}
                onClick={handleSaveRequest}
              >
                {t('ACTION.SAVE')}
              </SaveButton>
            </EditButtons>
          </EditPanel>
        </TextAreaWrapper>
        <Flex>
          <DateTime>{formatDateTime(modifiedOn || createdOn)}</DateTime>
          {modifiedOn && (
            <>
              <Divider />
              <EditIndicator>{t('ACTION.EDITED')}</EditIndicator>
            </>
          )}
          {resolved && (
            <>
              <Divider />
              <ResolveIndicator>{t('ACTION.RESOLVED')}</ResolveIndicator>
            </>
          )}
        </Flex>
      </Outer>
    );
  }
);

export default CommentItem;

const Outer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: ${(props) => props.theme.colors.black05};
  position: relative;
  border-radius: 8px;
  margin: 8px;
  transition: margin 0.3s ease-in-out;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .ant-input:hover {
    border: unset;
    outline: none;
  }
  .ant-input[disabled] {
    background-color: unset;
    border-color: unset;
  }
  ${(props) =>
    props.active &&
    css`
      margin: 0px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `};
`;

const InfoRow = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextArea = styled(TextArea)<{ edit: boolean }>`
  background: transparent;
  border: unset;
  outline: none;
  cursor: pointer;
  min-height: 35px;
  color: ${(props) => props.theme.colors.charcoal100};
  :hover,
  :focus,
  :focus-within {
    background: transparent;
    border: unset;
    outline: none;
    color: ${(props) => props.theme.colors.charcoal100};
  }
  ${(props) => props.edit && `margin-top: 5px; padding: 13px 12px 12px 20px;`}
`;

const EditPanel = styled.div<{ hide: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 18px 12px 12px 20px;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  gap: 8px;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 10px;
  .ant-btn-primary[disabled] {
    border: 2px solid transparent;
    color: ${(props) => props.theme.colors.white87};
    background-color: ${(props) => props.theme.colors.primary100};
  }
`;

const TextAreaWrapper = styled.div<{ edit: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid transparent;
  margin: 16px 0 8px -3px;
  ${(props) =>
    props.edit &&
    `border-color: ${props.theme.colors.blue600};background-color:${props.theme.colors.primary200_20}`}
`;

const DateTime = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.colors.black40};
`;

const Divider = styled.div`
  &::before {
    content: '';
    display: flex;
    height: 4px;
    width: 4px;
    background-color: ${(props) => props.theme.colors.black40};
    border-radius: 50%;
  }
`;

const EditIndicator = styled.div`
  color: ${(props) => props.theme.colors.black40};
  font-size: 11px;
`;

const ResolveIndicator = styled.div`
  color: ${(props) => props.theme.colors.success};
  font-size: 11px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CancelButton = styled(Button)`
  border-radius: 5px;
  border: 2px solid ${(props) => props.theme.colors.primary100};
  color: ${(props) => props.theme.colors.primary100};
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primary100};
    color: ${(props) => props.theme.colors.primary100};
  }
`;

export const SaveButton = styled(Button)`
  border-radius: 5px;
  border: 2px solid transparent;
  color: ${(props) => props.theme.colors.white87};
  background-color: ${(props) => props.theme.colors.primary100};
  &:hover,
  &:focus {
    border: 2px solid transparent;
    color: ${(props) => props.theme.colors.white87};
    background-color: ${(props) => props.theme.colors.primary100};
  }
`;
