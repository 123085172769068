import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';
import { SpinSize } from '../../types';
import useTranslation from '../../translations';

// Render a general purpose notification (by default positioned slightly above the center of containing element)

interface PropsBase {
  error?: boolean;
  className?: string;
  style?: React.CSSProperties;
  spinSize?: SpinSize;
  testId?: string;
  center?: boolean;
}

interface PropsWithChildren extends PropsBase {
  children?: React.ReactNode;
  text?: never;
  spin?: never;
}

interface PropsWithoutChildren extends PropsBase {
  children?: never;
  text?: string;
  spin?: boolean;
}

type Props = PropsWithChildren | PropsWithoutChildren;

const Notification: React.FC<Props> = ({
  children,
  text,
  spin,
  error,
  className,
  style,
  spinSize = 'default',
  testId,
  center,
}) => {
  const t = useTranslation();
  let content = children;

  switch (true) {
    case error:
      content = t('SOMETHING_WENT_WRONG');
      break;
    case text != null && spin != null:
      content = (
        <>
          <Spinner size={spinSize} />
          {text}
        </>
      );
      break;
    case text != null:
      content = text;
      break;
    case spin:
      content = <Spinner size={spinSize} />;
      break;
  }

  return (
    <Outer className={className} style={style} data-testid={testId}>
      <Inner isCenter={center}>{content}</Inner>
    </Outer>
  );
};

export default Notification;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 40px;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Inner = styled.div<{ isCenter?: boolean }>`
  ${(props) => !props.isCenter && 'position: absolute; top: 38%;'}
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
`;
