import React from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { Typography, Space, Button } from 'antd';
import { Components } from '@ipos/shared';
import { useTsdElement, useTsdNode } from 'queries';
import {
  TsdElementUsagePopup,
  TsdElementGraphics,
  TsdElementAttributes,
  TsdElementDetails,
  TsdElementContext,
} from 'components/TsdElement';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';

const { Title } = Typography;
const { LabelValue, Notification, Icon } = Components;

const TsdElementNode: React.FC = () => {
  const t = useTranslation();
  const { tsdId, tsdNodeId } = useParams<{
    tsdId: string;
    tsdNodeId: string;
  }>();
  const tsdNodeQuery = useTsdNode(tsdId, tsdNodeId);
  const tsdElementId = tsdNodeQuery.data?.tsdElementId ?? '';
  const tsdElementQuery = useTsdElement(tsdElementId, tsdNodeId, {
    enabled: tsdElementId !== '',
  });

  if (
    tsdNodeQuery.isLoading ||
    tsdNodeQuery.isIdle ||
    tsdElementQuery.isLoading ||
    tsdElementQuery.isIdle
  ) {
    return <Notification spin spinSize="large" />;
  }

  if (tsdNodeQuery.isError || tsdElementQuery.isError) {
    return <Notification error />;
  }

  const { data: tsdElement } = tsdElementQuery;
  const { name } = tsdElement;

  return (
    <TsdElementContext.Provider value={{ tsdElement, tsdId, tsdNodeId }}>
      <Outer>
        <Header>
          <LabelValue label={t('TSD_ELEMENT.NAME')}>
            <Title level={3}>{name}</Title>
          </LabelValue>
          <Space>
            <Link to={generateUrl(ROUTES.TSD_ELEMENT_ROOT, { tsdElementId })}>
              <Button
                icon={
                  <Icon
                    className="icn-edit"
                    style={{ verticalAlign: 'text-bottom', marginRight: 5 }}
                  />
                }
                type="link"
              >
                {t('TSD_ELEMENT.EDIT_IN_LIBRARY')}
              </Button>
            </Link>
            <TsdElementUsagePopup />
          </Space>
        </Header>
        <Flex>
          <TsdElementGraphics />
          <TsdElementDetails />
        </Flex>
        <TsdElementAttributes />
      </Outer>
    </TsdElementContext.Provider>
  );
};

export default TsdElementNode;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const Flex = styled.div`
  display: flex;
`;
