import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { MicrosoftLogo } from '../../../icons';

interface Props {
  label: string;
  loading: boolean;
  style?: React.CSSProperties;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MsButton: React.FC<Props> = ({ label, loading, style, onClick }) => (
  <StyledButton
    icon={<MicrosoftLogo />}
    loading={loading}
    type="primary"
    style={style}
    onClick={onClick}
    data-testid="ms-button"
  >
    <Label>{label}</Label>
  </StyledButton>
);

export default MsButton;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 44px;
  font-weight: 500;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.primary200};
  background-color: ${(props) => props.theme.colors.white100};
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.primary200};
    background-color: ${(props) => props.theme.colors.white87};
  }
`;

const Label = styled.span`
  margin-left: 10px;
  padding-top: 2px;
`;
