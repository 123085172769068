import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipProps } from 'antd';
import { Icon } from '../../../Icon';
import useTranslation from '../../../../translations';
import FileSelector from '../../../FileSelector';
import { DOCX_FORMAT } from '../../../../constants';
import { Colors } from '../../../../theme';

interface Props {
  selected: boolean;
  resetKey: number;
  loading: boolean;
  enableEmbeddedObjectActions: boolean;
  onCopy: () => void;
  onFileChange: (selectedFiles: File[]) => void;
  onDownload: () => void;
}

const TOOLTIP_STYLE = {
  maxWidth: '150px',
  color: Colors.white87,
  padding: '10px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
};

export const tooltipCommonProps: TooltipProps = {
  placement: 'right',
  overlayInnerStyle: TOOLTIP_STYLE,
  color: Colors.primary300,
};

const ImageMenu: React.FC<Props> = ({
  selected,
  resetKey,
  loading,
  enableEmbeddedObjectActions,
  onFileChange,
  onCopy,
  onDownload,
}) => {
  const t = useTranslation();

  const embeddedObjectActions = enableEmbeddedObjectActions && (
    <>
      <Tooltip {...tooltipCommonProps} title={t('ACTION.DOWNLOAD')}>
        <Button onClick={onDownload} disabled={loading}>
          <Icon className="icn-download" size={15} color="black" />
        </Button>
      </Tooltip>
      <UploadContainer active={selected}>
        <FileSelector
          resetKey={resetKey}
          accept={DOCX_FORMAT}
          onChange={(acceptedFiles) => onFileChange(acceptedFiles)}
          style={{ height: 32, border: 'none' }}
          overrideContent={
            <Tooltip {...tooltipCommonProps} title={t('ACTION.REPLACE')}>
              <Button disabled={loading}>
                <Icon className="icn-upload" size={15} color="black" />
              </Button>
            </Tooltip>
          }
        />
      </UploadContainer>
    </>
  );

  return (
    <ButtonGroup>
      <Tooltip {...tooltipCommonProps} title={t('ACTION.COPY')}>
        <Button onClick={onCopy}>
          <Icon className="icn-copy" size={15} color="black" />
        </Button>
      </Tooltip>
      {embeddedObjectActions}
    </ButtonGroup>
  );
};

const Button = styled.button`
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.colors.ecru100};
  transition: background 0.3s ease-in-out;
  border-radius: 4px;

  &:not([disabled]):hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.secondary300};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  position: absolute;
  right: 2%;
  top: 2%;
  user-select: none;
  z-index: 1000;
`;

const UploadContainer = styled.span<{ active: boolean }>`
  opacity: 0;
  ${(props) => props.active && `opacity: 1`}
`;

export default ImageMenu;
