import { css } from 'styled-components';

export default css`
  .numbered-paragraph {
    position: relative;
    counter-increment: numbered-paragraph;
    padding-left: 2.5em;
    &::before {
      position: absolute;
      left: 0.6em;
      font-variant-numeric: tabular-nums;
      content: counter(numbered-paragraph, decimal) '. ';
    }
  }
`;
