import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { Country } from '../../../types';

const { Option } = Select;

interface Props {
  value: string;
  onChange: (value: string) => void;
  defaultValue: string;
}

const CustomCountryPicker: React.FC<Props> = ({
  value,
  onChange,
  defaultValue,
}) => {
  const handleChange = (newValue: string): void => {
    onChange(newValue);
  };

  const countryOptions = Object.values(Country).map((v) => (
    <Option key={v} value={v}>
      <Flex>
        <Label>{v}</Label>
      </Flex>
    </Option>
  ));

  return (
    <Outer>
      <Select
        suffixIcon={<DownArrowIcon className="icn-chevron-down" />}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        showSearch
        placeholder="Select"
      >
        {countryOptions}
      </Select>
    </Outer>
  );
};

export default CustomCountryPicker;

const Label = styled.span``;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Outer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 15px;
    background: none;
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    transition: border 0.2s ease-in-out;
    border: none;
    width: 100%;
    border-radius: 0;
    padding: 0;

    .ant-select-selection-search {
      input {
        &:focus {
          outline: none;
        }
        outline: none;
        border: none;
        box-shadow: none;
        background: none;
        display: flex;
        flex: 1;
      }
    }
  }

  .ant-select-arrow {
    top: 9px;
    right: 9px;
  }

  .ant-select {
    width: 100%;
  }
`;

export const DownArrowIcon = styled.i`
  font-size: 24px;
`;
