import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Evaluator } from '../../../../assets/appLogo/Evaluator.svg';
import { ReactComponent as Competitor } from '../../../../assets/appLogo/Competitor.svg';
import { ReactComponent as TsdEditor } from '../../../../assets/appLogo/TsdEditor.svg';
import { ReactComponent as Patentor } from '../../../../assets/appLogo/Patentor.svg';
import { ReactComponent as DiligenceDude } from '../../../../assets/appLogo/DiligenceDude.svg';
import { ReactComponent as Liftoff } from '../../../../assets/appLogo/Liftoff.svg';
import { ReactComponent as OfficeActionHero } from '../../../../assets/appLogo/OfficeActionHero.svg';
import { ReactComponent as IposPhilipa } from '../../../../assets/appLogo/IposPhilipa.svg';
import { ApplicationIdentifier } from '../../../types';

interface Props {
  appContext?: ApplicationIdentifier;
  withGlow?: boolean;
  style?: React.CSSProperties;
}

const Logo: React.FC<Props> = ({ appContext, withGlow = true, style }) => {
  let logo;
  switch (appContext) {
    case ApplicationIdentifier.OFFICE_ACTION_HERO:
      logo = <OfficeActionHero />;
      break;

    case ApplicationIdentifier.EVALUATOR:
      logo = <Evaluator />;
      break;

    case ApplicationIdentifier.COMPETITOR:
      logo = <Competitor />;
      break;

    case ApplicationIdentifier.TSD_EDITOR:
      logo = <TsdEditor />;
      break;

    case ApplicationIdentifier.PATENTOR:
      logo = <Patentor />;
      break;

    case ApplicationIdentifier.DDUDE:
      logo = <DiligenceDude />;
      break;

    case ApplicationIdentifier.LIFTOFF:
      logo = <Liftoff />;
      break;

    default:
      logo = <IposPhilipa />;
  }

  return (
    <Outer>
      {withGlow && <Glow />}
      <LogoWrapper style={style}>{logo}</LogoWrapper>
    </Outer>
  );
};

export default Logo;

const Outer = styled.div`
  position: relative;
`;

const LogoWrapper = styled.div`
  width: 50px;
  height: 50px;
`;

const Glow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 1;
  -webkit-filter: blur(32px);
  filter: blur(32px);
  background-color: #00a7ff;
`;
