/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect, useRef, useState, useCallback } from 'react';
import RBush from 'rbush';
import { message } from 'antd';
import { DocumentMetadata } from '../../types';
import { useSelectableText } from '../../mutations';
import useTranslation from '../../translations';
import { SelectableTextBBox } from './types';
import {
  cacheSelectableText,
  calculateBoundingBox,
  drawRectangle,
  drawTextHighlights,
  findPageNumber,
  getNonCachedPages,
  getTextForCopying,
  getVisiblePages,
  repositionAndSetTooltip,
  unprojectSelectionRectangle,
} from './util';

interface HookShape {
  (props: {
    currentDocument: DocumentMetadata;
    visibilities: boolean[];
    containerRef: RefObject<HTMLDivElement>;
    pageRefs: HTMLDivElement[];
  }): {
    advancedOCRSelectionEnabled: boolean;
    advancedOCRSelectionActive: boolean;
    onAdvancedOCRSelection: () => void;
    selectionRectangleRef: RefObject<HTMLDivElement>;
  };
}

const useSelectableOcrText: HookShape = ({
  currentDocument,
  containerRef,
  visibilities,
  pageRefs,
}) => {
  const [advancedOCRSelectionActive, setAdvancedOCRSelectionActive] =
    useState(false);
  const { mutateAsync: fetchSelectableUris } = useSelectableText();
  const selectableTextCache = useRef<Record<string, RBush<SelectableTextBBox>>>(
    {}
  );
  const t = useTranslation();

  const currentSelection = useRef<
    Record<string, SelectableTextBBox[] | undefined>
  >({});
  const isMouseDown = useRef<boolean>(false);
  const mouseStartPositionRef = useRef<MouseEvent>();
  const selectionRectangleRef = useRef<HTMLDivElement>(null);
  const textToCopy = useRef<string>();

  const onAdvancedOCRSelection = () => {
    setAdvancedOCRSelectionActive((prev) => !prev);
  };

  useEffect(() => {
    const callback = async () => {
      if (
        !selectableTextCache?.current ||
        !currentDocument.selectableTextUris?.length ||
        !visibilities?.length
      ) {
        return;
      }

      const visiblePages = getVisiblePages(visibilities);
      const nonCachedPages = getNonCachedPages(
        visiblePages,
        selectableTextCache.current
      );
      if (!nonCachedPages?.length) {
        return;
      }
      try {
        const selectableTextPages = await fetchSelectableUris({
          documentId: currentDocument.id,
          pages: nonCachedPages,
        });
        cacheSelectableText(selectableTextPages, selectableTextCache.current);
      } catch (e) {
        console.error(e);
      }
    };
    callback();
  }, [currentDocument.id, currentDocument.selectableTextUris, visibilities]);

  // reset the selectable text cache when the document changes
  // otherwise the cache will contain the selectable text of the previous document
  // because the cache uses page number as key
  useEffect(() => {
    selectableTextCache.current = {};
  }, [currentDocument]);

  const onSelectMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      requestAnimationFrame(() => {
        if (
          !selectionRectangleRef?.current ||
          !mouseStartPositionRef?.current ||
          !isMouseDown.current ||
          !containerRef.current
        ) {
          return;
        }
        const OFFSET_Y = containerRef.current.getBoundingClientRect().top;
        const OFFSET_X = containerRef.current.getBoundingClientRect().left;
        const pageNumber = findPageNumber(
          e.target as HTMLElement,
          containerRef.current
        );

        const { top, left, width, height } = calculateBoundingBox(
          selectionRectangleRef.current,
          mouseStartPositionRef.current,
          e.clientX,
          e.clientY,
          OFFSET_X,
          OFFSET_Y
        );
        drawRectangle(selectionRectangleRef.current, top, left, width, height);

        if (pageNumber === -1) {
          return;
        }
        currentSelection.current[pageNumber - 1] = undefined;
        Object.keys(currentSelection.current).forEach((pi: string) => {
          const pageIndex = parseInt(pi, 10);
          const canvas: HTMLCanvasElement | null =
            pageRefs[pageIndex].querySelector('.annotation-canvas');
          if (!canvas) {
            return;
          }
          const canvasRect = canvas.getBoundingClientRect();
          const {
            x,
            y,
            width: unprojectedWidth,
            height: unprojectedHeight,
          } = unprojectSelectionRectangle(
            top - canvasRect.top + OFFSET_Y,
            left - canvasRect.left + OFFSET_X,
            width,
            height,
            canvasRect.width,
            canvasRect.height
          );

          const textSelectionsInsideRectangle = selectableTextCache.current?.[
            pageIndex
          ].search({
            minX: x,
            minY: y,
            maxX: x + unprojectedWidth,
            maxY: y + unprojectedHeight,
          });
          drawTextHighlights(canvas, textSelectionsInsideRectangle);
          currentSelection.current[pageNumber - 1] =
            textSelectionsInsideRectangle;
        });
        textToCopy.current = getTextForCopying(currentSelection.current);
        repositionAndSetTooltip(
          selectionRectangleRef.current,
          textToCopy.current
        );
      });
    },
    [currentDocument, pageRefs]
  );

  const cleanup = () => {
    if (!selectionRectangleRef?.current) {
      return;
    }
    Object.keys(currentSelection.current).forEach((pageIndex) => {
      const canvas: HTMLCanvasElement | null =
        pageRefs[parseInt(pageIndex, 10)].querySelector('.annotation-canvas');
      if (canvas) {
        const ctx = canvas?.getContext('2d');
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
      }
    });
    textToCopy.current = '';
    currentSelection.current = {};
    mouseStartPositionRef.current = undefined;
    selectionRectangleRef.current.style.display = 'none';
  };

  const copySelectionToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success(t('COPIED_TO_CLIPBOARD'));
    } catch {
      // User denied clipboard write permission
      message.error(t('FAILED_TO_COPY_CLIPBOARD'));
    }
  };

  const onSelectMouseUp = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      isMouseDown.current = false;
      copySelectionToClipboard(textToCopy.current || '');
      cleanup();
      containerRef.current?.removeEventListener('mousemove', onSelectMouseMove);
      containerRef.current?.removeEventListener('mouseup', onSelectMouseUp);
      containerRef.current?.removeEventListener('mouseout', onSelectMouseMove);
    },
    [onSelectMouseMove, currentDocument]
  );

  const onSelectMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (!selectionRectangleRef?.current) {
        return;
      }
      // Capture only selections in one view context
      if (!containerRef?.current?.contains(e.target as HTMLElement)) {
        return;
      }
      mouseStartPositionRef.current = e;
      isMouseDown.current = true;
      containerRef.current?.addEventListener('mouseup', onSelectMouseUp);
      containerRef.current?.addEventListener('mousemove', onSelectMouseMove);
      return () => {
        isMouseDown.current = false;
        containerRef.current?.removeEventListener('mouseout', onSelectMouseUp);
        containerRef.current?.removeEventListener('mouseup', onSelectMouseUp);
        containerRef.current?.removeEventListener(
          'mousemove',
          onSelectMouseMove
        );
      };
    },
    [onSelectMouseMove, onSelectMouseUp, currentDocument]
  );

  useEffect(() => {
    if (advancedOCRSelectionActive) {
      containerRef.current?.addEventListener('mousedown', onSelectMouseDown);
    } else {
      containerRef.current?.removeEventListener('mousedown', onSelectMouseDown);
    }
    return () => {
      containerRef.current?.removeEventListener('mousedown', onSelectMouseDown);
    };
  }, [
    currentDocument,
    advancedOCRSelectionActive,
    onSelectMouseDown,
    onSelectMouseUp,
  ]);
  return {
    advancedOCRSelectionEnabled:
      currentDocument.selectableTextUris != null &&
      currentDocument.selectableTextUris.length > 0,
    onAdvancedOCRSelection,
    advancedOCRSelectionActive,
    selectionRectangleRef,
  };
};

export default useSelectableOcrText;
