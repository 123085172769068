import React, { useState } from 'react';
import { validate } from 'uuid';
import { useFormContext, useController, get } from 'react-hook-form';
import { Popover } from 'react-tiny-popover';
import { DocumentPickerDisplayItem, DocumentPickerItem } from '../types';
import DocumentPickerDropdown from './DocumentPickerDropdown';
import DocumentPickerInput from './DocumentPickerInput';

interface Props {
  docItems: DocumentPickerItem[];
  disableItemsWithNoTextLayer?: boolean;
  inputStyle?: React.CSSProperties;
  onItemsChange: React.Dispatch<React.SetStateAction<DocumentPickerItem[]>>;
}

const cloneItem = (item: DocumentPickerItem) => ({ ...item });

const DocumentPicker: React.FC<Props> = ({
  docItems,
  disableItemsWithNoTextLayer = false,
  inputStyle,
  onItemsChange,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { onChange },
  } = useController({
    name: 'documentIds',
    control,
  });

  const isError = !!get(errors, 'documentIds');

  const isCheckable = (item: DocumentPickerItem) =>
    (item.hasOcrLayer || !disableItemsWithNoTextLayer) &&
    !item.subItems?.length &&
    validate(item.id);

  const handleChange = (updatedItems: DocumentPickerItem[]) => {
    const selectedIds: string[] = [];
    const extractCheckedIds = (items: DocumentPickerItem[]) => {
      items.forEach((item) => {
        if (item.checked && isCheckable(item)) {
          selectedIds.push(item.id);
        }
        item.subItems && extractCheckedIds(item.subItems);
      });
    };
    extractCheckedIds(updatedItems);
    onChange(selectedIds);
  };

  const handleItemCheck = (itemId: string, checked: boolean) => {
    const getUpdatedItems = (
      currentItems: DocumentPickerItem[]
    ): DocumentPickerItem[] =>
      currentItems.map((item) => {
        const clonedItem = cloneItem(item);
        if (item.id === itemId && isCheckable(item)) {
          clonedItem.checked = checked;
          return clonedItem;
        }
        if (item.subItems) {
          clonedItem.subItems = getUpdatedItems(item.subItems);
        }
        return clonedItem;
      });

    const updatedItems = getUpdatedItems(docItems);
    handleChange(updatedItems);
    onItemsChange(updatedItems);
  };

  const cloneAndCheckAllItems = (
    items: DocumentPickerItem[],
    checked: boolean
  ): DocumentPickerItem[] =>
    items.map((item) => {
      const clonedItem = cloneItem(item);
      if (isCheckable(clonedItem)) {
        clonedItem.checked = checked;
      }
      if (item.subItems) {
        clonedItem.subItems = cloneAndCheckAllItems(item.subItems, checked);
      }
      return clonedItem;
    });

  const handleCheckAllItems = (checked: boolean) => {
    const updatedItems = cloneAndCheckAllItems(docItems, checked);
    handleChange(updatedItems);
    onItemsChange(updatedItems);
  };

  const cloneAndUncheckItemAndChildren = (
    items: DocumentPickerItem[],
    itemId: string,
    applyToChildren = false
  ): DocumentPickerItem[] =>
    items.map((item) => {
      const clonedItem = cloneItem(item);
      if (applyToChildren && isCheckable(clonedItem)) {
        clonedItem.checked = false;
      }
      if (item.subItems) {
        clonedItem.subItems = cloneAndUncheckItemAndChildren(
          item.subItems,
          itemId,
          applyToChildren || item.id === itemId
        );
      }
      return clonedItem;
    });

  const handleUncheckChildren = (itemId: string) => {
    const updatedItems = cloneAndUncheckItemAndChildren(docItems, itemId);
    handleChange(updatedItems);
    onItemsChange(updatedItems);
  };

  const getTopLevelItems = (
    items: DocumentPickerItem[]
  ): DocumentPickerDisplayItem[] => {
    const topLevelItems: DocumentPickerDisplayItem[] = [];
    const countCheckedItems = (item: DocumentPickerItem): number => {
      let count = item.checked ? 1 : 0;
      if (item.subItems) {
        for (const subItem of item.subItems) {
          count += countCheckedItems(subItem);
        }
      }
      return count;
    };
    for (const item of items) {
      const count = countCheckedItems(item);
      topLevelItems.push({ name: item.name, id: item.id, count });
    }
    return topLevelItems.filter((item) => item.count > 0);
  };

  const handleItemOpenChange = (itemId: string, open: boolean) => {
    const getUpdatedItems = (
      currentItems: DocumentPickerItem[]
    ): DocumentPickerItem[] =>
      currentItems.map((item) => {
        const clonedItem = cloneItem(item);
        if (item.id === itemId && item.subItems?.length) {
          clonedItem.open = open;
          return clonedItem;
        }
        if (item.subItems) {
          clonedItem.subItems = getUpdatedItems(item.subItems);
        }
        return clonedItem;
      });

    const updatedItems = getUpdatedItems(docItems);
    onItemsChange(updatedItems);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top']}
      onClickOutside={() => setPopoverOpen(false)}
      align="start"
      containerStyle={{
        marginTop: '6px',
        zIndex: '1000',
      }}
      content={
        <DocumentPickerDropdown
          items={docItems}
          onItemCheck={handleItemCheck}
          onItemOpenChange={handleItemOpenChange}
          onCheckAll={handleCheckAllItems}
          disableItemsWithNoTextLayer={disableItemsWithNoTextLayer}
        />
      }
    >
      <DocumentPickerInput
        isPopoverOpen={isPopoverOpen}
        isError={isError}
        items={getTopLevelItems(docItems)}
        onRemoveItem={handleUncheckChildren}
        onToggle={() => setPopoverOpen((prev) => !prev)}
        style={inputStyle}
      />
    </Popover>
  );
};

export default DocumentPicker;
