import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { Tutorial } from '../types';
import { QueryKey } from '.';

const useTutorials = (
  options?: UseQueryOptions<Tutorial[], AxiosError>
): UseQueryResult<Tutorial[], AxiosError> =>
  useQuery<Tutorial[], AxiosError>(
    QueryKey.Tutorials,
    Api.tutorials.getTutorials,
    options
  );

export default useTutorials;
