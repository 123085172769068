import React, { useContext } from 'react';
import { UserSession } from '../types';
import { useUserSessionQueryContext } from '../contexts';
import { PageLoad } from '../components';
import useTranslation from '../translations';

const SessionContext = React.createContext<UserSession | undefined>(undefined);

export const SessionProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const t = useTranslation();
  const { session, groupSettings, translationService } =
    useUserSessionQueryContext();

  return session && groupSettings && translationService ? (
    <SessionContext.Provider
      value={{ session, groupSettings, translationService }}
    >
      {children}
    </SessionContext.Provider>
  ) : (
    <PageLoad message={t('SESSION_ERROR')} />
  );
};

export function useSessionContext(): UserSession {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within SessionProvider');
  }
  return context;
}
