import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { PatentPublication } from '../types';
import { Api } from '../..';
import { QueryKey } from './';

const usePatentPublication = (
  patentPublicationId: string,
  options?: UseQueryOptions<PatentPublication, AxiosError>
): UseQueryResult<PatentPublication, AxiosError> =>
  useQuery<PatentPublication, AxiosError>(
    [QueryKey.PatentPublication, patentPublicationId],
    () => Api.pb.getPublication(patentPublicationId),
    options
  );

export default usePatentPublication;
