import { object, string, mixed } from 'yup';
import { T } from '@ipos/shared';

const addTsdElementTextModuleSchema = object({
  textModuleTypeId: string().trim().required('VALIDATION.REQUIRED'),
  language: mixed<T.Language>().required('VALIDATION.REQUIRED'),
  text: string().trim().required('VALIDATION.REQUIRED'),
});

export default addTsdElementTextModuleSchema;
