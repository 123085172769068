import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { useGroupUsers, useIntersectionObserver } from '../../../hooks';
import ObservedTarget from '../../ObservedTarget';
import { T } from '../../../..';
import Search from '../../Search';
import { B2 } from '../../Atoms/Atoms';
import {
  ModalState,
  PatentApplicationStakeholderRole,
  SCREEN,
  StakeholderListItem,
} from '../../../types';
import { FlatButton } from '../../Button';
import ContactListBody from './ContactListBody';

interface Props {
  addNewContact?: boolean;
  groupType?: T.ContactGroupType;
  onGotoScreen: (modalScreen: SCREEN) => () => void;
  stakeholders: T.PatentApplicationStakeholder[];
  availableRoles: T.PatentApplicationStakeholderRole[];
  onAssignStakeHolderRole: (
    stakeholder: StakeholderListItem,
    role: PatentApplicationStakeholderRole
  ) => void;
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
}

const ContactsList: React.FC<Props> = ({
  groupType = T.ContactGroupType.CoworkerContacts,
  stakeholders,
  onAssignStakeHolderRole,
  availableRoles,
  addNewContact,
  onGotoScreen,
  modalState,
  setModalState,
}) => {
  const t = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const {
    infiniteData,
    isLoading,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useGroupUsers({ searchTerm, groupType });

  useIntersectionObserver({
    target: targetRef?.current,
    onIntersect: () => {
      fetchNextPage();
    },
    root: rootRef?.current,
    threshold: 0.1,
    enabled: hasNextPage,
  });

  const getScreenTitleAndBtnText = (screenType: SCREEN) => {
    switch (screenType) {
      case SCREEN.ADD_INVENTOR:
        return {
          title: t('STAKEHOLDERS.ROLES.INVENTORS'),
          btnText: t('STAKEHOLDERS.NEW_INVENTOR'),
          addScreen: SCREEN.ADD_INVENTOR_CONTACT,
          editScreen: SCREEN.EDIT_INVENTOR_CONTACT,
        };
      case SCREEN.ADD_APPLICANT:
        return {
          title: t('STAKEHOLDERS.ROLES.APPLICANTS'),
          btnText: t('STAKEHOLDERS.NEW_APPLICANT'),
          addScreen: SCREEN.ADD_APPLICANT_CONTACT,
          editScreen: SCREEN.EDIT_APPLICANT,
        };
      case SCREEN.ADD_IN_HOUSE_COUNSEL:
        return {
          title: t('STAKEHOLDERS.ROLES.INHOUSECOUNSEL'),
          btnText: '',
          addScreen: null,
          editScreen: SCREEN.EDIT_IN_HOUSE_COUNSEL,
        };
      case SCREEN.ADD_EXTERNAL_COUNSEL:
        return {
          title: t('STAKEHOLDERS.ROLES.EXTERNALCOUNSEL'),
          btnText: '',
          addScreen: null,
          editScreen: SCREEN.EDIT_EXTERNAL_COUNSEL,
        };
      case SCREEN.VIEW_STAKEHOLDERS:
      default:
        return {
          title: t('STAKEHOLDERS.ATTORNEYS'),
          btnText: t('STAKEHOLDERS.ATTORNEYS'),
          addScreen: null,
          editScreen: SCREEN.EDIT_STAKEHOLDER,
        };
    }
  };
  const { title, btnText, addScreen, editScreen } = useMemo(
    () => getScreenTitleAndBtnText(modalState.currentScreen),
    [modalState.currentScreen]
  );
  return (
    <Outer ref={rootRef}>
      <SearchWrapper>
        <Search
          style={{
            borderRadius: '5px',
          }}
          type="dark"
          value={searchTerm}
          onChange={(v) => setSearchTerm(v)}
        />
      </SearchWrapper>
      <Inner>
        <Row>
          <ListTitle>{title}</ListTitle>
          {addNewContact && addScreen && (
            <Button
              onClick={onGotoScreen(addScreen)}
              leftIcon={<i className="icn-add" style={{ fontSize: '17px' }} />}
            >
              {btnText}
            </Button>
          )}
        </Row>

        <Wrapper style={{ minHeight: 0, flex: 1 }} ref={bodyRef}>
          <ContactListBody
            availableRoles={availableRoles}
            stakeholders={stakeholders}
            onAddStakeholder={onAssignStakeHolderRole}
            userList={infiniteData}
            isError={isError}
            isLoading={isLoading}
            setModalState={setModalState}
            editScreen={editScreen}
          />
          <ObservedTarget targetRef={targetRef} loading={isFetchingNextPage} />
        </Wrapper>
      </Inner>
    </Outer>
  );
};

const ListTitle = styled(B2)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.white70};
  margin-bottom: 14px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px;
`;

const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  min-height: 0;
  flex: 1;
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  padding-bottom: 30px;
`;

const Inner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: baseline;
`;

const Button = styled(FlatButton)`
  margin: 0;
  border-radius: '5px';
  border: ${(props) => `2px solid ${props.theme.colors.white20}`};
  font-size: '14px';
  color: ${(props) => props.theme.colors.white87};
  padding: 4px 16px;
`;

export default ContactsList;
