import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './app';
import en from 'translations/en-US/common.json';
import de from 'translations/de-de/common.json';
import { Components, T, Translations } from '@ipos/shared';

const { Application } = Components;
const { registerNamespace } = Translations;

registerNamespace('tsd', { en, de });

Sentry.init({
  denyUrls: ['localhost', '127.0.0.1'],
  dsn: 'https://a941ee5f591540c8a99b0b6ba83f9d01@o918738.ingest.sentry.io/6761227',
  environment: process.env.NX_BUILD_ENV || 'development',
});

Sentry.setTag('appName', 'TSD Edithor');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Application appContext={T.ApplicationIdentifier.TSD_EDITOR}>
    <App />
  </Application>
);
