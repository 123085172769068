import { T } from '../..';
import { DEFAULT_PAGE_SIZE } from '../constants';
import {
  generateBaseDto,
  generateDataPoint,
  generateTsd,
} from './mockDataGenerators';

const mockTdsId = '6cb3f045-a284-4807-bf74-58d081cbc948';
const mockTsdNodeId = 'ee3b54f3-0ec7-4555-89e1-d40679a40b87';
const mockTsdParentNodeId = '7fadef4d-4aeb-4cbe-bbca-960555551f08';
const mockTsdElementId = '50ca4328-35f7-483c-8f03-ac0a809fd45f';
const mockIpcClassId = '21fb0be1-ec6e-4886-a1ec-170b86a1b82c';
const mockIpcClassParentId = '668564db-c20d-4620-8f6b-8a71dc3380dd';
const mockTsdIpcClassId = '2161be13-9c26-4918-b571-ad593ccefd76';
const NUMBER_OF_POINTS = 100;

export const mockIpcClass: T.IpcClass = {
  id: mockIpcClassId,
  code: 'test code',
  indent: 'test title',
  parentId: mockIpcClassParentId,
  description: 'test description',
  createdOn: '2021-02-06T12:23:24.206Z',
  modifiedOn: '2021-02-06T12:23:24.206Z',
};

export const mockTsdIpcClass: T.TsdElementIpcClass = {
  id: mockTsdIpcClassId,
  tsdElementId: mockTsdElementId,
  ipcClassId: mockIpcClassId,
  ipcClass: mockIpcClass,
  createdOn: '2021-02-06T12:23:24.206Z',
  modifiedOn: '2021-02-06T12:23:24.206Z',
  isBaseAttribute: false,
};

export const mockTsdElement: T.TsdElement = {
  id: mockTsdElementId,
  createdOn: '2021-02-06T12:23:24.206Z',
  modifiedOn: '2021-02-06T12:23:24.206Z',
  name: 'test',
  names: {
    [T.Language.De]: {
      language: T.Language.De,
      value: 'test',
      isAutoTranslation: false,
    },
  },
  description: 'test',
  ipcClassCount: 2,
  companiesCount: 3,
  synonymsCount: 3,
  descriptionsCount: 2,
  queriesCount: 1,
  uploadsCount: 0,
  textModulesCount: 0,
  frequency: 1,
};

export const mockTsdNode: T.TsdNode = {
  ...generateBaseDto(),
  id: mockTsdNodeId,
  tsdId: mockTdsId,
  name: 'some name',
  children: [],
  sequence: 3000,
  tsdElementId: mockTsdElementId,
};

export const mockTsdParentNode: T.TsdNode = {
  ...generateBaseDto(),
  id: mockTsdParentNodeId,
  tsdId: mockTdsId,
  name: 'another name',
  sequence: 3000,
  children: [mockTsdNode],
  tsdElementId: mockTsdElementId,
};

export const firstPageTsdResponse: T.Tsd[] = [
  ...Array.from(Array(DEFAULT_PAGE_SIZE)).map(() => generateTsd()),
];

export const secondPageTsdResponse: T.Tsd[] = [
  ...Array.from(Array(DEFAULT_PAGE_SIZE)).map(() => generateTsd()),
];

export const mockDataPoint: T.DataPoint = generateDataPoint();
export const mockDataPoints: T.DataPoint[] = [
  ...Array.from(Array(NUMBER_OF_POINTS)).map(() => generateDataPoint()),
];

export const mockTsd: T.Tsd = generateTsd();
