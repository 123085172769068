import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { UserSettings } from '../types';
import { QueryKey } from '.';

const useGetUserSettings = (
  options?: UseQueryOptions<UserSettings, AxiosError>
): UseQueryResult<UserSettings, AxiosError> =>
  useQuery<UserSettings, AxiosError>(
    [QueryKey.UserSettings],
    () => Api.userSettings.getUserSettings(),
    options
  );

export default useGetUserSettings;
