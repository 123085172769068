import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Components } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import { Tsd } from 'components/Tsd';
import Home from 'components/Home';
import useTranslation from 'translations';
import { TsdElement, TsdElements } from 'components/TsdElement';
import { AppContextProvider } from 'contexts';
import './App.less';

const { Layout } = Components;
const { Navigation, NavigationItem, Content, Body } = Layout;

const Index: React.FC = () => {
  const t = useTranslation();

  return (
    <AppContextProvider>
      <Navigation>
        <NavigationItem
          testId="navigation-menu-home"
          to={generateUrl(ROUTES.HOME)}
          label={t('MENU.HOME')}
          iconClassName="icn-case-explorer"
          activePath={{ path: ROUTES.HOME.path, exact: true }}
        />
        <NavigationItem
          testId="navigation-menu-tsd-elements"
          to={generateUrl(ROUTES.TSD_ELEMENTS)}
          label={t('MENU.TSD_ELEMENTS')}
          iconClassName="icn-patent-analysis"
          activePath={{ path: ROUTES.TSD_ELEMENTS.path, exact: true }}
        />
      </Navigation>
      <Content>
        <Body>
          <Switch>
            <Route path={ROUTES.TSD_ELEMENT_ROOT.path} component={TsdElement} />
            <Route
              exact
              path={ROUTES.TSD_ELEMENTS.path}
              component={TsdElements}
            />
            <Route path={ROUTES.TSD_ROOT.path} component={Tsd} />
            <Route path={ROUTES.HOME.path} component={Home} exact />
            <Redirect to={generateUrl(ROUTES.HOME)} />
          </Switch>
        </Body>
      </Content>
      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </AppContextProvider>
  );
};

export default Index;
