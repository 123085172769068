import { T } from '@ipos/shared';

export type QuerySegments = T.TsdQueryIpcClass | T.TsdQuerySynonym;

export enum QueryStringType {
  GooglePatents = 'googlePatents',
  PatBase = 'patBase',
}

export enum QuerySegment {
  Classes = 'classes',
  Synonyms = 'synonyms',
}

export enum QuerySynonymOption {
  IsTruncatedAtStart = 'isTruncatedAtStart',
  IsTruncatedAtEnd = 'isTruncatedAtEnd',
  SearchInTitle = 'searchInTitle',
  SearchInAbstract = 'searchInAbstract',
  SearchInDescription = 'searchInDescription',
  SearchInClaims = 'searchInClaims',
}

export enum QueryClassesOption {
  IsTruncated = 'isTruncated',
}

export type QueryBaseItem = {
  id: string;
  value: string;
  isBaseAttribute: boolean;
};

export type QueryBase = {
  classes: QueryBaseItem[];
  synonyms: QueryBaseItem[];
};

export type QueryBaseResult = {
  queryBase?: QueryBase;
  isLoading: boolean;
  isError: boolean;
};

// query builder reducer-related types
export enum Action {
  InitQueryRequest = 'INIT_QUERY_REQUEST',
  SelectChangeRequest = 'SELECT_CHANGE_REQUEST',
  OptionChangeRequest = 'OPTION_CHANGE_REQUEST',
  SwitchModeRequest = 'EXPERT_MODE_CHANGE_REQUEST',
  QueryStringChangeRequest = 'QUERY_STRING_CHANGE_REQUEST',
  UpdateQueryRequest = 'UPDATE_QUERY_REQUEST',
}

type InitQueryRequest = {
  type: Action.InitQueryRequest;
  payload: T.TsdQuery;
};

type UpdateQueryRequest = {
  type: Action.UpdateQueryRequest;
  payload: T.TsdQuery;
};

export type SelectChangePayload = {
  id: string;
  name: string;
  segment: QuerySegment;
};

type SelectChangeRequest = {
  type: Action.SelectChangeRequest;
  payload: SelectChangePayload;
};

export type OptionChangePayload = {
  id: string;
  option: QueryClassesOption | QuerySynonymOption;
  segment: QuerySegment;
  checked: boolean;
};

export type QueryStringChangePayload = {
  value: string;
  type: QueryStringType;
};

export type SwitchModePayload = {
  isInExpertMode: boolean;
};

type OptionChangeRequest = {
  type: Action.OptionChangeRequest;
  payload: OptionChangePayload;
};

type SwitchModeRequest = {
  type: Action.SwitchModeRequest;
  payload: SwitchModePayload;
};

type QueryStringChangeRequest = {
  type: Action.QueryStringChangeRequest;
  payload: QueryStringChangePayload;
};

export type QueryBuilderAction =
  | InitQueryRequest
  | UpdateQueryRequest
  | SelectChangeRequest
  | OptionChangeRequest
  | SwitchModeRequest
  | QueryStringChangeRequest;
