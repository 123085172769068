import styled, { css, ThemeProps, CSSObject } from 'styled-components';
import { Link } from 'react-router-dom';
import { IposTheme } from '../../..';
import { truncate } from '../../utils';

const TABLE_CELL_H_PADDING = '10px';
const TABLE_CELL_W_PADDING = '15px';
export const TABLE_PADDING_BETWEEN_SCROLL = '5px';

export const TitleSection = styled.div<{ styleOverride?: CSSObject }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 18px 50px;
  border-bottom: 1px solid #676e7f33;
  ${(props) => props.styleOverride}
`;

export const SearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div<{ scrollX?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  margin-bottom: 20px;
  overflow-x: ${(props) => (props.scrollX ? 'auto' : 'initial')};
  overflow-y: auto;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;

  &:not(.scroll-pinned-to-left) {
    .fixed-left-last {
      box-shadow: 7px 0 10px 0px rgba(0, 0, 0, 0.15);
    }
  }

  &:not(.scroll-pinned-to-right) {
    .fixed-right-first {
      box-shadow: rgba(0, 0, 0, 0.15) -7px 0px 10px 0px,
        rgb(50, 50, 60) 10px 0px 0px 0px; // it fixes border radius issue
    }
  }
`;

export const TableCell = styled.div`
  flex: 1;
  padding: ${TABLE_CELL_H_PADDING} ${TABLE_CELL_W_PADDING};
  align-items: center;
  display: flex;

  // Fixed columns related styles

  &.fixed {
    position: sticky;
    background: inherit;
    z-index: 1;
  }

  &.fixed-left {
    left: 0;
  }

  &.fixed-left-first {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: rgb(43 43 52) -10px 0px 0px 0px; // it fixes the border radius issue
  }

  &.fixed-right {
    right: 0;
  }

  &.fixed-right-last {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: rgb(50, 50, 60) 10px 0px 0px 0px; // it fixes the border radius issue
  }

  &:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const TableTitle = styled.div`
  font-family: 'Roboto';
  font-size: 20px;
  margin-right: auto;
  flex: 4;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
  line-height: ${(props) => props.theme.fonts.lineHeights.h1}px;
`;

export const TableHeader = styled.div<{
  scrollbarCompensation?: boolean;
  scrollX?: boolean;
}>`
  display: flex;
  z-index: 2;
  width: ${(props) => (props.scrollX ? 'max-content' : '100%')};
  min-width: 100%;
  align-items: flex-end;
  margin-bottom: 10px;
  position: sticky;
  background: #2b2b33;
  top: 0;
  color: ${(props) => props.theme.colors.white70};
  font-size: ${(props) => props.theme.fonts.sizes.title}px;
  margin-right: -10px;
  &:not(:empty) {
    min-height: 70px;
    padding: 0;
  }
  ${TableCell} {
    display: flex;
    height: 100%;

    &.fixed {
      box-shadow: none !important;
    }
  }
`;

const tableRowStyle = (props: ThemeProps<typeof IposTheme>) => css<{
  highlighted?: boolean;
}>`
  display: flex;
  background-color: ${props.theme.colors.primary800_80};
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  min-height: 80px;
  overflow: visible;
  transition: box-shadow 0.2s, background-color 0.2s, border 0.3s;
  transition-timing-function: ease-in-out;
  font-size: 14px;
  z-index: 1;
  margin-bottom: 10px;
  ${TableCell} {
    color: ${props.theme.colors.white70};
    ${truncate()}
  }
  &:hover {
    background-color: ${props.theme.colors.primary700};
    box-shadow: 0 10px 20px 0 ${props.theme.colors.black20};
  }
  ${({ highlighted, theme }) =>
    highlighted &&
    `
    background: ${theme.colors.primary700} !important;
    box-shadow: 0 10px 20px 0 ${theme.colors.black20};
  `}
  animation: fadein 0.3s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const AnchoredTableRow = styled(Link)<{
  highlighted?: boolean;
  style?: CSSObject;
  $hoverStyleOverride?: CSSObject;
}>`
  ${(props) => tableRowStyle(props)}
  ${(props) =>
    css`
      ${props.style}
    `};

  &:hover {
    ${(props) =>
      css`
        ${props.$hoverStyleOverride}
      `};
  }
`;

export const VanillaTableRow = styled.div<{
  highlighted?: boolean;
  styleOverride?: CSSObject;
  hoverStyleOverride?: CSSObject;
}>`
  ${(props) => tableRowStyle(props)}
  ${(props) =>
    css`
      ${props.styleOverride}
    `};

  &:hover {
    ${(props) =>
      css`
        ${props.hoverStyleOverride}
      `};
  }
`;

export const TableBody = styled.div<{ scrollX?: boolean }>`
  height: 100%;
  min-height: 0;
  width: ${(props) => (props.scrollX ? 'max-content' : '100%')};
  min-width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 60px);
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 60px);
  font-weight: 300;
  color: ${(props) => props.theme.fonts.colors.h2};
  font-size: ${(props) => props.theme.fonts.sizes.h2}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.h2}px;
`;

export const TableWrapper = styled.div<{ styleOverride?: CSSObject }>`
  position: relative;
  height: 100%;
  min-height: 0;
  margin-bottom: 20px;
  padding: 0 50px;
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

export const Outer = styled.div<{ styleOverride?: CSSObject }>`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #2b2b33 40%, #2b2b3300 80%);
  min-height: 0;
  height: 100%;
  position: relative;
  justify-content: center;
  flex: 1;
  padding: 0;
  border-radius: 16px;

  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;
