import { EditorState, Transaction } from 'prosemirror-state';
import { Node } from 'prosemirror-model';
import { createWrapInListCommand } from 'prosemirror-flat-list';
import { findParentNode } from '../../util';
import { ListType } from '../../styles/bulletListStyles';
import UICommand from './UICommand';

class ListToggleCommand extends UICommand {
  _type: ListType;

  constructor(type: ListType) {
    super();
    this._type = type;
  }

  isActive = (state: EditorState): boolean => !!this._findList(state);

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    // @ts-ignore
    const wrapInBulletList = createWrapInListCommand<{
      kind: ListType;
    }>({
      kind: this._type,
    });
    return wrapInBulletList(state, dispatch);
  };

  _findList(
    state: EditorState
  ):
    | { node: Node | null; depth: number; pos: number; start: number }
    | undefined {
    const listType = this._type;
    const findNode = findParentNode((node) => node.attrs?.kind === listType);
    return findNode(state.selection);
  }
}

export default ListToggleCommand;
