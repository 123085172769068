import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import useTranslation from '../../translations';
import { B1 } from '../Atoms/Atoms';
import { T } from '../../..';

const LEGAL_REMEDIES: {
  key: keyof T.LegalRemedies;
  label: string;
}[] = [
  {
    key: 'furtherProcessing',
    label: 'HOME_LIST.LEGAL_REMEDIES.LABELS.FURTHER_PROCESSING',
  },
  {
    key: 'reestablishment',
    label: 'HOME_LIST.LEGAL_REMEDIES.LABELS.REESTABLISHMENT',
  },
  {
    key: 'appeal',
    label: 'HOME_LIST.LEGAL_REMEDIES.LABELS.APPEAL',
  },
  {
    key: 'other',
    label: 'HOME_LIST.LEGAL_REMEDIES.LABELS.OTHER',
  },
];

interface Props {
  legalRemedies: T.LegalRemedies;
  lossOfRights: T.LossOfRights | null;
}

const LegalRemediesPopup: React.FC<Props> = ({
  legalRemedies,
  lossOfRights,
}) => {
  const t = useTranslation();
  return (
    <Outer>
      {lossOfRights && (
        <>
          <B1 style={{ fontWeight: 500 }}>
            {t('HOME_LIST.LEGAL_REMEDIES.MISSING_TIME_LIMIT')}
          </B1>
          <LegalRemedyName>{`${t(
            `HOME_LIST.LEGAL_REMEDIES.LOSS_OF_RIGHTS.${lossOfRights.toUpperCase()}`
          )}`}</LegalRemedyName>
        </>
      )}
      <Divider />
      <B1 style={{ fontWeight: 500, marginBottom: 13 }}>
        {t('HOME_LIST.LEGAL_REMEDIES.TITLE')}
      </B1>
      {LEGAL_REMEDIES.filter((lr) => legalRemedies[lr.key] !== undefined).map(
        ({ key, label }) => {
          const value =
            key !== 'other'
              ? t(
                  `HOME_LIST.LEGAL_REMEDIES.${key.toUpperCase()}.${legalRemedies[
                    key
                  ]
                    .toString()
                    .toUpperCase()}`
                )
              : legalRemedies['other'].map((o) => (
                  <span key={o}>
                    {t(
                      `HOME_LIST.LEGAL_REMEDIES.${key.toUpperCase()}.${o
                        .toString()
                        .toUpperCase()}`
                    )}
                  </span>
                ));
          return (
            <LegalRemedy key={key}>
              <LegalRemedyName>{`${t(label)}`}</LegalRemedyName>
              <LegalRemedyValue>
                <span>:</span>
                <LegalRemedyValueInner>{value}</LegalRemedyValueInner>
              </LegalRemedyValue>
            </LegalRemedy>
          );
        }
      )}
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const LegalRemedyName = styled(B1)`
  line-height: 24px;
  color: ${(props) => props.theme.colors.white70};
  min-width: 120px;
  font-size: 14px;
`;

const LegalRemedyValue = styled(B1)`
  line-height: 24px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white50};
  margin-left: 5px;
  display: flex;

  > span {
    margin-right: 5px;
  }
`;

const LegalRemedyValueInner = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
`;

const LegalRemedy = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
`;

export default LegalRemediesPopup;
