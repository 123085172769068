import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { CSSObject } from 'styled-components';

import { BaseDto, Column, QueryParams } from '../../../types';
import { Table } from '../..';

interface Props<TItem> {
  item: TItem;
  index: number;
  className?: string;
  cellClassName?: string;
  columns: Column<TItem>[];
  highlighted?: boolean;
  styleOverride?: CSSObject;
  hoverStyleOverride?: CSSObject;
  linkFn?: (item: TItem) => string | undefined;
  clickFn?: (item: TItem) => void;
  customItemRenderer?: (item: TItem, index: number) => React.ReactNode;
  isScrollingByDrag?: boolean;
  queryParams?: Partial<QueryParams>;
}

const ListItem = <TItem extends BaseDto>({
  item,
  index,
  className,
  cellClassName,
  columns,
  highlighted,
  styleOverride,
  hoverStyleOverride,
  linkFn,
  clickFn,
  isScrollingByDrag,
  customItemRenderer,
  queryParams,
}: PropsWithChildren<Props<TItem>>): ReactElement | null => {
  const listItemRef = useRef<HTMLAnchorElement | HTMLDivElement>(null);

  let content = customItemRenderer?.(item, index);

  if (!content) {
    content = (
      <>
        {columns.map((column) => {
          const firstFixedColumnToLeft =
            Table.TableUtils.getFirstFixedColumnToLeft(columns);

          const lastFixedColumnToLeft =
            Table.TableUtils.getLastFixedColumnToLeft(columns);

          const firstFixedColumnToRight =
            Table.TableUtils.getFirstFixedColumnToRight(columns);

          const lastFixedColumnToRight =
            Table.TableUtils.getLastFixedColumnToRight(columns);

          const additionalStyle: CSSObject = {
            display: column.hide ? 'none' : 'flex',
          };

          if (column.fixed) {
            additionalStyle[
              column.fixed
            ] = `${Table.TableUtils.calculateFixedColumnGap<TItem>(
              columns,
              column
            )}px`;
          }

          return (
            <Table.TableCell
              key={`${item.id}-${column.key}`}
              style={{ ...column.style, ...additionalStyle }}
              data-row-column-key={column.key}
              className={classNames(
                {
                  highlight: highlighted,
                  'fixed fixed-left': column.fixed === 'left',
                  'fixed fixed-right': column.fixed === 'right',
                  'fixed-left-first':
                    firstFixedColumnToLeft?.key === column.key,
                  'fixed-left-last': lastFixedColumnToLeft?.key === column.key,
                  'fixed-right-first':
                    firstFixedColumnToRight?.key === column.key,
                  'fixed-right-last':
                    lastFixedColumnToRight?.key === column.key,
                },
                cellClassName
              )}
            >
              {
                (column.renderer
                  ? column.renderer(
                      item[column.key as keyof TItem],
                      item,
                      listItemRef,
                      index,
                      queryParams
                    )
                  : item[column.key as keyof TItem]) as React.ReactNode
              }
            </Table.TableCell>
          );
        })}
      </>
    );
  }

  const generatedLink = linkFn?.(item);
  if (generatedLink) {
    return (
      <Table.AnchoredTableRow
        onClick={(e: React.MouseEvent) => {
          if (isScrollingByDrag) {
            e.preventDefault();
          }
        }}
        draggable={false}
        className={className}
        ref={listItemRef as React.RefObject<HTMLAnchorElement>}
        key={item.id}
        data-testid="list-item"
        to={generatedLink}
        highlighted={highlighted}
        style={styleOverride}
        $hoverStyleOverride={hoverStyleOverride}
      >
        {content}
      </Table.AnchoredTableRow>
    );
  }

  return (
    <Table.VanillaTableRow
      className={className}
      onClick={() => clickFn?.(item)}
      ref={listItemRef as React.RefObject<HTMLDivElement>}
      key={item.id}
      data-testid="list-item"
      highlighted={highlighted}
      styleOverride={styleOverride}
      hoverStyleOverride={hoverStyleOverride}
    >
      {content}
    </Table.VanillaTableRow>
  );
};

export default ListItem;
