import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { CLAIM_ADVANTAGE } from '../../schema/nodes/nodeNames';
import findActiveClaimNode from '../../toolbar/util/findActiveClaimNode';
import { preventNodeDeletionPluginKey } from '../preventNodeDeletionPlugin';
import { createReactNodeView } from './ReactNodeView';
import ClaimAdvantage from './ClaimAdvantage';

const collapsePluginKey = new PluginKey('claimAdvantagePlugin');

const claimAdvantagePlugin = () =>
  new Plugin({
    key: collapsePluginKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(tr) {
        const decorations: Decoration[] = [];
        tr.doc.descendants((node, pos) => {
          if (node && node.type.name === CLAIM_ADVANTAGE) {
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: node?.attrs?.expanded ? 'expanded' : 'collapsed',
              })
            );
          }
        });
        return DecorationSet.create(tr.doc, decorations);
      },
    },
    view: (view) => new ExpandablePluginView(view),
    props: {
      decorations(state) {
        return this.getState(state);
      },
      nodeViews: {
        claimAdvantage: (node, view, getPos, decorations) =>
          createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            component: ClaimAdvantage,
          }),
      },
      handleKeyDown(view, event) {
        const { tr } = view.state;
        const { from } = view.state.selection;

        const result = findActiveClaimNode(view.state);
        if (!result) {
          return false;
        }
        const isAtStartOfClaim = from === result.start + 2;
        if (event.key === 'Backspace') {
          if (isAtStartOfClaim) {
            const removeNode = (tr: Transaction) => {
              const endPosToRemove = result.pos + result.node.nodeSize;
              view.dispatch(
                tr.setMeta(preventNodeDeletionPluginKey, {
                  allowAllNodesDeletion: true,
                })
              );
              return tr.delete(from, endPosToRemove);
            };
            tr.setMeta(preventNodeDeletionPluginKey, {
              allowAllNodesDeletion: false,
            });
            view.dispatch(removeNode(view.state.tr));
          }
        }
        return false;
      },
    },
  });

class ExpandablePluginView {
  view: EditorView;

  constructor(view: EditorView) {
    this.view = view;
  }
  init(view: EditorView) {
    const tr = view.state.tr;

    view.state.doc.descendants((node, pos) => {
      if (node.type.name === 'claimAdvantage') {
        const attrs = {
          ...node.attrs,
        };
        const resolvedPos = view && pos && view.state.doc.resolve(pos);
        if (resolvedPos) {
          let currentParentNode = null;

          for (let depth = resolvedPos.depth; depth > 0; depth--) {
            const parentNode = resolvedPos.node(depth);
            if (parentNode.type.name === 'claim') {
              currentParentNode = parentNode;
              break;
            }
          }
          if (currentParentNode) {
            attrs.claimNumber =
              currentParentNode.attrs.calculatedClaimNumber ||
              currentParentNode.attrs.claimNumber;
          }
        }

        tr.setNodeMarkup(pos, undefined, attrs);
        tr.setMeta('addToHistory', false);
      }
    });

    view.dispatch(tr);
  }

  update(editorView: EditorView, prevState: EditorState) {
    if (editorView.state.doc.eq(prevState.doc)) {
      return false;
    }
    this.init(editorView);
  }
}

export default claimAdvantagePlugin;
