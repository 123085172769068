import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../theme';
import { Spinner } from '../../Spinner';
import { HeaderButton } from '../../Layout';
import useTranslation from '../../../translations';

interface Props {
  isLoading: boolean;
}

const DownloadButton: React.FC<Props> = ({ isLoading }) => {
  const t = useTranslation();

  return (
    <StyledButton
      htmlType="submit"
      disabled={isLoading}
      label={
        <>
          {isLoading ? (
            <Spinner
              size="small"
              color={Colors.white87}
              style={{ marginRight: 4 }}
            />
          ) : (
            <Icon className="icn-download" />
          )}
          <span>{t('ACTION.DOWNLOAD')}</span>
        </>
      }
    />
  );
};

export default DownloadButton;

const Icon = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.colors.white70};
`;

const StyledButton = styled(HeaderButton)`
  height: 40px;
  padding: 8px 16px;
  min-width: 120px;
  font-size: 14px;
  font-weight: 400;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
