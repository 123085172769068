import React, { useContext, useMemo, useReducer } from 'react';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { queryBuilderReducer } from './QueryBuilder.reducer';
import { T, Api, Hooks } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { QueryKey } from 'queries';
import { Action, QueryBuilderAction } from 'types';
import useTranslation from 'translations';
import { useAppContext } from 'contexts';

const { useInvalidateQueries } = Hooks;

interface Props {
  tsdElementQuery?: T.TsdElementQuery;
  tsdElementBaseQuery?: T.TsdElementQuery;
  children?: React.ReactNode;
}

export interface ContextProps extends Props {
  dispatch: React.Dispatch<QueryBuilderAction>;
  saveQuery: () => Promise<void>;
  query?: T.TsdQuery;
  baseQuery?: T.TsdQuery;
  isSaving: boolean;
  hasUnsavedChanges: boolean;
}

const sanitizeQueryString = (value?: string) =>
  value && value.replace(/\\n|\\t|\\r|\s+/g, ' ');

const QueryContext = React.createContext<Props>({});

const QueryContextProvider: React.FC<Props> = ({
  tsdElementBaseQuery,
  tsdElementQuery,
  children,
}) => {
  const { applyChange } = useElementUsageCheck();
  const { preferredLanguage } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const [query, dispatch] = useReducer(
    queryBuilderReducer,
    tsdElementQuery as T.TsdQuery
  );

  const { invalidateQueries, isInvalidating } = useInvalidateQueries();
  const t = useTranslation();

  const createQueryMutation = useMutation(
    (q: T.CreateTsdElementQuery) => Api.tsd.createTsdElementQuery(q),
    {
      onSuccess: async (result: T.TsdElementQuery) => {
        dispatch({
          type: Action.UpdateQueryRequest,
          payload: result as T.TsdQuery,
        });
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementQueries, tsdElementId, tsdNodeId]
        );
        message.success(t('QUERY.SAVE_SUCCESS'));
      },
    }
  );

  const updateQueryMutation = useMutation(
    (query: T.TsdElementQuery) => Api.tsd.updateTsdElementQuery(query),
    {
      onSuccess: async (result: T.TsdElementQuery) => {
        dispatch({
          type: Action.UpdateQueryRequest,
          payload: result as T.TsdQuery,
        });
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementQueries, tsdElementId, tsdNodeId]
        );
        message.success(t('QUERY.UPDATE_SUCCESS'));
      },
    }
  );

  const saveQuery = (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!query) {
        reject();
      }
      const { isInExpertMode, googlePatentsQuery, patBaseQuery } =
        query as T.TsdQuery;

      const payloadQuery = isInExpertMode
        ? {
            ...query,
            googlePatentsQuery: sanitizeQueryString(googlePatentsQuery),
            patBaseQuery: sanitizeQueryString(patBaseQuery),
          }
        : query;

      const opts = {
        onSuccess: () => resolve(),
        onError: () => reject(),
      };

      if (!tsdElementQuery?.id) {
        applyChange(() =>
          createQueryMutation.mutate(
            {
              ...(payloadQuery as T.TsdQuery),
              tsdElementId,
              tsdNodeId,
            },
            opts
          )
        );
      } else {
        applyChange(() =>
          updateQueryMutation.mutate(
            {
              ...tsdElementQuery,
              ...(payloadQuery as T.TsdQuery),
            },
            opts
          )
        );
      }
    });

  const isSaving =
    createQueryMutation.isLoading ||
    updateQueryMutation.isLoading ||
    isInvalidating;

  const checkForUnsavedChanges = () =>
    !isSaving && JSON.stringify(tsdElementQuery) != JSON.stringify(query);

  const hasUnsavedChanges = checkForUnsavedChanges();

  const context = useMemo<ContextProps>(
    () => ({
      dispatch,
      isSaving,
      query,
      hasUnsavedChanges,
      saveQuery,
      baseQuery: tsdElementBaseQuery,
    }),
    [
      query,
      dispatch,
      saveQuery,
      isSaving,
      hasUnsavedChanges,
      tsdElementBaseQuery,
    ]
  );

  return (
    <QueryContext.Provider value={context}>{children}</QueryContext.Provider>
  );
};

export const useQueryContext = (): ContextProps => {
  const context = useContext(QueryContext) as ContextProps;

  if (!context) {
    throw new Error(
      `useQueryContext must be used within a QueryContextProvider`
    );
  }

  return context;
};

export default QueryContextProvider;
