import React from 'react';
import { Components, T } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationTsdLargestTrees } from 'queries';

const { Visualizations } = Components;
const { BarChart } = Visualizations;

const TsdLargestTreesBarChart: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationTsdLargestTrees();

  if (!data) {
    return null;
  }

  const config: T.BarChartConfig = {
    series: [{ name: 'Number of nodes' }],
    data: data ?? [],
    tooltip: {
      formatter: (v) => ({
        name: t('STATS.NUMBER_OF_NODES_TOOLTIP'),
        value: v.y,
      }),
    },
    xAxis: {
      title: t('STATS.NUMBER_OF_NODES'),
    },
    yAxis: {
      title: t('STATS.TSD_TREES'),
    },
  };

  return (
    <BarChart config={config} title={t('STATS.TOP10_LARGEST_TSD_TREES')} />
  );
};

export default TsdLargestTreesBarChart;
