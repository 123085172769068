import React, { useEffect, useRef } from 'react';
import { transparentize } from 'polished';
import styled, { CSSObject, css } from 'styled-components';

interface Props {
  isOpen: boolean;
  width?: number;
  zIndex?: number;
  showOverlay?: boolean;
  position?: string;
  drawerStyle?: CSSObject;
  overlayStyle?: CSSObject;
  overlayColor?: string;
  overlayTransparency?: string | number;
  destroyOnClose?: boolean;
  closeOnOverlayClick?: boolean;
  closeOnEsc?: boolean;
  onClose: () => void;
}

const SideDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  width = 600,
  zIndex = 1000,
  showOverlay = false,
  position = 'fixed',
  drawerStyle,
  overlayStyle,
  overlayColor,
  overlayTransparency = 0.4,
  destroyOnClose = true,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  children,
  onClose,
}) => {
  const wasOpenedRef = useRef<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      wasOpenedRef.current = true;
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen && closeOnEsc) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, closeOnEsc, onClose]);

  const handleOverlayClick = () => {
    if (isOpen && closeOnOverlayClick) {
      onClose();
    }
  };

  return (
    <>
      {showOverlay && (
        <BackgroundOverlay
          position={position}
          zIndex={zIndex}
          isOpen={isOpen}
          transparency={overlayTransparency}
          styleOverride={overlayStyle}
          color={overlayColor}
          onClick={handleOverlayClick}
        />
      )}
      <Outer
        width={width}
        position={position}
        zIndex={zIndex}
        isOpen={isOpen}
        styleOverride={drawerStyle}
      >
        {(isOpen || (!destroyOnClose && wasOpenedRef.current)) && children}
      </Outer>
    </>
  );
};

export default SideDrawer;

const Outer = styled.div<{
  width: number;
  position: string;
  zIndex: number;
  isOpen: boolean;
  styleOverride?: CSSObject;
}>`
  position: ${(props) => props.position};
  width: ${(props) => props.width}px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.zIndex};
  transition: transform 0.2s;
  pointer-events: all;
  transform: ${(props) =>
    props.isOpen ? 'translate(0)' : `translate(${props.width}px)`};
  box-shadow: ${(props) =>
    props.isOpen ? '-20px 0px 30px 30px rgb(0 0 0 / 17%)' : 'none'};
  background-color: ${(props) => props.theme.colors.gradient100};
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

const BackgroundOverlay = styled.div<{
  position: string;
  zIndex: number;
  isOpen: boolean;
  transparency: string | number;
  styleOverride?: CSSObject;
  color?: string;
}>`
  position: ${(props) => props.position};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${(props) => props.zIndex - 1};
  transition: background-color 0.5s;
  background-color: ${(props) =>
    props.isOpen
      ? transparentize(
          props.transparency,
          props.color || props.theme.colors.primary800
        )
      : 'transparent'};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;
