import React from 'react';
import styled from 'styled-components';
import { B2, Small } from '../../Atoms/Atoms';
import { AvatarCircle } from '../../Avatar';

interface Props {
  title?: string;
  subtitle?: string;
  style?: React.CSSProperties;
}

const ContactEntry: React.FC<Props> = ({ title, subtitle, style }) => (
  <Outer style={style}>
    <AvatarCircle withHighlight={false} size={30} displayName={title || '?'} />
    <Inner>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Inner>
  </Outer>
);

const Title = styled(B2)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white70};
  margin-bottom: 3px;
`;

const Subtitle = styled(Small)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white40};
`;

const Outer = styled.div`
  display: flex;
  align-items: center;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export default ContactEntry;
