import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/patent-publications';

export const getPublication = (
  patentPublicationId: string
): Promise<T.PatentPublication> =>
  Api.get(`${API_BASE}/${patentPublicationId}`).then(
    (response) => response.data
  );

export const extendPublicationData = (
  payload: T.ExtendPublicationData
): Promise<T.PatentPublication> =>
  Api.post<T.PatentPublication>(
    `${API_BASE}/${payload.patentPublicationId}/extend`,
    {
      ...payload,
    }
  ).then((response) => response.data);

export const importPatentPublications = (
  payload: T.PatentPublicationBatchImportRequest
): Promise<string> =>
  Api.post<string>(`${API_BASE}/import`, payload).then(
    (response) => response.data
  );

export const getImportProcess = (
  processId: string
): Promise<T.PatentPublicationBatchImportProcess> =>
  Api.get<T.PatentPublicationBatchImportProcess>(
    `${API_BASE}/import/${processId}`
  ).then((response) => response.data);
