import React, { KeyboardEvent, Ref, useRef, useState } from 'react';
import { Selection, Transaction } from 'prosemirror-state';
import styled from 'styled-components';
import { Vector } from '../../../../icons';
import useTranslation from '../../../../translations';
import { findParentNodeOfType } from '../../util';
import { FlatButton } from '../../../Button';
import { Icon } from '../../../Icon';
import { Colors } from '../../../../theme';
import { useReactNodeView } from './ReactNodeView';

const getIcon = (expanded: boolean) => (
  <Icon
    className={expanded ? 'icn-chevron-up' : 'icn-chevron-down'}
    size={18}
    color={Colors.charcoal100}
    style={{ marginTop: 2 }}
  />
);
interface Props {
  contentDOMRef?: Ref<HTMLDivElement>;
}

const ClaimAdvantage: React.FC<Props> = ({ contentDOMRef }) => {
  const t = useTranslation();
  const { view: editorView, node, getPos } = useReactNodeView();

  const [expanded, setExpanded] = useState<boolean>(node?.attrs?.expanded);
  const claimNumber = node?.attrs.claimNumber || '';
  const buttonRef = useRef<HTMLButtonElement>(null);

  // TODO getPos() causes sometimes node size issue
  const getPosition = () => {
    try {
      return getPos?.();
    } catch {}
  };

  const toggleNode = (): void => {
    const expandState = !expanded;
    setExpanded(expandState);

    if (!canToggleExpansion()) {
      return;
    }

    const pos = getPosition();

    if (!pos) {
      return;
    }

    const transaction = createToggleTransaction(expandState, pos);
    if (!transaction) {
      return;
    }
    applyTransaction(transaction);
  };

  const canToggleExpansion = (): boolean => !!editorView && !!getPos && !!node;

  const createToggleTransaction = (
    expandState: boolean,
    pos: number
  ): Transaction | undefined => {
    const newAttrs = { ...node?.attrs, expanded: expandState };
    const parentNodePosition = findParentNodePosition(expandState);
    const targetPos = calculateTargetPosition(
      expandState,
      pos,
      parentNodePosition
    );
    if (!editorView) {
      return;
    }
    if (!targetPos) {
      return;
    }
    let tr = editorView.state.tr.setNodeMarkup(pos, undefined, newAttrs);
    tr = tr.setSelection(Selection.near(tr.doc.resolve(targetPos), -1));

    return tr;
  };

  const findParentNodePosition = (expandState: boolean): number | undefined =>
    expandState
      ? undefined
      : editorView &&
        findParentNodeOfType(editorView?.state.schema.nodes.claimAdvantage)(
          editorView?.state.selection
        )?.pos;

  const calculateTargetPosition = (
    expandState: boolean,
    pos: number,
    parentNodePos?: number
  ): number | undefined => {
    if (expandState && node) {
      return pos + (node?.nodeSize - 1);
    } else {
      return parentNodePos ?? editorView?.state.selection.from;
    }
  };

  const applyTransaction = (tr: Transaction): void => {
    editorView?.dispatch(tr);
    editorView?.focus();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      toggleNode();
    }
  };

  return (
    <Outer>
      <StyledButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleNode();
        }}
        ref={buttonRef}
        onKeyDown={handleKeyDown}
        rightIcon={getIcon(expanded)}
        style={{ marginRight: 0 }}
      >
        {t('CLAIMS_PLUGIN.TECHNICAL_ADVANTAGES', { claimNumber })}
      </StyledButton>
      {expanded && (
        <Inner>
          <Vector />
        </Inner>
      )}
      <div
        ref={contentDOMRef}
        className="ProseMirror__contentDOM"
        style={{ display: expanded ? 'block' : 'none' }}
      />
    </Outer>
  );
};

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
  padding-left: 10px;
`;

export const StyledButton = styled(FlatButton)`
  border-radius: 4px;
  padding: 8px;
  color: ${(props) => props.theme.colors.charcoal100};
  font-size: 10px;
  font-style: italic;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  &:hover {
    color: ${(props) => props.theme.colors.charcoal100};
    background: ${(props) => props.theme.colors.black05};
  }
`;
export default ClaimAdvantage;
