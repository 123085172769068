import React, { useContext } from 'react';
import styled from 'styled-components';
import { AadUser } from '../../../types';
import { useAadUser } from '../../../queries';
import { truncate } from '../../../utils';
import useTranslation from '../../../translations';

const AadUserContext = React.createContext<AadUser | undefined>(undefined);

interface Props {
  userId?: string;
  loadingFallback?: React.ReactNode;
  errorFallback?: React.ReactNode;
  idleFallback?: React.ReactNode;
  defaultFallbacks?: boolean; // use standard default text fallbacks
}

const UserContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  userId,
  children,
  loadingFallback = null,
  errorFallback = null,
  idleFallback = null,
  defaultFallbacks = false,
}) => {
  const t = useTranslation();
  const {
    isLoading,
    isIdle,
    isError,
    data: user,
  } = useAadUser(userId as string, {
    enabled: userId != null,
  });

  const loadingDefaultFallback = defaultFallbacks ? (
    <Loading>{t('LOADING_USER_INFO')}</Loading>
  ) : null;

  const idleAndErrorDefaultFallback = defaultFallbacks ? (
    <Fallback>{t('UNKNOWN_USER')}</Fallback>
  ) : null;

  let content;

  switch (true) {
    case isLoading:
      content = loadingFallback || loadingDefaultFallback;
      break;
    case isIdle:
      content = idleFallback || idleAndErrorDefaultFallback;
      break;
    case isError:
      content = errorFallback || idleAndErrorDefaultFallback;
      break;
    case user != null:
      content = (
        <AadUserContext.Provider value={user}>
          {children}
        </AadUserContext.Provider>
      );
  }

  return <>{content}</>;
};

export default UserContextProvider;

export function useAadUserContext(): AadUser {
  const context = useContext(AadUserContext);
  if (!context) {
    throw new Error(
      `useAadUserContext must be used within AadUserContext.Provider`
    );
  }
  return context;
}

const Fallback = styled.div`
  font-size: 13px;
  font-weight: 400;
  ${truncate()}
  color: ${(props) => props.theme.colors.white70};
`;

const Loading = styled(Fallback)`
  font-style: italic;
`;
