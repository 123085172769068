import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import * as yup from 'yup';
import { Popover } from 'react-tiny-popover';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { document } from '../../../../api';
import {
  SearchDocuments,
  DocumentPickerItem,
  DocumentSearchResult,
} from '../../types';
import { queryClient } from '../../../../services/queryClient';
import { QueryKey } from '../../../../queries';
import useTranslation from '../../../../translations';
import { HeaderIcon } from '../../common';
import { useMultiDocContext } from '../../MultiDocContext';
import { DocumentPicker } from '../../DocumentPicker';
import SearchInput from './SearchInput';
import SearchButton from './SearchButton';
import { SearchResults } from './SearchResults';

const searchDocumentsSchema: yup.ObjectSchema<SearchDocuments> = yup.object({
  searchText: yup.string().trim().required(),
  documentIds: yup.array().of(yup.string().required()).min(1).required(),
  ignoreCase: yup.boolean().required(),
});

const MultiDocSearch: React.FC = () => {
  const t = useTranslation();
  const { documents, setSearchActive, openSearchInView } = useMultiDocContext();
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const searchParamsRef = useRef<SearchDocuments | null>(null);

  // We are taking a snapshot of documents on mount (when search is opened)
  // This is our document tree to perform search on
  const [documentItems, setDocumentItems] =
    useState<DocumentPickerItem[]>(documents);

  // Form is updated by input fields via form context
  const methods = useForm({
    defaultValues: {
      searchText: '',
      documentIds: [],
      ignoreCase: true,
    },
    resolver: yupResolver(searchDocumentsSchema),
  });

  const searchQuery = useQuery<DocumentSearchResult[], AxiosError>(
    QueryKey.SearchMultipleDocuments,
    async () => {
      if (!searchParamsRef.current) {
        throw new Error('Undefined search params!');
      }
      return document.searchDocuments(searchParamsRef.current);
    },
    { enabled: false }
  );

  const handleSubmit = async (formValues: SearchDocuments) => {
    if (searchQuery.isLoading) {
      return;
    }
    searchParamsRef.current = formValues;
    await queryClient.cancelQueries(QueryKey.SearchMultipleDocuments);
    searchQuery.remove();
    searchQuery.refetch({ cancelRefetch: true });
    setResultsOpen(true);
  };

  function findDocumentById(
    items: DocumentPickerItem[],
    id: string
  ): DocumentPickerItem | undefined {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.subItems && item.subItems.length > 0) {
        const subItem = findDocumentById(item.subItems, id);
        if (subItem) {
          return subItem;
        }
      }
    }
    return undefined;
  }

  const handleOpenSearchInView = (documentId: string, index: number) => {
    const searchText = searchParamsRef.current?.searchText;
    const documentMetadata = findDocumentById(
      documentItems,
      documentId
    )?.document;
    if (!searchText || !documentMetadata) {
      return;
    }
    openSearchInView(documentMetadata, index, searchText);
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(handleSubmit)}>
        <Text>{t('MULTI_DOC_SEARCH.SEARCH_FOR')}</Text>
        <Popover
          isOpen={resultsOpen}
          positions={['bottom']}
          align="start"
          containerStyle={{ marginTop: '1px' }}
          content={
            <SearchResults
              docItems={documentItems}
              searchQuery={searchQuery}
              onOpenSearchInView={handleOpenSearchInView}
              onClose={() => setResultsOpen(false)}
            />
          }
        >
          <Inner>
            <SearchInput />
            <Text>{t('MULTI_DOC_SEARCH.IN')}</Text>
            <DocumentPicker
              docItems={documentItems}
              onItemsChange={setDocumentItems}
              disableItemsWithNoTextLayer
            />
            <SearchButton isLoading={searchQuery.isLoading} />
            <HeaderIcon
              className="icn-clear"
              onClick={() => setSearchActive(false)}
            />
          </Inner>
        </Popover>
      </StyledForm>
    </FormProvider>
  );
};

export default MultiDocSearch;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white70};
`;
