import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../Icon';
import { Colors } from '../../../../theme';
import { Spinner } from '../../../Spinner';
import useTranslation from '../../../../translations';
import { PrimaryButton } from '../../../Button';
import { queryClient } from '../../../../services/queryClient';
import { QueryKey } from '../../../../queries';
import DocumentSearchStatus from './DocumentSearchStatus';
import useDocumentOcrProcessing from './useDocumentOcrProcessing';

interface Props {
  documentId: string;
  viewerIndex?: number;
}

const DocumentSearchOcr: React.FC<Props> = ({ documentId, viewerIndex }) => {
  const t = useTranslation();
  const {
    progress,
    isStartingOcrProcess,
    isProcessing,
    isError,
    fetchProcessErrorStatus,
    startOcrProcess,
  } = useDocumentOcrProcessing(documentId, () => {
    queryClient.invalidateQueries([
      QueryKey.DocumentMetadata,
      viewerIndex,
      documentId,
    ]);
  });

  let text;
  let spinner;
  let button;
  switch (true) {
    case fetchProcessErrorStatus != null && fetchProcessErrorStatus === 404:
      text = t('SINGLE_DOC_SEARCH.NO_TEXT_LAYER_DETECTED');
      button = true;
      break;
    case fetchProcessErrorStatus != null && fetchProcessErrorStatus !== 404:
      text = t('SINGLE_DOC_SEARCH.FETCH_OCR_PROCESS_ERROR');
      break;
    case isError:
      text = t('SINGLE_DOC_SEARCH.EXTRACTING_TEXT_ERROR');
      break;
    case isProcessing == null:
      text = t('SINGLE_DOC_SEARCH.FETCH_OCR_PROCESS');
      spinner = true;
      break;
    case isProcessing:
      text = t('SINGLE_DOC_SEARCH.EXTRACTING_TEXT', { progress });
      spinner = true;
  }

  return (
    <Outer>
      {text && <DocumentSearchStatus text={text} spinner={spinner} />}
      {button && (
        <StyledButton
          onClick={startOcrProcess}
          leftIcon={
            isStartingOcrProcess ? (
              <Spinner size="small" />
            ) : (
              <Icon className="icn-extract-text" color={Colors.black70} />
            )
          }
        >
          {t('SINGLE_DOC_SEARCH.EXTRACT_TEXT')}
        </StyledButton>
      )}
    </Outer>
  );
};

export default DocumentSearchOcr;

const Outer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(PrimaryButton)`
  min-width: 130px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black87};
  border: 2px solid ${(props) => props.theme.colors.black20};
  background: transparent;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
`;
