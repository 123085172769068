import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import cx from 'classnames';
import { NodeSelection } from 'prosemirror-state';
import { message } from 'antd';
import { EditorView } from 'prosemirror-view';
import { Node, Fragment, DOMSerializer } from 'prosemirror-model';
import useTranslation from '../../../../translations';
import { Spinner } from '../../../Spinner';
import { EMBEDDED_OBJECT, IMAGE } from '../../schema/nodes/nodeNames';
import { DIFF_DELETED_COLOR, DIFF_INSERTED_COLOR } from '../../styles/styles';
import { BlobResource, EditorContentType } from '../../../../types';
import { processImagesAndCopy } from '../../util';
import ImageInlineEditor, { ImageInlineEditorValue } from './ImageInlineEditor';
import ImageResizeBox from './ImageResizeBox';
import { observe, unobserve } from './ResizeObserver';
import resolveImage from './resolveImage';

import { getMaxResizeWidth, resolveURL } from './utils';
import {
  DEFAULT_EMPTY_SRC,
  DEFAULT_IMAGE_PLACEHOLDER_SIZE,
  DEFAULT_MAX_SIZE,
  DEFAULT_MIN_SIZE,
  DEFAULT_ORIGINAL_SIZE,
  MM_TO_PX_CONVERSION_FACTOR,
} from './defaults';
import ImageMenu from './ImageMenu';
import { processImage } from './processImage';

interface ImageViewProps {
  selected: boolean;
  isLoading: boolean;
  id: string;
  readOnly: boolean;
  editorView: EditorView;
  resetKey: number;
  editorType: EditorContentType;
  node: Node;
  docId?: string;
  isCleanCopy?: boolean;
  getPos: () => number | undefined;
  onFileChange: (selectedFiles: File[]) => void;
  onDownloadImage: () => void;
  onUploadFile: (docId: string, file: File) => Promise<BlobResource>;
}

const ImageNodeView: React.FC<ImageViewProps> = ({
  selected,
  id,
  readOnly,
  editorView,
  node,
  isLoading,
  resetKey,
  editorType,
  docId,
  isCleanCopy,
  getPos,
  onUploadFile,
  onFileChange,
  onDownloadImage,
}) => {
  const t = useTranslation();
  const [originalSize, setOriginalSize] = useState(DEFAULT_ORIGINAL_SIZE);
  const [maxSize, setMaxSize] = useState({
    width: DEFAULT_MAX_SIZE,
    height: DEFAULT_MAX_SIZE,
    complete: false,
  });

  const imageRef = useRef<HTMLImageElement | null>(null);
  const mountedRef = useRef(false);
  const [bodyRef, setBodyRef] = useState<HTMLSpanElement | null>(null);

  const { editable } = editorView;
  const { attrs } = node;
  const {
    align,
    crop,
    rotate,
    width: widthMm,
    height: heightMm,
    isDeleted,
    isPasted,
    isInserted,
    aspectRatio,
    objectSrc,
  } = attrs;

  const isBase64 = node.attrs.src && node.attrs.src.startsWith('data:image/');

  const processBase64Image = async () => {
    if (!mountedRef?.current) {
      return;
    }
    try {
      const pos = getPos();
      if (!docId || !pos) {
        return;
      }
      const { imageUri, objectUri } = await processImage(
        docId,
        node,
        onUploadFile
      );

      const nodeType = objectSrc
        ? editorView.state.schema.nodes[EMBEDDED_OBJECT]
        : editorView.state.schema.nodes[IMAGE];

      const newAttrs = {
        ...attrs,
        src: imageUri,
        width: widthMm / MM_TO_PX_CONVERSION_FACTOR,
        height: heightMm / MM_TO_PX_CONVERSION_FACTOR,
        aspectRatio: widthMm / heightMm,
        isInserted: true,
        objectSrc: objectUri,
        isPasted: true,
      };

      editorView.dispatch(
        editorView.state.tr
          .setNodeMarkup(pos, nodeType, newAttrs)
          .setMeta('resourceInsert', true)
      );
    } catch (error) {
      console.error('Error processing base64 image:', error);
      message.error(t('SOMETHING_WENT_WRONG'));
    }
  };

  useEffect(() => {
    if (!bodyRef) {
      return;
    }
    observe(bodyRef, _onBodyResize);
    return () => {
      unobserve(bodyRef);
    };
  }, [bodyRef]);

  useEffect(() => {
    mountedRef.current = true;
    if (isBase64) {
      processBase64Image();
    } else {
      _resolveOriginalSize();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [isBase64]);

  // It's only active when the image's fully loaded.
  const loading = originalSize === DEFAULT_ORIGINAL_SIZE;
  const active = !loading && selected && editable && originalSize.complete;
  const src = originalSize.complete ? originalSize.src : DEFAULT_EMPTY_SRC;
  const error = !loading && !originalSize.complete;

  //ACTIONS
  const enableActions = selected && !readOnly && !isDeleted;
  const enableEmbeddedObjectActions = (() => {
    const isEmbeddedObject = node.type.name === EMBEDDED_OBJECT;
    const isValidForEditor =
      editorType === EditorContentType.Claims ? !isPasted : true;
    return isEmbeddedObject && enableActions && isValidForEditor;
  })();
  const enableResizeBox = active && !crop && !rotate && !isDeleted;

  let height = heightMm * MM_TO_PX_CONVERSION_FACTOR;
  let width = widthMm * MM_TO_PX_CONVERSION_FACTOR;

  if (loading) {
    width = width || DEFAULT_IMAGE_PLACEHOLDER_SIZE;
    height = height || DEFAULT_IMAGE_PLACEHOLDER_SIZE;
  }

  if (width && !height) {
    height = width / aspectRatio;
  } else if (height && !width) {
    width = height * aspectRatio;
  } else if (!width && !height) {
    width = originalSize.width;
    height = originalSize.height;
  }

  let scale = 1;
  if (width > maxSize.width && (!crop || crop.width > maxSize.width)) {
    width = maxSize.width;
    height = width / aspectRatio;
    scale = maxSize.width / width;
  }

  const imageStyle: CSSObject = useMemo(
    () => ({
      display: 'inline-block',
      height: height + 'px',
      left: '0',
      top: '0',
      width: width + 'px',
      position: 'relative',
    }),
    [width, height]
  );

  const clipStyle: CSSObject = {};
  if (crop) {
    const cropped = { ...crop };
    if (scale !== 1) {
      scale = maxSize.width / cropped.width;
      cropped.width *= scale;
      cropped.height *= scale;
      cropped.left *= scale;
      cropped.top *= scale;
    }
    clipStyle.width = cropped.width + 'px';
    clipStyle.height = cropped.height + 'px';
    imageStyle.left = cropped.left + 'px';
    imageStyle.top = cropped.top + 'px';
  }

  if (rotate) {
    clipStyle.transform = `rotate(${rotate}rad)`;
  }

  const errorView = null;
  const errorTitle = error
    ? `Unable to load image from ${attrs.src || ''}`
    : undefined;

  const _resolveOriginalSize = async (): Promise<void> => {
    if (!mountedRef?.current) {
      return;
    }

    setOriginalSize(DEFAULT_ORIGINAL_SIZE);
    const src = node.attrs.src;
    const url = resolveURL(src);
    const originalSize = await resolveImage(url);
    if (!mountedRef?.current) {
      return;
    }
    if (node.attrs.src !== src) {
      return;
    }

    if (!originalSize.complete) {
      originalSize.width = DEFAULT_MIN_SIZE;
      originalSize.height = DEFAULT_MIN_SIZE;
    }
    setOriginalSize(originalSize);
  };

  const _onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>): void => {
    console.log(e.keyCode);
  };

  const _onResizeEnd = (width: number, height: number): void => {
    const pos = getPos();
    if (pos === undefined) {
      return;
    }
    const attrs = {
      ...node.attrs,
      crop: null,
      width: width / MM_TO_PX_CONVERSION_FACTOR,
      height: height / MM_TO_PX_CONVERSION_FACTOR,
      aspectRatio: width / height,
    };
    editorView.dispatch(editorView.state.tr.setNodeMarkup(pos, null, attrs));
  };

  const _onChange = (value: ImageInlineEditorValue): void => {
    if (!mountedRef?.current) {
      return;
    }

    const align = value ? value.align : null;
    const pos = getPos();
    if (pos === undefined) {
      return;
    }
    const attrs = {
      ...node.attrs,
      align,
    };

    let tr = editorView.state.tr;
    tr = tr.setNodeMarkup(pos, null, attrs);
    const newSelection = NodeSelection.create(tr.doc, pos);
    tr = tr.setSelection(newSelection);
    editorView.dispatch(tr);
  };

  const _onBodyResize = (): void => {
    const width = bodyRef ? getMaxResizeWidth(bodyRef) : DEFAULT_MAX_SIZE;
    setMaxSize({
      width,
      height: DEFAULT_MAX_SIZE,
      complete: !!bodyRef,
    });
  };

  const handleCopyImage = () => {
    const newSlice = Fragment.fromArray([node]);
    const serializer = DOMSerializer.fromSchema(editorView.state.schema);
    const div = document.createElement('div');
    serializer.serializeFragment(newSlice, { document }, div);
    const text = newSlice.textBetween(0, newSlice.size, '\n');
    processImagesAndCopy(div, text);
  };

  const className = cx('image-view-body', {
    active,
    error,
    loading,
    selected,
    hidden: isCleanCopy && isDeleted,
    downloading: isLoading,
    pasted: isPasted && editorType === EditorContentType.Description,
  });

  const loader = isLoading && (
    <ProgressWrap>
      <Spinner />
    </ProgressWrap>
  );

  const actionsMenu = enableActions && (
    <ImageMenu
      selected={selected}
      loading={isLoading}
      resetKey={resetKey}
      enableEmbeddedObjectActions={enableEmbeddedObjectActions}
      onCopy={handleCopyImage}
      onFileChange={onFileChange}
      onDownload={onDownloadImage}
    />
  );

  return (
    <span
      ref={setBodyRef}
      className={className}
      data-active={active ? 'true' : undefined}
      data-original-src={String(attrs.src)}
      data-id={id}
      onKeyDown={_onKeyDown}
      title={errorTitle}
    >
      {actionsMenu}
      <span className="image-view-body-img-clip" style={clipStyle}>
        {loader}
        <ImageInlineEditor
          value={node.attrs}
          onSelect={_onChange}
          anchor={bodyRef}
          active={enableActions}
        >
          <span style={imageStyle}>
            <Image
              alt=""
              ref={imageRef}
              data-align={align}
              height={height}
              id={`${id}-img`}
              src={src}
              width={width}
              isDeleted={isDeleted}
              isInserted={isInserted}
              isCleanCopy={isCleanCopy}
              editorType={editorType}
            />

            {errorView}
          </span>
        </ImageInlineEditor>
      </span>
      {enableResizeBox && (
        <ImageResizeBox
          height={height}
          onResizeEnd={_onResizeEnd}
          src={src}
          width={width}
        />
      )}
    </span>
  );
};

const getBorderStyle = (
  isInserted: boolean | null,
  isDeleted: boolean | null,
  editorType: EditorContentType,
  isCleanCopy?: boolean
) => {
  switch (true) {
    case isCleanCopy || editorType === EditorContentType.Description:
      return '';
    case isInserted:
      return `border: 1px solid ${DIFF_INSERTED_COLOR};`;
    case isDeleted:
      return `border: 1px solid ${DIFF_DELETED_COLOR};`;
    default:
      return '';
  }
};

const Image = styled.img<{
  isDeleted: boolean | null;
  isInserted: boolean | null;
  editorType: EditorContentType;
  isCleanCopy?: boolean;
}>`
  ${(props) =>
    getBorderStyle(
      props.isInserted,
      props.isDeleted,
      props.editorType,
      props?.isCleanCopy
    )};
`;

export const ProgressWrap = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;

  & .ant-progress-text {
    color: ${(props) => props.theme.colors.black};
  }
`;

export default ImageNodeView;
