import React from 'react';
import styled from 'styled-components';
import ImageResizeBoxControl, {
  Direction,
  ResizeDirection,
} from './ImageResizeBoxControl';

type Props = {
  height: number;
  onResizeEnd: (w: number, height: number) => void;
  src: string;
  width: number;
};

const ImageResizeBox: React.FC<Props> = ({
  onResizeEnd,
  width,
  height,
  src,
}) => {
  const style = {
    height: height + 'px',
    width: width + 'px',
  };

  const controls = Object.keys(ResizeDirection).map((key) => (
    <ImageResizeBoxControl
      direction={key as Direction}
      height={height}
      key={key}
      onResizeEnd={onResizeEnd}
      width={width}
    />
  ));

  return (
    <span className="image-resize-box" style={style}>
      {controls}
      <ResizeBoxImage src={src} />
    </span>
  );
};

const ResizeBoxImage = styled.img`
  background-color: #fff;
  height: 100%;
  left: 0;
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export default ImageResizeBox;
