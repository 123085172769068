import React from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
import { UserContextProvider, UserAvatar } from '../User';

interface Props {
  userIds: string[];
  avatarSize?: number;
  maxAvatarCount?: number;
}

const UserAvatars: React.FC<React.PropsWithChildren<Props>> = ({
  userIds,
  avatarSize,
  maxAvatarCount = 5,
}) => {
  let moreAvatars;

  if (userIds.length > maxAvatarCount) {
    const content = (
      <Rest>
        {userIds.slice(maxAvatarCount).map((id) => (
          <UserContextProvider key={id} userId={id}>
            <UserAvatar
              style={{ margin: '0 5px 5px 0' }}
              avatarSize={avatarSize}
            />
          </UserContextProvider>
        ))}
      </Rest>
    );

    moreAvatars = (
      <Popover content={content} placement="bottomRight" trigger="hover">
        <More>+{userIds.length - maxAvatarCount}</More>
      </Popover>
    );
  }

  return (
    <Outer>
      {userIds.slice(0, maxAvatarCount).map((id) => (
        <UserContextProvider key={id} userId={id}>
          <UserAvatar style={{ marginLeft: -8 }} avatarSize={avatarSize} />
        </UserContextProvider>
      ))}
      {moreAvatars}
    </Outer>
  );
};

export default UserAvatars;

const Outer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Rest = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 180px;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  text-transform: lowercase;
  transition: color 0.2s ease-in;
  color: ${(props) => props.theme.colors.white70};
  &:hover {
    color: ${(props) => props.theme.colors.secondary300};
  }
`;
