import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { setCellAttr } from 'prosemirror-tables';

import { SyntheticEvent } from 'react';
import UICommand from '../UICommand';

const setCellBackgroundBlack = setCellAttr('background', '#000000');

class TableBackgroundColorCommand extends UICommand {
  shouldRespondToUIEvent = (e: SyntheticEvent | MouseEvent): boolean =>
    e.type === UICommand.EventType.MOUSEENTER;

  isEnabled = (state: EditorState): boolean => setCellBackgroundBlack(state);

  executeWithUserInput = (
    state: EditorState,
    hex: string,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView
  ): boolean => {
    if (dispatch && hex !== undefined) {
      const cmd = setCellAttr('background', hex);
      cmd(state, dispatch, view);
      return true;
    }
    return false;
  };
}

export default TableBackgroundColorCommand;
