import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Tag } from 'antd';
import { TsdNode } from '../../types';
import useTranslation from '../../translations';

type TsdTreeHeplers = {
  findNodeById: (id: string) => FindNodeResult;
  getAllParentNodeIds: (nodes: TsdNode[]) => Set<string>;
  getBranchNodeIds: (node: TsdNode) => string[];
  generateConfirmMessage: (
    draggedNode: TsdNode,
    parentNodeId: string
  ) => React.ReactNode;
};

export type FindNodeResult = {
  targetNode: TsdNode | undefined;
  parentsToRoot: TsdNode[];
};

const useTsdTreeHelpers = (tree: TsdNode[]): TsdTreeHeplers => {
  const t = useTranslation();

  const findNodeById = useCallback(
    (id: string): FindNodeResult => {
      const parentNodes: TsdNode[] = [];
      let targetNode: TsdNode | undefined = undefined;

      function dfs(nodes: TsdNode[], level = 0): void {
        for (let i = 0; i < nodes.length && !targetNode; i++) {
          parentNodes.splice(level);
          const node = nodes[i];
          if (node.id === id) {
            targetNode = node;
          }
          if (node.children.length && !targetNode) {
            parentNodes.push(node);
            dfs(node.children, level + 1);
          }
        }
      }

      dfs(tree);
      const parentsToRoot = parentNodes.slice().reverse();
      return { targetNode, parentsToRoot };
    },
    [tree]
  );

  const getAllParentNodeIds = useCallback(
    (tsdNodes: TsdNode[]): Set<string> => {
      const parentNodeIds = new Set<string>();

      function extractParentNodeIds(nodes: TsdNode[]): void {
        nodes.forEach((node) => {
          if (node.children.length) {
            parentNodeIds.add(node.id);
            extractParentNodeIds(node.children);
          }
        });
      }

      extractParentNodeIds(tsdNodes);
      return parentNodeIds;
    },
    []
  );

  const getBranchNodeIds = useCallback((tsdNode: TsdNode): string[] => {
    const branchNodeIds = [tsdNode.id];

    function extractChildNodeIds(nodes: TsdNode[]): void {
      nodes.forEach((node) => {
        branchNodeIds.push(node.id);
        if (node.children.length) {
          extractChildNodeIds(node.children);
        }
      });
    }

    extractChildNodeIds(tsdNode.children);
    return branchNodeIds;
  }, []);

  const generateConfirmMessage = useCallback(
    (draggedNode: TsdNode, parentNodeId: string): React.ReactNode => {
      const draggedNodeName = draggedNode.name;
      const parentNodeName = findNodeById(parentNodeId).targetNode?.name;

      const messageKey =
        draggedNode.parentNodeId === parentNodeId
          ? 'TSD_TREE.MOVE_NODE.SAME_PARENT'
          : 'TSD_TREE.MOVE_NODE.NEW_PARENT';

      return (
        <Trans
          t={t}
          i18nKey={messageKey}
          values={{ draggedNodeName, parentNodeName }}
          components={{
            tag: <StyledTag />,
          }}
        />
      );
    },
    [findNodeById]
  );

  return {
    findNodeById,
    getAllParentNodeIds,
    getBranchNodeIds,
    generateConfirmMessage,
  };
};

export default useTsdTreeHelpers;

const StyledTag = styled(Tag)`
  white-space: pre-wrap;
  display: inline;
  margin-left: 5px;
  padding: 2px 7px;
`;
