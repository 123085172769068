import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { BlobResource, ImagePluginSettings } from '../../../../types';
import { canInsert } from '../../util';
import { dataURIToFile, startImageUpload } from './utils';

function hasParent(element: HTMLElement, parent: HTMLElement): boolean {
  // Traverse up the DOM tree, checking if the element's parent has the given tag name
  while (element.parentNode) {
    element = element.parentNode as HTMLElement;
    if (element === parent) {
      return true;
    }
  }
  // If we reach the top of the DOM tree and haven't found the parent, return false
  return false;
}

export default (
    pluginSettings: ImagePluginSettings,
    schema: Schema,
    editorDocumentId: string,
    uploadFunction: (id: string, file: File) => Promise<BlobResource>
  ) =>
  (view: EditorView, event: DragEvent) => {
    if (
      event.target &&
      hasParent(event.target as HTMLElement, event.currentTarget as HTMLElement)
    ) {
      return;
    }
    if (!canInsert(view.state, schema.nodes.image)) {
      return false;
    }
    const textData = event?.dataTransfer?.getData('text/html');
    const file = event?.dataTransfer?.files?.[0];
    const posData = view.posAtCoords({
      top: event.clientY,
      left: event.clientX,
    });
    // The dropped data is HTML content
    if (textData && posData) {
      const container = document.createElement('div');
      container.innerHTML = textData;
      const firstChild = container.children[0];
      if (
        // The dragging comes from ProseMirror, in that case let ProseMirror handle the event.
        !(firstChild instanceof HTMLImageElement) ||
        firstChild.dataset.pmSlice
      ) {
        return false;
      }
      if (
        container.children.length === 1 &&
        firstChild instanceof HTMLImageElement
      ) {
        const fileFromHTML = dataURIToFile(
          firstChild.src,
          encodeURIComponent(firstChild.alt || 'dragged image')
        );
        startImageUpload(
          view,
          fileFromHTML,
          'dragged image',
          pluginSettings,
          schema,
          uploadFunction,
          editorDocumentId,
          posData.pos
        );
      }
      event.preventDefault();
      event.stopPropagation();
      return true;
    }
    // The dropped data is image dataURI
    if (file && posData) {
      startImageUpload(
        view,
        file,
        'dragged image',
        pluginSettings,
        schema,
        uploadFunction,
        editorDocumentId,
        posData.pos
      );
      event.preventDefault();
      event.stopPropagation();
      return true;
    }
    return false;
  };
