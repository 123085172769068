import { Transaction, EditorState } from 'prosemirror-state';
import { Node, NodeType } from 'prosemirror-model';
import { v4 as uuidv4 } from 'uuid';
import { schema } from '../schema/schema';
const isTargetNodeOfType = (node: Node, type: NodeType) => node.type === type;

const nodeHasAttribute = (node: Node, attrName: string) =>
  Boolean(node.attrs && node.attrs[attrName]);

const attrName = 'nodeId';

function changedDescendants(
  old: Node,
  cur: Node,
  offset: number,
  f: (node: Node, pos: number) => void
): void {
  const oldSize = old.childCount,
    curSize = cur.childCount;
  outer: for (let i = 0, j = 0; i < curSize; i++) {
    const child = cur.child(i);
    for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
      if (old.child(scan) == child) {
        j = scan + 1;
        offset += child.nodeSize;
        continue outer;
      }
    }
    f(child, offset);
    if (j < oldSize && old.child(j).sameMarkup(child)) {
      changedDescendants(old.child(j), child, offset + 1, f);
    } else {
      child.nodesBetween(0, child.content.size, f, offset + 1);
    }
    offset += child.nodeSize;
  }
}

export const uniqueNodeId = (
  tr: Transaction,
  prevState: EditorState,
  nextState: EditorState
) => {
  const UNIQUE_NODE_IDS = new Set();
  // Adds a unique id to a node
  changedDescendants(prevState.doc, nextState.doc, 0, (node, pos) => {
    const { paragraph, section, list, heading, table } = schema.nodes;
    const doesGuidExist = UNIQUE_NODE_IDS.has(node.attrs[attrName]);

    if (nodeHasAttribute(node, attrName)) {
      UNIQUE_NODE_IDS.add(node.attrs[attrName]);
    }
    if (
      (isTargetNodeOfType(node, paragraph) ||
        isTargetNodeOfType(node, list) ||
        isTargetNodeOfType(node, heading) ||
        isTargetNodeOfType(node, table) ||
        isTargetNodeOfType(node, section)) &&
      (doesGuidExist || node.attrs.nodeId == null)
    ) {
      const attrs = node.attrs;
      const newId = uuidv4();
      UNIQUE_NODE_IDS.add(newId);
      tr.setNodeMarkup(pos, undefined, {
        ...attrs,
        [attrName]: newId,
      });
    }
  });

  return tr;
};

export default uniqueNodeId;
