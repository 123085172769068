import React, { useState } from 'react';

import styled, { CSSObject, css } from 'styled-components';
import { IconButton } from '../Button';
import { B2 } from '../Atoms/Atoms';

interface Props {
  title: string;
  nrOfFields?: number;
  style?: CSSObject;
  arrowIconStyle?: CSSObject;
  panelHeaderStyle?: CSSObject;
  fieldGroupTitleStyle?: CSSObject;
  fieldGroupChildrenStyle?: CSSObject;
  numberFieldStyle?: CSSObject;
}

const CollapsablePanel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  nrOfFields,
  style,
  arrowIconStyle,
  panelHeaderStyle,
  fieldGroupTitleStyle,
  fieldGroupChildrenStyle,
  numberFieldStyle,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <StyledFieldGroup collapsed={collapsed} styleOverride={style}>
      <StyledPanelHeader styleOverride={panelHeaderStyle}>
        <Flex>
          <StyledFieldGroupTitle styleOverride={fieldGroupTitleStyle}>
            {title}
          </StyledFieldGroupTitle>
          {!!nrOfFields && (
            <StyledNumberField styleOverride={numberFieldStyle}>
              {nrOfFields}
            </StyledNumberField>
          )}
        </Flex>
        <IconButton
          className={`icn-chevron-${collapsed ? 'down' : 'up'}`}
          icon={ArrowIcon}
          onClick={(e) => {
            e.preventDefault();
            setCollapsed((prev) => !prev);
          }}
          style={arrowIconStyle}
        />
      </StyledPanelHeader>
      {React.Children.map(children, (child, index) => (
        <StyledFieldGroupChildren
          styleOverride={fieldGroupChildrenStyle}
          key={index}
        >
          {child}
        </StyledFieldGroupChildren>
      ))}
    </StyledFieldGroup>
  );
};

const Flex = styled.div`
  display: flex;
  gap: 8px;
`;
const ArrowIcon = styled.i`
  font-size: 23px;
  cursor: pointer;
`;

const StyledPanelHeader = styled.div<{ styleOverride?: CSSObject }>`
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 13px 6px 15px;
  background: transparent;
  border-radius: 6px;
  height: 48px;
  &:hover {
    background: ${(props) => props.theme.colors.primary700};
  }
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

const StyledFieldGroupTitle = styled(B2)<{ styleOverride?: CSSObject }>`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  height: 50%;
  color: ${(props) => props.theme.colors.white70};
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

const StyledFieldGroup = styled.div<{
  collapsed: boolean;
  styleOverride?: CSSObject;
}>`
  overflow: hidden;
  border-radius: 6px;
  transition: height 0.2s;
  flex-shrink: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  background: transparent;
  ${(props) =>
    props.collapsed &&
    css`
      background: transparent;
      height: 48px;
    `};
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

const StyledFieldGroupChildren = styled.div<{
  styleOverride?: CSSObject;
}>`
  padding: 6px 13px 3px 15px;
  background: ${(props) => props.theme.colors.primary700};
  margin-top: 10px;
  height: auto;
  border-radius: 6px;
  border: solid 2px transparent;
  &:focus-within {
    border-color: ${(props) => props.theme.colors.blue700};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary700_hover};
  }
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

const StyledNumberField = styled.span<{ styleOverride?: CSSObject }>`
  color: ${(props) => props.theme.colors.white87};
  background: ${(props) => `${props.theme.colors.primary200_40}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 11px;
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

export default CollapsablePanel;
