import React from 'react';
import styled from 'styled-components';
import { Property } from 'csstype';
import { truncate } from '../../../utils';
import { HeaderBackProps, HeaderNavItemProps } from '../../../types';
import { Logo } from '../Navigation';
import { NavDropdown } from './NavDropdown';
import HeaderBack from './HeaderBack';

const LOGO_COMPACT_STYLE = {
  height: 40,
  width: 40,
  marginBottom: -10,
  marginRight: -10,
};

export interface Props {
  title?: string;
  subtitle?: string;
  description?: string;
  children?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  justifyContent?: Property.JustifyContent;
  leftContent?: React.ReactNode;
  backProps?: HeaderBackProps;
  navItems?: HeaderNavItemProps[];
  logo?: boolean;
  compact?: true;
}

const AppHeader: React.FC<Props> = ({
  title,
  subtitle,
  description,
  children,
  contentStyle,
  justifyContent = 'space-between',
  leftContent,
  backProps,
  navItems,
  logo = false,
  compact = undefined,
}) => (
  <Outer $compact={compact}>
    <Left>
      {logo && (
        <LogoWrapper>
          <Logo style={compact && LOGO_COMPACT_STYLE} />
        </LogoWrapper>
      )}
      {backProps && <HeaderBack {...backProps} />}
      {title && <Title>{title}</Title>}
      {subtitle && (
        <>
          {!!title && <Separator style={{ marginRight: compact ? 10 : 20 }} />}
          <Subtitle>
            <SubtitleText>{subtitle}</SubtitleText>
            {description && (
              <SubtitleDescription>{description}</SubtitleDescription>
            )}
          </Subtitle>
        </>
      )}
      {navItems && (
        <>
          {!!(title || subtitle) && <Separator />}
          <NavDropdown items={navItems} />
        </>
      )}
      {leftContent}
    </Left>
    <Content style={{ justifyContent, ...contentStyle }}>{children}</Content>
  </Outer>
);

export default AppHeader;

const Outer = styled.div<{ $compact?: boolean }>`
  display: flex;
  height: 80px;
  padding: 0 20px 0 30px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #1c1e28;
  ${(props) =>
    props.$compact &&
    `
  height: 50px;
  ${Title} {
    font-size: 18px;
    margin-right: 10px;
  }
  ${SubtitleText} { 
    font-size: 18px;
  }
  ${Separator} {
    height: 20px;
  }
  `}
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 20px;
  line-height: 1.45;
  margin-right: 20px;
  color: ${(props) => props.theme.colors.white100};
`;

const Subtitle = styled.span`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: 400px;
`;

const SubtitleText = styled.span`
  font-size: 20px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.white100};
  ${truncate()}
`;

const SubtitleDescription = styled.span`
  font-size: 13px;
  line-height: 1.54;
  color: ${(props) => props.theme.colors.white50};
  ${truncate()}
`;

const Separator = styled.div`
  border-right: 2px solid ${(props) => props.theme.colors.white20};
  height: 30px;
`;

const LogoWrapper = styled.div`
  margin-right: 40px;
`;
