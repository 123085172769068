import React from 'react';
import styled from 'styled-components';
import TsdElementList from './List/TsdElementList';
import { Components } from '@ipos/shared';
import {
  MostUsedCpcClassesColumnChart,
  MostUsedCompanyGroupsRadialBarChart,
  MostUsedTsdElementsRadialBarChart,
} from 'components/Visualizations';
import useTranslation from 'translations';

const { Layout } = Components;
const { Grid, GridItem, AppHeader } = Layout;

const TsdElements: React.FC = () => {
  const t = useTranslation();

  return (
    <>
      <AppHeader title={t('HEADER.TITLE.TSD_ELEMENT_LIBRARY')} />
      <Grid>
        <GridItem position={[1, 8, 1, 7]}>
          <TsdElementList />
        </GridItem>
        <GridItem position={[1, 4, 8, 5]}>
          <Flex>
            <MostUsedCompanyGroupsRadialBarChart />
            <MostUsedTsdElementsRadialBarChart />
          </Flex>
        </GridItem>
        <GridItem position={[5, 4, 8, 5]}>
          <MostUsedCpcClassesColumnChart />
        </GridItem>
      </Grid>
    </>
  );
};

export default TsdElements;

const Flex = styled.div`
  display: flex;
  height: 100%;
`;
