import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Button, Input, message } from 'antd';
import { FormProvider } from 'react-hook-form';
import useTsdElementForm from './useTsdElementForm';
import TsdElementNameInput from './TsdElementNameInput';
import { T, Components, Api } from '@ipos/shared';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { FormField, LanguagePicker } = Components.Form;
const { TextArea } = Input;

interface Props {
  isTsdContext?: boolean;
  isParentSubmitting?: boolean;
  onCreateSuccess: (tsdElementId: string) => void;
  onAddExistingElement: (tsdElementId: string) => void;
  onCancel: () => void;
}

const SaveTsdElementForm: React.FC<Props> = ({
  isTsdContext = false,
  isParentSubmitting = false,
  onCreateSuccess,
  onAddExistingElement,
  onCancel,
}) => {
  const t = useTranslation();
  const { languages, preferredLanguage } = useAppContext();
  const { methods, resetForm } = useTsdElementForm(preferredLanguage);
  const [selectedElementId, setSelectedElementId] = useState<string>('');
  const isElementSelected = selectedElementId !== '';

  const createTsdElementMutation = useMutation(
    (newTsdElement: T.CreateTsdElement) =>
      Api.tsd.createTsdElement(newTsdElement),
    {
      onSuccess: (tsdElement) => {
        message.success(
          t('CREATE_ELEMENT.CREATE_SUCCESS', { elementName: tsdElement.name })
        );
        onCreateSuccess(tsdElement.id);
      },
    }
  );

  const handleSubmit = (values: T.CreateTsdElement) => {
    if (selectedElementId) {
      onAddExistingElement(selectedElementId);
    } else {
      createTsdElementMutation.mutate(values);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Inner>
          {isTsdContext ? (
            <TsdElementNameInput
              name="synonym"
              elementName={methods.watch().synonym}
              isElementSelected={isElementSelected}
              onFormReset={resetForm}
              onElementSelect={setSelectedElementId}
            />
          ) : (
            <FormField
              name="synonym"
              label={t('CREATE_ELEMENT.NAME')}
              component={Input}
              style={{ flex: 1 }}
              autoComplete="off"
              autoFocus
            />
          )}
          <FormField
            name="language"
            label={t('CREATE_ELEMENT.LANGUAGE')}
            component={LanguagePicker}
            options={languages}
            disabled={isElementSelected}
            style={{ width: 150 }}
          />
        </Inner>
        <FormField
          name="description"
          label={t('CREATE_ELEMENT.DESCRIPTION')}
          component={TextArea}
          rows={8}
          disabled={isElementSelected}
        />
        <Footer>
          <Button onClick={onCancel}>{t('ACTION.CANCEL')}</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createTsdElementMutation.isLoading || isParentSubmitting}
          >
            {t(
              isTsdContext && isElementSelected ? 'ACTION.ADD' : 'ACTION.CREATE'
            )}
          </Button>
        </Footer>
      </Form>
    </FormProvider>
  );
};

export default SaveTsdElementForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Inner = styled.div`
  display: flex;
  gap: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
