import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import {
  message,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popover,
} from 'antd';
import { InferType } from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api, Components, T, Hooks } from '@ipos/shared';
import { addTsdElementSynonymSchema } from 'validations';
import { QueryKey } from 'queries';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { Icon } = Components;
const { useInvalidateQueries } = Hooks;
const { FormField, LanguagePicker } = Components.Form;

type FormValues = InferType<typeof addTsdElementSynonymSchema>;

interface Props {
  tsdElementSynonym?: T.TsdElementSynonym;
  onCloseRequest: () => void;
}

const AddSynonymModal: React.FC<Props> = ({
  tsdElementSynonym,
  onCloseRequest,
}) => {
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { languages, preferredLanguage } = useAppContext();
  const t = useTranslation();
  const { applyChange } = useElementUsageCheck();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();

  const isEditing = tsdElementSynonym != null;
  const methods = useForm({
    defaultValues: {
      value: tsdElementSynonym?.value ?? '',
      isPrimary: tsdElementSynonym?.isPrimary ?? false,
      language: tsdElementSynonym?.language ?? preferredLanguage,
    },
    resolver: yupResolver(addTsdElementSynonymSchema),
  });

  const handleError = (
    notificationMessage: string,
    notificationDescription: string
  ): void => {
    notification.error({
      message: notificationMessage,
      description: notificationDescription,
    });
  };

  const updateTsdElementMutation = useMutation(
    (values: T.UpdateTsdElementSynonym) =>
      Api.tsd.updateTsdElementSynonym(values),
    {
      onSuccess: async () => {
        message.success(t('SYNONYMS.UPDATE_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementSynonyms, tsdElementId, tsdNodeId]
        );
      },
      onError: () => {
        handleError(t('ATTRIBUTES.UPDATE_FAIL'), t('SYNONYMS.UPDATE_FAIL'));
      },
    }
  );

  const createTsdElementMutation = useMutation(
    (values: T.CreateTsdElementSynonym) => Api.tsd.addTsdElementSynonym(values),
    {
      onSuccess: async () => {
        message.success(t('SYNONYMS.CREATE_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementSynonyms, tsdElementId, tsdNodeId]
        );
      },
    }
  );

  const handleSubmit = (values: FormValues) => {
    applyChange(() => {
      if (tsdElementSynonym != null) {
        updateTsdElementMutation.mutate(
          { ...values, tsdElementId, id: tsdElementSynonym.id },
          {
            onSuccess: () => {
              invalidateQueries([
                QueryKey.TsdElementQueries,
                tsdElementId,
                tsdNodeId,
              ]);
              onCloseRequest();
            },
            onError: () => {
              handleError(
                t('ATTRIBUTES.CREATE_FAIL'),
                t('SYNONYMS.CREATE_FAIL')
              );
            },
          }
        );
      } else {
        createTsdElementMutation.mutate(
          { ...values, tsdNodeId, tsdElementId },
          {
            onSuccess: () => {
              onCloseRequest();
            },
            onError: () => {
              handleError(
                t('ATTRIBUTES.CREATE_FAIL'),
                t('SYNONYMS.CREATE_FAIL')
              );
            },
          }
        );
      }
    });
  };

  return (
    <Modal
      width={600}
      onCancel={onCloseRequest}
      destroyOnClose
      title={t(
        isEditing ? 'SYNONYMS.EDIT_SYNONYM_TITLE' : 'SYNONYMS.ADD_SYNONYM_TITLE'
      )}
      open={true}
      footer={null}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Inner>
            <FormField
              name="language"
              label={t('SYNONYMS.LANGUAGE')}
              options={languages}
              component={LanguagePicker}
              style={{ width: 130 }}
            />
            <FormField
              name="value"
              label={t('SYNONYMS.SYNONYM')}
              component={Input}
              style={{ flex: 1 }}
              autoComplete="off"
              autoFocus
            />
          </Inner>
          {tsdNodeId == null && (
            <FormField name="isPrimary" component={Checkbox}>
              {t('SYNONYMS.IS_PRIMARY')}
              <Popover
                content={
                  <div style={{ width: 150 }}>
                    {t('SYNONYMS.PRIMARY_SYNONYM_DESCRIPTION')}
                  </div>
                }
              >
                <Icon
                  className="icn-info-outlined"
                  size={20}
                  style={{ marginLeft: 5 }}
                />
              </Popover>
            </FormField>
          )}
          <Footer>
            <Button onClick={onCloseRequest}>{t('ACTION.CANCEL')}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                createTsdElementMutation.isLoading ||
                updateTsdElementMutation.isLoading ||
                isInvalidating
              }
            >
              {t(isEditing ? 'SYNONYMS.EDIT_SYNONYM' : 'SYNONYMS.ADD_SYNONYM')}
            </Button>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddSynonymModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Inner = styled.div`
  display: flex;
  gap: 15px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
`;
