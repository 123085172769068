import React from 'react';
import styled from 'styled-components';
import useTranslation from 'translations';

const ClassesListHeader: React.FC = () => {
  const t = useTranslation();
  return <Outer>{t(`QUERY.TRUNCATION`)}</Outer>;
};

export default ClassesListHeader;

const Outer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;
