import React from 'react';
import { CSSObject } from 'styled-components';
import { Avatar } from '../../Avatar';
import { useAadUserContext } from './UserContextProvider';

interface Props {
  avatarSize?: number;
  withPopover?: boolean;
  style?: CSSObject;
}

const UserAvatar: React.FC<Props> = ({
  avatarSize = 30,
  withPopover = true,
  style,
}) => {
  const user = useAadUserContext();

  return (
    <Avatar
      user={user}
      withPopover={withPopover}
      avatarSize={avatarSize}
      style={style}
    />
  );
};

export default UserAvatar;
