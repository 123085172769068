import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import useTranslation from '../../../../translations';
import {
  useDownloadDescriptionObject,
  useUploadDescriptionOleObject,
} from '../../../../mutations';
import {
  EditorContentType,
  UploadDescriptionEditorOleResponse,
} from '../../../../types';

import { Api } from '../../../../..';
import type { NodeViewProps } from './ReactNodeView';
import { useReactNodeView } from './ReactNodeView';
import { findParentNodeIdBeforeSection, getNodeAttrValue } from './utils';
import ImageNodeView from './ImageNodeView';

enum ImageAction {
  Download = 'Download',
  Replace = 'Replace',
}

interface ImageViewDescriptionProps extends NodeViewProps {
  readOnly: boolean;
}

const ImageViewDescription: React.FC<ImageViewDescriptionProps> = ({
  selected,
  id,
  editorDocumentId,
  readOnly,
}) => {
  const { view: editorView, node, getPos } = useReactNodeView();
  const t = useTranslation();
  const [resetKey, setResetKey] = useState(Date.now());
  const { downloadDescriptionOle, isDownloadingDescriptionOle } =
    useDownloadDescriptionObject();
  const { uploadDescriptionOleObject, isUploadingDescriptionOleObject } =
    useUploadDescriptionOleObject();
  const { patentRevisionId } = useParams<{ patentRevisionId: string }>();

  if (!editorView || !node || !getPos) {
    return null;
  }

  const getOleObjectIds = (type: ImageAction) => {
    const contentNodeId = findParentNodeIdBeforeSection(
      editorView.state.doc,
      editorView.state.selection.$head.pos
    );
    const sectionId = getNodeAttrValue('section', 'nodeId', editorView);
    const imageSourceId = node.attrs.src.split?.('/')?.[1];

    if (
      !editorDocumentId ||
      !patentRevisionId ||
      !id ||
      !sectionId ||
      !contentNodeId ||
      !imageSourceId
    ) {
      message.error(
        t(
          type === ImageAction.Download
            ? 'FAILED_TO_DOWNLOAD'
            : 'FAILED_TO_UPDATE'
        )
      );
      return;
    }

    return {
      sectionId,
      contentNodeId,
      imageSourceId,
    };
  };

  const handleUploadSuccess = async (
    data: UploadDescriptionEditorOleResponse
  ) => {
    const pos = getPos();
    if (pos === undefined) {
      return;
    }
    const src = `resources/${data.imageResourceId}`;
    const attrs = {
      ...node.attrs,
      crop: null,
      width: data.width,
      height: null,
      aspectRatio: data.aspectRatio,
      objectSrc: `resources/${data.embeddedObjectResourceId}`,
      objectType: data.embeddedObjectType,
      src,
    };
    const nodeType = editorView.state.schema.nodes.embeddedObject;
    const newNode = nodeType.create(attrs);
    const { state, dispatch } = editorView;
    const tr = state.tr;
    dispatch(
      tr
        .delete(pos, pos + node.nodeSize)
        .insert(pos + node.nodeSize, newNode)
        .setMeta('resourceInsert', true)
    );
  };

  const handleFileChange = async (selectedFiles: File[]) => {
    if (selectedFiles.length) {
      const selectedFile = selectedFiles[0];
      const formData = new FormData();
      formData.append('files', selectedFile, selectedFile.name);
      const confirmUpload = window.confirm(t('CONFIRM_UNSAVED_CHANGES'));
      setResetKey(Date.now());
      if (!confirmUpload) {
        return;
      }
      const oleObjectIds = getOleObjectIds(ImageAction.Replace);
      if (!oleObjectIds) {
        return;
      }
      const { sectionId, contentNodeId, imageSourceId } = oleObjectIds;

      if (!editorDocumentId) {
        return;
      }

      try {
        const response = await uploadDescriptionOleObject({
          editorDocumentId,
          revisionId: patentRevisionId,
          imageSourceId,
          sectionId,
          contentNodeId,
          file: formData,
        });
        handleUploadSuccess(response);
      } catch {
        message.error(t('PM.ACTION.FAILED_TO_REPLACE'));
        return null;
      }
    }
  };

  const handleDownloadImage = () => {
    const oleObjectIds = getOleObjectIds(ImageAction.Download);
    if (!oleObjectIds) {
      return;
    }
    const { sectionId, contentNodeId, imageSourceId } = oleObjectIds;

    if (!editorDocumentId) {
      return;
    }

    downloadDescriptionOle({
      editorDocumentId,
      revisionId: patentRevisionId,
      imageSourceId,
      sectionId,
      contentNodeId,
    });
  };

  return (
    <ImageNodeView
      docId={editorDocumentId}
      onUploadFile={Api.oa.postUploadResource}
      editorView={editorView}
      selected={selected}
      id={id}
      readOnly={readOnly}
      getPos={getPos}
      node={node}
      onFileChange={handleFileChange}
      onDownloadImage={handleDownloadImage}
      isLoading={isDownloadingDescriptionOle || isUploadingDescriptionOleObject}
      resetKey={resetKey}
      editorType={EditorContentType.Description}
    />
  );
};

export default ImageViewDescription;
