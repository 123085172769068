/* eslint-disable */
import React from 'react';
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import styled from 'styled-components';
import UICommand from './commands/UICommand';
import CommandButton from './renderers/CommandButton';
import CommandMenuButton from './renderers/CommandMenuButton';
import {
  CommandGroup,
  ComponentTypeToolbarItemProps,
  isToolbarItem,
  isToolbarItemSubGroup,
} from './config';
import { isStateless } from './util/isStateless';

interface Props {
  group: CommandGroup;
  index: number;
  editorState: EditorState;
  editorView: EditorView;
  disabled?: boolean;
}

const ToolbarButton: React.FC<Props> = ({
  disabled,
  group,
  index,
  editorView,
  editorState,
}) => {
  const dispatch = editorView?.dispatch;
  const buttons = Object.keys(group)
    .map((label) => {
      const object = group[label];
      if (isToolbarItem(object)) {
        if (object.renderer) {
          const Component = object.renderer;
          return (
            <Component
              dispatch={dispatch}
              editorState={editorState}
              editorView={editorView}
            />
          );
        }
        if (isStateless(object?.command)) {
          const ThatComponent: React.ElementType<ComponentTypeToolbarItemProps> =
            object.command;
          return (
            <ThatComponent
              dispatch={dispatch}
              editorState={editorState}
              editorView={editorView}
              key={label}
            />
          );
        } else if (object?.command instanceof UICommand) {
          return (
            <CommandButton
              command={object.command}
              disabled={disabled}
              dispatch={dispatch}
              editorState={editorState}
              editorView={editorView}
              icon={object.icon}
              key={label}
              label={object.icon ? null : label}
              title={label}
            />
          );
        }
      } else if (isToolbarItemSubGroup(object)) {
        return (
          <CommandMenuButton
            commandGroups={object}
            disabled={disabled}
            dispatch={dispatch}
            editorState={editorState}
            editorView={editorView}
            icon={object.icon}
            key={label}
            label={object.icon ? null : label}
            title={label}
          />
        );
      } else {
        return null;
      }
    })
    .filter(Boolean);
  return <Outer key={'g' + String(index)}>{buttons}</Outer>;
};

const Outer = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-grow: 0;
`;

export default ToolbarButton;
