import React from 'react';
import QueryBuilderSegment from '../Common/QueryBuilderSegment';
import ClassesListItem from './ClassesListItem';
import ClassesListHeader from './ClassesListHeader';
import useTranslation from 'translations';
import { QueryBaseItem } from 'types';
import { useQueryContext } from 'components/TsdElement/Attributes/Query/QueryContext';

interface Props {
  items: QueryBaseItem[];
}

const QueryBuilderClasses: React.FC<Props> = ({ items }) => {
  const { query } = useQueryContext();
  const t = useTranslation();

  return (
    <QueryBuilderSegment header={<ClassesListHeader />} title={t('QUERY.CPC')}>
      {items.map((item) => {
        const cpcClass = query?.classes.find((c) => c.id === item.id);

        return (
          <ClassesListItem
            key={item.id}
            item={item}
            selected={!!cpcClass}
            isTruncated={cpcClass?.isTruncated}
          />
        );
      })}
    </QueryBuilderSegment>
  );
};

export default QueryBuilderClasses;
