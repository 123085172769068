import { NodeSpec } from 'prosemirror-model';

function getAttrs(dom: HTMLElement | string) {
  if (typeof dom === 'string') {
    return {};
  }
  const nodeId = dom.getAttribute('nodeId') || null;
  return { nodeId };
}

const section: NodeSpec = {
  attrs: {
    nodeId: { default: null },
  },
  content: 'block+',
  parseDOM: [{ tag: 'section', getAttrs }],
  toDOM(node) {
    return ['section', { nodeId: node.attrs.nodeId }, 0];
  },
};

export default section;
