import React from 'react';
import styled from 'styled-components';
import EditGraphicsListItem from './EditGraphicsListItem';
import { T } from '@ipos/shared';

interface Props {
  data: T.TsdElementUpload[];
  onRemoveRequest?: (id: string) => void;
}

const EditGraphicsList: React.FC<Props> = ({ data, onRemoveRequest }) => (
  <Outer>
    {data.map((item) => (
      <EditGraphicsListItem
        key={item.id}
        item={item}
        onRemoveRequest={onRemoveRequest}
      />
    ))}
  </Outer>
);

export default EditGraphicsList;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 700px;
  height: 100%;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid ${(props) => props.theme.colors.primary300};
  overflow: auto;
`;
