import React, { useMemo } from 'react';
import styled from 'styled-components';
import CpcTags from '../CpcTags';
import TsdTreeTags from '../TsdTreeTags';
import CreateTsdElementButton from '../CreateTsdElementButton';
import { NameRenderer, DateRenderer } from './Renderers';
import { Api, T, Components } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';
import { ContentLanguage } from 'components/common';

const MAX_TSD_TAG_RENDER_COUNT = 5;

const { List } = Components;

const TsdElementList: React.FC = () => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();

  const columns: T.Column<T.TsdElementBasicInfo>[] = useMemo(
    () => [
      {
        key: 'name',
        title: t('TSD_ELEMENT_LIST.NAME'),
        sorting: T.SortOrder.ASC,
        style: { flex: 7 },
        renderer: (value: string) => <NameRenderer name={value} />,
      },
      {
        key: 'tsdNames',
        title: t('TSD_ELEMENT_LIST.TSDS'),
        style: { flex: 6 },
        filters: () => Api.tsd.getTsdElementTreeFrequencies(T.Language.De),
        renderer: (value: string[]) => (
          <TsdTreeTags
            tsdNames={value}
            maxRenderCount={MAX_TSD_TAG_RENDER_COUNT}
          />
        ),
      },
      {
        key: 'cpcCodes',
        title: t('TSD_ELEMENT_LIST.CPCS'),
        style: { flex: 6 },
        filters: () => Api.tsd.getTsdElementCpcFrequencies(),
        renderer: (value: string[]) => <CpcTags codes={value} />,
      },
      {
        key: 'createdOn',
        style: { flex: '0 0 110px' },
        title: t('TSD_ELEMENT_LIST.CREATED_ON'),
        renderer: (value: string) => <DateRenderer date={value} />,
      },
    ],
    []
  );

  const dataSource: T.DataSource<
    T.TsdElementBasicInfo,
    T.TsdElementQueryParams
  > = useMemo(
    () => ({
      queryKey: QueryKey.TsdElements,
      apiFunction: Api.tsd.searchTsdElements,
      additionalQueryParams: () => ({
        language: preferredLanguage,
      }),
    }),
    [preferredLanguage]
  );

  return (
    <List
      linkFn={(item) =>
        generateUrl(ROUTES.TSD_ELEMENT_ROOT, {
          tsdElementId: item.id,
        })
      }
      title={
        <ListTitle>
          {t('TSD_ELEMENT_LIST.TITLE')}
          <ContentLanguage />
        </ListTitle>
      }
      headerChildren={<CreateTsdElementButton />}
      dataSource={dataSource}
      columns={columns}
      searchInSeparateRow
    />
  );
};

export default TsdElementList;

const ListTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
`;
