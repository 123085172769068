import React, { useMemo } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { QueryKey } from '../../queries';
import { queryClient } from '../../services/queryClient';
import {
  useCopyPatentRevision,
  useDeletePatentRevision,
  useUpdatePatentMetadata,
} from '../../mutations';
import { T } from '../../..';
import useTranslation from '../../translations';
import DeleteRevisionModal from './DeleteRevisionModal';
import { Tree, useTreeStructure } from './Tree';

interface Props {
  revisions: T.PatentRevisionHistoryItem[];
  patentApplicationId: string;
  revisionOnFileId?: string;
  activeRevisionId?: string;
  onPatentRevisionClick?: (patentRevisionId: string) => void;
  onPatentRevisionDoubleClick?: (patentRevisionId: string) => void;
}

const PatentRevisionsGraph: React.FC<Props> = ({
  revisions,
  patentApplicationId,
  revisionOnFileId,
  activeRevisionId,
  onPatentRevisionClick,
  onPatentRevisionDoubleClick,
}) => {
  const t = useTranslation();
  const { tree } = useTreeStructure(revisions);

  const invalidateTree = async () => {
    await queryClient.invalidateQueries([
      QueryKey.PatentHistory,
      patentApplicationId,
    ]);
  };

  const onActionError = () => {
    message.error(t('REVISION_TREE.ACTION_ERROR'));
  };

  const deleteQuery = useDeletePatentRevision(invalidateTree);
  const updateQuery = useUpdatePatentMetadata();

  const copyQuery = useCopyPatentRevision(async (r: T.PatentRevision) => {
    await invalidateTree();
    onPatentRevisionClick?.(r.id);
  });

  const handleCopyItem = async (item: T.TreeItem, title: string) => {
    try {
      await copyQuery.mutateAsync({
        patentRevisionId: item.id,
        patentRevisionTitle: title,
      });
    } catch {
      onActionError();
    }
  };

  const handleDeleteItem = async (item: T.TreeItem, force?: boolean) => {
    try {
      await deleteQuery.mutateAsync({ patentRevisionId: item.id, force });
    } catch {
      onActionError();
    }
  };

  const handleRenameItem = async (item: T.TreeItem, revisionName: string) => {
    try {
      await updateQuery.mutateAsync({
        patentRevisionId: item.id,
        updatedMetadata: {
          revisionName,
          revisionNameChanged: true,
        },
      });
      await invalidateTree();
    } catch {
      onActionError();
    }
  };

  const handleItemClick = (item: T.TreeItem) => {
    onPatentRevisionClick?.(item.id);
  };

  const handleItemDoubleClick = (item: T.TreeItem) => {
    onPatentRevisionDoubleClick?.(item.id);
  };

  const suggestions = useMemo(
    () => [
      t('REVISION_TREE.SUGGESTIONS.MAIN_REQUEST'),
      t('REVISION_TREE.SUGGESTIONS.AUX_REQUEST'),
      t('REVISION_TREE.SUGGESTIONS.PROPOSAL'),
      t('REVISION_TREE.SUGGESTIONS.DRAFT'),
    ],
    []
  );

  return (
    <Wrapper>
      <Tree
        tree={tree}
        suggestions={suggestions}
        activeItemId={activeRevisionId}
        onFileItemId={revisionOnFileId}
        onItemClick={handleItemClick}
        onItemDoubleClick={handleItemDoubleClick}
        onItemDelete={handleDeleteItem}
        onItemCopy={handleCopyItem}
        onItemRename={handleRenameItem}
        deleteConfirmation={(item, onConfirm, onCancel) => (
          <DeleteRevisionModal
            isInFinalState={!item.editable}
            title={item.name}
            onDelete={onConfirm}
            onCancel={onCancel}
          />
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: auto;
  min-height: 0;
  min-width: 0;
  margin-right: -15px;
  padding-top: 15px;
  margin-top: -10px;
`;

export default PatentRevisionsGraph;
