import React, { useRef } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { useNodeViewContext } from '../../ReactNodeView/nodeView';
import claimsPlugin from '../claims';
import ClaimActionsMenu from './ClaimActionsMenu';
import ClaimStatusIdentifier from './ClaimStatusIdentifier';

const ClaimNodeView = () => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const { contentRef, node, view, getPos } = useNodeViewContext();
  const {
    calculatedClaimNumber,
    claimNumber,
    baseClaimNumber,
    isDeleted,
    isInserted,
    comment,
    claimTraceId,
  } = node.attrs;
  const claimsPluginState =
    view?.state && claimsPlugin.key.getState(view?.state);

  const { editorContentId, handleClaimInsertSuccess, patentOffice } =
    claimsPluginState || {};

  const pos = getPos();

  const currentClaimNumber = calculatedClaimNumber || claimNumber;

  const isInsertedClass =
    baseClaimNumber && baseClaimNumber !== currentClaimNumber ? 'inserted' : '';

  const className = cx('claim', {
    deleted: isDeleted,
    inserted: isInserted,
  });

  return (
    <div
      className={className}
      data-claim-number={claimNumber}
      data-claim-trace-id={claimTraceId}
      data-base-claim-number={baseClaimNumber}
      data-calculated-claim-number={calculatedClaimNumber}
      data-is-deleted={isDeleted}
      data-is-inserted={isInserted}
    >
      <div className="number">
        {baseClaimNumber && baseClaimNumber !== currentClaimNumber && (
          <span className="old-claim-number">{baseClaimNumber}</span>
        )}
        <span
          className={cx('claim-number', {
            [isInsertedClass]: isInsertedClass,
          })}
        >
          {currentClaimNumber}
        </span>
      </div>
      <FlexColumn>
        <div>
          <ClaimStatusIdentifier
            node={node}
            view={view}
            pos={pos}
            patentOffice={patentOffice}
          />
        </div>

        <div
          className="content"
          ref={(element) => {
            contentRef(element);
            nodeRef.current = element;
          }}
        >
          {comment && <span className="note">{comment}</span>}
          <ClaimActionsMenu
            editorView={view}
            active={!isDeleted}
            root={nodeRef}
            claimNumber={currentClaimNumber}
            onSuccess={handleClaimInsertSuccess}
            editorContentId={editorContentId}
            patentOffice={patentOffice}
          />
        </div>
      </FlexColumn>
    </div>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default ClaimNodeView;
