import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

type Variables = {
  documentId: string;
  pages: number[];
};

type Result = Record<number, T.SelectableText[]>;

function useSelectableText(
  onSuccess?: (wipPatentRevision: Result) => void,
  onError?: (axiosError: AxiosError) => void
): UseMutationResult<Result, AxiosError, Variables> {
  return useMutation<Result, AxiosError, Variables>(
    ({ documentId, pages }) =>
      Api.document.getSelectableTexts(documentId, pages),
    {
      onSuccess,
      onError,
    }
  );
}

export default useSelectableText;
