import React from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';
import TsdElementAttributesWarnings from './TsdElementAttributesWarnings';
import { Permissions, Components, Colors } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTranslation from 'translations';

const { Icon } = Components;
const { usePermissionCheck } = Permissions;

interface Props {
  confirmRemovalTitle?: string;
  isBaseAttribute: boolean;
  children: React.ReactNode;
  onRemoveRequest?: () => void;
  onEditRequest?: () => void;
  warnings?: string[];
}

const TsdElementAttributeListItem: React.FC<Props> = ({
  isBaseAttribute,
  warnings,
  children,
  onRemoveRequest,
  confirmRemovalTitle,
  onEditRequest,
}) => {
  const t = useTranslation();
  const { isLibraryView } = useTsdElementContext();
  const hasWarnings = (warnings && warnings.length > 0) as boolean;
  const allowedToEdit = usePermissionCheck(
    AzureGroupsConfig.PermissionCompanyTsdEdit
  );

  const showBaseAttributeIndication = isBaseAttribute && !isLibraryView;

  // base attributes can be deleted/edited in the library view only
  // node attributes can be deleted/edited in the tsd view only
  const showDelete =
    !showBaseAttributeIndication && allowedToEdit && onRemoveRequest;

  const showEdit =
    !showBaseAttributeIndication && allowedToEdit && onEditRequest;

  return (
    <Outer isBaseAttribute={isBaseAttribute} hasWarnings={hasWarnings}>
      {children}
      <Actions>
        {warnings && <TsdElementAttributesWarnings warnings={warnings} />}
        {showBaseAttributeIndication && (
          <Icon
            className="icn-locked"
            style={{ cursor: 'not-allowed', marginRight: 8 }}
            title={t('ATTRIBUTES.LIBRARY_ATTRIBUTE')}
            color={Colors.primary300}
            size={19}
          />
        )}
        {showEdit && (
          <Button
            type="link"
            onClick={onEditRequest}
            icon={<StyledIcon className="icn-edit" />}
          />
        )}
        {showDelete && (
          <Popconfirm
            placement="left"
            title={confirmRemovalTitle}
            onConfirm={onRemoveRequest}
            okText={t('YES')}
            cancelText={t('NO')}
          >
            <Button type="link" icon={<StyledIcon className="icn-delete" />} />
          </Popconfirm>
        )}
      </Actions>
    </Outer>
  );
};

export default TsdElementAttributeListItem;

const Outer = styled.div<{ isBaseAttribute: boolean; hasWarnings: boolean }>`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary300};
  background-color: ${(props) => {
    switch (true) {
      case props.hasWarnings:
        return 'rgba(216, 150, 20, 0.15)';
      case !props.isBaseAttribute:
        return 'rgba(0, 125, 0, 0.075)';
      default:
        return 'transparent';
    }
  }};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledIcon = styled(Icon)`
  vertical-align: text-bottom;
  font-size: 20px;
`;
