import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import CountryFlag from '../CountryFlag';
import useTranslation from '../../translations';

const { Option } = Select;

export interface Props {
  value: string;
  onChange: (value: string) => void;
}

const COUNTRIES: string[] = ['de', 'en'];

const CountryPicker: React.FC<Props> = ({ value, onChange }) => {
  const t = useTranslation();

  const handleChange = (newValue: string): void => {
    onChange(newValue);
  };

  const options = COUNTRIES.map((c) => (
    <Option key={c} value={c}>
      <Flex>
        <CountryFlag countryCode={c} />
        {t(`COUNTRIES.${c.toUpperCase()}`)}
      </Flex>
    </Option>
  ));

  return (
    <Select value={value} onChange={handleChange}>
      {options}
    </Select>
  );
};

export default CountryPicker;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
