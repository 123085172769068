import { T } from '@ipos/shared';
import { QuerySynonymOption } from 'types';

type PropertyKey =
  | QuerySynonymOption.SearchInTitle
  | QuerySynonymOption.SearchInAbstract
  | QuerySynonymOption.SearchInDescription
  | QuerySynonymOption.SearchInClaims;

type PropertiesLists = {
  [key in PropertyKey]: string[];
};

const SECTION_PREFIX = {
  searchInTitle: 'TI=',
  searchInAbstract: 'AB=',
  searchInDescription: 'DSC=',
  searchInClaims: 'CL=',
};

const getTrunc = (isTruncated: boolean | undefined) => (isTruncated ? '*' : '');

const getSynonymsLists = (synonyms: T.TsdQuerySynonym[]) =>
  synonyms.reduce<PropertiesLists>(
    (res, p) => {
      const value = `${getTrunc(p.isTruncatedAtStart)}${p.value}${getTrunc(
        p.isTruncatedAtEnd
      )}`;

      if (p.searchInTitle) {
        res.searchInTitle.push(value);
      }
      if (p.searchInAbstract) {
        res.searchInAbstract.push(value);
      }
      if (p.searchInDescription) {
        res.searchInDescription.push(value);
      }
      if (p.searchInClaims) {
        res.searchInClaims.push(value);
      }

      return res;
    },
    {
      searchInTitle: [],
      searchInAbstract: [],
      searchInDescription: [],
      searchInClaims: [],
    }
  );

export function compileQueryPreview(query: T.TsdQuery): [string, string] {
  const { classes, synonyms } = query;

  const classesString = classes
    .map(({ code, isTruncated }) => `${code}${getTrunc(isTruncated)}`)
    .join(' OR ');

  const synonymsLists = getSynonymsLists(synonyms);

  const synonymString = Object.entries(synonymsLists)
    .filter(([, value]) => value.length > 0)
    .map(([key, value], _index, arr) => {
      const sectionValues = [...value];
      const sectionPrefix = SECTION_PREFIX[key as PropertyKey];
      const sectionString = sectionValues.join(' OR ');
      const prefixedString = `${sectionPrefix}(${sectionString})`;
      return arr.length > 1 ? `(${prefixedString})` : prefixedString;
    })
    .join(' OR ');

  return [classesString, synonymString];
}
