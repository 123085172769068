import React from 'react';
import styled from 'styled-components';
import { Components } from '@ipos/shared';

const { Icon } = Components;

interface Props {
  showWarning: boolean;
  warningText: string;
  children: React.ReactNode;
}

const TsdElementAttributesPane: React.FC<Props> = ({
  showWarning,
  warningText,
  children,
}) => (
  <Outer>
    {children}
    {showWarning && (
      <Warning>
        <Icon className="icn-info-outlined" size={32} />
        {warningText}
      </Warning>
    )}
  </Outer>
);

export default TsdElementAttributesPane;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  position: relative;
`;

const Warning = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.colors.warning};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
`;
