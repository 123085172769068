import React, { useState } from 'react';
import styled from 'styled-components';
import { FlatButton } from '../Button';
import useTranslation from '../../translations';

interface Props {
  id: string;
  onAddNode?: (tsdNodeId: string) => void;
  onRemoveNode?: (tsdNodeId: string) => void;
  tsdNodeIds: string[];
}

const TsdNodeContentActions: React.FC<Props> = ({
  id,
  onAddNode,
  onRemoveNode,
  tsdNodeIds,
}) => {
  const t = useTranslation();

  const [iconClassName, setIconClassName] =
    useState<string>('icn-done-outlined');
  const [btnText, setBtnText] = useState<string>('ACTION.ADDED');

  const handleMouseOver = () => {
    setIconClassName('icn-remove-outlined');
    setBtnText('ACTION.REMOVE');
  };

  const handleMouseOut = () => {
    setIconClassName('icn-done-solid');
    setBtnText('ACTION.ADDED');
  };

  return (
    <>
      {tsdNodeIds.includes(id) ? (
        <Button
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          leftIcon={<i className={iconClassName} />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemoveNode?.(id);
          }}
        >
          {t(btnText)}
        </Button>
      ) : (
        <AddButton
          leftIcon={<i className="icn-add" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onAddNode?.(id);
          }}
        >
          {t('ACTION.ADD')}
        </AddButton>
      )}
    </>
  );
};

export default TsdNodeContentActions;

const AddButton = styled(FlatButton)`
  padding: 5px 9px;
  margin-right: 12px;
  border-radius: 5px;
  &:hover {
    background: unset;
  }
  opacity: 0;
`;
const Button = styled(FlatButton)`
  padding: 5px 9px;
  margin: 0;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.success};
  opacity: 1;
  font-weight: 500;
  &:hover {
    background: unset;
    color: ${(props) => props.theme.colors.white70};
  }
  transition: all 0.3s ease-in-out;
`;
