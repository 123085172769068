import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import classNames from 'classnames';
import styled, { CSSObject } from 'styled-components';
import { Button } from 'antd';
import { BaseDto, SortOrder, Column, FilterValue } from '../../../types';
import { Icon, Table } from '../..';
import { Colors } from '../../../theme';
import useTranslation from '../../../translations';

interface Props<TItem> {
  scrollX?: boolean;
  columns: Column<TItem>[];
  appliedFilters: [string, FilterValue[]][];
  sortBy: string | number | symbol | undefined | { [key: string]: SortOrder };
  sortOrder?: SortOrder;
  scrollbarCompensation?: boolean;
  onApplyFilter: (columnKey: string, values: FilterValue[]) => void;
  onSortOrderChange: (columnKey: string, value: SortOrder) => void;
  onColumnVisibilityChange: (column: Column<TItem>, checked: boolean) => void;
  onColumnOrderChange: (
    column: Column<TItem>,
    oldIndex: number,
    newIndex: number
  ) => void;
  onClearTableFilters: () => void;
}

const TableHead = <TItem extends BaseDto>({
  scrollX,
  columns,
  appliedFilters,
  sortBy,
  sortOrder,
  scrollbarCompensation,
  onApplyFilter,
  onSortOrderChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onClearTableFilters,
}: PropsWithChildren<Props<TItem>>): ReactElement | null => {
  const headRef = useRef<HTMLDivElement>(null);
  const t = useTranslation();

  // watch the row's column's width and update the table head's column width
  useEffect(() => {
    let adjusted = false;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const columnKey = entry.target.getAttribute('data-row-column-key');

        const headEl = document.querySelector(
          `[data-head-column-key="${columnKey}"]`
        ) as HTMLElement;

        if (!headEl) {
          return;
        }

        if (
          entry.borderBoxSize?.length > 0 &&
          entry.borderBoxSize[0].inlineSize > 0 &&
          !adjusted
        ) {
          // set width of head element to width of body element
          headEl.style.minWidth = `${entry.borderBoxSize[0].inlineSize}px`;
          headEl.style.maxWidth = `${entry.borderBoxSize[0].inlineSize}px`;
          adjusted = true;
        }
      }
    });

    columns.forEach((column) => {
      const el = document.querySelector(
        `[data-row-column-key="${column.key}"]`
      ) as HTMLElement;

      if (!el) {
        return;
      }

      observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, [columns]);

  const tableFilterNotification = appliedFilters.length > 0 && (
    <NotificationWrapper>
      <Icon className="icn-info-outlined" color={Colors.primary100} />
      <Notification>
        {t('ITEM_LIST.FILTERING.FILTERS_ACTIVATED')}.{' '}
        <Button type="link" onClick={onClearTableFilters}>
          {t('ITEM_LIST.FILTERING.CLEAR_FILTERS')}.
        </Button>
      </Notification>
    </NotificationWrapper>
  );

  return (
    <Table.TableHeader
      scrollbarCompensation={scrollbarCompensation}
      ref={headRef}
      scrollX={scrollX}
    >
      {tableFilterNotification}
      {columns.map((column) => {
        const firstFixedColumnToLeft =
          Table.TableUtils.getFirstFixedColumnToLeft(columns);

        const lastFixedColumnToLeft =
          Table.TableUtils.getLastFixedColumnToLeft(columns);

        const firstFixedColumnToRight =
          Table.TableUtils.getFirstFixedColumnToRight(columns);

        const lastFixedColumnToRight =
          Table.TableUtils.getLastFixedColumnToRight(columns);

        const appliedFilterForColumn = appliedFilters.find(
          ([columnKey]) => columnKey === column.key
        )?.[1];

        const additionalStyle: CSSObject = {
          display: column.hide ? 'none' : 'flex',
        };

        if (column.fixed) {
          additionalStyle[
            column.fixed
          ] = `${Table.TableUtils.calculateFixedColumnGap<TItem>(
            columns,
            column
          )}px`;
        }

        return (
          <Table.TableCell
            key={column.key}
            style={{ height: '100%', ...column.style, ...additionalStyle }}
            data-head-column-key={column.key}
            className={classNames({
              'fixed fixed-left': column.fixed === 'left',
              'fixed fixed-right': column.fixed === 'right',
              'fixed-left-first': firstFixedColumnToLeft?.key === column.key,
              'fixed-left-last': lastFixedColumnToLeft?.key === column.key,
              'fixed-right-first': firstFixedColumnToRight?.key === column.key,
              'fixed-right-last': lastFixedColumnToRight?.key === column.key,
            })}
          >
            {column.key === '_column_customize' ? (
              <Table.ColumnCustomize
                columns={columns.map((c) => {
                  const newColumn = { ...c };

                  // if a filter applied on the column, it should not be hideable
                  if (Boolean(appliedFilters.find(([key]) => key === c.key))) {
                    newColumn.hideable = false;
                  }

                  return newColumn;
                })}
                onColumnVisibilityChange={onColumnVisibilityChange}
                onColumnOrderChange={onColumnOrderChange}
              />
            ) : (
              <CellTitleWrapper>
                <CellTitle>
                  {column.icon}
                  {column.title}
                  {column.sorting != null && (
                    <>
                      <Table.ColumnSort
                        sorting={sortBy === column.key ? sortOrder : undefined}
                        onChange={(value) =>
                          onSortOrderChange(column.key, value)
                        }
                      />
                    </>
                  )}
                </CellTitle>
                {column.filters && (
                  <Table.ColumnFilter
                    value={appliedFilterForColumn}
                    columnKey={column.key}
                    type={column.filters}
                    onApplyFilter={onApplyFilter}
                  />
                )}
              </CellTitleWrapper>
            )}
          </Table.TableCell>
        );
      })}
    </Table.TableHeader>
  );
};

export default TableHead;

const CellTitle = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 500;
`;

const CellTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin: 0px 0px -6px 15px;
  align-items: center;
  position: fixed;
  z-index: 100000;
`;

const Notification = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.primary100};
  button {
    padding: 0;
    font-size: 13px;
    color: ${(props) => props.theme.colors.blue600};
    &:hover {
      span {
        text-decoration: underline;
      }
      color: ${(props) => props.theme.colors.blue600};
    }
  }
`;
