import { useMemo } from 'react';
import useMedia from './useMedia';

function useCountryFlagSize(): number {
  const [
    laptopS,
    laptopM,
    laptopL2,
    laptopL,
    desktopS,
    desktopS2,
    desktopHD,
    desktop4K,
  ] = useMedia();

  return useMemo<number>(() => {
    if (desktop4K) {
      return 20;
    }
    if (desktopHD) {
      return 18;
    }
    if (desktopS2) {
      return 16;
    }
    if (desktopS) {
      return 14;
    }
    if (laptopL) {
      return 12;
    }
    if (laptopL2) {
      return 12;
    }
    if (laptopM) {
      return 12;
    }
    if (laptopS) {
      return 12;
    }
    return 12;
  }, [
    laptopS,
    laptopM,
    laptopL2,
    laptopL,
    desktopS,
    desktopS2,
    desktopHD,
    desktop4K,
  ]);
}

export default useCountryFlagSize;
