import React from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import styled, { css } from 'styled-components';

export interface HeaderItemProps {
  label: string;
  icon?: React.ReactNode;
  iconClassName?: string; // use with philipa icon fonts
  to?: LocationDescriptor;
  style?: React.CSSProperties;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const HeaderItem: React.FC<HeaderItemProps> = ({
  label,
  icon,
  iconClassName,
  to,
  style,
  disabled,
  active,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  const content = (
    <Outer
      isDisabled={disabled}
      isActive={active}
      style={style}
      onClick={handleClick}
    >
      {icon || (iconClassName && <i className={iconClassName} />)}
      <Label>{label}</Label>
    </Outer>
  );

  return to != null ? (
    <StyledLink to={to} isDisabled={disabled}>
      {content}
    </StyledLink>
  ) : (
    content
  );
};

export default HeaderItem;

const Label = styled.span`
  font-size: 11px;
  line-height: 1.64;
  transition: all 0.3s ease;
  color: ${(props) => props.theme.colors.white70};
`;

const activeState = css`
  ${Label} {
    color: ${(props) => props.theme.colors.white100};
  }
  background-color: ${(props) => props.theme.colors.primary200_20};
`;

const Outer = styled.div<{ isDisabled?: boolean; isActive?: boolean }>`
  height: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
  i {
    color: ${(props) => props.theme.colors.white100};
    font-size: 26px;
  }
  &:hover {
    ${activeState}
  }
  ${(props) => props.isActive && activeState}
  ${(props) => props.isDisabled && 'opacity: 0.5; pointer-events: none;'}
`;

const StyledLink = styled(Link)<{ isDisabled?: boolean }>`
  cursor: pointer;
  ${(props) => props.isDisabled && `cursor: auto; pointer-events: none;`}
`;
