import React, { useRef } from 'react';
import styled from 'styled-components';
import { T } from '../../..';
import { useIntersectionObserver } from '../../hooks';
import ObservedTarget from '../ObservedTarget';

interface Props {
  notesQuery: T.HookResult<T.UserNote>;
}

const NotesListContainer: React.FC<React.PropsWithChildren<Props>> = ({
  notesQuery,
  children,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const { hasNextPage, isFetchingNextPage, fetchNextPage } = notesQuery;

  useIntersectionObserver({
    target: targetRef?.current,
    onIntersect: fetchNextPage,
    root: rootRef?.current,
    threshold: 0.1,
    enabled: hasNextPage,
  });

  return (
    <Outer ref={rootRef}>
      {children}
      <ObservedTarget targetRef={targetRef} loading={isFetchingNextPage} />
    </Outer>
  );
};

export default NotesListContainer;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;
