import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Api, T } from '@ipos/shared';
import useTranslation from 'translations';
import { QueryKey } from 'queries';

interface Props {
  searchTerm: string;
  isSearchEnabled: boolean;
  defaultSelectedKeys?: string[];
  onSearchEnd: () => void;
  onChange: (companies: T.PatstatCompanyNameVariation[]) => void;
}

const CompanyNameVariationsPicker: React.FC<Props> = ({
  searchTerm,
  isSearchEnabled,
  defaultSelectedKeys,
  onSearchEnd,
  onChange,
}) => {
  const t = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
    defaultSelectedKeys ?? []
  );

  const { data, isLoading } = useQuery(
    [QueryKey.CompanyNameVariations, searchTerm],
    () => Api.patstat.getCompanyNameVariations(searchTerm),
    {
      enabled: isSearchEnabled,
      cacheTime: 0,
      onSettled: () => {
        onSearchEnd();
      },
    }
  );

  const handleSelectionChange = (
    keys: React.Key[],
    rows: T.PatstatCompanyNameVariation[]
  ) => {
    setSelectedKeys(keys);
    onChange(rows);
  };

  const columns = useMemo(
    () => [
      {
        title: t('COMPANIES.NAME'),
        dataIndex: 'name',
        width: 450,
        ellipsis: true,
      },
      {
        title: t('COMPANIES.APPLICATIONS'),
        dataIndex: 'numberOfApplications',
      },
      {
        title: t('COMPANIES.EARLIEST_FILING'),
        dataIndex: 'earliestFilingYear',
      },
      {
        title: t('COMPANIES.LATEST_FILING'),
        dataIndex: 'latestFilingYear',
      },
    ],
    []
  );

  return (
    <Outer>
      <Table
        rowKey="name"
        dataSource={data}
        loading={{
          spinning: isLoading,
          tip: t('COMPANIES.QUERY_DURATION_MSG'),
        }}
        columns={columns}
        pagination={{ pageSize: 6 }}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: selectedKeys,
          onChange: handleSelectionChange,
        }}
      />
    </Outer>
  );
};

export default CompanyNameVariationsPicker;

const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
