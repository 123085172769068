import React, { useState } from 'react';
import styled from 'styled-components';
import { EditorState, RichUtils } from 'draft-js';
import * as T from '../../../types';
import getNewImageBlock from '../MediaBlock/getNewImageBlock';
import { ToolbarIcon } from '../Utils';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import Button from './Button';
import UploadImageModal from './Images/UploadImageModal';
import TextBlocksPicker from './TextBlocksPicker';

const RichTextControls: React.FC<{
  editorState: EditorState;
  onChange: (newState: EditorState) => void;
}> = ({ editorState, onChange }) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const handleToggleInlineStyle = (inlineStyle: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const handleToggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleUndoClick = () => {
    onChange(EditorState.undo(editorState));
  };

  const handleRedoClick = () => {
    onChange(EditorState.redo(editorState));
  };

  const handleImageSelection = (uploads: T.BlobResource[]) => {
    if (!uploads?.length) {
      return;
    }
    const [upload] = uploads;

    const newEditorState = getNewImageBlock(upload.id, editorState);
    onChange(newEditorState);
    setShowUploadModal(false);
  };

  return (
    <Outer>
      <Button
        onClick={handleUndoClick}
        label="RICH_TEXT.CONTROLS.UNDO"
        icon={<ToolbarIcon className="icn-undo" />}
      />
      <Button
        onClick={handleRedoClick}
        label="RICH_TEXT.CONTROLS.REDO"
        icon={<ToolbarIcon className="icn-redo" />}
      />
      <Divider />
      <BlockStyleControls
        editorState={editorState}
        onToggle={handleToggleBlockType}
      />
      <Divider />
      <InlineStyleControls
        editorState={editorState}
        onToggle={handleToggleInlineStyle}
      />
      <Divider />
      <Button
        onClick={() => setShowUploadModal(true)}
        label="RICH_TEXT.CONTROLS.IMAGE"
        icon={<ToolbarIcon className="icn-photo" />}
      />
      <TextBlocksPicker editorState={editorState} onChange={onChange} />
      {showUploadModal && (
        <UploadImageModal
          onSelect={handleImageSelection}
          onCloseRequest={() => setShowUploadModal(false)}
        />
      )}
    </Outer>
  );
};

export default RichTextControls;

const Outer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
  transition: background-color 0.2s;
  user-select: none;
`;

const Divider = styled.div`
  width: 10px;
`;
