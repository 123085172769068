import { object, string, mixed, boolean } from 'yup';
import { T } from '@ipos/shared';

const addTsdElementSynonymSchema = object({
  value: string().trim().required('VALIDATION.REQUIRED'),
  isPrimary: boolean().defined(),
  language: mixed<T.Language>().required('VALIDATION.REQUIRED'),
});

export default addTsdElementSynonymSchema;
