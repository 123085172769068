import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { SyntheticEvent, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import UICommand from '../commands/UICommand';
import DropdownMenu from '../../../DropdownMenu';
import { FlatButton } from '../../../Button';
import { MenuConfig } from '../../../DropdownMenu/DropdownMenu';
import { ToolbarItemSubGroup } from '../config';
import { Colors } from '../../../../theme';
import { isElementType, StyledIconButton } from './CommandButton';
import CommandGroupDivider from './CommandGroupDivider';
import TableCommandLabel from './TableCommandLabel';

interface Props {
  commandGroups: ToolbarItemSubGroup;
  disabled?: boolean;
  dispatch: (tr: Transaction) => void;
  editorState: EditorState;
  editorView?: EditorView;
  icon?: string | React.ReactNode | null;
  label?: string | React.ReactNode | null;
  title?: string;
  anchor?: React.ReactElement;
}

const CommandMenuButton: React.FC<Props> = ({
  label,
  commandGroups,
  editorState,
  editorView,
  dispatch,
  icon,
  disabled,
  title,
  anchor,
}) => {
  const idRef = useRef(uuid());
  const activeCommandRef = useRef<UICommand | null>(null);
  const direction = anchor ? 'right' : 'bottom';

  const onItemClick = (command: UICommand, event: SyntheticEvent): void => {
    activeCommandRef?.current?.cancel();
    activeCommandRef.current = command;
    _execute(command, event);
  };

  const _execute = (command: UICommand, e: SyntheticEvent) => {
    command.execute(editorState, dispatch, editorView, e);
  };

  const menu = useMemo(() => {
    const menuConfig: MenuConfig<UICommand> = {
      name: 'root',
      items: [],
    };
    const jj = commandGroups.group.length - 1;
    commandGroups.group.forEach((group, ii) => {
      let groupLabel = '';

      Object.keys(group).forEach((label) => {
        const { command, renderer, footer, icon } = group[label];
        groupLabel = footer || groupLabel;
        let disabled = true;
        try {
          disabled = !editorView || !command.isEnabled(editorState, editorView);
        } catch (ex) {
          disabled = false;
        }
        if (renderer) {
          const Component = renderer;
          menuConfig.items?.push({
            label: (
              <Component
                dispatch={dispatch}
                editorState={editorState}
                editorView={editorView}
                command={command}
              />
            ),
            value: command,
            checked: command.isActive(editorState),
            disabled,
          });
          return;
        }

        menuConfig.items?.push({
          label: (
            <TableCommandLabel icon={icon} label={label} active={!disabled}>
              {command.renderLabel(editorState)}
            </TableCommandLabel>
          ),
          value: command,
          checked: command.isActive(editorState),
          disabled,
          onItemClick: onItemClick as never,
        });
      });
      if (ii !== jj) {
        menuConfig.items?.push(
          <CommandGroupDivider key={ii} label={groupLabel} />
        );
      }
    });

    return menuConfig;
  }, [onItemClick, commandGroups]);

  const enabled =
    !disabled &&
    commandGroups.group.some((group) =>
      Object.keys(group).some((label) => {
        const { command } = group[label];
        let disabledVal = true;
        try {
          disabledVal =
            !editorView || !command.isEnabled(editorState, editorView);
        } catch (ex) {
          disabledVal = false;
        }
        return !disabledVal;
      })
    );

  const Button = useMemo(() => {
    switch (true) {
      case !!anchor:
        return anchor;
      case !!label:
        return (
          <FlatButton
            active={activeCommandRef.current?.isActive(editorState)}
            disabled={!enabled}
            leftIcon={icon}
            id={idRef.current}
            title={title}
          >
            {label}
          </FlatButton>
        );
      case isElementType(icon):
        return (
          <StyledIconButton
            active={activeCommandRef.current?.isActive(editorState)}
            buttonStyle={{ borderRadius: 3 }}
            icon={icon as React.ElementType}
          />
        );
      case typeof icon === 'string':
        return (
          <StyledIconButton
            active={activeCommandRef.current?.isActive(editorState)}
            buttonStyle={{ borderRadius: 3 }}
            className={icon as string}
          />
        );
      default:
        return (
          <StyledIconButton
            active={activeCommandRef.current?.isActive(editorState)}
            buttonStyle={{ borderRadius: 3 }}
            className="icn-question-outlined"
          />
        );
    }
  }, [editorState, enabled, icon, label, title, anchor]);

  return (
    <DropdownMenu
      portal={true}
      menuConfig={menu}
      anchor={Button}
      direction={direction}
      align="start"
      style={{
        background: `linear-gradient(135deg, ${Colors.primary600} 0%, ${Colors.primary800} 100%)`,
        marginLeft: 2,
        width: 220,
        borderRadius: 12,
        padding: '20px 0',
      }}
      menuItemStyle={{
        padding: 0,
        borderRadius: 0,
      }}
    />
  );
};

export default CommandMenuButton;
