import React from 'react';
import styled from 'styled-components';
import { formatDateTime } from '../../utils';
import useTranslation from '../../translations';
import { UserAvatar, UserRenderer } from '../Aad';
import { T } from '../../..';
import { Icon } from '../Icon';

const formatDate = (v: string) => formatDateTime(v, 'DD.MM.YYYY');

interface Props {
  item: T.UserNote;
}

const NotesItemInfo: React.FC<Props> = ({ item }) => {
  const t = useTranslation();
  const fallback = (
    <ContentRenderer note={item} placeholder={t('UNKNOWN_USER')} />
  );

  return (
    <UserRenderer
      userId={item.createdById}
      loadingFallback={<ContentRenderer note={item} />}
      idleFallback={fallback}
      errorFallback={fallback}
      render={(user) => <ContentRenderer note={item} user={user} />}
    />
  );
};

export default NotesItemInfo;

const ContentRenderer: React.FC<{
  note: T.UserNote;
  user?: T.AadUser;
  placeholder?: string;
}> = ({ note, user, placeholder }) => {
  const t = useTranslation();
  const { createdOn, modifiedOn, isPublic } = note;

  let userContent = <Name style={{ minWidth: 100 }} />;

  if (user) {
    userContent = (
      <>
        <UserAvatar />
        <Name>{user.displayName}</Name>
        <Divider>|</Divider>
      </>
    );
  } else if (placeholder) {
    userContent = (
      <>
        <Name>{placeholder}</Name>
        <Divider>|</Divider>
      </>
    );
  }

  return (
    <Outer noUser={!user}>
      {userContent}
      <DateTime>{formatDate(modifiedOn || createdOn)}</DateTime>
      {modifiedOn && (
        <>
          <Divider>|</Divider>
          <EditIndicator>{t('NOTES.EDITED')}</EditIndicator>
        </>
      )}
      {!isPublic && (
        <>
          <Divider>|</Divider>
          <PrivateTag>
            <Icon size={12} className="icn-locked" />
            {t('NOTES.PRIVATE')}
          </PrivateTag>
        </>
      )}
    </Outer>
  );
};

const Outer = styled.div<{ noUser: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${(props) => props.noUser && 'margin-left: 40px;'}
`;

const Name = styled.div`
  color: ${(props) => props.theme.colors.white70};
`;

const Divider = styled.div`
  color: ${(props) => props.theme.colors.primary200_70};
`;

const DateTime = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.white40};
`;

const EditIndicator = styled.div`
  color: ${(props) => props.theme.colors.white60};
  font-size: 13px;
`;

const PrivateTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 6px;
  gap: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white100};
  color: ${(props) => props.theme.colors.primary100};
`;
