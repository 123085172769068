import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

function usePreviewPrompt(
  options?: UseMutationOptions<T.AiResponse, AxiosError, T.ExecuteAiPrompt>
): UseMutationResult<T.AiResponse, AxiosError, T.ExecuteAiPrompt> {
  return useMutation<T.AiResponse, AxiosError, T.ExecuteAiPrompt>(
    (payload) => Api.ai.previewPrompt(payload),
    options
  );
}

export default usePreviewPrompt;
