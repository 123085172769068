import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';

interface Props {
  style?: React.CSSProperties;
}

const AuthenticTag: React.FC<Props> = ({ style }) => {
  const t = useTranslation();

  return <Tag style={style}>{t('TRANSLATION_PICKER.AUTHENTIC')}</Tag>;
};

export default AuthenticTag;

export const Tag = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border-radius: 2px;
  font-size: 11px;
  line-height: 1.6;
  background: ${(props) => props.theme.colors.white20};
  color: ${(props) => props.theme.colors.white87};
`;
