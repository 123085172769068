import { MarkSpec } from 'prosemirror-model';

const inserted: MarkSpec = {
  parseDOM: [{ tag: 'span' }, { style: 'text-decoration=inserted' }],
  toDOM() {
    return ['span', { class: 'inserted' }, 0];
  },
};

export default inserted;
