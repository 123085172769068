import { Attrs, MarkSpec } from 'prosemirror-model';
import { toClosestFontPtSize } from '../../util';

const FontSizeMarkSpec: MarkSpec = {
  attrs: {
    pt: { default: null },
  },
  inline: true,
  group: 'inline',
  parseDOM: [
    {
      style: 'font-size',
      getAttrs,
    },
  ],
  toDOM(node) {
    const { pt } = node.attrs;
    const domAttrs = pt
      ? {
          style: `font-size: ${pt}pt;`,
        }
      : null;

    return ['span', domAttrs, 0];
  },
};

function getAttrs(fontSize: string | HTMLElement): Attrs | false | null {
  const attrs: Attrs = {};
  if (!fontSize || typeof fontSize !== 'string') {
    return attrs;
  }

  const ptValue = toClosestFontPtSize(fontSize);
  if (!ptValue) {
    return attrs;
  }
  return {
    pt: ptValue,
  };
}

export default FontSizeMarkSpec;
