import { css } from 'styled-components';

export function truncate(width?: string): string {
  return `
    ${width != null ? `width: ${width};` : ''}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export function lineClamp(lines: number): string {
  return `
    -webkit-line-clamp: ${lines};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  `;
}

export function hover(color?: string): ReturnType<typeof css> {
  return css`
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${(props) => color || props.theme.colors.primary500};
    }
  `;
}

export function gradient(
  startColor?: string,
  endColor?: string,
  angle = '120deg'
): ReturnType<typeof css> {
  return css`
    background-image: linear-gradient(
      ${angle || '120deg'},
      ${(props) => startColor || props.theme.colors.primary600},
      ${(props) => endColor || props.theme.colors.primary800}
    );
  `;
}

export function scrollbar({
  trackColor,
  thumbColor,
  thumbRadius,
  thumbHoverColor,
  thickness,
  applyToChildren,
}: {
  trackColor?: string;
  thumbColor?: string;
  thumbRadius?: string;
  thumbHoverColor?: string;
  thickness?: string;
  applyToChildren?: boolean;
}) {
  // TODO: recheck selector logic (issues with popover)
  const selector = applyToChildren ? '& *' : '&';

  // TODO: recheck this color picker logic
  // if undefined, use global style variables
  const firefoxThumbColor = thumbColor || '@thumb-color';
  const firefoxTrackColor = trackColor || '@track-color';

  return css`
    /* Works on Firefox */
    scrollbar-color: ${firefoxThumbColor} ${firefoxTrackColor};
    scrollbar-width: ${thickness || '@thickness'};

    /* Works on Chrome, Edge, and Safari */
    &${selector}::-webkit-scrollbar {
      ${thickness && `width: ${thickness};`}
      ${thickness && `height: ${thickness};`}
    }
    &${selector}::-webkit-scrollbar-track {
      ${trackColor && `background: ${trackColor};`}
    }
    &${selector}::-webkit-scrollbar-thumb {
      ${thumbColor && `background: ${thumbColor};`}
      ${thumbRadius && `border-radius: ${thumbRadius};`}
      ${thickness && `width: ${thickness};`}
      &:hover {
        ${thumbHoverColor && `background: ${thumbHoverColor};`}
      }
    }
    &${selector}::-webkit-scrollbar-corner {
      ${trackColor && `background: ${trackColor};`}
  `;
}
