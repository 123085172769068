import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SHARED_ROUTES, { generateUrl } from '../../utils/routing';
import { PageLoad } from '..';
import useTranslation from '../../translations';
import { DocumentMetadata, DocViewerParams } from '../../types';
import { prepareQueryParams } from '../../utils';
import { DocViewerData } from './types';
import SingleDocument from './SingleDocument';
import useDocViewerDocumentMetadataList from './useDocViewerDocumentMetadataList';
import MultiDocViewerHeader from './MultiDocViewerHeader';
import { findDocumentById } from './util';
import './styles.less';
import { MultiDocProvider } from './MultiDocContext';

const MAX_DOCS_TO_SHOW = 3;

const prepareDocs = ({ docCountToShow, documents }: DocViewerData) => {
  const docs: DocumentMetadata[] = [];
  for (let i = 0; i < Math.min(docCountToShow, MAX_DOCS_TO_SHOW); i += 1) {
    const doc = documents[i] ? documents[i] : documents[documents.length - 1];
    docs.push({ ...doc });
  }
  return docs;
};

const DOC_MAPPER = {
  '0': 'firstDocumentId',
  '1': 'secondDocumentId',
  '2': 'thirdDocumentId',
};

const MultiDocViewer: React.FC<DocViewerParams> = (params) => {
  const t = useTranslation();

  const [state, setState] = useState<DocViewerData>({
    documents: [],
    startAtPage: [],
    searchTerm: [],
    docCountToShow: 0,
  });

  const history = useHistory();

  const { documentsToSelect } = useDocViewerDocumentMetadataList({
    ...params,
    setState,
  });

  const shouldFetchList = !!params.rootId;

  const updateSearchParams = (newParams: Partial<DocViewerParams>) => {
    const newUrl = generateUrl(
      SHARED_ROUTES.DOC_VIEWER,
      undefined,
      prepareQueryParams({
        ...params,
        ...newParams,
      })
    );
    history.push(newUrl);
  };

  const handleDocSelectionChange = useCallback(
    (d: DocumentMetadata, index = 0, searchTerm = '') => {
      setState((prevData) => {
        const newState = { ...prevData };
        const newDocuments = [...prevData.documents];
        newDocuments[index] = d;
        newState.documents = newDocuments;
        const newSearchTerms = [...(prevData.searchTerm ?? [])];
        newSearchTerms[index] = searchTerm ?? '';
        newState.searchTerm = newSearchTerms;
        const queryParam = DOC_MAPPER[`${index}` as keyof typeof DOC_MAPPER];
        updateSearchParams({ [queryParam]: d.id });
        return newState;
      });
    },
    [params]
  );

  /**
   * when user click on a link, we will open the document in the next available viewer
   * if there is no available viewer, we will open the document in the first viewer
   *
   * @param documentId - the id of the document to open (clicked on)
   * @param pageIndex - the page index to open
   * @param docViewerIndex - it indicates which viewer the click comes from
   */
  const onClickNavigationLink = useCallback(
    (documentId: string, pageIndex: number, docViewerIndex: number) => {
      if (!documentsToSelect) {
        return;
      }

      const targetDocument = findDocumentById(documentsToSelect, documentId);
      if (!targetDocument) {
        return;
      }

      let targetDocViewerIndex = docViewerIndex + 1;

      if (targetDocViewerIndex > state.docCountToShow - 1) {
        targetDocViewerIndex = 0;
      }

      handleDocSelectionChange(targetDocument, targetDocViewerIndex);

      setState((prevData) => {
        const startAtPage = prevData.startAtPage || [];

        startAtPage[targetDocViewerIndex] = pageIndex + 1;

        return {
          ...prevData,
          startAtPage,
        };
      });
    },
    [state, documentsToSelect, handleDocSelectionChange]
  );

  const handleViewChange = (count: number) => {
    setState((prevData) => ({
      ...prevData,
      searchTerm: prevData.searchTerm?.slice(0, count),
      docCountToShow: count,
    }));
    updateSearchParams({ defaultVisibleDocCount: count.toString() });
  };

  if ((shouldFetchList && !documentsToSelect) || !state) {
    return <PageLoad message={t('PREPARING_DOCUMENTS')} />;
  }

  const { title, hideHeader } = params;
  const docs = prepareDocs(state);

  return (
    <MultiDocProvider
      currentDocuments={docs}
      documentList={documentsToSelect}
      visibleDocCount={state.docCountToShow}
      openSearchInView={handleDocSelectionChange}
    >
      <Outer>
        {!hideHeader && (
          <MultiDocViewerHeader
            subtitle={title}
            onViewChange={handleViewChange}
          />
        )}
        <Inner>
          {docs.map((d, index) => (
            <SingleDocument
              defaultDocument={d}
              key={`${index}.${d.id}`}
              searchTerm={state.searchTerm?.[index]}
              initialPage={state.startAtPage?.[index]}
              viewerIndex={index}
              documentsToSelect={documentsToSelect}
              onDocumentChange={handleDocSelectionChange}
              onClickNavigationLink={(documentId, pageIndex) => {
                onClickNavigationLink(documentId, pageIndex, index);
              }}
              hideAnnotations={params.hideAnnotations}
            />
          ))}
        </Inner>
      </Outer>
    </MultiDocProvider>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  gap: 20px;
`;

export default MultiDocViewer;
