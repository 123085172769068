import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { BlobFileDownload } from '../types';
import { Api } from '../..';
import { QueryKey } from '.';

// current implementation does not support download of generated docs
const useDocumentAsBlobById = (
  id: string,
  options?: UseQueryOptions<BlobFileDownload, AxiosError>,
  index?: number
): UseQueryResult<BlobFileDownload, AxiosError> =>
  useQuery<BlobFileDownload, AxiosError>(
    [QueryKey.DocumentAsBlobById, id, index],
    () => Api.document.getAsBlobById(id),
    options
  );

export default useDocumentAsBlobById;
