import React, { useState } from 'react';
import styled from 'styled-components';
import TextModulesListItem from './TextModulesListItem';
import SaveTextModuleModal from './SaveTextModuleModal';
import { T } from '@ipos/shared';
import useTranslation from 'translations';

interface Props {
  data: T.TsdElementTextModule[];
  onRemoveRequest: (id: string) => void;
}

const TextModulesList: React.FC<Props> = ({ data, onRemoveRequest }) => {
  const t = useTranslation();
  const [editedItem, setEditedItem] = useState<T.TsdElementTextModule | null>(
    null
  );

  const checkForWarnings = (
    listItems: T.TsdElementTextModule[],
    item: T.TsdElementTextModule
  ): string[] => {
    const warnings: string[] = [];
    // check if text module is doubled
    if (
      listItems.find(
        (d) =>
          d.id != item.id &&
          d.isBaseAttribute &&
          d.textModuleTypeId == item.textModuleTypeId &&
          d.language == item.language &&
          d.text.toLowerCase() == item.text.toLowerCase()
      ) != null
    ) {
      warnings.push(t('ATTRIBUTES.WARNINGS.DOUBLE_TEXT_MODULE_ENTRY'));
    }
    return warnings;
  };

  const handleEditRequest = (item: T.TsdElementTextModule) => {
    setEditedItem(item);
  };

  return (
    <Outer>
      {data.map((d) => (
        <TextModulesListItem
          key={d.id}
          item={d}
          warnings={checkForWarnings(data, d)}
          onRemoveRequest={onRemoveRequest}
          onEditRequest={handleEditRequest}
        />
      ))}
      {editedItem && (
        <SaveTextModuleModal
          textModule={editedItem}
          onCloseRequest={() => setEditedItem(null)}
        />
      )}
    </Outer>
  );
};

export default TextModulesList;

const Outer = styled.div`
  margin-top: 20px;
  flex: 1;
  overflow: hidden auto;
`;
