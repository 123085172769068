import { useEffect, useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { asyncMap } from '../../utils';

interface HookShape {
  (pdf?: PDFDocumentProxy): {
    pagesAspectRatio?: number[] | null;
  };
}

const usePdfPagesAspectRatio: HookShape = (pdf) => {
  const [pagesAspectRatio, setPagesAspectRatio] = useState<number[] | null>([]);

  useEffect(() => {
    setPagesAspectRatio(null);

    if (!pdf) {
      return;
    }

    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextState = await asyncMap(pageNumbers, (pageNumber) =>
        pdf.getPage(pageNumber).then((page) => {
          const { width, height } = page.getViewport({ scale: 1 });
          return height / width;
        })
      );

      setPagesAspectRatio(nextState);
    })();
  }, [pdf]);

  return { pagesAspectRatio };
};

export default usePdfPagesAspectRatio;
