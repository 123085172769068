import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { Popover, Switch, Tooltip } from 'antd';
import { BaseDto, Column } from '../../types';
import { IconButton } from '../Button';
import useTranslation from '../../translations';
import { Colors } from '@ipos/shared';
import './ColumnCustomize.less';

interface Props<TItem> {
  columns: Column<TItem>[];
  onColumnVisibilityChange: (column: Column<TItem>, checked: boolean) => void;
  onColumnOrderChange: (
    column: Column<TItem>,
    oldIndex: number,
    newIndex: number
  ) => void;
}

const ColumnCustomize = <TItem extends BaseDto>({
  columns,
  onColumnOrderChange,
  onColumnVisibilityChange,
}: Props<TItem>): React.ReactElement | null => {
  const [popupVisible, setPopupVisible] = useState(false);
  const t = useTranslation();

  const hasLabel = (c: Column<TItem>) =>
    Boolean((c.title && typeof c.title === 'string') || c.titleText);

  const columnsFixedLeft: Column<TItem>[] = useMemo(
    () => columns.filter((c) => c.fixed === 'left'),
    [columns]
  );

  const columnsFixedRight: Column<TItem>[] = useMemo(
    () => columns.filter((c) => c.fixed === 'right'),
    [columns]
  );

  const columnsNotFixed: Column<TItem>[] = useMemo(
    () => columns.filter((c) => !c.fixed),
    [columns]
  );

  const onDragEnd = (result: DropResult) => {
    const FIXED_LEFT = 'droppableFixedLeft';
    const FIXED_RIGHT = 'droppableFixedRight';

    if (
      !result.destination ||
      (result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index)
    ) {
      return;
    }

    const column = columns.find((c) => c.key === result.draggableId);
    const isTryingToPinHiddenColumn =
      column &&
      column.hide &&
      (result.destination.droppableId === FIXED_LEFT ||
        result.destination.droppableId === FIXED_RIGHT);

    if (!column || isTryingToPinHiddenColumn) {
      return;
    }

    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;

    if (result.source.droppableId === 'droppable') {
      sourceIndex += columnsFixedLeft.length;
    }

    if (result.destination.droppableId === 'droppable') {
      destinationIndex += columnsFixedLeft.length;
    }

    if (result.source.droppableId === FIXED_RIGHT) {
      sourceIndex += columnsFixedLeft.length + columnsNotFixed.length;
    }

    if (result.destination.droppableId === FIXED_RIGHT) {
      destinationIndex += columnsFixedLeft.length + columnsNotFixed.length;
    }

    column.fixed =
      result.destination.droppableId === FIXED_LEFT
        ? 'left'
        : result.destination.droppableId === FIXED_RIGHT
        ? 'right'
        : undefined;

    onColumnOrderChange(column, sourceIndex, destinationIndex);
  };

  return (
    <Popover
      trigger="click"
      open={popupVisible}
      onOpenChange={(visible) => {
        setPopupVisible(visible);
      }}
      overlayClassName="popover-column-customize-overlay"
      destroyTooltipOnHide
      arrowPointAtCenter
      placement={'bottomRight'}
      content={
        <PopoverContainer data-testid="item-actions-popover">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableFixedLeft">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {(columnsFixedLeft.length === 0 ||
                    columnsFixedLeft.every((column) => !hasLabel(column))) && (
                    <EmptyState
                      style={{ marginBottom: 6, textAlign: 'center' }}
                    >
                      {t('DRAG_TO_FIX', { direction: 'left' })}
                    </EmptyState>
                  )}

                  {columnsFixedLeft.length > 0 &&
                    columnsFixedLeft.map((column, index) => {
                      if (!hasLabel(column)) {
                        return null;
                      }

                      return (
                        <Draggable
                          key={column.key}
                          draggableId={column.key}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <DraggableRowContainer
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                              className="draggable-row-container"
                            >
                              <div className="drag-handle">
                                <i className="icn-drag" />
                              </div>
                              <Row
                                column={column}
                                disabledText={t('UNPIN_COLUMN')}
                                onColumnVisibilityChange={
                                  onColumnVisibilityChange
                                }
                                disabled
                              />
                            </DraggableRowContainer>
                          )}
                        </Draggable>
                      );
                    })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Separator />

            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {columnsNotFixed.map((column, index) => {
                    if (!hasLabel(column)) {
                      return null;
                    }

                    return (
                      <Draggable
                        key={column.key}
                        draggableId={column.key}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <DraggableRowContainer
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                            className="draggable-row-container"
                          >
                            <div className="drag-handle">
                              <i className="icn-drag" />
                            </div>
                            <Row
                              column={column}
                              disabledText={t('CLEAR_APPLIED_FILTER')}
                              onColumnVisibilityChange={
                                onColumnVisibilityChange
                              }
                            />
                          </DraggableRowContainer>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Separator />

            <Droppable droppableId="droppableFixedRight">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {(columnsFixedRight.length === 0 ||
                    columnsFixedRight.every((column) => !hasLabel(column))) && (
                    <EmptyState style={{ marginTop: 6, textAlign: 'center' }}>
                      {t('DRAG_TO_FIX', { direction: 'right' })}
                    </EmptyState>
                  )}
                  {columnsFixedRight.map((column, index) => {
                    if (!hasLabel(column)) {
                      return null;
                    }

                    return (
                      <Draggable
                        key={column.key}
                        draggableId={column.key}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <DraggableRowContainer
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                            className="draggable-row-container"
                          >
                            <div className="drag-handle">
                              <i className="icn-drag" />
                            </div>
                            <Row
                              column={column}
                              disabledText={t('UNPIN_COLUMN')}
                              onColumnVisibilityChange={
                                onColumnVisibilityChange
                              }
                              disabled
                            />
                          </DraggableRowContainer>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </PopoverContainer>
      }
    >
      <SettingButtonWrapper>
        <Tooltip title={t('SETTINGS')} color={Colors.primary300}>
          <IconButton className="icn-settings" />
        </Tooltip>
      </SettingButtonWrapper>
    </Popover>
  );
};

interface RowProps<TItem> {
  column: Column<TItem>;
  disabledText: string;
  onColumnVisibilityChange: (column: Column<TItem>, checked: boolean) => void;
  disabled?: boolean;
}

const Row = <TItem extends BaseDto>({
  column,
  disabledText,
  onColumnVisibilityChange,
  disabled = false,
}: RowProps<TItem>) => {
  const isDisabled = disabled || !column.hideable;
  const switchComponent = (
    <Switch
      size="small"
      checked={!column.hide}
      disabled={isDisabled}
      onChange={(checked) => {
        onColumnVisibilityChange(column, checked);
      }}
    />
  );

  return (
    <PopoverRow>
      {typeof column.title === 'string' ? column.title : column.titleText}
      {isDisabled && disabledText ? (
        <Tooltip title={disabledText} color={Colors.primary300}>
          {switchComponent}
        </Tooltip>
      ) : (
        switchComponent
      )}
    </PopoverRow>
  );
};

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  border-radius: 12px;
  padding: 20px 0;
  max-height: 60vh;
  overflow-y: auto;

  .drag-handle {
    visibility: hidden;
    display: flex;
    align-items: center;

    i {
      font-size: 18px;
    }
  }

  .draggable-row-container:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }

  .draggable-row-container:hover .drag-handle {
    visibility: visible;
  }
`;

const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 8px;
  flex: 1;

  .ant-switch-checked {
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;

const DraggableRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white20};
  display: inline-block;
  margin: 10px 0;
`;

const EmptyState = styled.div`
  color: ${(props) => props.theme.colors.white40};
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
`;

const SettingButtonWrapper = styled.div`
  button {
    width: 34px;
    height: 34px;
    border-radius: 3px;
  }
`;

export default ColumnCustomize;
