import cx from 'classnames';
import React, { MouseEventHandler, useCallback, useRef } from 'react';
import { fromHTMlElement } from '../util/rects';
import { clamp } from '../../images/imageViewPlugin/utils';
import { Colors } from '../../../../theme';

export type TableGridSizeEditorValue = {
  cols: number;
  rows: number;
};

const GUTTER_SIZE = 4;
const CELL_SIZE = 24;
const MAX_SIZE = 20;

interface Props {
  x: number;
  y: number;
  selected: boolean;
}

const GridCell: React.FC<Props> = ({ x, y, selected }) => {
  const style = {
    left: x + 'px',
    top: y + 'px',
    width: CELL_SIZE + 'px',
    height: CELL_SIZE + 'px',
  };
  const className = cx('table-grid-size-editor-cell', {
    selected,
  });
  return <div className={className} style={style} />;
};

interface TableGridSizeProps {
  onSelect: (val: TableGridSizeEditorValue) => void;
}

const TableGridSizeEditor = React.forwardRef<
  HTMLDivElement,
  TableGridSizeProps
>((props, elRef) => {
  const { onSelect } = props;
  const exRef = useRef(0);
  const eyRef = useRef(0);
  const mxRef = useRef(0);
  const myRef = useRef(0);
  const rafIDRef = useRef(0);
  const enteredRef = useRef(false);

  const [state, setState] = React.useState<TableGridSizeEditorValue>({
    rows: 1,
    cols: 1,
  });

  const { rows, cols } = state;
  let rr = Math.max(5, rows);
  let cc = Math.max(5, cols);
  if (rr === rows) {
    rr = Math.min(MAX_SIZE, rr + 1);
  }
  if (cc === cols) {
    cc = Math.min(MAX_SIZE, cc + 1);
  }
  const cells = [];
  let ii = 0;
  let y = 0;
  let w = 0;
  let h = 0;
  while (ii < rr) {
    y += GUTTER_SIZE;
    let jj = 0;
    let x = 0;
    while (jj < cc) {
      x += GUTTER_SIZE;
      const selected = ii < rows && jj < cols;
      cells.push(
        <GridCell
          key={`${String(ii)}-${String(jj)}`}
          selected={selected}
          x={x}
          y={y}
        />
      );
      x += CELL_SIZE;
      w = x + GUTTER_SIZE;
      jj++;
    }
    y += CELL_SIZE;
    h = y + GUTTER_SIZE;
    ii++;
  }
  const bodyStyle = { width: w + 'px', height: h + 'px' };
  const _onMouseEnter: MouseEventHandler<HTMLDivElement> = useCallback(
    (e): void => {
      const node = e.currentTarget;
      if (node instanceof HTMLElement) {
        const rect = fromHTMlElement(node);
        const mx = Math.round(e.clientX);
        const my = Math.round(e.clientY);
        exRef.current = rect.x;
        eyRef.current = rect.y;
        mxRef.current = mx;
        myRef.current = my;
        if (!enteredRef?.current) {
          enteredRef.current = true;
          document.addEventListener('mousemove', _onMouseMove, true);
        }
      }
    },
    []
  );
  const _updateGridSize = useCallback((): void => {
    rafIDRef.current = 0;
    const mx = mxRef.current;
    const my = myRef.current;
    const x = mx - exRef.current;
    const y = my - eyRef.current;
    const rr = clamp(1, Math.ceil(y / (CELL_SIZE + GUTTER_SIZE)), MAX_SIZE);
    const cc = clamp(1, Math.ceil(x / (CELL_SIZE + GUTTER_SIZE)), MAX_SIZE);
    const { rows, cols } = state;
    if (rows !== rr || cols !== cc) {
      setState({ rows: rr, cols: cc });
    }
  }, [state]);

  const _onMouseMove = useCallback(
    (e: MouseEvent): void => {
      const mx = Math.round(e.clientX);
      const my = Math.round(e.clientY);
      if (mx !== mxRef.current || my !== myRef.current) {
        mxRef.current = mx;
        myRef.current = my;
        rafIDRef?.current && cancelAnimationFrame(rafIDRef.current);
        rafIDRef.current = requestAnimationFrame(_updateGridSize);
      }
    },
    [_updateGridSize]
  );

  const _onMouseDown = (): void => {
    onSelect(state);
  };

  return (
    <div className="table-grid-size-editor" ref={elRef}>
      <div
        className="table-grid-size-editor-body"
        onMouseDown={_onMouseDown}
        onMouseEnter={_onMouseEnter}
        style={bodyStyle}
      >
        {cells}
      </div>
      <div className="table-grid-size-editor-footer">
        {rows} <span style={{ color: Colors.white50, fontWeight: 400 }}>x</span>{' '}
        {cols}
      </div>
    </div>
  );
});

export default TableGridSizeEditor;
