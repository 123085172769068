import React, { useState } from 'react';
import { Button } from 'antd';
import SaveTextModuleModal from './SaveTextModuleModal';
import { Permissions } from '@ipos/shared';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

const AddTextModuleButton: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={handleOpenRequest} type="primary">
        {t('TEXT_MODULES.ADD_BUTTON')}
      </Button>
      {isModalOpen && (
        <SaveTextModuleModal onCloseRequest={handleCloseRequest} />
      )}
    </WithPermission>
  );
};

export default AddTextModuleButton;
