import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Switch, Tooltip } from 'antd';
import { Popover } from 'react-tiny-popover';
import useTranslation from '../../../translations';
import { Title } from '../../Atoms/Atoms';
import { DocumentMetadata } from '../../../types';
import DownloadTool from './DownloadTool';
import { DOWNLOAD_ID, TEXT_SELECTION_TOOL_ID } from './Toolbar';
import { FlexCenter, ToggleIcon, ToolbarIcon } from './styled';

export interface PopoverItemProps {
  id: string;
  title: string;
  iconClassName: string;
  isVisible: boolean;
  onClick?: (e: React.MouseEvent) => void;
  active?: boolean;
}

interface Props {
  items: PopoverItemProps[];
  document: DocumentMetadata;
  parentElementRef?: React.RefObject<HTMLElement>;
}

const ViewMoreMenu: React.FC<Props> = ({
  items,
  document,
  parentElementRef,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const t = useTranslation();
  const theme = useTheme();

  if (!items.length) {
    return null;
  }
  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const handleItemClick = (
    e: React.MouseEvent,
    onClickHandler?: (e: React.MouseEvent) => void,
    autoClosePopover = true
  ) => {
    onClickHandler?.(e);
    autoClosePopover && closePopover();
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left']}
      align="end"
      parentElement={parentElementRef?.current ?? undefined}
      onClickOutside={closePopover}
      clickOutsideCapture
      reposition
      content={
        <PopoverContainer onClick={(e) => e.stopPropagation()}>
          {items.map((item) => {
            const popoverItemContent = (
              <>
                <StyledDropdownIcon className={item.iconClassName} />
                <StyledDropdownText>{item.title}</StyledDropdownText>
                {item.id === TEXT_SELECTION_TOOL_ID && (
                  <StyledSwitch size="small" checked={item.active} />
                )}
              </>
            );
            return (
              <PopoverItem
                key={item.id}
                onClick={(e) =>
                  handleItemClick(e, item.onClick, item.id !== DOWNLOAD_ID)
                }
              >
                {item.id === DOWNLOAD_ID ? (
                  <DownloadTool
                    document={document}
                    toggleIcon={
                      <StyledDropdownIcon className={item.iconClassName} />
                    }
                    helperText={
                      <StyledDropdownText>{item.title}</StyledDropdownText>
                    }
                  />
                ) : (
                  popoverItemContent
                )}
              </PopoverItem>
            );
          })}
        </PopoverContainer>
      }
    >
      <FlexCenter
        style={{ marginLeft: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
          togglePopover();
        }}
      >
        <Tooltip
          title={t('MORE')}
          color={theme.colors.primary300}
          overlayClassName="action-button-tooltip"
          align={{
            offset: [0, 5],
          }}
        >
          <ToggleIcon className="icn-more-horizontal" />
        </Tooltip>
      </FlexCenter>
    </Popover>
  );
};

export default ViewMoreMenu;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
`;

const PopoverItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  margin: -8px -18px;
  transition: background 0.2s ease-in-out;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
      cursor: not-allowed;
      background-color: ${props.theme.colors.white10};
    `};
  &:hover {
    background: ${(props) => props.theme.colors.white10};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-left: 31px;
  pointer-events: none;
`;

const StyledDropdownText = styled(Title)`
  font-weight: 400;
`;

const StyledDropdownIcon = styled(ToolbarIcon)`
  margin-right: 12px;
`;
