import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import { Utils } from '@ipos/shared';

const { truncate } = Utils;

interface Props {
  name: string;
}

const TsdTreeTag: React.FC<Props> = ({ name }) => (
  <StyledTag title={name}>{name}</StyledTag>
);

export default TsdTreeTag;

const StyledTag = styled(Tag)`
  margin-bottom: 4px;
  background-color: rgba(48, 181, 238, 0.2);
  border-radius: 3px;
  border-color: rgba(255, 255, 255, 0.25);
  ${truncate()}
`;
