import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

function useStartDocumentOcr(
  options?: UseMutationOptions<T.DocumentOcrProcess, AxiosError, string>
): UseMutationResult<T.DocumentOcrProcess, AxiosError, string> {
  return useMutation<T.DocumentOcrProcess, AxiosError, string>(
    (documentId) => Api.document.startPdfOcrProcess(documentId),
    options
  );
}

export default useStartDocumentOcr;
