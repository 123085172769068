import React from 'react';
import QueryBuilderSegment from '../Common/QueryBuilderSegment';
import Item from './SynonymsListItem';
import Header from './SynonymsListHeader';
import { QueryBaseItem } from 'types';
import { useQueryContext } from 'components/TsdElement/Attributes/Query/QueryContext';
import useTranslation from 'translations';

interface Props {
  items: QueryBaseItem[];
}

const QueryBuilderSynonyms: React.FC<Props> = ({ items }) => {
  const { query } = useQueryContext();
  const t = useTranslation();
  return (
    <QueryBuilderSegment header={<Header />} title={t('QUERY.SYNONYM')}>
      {items.map((item) => {
        const selectedItem = query?.synonyms.find((c) => c.id === item.id);

        return (
          <Item
            key={item.id}
            item={item}
            selected={!!selectedItem}
            isTruncatedAtStart={selectedItem?.isTruncatedAtStart}
            isTruncatedAtEnd={selectedItem?.isTruncatedAtEnd}
            searchInAbstract={selectedItem?.searchInAbstract}
            searchInClaims={selectedItem?.searchInClaims}
            searchInDescription={selectedItem?.searchInDescription}
            searchInTitle={selectedItem?.searchInTitle}
          />
        );
      })}
    </QueryBuilderSegment>
  );
};

export default QueryBuilderSynonyms;
