import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useTsdElementTextModules = (
  tsdElementId: string,
  tsdNodeId?: string,
  language?: T.Language,
  options?: UseQueryOptions<T.TsdElementTextModule[], AxiosError>
): UseQueryResult<T.TsdElementTextModule[], AxiosError> =>
  useQuery<T.TsdElementTextModule[], AxiosError>(
    [QueryKey.TsdElementTextModules, tsdElementId, tsdNodeId, language],
    () => Api.tsd.getTsdElementTextModules(tsdElementId, tsdNodeId, language),
    options
  );

export default useTsdElementTextModules;
