import axios, { AxiosResponse } from 'axios';
import { AccountInfo } from '@azure/msal-browser';
import {
  getCurrentLanguageValue,
  getFileNameFromContentDispositionHeader,
} from '../utils';
import { showUnauthorisedNotification } from '../components/Session';
import { msalInstance, authRequest } from '../auth/msalInstance';

export const handleBlobResponse = (
  response: AxiosResponse<Blob>
): [Blob, string] => [
  response.data,
  getFileNameFromContentDispositionHeader(
    response.headers['content-disposition']
  ),
];

export const iposApi =
  // @ts-ignore
  window.IPOS_BACKEND_API || process.env.NX_PUBLIC_API_BASE_URL;

const instance = axios.create({
  baseURL: iposApi as string,
});

instance.interceptors.request.use(async (config) => {
  config.headers['accept-language'] = getCurrentLanguageValue();
  if (process.env.NODE_ENV === 'test') {
    console.log(
      'I tried to make a network request, you need to mock this request',
      config
    );
    // TODO: Check the tests and consider throwing here
  }
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      const authResult = await msalInstance.acquireTokenSilent({
        ...authRequest,
        account: accounts[0] as AccountInfo,
      });
      const { accessToken } = authResult;

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  } catch (error) {
    showUnauthorisedNotification();
    throw error;
  }
});

export default instance;
