import React, { FC, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { isNumberValid } from '../util';
import { Props } from './Toolbar';
import { FlexCenter, StyledInput, ToolbarIcon } from './styled';

interface ZoomTool extends Pick<Props, 'currentZoom' | 'setZoom'> {
  showZoomDropdown: boolean;
}

const ZOOM_VALUES = [50, 70, 85, 100, 120, 150, 175, 200, 300, 400];

function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

function findClosestZoomIndex(currentZoom: number): number {
  const currentZoomIndex = ZOOM_VALUES.findIndex((z) => z === currentZoom);
  if (currentZoomIndex !== -1) {
    return currentZoomIndex;
  }

  let distance = Infinity;
  return ZOOM_VALUES.reduce((prev, zoomValue, index) => {
    if (Math.abs(currentZoom - zoomValue) < distance) {
      distance = Math.abs(currentZoom - zoomValue);
      return index;
    }
    return prev;
  }, -1);
}

const ZoomTool: FC<ZoomTool> = ({ currentZoom, setZoom, showZoomDropdown }) => {
  const [zoomInputValue, setZoomInputValue] = useState<string>(
    `${currentZoom}`
  );
  const zoomRef = useRef<HTMLInputElement>(null);

  const updateZoom = (newZoom: number) => {
    setZoom(newZoom);
    setZoomInputValue(`${newZoom}`);
  };

  useEffect(() => {
    const callback = (e: WheelEvent) => {
      const scrollingUp = e.deltaY < 0;
      const delta = scrollingUp ? 5 : -5;
      const nextZoomRounded = Math.round((currentZoom + delta - 1) / 5) * 5;
      updateZoom(nextZoomRounded);
    };

    zoomRef?.current?.addEventListener('wheel', callback);
    return () => {
      zoomRef?.current?.removeEventListener('wheel', callback);
    };
  }, [zoomRef, currentZoom, updateZoom]);

  const onZoomIn = () => {
    const currentZoomIndex = findClosestZoomIndex(currentZoom);
    if (currentZoomIndex + 1 === ZOOM_VALUES.length) {
      return;
    }
    updateZoom(ZOOM_VALUES[currentZoomIndex + 1]);
  };

  const onZoomOut = () => {
    const currentZoomIndex = findClosestZoomIndex(currentZoom);
    if (currentZoomIndex - 1 === -1) {
      return;
    }
    updateZoom(ZOOM_VALUES[currentZoomIndex - 1]);
  };

  useEffect(() => {
    const handlerTimer = setTimeout(() => {
      onZoomInputChange(zoomInputValue);
    }, 500);
    return () => {
      clearTimeout(handlerTimer);
    };
  }, [zoomInputValue]);

  const onZoomInputChange = (v: string) => {
    const parsedValue = parseInt(v);
    if (!isNumberValid(parsedValue) || parsedValue < 0) {
      return;
    }
    const clampedValue = clamp(
      parsedValue,
      ZOOM_VALUES[0],
      ZOOM_VALUES[ZOOM_VALUES.length - 1]
    );
    updateZoom(clampedValue);
  };

  const onZoomMenuSelect: MenuClickEventHandler = (e) => {
    updateZoom(parseInt(e.key as string));
  };

  return (
    <ZoomControls>
      <ToolbarIcon
        style={{ marginRight: '4px' }}
        className="icn-remove-outlined"
        onClick={onZoomOut}
      />
      <ToolbarIcon
        style={{ marginRight: '4px' }}
        className="icn-add-outlined"
        onClick={onZoomIn}
      />
      {showZoomDropdown && (
        <Dropdown
          trigger={['click']}
          menu={{
            items: ZOOM_VALUES.map((v) => ({
              label: `${v}%`,
              key: v,
            })),
            onClick: onZoomMenuSelect,
          }}
        >
          <FlexCenter>
            <ZoomInput
              type="number"
              onChange={(e) => setZoomInputValue(e.target.value)}
              ref={zoomRef}
              value={zoomInputValue}
            />
            <span>%</span>
          </FlexCenter>
        </Dropdown>
      )}
    </ZoomControls>
  );
};

const ZoomControls = styled.div`
  display: flex;
  align-items: center;
`;

const ZoomInput = styled(StyledInput)`
  width: 43px;
  margin: 0 5px 0 3px;
  -moz-appearance: textfield !important;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
`;

export default ZoomTool;
