import React, { useRef, useEffect } from 'react';
import { Typography, Button } from 'antd';
import { UseInfiniteQueryResult } from 'react-query';
import styled from 'styled-components';
import TsdElementSearchResultsItem from './TsdElementSearchResultsItem';
import { T, Components, Hooks } from '@ipos/shared';
import useTranslation from 'translations';

const { useIntersectionObserver, useForceUpdate } = Hooks;
const { Notification, ObservedTarget } = Components;
const { Text } = Typography;

interface Props {
  tsdElements: T.TsdElementBasicInfo[];
  tsdElementsQuery: UseInfiniteQueryResult<T.TsdElementListPage>;
  onPopoverOpen: (container: HTMLDivElement | null) => void;
  onSelectElement: (tsdElementId: string) => void;
  onCreateNew: () => void;
}

const TsdElementSearchResults: React.FC<Props> = ({
  tsdElements,
  tsdElementsQuery,
  onPopoverOpen,
  onSelectElement,
  onCreateNew,
}) => {
  const t = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  // Component has to update after initial render,
  // so that intersection observer can acquire non-null refs
  const { forceUpdate } = useForceUpdate();

  const { isLoading, isFetchingNextPage, isError, hasNextPage, fetchNextPage } =
    tsdElementsQuery;

  useIntersectionObserver({
    target: targetRef?.current,
    onIntersect: fetchNextPage,
    root: containerRef?.current,
    threshold: 0.1,
    enabled: hasNextPage,
  });

  useEffect(() => {
    onPopoverOpen(containerRef.current);
    forceUpdate();
  }, []);

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <Notification spin />;
      case isError:
        return <Notification error />;
      default:
        return tsdElements.map((tsdElement: T.TsdElementBasicInfo) => (
          <TsdElementSearchResultsItem
            key={tsdElement.id}
            tsdElement={tsdElement}
            onSelectElement={onSelectElement}
          />
        ));
    }
  };

  return (
    <Outer ref={containerRef}>
      <Header>
        <Button
          type="primary"
          onClick={onCreateNew}
          style={{ alignSelf: 'flex-end' }}
        >
          {t('CREATE_ELEMENT.CREATE_NEW')}
        </Button>
        <Text strong>{t('CREATE_ELEMENT.LIBRARY_ELEMENTS')}</Text>
      </Header>
      {getContent()}
      <ObservedTarget targetRef={targetRef} loading={isFetchingNextPage} />
    </Outer>
  );
};

export default TsdElementSearchResults;

const Outer = styled.div`
  height: 450px;
  width: 570px;
  background-color: #1f1f1f;
  overflow: auto;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%),
    0 9px 28px 8px rgb(0 0 0 / 20%);
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  background-color: #1f1f1f;
`;
