import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { DocumentMetadata, Language } from '../../../types';
import { QueryKey } from '../../../queries';
import { DEEPL_SUPPORTED_LANGUAGES } from '../../../constants';
import { getTranslations } from '../../../api/document';
import {
  TranslationPicker,
  TranslationRangeSelectModal,
} from '../../Translations';

interface Props {
  document: DocumentMetadata;
  onLanguageChange: (
    language: Language,
    pageNumbers?: string,
    isRetranslate?: boolean
  ) => void;
}

const TranslationTool: React.FC<Props> = ({ document, onLanguageChange }) => {
  const [translateRangeLanguage, setTranslateRangeLanguage] =
    useState<Language | null>(null);
  const [isRetranslate, setIsRetranslate] = useState(false);

  const originalDocument = document.parentDocument ?? document;

  const translationStateQuery = useQuery(
    [QueryKey.DocTranslations, originalDocument.id],
    () => getTranslations(originalDocument.id)
  );

  const availableTranslations = useMemo(() => {
    if (!translationStateQuery.data) {
      return [];
    }

    const langs = Object.keys(translationStateQuery.data) as Language[];
    return langs.filter((l) => l !== originalDocument.contentLanguage);
  }, [translationStateQuery.data]);

  const partiallyTranslatedLanguages = useMemo(() => {
    if (!translationStateQuery.data) {
      return [];
    }

    const langs: Language[] = [];

    Object.entries(translationStateQuery.data).forEach(([lang, value]) => {
      if (!value.translatedPageIndices) {
        return;
      }

      if (value.numPages !== value.translatedPageIndices.length) {
        langs.push(lang as Language);
      }
    });

    return langs;
  }, [translationStateQuery.data]);

  const activeLanguage = document.contentLanguage;
  const authenticLanguage = document.parentDocument
    ? document.parentDocument.contentLanguage
    : document.contentLanguage;

  const handleLanguageChange = (language: Language) => {
    if (
      language === authenticLanguage ||
      availableTranslations.includes(language)
    ) {
      onLanguageChange(language);
    } else {
      setTranslateRangeLanguage(language);
    }
  };

  const closeTranslateRangeModal = () => {
    setTranslateRangeLanguage(null);
    setIsRetranslate(false);
  };

  const handleTranslateRange = (pageNumbers?: string) => {
    if (!translateRangeLanguage) {
      return;
    }
    onLanguageChange(translateRangeLanguage, pageNumbers, isRetranslate);
    closeTranslateRangeModal();
  };

  const handleRetranslate = (language: Language) => {
    setTranslateRangeLanguage(language);
    setIsRetranslate(true);
  };

  return (
    <>
      <TranslationPicker
        languages={DEEPL_SUPPORTED_LANGUAGES.filter(
          (l) => l !== originalDocument.contentLanguage
        )}
        availableTranslations={availableTranslations}
        partiallyTranslatedLanguages={partiallyTranslatedLanguages}
        onChange={handleLanguageChange}
        onRetranslate={handleRetranslate}
        authenticLanguage={authenticLanguage}
        activeLanguage={activeLanguage}
      />
      {translateRangeLanguage && (
        <TranslationRangeSelectModal
          authenticLanguage={authenticLanguage}
          targetLanguage={translateRangeLanguage}
          onTranslate={handleTranslateRange}
          onClose={closeTranslateRangeModal}
        />
      )}
    </>
  );
};

export default TranslationTool;
