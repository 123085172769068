import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../../../translations';
import { useMultiDocContext } from '../../../MultiDocContext';
import SearchResultsListItemAction from './SearchResultsListItemAction';

interface Props {
  hitCount: number;
  onOpenSearchInView: (index: number) => void;
}

const SearchResultsListItemHits: React.FC<Props> = ({
  hitCount,
  onOpenSearchInView,
}) => {
  const t = useTranslation();
  const { visibleDocCount } = useMultiDocContext();

  return (
    <Outer>
      <Actions className="search-result-item__actions">
        {Array.from({ length: Math.min(visibleDocCount, 3) }, (_, i) => (
          <SearchResultsListItemAction
            key={i}
            activePosition={i + 1}
            visibleDocCount={visibleDocCount}
            onClick={onOpenSearchInView}
          />
        ))}
      </Actions>
      <Hits>
        {hitCount} {t('MULTI_DOC_SEARCH.HIT')}
      </Hits>
    </Outer>
  );
};

export default SearchResultsListItemHits;

const Outer = styled.div`
  display: flex;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  visibility: hidden;
`;

const Hits = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 60px;
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.theme.colors.white100};
`;
