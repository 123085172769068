import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import SHARED_ROUTES, { generateUrl } from '../../../utils/routing';
import useTranslation from '../../../translations';

export enum AuthAction {
  Signup = 'Signup',
  Login = 'Login',
}

const CONFIG = {
  [AuthAction.Signup]: {
    tkey: 'SIGNUP.PROMPT',
    to: generateUrl(SHARED_ROUTES.SIGNUP),
  },
  [AuthAction.Login]: {
    tkey: 'LOGIN.PROMPT',
    to: generateUrl(SHARED_ROUTES.LOGIN),
  },
};

interface Props {
  action: AuthAction;
}

const Prompt: React.FC<Props> = ({ action }) => {
  const t = useTranslation();
  const { tkey, to } = CONFIG[action];

  return (
    <Outer>
      <Trans
        t={t}
        i18nKey={tkey}
        components={{
          tag: <StyledLink to={to} />,
        }}
      />
    </Outer>
  );
};

export default Prompt;

const Outer = styled.div`
  margin-top: auto;
  color: ${(props) => props.theme.colors.white50};
  line-height: 1.71;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
  &:focus,
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.white87};
    text-decoration: underline;
  }
`;
