import { Schema } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { isListNode } from 'prosemirror-flat-list';
import {
  BLOCKQUOTE,
  HEADING,
  LIST,
  PARAGRAPH,
} from '../../schema/nodes/nodeNames';

import compareNumber from './compareNumber';
import isInsideListItem from './isInsideListItem';

export default function toggleHeading(
  tr: Transaction,
  schema: Schema,
  level: number
): Transaction {
  const { nodes } = schema;
  const { selection, doc } = tr;

  const blockquote = nodes[BLOCKQUOTE];
  const heading = nodes[HEADING];
  const listItem = nodes[LIST];
  const paragraph = nodes[PARAGRAPH];

  if (
    !selection ||
    !doc ||
    !heading ||
    !paragraph ||
    !listItem ||
    !blockquote
  ) {
    return tr;
  }

  const { from, to } = tr.selection;
  let startWithHeadingBlock: boolean | null = null;
  const positions: number[] = [];
  doc.nodesBetween(from, to, (node, pos, parentNode) => {
    const nodeType = node.type;
    const parentNodeType = parentNode?.type;

    if (startWithHeadingBlock === null) {
      startWithHeadingBlock =
        nodeType === heading && node.attrs.level === level;
    }

    if (parentNodeType !== listItem) {
      positions.push(pos);
    }
    return !isListNode(node);
  });
  // Update from the bottom to avoid disruptive changes in pos.
  positions
    .sort(compareNumber)
    .reverse()
    .forEach((pos) => {
      tr = setHeadingNode(
        tr,
        schema,
        pos,
        startWithHeadingBlock ? null : level
      );
    });
  return tr;
}

function setHeadingNode(
  tr: Transaction,
  schema: Schema,
  pos: number,
  level?: number | null
): Transaction {
  const { nodes } = schema;
  const heading = nodes[HEADING];
  const paragraph = nodes[PARAGRAPH];
  const blockquote = nodes[BLOCKQUOTE];
  if (pos >= tr.doc.content.size) {
    // Workaround to handle the edge case that pos was shifted caused by `toggleList`.
    return tr;
  }
  const node = tr.doc.nodeAt(pos);
  if (!node || !heading || !paragraph || !blockquote) {
    return tr;
  }
  const nodeType = node.type;
  if (isInsideListItem(tr.doc, pos)) {
    return tr;
  } else if (isListNode(node)) {
    // Toggle list
    if (heading && level !== null) {
      // TODO support toggle list to heading
    }
  } else if (nodeType === heading) {
    // Toggle heading
    if (level === null) {
      tr = tr.setNodeMarkup(pos, paragraph, node.attrs, node.marks);
    } else {
      tr = tr.setNodeMarkup(pos, heading, { ...node.attrs, level }, node.marks);
    }
  } else if ((level && nodeType === paragraph) || nodeType === blockquote) {
    tr = tr.setNodeMarkup(pos, heading, { ...node.attrs, level }, node.marks);
  }
  return tr;
}
