import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useTsdElementSynonyms = (
  tsdElementId: string,
  tsdNodeId?: string,
  options?: UseQueryOptions<T.TsdElementSynonym[], AxiosError>
): UseQueryResult<T.TsdElementSynonym[], AxiosError> =>
  useQuery<T.TsdElementSynonym[], AxiosError>(
    [QueryKey.TsdElementSynonyms, tsdElementId, tsdNodeId],
    () => Api.tsd.getTsdElementSynonynms(tsdElementId, tsdNodeId),
    options
  );

export default useTsdElementSynonyms;
