import React from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';

import CompanyGroupDetails from './CompanyGroupDetails';
import { T, Components } from '@ipos/shared';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';
import useTranslation from 'translations';

const { CountryFlag } = Components;

interface Props {
  warnings?: string[];
  item: T.TsdElementCompanyGroup;
  onRemoveRequest: (id: string) => void;
}

const CompanyListItem: React.FC<Props> = ({
  warnings,
  item,
  onRemoveRequest,
}) => {
  const t = useTranslation();
  const { companyGroup, id, isBaseAttribute } = item;
  const { name, countryCode } = companyGroup;

  return (
    <TsdElementAttributeListItem
      warnings={warnings}
      isBaseAttribute={isBaseAttribute}
      onRemoveRequest={() => onRemoveRequest(id)}
      confirmRemovalTitle={t('COMPANIES.DELETE_PROMPT')}
    >
      <Popover
        content={<CompanyGroupDetails data={companyGroup} />}
        trigger="hover"
      >
        <Outer>
          <CountryFlag countryCode={countryCode} />
          <Name>{name}</Name>
        </Outer>
      </Popover>
    </TsdElementAttributeListItem>
  );
};

export default CompanyListItem;

const Outer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
`;

const Name = styled.div`
  margin-left: 10px;
`;
