import { authRequest, msalInstance } from '../auth/msalInstance';

const registerServiceWorker = () => {
  const isServiceWorkerSupported = 'serviceWorker' in navigator;

  if (!isServiceWorkerSupported) {
    console.log('Service workers are not supported.');
    return;
  }

  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(
      function () {
        // @ts-ignore
        // console.log('Service worker registration succeeded:', registration);
      },
      (error) => {
        console.log('Service worker registration failed:', error);
      }
    );
  });
};

if ('serviceWorker' in navigator) {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker
      .getRegistration(navigator.serviceWorker.controller.scriptURL)
      .then(function (sw) {
        if (sw) {
          sw.unregister().then(() => {
            navigator.serviceWorker.register('sw.js');
          });
        }
      });
  } else {
    const url =
      window.location.protocol + '//' + window.location.host + '/sw.js';
    navigator.serviceWorker.getRegistration(url).then(function (sw) {
      if (sw) {
        sw.unregister().then(() => {
          navigator.serviceWorker.register('sw.js');
        });
      } else {
      }
    });
  }
}

if (process.env.NODE_ENV !== 'test') {
  const channel = new BroadcastChannel('TOKEN_EXCHANGE');

  channel.onmessage = (e) => {
    // console.log('[[CLIENT]] Received message from token exchange', e);
    if (e.data.type === 'TOKEN_REQUEST') {
      const account = msalInstance.getAllAccounts()[0];
      msalInstance
        .acquireTokenSilent({ ...authRequest, account })
        .then((response) => {
          // console.log('[[CLIENT]] Sending back token', response);
          channel.postMessage({
            type: 'TOKEN_RESPONSE',
            url: e.data.url,
            token: response.accessToken,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
}

registerServiceWorker();
