import React from 'react';
import { Button } from 'antd';
import { Components } from '@ipos/shared';

const { Icon } = Components;

interface Props {
  iconClassName: string;
  title: string;
  disabled?: boolean;
  onClick?: () => void;
}

const MenuBarOption: React.FC<Props> = ({
  iconClassName,
  title,
  disabled,
  onClick,
}) => (
  <Button
    icon={
      <Icon
        className={iconClassName}
        style={{ marginRight: 5, paddingBottom: 3 }}
      />
    }
    type="link"
    disabled={disabled}
    style={{ display: 'flex', alignItems: 'center', fontSize: 15 }}
    onClick={onClick}
  >
    {title}
  </Button>
);

export default MenuBarOption;
