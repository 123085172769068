import { MarkSpec } from 'prosemirror-model';
import { toCSSColor } from '../../util';

const TextColorMarkSpec: MarkSpec = {
  attrs: {
    color: { default: '' },
  },
  inline: true,
  group: 'inline',
  parseDOM: [
    {
      style: 'color',
      getAttrs: (color) => ({
        color: toCSSColor(color),
      }),
    },
  ],
  toDOM(node) {
    const { color } = node.attrs;
    let style = '';
    if (color) {
      style += `color: ${color};`;
    }
    return ['span', { style }, 0];
  },
};

export default TextColorMarkSpec;
