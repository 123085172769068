import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { ApplicationIdentifier } from '../types';
import { QueryKey } from './';

const useDocumentProxy = (
  documentId: string,
  appContext: ApplicationIdentifier,
  options?: UseQueryOptions<T.DocumentListItem[], AxiosError>
): UseQueryResult<T.DocumentListItem[], AxiosError> =>
  useQuery<T.DocumentListItem[], AxiosError>(
    [QueryKey.DocumentProxy, documentId, appContext],
    () => Api.document.getDocumentsViaProxy(documentId, appContext),
    options
  );

export default useDocumentProxy;
