import { Node as PMNode, Schema } from 'prosemirror-model';
import { DecorationSet } from 'prosemirror-view';
import { EditorState, StateField } from 'prosemirror-state';
import { Change } from '../components/VeryRichTextEditor/trackChangesPlugin';
import { PatentRevisionUndoRedoState } from './patents';

export interface EditorDocument extends PMNode {
  changeSet: {
    baseDocId: string;
    changes: Change[];
  };
  contentSource: 'Autotranslated' | 'Copy';
}

export interface ClaimEditorDocumentResponse {
  clean: EditorDocument;
  baseline: EditorDocument | null;
  trackChanges: EditorDocument;
  amendmentDescription: null | string;
  suggestions: string[];
  warnings: string[];
  undoRedoState: PatentRevisionUndoRedoState;
  patentRevisionId: string;
}

export type ImagePluginState = DecorationSet;

export interface InsertImagePlaceholder {
  type: 'add';
  pos: number;
  id: unknown;
}

export interface RemoveImagePlaceholder {
  type: 'remove';
  id: unknown;
}

export type ImagePluginAction = InsertImagePlaceholder | RemoveImagePlaceholder;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ImagePluginSettings<T = any> {
  extraAttributes: Record<string, string | null>;
  minSize: number;
  maxSize: number;
  scaleImage: boolean;
  createState: (
    pluginSettings: ImagePluginSettings,
    schema: Schema
  ) => StateField<T>;
  createDecorations: (state: EditorState) => DecorationSet;
  findPlaceholder: (state: EditorState, id: object) => number | undefined;
}

export type DocumentSyncState = 'synced' | 'syncing' | 'unsynced' | 'error';

export enum EditorContentType {
  Description = 'description',
  Claims = 'claims',
}

export enum EditorListKind {
  None,
  Disc,
  Bullet,
  Square,
  Diamond,
  Decimal,
  Circle,
  UpperAlpha,
  LowerAlpha,
  UpperRoman,
  LowerRoman,
  ManuallyNumbered,
}

export const ListKindToEditorListKind = {
  none: EditorListKind.None,
  disc: EditorListKind.Disc,
  bullet: EditorListKind.Bullet,
  square: EditorListKind.Square,
  diamond: EditorListKind.Diamond,
  number: EditorListKind.Decimal,
  decimal: EditorListKind.Decimal,
  circle: EditorListKind.Circle,
  'upper-alpha': EditorListKind.UpperAlpha,
  'lower-alpha': EditorListKind.LowerAlpha,
  'upper-roman': EditorListKind.UpperRoman,
  'lower-roman': EditorListKind.LowerRoman,
  'manually-numbered': EditorListKind.ManuallyNumbered,
};

export const EDITOR_LIST_KIND_MAP = {
  [EditorListKind.None]: 'none',
  [EditorListKind.Disc]: 'disc',
  [EditorListKind.Bullet]: 'bullet',
  [EditorListKind.Square]: 'square',
  [EditorListKind.Diamond]: 'diamond',
  [EditorListKind.Decimal]: 'decimal',
  [EditorListKind.Circle]: 'circle',
  [EditorListKind.UpperAlpha]: 'upper-alpha',
  [EditorListKind.LowerAlpha]: 'lower-alpha',
  [EditorListKind.UpperRoman]: 'upper-roman',
  [EditorListKind.LowerRoman]: 'lower-roman',
  [EditorListKind.ManuallyNumbered]: 'manually-numbered',
};

export interface UploadDescriptionEditorOleResponse {
  imageResourceId: string;
  imageBytes: null | string;
  imageFormat: string;
  embeddedObjectResourceId: string;
  embeddedObjectBytes: null | string;
  embeddedObjectType: string;
  width: number;
  aspectRatio: number;
}
