import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import debounce from 'lodash.debounce';
import createPopUp, {
  PopUpHandle,
} from '../../toolbar/renderers/popup/createPopUp';
import { atAnchorTopRight } from '../../toolbar/renderers/popup/PopUpPosition';
import { commentPluginKey } from '../comment/commentPlugin';
import Toolbar from './Toolbar';

const pluginKey = new PluginKey('claimTextSelectionMenuPlugin');

const claimTextSelectionMenuPlugin = () =>
  new Plugin({
    key: pluginKey,
    view(editorView) {
      return new ClaimTextSelectionMenuView(editorView);
    },
  });

class ClaimTextSelectionMenuView {
  editorView: EditorView;
  _popUp: PopUpHandle | null = null;
  debouncedShowPopUp: (view: EditorView) => void;

  constructor(editorView: EditorView) {
    this.editorView = editorView;
    this.debouncedShowPopUp = debounce(this.showPopUp.bind(this), 200);
    this._onClose = this._onClose.bind(this);
  }

  update(view: EditorView, lastState: EditorState) {
    const docChanged = !lastState.doc.eq(view.state.doc);
    const selectionChanged = !lastState.selection.eq(view.state.selection);
    const isSelectionEmpty = view.state.selection.empty;
    if (!docChanged && !selectionChanged) {
      return;
    }
    this.destroy();
    if (!isSelectionEmpty) {
      this.debouncedShowPopUp(view);
    }
  }

  showPopUp(view: EditorView) {
    const { isOpen } = commentPluginKey.getState(view.state);
    const { from, to } = view.state.selection;
    const domFound = view.domAtPos(from);

    if (from === to || !domFound || isOpen) {
      this.destroy();
      return;
    }

    const el: HTMLElement | null = domFound.node.parentNode as HTMLElement;

    if (!el) {
      this.destroy();
      return;
    }

    this._popUp = createPopUp(
      Toolbar,
      {
        editorState: view.state,
        editorView: view,
        anchor: el,
        onClose: this.destroy.bind(this),
      },
      {
        anchor: el,
        autoDismiss: false,
        onClose: this._onClose,
        position: atAnchorTopRight,
      }
    );
  }

  destroy() {
    if (this._popUp) {
      this._popUp.close();
      this._popUp = null;
    }
  }

  _onClose() {
    this._popUp = null;
  }
}

export default claimTextSelectionMenuPlugin;
