import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme';
import { SpinSize } from '../../types';

const SIZE_CONFIG = {
  small: 16,
  default: 30,
  large: 42,
};

interface Props {
  size?: SpinSize;
  customSize?: number;
  color?: string;
  tip?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Spinner: React.FC<Props> = ({
  size = 'default',
  customSize,
  color = Colors.blue600,
  tip,
  style,
  className,
}) => {
  const spinSize = customSize || SIZE_CONFIG[size];

  return (
    <Outer spinSize={spinSize} style={style} className={className}>
      <svg className="philipa-spinner" viewBox="0 0 50 50">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="2s"
          from="0"
          to="360"
          repeatCount="indefinite"
        ></animateTransform>
        <circle
          className="philipa-spinner__circle"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
          stroke={color}
          strokeLinecap="round"
        ></circle>
      </svg>
      {tip}
    </Outer>
  );
};

export default Spinner;

const Outer = styled.div<{ spinSize: number }>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .philipa-spinner {
    width: ${(props) => props.spinSize}px;
    height: ${(props) => props.spinSize}px;

    .philipa-spinner__circle {
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
