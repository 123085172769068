import React, { useMemo, useState } from 'react';
import {
  Annotation,
  DocumentListItem,
  DocumentMetadata,
  Language,
} from '../../types';
import { useUpdateDocumentAuthenticLanguage } from '../../mutations';
import SingleDocumentRenderer from './SingleDocumentRenderer';
import useCurrentDocMetadata from './useCurrentDocMetadata';
import { getRootAnnotationKey } from './AnnotationsToolbar/annotationsUtil';
import useDocumentTranslation from './TranslationTool/useDocumentTranslation';

interface Props {
  defaultDocument: DocumentMetadata;
  activeAnnotations?: Annotation[];
  activeAnnotation?: Annotation | null;
  onClickClearActiveAnnotation?: () => void;
  showAllAnnotations?: boolean;
  showTranslationTool?: boolean;
  viewerIndex?: number;
  initialPage?: number;
  globalCache?: React.MutableRefObject<Record<string, Blob>>;
  documentsToSelect?: DocumentListItem[];
  toolbarStyle?: React.CSSProperties;
  hideAnnotations?: boolean;
  initialZoom?: number;
  searchTerm?: string;
  onDocumentChange?: (d: DocumentMetadata, viewerIndex?: number) => void;
  onClickNavigationLink?: (documentId: string, pageIndex: number) => void;
  onPreviousScreen?: () => void;
  containerStyleOverride?: React.CSSProperties;
}

const SingleDocument: React.FC<Props> = ({
  viewerIndex,
  defaultDocument,
  onDocumentChange,
  onClickNavigationLink,
  ...otherProps
}) => {
  const [selectedAnnotationSets, setSelectedAnnotationSets] = useState<
    Record<string, boolean>
  >({});

  const [annotationFilter, setAnnotationFilter] = useState('');

  const { handleDocChange, currentDocument, invalidateQuery } =
    useCurrentDocMetadata({
      viewerIndex,
      defaultDocument,
      selectedAnnotationSets,
      onDocumentChange,
    });

  const { mutateAsync: updateAuthenticLanguage } =
    useUpdateDocumentAuthenticLanguage();

  const annotations = useMemo(() => {
    if (!currentDocument.annotations) {
      return [];
    }

    return currentDocument.annotations
      .map((annotation) => {
        if (annotation.navigationLinks?.length) {
          annotation.annotationSetTitle = 'LINKS_TO_PRIOR_ART';
        }

        return annotation;
      })
      .filter((a) => {
        if (selectedAnnotationSets['placeholder-LINKS_TO_PRIOR_ART'] === true) {
          return true;
        }

        return a.annotationSetTitle !== 'LINKS_TO_PRIOR_ART';
      });
  }, [currentDocument, selectedAnnotationSets]);

  const activeAnnotations = useMemo(() => {
    if (!annotations) {
      return [];
    }

    if (annotationFilter === '') {
      return annotations;
    }

    return annotations?.filter((a) => {
      if (!a.annotationSetTitle) {
        return false;
      }

      const rootAnnotationKey = getRootAnnotationKey(a.annotationSetTitle);
      return rootAnnotationKey === annotationFilter;
    });
  }, [annotationFilter, annotations]);

  const {
    isTranslating,
    translationProgressPercentage,
    startTranslate,
    onLanguageChange,
    translationErrorCode,
  } = useDocumentTranslation({
    document: currentDocument,
    onDocumentChange: handleDocChange,
  });

  const onAuthenticLanguageChange = async (language: Language) => {
    const authenticDocument = currentDocument.parentDocument ?? currentDocument;

    try {
      await updateAuthenticLanguage({
        documentId: authenticDocument.id,
        documentName: authenticDocument.name,
        contentLanguage: language,
      });
    } catch (e) {
      console.error(e);
    }

    invalidateQuery();
  };

  return (
    <SingleDocumentRenderer
      activeAnnotations={activeAnnotations}
      annotations={annotations}
      document={currentDocument}
      onDocumentChange={handleDocChange}
      selectedAnnotationSets={selectedAnnotationSets}
      setSelectedAnnotationSets={setSelectedAnnotationSets}
      annotationFilter={annotationFilter}
      setAnnotationFilter={setAnnotationFilter}
      isTranslating={isTranslating}
      translationErrorCode={translationErrorCode}
      viewerIndex={viewerIndex}
      translationProgressPercentage={translationProgressPercentage}
      onLanguageChange={onLanguageChange}
      onAuthenticLanguageChange={onAuthenticLanguageChange}
      startTranslate={startTranslate}
      onClickNavigationLink={onClickNavigationLink}
      {...otherProps}
    />
  );
};

export default SingleDocument;
