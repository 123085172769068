import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { TFunction } from 'react-i18next';
import { Checkbox, Button, message, Tooltip } from 'antd';
import TextArea from 'rc-textarea';
import useTranslation from '../../translations';
import { Colors, T } from '../../..';
import { Icon } from '../Icon';

import NotesItemInfo from './NotesItemInfo';

type EditNote = {
  text: string;
  isPublic: boolean;
};
const TOOLTIP_STYLE = {
  maxWidth: '130px',
  color: Colors.white87,
  padding: '8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 5,
  boxShadow: 'none',
  marginBottom: '-7px',
};

const parseNote = (t: TFunction<string>, n?: string | null): string =>
  n || t('NOTES.EMPTY_NOTE');

interface Props {
  note: T.UserNote;
  isOwnedByUser: boolean;
  onUpdateNote: (
    noteId: string,
    value: string,
    isPublic: boolean
  ) => Promise<void>;
  onDeleteNote: (noteId: string) => void;
}

const NotesItem: React.FC<Props> = ({
  note,
  isOwnedByUser,
  onUpdateNote,
  onDeleteNote,
}) => {
  const t = useTranslation();
  const { colors } = useTheme();
  const inputRef = useRef<TextArea>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const hasStaredEditing = useRef<boolean>(false);
  const { id, noteText, isPublic } = note;

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [userNote, setUserNote] = useState<EditNote>({
    text: parseNote(t, noteText),
    isPublic,
  });

  const handleEditRequest = () => {
    setIsEditing(true);
    outerRef.current?.scrollIntoView();
  };
  const stopEdit = () => setIsEditing(false);

  const handleNoteChange = useCallback(
    (value: Partial<EditNote>) =>
      setUserNote((prev) => ({ ...prev, ...value })),
    []
  );

  useEffect(() => {
    if (!isEditing) {
      handleNoteChange({
        text: parseNote(t, note.noteText),
        isPublic: note.isPublic,
      });
    }
  }, [note, isEditing, handleNoteChange]);

  useEffect(() => {
    if (!isEditing) {
      hasStaredEditing.current = false;
      return;
    }
    if (!hasStaredEditing.current) {
      hasStaredEditing.current = true;
      const textLength = userNote.text.length;
      inputRef.current?.resizableTextArea.textArea.setSelectionRange(
        textLength,
        textLength
      );
      inputRef.current?.focus();
    }
  }, [isEditing, userNote.text]);

  const handleDeleteRequest = () => {
    onDeleteNote(id);
  };

  const handleSaveRequest = async () => {
    setIsUpdating(true);
    try {
      await onUpdateNote(id, userNote.text, userNote.isPublic);
      stopEdit();
    } catch {
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEditCancel = () => {
    handleNoteChange({
      text: parseNote(t, noteText),
      isPublic,
    });
    stopEdit();
  };

  const handleCopyToClipboard = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(noteText);
      message.success(t('COPIED_TO_CLIPBOARD'));
    } catch {
      // User denied clipboard write permission
      message.error(t('FAILED_TO_COPY_CLIPBOARD'));
    }
  };

  return (
    <Outer ref={outerRef}>
      <InfoRow>
        <NotesItemInfo item={note} />
        <ActionButtons className="notes__item-buttons">
          <Tooltip
            destroyTooltipOnHide
            title={t('ACTION.COPY')}
            placement="top"
            overlayInnerStyle={TOOLTIP_STYLE}
            color={Colors.primary300}
          >
            <StyledButton
              title={t('ACTION.COPY')}
              onClick={handleCopyToClipboard}
            >
              <i className="icn-copy" />
            </StyledButton>
          </Tooltip>
          {isOwnedByUser && (
            <>
              <Tooltip
                title={t('ACTION.EDIT')}
                placement="top"
                overlayInnerStyle={TOOLTIP_STYLE}
                color={Colors.primary300}
              >
                <StyledButton
                  title={t('ACTION.EDIT')}
                  onClick={handleEditRequest}
                >
                  <i className="icn-edit" />
                </StyledButton>
              </Tooltip>
              <Tooltip
                title={t('ACTION.DELETE')}
                placement="top"
                overlayInnerStyle={TOOLTIP_STYLE}
                color={Colors.primary300}
              >
                <StyledButton
                  title={t('ACTION.DELETE')}
                  onClick={handleDeleteRequest}
                >
                  <i className="icn-delete" />
                </StyledButton>
              </Tooltip>
            </>
          )}
        </ActionButtons>
      </InfoRow>
      <TextAreaWrapper edit={isEditing}>
        <StyledTextArea
          autoSize
          ref={inputRef}
          edit={isEditing}
          disabled={!isEditing}
          value={
            isUpdating || isEditing ? userNote.text : parseNote(t, noteText)
          }
          onChange={(e) => handleNoteChange({ text: e.target.value })}
        />
        <EditPanel hide={!isEditing}>
          <Checkbox
            checked={!userNote.isPublic}
            onChange={(e) => handleNoteChange({ isPublic: !e.target.checked })}
          >
            <FlexCenter>
              <Icon className="icn-locked" size={16} />
              {t('NOTES.SAVE_AS_PRIVATE')}
            </FlexCenter>
          </Checkbox>
          <EditButtons>
            <Button
              style={{
                borderRadius: 5,
                border: `2px solid ${colors.white20}`,
                color: colors.white87,
                fontWeight: 500,
              }}
              disabled={isUpdating}
              onClick={handleEditCancel}
            >
              {t('ACTION.CANCEL')}
            </Button>
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                border: `2px solid transparent`,
                color: colors.white87,
                background: colors.primary100,
                fontWeight: 500,
              }}
              loading={isUpdating}
              disabled={userNote.text.trim() === ''}
              onClick={handleSaveRequest}
            >
              {t('ACTION.SAVE')}
            </Button>
          </EditButtons>
        </EditPanel>
      </TextAreaWrapper>
    </Outer>
  );
};

export default NotesItem;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  padding: 15px 0 25px 0;
  position: relative;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    .notes__item-buttons {
      display: flex;
    }
  }
  .ant-input:hover {
    border: unset;
    outline: none;
  }
  .ant-input[disabled] {
    background-color: unset;
    border-color: unset;
  }
`;

const InfoRow = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextArea = styled(TextArea)<{ edit: boolean }>`
  background: transparent;
  border: unset;
  outline: none;
  cursor: pointer;
  min-height: 30px;
  color: ${(props) => props.theme.colors.white60};
  :hover,
  :focus,
  :focus-within {
    background: transparent;
    border: unset;
    outline: none;
    color: ${(props) => props.theme.colors.white87};
  }
  ${(props) => props.edit && `margin-top: 5px; padding: 13px 12px 12px 20px;`}
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  gap: 3px;
`;

const EditPanel = styled.div<{ hide: boolean }>`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 18px 12px 12px 20px;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  gap: 8px;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 10px;
`;
const TextAreaWrapper = styled.div<{ edit: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  gap: 30px;
  border: 2px solid transparent;
  margin: 5px 16px 0px 40px;
  ${(props) =>
    props.edit &&
    `border-color: ${props.theme.colors.blue600};background-color:${props.theme.colors.primary200_20}`}
`;
const FlexCenter = styled.div`
  display: flex;
  gap: 5px;
  align-items: baseline;
  font-size: 15px;
`;
const StyledButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 3px;
  display: flex;
  padding: 6px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white70}
  > :first-child {
    font-size: 16px;
  }
  :hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
`;
