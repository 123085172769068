import {
  BaseDto,
  BaseProcess,
  PaginatedApiFunction,
  PagingParams,
  ProcessStageState,
} from './common';
import { Filters, QueryParams } from './list';
import {
  PatentApplicationStakeholderDisplayNameAndRole,
  PatentIdentifier,
  PatentRevision,
  PatentRevisionPreview,
} from './patents';
import { DocumentMetadata } from './documents';
import { Language } from './language';

export enum PatentAnalysisCheck {
  AbstractIsShorterThan200Words = 'AbstractIsShorterThan200Words',
  AbstractPresent = 'AbstractPresent',
  ClaimDependenciesOk = 'ClaimDependenciesOk',
  ClaimReferenceSignArticleOrder = 'ClaimReferenceSignArticleOrder',
  ClaimsAreWellFormed = 'ClaimsAreWellFormed',
  ClaimsPresent = 'ClaimsPresent',
  DescriptionFormalityDefects = 'DescriptionFormalityDefects',
  DescriptionPresent = 'DescriptionPresent',
  DescriptionUnexpectedPunctuation = 'DescriptionUnexpectedPunctuation',
  FigureImagesPresent = 'FigureImagesPresent',
  FigureListNotMissingAnyFigures = 'FigureListNotMissingAnyFigures',
  FigureListPresent = 'FigureListPresent',
  FiguresReferencedInDescription = 'FiguresReferencedInDescription',
  ReferenceSignListPresent = 'ReferenceSignListPresent',
  ReferenceSignsConsistent = 'ReferenceSignsConsistent',
  ReferenceSignsReferencedInClaims = 'ReferenceSignsReferencedInClaims',
  ReferenceSignsReferencedInDescription = 'ReferenceSignsReferencedInDescription',
  ReferenceSignsShownInFigureImages = 'ReferenceSignsShownInFigureImages',
  ReferenceSignsUnique = 'ReferenceSignsUnique',
  SequencesReferencedInClaims = 'SequencesReferencedInClaims',
  SequencesReferencedInClaimsAlsoReferencedInDescription = 'SequencesReferencedInClaimsAlsoReferencedInDescription',
  SequencesReferencedInDescription = 'SequencesReferencedInDescription',
  SequencesReferencedInDescriptionAlsoReferencedInClaims = 'SequencesReferencedInDescriptionAlsoReferencedInClaims',
  TitleDoesNotContainDiscouragedTerms = 'TitleDoesNotContainDiscouragedTerms',
  TitleIsNotVague = 'TitleIsNotVague',
  TitleNotTooLong = 'TitleNotTooLong',
  TitlePresent = 'TitlePresent',
}

export enum ExtensionOfTimeLimit {
  NotApplicable = 'NotApplicable',
  No = 'No',
  Possibly = 'Possibly',
  Yes = 'Yes',
  YesForEP = 'YesForEP',
}

export interface PatentParseAndAnalysis {
  fileName: string;
  patentRevision: PatentRevision;
  analysis: PatentAnalysis;
}

export enum PatentAnalysisCheckSource {
  Claims = 'Claims',
  Description = 'Description',
}

export interface PatentAnalysisCheckResult {
  warning: string;
  context: string;
  source?: PatentAnalysisCheckSource;
  offendingPart: string;
}

export interface PatentAnalysis {
  refSignList: { [key: string]: string };
  refSignDescriptionFrequencies: { [key: string]: number };
  refSignClaimFrequencies: { [key: string]: number };
  checksAndResults:
    | {
        [key in PatentAnalysisCheck]: PatentAnalysisCheckResult[] | null;
      }
    | null;
}

export interface OfficeActionStatistics {
  daysOfMonthWithOfficeActionsDue: number[];
  numberOfUpcoming: number;
  numberOfInUploadQueue: number;
  numberOfInProgress: number;
  numberOfClosed: number;
}

export interface OfficeActionListFilters {
  applicants: Record<string, string>;
  inHouseStakeholders: Record<string, string>;
  patentOffices: string[];
  inventors: Record<string, string>;
  externalCounsels: Record<string, string>;
}

export interface TreeItem {
  id: string;
  parentId?: string;
  name: string;
  editable: boolean;
  asDraft: boolean;
  children: TreeItem[];
}

export interface CitedPriorArtReference {
  citedPriorArtNumber: number;
  references: string[];
  context: string;
}

export interface OfficeAction extends BaseDto {
  patentApplicationId: string;
  workflowState: WorkflowState;
  language: Language;
  patentOffice: PatentOffice;
  applicationCountryCode: string;
  applicationNumber: string;
  applicationKind?: string;
  applicationDate?: string;
  priorityDate?: string;
  officeActionDate?: string;
  dueDate?: string;
  lastResortDate?: string;
  reminderDate?: string;
  priority: string;
  officeActionTypes: { [key: string]: string } | null;
  internalReference: string;
  numberOfClaims: number;
  objections: Objections;
  suggestedAmendments: string[];
  personSkilledInTheArt: string;
  closestPriorArt: CitedPriorArtReference[];
  citedPriorArtDisclosure: CitedPriorArtReference[];
  distinguishingFeatures: string[];
  technicalEffects: string[];
  objectiveTechnicalProblems: string[];
  uploadProcessId?: string;
  closedDate?: string;
}

export enum WorkflowState {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Closed = 'Closed',
}

export enum PatentOffice {
  EPO = 'EPO',
  WO = 'WO',
  DPMA = 'DPMA',
  USPTO = 'USPTO',
  CNIPA = 'CNIPA',
  KIPO = 'KIPO',
  JPO = 'JPO',
  TIPO = 'TIPO',
}

export interface OfficeActionCitedPriorArtInfo {
  citedPriorArt: OfficeActionCitedPriorArt[];
  claimCitationMatrix: ClaimCitation[];
  claimFeatureMatrix: ClaimFeatureCitation[];
}

export interface OfficeActionCitedPriorArt {
  citationNumber: number;
  citationNumberFormat: string;
  citedLiterature: string;
  isNonPatentLiterature?: boolean | null;
  citedPatentPublicationId?: string | null;
  citedDocumentId: string | null;
  externalUri?: string;
  citedNonPatentDocumentId?: string;
  citedNonPatentDocumentName?: string;
}

export interface DocumentLink {
  pageIndex: number;
  label: string;
  uri: string;
  thumbnailUri: string;
}

export interface TextRange {
  index: number;
  text: string;
}

export interface DocumentReference {
  index: number;
  text: string;
  links: DocumentLink[];
}

export interface ClaimCitationReference {
  officeActionPageIndex: number;
  officeActionDocViewLink: string;
  officeActionText: string;
  claimNumberInstances: TextRange[];
  priorArtNumberInstances: TextRange[];
  documentReferences: DocumentReference[];
}

export interface ClaimCitation {
  claimNumber: number;
  citedPriorArtNumber: number;
  references: ClaimCitationReference[];
  citedPriorArtDocumentUri: string | null;
}

export interface ClaimFeatureCitation extends ClaimCitation {
  claimFeatureReferences: ClaimFeatureReference[];
}

export interface ClaimFeatureReference {
  feature: string;
  reference: string;
}

export interface OfficeActionListItem extends BaseDto {
  closedDate?: string;
  citedPriorArtsCount?: number;
  importProcessId?: string;
  patentApplicationId: string | null;
  workflowState: WorkflowState;
  fileName: string;
  patentOffice: PatentOffice;
  fullApplicationNumber: string | null;
  fullPublicationNumber: string | null;
  applicationCountryCode: string | null;
  applicationKind: string | null;
  applicationNumber: string;
  currentStage?: UploadStageIndex;
  currentStageState?: ProcessStageState;
  applicantReference: string | null;
  stakeholders?: PatentApplicationStakeholderDisplayNameAndRole[];
  dueDate?: string;
  daysPassed?: number;
  daysLeft?: number;
  lossOfRights: LossOfRights;
  extensionOfTimeLimit: ExtensionOfTimeLimit;
  legalRemedies: LegalRemedies | null;
  lawFirmReference: string | null;
  domain: string | null;
  tsdS: null;
  numCitations: number | null;
  projectReference: string | null;
  productReference: string | null;
  value: string | null;
  applicationDate: string | null;
  publicationDate: string | null;
  priorityNumber: string | null;
  priorityDate: string | null;
  expirationDate: string | null;
  uploadDate: string | null;
  figureUri: string | null;
  title: string | null;
  filingType: string | null;
}

export enum LossOfRights {
  NotApplicable = 'NotApplicable',
  NoLossOfRights = 'NoLossOfRights',
  NoLossOfRightsOrTpoPartiallyDisregarded = 'NoLossOfRightsOrTpoPartiallyDisregarded',
  ActionsDeemedNotTaken = 'ActionsDeemedNotTaken',
  AmendmentsNotInProceedings = 'AmendmentsNotInProceedings',
  ApplicationDeemedWithdrawn = 'ApplicationDeemedWithdrawn',
  DelayOfProceedings = 'DelayOfProceedings',
  DependsOnOmittedAct = 'DependsOnOmittedAct',
  DocumentDeemedNotFiled = 'DocumentDeemedNotFiled',
  LossOfDisclosure = 'LossOfDisclosure',
  LossOfPriority = 'LossOfPriority',
  NoFurtherFeesPaidAllFurtherFeesDebitedIfAutomaticDebitingInPlace = 'NoFurtherFeesPaidAllFurtherFeesDebitedIfAutomaticDebitingInPlace',
  NotTreatedAsPatentApplication = 'NotTreatedAsPatentApplication',
  RefusalOfApplication = 'RefusalOfApplication',
  RefusalOfApplicationActionsDeemedNotTaken = 'RefusalOfApplicationActionsDeemedNotTaken',
  RejectionOfRequestForEntryOfChange = 'RejectionOfRequestForEntryOfChange',
  RejectionOfRequestForLimitation = 'RejectionOfRequestForLimitation',
  RequestForLimitationDeemedWithdrawn = 'RequestForLimitationDeemedWithdrawn',
  SeeOriginalOfficeAction = 'SeeOriginalOfficeAction',
  StayOfProceedings = 'StayOfProceedings',
  UnsearchedSubjectMatter = 'UnsearchedSubjectMatter',
  VariousDelayOfProceedings = 'VariousDelayOfProceedings',
  VariousLossOfPriority = 'VariousLossOfPriority',
}

export interface LegalRemedies {
  furtherProcessing: LegalRemediesFurtherProcessing;
  reestablishment: LegalRemediesReestablishment;
  appeal: LegalRemediesAppeal;
  other: LegalRemediesOther[];
}

export enum LegalRemediesReestablishment {
  NotApplicable = 'NotApplicable',
  Undefined = 'Undefined',
  No = 'No',
  Yes = 'Yes',
  Possibly = 'Possibly',
  NoForR160_1_EPC = 'NoForR160_1_EPC',
  NoForR163_2YesForR53_3_EPC = 'NoForR163_2YesForR53_3_EPC',
  NoForR163_6YesForArt90_5_EPC = 'NoForR163_6YesForArt90_5_EPC',
}

export enum LegalRemediesOther {
  NotApplicable = 'NotApplicable',
  AppealWithFinalDecision = 'AppealWithFinalDecision',
  AssignNewRepresentative = 'AssignNewRepresentative',
  FileDivisionalForUnsearchedSubjectMatter = 'FileDivisionalForUnsearchedSubjectMatter',
  FileDivisional = 'FileDivisional',
  FileNewApplication = 'FileNewApplication',
  FileNewRequest = 'FileNewRequest',
  DependsOnOmittedAct = 'DependsOnOmittedAct',
  RefileThirdPartyObservation = 'RefileThirdPartyObservation',
  RequestRefund = 'RequestRefund',
  RequestForConversion = 'RequestForConversion',
  SeeOriginalOfficeAction = 'SeeOriginalOfficeAction',
  SubmitAddressOfInventor = 'SubmitAddressOfInventor',
  SubmitAmendmentsAfterSearchReport = 'SubmitAmendmentsAfterSearchReport',
  SubmitCertifiedCopy = 'SubmitCertifiedCopy',
  SubmitRevisedClaims = 'SubmitRevisedClaims',
}

export enum LegalRemediesAppeal {
  NotApplicable = 'NotApplicable',
  Undefined = 'Undefined',
  No = 'No',
  Yes = 'Yes',
}

export enum LegalRemediesFurtherProcessing {
  NotApplicable = 'NotApplicable',
  No = 'No',
  Yes = 'Yes',
  YesTwoFees = 'YesTwoFees',
  YesForR160_1_EPC = 'YesForR160_1_EPC',
  YesForR163_2NoForR53_3_EPC = 'YesForR163_2NoForR53_3_EPC',
  YesForR163_6NoFor_Art90_5_EPC = 'YesForR163_6NoFor_Art90_5_EPC',
}

export enum TOaDetailsFields {
  PatentOffice = 'PatentOffice',
  ApplicationNumber = 'ApplicationNumber',
  OfficeActionType = 'OfficeActionType',
  OfficeActionDate = 'OfficeActionDate',
  DueDate = 'DueDate',
  Examiner = 'Examiner',
  InternalReference = 'InternalReference',
}

export interface Objection {
  objectionTexts?: string[];
  claimNumbers?: number[] | null;
  claimNumbersAsText?: string;
}

export enum OfficeActionObjectionCategory {
  Patentability = 'Patentability',
  Novelty = 'Novelty',
  Inventiveness = 'Inventiveness',
  Clarity = 'Clarity',
  UnsupportedAmendments = 'UnsupportedAmendments',
  Formalities = 'Formalities',
  InsufficientDisclosure = 'InsufficientDisclosure',
  IndustrialApplicability = 'IndustrialApplicability',
  NonUnity = 'NonUnity',
}

export type Objections = {
  [key in OfficeActionObjectionCategory]?: Objection | null;
};

export interface OfficeActionParserOutput {
  id: string;
  uploadProcessId: string;
  ownerGroupId: string;
  officeActionId: string;
  patentOffice: PatentOffice;
  applicationCountryCode: string;
  applicationNumber: string;
  applicationKind?: string;
  applicationDate?: string;
  applicant?: string | null;
  language: Language;
  officeActionDate?: string;
  dueDate?: string;
  lastResortDate?: string;
  reminderDate?: string;
  priorityDate?: string;
  priority?: string | null;
  officeActionTypes?: { [key: string]: string };
  examiner?: string | null;
  personSkilledInTheArt: string;
  internalReference?: string | null;
  citedPriorArt: OfficeActionCitedPriorArt[];
  objections: Objections;
  suggestedAmendments: string[];
  closestPriorArt: string[];
  claimCitationMatrix: ClaimCitationParserOutput[];
  claimFeatureMatrix: ClaimFeatureCitationParserOutput[];
  patentOfficeMatchText: string;
  applicationNumberMatchText: string;
  applicationDateMatchText: string;
  priorityDateMatchText: string;
  officeActionDateMatchText: string;
  dueDateMatchText?: string;
  dueDateValueText?: string;
}

export interface PatentApplicationUpdate {
  title?: string | undefined;
  engineeringDomain?: EngineeringDomain | null;
  expiryDate?: string | null;
  lawFirmReference?: string | null;
  projectReference?: string | null;
  productReference?: string | null;
  mostImportantAspect?: string | null;
  patentFamilyId?: string | null;
  filingType?: string | null;
  publicationDate?: string;
}

export interface UpdateOfficeActionDates {
  officeActionTypes?: { [key: string]: string } | null;
  officeActionDate?: string | null;
  priorityDate?: string | null;
  filingDate?: string | null;
  grantDate?: string | null;
  dueDate?: string | null;
  reminderDate?: string | null;
  lastResortDate?: string | null;
  officeActionDateChanged: boolean;
  priorityDateChanged: boolean;
  filingDateChanged: boolean;
  grantDateChanged: boolean;
  dueDateChanged: boolean;
  reminderDateChanged: boolean;
  lastResortDateChanged: boolean;
  expiryDate?: string | null;
  publicationDate?: string | null;
  expiryDateChanged: boolean;
  publicationDateChanged: boolean;
}

export interface ClaimCitationParserOutput {
  citedPriorArtNumbers: string;
  claimNumbers: string;
  context: string;
}

export interface ClaimFeatureCitationParserOutput
  extends ClaimCitationParserOutput {
  claimFeatureReferences: ClaimFeatureReference[];
}

export enum UploadStageIndex {
  OfficeActionUploadStage,
  OfficeActionReviewStage,
  OfficeActionProcessingStage,
}

export interface OfficeActionImportProcess extends BaseProcess {
  patentApplicationId?: string | null;
  officeActionId?: string | null;
}

export interface PatentRevisionImportProcess extends BaseProcess {
  patentApplicationId?: string | null;
  patentRevisionId?: string | null;
}

export interface DocumentImportProcess extends BaseProcess {
  patentApplicationId: string | null;
  documentId: string | null;
  progress: number;
}

export interface OfficeActionUploadOverride {
  overridePatentIdentifier: boolean;
  patentIdentifier?: PatentIdentifier;
}

export interface OfficeActionUploadReview {
  officeActionParserOutput: OfficeActionParserOutput;
  officeActionDocument: DocumentMetadata;
  earlierOfficeActionDocuments: DocumentMetadata[];
  patentRevisions: PatentRevisionPreview[];
  officeActionSummary: string | null;
  patentApplicationSummary: PatentApplicationSummary;
  patentRevisionImportProcessInProgressId: string | null;
}

export interface PatentApplicationSummary {
  applicationTitle: string;
  publicationIdentifier?: PatentIdentifier;
  applicationIdentifier: PatentIdentifier;
  engineeringDomain: EngineeringDomain | null;
  expiryDate?: string;
  lawFirmReference: string | null;
  projectReference: string | null;
  productReference: string | null;
  mostImportantAspect: string | null;
  patentFamilyId: string | null;
  filingType: string | null;
  priorityDate: string | null;
  publicationDate?: string;
  applicationDate: string | null;
}

export enum SearchOccurrenceTypes {
  'Begins with' = 'BeginsWith',
  'Ends with' = 'EndsWith',
  'Contains' = 'Contains',
  'Exact match' = 'ExactMatch',
}

export type SearchCriteriaType =
  (typeof SearchOccurrenceTypes)[keyof typeof SearchOccurrenceTypes];

export type TextBlocksQueryParams = QueryParams;

export interface GlossaryEntriesQueryParams
  extends Omit<QueryParams, 'filters'> {
  sortBy: string;
  includeSupportedLanguages: boolean;
  includeDuplicatedEntryIds: boolean;
  searchLanguages: string[];
  startsWithLetter: string;
}

export interface GlossaryEntriesListQueryParams
  extends Omit<
    GlossaryEntriesQueryParams,
    | 'includeSupportedLanguages'
    | 'includeDuplicatedEntryIds'
    | 'startsWithLetter'
  > {
  sortByLanguage: string[];
  searchCriteria: SearchCriteriaType;
}

export enum ResponseLetterTextBlockLibrary {
  MyLibrary = 'User',
  CompanyLibrary = 'Group',
  PhilipaLibrary = 'Shared',
  AllTextTemplates = 'All',
}

export enum ResponseLetterSection {
  General = 'General',
  Priority = 'Priority',
  FormalityObjections = 'FormalityObjections',
  ClaimAmendmentDescription = 'ClaimAmendmentDescription',
  UnsupportedAmendments = 'UnsupportedAmendments',
  PatentabilityObjections = 'PatentabilityObjections',
  IndustrialApplicabilityObjections = 'IndustrialApplicabilityObjections',
  NonUnityObjections = 'NonUnityObjections',
  NoveltyObjections = 'NoveltyObjections',
  InventivenessObjections = 'InventivenessObjections',
  ClarityObjections = 'ClarityObjections',
  InsufficientDisclosureObjections = 'InsufficientDisclosureObjections',
  ClosingStatement = 'ClosingStatement',
  Attachments = 'Attachments',
}

export enum ResponseLetterTextType {
  Headline = 'Headline',
  Request = 'Request',
  Declaration = 'Declaration',
  Argument = 'Argument',
  ChangeDescription = 'ChangeDescription',
  LegalBasis = 'LegalBasis',
  Submission = 'Submission',
  Conclusion = 'Conclusion',
}

export enum EngineeringDomain {
  Biology = 'Biology',
  Chemistry = 'Chemistry',
  Electronics = 'Electronics',
  Mechanics = 'Mechanics',
  Software = 'Software',
  Other = 'Other',
  DomainIndependent = 'DomainIndependent',
}

export interface ResponseLetterSectionContent {
  headline: string;
  text: string;
  order: number;
  objectionAgreed: boolean | undefined;
  included: boolean;
}

export interface ResponseLetter extends BaseDto {
  patentRevisionId?: string;
  claimsAmendedOnSamePatentRevisionSinceLastChange: boolean;
  claimsAmendedOnOtherPatentRevisionSinceLastChange: boolean;
  content: {
    [key in ResponseLetterSection]: ResponseLetterSectionContent;
  };
}

export interface CreateOfficeActionResponseLetter {
  patentRevisionId?: string;
  objectionsAgreed?: {
    [key in OfficeActionObjectionCategory]: boolean;
  };
}

export interface ResponseLetterTextBlocksQueryParams {
  responseLetterId?: string;
  searchTerm: string;
  pageSize?: number;
  filteringMode: 'exclusion' | 'inclusion';
  filters: Filters;
  sources?: ResponseLetterTextBlockLibrary[];
}

export interface ContactsQueryParams extends PagingParams {
  searchTerm?: string;
  groupType: ContactGroupType;
  withAppAccessGroupIds: string[];
}

export enum ContactGroupType {
  AutoCreated = 'AutoCreated',
  All = 'All',
  CoworkerContacts = 'CoworkerContacts',
  WithAppAccess = 'WithAppAccess',
}

export enum TextBlockLibraryType {
  Generated = 'Generated',
  User = 'User',
  Group = 'Group',
  Shared = 'Shared',
}

export type NewTextBlock = Omit<TextBlock, 'id' | 'createdOn'>;

export interface TextBlock extends BaseDto {
  section: ResponseLetterSection;
  textType: ResponseLetterTextType;
  domains: EngineeringDomain[];
  claimsAmended?: boolean | null;
  order?: number;
  keywordPatterns: string[] | null;
  text: { [key in Language]?: string };
  addByDefault: boolean;
  libraryType?: TextBlockLibraryType;
  patentOffice: PatentOffice;
}

export interface FiledClaimCorrection {
  claimNumber: number;
  text: string;
  claimTraceId?: string;
}

export interface HookProps<T, Q> {
  queryParams: Q;
  queryKey: string;
  pageSize?: number;
  apiFunction: PaginatedApiFunction<T, Q>;
}

export interface HookResult<T> {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  infiniteData: T[];
}

export interface OfficeActionType {
  form: string;
  communication: string;
}

export interface OfficeActionTypeSearch {
  patentOffice?: PatentOffice;
  language?: Language;
  searchText: string;
}

export enum OfficeActionDetailsContext {
  Matrices = 'matrices',
  PatentFamily = 'patent-family',
  ProblemSolution = 'problem-solution',
}

export interface PatentRevisionAmendmentSummary {
  hasClaimAmendments: boolean;
  hasDescriptionAmendments: boolean;
}

export interface OfficeActionResponseLetterDownloadOptions {
  includeCleanClaimDocument: boolean;
  includeCleanDescriptionDocument: boolean;
  includeTrackChangesClaimDocument: boolean;
  includeTrackChangesDescriptionDocument: boolean;
  mergeDescriptionAndClaimDocuments: boolean;
  expandClaimFeatures: boolean;
  showDisclaimer: boolean;
  asDocx: boolean;
  asPdf: boolean;
}

export interface ShareScreen {
  officeActionId: string;
  contactIds: string[];
  link: string;
}
