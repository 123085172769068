import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import {
  MultiSortingField as IMultiSortingField,
  SortOrder,
} from '../../types';
import { Select } from '../Form';

interface Props {
  field: IMultiSortingField;
  onChange: (value: SortOrder) => void;
}

const MultiSortingField: React.FC<Props> = ({ field, onChange }) => {
  const t = useTranslation();

  return (
    <Outer>
      <Label>{field.label}</Label>

      <SelectWrapper>
        <Select
          style={{ width: '100%' }}
          value={field.value}
          onChange={(value) => onChange(value)}
          allowClear
        >
          <Select.Option key={SortOrder.ASC}>
            {t(`MULTI_SORTING_MODAL.OPTIONS.${field.type.toUpperCase()}.ASC`)}
          </Select.Option>
          <Select.Option key={SortOrder.DESC}>
            {t(`MULTI_SORTING_MODAL.OPTIONS.${field.type.toUpperCase()}.DESC`)}
          </Select.Option>
        </Select>
      </SelectWrapper>
    </Outer>
  );
};

export default MultiSortingField;

const Outer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  flex: 2;
`;

const Label = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.white87};
`;
