import { Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { FlatButton } from '../../../Button';
import { T } from '../../../../..';
import { splitCamelCase } from '../../util';

enum StatusIdentifier {
  Original = 'Original',
  CurrentlyAmended = 'CurrentlyAmended',
  PreviouslyPresented = 'PreviouslyPresented',
  Canceled = 'Canceled',
  Withdrawn = 'Withdrawn',
  New = 'New',
  NotEntered = 'NotEntered',
}

interface Props {
  node: Node;
  view?: EditorView;
  pos?: number;
  patentOffice?: T.PatentOffice;
}
const ClaimStatusIdentifier: React.FC<Props> = ({
  node,
  view,
  pos,
  patentOffice,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const editable = view?.editable;
  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const handleItemClick = (item: string) => {
    if (!view || !pos) {
      return;
    }
    const attrs = {
      ...node.attrs,
      statusIdentifier: item,
    };
    view.dispatch(view.state.tr.setNodeMarkup(pos, null, attrs));
    closePopover();
  };

  if (patentOffice !== T.PatentOffice.USPTO) {
    return null;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['right']}
      align="start"
      containerStyle={{ marginLeft: '10px' }}
      onClickOutside={closePopover}
      content={
        <Outer onClick={(e) => e.stopPropagation()}>
          {Object.values(StatusIdentifier).map((item) => (
            <Item key={item} onClick={() => handleItemClick(item)}>
              {splitCamelCase(item)}
            </Item>
          ))}
        </Outer>
      }
    >
      <StyledButton
        rightIcon={
          isPopoverOpen ? (
            <i className="icn-chevron-up" />
          ) : (
            <i className="icn-chevron-down" />
          )
        }
        onClick={togglePopover}
        disabled={!editable}
      >
        {splitCamelCase(node.attrs.statusIdentifier || '')}
      </StyledButton>
    </Popover>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;
  padding: 10px 0;
  border-radius: 12px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
`;

const Item = styled(FlatButton)`
  padding: 12px 20px;
  border-radius: none;
  margin: 0;
  justify-content: flex-start;
  font-size: 13px;
`;

const StyledButton = styled(FlatButton)`
  margin: 2px 0;
  height: 16px;
  padding: 1px 6px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.black20};
  background: ${(props) => props.theme.colors.primary200_20};
  color: ${(props) => props.theme.colors.black87};
  font-size: 10px;
  i {
    color: ${(props) => props.theme.colors.black};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.black87};
  }
`;

export default ClaimStatusIdentifier;
