import React from 'react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { Select } from 'antd';

const SIZES = [8, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 28];

const FontSizes: React.FC<{
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}> = ({ onChange, editorState }) => {
  const handleChange = (fontStyle: string) => {
    const selection = editorState.getSelection();

    // allow one size at a time
    const nextContentState = Object.keys(STYLE_MAP).reduce(
      (contentState, style) =>
        Modifier.removeInlineStyle(contentState, selection, style),
      editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current font style
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce(
        (state, fontSize) =>
          RichUtils.toggleInlineStyle(state as EditorState, fontSize as string),
        nextEditorState
      );
    }

    if (!currentStyle.has(fontStyle)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, fontStyle);
    }

    onChange(nextEditorState);
  };

  return (
    <Select style={{ width: 60 }} onChange={handleChange}>
      {SIZES.map((size) => (
        <Select.Option key={size} value={`fsize-${size}`}>
          {size}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FontSizes;

// Turn SIZES into a style map like below:
// export const STYLE_MAP = {
//   'fsize-8': {
//     fontSize: 8,
//   },
//   'fsize-10': {
//     fontSize: 10,
//   },
//   ...
// };
export const STYLE_MAP = SIZES.reduce(
  (p, c) => ({ ...p, [`fsize-${c}`]: { fontSize: c } }),
  {}
);
