import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { T } from '../../../..';
import { truncate } from '../../../utils';
import { Icon } from '../../Icon';
import ConfirmationInput from './ConfirmationInput';

const ITEM_OUTER_HEIGHT = 55;

enum ActionType {
  Delete = 'Delete',
  Rename = 'Rename',
  Copy = 'Copy',
}

const TreeElement: React.FC<{
  item: T.TreeItem;
  activeItemId?: string;
  onFileItemId?: string;
  suggestions?: string[];
  onClick?: (item: T.TreeItem) => void;
  onDoubleClick?: (item: T.TreeItem) => void;
  onRename?: (item: T.TreeItem, newValue: string) => Promise<void> | void;
  onCopy?: (item: T.TreeItem, newValue: string) => Promise<void> | void;
  onDelete?: (item: T.TreeItem, force?: boolean) => Promise<void> | void;
  deleteConfirmation?: (
    item: T.TreeItem,
    onConfirm: () => void,
    onCancel: () => void
  ) => React.ReactNode;
}> = ({
  item,
  activeItemId,
  onFileItemId,
  suggestions,
  onClick,
  onDoubleClick,
  onRename,
  onCopy,
  onDelete,
  deleteConfirmation,
}) => {
  const t = useTranslation();
  const { id, asDraft, name, children, editable, parentId } = item;
  const active = id === activeItemId;
  const isCurrentlyOnFile = onFileItemId === id;
  const isEditable = editable && !isCurrentlyOnFile;

  const [requestedAction, setRequestedAction] = useState<ActionType | null>(
    asDraft ? ActionType.Rename : null
  );
  const [actionInProgress, setActionInProgress] = useState<ActionType | null>(
    null
  );

  const clearRequestedAction = useCallback(() => {
    setRequestedAction(null);
  }, []);

  useEffect(() => {
    if (!active) {
      clearRequestedAction();
    }
  }, [active, clearRequestedAction]);

  const executeAction = async (
    actionType: ActionType,
    action: () => Promise<void> | void
  ) => {
    setActionInProgress(actionType);
    await action();
    setActionInProgress(null);
    clearRequestedAction();
  };

  const handleDoubleClick = () => {
    onDoubleClick?.(item);
  };

  const handleClick = () => {
    onClick?.(item);
  };

  const handleCopyConfirmation = (newValue: string) => {
    if (onCopy) {
      executeAction(ActionType.Copy, () => onCopy(item, newValue));
    }
  };

  const handleDeleteConfirmation = (force?: boolean) => {
    if (onDelete) {
      executeAction(ActionType.Delete, () => onDelete(item, force));
    }
  };

  const handleRenameConfirmation = (newValue: string) => {
    if (onRename) {
      executeAction(ActionType.Rename, () => onRename(item, newValue));
    }
  };

  let itemIcon = <Icon className="icn-locked" size={15} />;
  switch (true) {
    case isCurrentlyOnFile:
      itemIcon = <Icon className="icn-folder-special" size={17} />;
      break;
    case isEditable:
      itemIcon = <Icon className="icn-edit" size={15} />;
      break;
    default:
      break;
  }

  return (
    <Item data-parentid={parentId} data-id={id} data-testid="claim-tree-item">
      <Outer>
        <Inner
          data-testid={`claim-tree-item-inner-${id}`}
          isOnFile={isCurrentlyOnFile}
          active={active}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
        >
          <Box active={active}>{itemIcon}</Box>
          {requestedAction === ActionType.Rename ? (
            <ConfirmationInput
              value={name}
              required
              loading={actionInProgress === ActionType.Rename}
              onConfirm={handleRenameConfirmation}
              onCancel={clearRequestedAction}
              suggestions={suggestions}
              suggestionsTitle={t('REVISION_TREE.SUGGESTIONS_TITLE')}
            />
          ) : (
            <>
              <RevisionName>{name}</RevisionName>
              {isCurrentlyOnFile && (
                <CurrentlyOnFileTag>Currently on file</CurrentlyOnFileTag>
              )}
              {active && (
                <Actions
                  className="actions"
                  onClick={(e) => e.stopPropagation()}
                >
                  {onCopy && (
                    <Tooltip title={t('ACTION.DUPLICATE')} destroyTooltipOnHide>
                      <ActionButton
                        data-testid="duplicate-patent-revision"
                        onClick={() => setRequestedAction(ActionType.Copy)}
                      >
                        <Icon className="icn-files-outlined" size={16} />
                      </ActionButton>
                    </Tooltip>
                  )}
                  {isEditable && onRename && (
                    <Tooltip title={t('ACTION.RENAME')} destroyTooltipOnHide>
                      <ActionButton
                        data-testid="rename-patent-revision"
                        onClick={() => setRequestedAction(ActionType.Rename)}
                      >
                        <Icon className="icn-edit" size={16} />
                      </ActionButton>
                    </Tooltip>
                  )}
                  {onDelete && (
                    <Tooltip title={t('ACTION.DELETE')} destroyTooltipOnHide>
                      <ActionButton
                        data-testid="delete-patent-revision"
                        onClick={() => {
                          if (deleteConfirmation) {
                            setRequestedAction(ActionType.Delete);
                          } else {
                            handleDeleteConfirmation();
                          }
                        }}
                      >
                        <Icon className="icn-delete" size={16} />
                      </ActionButton>
                    </Tooltip>
                  )}
                </Actions>
              )}
            </>
          )}
        </Inner>
      </Outer>
      <ul>
        {requestedAction === ActionType.Copy && (
          <Item>
            <Outer>
              <Inner>
                <Box>
                  <Icon className="icn-edit" size={15} />
                </Box>
                <ConfirmationInput
                  value={`Copy of ${name}`}
                  required
                  loading={actionInProgress === ActionType.Copy}
                  onConfirm={handleCopyConfirmation}
                  onCancel={clearRequestedAction}
                  suggestions={suggestions}
                  suggestionsTitle={t('REVISION_TREE.SUGGESTIONS_TITLE')}
                />
              </Inner>
            </Outer>
          </Item>
        )}
        {children?.length > 0 &&
          children.map((child) => (
            <TreeElement
              key={child.id}
              item={child}
              activeItemId={activeItemId}
              onFileItemId={onFileItemId}
              suggestions={suggestions}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onRename={onRename}
              onCopy={onCopy}
              onDelete={onDelete}
              deleteConfirmation={deleteConfirmation}
            />
          ))}
      </ul>
      {requestedAction === ActionType.Delete &&
        deleteConfirmation &&
        deleteConfirmation(
          item,
          handleDeleteConfirmation,
          clearRequestedAction
        )}
    </Item>
  );
};

export default TreeElement;

const Item = styled.li`
  position: relative;
  margin: 0;
  padding-left: 20px;
  border-left: 1px solid #888a93;
  white-space: nowrap;
  user-select: none;
  &:before {
    position: absolute;
    top: 0;
    height: ${ITEM_OUTER_HEIGHT / 2}px;
    width: 20px;
    border-bottom: 1px solid #888a93;
    content: '';
    display: inline-block;
    left: 0;
    border-bottom-left-radius: 2px;
  }
  &:last-child {
    border-left: none;
    & > div {
      margin-left: 1px;
    }
    &:before {
      border-left: 1px solid #888a93;
    }
  }
`;

const Outer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: -15px 0;
  height: ${ITEM_OUTER_HEIGHT}px;
`;

const RevisionName = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.white70};
  ${truncate()}
`;

const Box = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #888a93;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white70};
`;

const Inner = styled.div<{ active?: boolean; isOnFile?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 35px;
  margin: -5px;
  padding: 5px;
  border-radius: 6px;
  position: relative;
  &:hover {
    ${(props) =>
      !props.active &&
      `background: linear-gradient(to right, ${props.theme.colors.primary200_20}, rgba(160, 167, 182, 0));
      ${RevisionName} {
        color: ${props.theme.colors.white100};
      }
      ${Box} {
        color: ${props.theme.colors.white87};
      }`}
    .actions:not(:empty) {
      display: flex;
    }
  }
  ${(props) =>
    props.active &&
    `background: linear-gradient(to right, ${props.theme.colors.primary200_40}, rgba(160, 167, 182, 0));
    ${RevisionName} {
      font-weight: bold;
      color: ${props.theme.colors.white87};
    }
    ${Box} {
      color: ${props.theme.colors.white87};
    }`}
`;

const Actions = styled.div`
  &:empty {
    display: none;
  }
  display: none;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  bottom: 50%;
  right: 2px;
  padding: 2px;
  background-color: ${(props) => props.theme.colors.primary200};
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  border-radius: 2px;
  height: 26px;
  color: ${(props) => props.theme.colors.white100};
  & + & {
    margin-left: 2px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.white20};
  }
`;

const CurrentlyOnFileTag = styled.div`
  margin-left: auto;
  padding: 0 25px 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white87};
`;
