import React, { CSSProperties, useRef } from 'react';
import styled from 'styled-components';
import { B1, Title } from '../Atoms/Atoms';
import useTranslation from '../../translations';
import { filterAcceptedFiles } from './util';

interface Props {
  title?: string;
  testId?: string;
  accept?: string;
  horizontal?: boolean;
  style?: CSSProperties;
  multiple?: boolean;
  disabled?: boolean;
  resetKey?: React.Key; // clear file input from parent component by changing key prop
  overrideContent?: React.ReactNode;
  onChange: (acceptedFiles: File[]) => void;
}

const FileSelector: React.FC<Props> = ({
  testId = 'file-selector',
  title,
  accept,
  horizontal,
  style,
  multiple,
  disabled,
  resetKey,
  overrideContent,
  onChange,
}) => {
  const t = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) {
      return;
    }
    const acceptedFiles = [...files].filter((file: File) =>
      filterAcceptedFiles(file, accept)
    );
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles);
      e.target.value = '';
    }
  };

  const _onClick = (
    e:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    const el = fileInputRef;
    if (!el.current) {
      return;
    }
    el.current.click();
  };

  const _onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.type === 'dragover' || disabled) {
      return;
    }

    let files = [...e.dataTransfer.files].filter((file: File) =>
      filterAcceptedFiles(file, accept)
    );

    if (multiple === false) {
      files = files.slice(0, 1);
    }
    onChange(files);
  };

  let content;
  if (horizontal) {
    content = (
      <ContentHorizontal>
        <Inner>
          {title && <StyledTitle>{title}</StyledTitle>}
          <DropHint>{t('DROP_HINT')}</DropHint>
        </Inner>
        <B1>{t('OR')}</B1>
        <UploadButton onClick={_onClick}>{t('CHOOSE_FILES')}</UploadButton>
      </ContentHorizontal>
    );
  } else {
    content = (
      <ContentVertical>
        {title && <B1>{title}</B1>}
        <B1>{t('DROP_HINT')}</B1>
        <B1>{t('OR')}</B1>
        <UploadButton onClick={_onClick}>{t('CHOOSE_FILES')}</UploadButton>
      </ContentVertical>
    );
  }

  return (
    <Outer
      style={style}
      data-testid={testId}
      disabled={disabled}
      horizontal={horizontal}
      onDrop={_onFileDrop}
      onDragOver={_onFileDrop}
      onClick={_onClick}
    >
      <input
        key={resetKey}
        ref={fileInputRef}
        type="file"
        onClick={(e) => e.stopPropagation()}
        style={{ display: 'none' }}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={_onChange}
      />
      {overrideContent || content}
    </Outer>
  );
};

const Outer = styled.div<{ horizontal?: boolean; disabled?: boolean }>`
  display: flex;
  ${(props) => !props.horizontal && 'flex-direction: column;'}
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  cursor: pointer;
  border: dashed 1px rgba(255, 255, 255, 0.3);
  ${(props) =>
    props.disabled && 'pointer-events: none; cursor: auto; opacity: 60%;'}
`;

const DropHint = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.colors.primary200};
`;

const StyledTitle = styled(Title)`
  font-size: 15px;
  color: ${(props) => props.theme.colors.white87};
  line-height: 15px;
  margin-bottom: 3px;
`;

const UploadButton = styled.button`
  width: 140px;
  height: 40px;
  color: ${(props) => props.theme.colors.white100};
  font-size: 14px;
  line-height: 1.71;
  cursor: pointer;
  padding: 8px 19px 8px 20px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.primary200};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHorizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
`;

const ContentVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
`;

export default FileSelector;
