import { css } from 'styled-components';

export default css`
  [data-list-kind='none'] {
    margin-left: 0 !important;
  }

  [data-list-kind]::before {
    margin-right: 10px;
  }

  [data-node-type='claim'] {
    margin-bottom: 10px;
  }
`;
