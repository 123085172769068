import React from 'react';
import styled from 'styled-components';

const WorldFlag: React.FC = () => (
  <Outer>
    <svg
      style={{ width: '100%', height: '100%' }}
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m508.867188 215.96875c-6.199219.714844-12.449219-2.242188-14-10.222656-5.148438-26.324219-3.753907-55.578125-21.691407-77.535156-12.332031-15.085938-33.261719-32.609376-48.261719-45.230469-19.710937-16.589844-45.019531-23.589844-67.644531-34.933594-17.601562-8.824219-42.664062-36.148437-63.242187-29.800781-12.003906 3.707031-19.296875-7.828125-17.28125-17.335938-6.851563-.546875-13.753906-.910156-20.746094-.910156-84.675781 0-159.707031 41.128906-206.308594 104.472656 2.984375 3.082032 4.449219 7.558594 2.761719 12.996094-5.402344 17.425781-5.949219 36.0625-12.78125 53.0625-6.382813 15.863281-18.804687 27.207031-23.632813 44.039062-2.109374 7.363282-8.113281 10.3125-14.054687 10.03125-1.261719 10.300782-1.984375 20.761719-1.984375 31.398438 0 9.355469.539062 18.589844 1.507812 27.691406 5.070313.644532 9.933594 3.515625 12.324219 9.324219 11.679688 28.449219 11.804688 59.496094 22.617188 88.027344 10.167969 26.828125 33.5625 45.691406 51.324219 67.832031 44.992187 39.273438 103.804687 63.125 168.226562 63.125 141.382812 0 256-114.617188 256-256 0-13.621094-1.085938-26.984375-3.132812-40.03125zm0 0"
        fill="#366db6"
      />
      <g fill="#25ae88">
        <path d="m257.773438 198.804688-32.035157-19.226563c-3.300781-1.976563-7.085937-3.027344-10.9375-3.027344h-18.476562c-6.867188 0-13.3125 3.320313-17.300781 8.90625l-33.820313 47.34375c-2.574219 3.601563-3.960937 7.925781-3.960937 12.359375v9.445313c0 6.460937 2.9375 12.570312 7.980468 16.605469l27.929688 22.351562c5.179687 4.148438 12.003906 5.632812 18.441406 4.023438l8.542969-2.136719c9.667969-2.417969 19.722656 2.207031 24.179687 11.125l8.988282 17.964843c.695312 1.382813 1.234374 2.851563 1.605468 4.351563l8.261719 33.039063 8.476563 33.90625c.226562.9375.527343 1.847656.882812 2.738281l9.671875 24.179687c4.1875 10.449219 15.785156 15.835938 26.464844 12.277344 4.8125-1.605469 8.898437-4.878906 11.511719-9.234375l21.089843-35.148437c.890625-1.492188 1.96875-2.871094 3.195313-4.097657l9.578125-9.578125c3.992187-3.972656 6.234375-9.382812 6.234375-15.023437v-27.871094c0-4.828125 1.640625-9.515625 4.660156-13.285156l15.449219-19.3125c8.039062-10.054688 5.375-24.894531-5.667969-31.515625l-17.382812-10.425782c-3.832032-2.304687-6.839844-5.746093-8.605469-9.859374l-16.976563-39.617188c-3.964844-9.242188-13.851562-14.453125-23.710937-12.476562l-19.15625 3.832031c-5.179688 1.042969-10.574219.105469-15.113281-2.613281zm0 0" />
        <path d="m406.070312 335.449219-26.484374 26.480469-8.828126 44.140624 17.65625 17.652344 26.484376-44.136718zm0 0" />
        <path d="m268.226562.308594c-12.722656 23.660156-36.476562 48.632812-80.613281 52.214844-7.742187.628906-14.980469 4.378906-19.746093 10.503906-.3125.398437-.628907.8125-.945313 1.230468-22.398437 29.480469 8.773437 68.375 43.148437 54.632813 6.207032-2.472656 12.828126-4.132813 19.445313-4.132813 0 0 79.449219-8.828124 123.585937 79.449219 0 0 22.257813 44.507813 66.757813-9.269531 9.839844-11.898438 28.679687-10.714844 35.925781 2.921875 3.089844 5.816406 6.03125 13.3125 8.464844 22.96875 2.738281 10.851563 13.003906 18.0625 24.09375 16.589844 6.292969-.839844 13.664062-1.980469 21.582031-3.507813-15.308593-122.269531-116.753906-217.722656-241.699219-223.601562zm0 0" />
        <path d="m109.257812 143.554688c-2.089843-12.542969-10.054687-23.332032-21.433593-29.015626l-33.144531-16.570312c-29.089844 36.996094-48.246094 82.167969-53.320313 131.546875h21.503906c13.285157 0 25.699219-6.636719 33.066407-17.699219 3.84375-5.753906 9.113281-10.40625 15.300781-13.503906l17.265625-8.632812c15.609375-7.804688 24.304687-24.878907 21.433594-42.089844zm0 0" />
        <path d="m10.355469 275.519531-9.242188 3.960938c6.609375 72.625 43.535157 136.449219 98.039063 178.769531l5.207031-46.917969c1.03125-9.261719 4.265625-18.140625 9.4375-25.894531 6.382813-9.566406 9.789063-20.824219 9.789063-32.324219v-2.445312c0-19.113281-13.488282-35.566407-32.230469-39.320313-7.757813-1.550781-14.890625-5.367187-20.488281-10.960937l-16.355469-16.359375c-11.601563-11.597656-29.078125-14.960938-44.15625-8.507813zm0 0" />
      </g>
    </svg>
  </Outer>
);

export default WorldFlag;

const Outer = styled.div`
  width: 100%;
  height: 100%;
`;
