import React from 'react';
import styled, { useTheme } from 'styled-components';
import { H2 } from '../Atoms/Atoms';
import useTranslation from '../../translations';
import ProgressBar from '../ProgressBar';

const LifespanPlaceholder: React.FC = () => {
  const t = useTranslation();
  const theme = useTheme();

  return (
    <Outer data-testid="lifespan-placeholder-indication">
      <Short>
        <H2>0</H2>
        <Unit>{t(`TIME_SPAN.DAY`)}</Unit>
      </Short>
      <ProgressBar
        colors={[
          theme.colors.success,
          theme.colors.warning,
          theme.colors.red500,
        ]}
        percent={50}
      />
      <Long>{t('LIFE_SPAN.PLACEHOLDER.MISSING_DATE')}</Long>
    </Outer>
  );
};

export default LifespanPlaceholder;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Short = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
`;

const Unit = styled.div``;

const Long = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary200};
`;
