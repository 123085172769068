import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React from 'react';
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  deleteTable,
  goToNextCell,
  splitCell,
  toggleHeaderCell,
  toggleHeaderColumn,
  toggleHeaderRow,
} from 'prosemirror-tables';
import {
  MARK_EM,
  MARK_STRIKE,
  MARK_STRONG,
  MARK_SUPER,
  MARK_SUB,
  MARK_UNDERLINE,
} from '../schema/marks/markNames';
import {
  orderedListTypes,
  unorderedListTypes,
} from '../styles/bulletListStyles';
import FontTypeCommandMenuButton from './renderers/FontTypeCommandMenuButton';
import FontSizeCommandMenuButton from './renderers/FontSizeCommandMenuButton';
import UICommand from './commands/UICommand';
import HeadingCommand from './commands/HeadingCommand';
import MarkToggleCommand from './commands/MarkToggleCommand';
import createCommand from './util/createCommand';
import TableBackgroundColorCommand from './commands/table/TableBackgroundColorCommand';
import TableMergeCellsCommand from './commands/table/TableMergeCellsCommand';
import TableInsertCommand from './commands/TableInsertCommand';
import TableBorderColorCommand from './commands/table/TableBorderColorCommand';
import TableGridSizeMenu from './renderers/TableGridSizeMenu';
import ListToggleCommand from './commands/ListToggleCommand';
import HistoryRedoCommand from './commands/HistoryRedoCommand';
import HistoryUndoCommand from './commands/HistoryUndoCommand';
import TextAlignCommand from './commands/TextAlignCommand';
import SymbolMenu from './renderers/SymbolMenu';

export interface ComponentTypeToolbarItemProps {
  dispatch: (tr: Transaction) => void;
  editorState: EditorState;
  editorView?: EditorView;
  command?: UICommand;
}

export const HISTORY_REDO = new HistoryRedoCommand();
export const HISTORY_UNDO = new HistoryUndoCommand();
export const TABLE_ADD_COLUMN_AFTER = createCommand(addColumnAfter);
export const TABLE_ADD_COLUMN_BEFORE = createCommand(addColumnBefore);
export const TABLE_ADD_ROW_AFTER = createCommand(addRowAfter);
export const TABLE_ADD_ROW_BEFORE = createCommand(addRowBefore);
export const TABLE_BACKGROUND_COLOR = new TableBackgroundColorCommand();
export const TABLE_BORDER_COLOR = new TableBorderColorCommand();
export const TABLE_DELETE_COLUMN = createCommand(deleteColumn);
export const TABLE_DELETE_ROW = createCommand(deleteRow);
export const TABLE_DELETE_TABLE = createCommand(deleteTable);
export const TABLE_INSERT_TABLE = new TableInsertCommand();
export const TABLE_MERGE_CELLS = new TableMergeCellsCommand();
export const TABLE_MOVE_TO_NEXT_CELL = createCommand(goToNextCell(1));
export const TABLE_MOVE_TO_PREV_CELL = createCommand(goToNextCell(-1));
export const TABLE_SPLIT_ROW = createCommand(splitCell);
export const TABLE_TOGGLE_HEADER_CELL = createCommand(toggleHeaderCell);
export const TABLE_TOGGLE_HEADER_COLUMN = createCommand(toggleHeaderColumn);
export const TABLE_TOGGLE_HEADER_ROW = createCommand(toggleHeaderRow);

export const H1 = new HeadingCommand(1);
export const H2 = new HeadingCommand(2);
export const H3 = new HeadingCommand(3);
export const H4 = new HeadingCommand(4);
export const H5 = new HeadingCommand(5);
export const H6 = new HeadingCommand(6);
export const STRIKE = new MarkToggleCommand(MARK_STRIKE);
export const STRONG = new MarkToggleCommand(MARK_STRONG);
export const SUPER = new MarkToggleCommand(MARK_SUPER);
export const SUB = new MarkToggleCommand(MARK_SUB);
export const EM = new MarkToggleCommand(MARK_EM);

const OrderedListCommands = orderedListTypes.reduce<Record<string, UICommand>>(
  (prev, current) => {
    prev[current] = new ListToggleCommand(current);
    return prev;
  },
  {}
);

const UnorderedListCommands = unorderedListTypes.reduce<
  Record<string, UICommand>
>((prev, current) => {
  prev[current] = new ListToggleCommand(current);
  return prev;
}, {});

export const OLLowerAlpha = new ListToggleCommand('lower-alpha');
export const OLLowerRoman = new ListToggleCommand('lower-roman');
export const OLRoman = new ListToggleCommand('upper-roman');
export const OLAlpha = new ListToggleCommand('upper-alpha');
export const OLNumbered = new ListToggleCommand('ordered');
export const ULBullet = new ListToggleCommand('bullet');

export const ALIGN_LEFT = new TextAlignCommand('left');
export const ALIGN_CENTER = new TextAlignCommand('center');
export const ALIGN_RIGHT = new TextAlignCommand('right');

export const UNDERLINE = new MarkToggleCommand(MARK_UNDERLINE);
type ToolbarCommand =
  | JSX.Element
  | React.ElementType
  | UICommand
  | Array<Record<string, UICommand>>;

export type ToolbarItem = {
  command?: ToolbarCommand;
  renderer?: React.ElementType;
  icon?: React.ReactNode;
};

export type UIToolbarItem = {
  command: UICommand;
  renderer?: React.ElementType;
  icon?: string;
  footer?: string;
};

export type ToolbarItemSubGroup = {
  group: Array<Record<string, UIToolbarItem>>;
  icon?: React.ReactNode;
};

export type CommandGroup = Record<string, ToolbarItem | ToolbarItemSubGroup>;

export const isToolbarItemSubGroup = (
  item: ToolbarItem | ToolbarItemSubGroup
): item is ToolbarItemSubGroup =>
  (item as ToolbarItemSubGroup).group !== undefined;

export const isToolbarItem = (
  item: ToolbarItem | ToolbarItemSubGroup
): item is ToolbarItem => !isToolbarItemSubGroup(item);

export const TABLE_COMMANDS_GROUP: ToolbarItemSubGroup = {
  icon: 'icn-table',
  group: [
    {
      INSERT_TABLE: {
        command: TABLE_INSERT_TABLE,
        renderer: TableGridSizeMenu,
        footer: 'Columns',
        icon: 'icn-table',
      },
    },
    // {
    //   'Fill Color...': { command: TABLE_BACKGROUND_COLOR },
    //   'Border Color....': { command: TABLE_BORDER_COLOR },
    // },
    {
      INSERT_COLUMN_BEFORE: {
        footer: 'Rows',
        icon: 'icn-add-column-left',
        command: TABLE_ADD_COLUMN_BEFORE,
      },
      INSERT_COLUMN_AFTER: {
        footer: 'Rows',
        command: TABLE_ADD_COLUMN_AFTER,
        icon: 'icn-add-column-right',
      },
      DELETE_COLUMN: {
        footer: 'Rows',
        command: TABLE_DELETE_COLUMN,
        icon: 'icn-delete-column',
      },
    },
    {
      INSERT_ROW_ABOVE: {
        footer: 'Table Cells',
        command: TABLE_ADD_ROW_BEFORE,
        icon: 'icn-add-row-above',
      },
      INSERT_ROW_BELOW: {
        footer: 'Table Cells',
        command: TABLE_ADD_ROW_AFTER,
        icon: 'icn-add-row-below',
      },
      DELETE_ROW: {
        footer: 'Table Cells',
        command: TABLE_DELETE_ROW,
        icon: 'icn-delete-row',
      },
    },
    {
      SPLIT_CELLS: { command: TABLE_SPLIT_ROW, icon: 'icn-split-cells' },
      MERGE_CELLS: { command: TABLE_MERGE_CELLS, icon: 'icn-merge-cells' },
      DELETE_TABLE: {
        command: TABLE_DELETE_TABLE,
        icon: 'icn-delete',
      },
    },
  ],
};

export const COMMAND_GROUPS: Array<CommandGroup> = [
  {
    'Font Type': { command: FontTypeCommandMenuButton, icon: '' },
  },
  {
    'Text Size': { command: FontSizeCommandMenuButton, icon: '' },
  },
  {
    'Headings...': {
      icon: 'icn-heading',
      group: [
        {
          H1: {
            command: H1,
          },
          H2: { command: H2 },
          H3: { command: H3 },
          H4: { command: H4 },
          H5: { command: H5 },
          H6: { command: H6 },
        },
      ],
    },
  },
  {
    Bold: { command: STRONG, icon: 'icn-bold' },
    Italic: { command: EM, icon: 'icn-italic' },
    Underline: { command: UNDERLINE, icon: 'icn-underlined' },
    Superscript: { command: SUPER, icon: 'icn-superscript' },
    Subscript: { command: SUB, icon: 'icn-subscript' },
  },
  {
    GRID: TABLE_COMMANDS_GROUP,
    SYMBOL_MENU: {
      renderer: SymbolMenu,
    },
  },
  {
    LEFT: { command: ALIGN_LEFT, icon: 'icn-text-left' },
    CENTER: { command: ALIGN_CENTER, icon: 'icn-text-center' },
    RIGHT: { command: ALIGN_RIGHT, icon: 'icn-text-right ' },
  },
  {
    UL: {
      icon: 'icn-bullet-list',
      group: [
        ...Object.entries(UnorderedListCommands).map(([name, command]) => ({
          [name]: { command },
        })),
      ],
    },
    OL: {
      icon: 'icn-numbered-list',
      group: [
        ...Object.entries(OrderedListCommands).map(([name, command]) => ({
          [name]: { command },
        })),
      ],
    },
  },
  {
    undo: { command: HISTORY_UNDO, icon: 'icn-undo' },
    redo: { command: HISTORY_REDO, icon: 'icn-redo' },
  },
];
