import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import { Tag, Select, Typography } from 'antd';
import { Api, T, Components } from '@ipos/shared';
import { QueryKey } from 'queries';
import useTranslation from 'translations';

const PAGE_SIZE = 50;
const { Paragraph } = Typography;
const { Spinner } = Components;

interface Props {
  onSelect: (selection: string[]) => void;
  /** An optional prop specifying a list of IpcClass ids to exclude from selection*/
  filterValues?: string[];
}

const IpcClassSelector: React.FC<Props> = ({ filterValues, onSelect }) => {
  const t = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const q = useInfiniteQuery({
    queryKey: [QueryKey.IpcClasses, searchTerm],
    queryFn: async ({ pageParam: skip = 0 }): Promise<T.IpcClassesPage> => {
      const ipcClasses = await Api.ipc.getClasses(searchTerm, {
        skip,
        pageSize: PAGE_SIZE + 1,
      });

      const hasNextPage = ipcClasses.length > PAGE_SIZE;
      ipcClasses.splice(PAGE_SIZE);
      return {
        ipcClasses,
        nextCursor: hasNextPage ? skip + PAGE_SIZE : undefined,
      };
    },
    getNextPageParam: (lastPage: T.IpcClassesPage) => lastPage.nextCursor,
  });

  const handleSearch = (value: string): void => setSearchTerm(value);

  const handleChange = (selection: string[]): void => {
    onSelect(selection);
  };

  const handlePopupScroll = async (e: React.UIEvent<HTMLElement>) => {
    e.persist();

    const { target } = e;
    // @ts-ignore
    const { scrollTop, offsetHeight, scrollHeight } = target;

    if (scrollTop + offsetHeight === scrollHeight) {
      await q.fetchNextPage();
    }
  };

  const ipcClassList = useMemo((): T.IpcClass[] => {
    if (!q.data) {
      return [];
    }

    return q.data.pages.reduce(
      (result: T.IpcClass[], page): T.IpcClass[] => [
        ...result,
        ...page.ipcClasses,
      ],
      []
    );
  }, [q.data]);

  const availableOptions = ipcClassList.map(({ id, code, description }) => (
    <Select.Option
      key={id}
      data-testid="ipc-class-option"
      value={id}
      disabled={filterValues?.includes(id)}
      label={code}
      title={`${code} - ${description}`}
    >
      <OptionContent>
        <Tag>{code}</Tag>
        <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
          {description}
        </Paragraph>
      </OptionContent>
    </Select.Option>
  ));

  return (
    <Select
      optionLabelProp="label"
      onPopupScroll={handlePopupScroll}
      style={{ width: '100%' }}
      allowClear
      mode="multiple"
      placeholder={t('IPC.SELECT')}
      onChange={handleChange}
      onSearch={handleSearch}
      filterOption={false}
      autoFocus
      dropdownRender={(menu) =>
        q.isLoading ? (
          <SpinWrapper>
            <Spinner tip={t('IPC.SEARCHING')} />
          </SpinWrapper>
        ) : (
          menu
        )
      }
    >
      {availableOptions}
    </Select>
  );
};

export default IpcClassSelector;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
`;

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
`;
