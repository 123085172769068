import React from 'react';
import styled from 'styled-components';
import { T } from '../../..';
import { useSessionContext } from '../../contexts';
import { getAppUrls } from '../../utils';
import AppCardListItem from './AppCardListItem';

const isProduction = process.env.NX_BUILD_ENV === 'production';

const MIN_COL_WIDTH = 200;

type AppCard = {
  appIdentifier?: T.ApplicationIdentifier;
  name: string;
  description: string;
  link: string;
  azureGroup?: string;
};

interface Props {
  gridGap?: number;
  variableColumns?: boolean;
  includeDashboard?: boolean;
  listStyle?: React.CSSProperties;
  cardStyle?: React.CSSProperties;
  logoStyle?: React.CSSProperties;
  cardNameStyle?: React.CSSProperties;
  cardDescriptionStyle?: React.CSSProperties;
}

const AppCardList: React.FC<Props> = ({
  gridGap = 8,
  variableColumns = true,
  includeDashboard = true,
  listStyle,
  ...otherProps
}) => {
  const { session } = useSessionContext();
  const philipaAppUrls = getAppUrls();

  const apps: AppCard[] = [
    {
      appIdentifier: T.ApplicationIdentifier.TSD_EDITOR,
      name: 'APPS.TSDEDITOR',
      description: 'APP_DESCRIPTION.TSDEDITOR',
      link: philipaAppUrls[T.ApplicationIdentifier.TSD_EDITOR] || '',
      azureGroup: AzureGroupsConfig.AppAccessTsd,
    },
    {
      appIdentifier: T.ApplicationIdentifier.EVALUATOR,
      name: 'APPS.EVALUATOR',
      description: 'APP_DESCRIPTION.EVALUATOR',
      link: philipaAppUrls[T.ApplicationIdentifier.EVALUATOR] || '',
      azureGroup: AzureGroupsConfig.AppAccessEval,
    },
    {
      appIdentifier: T.ApplicationIdentifier.LIFTOFF,
      name: 'APPS.LIFTOFF',
      description: 'APP_DESCRIPTION.LIFTOFF',
      link: philipaAppUrls[T.ApplicationIdentifier.LIFTOFF] || '',
      azureGroup: AzureGroupsConfig.AppAccessFto,
    },
    {
      appIdentifier: T.ApplicationIdentifier.OFFICE_ACTION_HERO,
      name: 'APPS.OFFICEACTIONHERO',
      description: 'APP_DESCRIPTION.OFFICEACTIONHERO',
      link: philipaAppUrls[T.ApplicationIdentifier.OFFICE_ACTION_HERO] || '',
      azureGroup: AzureGroupsConfig.AppAccessOah,
    },
    {
      name: 'APPS.IDS',
      description: 'APP_DESCRIPTION.IDS',
      link:
        `${
          philipaAppUrls[T.ApplicationIdentifier.OFFICE_ACTION_HERO]
        }/ids-download` || '',
      azureGroup: AzureGroupsConfig.AppAccessOah,
    },
    {
      appIdentifier: T.ApplicationIdentifier.DDUDE,
      name: 'APPS.DDUDE',
      description: 'APP_DESCRIPTION.DDUDE',
      link: philipaAppUrls[T.ApplicationIdentifier.DDUDE] || '',
      azureGroup: AzureGroupsConfig.AppAccessDdude,
    },
    {
      appIdentifier: T.ApplicationIdentifier.COMPETITOR,
      name: 'APPS.COMPETITOR',
      description: 'APP_DESCRIPTION.COMPETITOR',
      link: philipaAppUrls[T.ApplicationIdentifier.COMPETITOR] || '',
      azureGroup: AzureGroupsConfig.AppAccessComp,
    },
    {
      name: 'APPS.PATENTANALYSISTOOL',
      description: 'APP_DESCRIPTION.PATENTANALYSISTOOL',
      link:
        `${
          philipaAppUrls[T.ApplicationIdentifier.OFFICE_ACTION_HERO]
        }/patent-analysis` || '',
      azureGroup: AzureGroupsConfig.AppAccessOah,
    },
  ];

  if (includeDashboard) {
    apps.unshift({
      // TODO: When Dashboard app icon gets updated, add DASHBOARD app identifier to render it accordingly
      name: 'PHILIPA_APP_CARD',
      description: 'APP_DESCRIPTION.DASHBOARD',
      link: philipaAppUrls[T.ApplicationIdentifier.DASHBOARD] || '',
    });
  }

  // TODO: Remove check when Patentor and AI templates move to production
  if (!isProduction) {
    apps.push({
      appIdentifier: T.ApplicationIdentifier.PATENTOR,
      name: 'APPS.PATENTOR',
      description: 'APP_DESCRIPTION.PATENTOR',
      link: philipaAppUrls[T.ApplicationIdentifier.PATENTOR] || '',
      azureGroup: AzureGroupsConfig.AppAccessPat,
    });
    // @ts-ignore
    apps.push({
      name: 'APPS.PHILIPAI',
      description: 'APP_DESCRIPTION.PHILIPAI',
    });
  }

  const availableApps = apps.filter((app) => {
    if (!app.azureGroup) {
      return true;
    }
    return session.azureGroups.includes(app.azureGroup);
  });

  let colCount = 3;
  if (variableColumns && availableApps.length <= 6) {
    colCount = 2;
  }

  return (
    <Wrapper colCount={colCount} gridGap={gridGap} style={listStyle}>
      {availableApps.map((app, index) => (
        <AppCardListItem key={index} {...app} {...otherProps} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ gridGap: number; colCount: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.colCount},
    minmax(${MIN_COL_WIDTH}px, 1fr)
  );
  gap: ${(props) => props.gridGap}px;
`;

export default AppCardList;
