import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';

const useVisualizationMostUsedTsdElements = (
  options?: UseQueryOptions<T.DataPoint[], AxiosError>
): UseQueryResult<T.DataPoint[], AxiosError> => {
  const { preferredLanguage } = useAppContext();
  return useQuery<T.DataPoint[], AxiosError>(
    [QueryKey.MostUsedTsdElements, preferredLanguage],
    () => Api.tsd.getMostUsedTsdElements(preferredLanguage),
    options
  );
};

export default useVisualizationMostUsedTsdElements;
