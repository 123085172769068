import React from 'react';
import { Space, Typography } from 'antd';

import { T } from '@ipos/shared';
import CpcTag from 'components/TsdElement/CpcTag';
import useTranslation from 'translations';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';

const { Text } = Typography;

interface Props {
  warnings?: string[];
  data: T.TsdElementIpcClass;
  onRemoveRequest: (id: string) => void;
}

const IpcClassListItem: React.FC<Props> = ({
  warnings,
  data,
  onRemoveRequest,
}) => {
  const t = useTranslation();
  const { id, ipcClass, isBaseAttribute } = data;
  const { code, description } = ipcClass;
  return (
    <TsdElementAttributeListItem
      warnings={warnings}
      isBaseAttribute={isBaseAttribute}
      onRemoveRequest={() => onRemoveRequest(id)}
      confirmRemovalTitle={t('IPC.DELETE_IPC_CLASS')}
    >
      <Space align="start">
        <CpcTag code={code} />
        <Text type="secondary">{description}</Text>
      </Space>
    </TsdElementAttributeListItem>
  );
};

export default IpcClassListItem;
