import React from 'react';
import styled from 'styled-components';
import { T } from '../../../..';
import { useTranslation } from '../../../translations';
import { FlatButton } from '../../Button';
import { ModalState, SCREEN } from '../../../types';
import { useSessionContext } from '../../../contexts';

interface Props {
  item: T.StakeholderListItem;
  setIsPopoverOpen: (value: boolean) => void;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  editScreen: SCREEN;
}

const ActionStakeholderPopover: React.FC<Props> = ({
  item,
  setModalState,
  setIsPopoverOpen,
  editScreen,
}) => {
  const t = useTranslation();
  const { session } = useSessionContext();

  const isSelf =
    session.userId === item.contact?.userId || session.userId === item.userId;

  return (
    <Outer>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setModalState((prev) => ({
            ...prev,
            currentScreen: editScreen,
            activeStakeholder:
              ({
                contact: item,
              } as T.PatentApplicationStakeholder) || null,
            panelOpen: true,
          }));
          setIsPopoverOpen(false);
        }}
        leftIcon={<i className="icn-edit" />}
        style={{ margin: 0 }}
      >
        {t('STAKEHOLDERS.EDIT_CONTACT')}
      </Button>
      {!isSelf && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setModalState((prev) => ({
              ...prev,
              currentScreen: SCREEN.DELETE_CONTACT,
              activeStakeholder:
                ({
                  contact: item,
                  role: item.role,
                } as T.PatentApplicationStakeholder) || null,
              panelOpen: true,
            }));
            setIsPopoverOpen(false);
          }}
          leftIcon={<i className="icn-delete" />}
          style={{ margin: 0 }}
        >
          {t('STAKEHOLDERS.DELETE_CONTACT')}
        </Button>
      )}
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  z-index: 100;
  border-radius: 8px;
  padding: 20px 0;
  background-image: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end} 100%
  );
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const Button = styled(FlatButton)`
  padding: 10px 30px 8px 20px;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  &:last-child {
    &:hover {
      background-color: rgba(197, 101, 129, 0.3);
    }
  }
`;

export default ActionStakeholderPopover;
