import { BaseDto, Contact } from './common';
import { DocumentMetadata } from './documents';
import { Language } from './language';
import { EngineeringDomain } from './oah';

export enum PatentUploadStatus {
  Enqueued = 0,
  Processing,
  Success,
  Failed,
}

export enum PatentUploadStage {
  Unknown = 0,
  Parsing,
  Processing,
  Claims,
}

export interface FigureImage extends BaseDto {
  blobResourceId: string;
  figureNumbers: string[];
  textContent: string[];
  imageSizeWidth: number;
  imageSizeHeight: number;
  label: string;
  description: string;
  includeInAbstract: boolean;
}

export interface Examples extends BaseDto {
  blobResourceId: string;
  figureNumbers: string[];
  textContent: string[];
  imageSizeWidth: number;
  imageSizeHeight: number;
}

export enum ClaimPartType {
  Invention = 'Invention',
  PreambleFeature = 'PreambleFeature',
  Dependencies = 'Dependencies',
  PreambleDelimiter = 'PreambleDelimiter',
  CharacterizingFeature = 'CharacterizingFeature',
}

export interface ClaimPart {
  partNumber: number;
  type: ClaimPartType;
  text: string;
  numberingLevel: number;
  numberingFormat: NumberingFormat;
  numberingValue: string | number;
}

export enum NumberingFormat {
  None = 'None',
  Bullet = 'Bullet',
  Number = 'Number',
  Letter = 'Letter',
  Roman = 'Roman',
}

export interface Claim extends BaseDto {
  claimTraceId: string;
  claimNumber: number;
  text: string;
  richText?: string;
  dependencies: number[];
  parts: ClaimPart[];
  advantage: string | null;
}

export interface PatentUpload {
  id: string;
  patentId: string;
  uploadId: string;
  fileName: string;
  warnings: string;
  createdOn: string;
  status: PatentUploadStatus;
  stage: PatentUploadStage;
  progressDescription: string;
  processingProgress: number;
}

export interface PatentIdentifierAndDate {
  identifier: PatentIdentifier;
  date?: string;
}

export interface PatentIdentifier {
  countryCode: string;
  number: string;
  kind?: string | null;
}

export enum PatentRevisionDocumentType {
  Claims = 'Claims',
  Description = 'Description',
  Figures = 'Figures',
  Sequences = 'Sequences',
  Abstract = 'Abstract',
}

export interface PatentRevision extends BaseDto {
  patentApplicationId: string;
  previousRevisionId?: string;
  baseRevisionId?: string;
  comment: string;
  revisionName: string;
  combinationInfo: string;
  amendmentDescription: string;
  isFiled: boolean;
  description: string;
  title: string;
  abstract: string;
  descriptionContentId: string;
  language: Language;
  claims: Claim[];
  figureDescriptions: { [key: string]: string };
  figureImages: FigureImage[];
  referenceSignList: { [key: string]: string };
  importedDocuments: Record<string, PatentRevisionDocumentType[]>;
  mostImportantAspect?: string | null;
}

export interface PatentRevisionUpdate {
  previousRevisionIdChanged: boolean;
  revisionNameChanged: boolean;
  commentChanged: boolean;
  amendmentDescriptionChanged: boolean;
  titleChanged: boolean;
  previousRevisionId?: string;
  revisionName: string;
  comment: string;
  amendmentDescription: string;
  title: string;
}

export interface PatentRevisionDocument {
  document: DocumentMetadata;
  documentTypes: PatentRevisionDocumentType[];
}

export interface DeletePatentRevisionDocument {
  revisionId: string;
  document: DocumentMetadata;
  types: PatentRevisionDocumentType[];
}

export interface PatentRevisionPreview extends BaseDto {
  revisionName: string;
  comment: string;
  isFiled: boolean;
  isCurrentOnFile: boolean;
  descriptionContentId: string;
  claims: Claim[];
  figureImages: FigureImage[];
  examples: Examples[];
  documents: PatentRevisionDocument[];
  importProcessId: string | null;
  isImportProcessInProgress: boolean;
}

export interface CreatePatentRevisionImport {
  patentApplicationId: string;
  name: string;
  lifeCycleState: PatentLifeCycleState;
  files: File[];
  documentTypesJson: string;
}

export interface UpdatePatentRevisionImport {
  patentRevisionId: string;
  name: string;
  files: File[];
  documentTypesJson: string;
}

export interface PatentAmendmentSummary {
  operations: PatentAmendmentOperation[];
  claimDiffs: ClaimChange[];
}

export interface PatentAmendmentOperation {
  action: PatentAmendmentAction;
  targetClaimTraceIds: string[];
}

export interface ClaimChange {
  claimTraceId: string;
  originalClaimNumber: number;
  amendedClaimNumber: number;
  claimTextDiffs: ClaimTextDiff[];
  hasChanges(): boolean;
}

export interface ClaimTextDiff {
  diffType: ClaimTextDiffType;
  startIndex: number;
  text: string;
  changedByUserId: string;
  timestamp: string;
  toString(): string;
}

export enum ClaimTextDiffType {
  None = 'None',
  Insertion = 'Insertion',
  Deletion = 'Deletion',
  ClaimCombinationInsertion = 'ClaimCombinationInsertion',
  ClaimCombinationDeletion = 'ClaimCombinationDeletion',
  ManualInsertion = 'ManualInsertion',
  ManualDeletion = 'ManualDeletion',
}

export enum PatentLifeCycleState {
  Drafting = 'Drafting',
  Filed = 'Filed',
  Examination = 'Examination',
  Granted = 'Granted',
  Limitation = 'Limitation',
  Opposition = 'Opposition',
  Appeal = 'Appeal',
  Abandoned = 'Abandoned',
}

export interface PatentRevisionHistoryItem {
  id: string;
  previousRevisionId?: string;
  revisionName: string;
  isFiled: boolean;
}

export interface PatentPublicationFamilyMember {
  application: PatentIdentifierAndDate;
  publication: PatentIdentifierAndDate;
}

export interface PatentCitation {
  citedPatentPublication: PatentIdentifierAndDate;
  citedByPatentPublication: PatentIdentifierAndDate;
  citedPhase: string;
  citedBy: string;
  sequence: string;
  categories: string[];
  claimNumbers: string[];
  passages: string[];
}

export enum PatentApplicationStakeholderRole {
  Inventor = 'Inventor',
  Applicant = 'Applicant',
  InHouseParalegal = 'InHouseParalegal',
  Examiner = 'Examiner',
  InHouseCounsel = 'InHouseCounsel',
  InHouseCoCounsel = 'InHouseCoCounsel',
  ExternalCounsel = 'ExternalCounsel',
  LawFirm = 'LawFirm',
}

export enum PatentAmendmentAction {
  EditClaim = 'EditClaim',
  DeleteClaim = 'DeleteClaim',
  InsertClaim = 'InsertClaim',
  CombineClaims = 'CombineClaims',
  EditPatentRevision = 'EditPatentRevision',
  DeletePatentRevision = 'DeletePatentRevision',
}

export interface AmendmentHistory {
  id: string;
  action: string;
  argumentDescription: string;
  isUndone: boolean;
  isRejected: boolean;
}

export interface PatentRevisionUndoRedoState {
  amendmentHistory: AmendmentHistory[];
  undoableAction?: PatentAmendmentAction;
  redoableAction?: PatentAmendmentAction;
}

export interface PatentApplicationStakeholder extends BaseDto {
  contact: Contact;
  role?: PatentApplicationStakeholderRole;
}

export interface PatentApplicationStakeholderDisplayNameAndRole
  extends BaseDto {
  contactId: string;
  displayName?: string;
  role?: PatentApplicationStakeholderRole;
}

export type NewContact = Omit<Contact, keyof BaseDto>;

export interface NewPatentApplicationStakeholder {
  contact: NewContact;
  role: PatentApplicationStakeholderRole;
}

export interface PatentPublicationFamily extends BaseDto {
  publications: PatentPublication[];
  epoFamilyId: string;
}

export interface ExtendPublicationData {
  downloadPdf?: boolean;
  performOcr?: boolean;
  fetchClaimsAndDescription?: boolean;
  patentPublicationId: string;
}

export enum PatentPublicationIdentifierFormat {
  DocDb = 'DocDb',
  PatBase = 'PatBase',
}

export enum PatentPublicationIdentifierType {
  Unknown = 'Unknown',
  Application = 'Application',
  Publication = 'Publication',
}

export interface PatentPublicationText extends BaseDto {
  language: Language;
  text: string;
  texts: string[];
  isAutoTranslation: boolean;
}

export interface PatentPublicationIdentifier extends BaseDto {
  identifier: string;
  format: PatentPublicationIdentifierFormat;
  type: PatentPublicationIdentifierType;
}

export interface PatentPublication extends BaseDto {
  documentId?: string;
  patentPublicationFamilyId: string;
  applicationIdentifier: PatentIdentifier;
  applicationDate?: string;
  publicationIdentifier: PatentIdentifier;
  publicationDate?: string;
  calculatedExpiryDate?: string;
  title: PatentPublicationText[];
  abstract?: PatentPublicationText[];
  description?: PatentPublicationText[];
  claimSets?: PatentPublicationText[];
  applicants: string[];
  inventors: string[];
  icpClassifications: string[];
  icprClassifications: string[];
  referencesCited: PatentCitation[];
}

export interface PatentPublicationBatchImportRequest {
  patentIdentifiers: string[];
  performOcr: boolean;
  downloadPdf: boolean;
  fetchClaimsAndDescription: boolean;
  preferDefaultPublicationHandler: boolean;
}

export interface PatentPublicationBatchImportProcess {
  processId: string;
  status: string;
  progress: number;
  totalEntriesCount?: number;
  successCount?: number;
  importTasks: PatentPublicationBatchImportProcessTask[];
}

export interface PatentPublicationBatchImportProcessTask extends BaseDto {
  publicationId?: string;
  publicationIdentifier: string;
  progressPercentage: number;
  status: PatentPublicationBatchImportProcessTaskStatus;
  errorCode?: PatentPublicationBatchImportProcessErrorCode;
}

export enum PatentPublicationBatchImportProcessTaskStatus {
  Unknown = 0,
  Pending = 10,
  Processing = 20,
  Succeeded = 30,
  Skipped = 40,
  Failed = 90,
}

export enum PatentPublicationBatchImportProcessErrorCode {
  Unknown = 0,
  InvalidIdentifier = 10,
  HandlePublicationIssue = 20,
  Duplicate = 30,
}

export interface PatentApplication extends BaseDto {
  applicantReference: string;
  lawFirmReference: string;
  projectReference: string;
  title: string;
  mostImportantAspect: string;
  language: Language;
  dueDate?: string;
  engineeringDomain: EngineeringDomain;
  currentState: PatentLifeCycleState;
  patentPublicationFamilyId?: string;
  applicationDocumentId?: string;
  stakeholders: PatentApplicationStakeholder[];
  publicationIds: string[];
  officeActionIds: string[];
}

export interface PriorArtDiscussion {
  suggestedAmendments: string[];
  priorArtDiscussions: PriorArtDiscussionItem[];
}

export interface PriorArtDiscussionItem {
  citationNumber: number;
  citationNumberFormat: string;
  citedLiterature: string;
  abstractText: string | null;
  firstClaim: string | null;
  isClosestPriorArt: boolean;
  citedDocumentId: string | null;
  aiSummary: string | null;
}

export interface GenerateClaimDocument {
  format: string;
  expandFeatures?: boolean;
  trackChanges?: boolean;
  translation?: Language;
  showDisclaimer?: boolean;
}

export interface GenerateTranslationDocument {
  format: string;
  translation: Language;
}

export interface GenerateTranslationDocumentRequest {
  patentRevisionId: string;
  translations: GenerateTranslationDocument[];
}

export interface PatenRevisionTranslationStatus {
  languages: Language[];
  hasStaleTranslations: boolean;
}

export interface ClaimTranslationStatus {
  claimTranslationStatuses: {
    claimId: string;
    claimNumber: number;
    id: string;
    isMachineTranslated: boolean;
    isStaleTranslation: boolean;
  }[];
  patentRevisionId: string;
  language: Language;
  untranslatedClaimIds: string[];
}

export interface ClaimTranslation extends BaseDto {
  claimId: string;
  claimNumber: number;
  language: Language;
  text: string;
  isMachineTranslated: boolean;
  isStaleTranslation: boolean;
}

export interface StakeholderListItem extends Contact {
  role?: PatentApplicationStakeholderRole;
  stakeholderId?: string;
  contact?: Contact;
}

export interface ClaimAmendmentResult {
  patentRevision: PatentRevision;
  warnings: string[];
  suggestions: string[];
}
