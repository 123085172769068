import { EditorState } from 'prosemirror-state';

import { MARK_FONT_TYPE } from '../../schema/marks/markNames';
import findActiveMark from './findActiveMark';

export const FONT_TYPE_NAME_DEFAULT = 'Arial';

export default function findActiveFontType(state: EditorState): string {
  const { schema, doc, selection, tr } = state;
  const markType = schema.marks[MARK_FONT_TYPE];
  if (!markType) {
    return FONT_TYPE_NAME_DEFAULT;
  }
  const { from, to, empty } = selection;

  if (empty) {
    const storedMarks =
      tr.storedMarks ||
      state.storedMarks ||
      (selection.$head && selection.$head.marks && selection.$head.marks()) ||
      [];
    const sm = storedMarks.find((m) => m.type === markType);
    return (sm && sm.attrs.name) || FONT_TYPE_NAME_DEFAULT;
  }

  const mark = markType ? findActiveMark(doc, from, to, markType) : null;
  const fontName = mark && mark.attrs.name;
  if (!fontName) {
    return FONT_TYPE_NAME_DEFAULT;
  }

  return fontName;
}
