import React from 'react';
import styled from 'styled-components';
import { NavigationBarItemType } from '../../types/common';

interface Props {
  type: NavigationBarItemType;
  active: boolean;
  number?: number;
}

const NavigationBarStep: React.FC<Props> = ({ type, active, number }) => {
  let content = <div />;

  switch (type) {
    case NavigationBarItemType.Default:
      content = <span>{number}</span>;
      break;
    case NavigationBarItemType.Done:
      content = <i className="icn-done-solid" />;
      break;
    case NavigationBarItemType.InProgress:
      content = <i className="icn-play-solid"></i>;
      break;
  }

  return (
    <Outer type={type} active={active}>
      {content}
    </Outer>
  );
};

export default NavigationBarStep;

const Outer = styled.div<{
  type: NavigationBarItemType;
  active: boolean;
}>`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;

  ${(props) =>
    props.type === NavigationBarItemType.Default &&
    `
      background: ${
        props.active
          ? props.theme.colors.white87
          : props.theme.colors.primary200
      };

      color: ${
        props.active ? props.theme.colors.black87 : props.theme.colors.white60
      };

  `}

  i {
    font-size: 20px;
    opacity: ${(props) => (props.active ? 1 : 0.7)};
  }

  ${(props) =>
    props.type === NavigationBarItemType.Done &&
    `
    i {
        color: ${props.theme.colors.secondary400};
    }
    `}
`;
