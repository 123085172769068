import { Schema } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import React from 'react';

import {
  EditorContentType,
  ImagePluginSettings,
  ImagePluginState,
} from '../../../types';
import { Api } from '../../../..';
import { imagePluginKey } from './imageViewPlugin/utils';
import dropHandler from './imageViewPlugin/dropHandler';
import pasteHandler from './imageViewPlugin/pasteHandler';

import './imageViewPlugin/styles.less';
import { createReactNodeView } from './imageViewPlugin/ReactNodeView';
import ImageViewDescription from './imageViewPlugin/ImageViewDescription';

const imageDescriptionPlugin = (
  schema: Schema,
  pluginSettings: ImagePluginSettings,
  editorDocumentId: string,
  handleCreatePortal: (portal: React.ReactPortal) => void,
  readOnly: boolean
): Plugin<ImagePluginState> =>
  new Plugin({
    key: imagePluginKey,
    state: pluginSettings.createState(pluginSettings, schema),
    props: {
      decorations: pluginSettings.createDecorations,
      nodeViews: {
        image: (node, view, getPos, decorations) =>
          createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            editorDocumentId,
            component: ImageViewDescription,
            onCreatePortal: handleCreatePortal,
            readOnly,
            lazyLoad: true,
          }),
        embeddedObject: (node, view, getPos, decorations) =>
          createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            editorDocumentId,
            component: ImageViewDescription,
            onCreatePortal: handleCreatePortal,
            readOnly,
            lazyLoad: true,
          }),
      },
      handleDOMEvents: {
        paste: pasteHandler({
          editorType: EditorContentType.Description,
          pluginSettings,
          schema,
          editorDocumentId,
          uploadFunction: Api.oa.postUploadResource,
        }),
        drop: dropHandler(
          pluginSettings,
          schema,
          editorDocumentId,
          Api.oa.postUploadResource
        ),
      },
    },
  });

export default imageDescriptionPlugin;
