import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';

interface Props {
  code: string;
}

const CpcClassTag: React.FC<Props> = ({ code }) => (
  <StyledTag>{code}</StyledTag>
);

export default CpcClassTag;

const StyledTag = styled(Tag)`
  margin-bottom: 4px;
  background-color: rgba(242, 201, 65, 0.25);
  border-radius: 3px;
  border-color: rgba(255, 255, 255, 0.25);
`;
