import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../Icon';
import { Spinner } from '../../../Spinner';
import { Colors } from '../../../../theme';

interface Props {
  text: string;
  spinner?: boolean;
}

const DocumentSearchStatus: React.FC<Props> = ({ text, spinner }) => (
  <Outer>
    {spinner ? (
      <Spinner size="small" style={{ marginRight: 5 }} />
    ) : (
      <Icon className="icn-error-solid" color={Colors.black70} size={21} />
    )}
    <Text>{text}</Text>
  </Outer>
);

export default DocumentSearchStatus;

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black70};
`;
