import React from 'react';
import styled from 'styled-components';
import { Language } from '../../../types';

interface Props {
  label: Language;
  children: React.ReactNode;
}

const RowItem: React.FC<Props> = ({ label, children }) => (
  <Outer>
    <Label>{label}</Label>
    {children}
  </Outer>
);

export default RowItem;

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Label = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.white60};
  min-width: 150px;
`;
