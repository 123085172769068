/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'querystring';
import { AxiosResponse } from 'axios';
import { Node } from 'prosemirror-model';
import { Step } from 'prosemirror-transform';
import { T } from '../..';
import {
  BlobFileDownload,
  BlobResource,
  ClaimEditorDocumentResponse,
  EditorDocument,
  Operation,
  UploadDescriptionEditorOleResponse,
} from '../types';
import { DownloadDescriptionOleProps } from '../mutations/useDownloadDescriptionObject';
import { UploadDescriptionOleProps } from '../mutations/useUploadDescriptionOleObject';
import Api, { handleBlobResponse } from './base';

const API_BASE = '/v1/office-actions';

export const getOfficeActionTypes = (
  params: T.OfficeActionTypeSearch
): Promise<T.OfficeActionType[]> =>
  Api.post<T.OfficeActionType[]>(`${API_BASE}/types`, {
    ...params,
  }).then((response) => response.data);

export const getOfficeActions: T.PaginatedApiFunction<
  T.OfficeActionListItem,
  T.QueryParams
> = (params: T.QueryParams): Promise<T.OfficeActionListItem[]> =>
  Api.post<T.OfficeActionListItem[]>(`${API_BASE}/search`, {
    ...params,
  }).then((response) => response.data);

export const getOneById = (officeActionId: string): Promise<T.OfficeAction> =>
  Api.get<T.OfficeAction>(`${API_BASE}/${officeActionId}`).then(
    (response) => response.data
  );

export const postEditOfficeAction = (
  officeActionId: string
): Promise<T.OfficeActionImportProcess> =>
  Api.post<T.OfficeActionImportProcess>(
    `${API_BASE}/${officeActionId}/edit`
  ).then((response) => response.data);

export const deleteEditOfficeAction = (
  officeActionId: string
): Promise<T.OfficeActionImportProcess> =>
  Api.delete<T.OfficeActionImportProcess>(
    `${API_BASE}/${officeActionId}/edit`
  ).then((response) => response.data);

export const deleteOfficeAction = (
  officeActionId: string
): Promise<AxiosResponse> =>
  Api.delete<T.DocumentMetadata>(`${API_BASE}/${officeActionId}`).then(
    (response) => response
  );

export const getOfficeActionDocMetadata = (
  officeActionId: string
): Promise<T.DocumentMetadata> =>
  Api.get<T.DocumentMetadata>(`${API_BASE}/${officeActionId}/metadata`).then(
    (response) => response.data
  );

export const getCitedPriorArt = (
  officeActionId: string
): Promise<T.OfficeActionCitedPriorArtInfo> =>
  Api.get<T.OfficeActionCitedPriorArtInfo>(
    `${API_BASE}/${officeActionId}/cited-prior-art`
  ).then((response) => response.data);

export const getStakeholders = (
  officeActionId: string
): Promise<T.PatentApplicationStakeholder[]> =>
  Api.get<T.PatentApplicationStakeholder[]>(
    `${API_BASE}/${officeActionId}/stakeholders`
  ).then((response) => response.data);

export const getOfficeActionStatistics = (
  month: number,
  year: number
): Promise<T.OfficeActionStatistics> =>
  Api.get<T.OfficeActionStatistics>(
    `${API_BASE}/status?month=${month}&year=${year}`
  ).then((response) => response.data);

export const getOfficeActionListFilters =
  (): Promise<T.OfficeActionListFilters> =>
    Api.get<T.OfficeActionListFilters>(`${API_BASE}/search/filters`).then(
      (response) => response.data
    );

export const markAsFiled = (
  useOfficeActionId: string,
  patentRevisionId: string
): Promise<void> =>
  Api.post(`${API_BASE}/${useOfficeActionId}/mark-as-filed`, null, {
    params: { patentRevisionId },
  }).then((response) => response.data);

export const reopen = (useOfficeActionId: string): Promise<void> =>
  Api.post(`${API_BASE}/${useOfficeActionId}/re-open`).then(
    (response) => response.data
  );

export const getOfficeActionReviewResults = (
  processId: string
): Promise<T.OfficeActionUploadReview> =>
  Api.get<T.OfficeActionUploadReview>(
    `${API_BASE}/imports/${processId}/review`
  ).then((response) => response.data);

export const generateOfficeActionSummary = (
  processId: string
): Promise<string> =>
  Api.post<string>(`${API_BASE}/imports/${processId}/summary`).then(
    (response) => response.data
  );

export const deleteUserProcess = (processId: string): Promise<boolean> =>
  Api.delete<boolean>(`${API_BASE}/imports/${processId}`).then(
    (response) => response.status === 200
  );

export const restartUserProcess = (
  processId: string,
  override: T.OfficeActionUploadOverride
): Promise<T.OfficeActionImportProcess> =>
  Api.post<T.OfficeActionImportProcess>(`${API_BASE}/imports/${processId}`, {
    ...override,
  }).then((response) => response.data);

export const uploadOfficeActions = (
  formData: FormData,
  options?: {
    languageDetectionOverride?: T.Language | null;
  },
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<T.OfficeActionImportProcess> => {
  let params = {};

  if (options?.languageDetectionOverride) {
    params = { languageDetectionOverride: options.languageDetectionOverride };
  }
  return Api.post<T.OfficeActionImportProcess>(
    `${API_BASE}/imports`,
    formData,
    {
      onUploadProgress,
      params,
    }
  ).then((response) => response.data);
};

export const getHasClaimsOnFileInOfficeActionUpload = (
  processId: string
): Promise<boolean> =>
  Api.get<boolean>(`${API_BASE}/imports/${processId}/has-claims-on-file`).then(
    (response) => response.data
  );

export const createUploadProcess = (): Promise<T.OfficeActionImportProcess> =>
  Api.post<T.OfficeActionImportProcess>(`${API_BASE}/imports`).then(
    (response) => response.data
  );

export const getUploadProcessById = (
  processId: string
): Promise<T.OfficeActionImportProcess> =>
  Api.get<T.OfficeActionImportProcess>(`${API_BASE}/imports/${processId}`).then(
    (response) => response.data
  );

export const getOaDocumentMetadata = (
  processId: string
): Promise<T.DocumentMetadata> =>
  Api.get<T.DocumentMetadata>(`${API_BASE}/imports/${processId}/metadata`).then(
    (response) => response.data
  );

export const importNonPatentPriorArtDocument = (
  officeActionProcessId: string,
  citationNumber: number,
  formData: FormData,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<T.DocumentImportProcess> =>
  Api.post<T.DocumentImportProcess>(
    `${API_BASE}/imports/${officeActionProcessId}/prior-art/${citationNumber}`,
    formData,
    {
      onUploadProgress,
    }
  ).then((response) => response.data);

export const getNonPatentPriorArtDocumentImportDetails = (processId: string) =>
  Api.get<T.DocumentImportProcess>(
    `${API_BASE}/prior-art/imports/${processId}`
  ).then((response) => response.data);

export const deleteImportedNonPatentPriorArtDocument = (
  officeActionProcessId: string,
  citationNumber: number
) =>
  Api.delete(
    `${API_BASE}/imports/${officeActionProcessId}/prior-art/${citationNumber}/document`
  ).then((response) => response.data);

export const putApproveOfficeAction = async (
  processId: string,
  parsedOfficeAction: T.OfficeActionParserOutput,
  patentApplicationUpdate: T.PatentApplicationUpdate
): Promise<T.OfficeActionImportProcess> =>
  Api.put<T.OfficeActionImportProcess>(
    `${API_BASE}/imports/${processId}/approve`,
    { officeActionCorrection: parsedOfficeAction, patentApplicationUpdate }
  ).then((response) => response.data);

export const updateOfficeActionDates = async (
  processId: string,
  officeActionDates: T.UpdateOfficeActionDates
): Promise<T.OfficeActionUploadReview> =>
  Api.put<T.OfficeActionUploadReview>(
    `${API_BASE}/imports/${processId}/office-action-dates`,
    officeActionDates
  ).then((response) => response.data);

export const updateResponseLetterSection = (
  responseLetterId: string,
  section: T.ResponseLetterSection,
  data: T.ResponseLetterSectionContent
): Promise<void> =>
  Api.post<void>(
    `${API_BASE}/response-letters/${responseLetterId}/sections/${section}`,
    { ...data }
  ).then((response) => response.data);

export const postExtendTimeLimit = (
  officeActionId: string
): Promise<T.ResponseLetter> =>
  Api.post<T.ResponseLetter>(
    `${API_BASE}/${officeActionId}/time-extension`
  ).then((response) => response.data);

export const getResponseLetter = (
  responseLetterId: string
): Promise<T.ResponseLetter> =>
  Api.get<T.ResponseLetter>(
    `${API_BASE}/response-letters/${responseLetterId}`,
    {}
  ).then((response) => response.data);

export const getLatestResponseLetterForOa = (
  officeActionId: string
): Promise<T.ResponseLetter> =>
  Api.get<T.ResponseLetter>(
    `${API_BASE}/${officeActionId}/latest-response-letter`,
    {}
  ).then((response) => response.data);

export const changeResponseLetterRevision = (
  responseLetterId: string,
  patentRevisionId: string
): Promise<void> =>
  Api.post<void>(
    `${API_BASE}/response-letters/${responseLetterId}/change-revision`,
    { patentRevisionId }
  ).then((response) => response.data);

export const initResponseLetter = (
  officeActionId: string,
  createResponseLetter?: T.CreateOfficeActionResponseLetter
): Promise<T.ResponseLetter> =>
  Api.post<T.ResponseLetter>(`${API_BASE}/${officeActionId}/response-letter`, {
    ...createResponseLetter,
  }).then((response) => response.data);

export const getResponseLetterDefaultLibraryBlocks: T.PaginatedApiFunction<
  T.TextBlock,
  T.ResponseLetterTextBlocksQueryParams
> = (data: T.ResponseLetterTextBlocksQueryParams): Promise<T.TextBlock[]> =>
  Api.post<T.TextBlock[]>(
    `${API_BASE}/response-letters/${data.responseLetterId}/text-blocks`,
    data
  ).then((response) => response.data);

export const getSharedTextBlocks: T.PaginatedApiFunction<
  T.TextBlock,
  T.TextBlocksQueryParams
> = (params?: T.TextBlocksQueryParams): Promise<T.TextBlock[]> =>
  Api.post<T.TextBlock[]>(
    `${API_BASE}/response-letter/text-blocks/shared/search`,
    {
      ...params,
    }
  ).then((response) => response.data);

export const getGroupTextBlocks: T.PaginatedApiFunction<
  T.TextBlock,
  T.TextBlocksQueryParams
> = (params?: T.TextBlocksQueryParams): Promise<T.TextBlock[]> =>
  Api.post<T.TextBlock[]>(
    `${API_BASE}/response-letter/text-blocks/group/search`,
    {
      ...params,
    }
  ).then((response) => response.data);

export const getPrivateTextBlocks: T.PaginatedApiFunction<
  T.TextBlock,
  T.TextBlocksQueryParams
> = (params?: T.TextBlocksQueryParams): Promise<T.TextBlock[]> =>
  Api.post<T.TextBlock[]>(
    `${API_BASE}/response-letter/text-blocks/private/search`,
    {
      ...params,
    }
  ).then((response) => response.data);

export const putDeleteSharedTextBlock = (
  textBlockId: string
): Promise<T.TextBlock> =>
  Api.delete<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/shared/${textBlockId}`
  ).then((response) => response.data);

export const putDeleteGroupTextBlock = (
  textBlockId: string
): Promise<T.TextBlock> =>
  Api.delete<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/group/${textBlockId}`
  ).then((response) => response.data);

export const putDeletePrivateTextBlock = (
  textBlockId: string
): Promise<T.TextBlock> =>
  Api.delete<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/private/${textBlockId}`
  ).then((response) => response.data);

export const putUpdateSharedTextBlock = (
  textBlock: T.TextBlock
): Promise<T.TextBlock> =>
  Api.put<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/shared`,
    textBlock
  ).then((response) => response.data);

export const putUpdateGroupTextBlock = (
  textBlock: T.TextBlock
): Promise<T.TextBlock> =>
  Api.put<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/group`,
    textBlock
  ).then((response) => response.data);

export const putUpdatePrivateTextBlock = (
  textBlock: T.TextBlock
): Promise<T.TextBlock> =>
  Api.put<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/private`,
    textBlock
  ).then((response) => response.data);

export const postCreateSharedTextBlock = (
  textBlock: T.NewTextBlock
): Promise<T.TextBlock> =>
  Api.post<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/shared`,
    textBlock
  ).then((response) => response.data);

export const postCreateGroupTextBlock = (
  textBlock: T.NewTextBlock
): Promise<T.TextBlock> =>
  Api.post<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/group`,
    textBlock
  ).then((response) => response.data);

export const postCreatePrivateTextBlock = (
  textBlock: T.NewTextBlock
): Promise<T.TextBlock> =>
  Api.post<T.TextBlock>(
    `${API_BASE}/response-letter/text-blocks/private`,
    textBlock
  ).then((response) => response.data);

export const revertResponseLetter = (
  responseLetterId: string
): Promise<T.ResponseLetter> =>
  Api.post<T.ResponseLetter>(
    `${API_BASE}/response-letters/${responseLetterId}/revert`
  ).then((response) => response.data);

export const getResponseLetterDocument = (
  responseLetterId: string,
  format: T.DocumentFormat
): Promise<[Blob, string]> =>
  Api.get<Blob>(
    `${API_BASE}/response-letters/${responseLetterId}/download?${qs.stringify({
      format,
    })}`,
    { responseType: 'blob' }
  ).then(handleBlobResponse);

export const getResponseLetterDocumentsAsZip = (
  responseLetterId: string,
  options: T.OfficeActionResponseLetterDownloadOptions
): Promise<[Blob, string]> =>
  Api.post<Blob>(
    `${API_BASE}/response-letters/${responseLetterId}/download`,
    options,
    { responseType: 'blob' }
  ).then(handleBlobResponse);

export const getDescriptionAmendmentsDocument = (
  officeActionId: string
): Promise<[Blob, string]> =>
  Api.get<Blob>(
    `${API_BASE}/${officeActionId}/description-amendment-letter?${qs.stringify(
      {}
    )}`,
    { responseType: 'blob' }
  ).then(handleBlobResponse);

export const getPatentRevisionCurrentlyOnFile = (
  officeActionId: string
): Promise<T.PatentRevision | null> =>
  Api.get<T.PatentRevision | null>(
    `${API_BASE}/${officeActionId}/patent-revision-currently-on-file`
  ).then((response) => {
    if (response.status === T.HttpStatusCode.NO_CONTENT) {
      return null;
    }
    return response.data;
  });

export const getEditorContent = (
  editorContentId: string | undefined
): Promise<EditorDocument | null> =>
  Api.get<EditorDocument | null>(`v1/editor/${editorContentId}`).then(
    (response) => {
      if (response.status === T.HttpStatusCode.NO_CONTENT) {
        return null;
      }
      return response.data;
    }
  );

export const getEditorClaimsContent = (
  editorContentId: string | undefined,
  options: {
    inlined?: boolean;
    includeTrackChanges?: boolean;
    includeBaseline?: boolean;
    createExampleClaimIfNoneFound?: boolean;
  } = {
    inlined: false,
    includeTrackChanges: true,
    includeBaseline: false,
    createExampleClaimIfNoneFound: false,
  }
): Promise<ClaimEditorDocumentResponse | null> =>
  Api.get<ClaimEditorDocumentResponse | null>(`v1/claims/${editorContentId}`, {
    params: {
      inlined: options.inlined,
      returnTrackChanges: options.includeTrackChanges,
      returnBaseline: options.includeBaseline,
      createExampleClaimIfNoneFound: options.createExampleClaimIfNoneFound,
    },
  }).then((response) => {
    if (response.status === T.HttpStatusCode.NO_CONTENT) {
      return null;
    }
    return response.data;
  });

export const putEditorClaimsContent = (
  editorContentId: string,
  content: EditorDocument
): Promise<ClaimEditorDocumentResponse> =>
  Api.put<ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}`,
    content
  ).then((response) => response.data);

export const combineEditorClaims = (
  editorContentId: string,
  claimNumbers: string,
  ignoreWarnings?: boolean
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/combine`,
    {
      claimNumbers,
    },
    {
      params: {
        ignoreWarnings,
      },
    }
  ).then((response) => response.data);

export const resolveEditorClaims = (
  editorContentId: string,
  claimNumbers: string,
  ignoreWarnings?: boolean
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/resolve`,
    {
      claimNumbers,
    },
    {
      params: {
        ignoreWarnings,
      },
    }
  ).then((response) => response.data);

export const deleteEditorClaims = (
  editorContentId: string,
  claimNumbers: string,
  ignoreWarnings?: boolean
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/delete`,
    {
      claimNumbers,
    },
    {
      params: {
        ignoreWarnings,
      },
    }
  ).then((response) => response.data);

export const insertEditorClaims = (
  editorContentId: string,
  newNode: Node,
  ignoreWarnings?: boolean
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/insert`,
    newNode,
    {
      params: {
        ignoreWarnings,
      },
    }
  ).then((response) => response.data);

export const splitEditorClaims = (
  editorContentId: string,
  claimNumbers: string,
  ignoreWarnings?: boolean
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/split`,
    {
      claimNumbers,
    },
    {
      params: {
        ignoreWarnings,
      },
    }
  ).then((response) => response.data);

export const undoEditorClaims = (
  editorContentId: string
): Promise<ClaimEditorDocumentResponse> =>
  Api.put<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/undo`
  ).then((response) => response.data);

export const redoEditorClaims = (
  editorContentId: string
): Promise<ClaimEditorDocumentResponse> =>
  Api.put<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/redo`
  ).then((response) => response.data);

export const rejectEditorClaims = (
  editorContentId: string,
  amendmentIds: string[]
): Promise<ClaimEditorDocumentResponse> =>
  Api.put<T.ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/reject-amendments`,
    amendmentIds
  ).then((response) => response.data);

export const patchEditorContent = (
  editorContentId: string,
  content: Operation[],
  changes: any
): Promise<Node> =>
  Api.patch<Node>(`v1/editor/${editorContentId}`, {
    patchDoc: content,
    changes,
  }).then((response) => response.data);

export const putEditorChangeset = (
  editorContentId: string,
  changeset: Step[]
): Promise<Node> =>
  Api.put<Node>(`v1/editor/${editorContentId}/steps`, changeset).then(
    (response) => response.data
  );

export const getEditorChangeset = (editorContentId: string): Promise<Step[]> =>
  Api.get<Step[]>(`v1/editor/${editorContentId}/steps`).then(
    (response) => response.data
  );

export const postUploadResource = (
  editorContentId: string,
  file: File
): Promise<BlobResource> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return Api.post<BlobResource>(
    `v1/editor/${editorContentId}/resource`,
    formData
  ).then((response) => response.data);
};

export const postUploadOleObject = (
  editorContentId: string,
  claimPartId: string,
  imageSourceId: string,
  file: FormData,
  handleUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<ClaimEditorDocumentResponse> =>
  Api.post<ClaimEditorDocumentResponse>(
    `v1/claims/${editorContentId}/ole/${claimPartId}/${imageSourceId}`,
    file,
    {
      onUploadProgress: (progressEvent) => {
        handleUploadProgress?.(progressEvent);
      },
    }
  ).then((response) => response.data);

export const getClaimOle = (
  editorContentId: string,
  claimPartId: string,
  imageSourceId: string,
  handleDownloadProgress?: (progressEvent: ProgressEvent) => void
): Promise<BlobFileDownload> =>
  Api.get<Blob>(
    `v1/claims/${editorContentId}/ole/${claimPartId}/${imageSourceId}`,
    {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        handleDownloadProgress?.(progressEvent);
      },
    }
  ).then(handleBlobResponse);

export const getDescriptionOle = ({
  editorDocumentId,
  revisionId,
  imageSourceId,
  sectionId,
  contentNodeId,
  handleDownloadProgress,
}: DownloadDescriptionOleProps): Promise<BlobFileDownload> =>
  Api.get<Blob>(
    `v1/editor/${editorDocumentId}/ole/${revisionId}/${sectionId}/${contentNodeId}/${imageSourceId}`,
    {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        handleDownloadProgress?.(progressEvent);
      },
    }
  ).then(handleBlobResponse);

export const postUploadDescriptionOleObject = ({
  editorDocumentId,
  revisionId,
  imageSourceId,
  sectionId,
  contentNodeId,
  file,
  handleUploadProgress,
}: UploadDescriptionOleProps): Promise<UploadDescriptionEditorOleResponse> =>
  Api.post<UploadDescriptionEditorOleResponse>(
    `v1/editor/${editorDocumentId}/ole/${revisionId}/${sectionId}/${contentNodeId}/${imageSourceId}`,
    file,
    {
      onUploadProgress: (progressEvent) => {
        handleUploadProgress?.(progressEvent);
      },
    }
  ).then((response) => response.data);

export const postUploadClaimEditorResource = (
  editorContentId: string,
  file: File
): Promise<BlobResource> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return Api.post<BlobResource>(
    `v1/claims/${editorContentId}/resource`,
    formData
  ).then((response) => response.data);
};

export const postUploadEmbeddedObject = (
  editorContentId: string,
  file: FormData
): Promise<Node> =>
  Api.post<Node>(`v1/claims/${editorContentId}/ole`, file).then(
    (response) => response.data
  );
