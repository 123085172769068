import { DEFAULT_PAGE_SIZE } from '../constants';
import { randomDate, randomString } from '../utils';
import { T } from '../../index';
import { generateBaseDto } from './mockDataGenerators';

export function generateFtoCase(overrides?: T.FtoCase): T.FtoCase {
  return {
    ...generateBaseDto(),
    internalReference: randomString(),
    title: randomString(),
    description: randomString(),
    resolvedPriorArtCitationCount: 0,
    criticalCitationCount: 0,
    priorArtCitationCount: 0,
    dueDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    participants: [],
    isArchived: false,
    tsdNodes: [],
    ...overrides,
  };
}

export const generateCases = (size = DEFAULT_PAGE_SIZE): T.FtoCase[] => [
  ...Array.from(Array(size)).map(() => generateFtoCase()),
];
