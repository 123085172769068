/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import Downshift, {
  DownshiftState,
  GetItemPropsOptions,
  StateChangeOptions,
} from 'downshift';
import styled, { css } from 'styled-components';
import { gradient, hover } from '../../utils';
import { AutocompleteItem } from '../../types';
import Portal from '../Portal';
import { Void as TextArea } from './TextArea';

interface Props<T> {
  items: AutocompleteItem<T>[];
  value?: T | T[];
  inputValue?: string;
  defaultInputValue?: string;
  onBlurInput?: (e: React.FocusEvent<HTMLInputElement>) => void;
  stateReducer?: (
    state: DownshiftState<AutocompleteItem<T>>,
    changes: StateChangeOptions<AutocompleteItem<T>>
  ) => Partial<StateChangeOptions<AutocompleteItem<T>>>;
  onInputChange?: (value: string) => void;
  renderDropdown?: (
    items: AutocompleteItem<T>[],
    getItemProps: (options: GetItemPropsOptions<AutocompleteItem<T>>) => any,
    highlightedIndex: number | null
  ) => JSX.Element;
  onChange?: (value: AutocompleteItem<T> | null) => void;
  onClick?: () => void;
  testId?: string;
}

const Autocomplete = <T,>({
  items,
  renderDropdown,
  onInputChange,
  inputValue,
  onBlurInput,
  stateReducer,
  defaultInputValue,
  onChange,
  onClick,
  testId = 'autocomplete',
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>();
  return (
    <Downshift
      inputValue={inputValue}
      onInputValueChange={(value) => onInputChange?.(value)}
      stateReducer={stateReducer}
      onChange={(selection: AutocompleteItem<T> | null) => {
        onChange?.(selection);
      }}
      initialInputValue={defaultInputValue}
      itemToString={(item) => (item ? item.label : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        inputValue: iv,
        highlightedIndex,
        selectedItem,
        isOpen,
      }) => (
        <Container {...getRootProps()}>
          <TextArea
            {...getInputProps()}
            data-testid={`${testId}-textarea`}
            onBlur={(e: any) => {
              getInputProps?.()?.onBlur?.(e);
              onBlurInput?.(e);
            }}
            ref={setInputRef}
            onKeyDown={(e: any) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
              }
              getInputProps?.()?.onKeyDown?.(e);
            }}
            onClick={onClick}
          />
          <Portal id="dropdown-autocomplete-portal">
            {isOpen && (
              <StyledDropdown
                {...getMenuProps()}
                inputPos={inputRef?.getBoundingClientRect()}
              >
                {renderDropdown ? (
                  renderDropdown(items, getItemProps, highlightedIndex)
                ) : (
                  <StyledDropdownContainer>
                    {items
                      .filter((item) => !iv || item.label.includes(iv))
                      .map((item, index) => (
                        <StyledListItem
                          key={`${item.value}${index}`}
                          {...getItemProps({
                            key: `${item.value}${index}`,
                            item,
                            index,
                          })}
                          $highlighted={
                            highlightedIndex === index ||
                            selectedItem?.value === item.value
                          }
                        >
                          {item.label}
                        </StyledListItem>
                      ))}
                  </StyledDropdownContainer>
                )}
              </StyledDropdown>
            )}
          </Portal>
        </Container>
      )}
    </Downshift>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledDropdownContainer = styled.div`
  overflow-y: auto;
  max-height: 400px;
  min-width: 300px;
  padding: 24px 12px;
`;

const StyledDropdown = styled.div<{ inputPos?: DOMRect }>`
  position: absolute;
  top: ${(props) => props.inputPos?.bottom}px;
  left: ${(props) => props.inputPos?.left}px;
  border-radius: 10px;
  display: flex;
  z-index: 10;
  flex-direction: column;
  max-height: 400px;
  ${gradient()};
`;

const StyledListItem = styled.div<{ $highlighted: boolean }>`
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  ${(props) =>
    props.$highlighted &&
    css`
      background-color: ${props.theme.colors.primary200};
    `};
  ${hover()};
`;

export default Autocomplete;
