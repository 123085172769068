import React from 'react';
import styled from 'styled-components';
import useTranslation from 'translations';
import { Utils } from '@ipos/shared';

const { truncate } = Utils;

const SynonymsListHeader: React.FC = () => {
  const t = useTranslation();
  return (
    <Outer>
      <Col flex={1.5} title={t(`QUERY.TRUNCATION`)}>
        <Text>{t(`QUERY.TRUNCATION`)}</Text>
      </Col>
      <Col flex={4.5} />
      <Col flex={1.5} title={t(`QUERY.TRUNCATION`)}>
        <Text>{t(`QUERY.TRUNCATION`)}</Text>
      </Col>
      <Col title={t(`QUERY.TITLE`)}>
        <Text>{t(`QUERY.TITLE`)}</Text>
      </Col>
      <Divider />
      <Col title={t(`QUERY.ABSTRACT`)}>
        <Text>{t(`QUERY.ABSTRACT`)}</Text>
      </Col>
      <Divider />
      <Col title={t(`QUERY.DESCRIPTION`)}>
        <Text>{t(`QUERY.DESCRIPTION`)}</Text>
      </Col>
      <Divider />
      <Col title={t(`QUERY.CLAIMS`)}>
        <Text>{t(`QUERY.CLAIMS`)}</Text>
      </Col>
    </Outer>
  );
};

export default SynonymsListHeader;

const Outer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 30px;
  justify-content: space-between;
`;

export const Col = styled.div<{ flex?: number; bordered?: boolean }>`
  display: flex;
  flex: ${(props) => props.flex ?? 1};
  padding: 0 5px;
  overflow: hidden;
  justify-content: center;
`;

const Text = styled.span`
  text-align: center;
  ${truncate('100%')};
`;

const Divider = styled.div`
  height: 15px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.primary300};
`;
