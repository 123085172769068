/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, Transaction } from 'prosemirror-state';

import { HEADING } from '../../schema/nodes/nodeNames';
import toggleHeading from '../util/toggleHeading';
import { findParentNodeOfType } from '../../util';
import noop from '../util/noop';
import UICommand from './UICommand';

class HeadingCommand extends UICommand {
  _level: number;

  constructor(level: number) {
    super();
    this._level = level;
  }

  isActive = (state: EditorState): boolean => {
    const result = this._findHeading(state);
    return !!(
      result &&
      result.node &&
      result.node.attrs &&
      result.node.attrs.level === this._level
    );
  };

  isEnabled = (state: EditorState): boolean => {
    const list = state.schema.nodes['list'];
    const fn = list ? findParentNodeOfType(list) : noop;
    const listNode = fn(state.selection);
    return !listNode;
  };

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, selection } = state;
    const tr = toggleHeading(
      state.tr.setSelection(selection),
      schema,
      this._level
    );
    if (tr.docChanged) {
      dispatch && dispatch(tr);
      return true;
    } else {
      return false;
    }
  };

  _findHeading(state: EditorState): any {
    const heading = state.schema.nodes[HEADING];
    const fn = heading ? findParentNodeOfType(heading) : noop;
    return fn(state.selection);
  }
}

export default HeadingCommand;
