import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Props } from './BaseButton';

interface ButtonProps extends Props {
  icon?: React.ElementType;
  buttonStyle?: CSSProperties;
}

const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { buttonStyle, active, icon: Icon, disabled, testId, onClick, ...rest },
    ref
  ) => (
    <IconWrapper
      active={active}
      disabled={disabled}
      data-testid={testId}
      onClick={onClick}
      ref={ref}
      style={buttonStyle}
    >
      {Icon ? <Icon {...rest} /> : <i {...rest} />}
    </IconWrapper>
  )
);

const IconWrapper = styled.button<{ active?: boolean }>`
  width: 32px;
  border-radius: 50%;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: center;
  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.white10};
    `};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.47;
      pointer-events: none;
    `};
  > :first-child {
    font-size: 22px;
    color: ${(props) => props.theme.colors.white40};
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background: ${(props) => props.theme.colors.white10};
  }
`;

export default IconButton;
