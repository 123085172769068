import React from 'react';
import styled, { css, CSSObject } from 'styled-components';

interface Props {
  /** A required prop specifying the exact position of Grid.
   * It expects the following structure:
   * [starts at row, rows to span, starts at column, columns to span] */
  position: [number, number, number, number];
  componentRef?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
  style?: CSSObject;
  testId?: string;
}

const GridItem: React.FC<Props> = ({
  position,
  componentRef,
  children,
  style,
  testId,
}) => (
  <Outer
    data-testid={testId}
    position={position}
    ref={componentRef}
    styleOverride={style}
  >
    {children}
  </Outer>
);

const Outer = styled.div<{
  position: [number, number, number, number];
  styleOverride?: CSSObject;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  grid-row-start: ${(props) => props.position[0]};
  grid-row-end: span ${(props) => props.position[1]};
  grid-column-start: ${(props) => props.position[2]};
  grid-column-end: span ${(props) => props.position[3]};
  ${(props) =>
    css`
      ${props.styleOverride as CSSObject}
    `}
`;

export default GridItem;
