import { PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Node, Schema } from 'prosemirror-model';

import {
  BlobResource,
  ImagePluginSettings,
  ImagePluginState,
  InsertImagePlaceholder,
  RemoveImagePlaceholder,
} from '../../../../types';
import { iposApi } from '../../../../api/base';
import { canInsert, findParentNode } from '../../util';
import {
  DEFAULT_IMAGE_MARGIN,
  DEFAULT_MAX_SIZE,
  DEFAULT_MIN_SIZE,
  MM_TO_PX_CONVERSION_FACTOR,
} from './defaults';

export const dataURIToFile = (dataURI: string, name: string) => {
  const arr = dataURI.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const extension = mime?.split('/')[1];
  const filename = `${name}.${extension}`;

  return new File([u8arr], filename, { type: mime });
};

export const imagePluginKey = new PluginKey<ImagePluginState>('imagePlugin');

export const startImageUpload = (
  view: EditorView,
  file: File,
  alt: string,
  pluginSettings: ImagePluginSettings,
  schema: Schema,
  uploadFunction: (id: string, file: File) => Promise<{ uri: string }>,
  editorDocumentId?: string,
  pos?: number
) => {
  if (!editorDocumentId) {
    return false;
  }
  // A fresh object to act as the ID for this upload
  const id = {};
  // Replace the selection with a placeholder
  const { tr } = view.state;
  if (!tr.selection.empty && !pos) {
    tr.deleteSelection();
  }
  const imageMeta: InsertImagePlaceholder = {
    type: 'add',
    pos: pos || tr.selection.from,
    id,
  };

  tr.setMeta(imagePluginKey, imageMeta);

  view.dispatch(tr);
  uploadFunction(editorDocumentId, file).then(
    (response) => {
      const placholderPos = pluginSettings.findPlaceholder(view.state, id);
      // If the content around the placeholder has been deleted, drop
      // the image
      if (placholderPos == null) {
        return;
      }
      // Otherwise, insert it at the placeholder's position, and remove
      // the placeholder
      const removeMeta: RemoveImagePlaceholder = { type: 'remove', id };
      view.dispatch(
        view.state.tr
          .insert(
            placholderPos,
            schema.nodes.image.create({ src: response.uri, alt })
          )
          .setMeta(imagePluginKey, removeMeta)
          .setMeta('resourceInsert', true)
      );
    },
    () => {
      // On failure, just clean up the placeholder
      view.dispatch(tr.setMeta(imagePluginKey, { remove: { id } }));
    }
  );
};

export const clamp = (min: number, value: number, max: number) =>
  Math.max(Math.min(max, value), min);

export const resolveURL = (src: string | null): string | null =>
  src?.indexOf(`${iposApi}/v1`) === -1 ? `${iposApi}/v1/${src}` : src;

export const findParentNodeIdBeforeSection = (
  doc: Node,
  targetNodePos: number
): string | null => {
  const resolvedPos = doc.resolve(targetNodePos);
  let currentDepth = resolvedPos.depth;

  while (currentDepth > 0) {
    const parentNode = resolvedPos.node(currentDepth);
    const grandparentNode = resolvedPos.node(currentDepth - 1);

    if (grandparentNode && grandparentNode.type.name === 'section') {
      return parentNode.attrs?.nodeId || null;
    }
    currentDepth--;
  }

  return null;
};

export const getNodeAttrValue = (
  nodeName: string,
  attributeName: string,
  editorView: EditorView
): string => {
  const nearestNode = findParentNode(
    (node) => node.type === editorView.state.schema.nodes[nodeName]
  )(editorView.state.selection);
  return nearestNode?.node.attrs[attributeName];
};

export const getMaxResizeWidth = (el: HTMLElement): number => {
  // Ideally, the image should bot be wider then its containing element.
  let node: HTMLElement | null = el.parentElement;
  while (node && !node.offsetParent) {
    node = node.parentElement;
  }
  if (
    node &&
    node.offsetParent &&
    node.offsetParent instanceof HTMLElement &&
    node.offsetParent.offsetWidth &&
    node.offsetParent.offsetWidth > 0
  ) {
    const { offsetParent } = node;
    const style = el.ownerDocument?.defaultView?.getComputedStyle(offsetParent);
    let width = offsetParent.clientWidth - DEFAULT_IMAGE_MARGIN * 2;
    if (style?.boxSizing === 'border-box') {
      const pl = parseInt(style.paddingLeft, 10);
      const pr = parseInt(style.paddingRight, 10);
      width -= pl + pr;
    }
    return Math.max(width, DEFAULT_MIN_SIZE);
  }
  // Let the image resize freely.
  return DEFAULT_MAX_SIZE;
};

const MIME_TO_EXTENSION: { [key: string]: string } = {
  'application/pdf': 'pdf',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
};

export const getFileExtension = (mimeType: string): string =>
  MIME_TO_EXTENSION[mimeType] || 'bin';

export const uploadBlobAsFile = async (
  blob: Blob,
  editorDocumentId: string,
  uploadFunction: (id: string, file: File) => Promise<BlobResource>
): Promise<string | null> => {
  try {
    const extension = getFileExtension(blob.type);
    const fileName = `doc.${extension}`;
    const file = new File([blob], fileName, { type: blob.type });
    const response = await uploadFunction(editorDocumentId, file);
    return response.uri;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export const fetchAndUploadFile = async (
  objectSrc: string,
  editorDocumentId: string,
  uploadFunction: (id: string, file: File) => Promise<BlobResource>
): Promise<string | null> => {
  try {
    const response = await fetch(objectSrc);
    if (!response.ok) {
      throw new Error('Failed to fetch the file');
    }

    const blob = await response.blob();
    return uploadBlobAsFile(blob, editorDocumentId, uploadFunction);
  } catch (error) {
    console.error('Error fetching and uploading the file:', error);
    return null;
  }
};

export const insertImageNode = ({
  view,
  schema,
  tr,
  width,
  aspectRatio,
  src,
  nodeType,
  objectSrc,
}: {
  view: EditorView;
  schema: Schema;
  tr: Transaction;
  width: string | number;
  aspectRatio: string | number;
  src: string;
  nodeType: string;
  objectSrc?: string;
}): void => {
  const nodeData = {
    objectSrc,
    width,
    aspectRatio,
    src,
    isPasted: true,
  };

  const newNode = schema.nodes[nodeType].create(nodeData);

  view.dispatch(
    tr.insert(tr.selection.from, newNode).setMeta('resourceInsert', true)
  );
};

interface handleClipboardItemsDescriptionProps {
  clipboardItems: DataTransferItemList;
  view: EditorView;
  schema: Schema;
  event: ClipboardEvent;
  uploadFunction: (id: string, file: File) => Promise<BlobResource>;
  pluginSettings?: ImagePluginSettings;
  editorDocumentId?: string;
}

export const handleClipboardItemsDescription = ({
  clipboardItems,
  view,
  schema,
  event,
  uploadFunction,
  pluginSettings,
  editorDocumentId,
}: handleClipboardItemsDescriptionProps): boolean => {
  const items = Array.from(clipboardItems).filter(
    (item) => item.type.indexOf('image') !== -1
  );
  if (items.length === 0) {
    return false;
  }

  const item = items[0];
  const file = item.getAsFile();
  if (!file) {
    return false;
  }
  if (event?.clipboardData?.types.includes('text/rtf')) {
    return false;
  }

  if (!canInsert(view.state, schema.nodes.image)) {
    return false;
  }
  if (!pluginSettings || !editorDocumentId) {
    return false;
  }

  getImageDimensions(file).then(({ width, height }) => {
    const widthMm = width / MM_TO_PX_CONVERSION_FACTOR;
    const heightMm = height / MM_TO_PX_CONVERSION_FACTOR;
    const aspectRatio = widthMm / heightMm;
    uploadFunction(editorDocumentId, file).then((response) => {
      insertImageNode({
        view,
        schema,
        tr: view.state.tr,
        width: widthMm,
        aspectRatio,
        src: response.uri,
        nodeType: schema.nodes.image.name,
      });
    });
  });

  event.preventDefault();
  return true;
};

const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;
      URL.revokeObjectURL(img.src);
      resolve({ width, height });
    };
    img.onerror = reject;
  });

interface handleClipboardItemsClaimsProps {
  uploadFunction: (id: string, file: File) => Promise<BlobResource>;
  clipboardItems: DataTransferItemList;
  view: EditorView;
  schema: Schema;
  event: ClipboardEvent;
  editorDocumentId?: string;
}
export const handleClipboardItemsClaims = ({
  uploadFunction,
  clipboardItems,
  view,
  schema,
  event,
  editorDocumentId,
}: handleClipboardItemsClaimsProps): boolean => {
  const items = Array.from(clipboardItems).filter(
    (item) => item.type.indexOf('image') !== -1
  );
  if (items.length === 0) {
    return false;
  }

  const item = items[0];
  const file = item.getAsFile();
  if (!file) {
    return false;
  }

  if (event?.clipboardData?.types.includes('text/rtf')) {
    return false;
  }
  if (!canInsert(view.state, schema.nodes.image)) {
    return false;
  }
  if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
    console.error('Unsupported file type:', file.type);
    return false;
  }
  if (!editorDocumentId) {
    return false;
  }

  getImageDimensions(file)
    .then(({ width, height }) => {
      const widthMm = width / MM_TO_PX_CONVERSION_FACTOR;
      const heightMm = height / MM_TO_PX_CONVERSION_FACTOR;
      const aspectRatio = widthMm / heightMm;
      uploadFunction(editorDocumentId, file).then((response) => {
        insertImageNode({
          view,
          schema,
          tr: view.state.tr,
          width: widthMm,
          aspectRatio,
          src: response.uri,
          nodeType: schema.nodes.image.name,
        });
      });
    })
    .catch((error) => {
      console.error('Error loading image dimensions:', error);
    });
  event.preventDefault();
  return true;
};

export const isElementXPercentInViewport = function (
  el: HTMLElement,
  percentVisible: number
) {
  const rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
      percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  );
};
