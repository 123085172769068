import { MarkSpec } from 'prosemirror-model';

const comment: MarkSpec = {
  attrs: {
    comments: { default: [] },
  },
  inclusive: false,
  parseDOM: [
    {
      tag: 'span.comment',
    },
  ],
  toDOM: () => ['span', { class: 'comment' }, 0],
};

export default comment;
