import qs from 'querystring';
import { AxiosResponse } from 'axios';
import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/patent-applications';

// TODO: Fix that for real usage. It is just a workaround to generate draft patent application with hardcoded data.
export const createPatentApplication = (payload: {
  title: string;
  patentOffice: T.PatentOffice;
}) =>
  Api.post<T.PatentDraft>(`${API_BASE}`, payload, {}).then(
    (response) => response.data
  );

export const updatePatentDraft = (
  id: string,
  payload: T.UpdatePatentDraft
): Promise<T.PatentDraft> =>
  Api.put<T.PatentDraft>(`${API_BASE}/${id}`, payload).then(
    (response) => response.data
  );

export const getPatentDrafts: T.PaginatedApiFunction<
  T.PatentDraftListItem,
  T.QueryParams
> = (params: T.QueryParams): Promise<T.PatentDraftListItem[]> =>
  Api.post<T.PatentDraftListItem[]>(`${API_BASE}/search`, {
    ...params,
  }).then((response) => response.data);

export const getPatentDraftListFilters =
  (): Promise<T.PatentDraftListFilters> =>
    Api.get<T.PatentDraftListFilters>(`${API_BASE}/search/filters`).then(
      (response) => response.data
    );

export const getPatentDraft = (id: string): Promise<T.PatentDraft> =>
  Api.get<T.PatentDraft>(`${API_BASE}/${id}/bibliographic-data`).then(
    (response) => response.data
  );

export const deletePatentDraft = (
  patentDraftId: string
): Promise<AxiosResponse> =>
  Api.delete<T.DocumentMetadata>(`${API_BASE}/${patentDraftId}`).then(
    (response) => response
  );

export const deletePatentDocument = (
  id: string,
  force?: boolean
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/${id}/documents?${qs.stringify({
      force: force !== undefined ? force : false,
    })}`
  ).then((response) => response.data);

export const updatePatentDocuments = (
  patentApplicationId: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<T.PatentRevisionImportProcess> =>
  Api.put<T.PatentRevisionImportProcess>(
    `${API_BASE}/imports/${patentApplicationId}`,
    formData,
    {
      onUploadProgress,
    }
  ).then((response) => response.data);
