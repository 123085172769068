/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { Node } from 'prosemirror-model';
import { Api } from '../..';

type Variables = {
  editorDocumentId: string;
  patches: any;
  changes: any;
};

function usePatchEditorContent(
  onSuccess?: (response: Node) => void,
  onError?: (axiosError: AxiosError) => void
): UseMutationResult<Node, AxiosError, Variables> {
  return useMutation<Node, AxiosError, Variables>(
    ({ editorDocumentId, patches, changes }) =>
      Api.oa.patchEditorContent(editorDocumentId, patches, changes),
    {
      onSuccess,
      onError,
    }
  );
}

export default usePatchEditorContent;
