import { MarkSpec } from 'prosemirror-model';

const TextSelectionMarkSpec: MarkSpec = {
  attrs: {
    id: { default: '' },
  },
  inline: true,
  group: 'inline',
  parseDOM: [
    {
      tag: 'text-selection',
    },
  ],

  toDOM() {
    return ['text-selection', { class: 'text-selection' }, 0];
  },
};

export default TextSelectionMarkSpec;
