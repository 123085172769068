import React, { useState } from 'react';
import { Modal, Radio, Input } from 'antd';
import styled from 'styled-components';
import { PrimaryButton, VoidButton } from '../../Button';
import { Language } from '../../../types';
import useTranslation from '../../../translations';
import RowItem from './RowItem';
import LanguageItem from './LanguageItem';

enum TranslationRangeType {
  All = 'All',
  Current = 'Current',
  Range = 'Range',
}

interface Props {
  authenticLanguage: Language;
  targetLanguage: Language;
  onTranslate: (pageNumbers?: string) => void;
  onClose: () => void;
}

const TranslationRangeSelectModal: React.FC<Props> = ({
  authenticLanguage,
  targetLanguage,
  onTranslate,
  onClose,
}) => {
  const t = useTranslation();

  const [rangeType, setRangeType] = useState<TranslationRangeType>(
    TranslationRangeType.All
  );

  const [pageNumbers, setPageNumbers] = useState('');

  const onClickTranslate = () => {
    if (rangeType === TranslationRangeType.Range) {
      onTranslate(pageNumbers);
    } else {
      onTranslate();
    }
  };

  return (
    <Modal
      title={t('TRANSLATION_RANGE.TITLE')}
      open
      onCancel={onClose}
      footer={null}
      width={600}
      destroyOnClose
    >
      <Main>
        <RowItem label={t('TRANSLATION_RANGE.AUTHENTIC')}>
          <LanguageItem language={authenticLanguage} />
        </RowItem>
        <RowItem label={t('TRANSLATION_RANGE.TRANSLATE_TO')}>
          <LanguageItem language={targetLanguage} />
        </RowItem>
        <RowItem label={t('TRANSLATION_RANGE.RANGE')}>
          <Range>
            <Radio.Group
              onChange={(e) => {
                setRangeType(e.target.value);
              }}
              value={rangeType}
            >
              <Radio value={TranslationRangeType.All}>
                {t('TRANSLATION_RANGE.ALL')}
              </Radio>
              <Radio value={TranslationRangeType.Range}>
                <PageInput>
                  <span>{t('TRANSLATION_RANGE.PAGES')}</span>
                  <Input
                    disabled={rangeType !== TranslationRangeType.Range}
                    value={pageNumbers}
                    placeholder="i.e. 3, 6-9"
                    onChange={(e) => setPageNumbers(e.target.value)}
                  />
                </PageInput>
              </Radio>
            </Radio.Group>
          </Range>
        </RowItem>
      </Main>
      <Footer>
        <VoidButton onClick={onClose}>{t('ACTION.CANCEL')}</VoidButton>
        <PrimaryButton
          onClick={onClickTranslate}
          disabled={
            rangeType === TranslationRangeType.Range &&
            pageNumbers.trim() === ''
          }
        >
          {t('ACTION.TRANSLATE')}
        </PrimaryButton>
      </Footer>
    </Modal>
  );
};

export default TranslationRangeSelectModal;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;
`;

const Range = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const PageInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
