import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { setCellAttr } from 'prosemirror-tables';
import { SyntheticEvent } from 'react';
import UICommand from '../UICommand';

const setCellBorderBlack = setCellAttr('borderColor', '#000000');

class TableBorderColorCommand extends UICommand {
  _popUp = null;

  shouldRespondToUIEvent = (e: SyntheticEvent | MouseEvent): boolean =>
    e.type === UICommand.EventType.MOUSEENTER;

  isEnabled = (state: EditorState): boolean => setCellBorderBlack(state);

  executeWithUserInput = (
    state: EditorState,
    color: string,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView
  ): boolean => {
    if (dispatch && color !== undefined) {
      const cmd = setCellAttr('borderColor', color);
      cmd(state, dispatch, view);
      return true;
    }
    return false;
  };
}

export default TableBorderColorCommand;
