import React from 'react';
import styled from 'styled-components';
import { DocumentFormat } from '../../../types';
import FormatSelectionItem from './FormatSelectionItem';

interface Props {
  checkedFormats: DocumentFormat[];
  indeterminateFormats?: DocumentFormat[];
  showLabels?: boolean;
  onFormatCheck: (format: DocumentFormat, checked: boolean) => void;
}

const FormatSelection: React.FC<Props> = ({
  checkedFormats,
  indeterminateFormats,
  showLabels,
  onFormatCheck,
}) => (
  <Outer>
    <FormatSelectionItem
      format={DocumentFormat.DOCX}
      checked={checkedFormats.includes(DocumentFormat.DOCX)}
      indeterminate={indeterminateFormats?.includes(DocumentFormat.DOCX)}
      showLabel={showLabels}
      onCheck={onFormatCheck}
    />
    <FormatSelectionItem
      format={DocumentFormat.PDF}
      checked={checkedFormats.includes(DocumentFormat.PDF)}
      indeterminate={indeterminateFormats?.includes(DocumentFormat.PDF)}
      showLabel={showLabels}
      onCheck={onFormatCheck}
    />
  </Outer>
);

export default FormatSelection;

const Outer = styled.div`
  display: flex;
  gap: 30px;
`;
