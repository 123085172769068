import produce from 'immer';
import { T } from '@ipos/shared';
import {
  QuerySegment,
  QuerySegments,
  QueryClassesOption,
  QuerySynonymOption,
  Action,
  SelectChangePayload,
  OptionChangePayload,
  QueryBuilderAction,
  SwitchModePayload,
  QueryStringChangePayload,
  QueryStringType,
} from 'types';

const selectChange = (
  state: T.TsdQuery,
  payload: SelectChangePayload
): T.TsdQuery => {
  const { id, name, segment } = payload;

  return produce(state, (draft) => {
    const seg = draft[segment];
    const index = seg.findIndex((s: QuerySegments) => s.id === id);

    if (index !== -1) {
      seg.splice(index, 1);
    } else {
      switch (segment) {
        case QuerySegment.Classes:
          draft.classes.push({
            id,
            code: name,
            isTruncated: false,
          });
          break;
        case QuerySegment.Synonyms:
          draft.synonyms.push({
            id,
            value: name,
            searchInTitle: true,
            searchInAbstract: true,
            searchInClaims: true,
          });
          break;
        default:
          break;
      }
    }
  });
};

const modeChange = (
  state: T.TsdQuery,
  payload: SwitchModePayload
): T.TsdQuery =>
  produce(state, (draft) => {
    draft.isInExpertMode = payload.isInExpertMode;

    if (!draft.isInExpertMode) {
      draft.googlePatentsQuery = undefined;
      draft.patBaseQuery = undefined;
    }
  });

const optionChange = (
  state: T.TsdQuery,
  payload: OptionChangePayload
): T.TsdQuery =>
  produce(state, (draft) => {
    const { id, option, segment, checked } = payload;
    switch (segment) {
      case QuerySegment.Classes:
        const ipcClass = draft.classes.find((c) => c.id === id);
        if (ipcClass) {
          ipcClass[option as QueryClassesOption] = checked;
        }
        break;
      case QuerySegment.Synonyms:
        const synonym = draft.synonyms.find((c) => c.id === id);
        if (synonym) {
          synonym[option as QuerySynonymOption] = checked;
        }
        break;
      default:
        break;
    }
  });

const queryStringChange = (
  state: T.TsdQuery,
  payload: QueryStringChangePayload
): T.TsdQuery =>
  produce(state, (draft) => {
    const { value, type } = payload;
    switch (type) {
      case QueryStringType.GooglePatents:
        draft.googlePatentsQuery = value;
        break;
      case QueryStringType.PatBase:
        draft.patBaseQuery = value;
        break;
      default:
        break;
    }
  });

export const queryBuilderReducer = (
  state: T.TsdQuery | undefined,
  action: QueryBuilderAction
): T.TsdQuery | undefined => {
  let nextState: T.TsdQuery | undefined;
  switch (action.type) {
    case Action.InitQueryRequest:
    case Action.UpdateQueryRequest:
      nextState = action.payload;
      break;
    case Action.SelectChangeRequest:
      nextState = selectChange(state as T.TsdQuery, action.payload);
      break;
    case Action.OptionChangeRequest:
      nextState = optionChange(state as T.TsdQuery, action.payload);
      break;
    case Action.SwitchModeRequest:
      nextState = modeChange(state as T.TsdQuery, action.payload);
      break;
    case Action.QueryStringChangeRequest:
      nextState = queryStringChange(state as T.TsdQuery, action.payload);
      break;
    default:
      nextState = state;
      break;
  }

  return nextState;
};
