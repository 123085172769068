import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { Icon } from '../../../Icon';
import useTranslation from '../../../../translations';
import { Spinner } from '../../../Spinner';

interface Props {
  isLoading: boolean;
  value: string;
  onChange: (value: string) => void;
}

const DocumentSearchInput: React.FC<Props> = ({
  isLoading,
  value,
  onChange,
}) => {
  const t = useTranslation();

  let suffix;
  switch (true) {
    case isLoading:
      suffix = <Spinner size="small" style={{ marginRight: 5 }} />;
      break;
    case value !== '':
      suffix = (
        <Icon
          className="icn-clear clear-icon"
          size={18}
          onClick={() => onChange('')}
        />
      );
      break;
    default:
      break;
  }

  return (
    <StyledInput
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={t('SINGLE_DOC_SEARCH.INPUT_PLACEHOLDER')}
      prefix={<Icon className="icn-search" size={20} />}
      autoFocus
      suffix={suffix}
    />
  );
};

export default DocumentSearchInput;

const StyledInput = styled(Input)`
  flex: 1;
  height: 32px;
  border: none;
  margin: 0;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
  background-color: ${(props) => props.theme.colors.primary200_20};
  &.ant-input-affix-wrapper {
    box-shadow: none;
    padding-right: 6px;
    border: 2px solid transparent;
    border-right-width: 2px !important;
    &:focus-within,
    &:hover {
      border-color: ${(props) => props.theme.colors.blue600};
    }
  }
  .clear-icon {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary100};
    &:hover {
      color: ${(props) => props.theme.colors.black60};
    }
  }
  input {
    border: none;
    color: ${(props) => props.theme.colors.black};
    background-color: transparent;
    &::placeholder {
      color: ${(props) => props.theme.colors.primary100};
    }
  }
  i {
    color: ${(props) => props.theme.colors.black70};
    margin-right: 5px;
  }
`;
