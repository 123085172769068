import { object, string } from 'yup';

const signupSchema = object().shape({
  fullName: string().ensure(),
  context: string().defined(),
  email: string()
    .email('SIGNUP.ERRORS.INVALID_EMAIL_FORMAT')
    .required('VALIDATION.REQUIRED'),
});

export default signupSchema;
