/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ContentState, EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import styled from 'styled-components';
import { STYLE_MAP as FONT_SIZES_STYLE } from './RichTextControls/FontSizes';

export const DEFAULT_VALUE = '<p></p>';

export const styleMap = { ...FONT_SIZES_STYLE };

export enum DraftHandleValues {
  Handled = 'handled',
  NotHandled = 'not-handled',
}

export const toHtml = (contentState: ContentState): string =>
  convertToHTML({
    blockToHTML: (block) => {
      if (block.type === 'atomic') {
        const contentBlock = contentState.getBlockForKey(block.key);
        const entity = contentState.getEntity(contentBlock.getEntityAt(0));
        const type = entity.getType();

        if (type === 'image') {
          const { uploadId } = entity.getData();
          return (
            <figure>
              <img data-upload-id={uploadId} />
            </figure>
          );
        }

        return false;
      }
    },
  })(contentState);

export const convertFromHtmlWithOptions = convertFromHTML({
  htmlToBlock: (nodeName) => {
    if (nodeName === 'figure') {
      return {
        type: 'atomic',
        data: {},
      };
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'img') {
      return createEntity('image', 'IMMUTABLE', {
        uploadId: node.dataset.uploadId,
        width: node.width,
        height: node.height,
      });
    }
  },
});

export const getEditorStateFromValue = (
  value: string | undefined
): EditorState => {
  const contentState = convertFromHtmlWithOptions(value ?? DEFAULT_VALUE);
  return EditorState.createWithContent(contentState);
};

export const ToolbarIcon = styled.i`
  font-size: 21px;
`;
