import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { DoubleView, TripleView } from '../../icons';

const DocumentButtons: React.FC<{
  loading?: boolean;
  showLabel?: boolean;
  showIconLabels?: boolean;
  style?: React.CSSProperties;
  onSelect: (count: number) => void;
}> = ({
  loading = false,
  showLabel = true,
  showIconLabels = false,
  style,
  onSelect,
}) => {
  const t = useTranslation();
  return (
    <Outer style={style}>
      {showLabel && <Label>{t('DOC_VIEWER.DOCUMENT_VIEWS')}</Label>}
      <IconWrapper docLoading={loading} onClick={() => onSelect(3)}>
        <TripleView />
        {showIconLabels && <Text>{t('DOC_VIEWER.TRIPLE_VIEW')}</Text>}
      </IconWrapper>
      <IconWrapper
        docLoading={loading}
        onClick={() => onSelect(2)}
        style={{ marginLeft: 10 }}
      >
        <DoubleView />
        {showIconLabels && <Text>{t('DOC_VIEWER.DOUBLE_VIEW')}</Text>}
      </IconWrapper>
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  color: ${(props) => props.theme.colors.primary200};
`;

const IconWrapper = styled.div<{ docLoading: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${(props) => props.docLoading && `opacity: 0.3; pointer-events: none;`}
  span {
    color: ${(props) => props.theme.colors.blue300};
  }
`;

const Text = styled.span`
  margin: 6px 0 -6px;
`;

export default DocumentButtons;
