import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Button } from 'antd';
import useTranslation from '../../translations';
import { FilterType, FilterValue } from '../../types';
import { queryClient } from '../../services/queryClient';
import { QueryKey } from '../../queries';
import { B2 } from '../Atoms/Atoms';
import { Spinner } from '../Spinner';
import ColumnFilterSearchInput from './ColumnFilterSearchInput';
import ColumnFilterItem from './ColumnFilterItem';

interface Props {
  columnKey: string;
  type: FilterType;
  isOpen: boolean;
  value?: FilterValue[];
  setMenuOpen: (value: boolean) => void;
  onApplyFilter: (columnKey: string, value: FilterValue[]) => void;
}

const FILTER_SEARCH_THRESHOLD_LENGTH = 8; // number of items after which filter search appears as help

const ColumnFilterDropdown: React.FC<Props> = ({
  columnKey,
  type,
  isOpen,
  value,
  setMenuOpen,
  onApplyFilter,
}) => {
  const t = useTranslation();
  const [filterSearchTerm, setFilterSearchTerm] = useState<string>('');
  const [appliedFilters, setAppliedFilters] = useState<
    FilterValue[] | undefined
  >(value);

  const filterFunction = Array.isArray(type)
    ? () => Promise.resolve(type)
    : type;

  const {
    data: filterItems,
    isLoading,
    isError,
  } = useQuery([QueryKey.ListColumnFilter, columnKey], filterFunction, {
    onSuccess(data) {
      setAppliedFilters(
        (prev) =>
          prev?.filter((id) => data.find((f) => f.id === id) != null) ?? []
      );
    },
  });

  useEffect(() => {
    if (value !== appliedFilters) {
      setAppliedFilters(value);
    }
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      queryClient.invalidateQueries([QueryKey.ListColumnFilter, columnKey]);
    }
  }, [isOpen]);

  const filtersToRender = useMemo(() => {
    if (!filterItems) {
      return [];
    }

    return filterItems.filter((f) => {
      if (!filterSearchTerm.length) {
        return true;
      }

      return [f.value, f.title].some((v) =>
        v?.toLowerCase().includes(filterSearchTerm.toLowerCase())
      );
    });
  }, [filterItems, filterSearchTerm]);

  const handleClearAllClick = () => {
    setAppliedFilters([]);
    setFilterSearchTerm('');
  };

  const handleSelectAllClick = () => {
    if (!filterItems) {
      return;
    }
    setAppliedFilters(filterItems.map((f) => f.id));
    setFilterSearchTerm('');
  };

  const handleApplyClick = () => {
    setMenuOpen(false);
    if (appliedFilters) {
      onApplyFilter(columnKey, appliedFilters);
    }
  };

  const handleFilterSelectionChange = (newSelection: string | null) => {
    if (!filterItems) {
      return;
    }
    setAppliedFilters((prev) => {
      const newState = prev ? [...prev] : [];
      const index = newState.indexOf(newSelection);
      if (index < 0) {
        return [...newState, newSelection];
      }
      newState.splice(index, 1);
      return newState;
    });
  };

  let content: React.ReactNode;
  switch (true) {
    case isLoading:
      content = (
        <Message>
          <Spinner />
        </Message>
      );
      break;
    case isError:
      content = <Message>{t('SOMETHING_WENT_WRONG')}</Message>;
      break;
    case filterItems != null:
      content = filtersToRender.length
        ? filtersToRender.map((f) => (
            <ColumnFilterItem
              checked={!!appliedFilters?.includes(f.id as string)}
              key={f.id ?? '-1'}
              filter={f}
              onChange={handleFilterSelectionChange}
            />
          ))
        : t('ITEM_LIST.FILTERING.NO_FILTERS');
      break;
    default:
      break;
  }

  return (
    <FilterMenu>
      <FilterInner>
        <FilterControl onClick={handleClearAllClick}>
          {t('ITEM_LIST.FILTERING.CLEAR')}
        </FilterControl>
        <FilterControl onClick={handleSelectAllClick}>
          {t('ITEM_LIST.FILTERING.SELECT_ALL')}
        </FilterControl>
      </FilterInner>
      <Divider />
      {filterItems && filterItems.length > FILTER_SEARCH_THRESHOLD_LENGTH && (
        <ColumnFilterSearchInput
          value={filterSearchTerm}
          onChange={(v) => setFilterSearchTerm(v)}
        />
      )}
      <FilterList>{content}</FilterList>
      {filterItems && (
        <Actions>
          <Button type="primary" size="small" onClick={handleApplyClick}>
            {t('ITEM_LIST.FILTERING.APPLY')}
          </Button>
        </Actions>
      )}
    </FilterMenu>
  );
};

export default ColumnFilterDropdown;

const FilterControl = styled(B2)<{ $disabled?: boolean }>`
  color: ${(props) => props.theme.colors.blue300};
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  ${(props) =>
    props.$disabled && 'pointer-events: none; cursor: auto; opacity: 60%;'}
`;

const Divider = styled.div`
  margin: 10px -20px;
  height: 1px;
  width: calc(100% + 40px);
  background-color: ${(props) => props.theme.colors.white20};
`;

const FilterList = styled.div`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const FilterInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FilterMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 320px;
  max-height: 400px;
  overflow: hidden auto;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.17);
  background-image: linear-gradient(
    147deg,
    ${(props) => props.theme.colors.primary600},
    ${(props) => props.theme.colors.primary800} 100%
  );
  border-radius: 8px;
`;
