/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftStyleMap,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';
import React from 'react';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import DiffMatchPatch from '../../utils/dmp';
import { DiffOperation, DiffTuple } from '../../types';
import {
  getSelectedBlock,
  getSelectedBlocks,
  RemovableWord,
} from '../../utils';

const diff = new DiffMatchPatch();

export function getTargetText(diffs: DiffTuple[]): string {
  return diff.text2(diffs);
}

export const styleMap: DraftStyleMap = {
  inserted: {
    color: '#70c7ab',
    textDecoration: 'underline',
    whiteSpace: 'pre-line',
    fontSize: '14px',
    fontWeight: 400,
  },
  deleted: {
    textDecoration: 'line-through',
    color: '#c56581',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'pre-line',
  },
};

export enum DiffEntityTypes {
  DELETED = 'DELETED',
  INSERTED = 'INSERTED',
  NO_CHANGE = 'NO_CHANGE',
}

export const DeletedText: React.FC<{
  entityKey: string;
  offsetKey: string;
  children: React.ReactNode;
  contentState: ContentState;
}> = ({ offsetKey, children }) => (
  <span
    data-testid="deleted-text"
    style={styleMap.deleted}
    data-offset-key={offsetKey}
  >
    {children}
  </span>
);

export const NoChangeText: React.FC<{
  entityKey: string;
  offsetKey: string;
  children: React.ReactNode;
  contentState: ContentState;
}> = ({ offsetKey, children }) => (
  <span data-testid="normal-text" data-offset-key={offsetKey}>
    {children}
  </span>
);

export const InsertedText: React.FC<{
  entityKey: string;
  offsetKey: string;
  children: React.ReactNode;
  contentState: ContentState;
}> = ({ offsetKey, children }) => (
  <span
    data-testid="inserted-text"
    style={styleMap.inserted}
    data-offset-key={offsetKey}
  >
    {children}
  </span>
);

export function handleDeleteStrategy(
  contentBlock: ContentBlock,
  callback: (...args: any[]) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'DELETED'
    );
  }, callback);
}

export function handleInsertStrategy(
  contentBlock: ContentBlock,
  callback: (...args: any[]) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === DiffEntityTypes.INSERTED
    );
  }, callback);
}

export function handleNoChangeStrategy(
  contentBlock: ContentBlock,
  callback: (...args: any[]) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === DiffEntityTypes.NO_CHANGE
    );
  }, callback);
}
export enum DiffType {
  NO_CHANGE = 0,
  INSERTION = 1,
  DELETION = -1,
}

const DiffEntityMap = {
  [DiffType.NO_CHANGE]: DiffEntityTypes.NO_CHANGE,
  [DiffType.INSERTION]: DiffEntityTypes.INSERTED,
  [DiffType.DELETION]: DiffEntityTypes.DELETED,
};

export type EntityRecord = {
  block: ContentBlock;
  entities: {
    entityKey: string;
    instance: EntityInstance;
    start: number;
  }[];
};

export function isSelectionAtTheOfBlock(es: EditorState): boolean {
  const currentSelection = es.getSelection();
  const startOffset = currentSelection.getStartOffset();
  const endOffset = currentSelection.getEndOffset();
  const currentBlock = getSelectedBlock(es);
  if (startOffset !== endOffset) {
    return false;
  }
  return startOffset === currentBlock?.getLength();
}

export type BlocksWithEntityRecords = Record<string, EntityRecord>;

export function getSelectionEntity(
  editorState: EditorState
): BlocksWithEntityRecords {
  const selection = editorState.getSelection();
  let start = selection.getStartOffset();
  let end = selection.getEndOffset();
  if (start === end && start === 0) {
    end = 1;
  } else if (start === end) {
    start -= 1;
  }
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  const blocksWithEntities: BlocksWithEntityRecords = {};

  const blocks = getSelectedBlocks(editorState);

  let selectionStart = start;
  let selectionEnd = end;

  blocks?.forEach((b) => {
    if (!b) {
      return;
    }
    const currentBlockKey = (b as ContentBlock).getKey();
    if (blocksWithEntities[currentBlockKey] === undefined) {
      blocksWithEntities[currentBlockKey] = {
        block: b,
        entities: [],
      };
    }

    if (currentBlockKey !== startKey) {
      selectionStart = 0;
    } else {
      selectionStart = start;
    }
    if (currentBlockKey !== endKey) {
      selectionEnd = (b as ContentBlock).getLength();
    } else {
      selectionEnd = end;
    }

    if (selectionStart === selectionEnd) {
      const currentEntity = b?.getEntityAt(start);
      if (!currentEntity) {
        return;
      }
      blocksWithEntities[currentBlockKey].entities.push({
        entityKey: currentEntity,
        instance: editorState.getCurrentContent().getEntity(currentEntity),
        start: selectionStart,
      });
      return;
    }

    for (let i = selectionStart; i < selectionEnd; i += 1) {
      const entities = blocksWithEntities[currentBlockKey].entities;

      const currentEntity = b?.getEntityAt(i);
      if (!currentEntity) {
        continue;
      }
      if (entities[entities.length - 1]?.entityKey === currentEntity) {
        continue;
      }
      blocksWithEntities[currentBlockKey].entities.push({
        entityKey: currentEntity,
        instance: editorState.getCurrentContent().getEntity(currentEntity),
        start: i,
      });
    }
  });
  return blocksWithEntities;
}

export function createInsertedEntity(
  contentState: ContentState,
  data?: any
): ContentState {
  return contentState.createEntity(DiffEntityTypes.INSERTED, 'MUTABLE', data);
}

export function createDeletedEntity(
  contentState: ContentState,
  data?: any
): ContentState {
  return contentState.createEntity(DiffEntityTypes.DELETED, 'MUTABLE', data);
}

export function createNoChangeEntity(
  contentState: ContentState,
  data?: any
): ContentState {
  return contentState.createEntity(DiffEntityTypes.NO_CHANGE, 'MUTABLE', data);
}

const compositeDecorator = new CompositeDecorator([
  {
    strategy: handleDeleteStrategy,
    component: DeletedText,
  },
  {
    strategy: handleInsertStrategy,
    component: InsertedText,
  },
  {
    strategy: handleNoChangeStrategy,
    component: NoChangeText,
  },
]);
const toHtml = (contentState: ContentState): string =>
  convertToHTML({
    blockToHTML: (block) => {
      if (block.type === 'atomic') {
        const contentBlock = contentState.getBlockForKey(block.key);
        const entity = contentState.getEntity(contentBlock.getEntityAt(0));
        const type = entity.getType();

        if (type === 'image') {
          const { uploadId } = entity.getData();
          return (
            <figure>
              <img data-upload-id={uploadId} />
            </figure>
          );
        }

        return false;
      }
      let selectedEntity: EntityInstance | null = null;
      const contentBlock = contentState.getBlockForKey(block.key);
      let outputText = '';
      const text = contentBlock.getText();
      contentBlock.findEntityRanges(
        (character) => {
          if (character.getEntity() !== null) {
            selectedEntity = contentState.getEntity(character.getEntity());
            return true;
          }
          return false;
        },
        (start, end) => {
          const entityType = selectedEntity?.getType() as DiffEntityTypes;
          if (
            entityType === DiffEntityTypes.INSERTED ||
            entityType === DiffEntityTypes.NO_CHANGE
          ) {
            outputText = `${outputText}${text.slice(start, end)}`;
          }
        }
      );
      if (block.text === ' ' || block.text === '') {
        return <p></p>;
      }

      if (block.type === 'paragraph') {
        return <p>{outputText}</p>;
      }
      if (block.type === 'unstyled') {
        return <p>{outputText}</p>;
      }
    },
  })(contentState);

const convertFromHtmlWithOptions = convertFromHTML({
  htmlToBlock: (nodeName) => {
    if (nodeName === 'figure') {
      return {
        type: 'atomic',
        data: {},
      };
    }
    if (nodeName === 'p') {
      return {
        type: 'paragraph',
        data: {},
      };
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'ins') {
      return createEntity(
        DiffEntityMap[DiffOperation.DIFF_INSERT],
        'MUTABLE',
        {}
      );
    }
    if (nodeName === 'del') {
      return createEntity(
        DiffEntityMap[DiffOperation.DIFF_DELETE],
        'MUTABLE',
        {}
      );
    }
    if (nodeName === 'normal') {
      return createEntity(
        DiffEntityMap[DiffOperation.DIFF_EQUAL],
        'MUTABLE',
        {}
      );
    }
    if (nodeName === 'img') {
      return createEntity('image', 'IMMUTABLE', {
        uploadId: node.dataset.uploadId,
        width: node.width,
        height: node.height,
      });
    }
  },
});

const htmlTagPattern = /<.*?>/g;

function wrapStringBetweenPositions(
  str: string,
  startIndex: number,
  endIndex: number,
  tag: string
): string {
  const extractedText = str.slice(startIndex, endIndex);
  return extractedText !== '' ? `<${tag}>${extractedText}</${tag}>` : '';
}

function wrapTextWithTag(
  text: string,
  tag: 'ins' | 'del' | 'normal' = 'normal'
): string {
  const matches = [...text.matchAll(htmlTagPattern)];
  if (
    matches.length === 0 ||
    (matches.length === 1 && Object.keys(matches[0]).length === 0)
  ) {
    return `<${tag}>${text}</${tag}>`;
  }
  let outputString = '';
  matches.forEach((m, i) => {
    let startIndex;
    let endIndex;

    switch (true) {
      // if we matched the tag at the beginning of the string
      // E.g. <p>something_something_something...</p>
      //         ^-start index                   ^-end index
      // OR
      // </br>something_something_something.....end of string
      //      ^-start index                                  ^-end index (text length)
      case m.index === 0: {
        startIndex = m[0].length;
        endIndex = matches[i + 1]?.index || text.length;
        const wrappedString = wrapStringBetweenPositions(
          text,
          startIndex,
          endIndex,
          tag
        );
        outputString = `${outputString}${m[0]}${wrappedString}`;
        break;
      }
      // All other cases
      // We have some string at the beginning and tag somewhere after
      // ...<p>something_something....</p>.....
      //       ^- extract this text   ^-until here
      case i > 0: {
        startIndex = (m.index as number) + m[0].length;
        // If this is last match, match everything until end
        endIndex = matches[i + 1]?.index || text.length;
        const wrappedString = wrapStringBetweenPositions(
          text,
          startIndex,
          endIndex,
          tag
        );
        outputString = `${outputString}${m[0]}${wrappedString}`;
        break;
      }
      // We have some string at the beginning and tag somewhere after
      // something_something_something...</br>something_something
      // ^- extract this text           ^     ^- and this text
      case i === 0: {
        startIndex = 0;
        endIndex = m.index as number;
        const firstPart = wrapStringBetweenPositions(
          text,
          startIndex,
          endIndex,
          tag
        );
        startIndex = (m.index as number) + m[0].length;
        endIndex = matches[i + 1]?.index || text.length - startIndex;
        const secondPart = wrapStringBetweenPositions(
          text,
          startIndex,
          endIndex,
          tag
        );
        outputString = `${outputString}${firstPart}${m[0]}${secondPart}`;
        break;
      }
      default: {
        startIndex = 0;
        endIndex = m.index as number;
        const wrappedText = wrapStringBetweenPositions(
          text,
          startIndex,
          endIndex,
          tag
        );
        outputString = `${outputString}${m[0]}${wrappedText}`;
      }
    }
  });

  return outputString;
}

export const getEditorStateFromTuples = (
  diffTuples?: Array<DiffTuple>
): EditorState => {
  if (!diffTuples?.length) {
    return EditorState.createWithContent(ContentState.createFromBlockArray([]));
  }
  const concatenatedDiff = diffTuples.reduce((prev, [type, textPart]) => {
    if (type === DiffOperation.DIFF_INSERT) {
      return `${prev}${wrapTextWithTag(textPart, 'ins')}`;
    }
    if (type === DiffOperation.DIFF_DELETE) {
      return `${prev}${wrapTextWithTag(textPart, 'del')}`;
    }
    if (type === DiffOperation.DIFF_EQUAL) {
      return `${prev}${wrapTextWithTag(textPart, 'normal')}`;
    }
    return `${prev}${textPart}`;
  }, '');
  const newContentState = convertFromHtmlWithOptions(concatenatedDiff);
  return EditorState.createWithContent(newContentState, compositeDecorator);
};

export function getEntitiesInPreviousWord(
  es: EditorState
): BlocksWithEntityRecords {
  const selectedWord = selectPreviousWord(es);
  return getSelectionEntity(EditorState.forceSelection(es, selectedWord));
}

export function getEntitiesInNextWord(
  es: EditorState
): BlocksWithEntityRecords {
  const selectedWord = selectNextWord(es);
  return getSelectionEntity(EditorState.forceSelection(es, selectedWord));
}

export function applyDeletedEntityToHomogenousSelection(
  es: EditorState,
  selection?: SelectionState
): EditorState {
  const contentState = es.getCurrentContent();
  const contentStateWithEntity = createDeletedEntity(contentState);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const appliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    selection || es.getSelection(),
    entityKey
  );
  // Apply entity to the state
  return EditorState.push(es, appliedEntity, 'apply-entity');
}

export function handleFreshlyBackwardDeletedText(es: EditorState): EditorState {
  const selection = es.getSelection();
  const contentState = es.getCurrentContent();
  const contentStateWithEntity = createDeletedEntity(contentState);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const anchor = selection.getAnchorOffset();
  const focus = selection.getFocusOffset();
  let finalSelection: SelectionState;
  let currentSelection: SelectionState;
  // If we don't have anything selected in editor
  if (focus === anchor) {
    currentSelection = selection.merge({
      focusOffset: selection.getAnchorOffset(),
      anchorOffset: selection.getAnchorOffset() - 1,
    });
    finalSelection = selection.merge({
      focusOffset: selection.getFocusOffset() - 1,
      anchorOffset: selection.getFocusOffset() - 1,
    });
    // If we have a reverse selection
  } else if (focus < anchor) {
    currentSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
    finalSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getFocusOffset(),
    });
    // If we have a forward selection
  } else {
    currentSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
    finalSelection = selection.merge({
      focusOffset: selection.getAnchorOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
  }

  const appliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    currentSelection,
    entityKey
  );
  // Apply entity to the state
  const editorState = EditorState.push(es, appliedEntity, 'apply-entity');
  // Return cursor to proper position
  return EditorState.acceptSelection(editorState, finalSelection);
}

export function handleFreshlyForwardDeletedText(es: EditorState): EditorState {
  const selection = es.getSelection();
  const contentState = es.getCurrentContent();
  const contentStateWithEntity = createDeletedEntity(contentState);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const anchor = selection.getAnchorOffset();
  const focus = selection.getFocusOffset();
  let finalSelection: SelectionState;
  let currentSelection: SelectionState;
  // If we don't have anything selected in editor
  if (focus === anchor) {
    currentSelection = selection.merge({
      focusOffset: selection.getAnchorOffset() + 1,
      anchorOffset: selection.getAnchorOffset(),
    });
    finalSelection = selection.merge({
      focusOffset: selection.getFocusOffset() + 1,
      anchorOffset: selection.getFocusOffset() + 1,
    });
    // If we have a reverse selection
  } else if (focus < anchor) {
    currentSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
    finalSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getFocusOffset(),
    });
    // If we have a forward selection
  } else {
    currentSelection = selection.merge({
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
    finalSelection = selection.merge({
      focusOffset: selection.getAnchorOffset(),
      anchorOffset: selection.getAnchorOffset(),
    });
  }

  const appliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    currentSelection,
    entityKey
  );
  // Apply entity to the state
  const editorState = EditorState.push(es, appliedEntity, 'apply-entity');
  // Return cursor to proper position
  return EditorState.acceptSelection(editorState, finalSelection);
}

export function getEntityAfterCharacter(
  es: EditorState
): BlocksWithEntityRecords {
  const selection = es.getSelection();
  const selectionState = es.getSelection().merge({
    focusOffset: selection.getFocusOffset() + 1,
    anchorOffset: selection.getAnchorOffset(),
  });
  return getSelectionEntity(EditorState.forceSelection(es, selectionState));
}

export function isSelectionEqualToText(es: EditorState, text: string): boolean {
  const selection = es.getSelection();
  const currentBlock = getSelectedBlock(es);

  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  if (start === end) {
    return false;
  } else {
    const selectedText = currentBlock?.getText().slice(start, end);
    return selectedText === text;
  }
}

export function applyInsertedEntity(
  es: EditorState,
  text: string,
  existingEntityKey?: string
): EditorState {
  const selection = es.getSelection();
  let contentState = es.getCurrentContent();
  let entityKey;
  if (existingEntityKey) {
    entityKey = existingEntityKey;
  } else {
    contentState = createInsertedEntity(contentState);
    entityKey = contentState.getLastCreatedEntityKey();
  }

  const contentStateWithText = Modifier.insertText(
    contentState,
    selection,
    text,
    undefined,
    entityKey
  );
  return EditorState.push(es, contentStateWithText, 'insert-characters');
}

export function applyNoChangeEntityToSelection(
  es: EditorState,
  selection?: SelectionState
): EditorState {
  const contentState = es.getCurrentContent();
  const contentStateWithEntity = createNoChangeEntity(contentState);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const appliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    selection || es.getSelection(),
    entityKey
  );
  return EditorState.push(es, appliedEntity, 'apply-entity');
}

export function moveSelectionToTheEnd(es: EditorState): EditorState {
  const block = getSelectedBlock(es);
  if (!block) {
    return es;
  }
  const selection = es.getSelection();
  const selectionState = SelectionState.createEmpty(block.getKey());
  const updatedSelection = selectionState.merge({
    focusOffset: selection.getEndOffset(),
    anchorOffset: selection.getEndOffset(),
  });
  return EditorState.forceSelection(es, updatedSelection);
}

export function moveSelectionToTheStart(es: EditorState): EditorState {
  const block = getSelectedBlock(es);
  if (!block) {
    return es;
  }
  const selection = es.getSelection();
  const selectionState = SelectionState.createEmpty(block.getKey());
  const updatedSelection = selectionState.merge({
    focusOffset: selection.getStartOffset(),
    anchorOffset: selection.getStartOffset(),
  });
  return EditorState.forceSelection(es, updatedSelection);
}

export function removeTextRange(es: EditorState): EditorState {
  const selection = es.getSelection();
  let currentContent = es.getCurrentContent();
  currentContent = Modifier.removeRange(currentContent, selection, 'forward');
  return EditorState.push(es, currentContent, 'remove-range');
}

export function getBlockSelectionFromSelectedBlocks(
  entityRecord: EntityRecord,
  es: EditorState
) {
  const block = entityRecord.block;
  const selectionStart = es.getSelection().getStartOffset();
  const startBlockKey = es.getSelection().getStartKey();
  const selectionEnd = es.getSelection().getEndOffset();
  const endBlockKey = es.getSelection().getEndKey();
  let blockSelectionStart;
  let blockSelectionEnd;
  const currentBlockKey = block.getKey();
  const currentBlock = block;
  if (currentBlockKey !== startBlockKey) {
    blockSelectionStart = 0;
  } else {
    blockSelectionStart = selectionStart;
  }
  if (currentBlockKey !== endBlockKey) {
    blockSelectionEnd = currentBlock.getLength();
  } else {
    blockSelectionEnd = selectionEnd;
  }

  let tempSelection = SelectionState.createEmpty(currentBlockKey);
  tempSelection = tempSelection.merge({
    anchorOffset: blockSelectionStart,
    focusOffset: blockSelectionEnd,
  });
  return EditorState.forceSelection(es, tempSelection);
}

export function applyNewTextOverSelection(
  es: EditorState,
  text: string
): EditorState {
  const blocksWithEntities: BlocksWithEntityRecords = getSelectionEntity(es);

  let finalEditorState: EditorState = es;
  Object.values(blocksWithEntities).forEach((er) => {
    const entities = er.entities;
    if (entities.length > 1) {
      if (
        entities.every((f) => f.instance.getType() === DiffEntityTypes.DELETED)
      ) {
        const tempEditorState = getBlockSelectionFromSelectedBlocks(
          er,
          finalEditorState
        );

        const removeDeleted = applyNoChangeEntityToSelection(tempEditorState);
        finalEditorState = moveSelectionToTheEnd(removeDeleted);
      }
      const finalState = moveSelectionToTheEnd(finalEditorState);
      finalEditorState = addInsertedEntity(finalState, text);
    }
    const singleEntityInstance = entities[0].instance;
    const selection = finalEditorState.getSelection();
    const anchor = selection.getAnchorOffset();
    const focus = selection.getFocusOffset();
    if (anchor === focus) {
      return;
    }
    const textEqualToSelection = isSelectionEqualToText(finalEditorState, text);

    const isNoChangeSelection =
      entities.length === 1 &&
      singleEntityInstance.getType() === DiffEntityTypes.NO_CHANGE;

    const isInsertedSelection =
      entities.length === 1 &&
      singleEntityInstance.getType() === DiffEntityTypes.INSERTED;

    const isDeletedSelection =
      entities.length === 1 &&
      singleEntityInstance.getType() === DiffEntityTypes.DELETED;
    if ((isNoChangeSelection || isInsertedSelection) && textEqualToSelection) {
      finalEditorState = moveSelectionToTheEnd(finalEditorState);
    } else if (isNoChangeSelection && !textEqualToSelection) {
      const nextEditorState =
        applyDeletedEntityToHomogenousSelection(finalEditorState);
      const selectionMoved = moveSelectionToTheEnd(nextEditorState);
      finalEditorState = addInsertedEntity(selectionMoved, text);
    } else if (isDeletedSelection && textEqualToSelection) {
      const removeDeleted = applyNoChangeEntityToSelection(finalEditorState);
      finalEditorState = moveSelectionToTheEnd(removeDeleted);
    } else if (isDeletedSelection) {
      const selectionMoved = moveSelectionToTheEnd(finalEditorState);
      finalEditorState = addInsertedEntity(selectionMoved, text);
    } else if (isInsertedSelection) {
      const selectionRemoved = removeTextRange(finalEditorState);
      finalEditorState = addInsertedEntity(selectionRemoved, text);
    }
  });

  return finalEditorState;
}

export function applyTextAfterCursor(
  es: EditorState,
  text: string
): EditorState {
  const selection = es.getSelection();
  const start = selection.getStartOffset();
  const currentBlock = getSelectedBlock(es);
  if (!currentBlock) {
    return es;
  }
  const currentForwardMatchingText = currentBlock
    ?.getText()
    .slice(start, start + text.length);
  if (currentForwardMatchingText === text) {
    const selectionState = SelectionState.createEmpty(currentBlock.getKey());
    const updatedSelection = selectionState.merge({
      focusOffset: start + text.length,
      anchorOffset: start,
    });
    const tempEditorState = EditorState.forceSelection(es, updatedSelection);
    const forwardMatchingEntity: BlocksWithEntityRecords =
      getSelectionEntity(tempEditorState);

    const entityRecord = forwardMatchingEntity[currentBlock.getKey()];
    if (entityRecord.entities.length > 1) {
      if (
        entityRecord.entities.every(
          (f) => f.instance.getType() === DiffEntityTypes.DELETED
        )
      ) {
        const removeDeleted = applyNoChangeEntityToSelection(es);
        return moveSelectionToTheEnd(removeDeleted);
      }
      // handle multiple entities
      return addInsertedEntity(es, text);
    }

    const singleEntityInstance = entityRecord.entities[0].instance;
    const isDeletedSelection =
      singleEntityInstance.getType() === DiffEntityTypes.DELETED;
    if (isDeletedSelection) {
      const removeDeleted = applyNoChangeEntityToSelection(tempEditorState);
      return moveSelectionToTheEnd(removeDeleted);
    } else {
      return addInsertedEntity(es, text);
    }
  } else {
    return addInsertedEntity(es, text);
  }
}

export function addInsertedEntity(es: EditorState, text: string): EditorState {
  const content = es.getCurrentContent();
  const selection = es.getSelection();
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const block = getSelectedBlock(es);
  if (start !== end) {
    return es;
  }
  const precedingEntityKey = block?.getEntityAt(start - 1);
  const succeedingEntityKey = block?.getEntityAt(start + 1);
  const precedingEntity = precedingEntityKey
    ? content.getEntity(precedingEntityKey)
    : undefined;
  const succeedingEntity = succeedingEntityKey
    ? content.getEntity(succeedingEntityKey)
    : undefined;
  if (precedingEntity?.getType() === DiffEntityTypes.INSERTED) {
    return applyInsertedEntity(es, text, precedingEntityKey);
  } else if (succeedingEntity?.getType() === DiffEntityTypes.INSERTED) {
    return applyInsertedEntity(es, text, succeedingEntityKey);
  }
  return applyInsertedEntity(es, text);
}

export function selectNextWord(es: EditorState): SelectionState {
  const selection = es.getSelection();
  const startOffset = selection.getStartOffset();
  const key = selection.getStartKey();
  const content = es.getCurrentContent();
  const text = content.getBlockForKey(key).getText().slice(startOffset);
  const toRemove = RemovableWord.getForward(text);
  return selection.merge({
    focusOffset: startOffset + toRemove.length,
    anchorOffset: startOffset,
  });
}

export function selectPreviousWord(es: EditorState): SelectionState {
  const selection = es.getSelection();
  const key = selection.getStartKey();
  const offset = selection.getStartOffset();
  const content = es.getCurrentContent();
  const text = content.getBlockForKey(key).getText().slice(0, offset);
  const toRemove = RemovableWord.getBackward(text);
  return selection.merge({
    focusOffset: selection.getFocusOffset() - toRemove.length,
    anchorOffset: selection.getAnchorOffset(),
    isBackward: true,
  });
}

export function convertEditorStateToDiff(es: EditorState): string {
  const content = es.getCurrentContent();
  return toHtml(content);
}

export function deleteWordWithMultipleEntities(
  es: EditorState,
  entities: BlocksWithEntityRecords,
  backwards = false
): EditorState {
  const previousWordSelection = backwards
    ? selectPreviousWord(es)
    : selectNextWord(es);
  return applyDeletedEntityToSelection(
    EditorState.forceSelection(es, previousWordSelection),
    entities,
    backwards
  );
}

export function applyDeletedEntityToSelection(
  es: EditorState,
  blocksWithEntities: BlocksWithEntityRecords,
  backwards = false
): EditorState {
  let finalEditorState: EditorState = es;
  const selectionStart = es.getSelection().getStartOffset();
  const startBlockKey = es.getSelection().getStartKey();
  const selectionEnd = es.getSelection().getEndOffset();
  const endBlockKey = es.getSelection().getEndKey();

  let blockSelectionStart = 0;
  let blockSelectionEnd = 0;
  Object.values(blocksWithEntities).forEach((b) => {
    const currentBlockKey = b.block.getKey();
    const currentBlock = b.block;
    const entities = b.entities;
    if (currentBlockKey !== startBlockKey) {
      blockSelectionStart = 0;
    } else {
      blockSelectionStart = selectionStart;
    }
    if (currentBlockKey !== endBlockKey) {
      blockSelectionEnd = currentBlock.getLength();
    } else {
      blockSelectionEnd = selectionEnd;
    }
    let offset = 0;
    for (const [i, entity] of b.entities.entries()) {
      const start = entity.start;
      const end =
        i < entities.length - 1 ? entities[i + 1].start : blockSelectionEnd;

      let tempSelection = SelectionState.createEmpty(currentBlockKey);
      tempSelection = tempSelection.merge({
        anchorOffset: start - offset,
        focusOffset: end - offset,
      });
      switch (entity.instance.getType()) {
        case DiffEntityTypes.DELETED:
          continue;
        case DiffEntityTypes.NO_CHANGE:
          finalEditorState = applyDeletedEntityToHomogenousSelection(
            finalEditorState,
            tempSelection
          );
          continue;
        case DiffEntityTypes.INSERTED:
          offset += Math.abs(blockSelectionEnd - start);
          let currentContent = finalEditorState.getCurrentContent();
          currentContent = Modifier.removeRange(
            currentContent,
            tempSelection,
            'forward'
          );
          finalEditorState = EditorState.push(
            finalEditorState,
            currentContent,
            'remove-range'
          );
      }
    }
    const finalSelection = finalEditorState.getSelection().merge({
      focusOffset: backwards ? blockSelectionStart : selectionEnd - offset,
      anchorOffset: backwards ? blockSelectionStart : selectionEnd - offset,
    });
    finalEditorState = EditorState.forceSelection(
      finalEditorState,
      finalSelection
    );
  });
  return finalEditorState;
}
