import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Input } from 'antd';
import useTranslation from '../../../../translations';
import isElementFullyVisible from '../../toolbar/util/isElementFullyVisible';
import bindScrollHandler from '../../toolbar/util/bindScrollHandler';
import { T } from '../../../../..';
import CommentUserInfo from './CommentUserInfo';
import { IComment } from './commentPlugin';
import CommentItem, { CancelButton, SaveButton } from './CommentItem';
import ModalHeader from './ModalHeader';
import { getNewComment, processCommentMarks } from './utils';

const { TextArea } = Input;

interface Props {
  editorState: EditorState;
  editorView: EditorView;
  anchor: HTMLElement | null;
  session: T.Session;
  parentNode: HTMLElement;
  comments: IComment[];
  onClose: () => void;
}

const Modal: React.FC<Props> = ({
  editorView,
  session,
  parentNode,
  comments,
  onClose,
}) => {
  const t = useTranslation();

  const { from, to } = editorView.state.selection;

  const [isOpen, setIsOpen] = useState(true);
  const [newComment, setNewComment] = useState<IComment>(
    getNewComment(session, from, to)
  );
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isTextAreaFocus, setIsTextAreaFocus] = useState<boolean>(false);
  const commentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const isCommentEmpty = newComment.text.trim().length === 0;

  useEffect(() => {
    const handleToolbarVisibility = () => {
      setIsOpen(isElementFullyVisible(parentNode));
    };

    handleToolbarVisibility();
    bindScrollHandler(parentNode, handleToolbarVisibility);
  }, [parentNode]);

  useEffect(() => {
    commentRefs.current = comments.map((_, i) => commentRefs.current[i]);
  }, [comments]);

  const navigateComments = (direction: number) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < commentRefs.current.length) {
      commentRefs.current[newIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
      setCurrentIndex(newIndex);
    }
  };

  const changeNewComment = (value: Partial<IComment>) => {
    setNewComment((prev) => ({ ...prev, ...value }));
  };

  const resetNewComment = () => {
    setNewComment(getNewComment(session, from, to));
    setIsTextAreaFocus(false);
  };

  const addComment = () => {
    const { state, dispatch } = editorView;
    const commentAttr = { comments: [...comments, newComment] };
    const commentMarkType = state.schema.marks.comment;
    const tr = state.tr.addMark(from, to, commentMarkType.create(commentAttr));
    dispatch(tr);
    resetNewComment();
  };

  const updateComment = (id: string, newText: string) => {
    processCommentMarks(editorView, from, to, () =>
      comments.map((comment) =>
        comment.id === id ? { ...comment, text: newText } : comment
      )
    );
  };

  const deleteComment = (id: string) => {
    processCommentMarks(editorView, from, to, () =>
      comments.filter((comment) => comment.id !== id)
    );
  };

  const resolveComment = (commentIndex: number) => {
    processCommentMarks(editorView, from, to, () => {
      if (commentIndex >= 0 && commentIndex < comments.length) {
        const newComments = [...comments];
        newComments[commentIndex] = {
          ...newComments[commentIndex],
          resolved: true,
        };
        return newComments;
      }
      return comments;
    });
  };

  return (
    <Outer>
      <Inner isVisible={isOpen}>
        <ModalHeader
          onMoveUp={() => navigateComments(-1)}
          onMoveDown={() => navigateComments(1)}
          currentIndex={currentIndex}
          comments={comments}
          onResolve={resolveComment}
          onClose={() => onClose()}
          isVisible={comments.length !== 0}
        />
        <List>
          {comments.map((c, index) => (
            <CommentItem
              key={c.id}
              ref={(el: HTMLDivElement) => (commentRefs.current[index] = el)}
              comment={c}
              active={index === currentIndex}
              isOwnedByUser={c.createdById === session.userId}
              onUpdateNote={updateComment}
              onDeleteNote={deleteComment}
            />
          ))}
        </List>
        {comments.length > 0 && <Divider />}
        <CommentUserInfo
          fullName={session.fullName}
          style={{ padding: '12px 0 8px 0' }}
        />
        <TextAreaWrapper isTextAreaFocus={isTextAreaFocus}>
          <StyledTextArea
            value={newComment.text}
            onChange={(e) => changeNewComment({ text: e.target.value })}
            placeholder={t('NOTES.PLACEHOLDER')}
            autoSize={{ minRows: 1 }}
            onFocus={(e) => {
              e.stopPropagation();
              setIsTextAreaFocus(true);
            }}
            onBlur={(e) => {
              e.stopPropagation();
              isCommentEmpty && setIsTextAreaFocus(false);
            }}
          />
          <Actions hide={isCommentEmpty}>
            <ActionButtons>
              <CancelButton type="default" onClick={resetNewComment}>
                {t('ACTION.CANCEL')}
              </CancelButton>
              <SaveButton
                type="primary"
                disabled={isCommentEmpty}
                onClick={addComment}
              >
                {t('ACTION.SAVE')}
              </SaveButton>
            </ActionButtons>
          </Actions>
        </TextAreaWrapper>
      </Inner>
    </Outer>
  );
};

export default Modal;

const Outer = styled.div`
  position: relative;
`;

const Inner = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white100};
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
  padding: 12px;
  border-radius: 8px;
  width: 340px;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transform: translateY(${(props) => (props.isVisible ? '0' : '-20px')});
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  right: 14px;
  top: 60px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  height: 100%;
`;

const TextAreaWrapper = styled.div<{ isTextAreaFocus: boolean }>`
  background-color: ${(props) => props.theme.colors.primary200_20};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid
    ${(props) =>
      props.isTextAreaFocus ? props.theme.colors.blue600 : 'transparent'};
`;
const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  background-color: transparent;
  padding: 18px 12px 18px 20px;
  border: unset;
  outline: none;
  cursor: pointer;
  max-height: 80px;
  color: ${(props) => props.theme.colors.charcoal100};
  &:focus {
    border: unset;
    box-shadow: unset;
  }
  &:focus-within {
    border: unset;
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.black40};
  }
`;

const Actions = styled.div<{ hide: boolean }>`
  flex-direction: row;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  gap: 8px;
  padding: 18px 12px 12px 20px;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const Divider = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.black10};
  margin-top: 10px;
`;
