import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

function useUpdateUserSettings(
  onSuccess?: (newUserSettings: T.UserSettings) => void,
  onError?: (error: AxiosError) => void
): UseMutationResult<T.UserSettings, AxiosError, T.UserSettings> {
  return useMutation<T.UserSettings, AxiosError, T.UserSettings>(
    (payload) => Api.userSettings.updateUserSettings(payload),
    {
      onSuccess,
      onError,
    }
  );
}

export default useUpdateUserSettings;
