import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const DEFAULT_ICON_SIZE = 18;

interface Props {
  className: string;
  size?: number;
  color?: string;
  style?: CSSProperties;
  title?: string;
  transition?: boolean;
  testId?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Icon = React.forwardRef<HTMLElement, Props>(
  (
    {
      className,
      size = DEFAULT_ICON_SIZE,
      color,
      style,
      title,
      transition = false,
      testId,
      onClick,
      ...props
    },
    ref
  ) => (
    <StyledIcon
      ref={ref}
      className={className}
      size={size}
      color={color}
      style={style}
      title={title}
      transition={transition}
      data-testid={testId}
      onClick={onClick}
      {...props}
    />
  )
);

export default Icon;

const StyledIcon = styled.i<{
  size: number;
  transition: boolean;
  color?: string;
}>`
  font-size: ${(props) => props.size}px;
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.transition && `transition: all 0.3s ease;`}
`;
