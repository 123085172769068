import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  active?: boolean;
  testId?: string;
  style?: CSSObject;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const StyledButton = styled.button`
  margin: 0 8px;
  padding: 9px 24px;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: ${(props) => props.theme.colors.white87};
  z-index: 1;
  font-size: ${(props) => props.theme.fonts.sizes.b1}px;
  line-height: ${(props) => props.theme.fonts.sizes.b1}px;
  transition: background 0.3s ease-in-out;
  &:disabled {
    background-image: none;
    pointer-events: none;
    color: ${(props) => props.theme.colors.white20};
    background-color: ${(props) => props.theme.colors.white10};
  }
`;

export default StyledButton;
