import { MarkSpec } from 'prosemirror-model';
import type { MutableAttrs } from 'prosemirror-tables';
import WebFontLoader from '../../toolbar/commands/WebFontLoader';

export const FONT_TYPE_NAMES = [
  // SERIF
  'Arial',
  'Arial Black',
  'Georgia',
  'Tahoma',
  'Times New Roman',
  'Times',
  'Verdana',
  // MONOSPACE
  'Courier New',
  'Lucida Console',
  'Monaco',
  'monospace',
];

const RESOLVED_FONT_NAMES = new Set(FONT_TYPE_NAMES);

const FontTypeMarkSpec: MarkSpec = {
  attrs: {
    name: { default: '' },
  },
  inline: true,
  group: 'inline',
  parseDOM: [
    {
      style: 'font-family',
      getAttrs: (name) => {
        if (typeof name !== 'string') {
          return null;
        }
        return {
          name: name ? name.replace(/[\"\']/g, '') : '',
        };
      },
    },
  ],

  toDOM(node) {
    const { name } = node.attrs;
    const attrs: MutableAttrs = {};
    if (name) {
      if (!RESOLVED_FONT_NAMES.has(name)) {
        // TODO: Cache custom fonts and preload them earlier.
        RESOLVED_FONT_NAMES.add(name);
        // https://github.com/typekit/webfontloader
        WebFontLoader.load({ google: { families: [name] } });
      }
      attrs.style = `font-family: ${name}`;
    }
    return ['span', attrs, 0];
  },
};

export default FontTypeMarkSpec;
