import { MarkSpec } from 'prosemirror-model';

const strikethrough: MarkSpec = {
  parseDOM: [
    { tag: 'strike' },
    { style: 'text-decoration=line-through' },
    { style: 'text-decoration-line=line-through' },
  ],
  toDOM() {
    return ['span', { style: 'text-decoration-line:line-through' }];
  },
};

export default strikethrough;
