import { MarkSpec } from 'prosemirror-model';

const superscript: MarkSpec = {
  parseDOM: [{ tag: 'sup' }, { style: 'vertical-align=super' }],
  toDOM() {
    return ['sup', 0];
  },
  excludes: 'subscript',
};

export default superscript;
