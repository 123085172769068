import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useVisualizationMostUsedCompanyGroups = (
  options?: UseQueryOptions<T.DataPoint[], AxiosError>
): UseQueryResult<T.DataPoint[], AxiosError> =>
  useQuery<T.DataPoint[], AxiosError>(
    QueryKey.MostUsedCompanyGroups,
    Api.tsd.getMostUsedCompanyGroups,
    options
  );

export default useVisualizationMostUsedCompanyGroups;
