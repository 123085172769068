import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import useTranslation from '../../../translations';
import { QueryKey } from '../../../queries';
import { Api } from '../../../..';
import { useFeatureToggle } from '../../../hooks';

declare global {
  interface Window {
    VERSION?: string;
  }
}

const AppVersion = () => {
  const t = useTranslation();
  const enabled = useFeatureToggle(false, 'v');
  const { data: BEVersion } = useQuery(
    QueryKey.GetAppVersion,
    Api.version.getAppVersion
  );
  const VERSION_INFO = [
    {
      label: t('VERSION.APP'),
      value: window?.VERSION || '',
    },
    {
      label: t('VERSION.BE'),
      value: BEVersion,
    },
  ];

  if (!enabled) {
    return null;
  }

  return (
    <Outer>
      {VERSION_INFO.map((info, index) => (
        <Version key={index}>
          <Label>{info.label}:</Label>
          <Value title={info.value}>{info.value}</Value>
        </Version>
      ))}
    </Outer>
  );
};

export default AppVersion;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: ${(props) => `1px solid ${props.theme.colors.primary200_30}`};
  align-items: center;
  padding: 8px;
`;

const Version = styled.div`
  display: flex;
  gap: 2px;
  max-width: 70px;
`;
const Label = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.colors.white40};
`;

const Value = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.colors.white70};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
