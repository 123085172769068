import {
  Command,
  EditorState,
  NodeSelection,
  TextSelection,
  Transaction,
} from 'prosemirror-state';
import { Fragment, Node } from 'prosemirror-model';
import { canSplit } from 'prosemirror-transform';
import { findParentNodeOfType } from '../util';
import { LIST_NUMBERING } from '../schema/nodes/nodeNames';

type NodeOnCursor = {
  pos: number;
  start: number;
  depth: number;
  node: Node;
};

export const claimPartEnter = (
  startNewParagraph = false,
  listType?: string
): Command => {
  return (state, dispatch) => {
    // Do not allow user to press enter inside list numbering node
    // it solves the following issue: (if I click enter in the middle of the custom numbering - part of the text moves to the right)
    // https://dev.azure.com/digitalattorney/ipos/_workitems/edit/2902#5472171
    if (state.selection.$from.parent.type.name === LIST_NUMBERING) {
      return true;
    }

    const claimPartOnCursor = findParentNodeOfType(
      state.schema.nodes.claimPart
    )(state.selection);
    const claimPartPos =
      claimPartOnCursor && state.doc.resolve(claimPartOnCursor.start);

    if (!claimPartOnCursor || !claimPartPos) {
      return false;
    }

    const { $from, $to } = state.selection;

    const nodeAtDepth = $from.node(-1);

    if (nodeAtDepth.type.name === 'table_cell') {
      return false;
    }

    let isRootClaimPartStart = false;

    if (
      claimPartOnCursor.node.textContent.length === 0 &&
      claimPartOnCursor.node.attrs.indentLevel === 0
    ) {
      isRootClaimPartStart = true;
    }

    let indentLevel = claimPartOnCursor.node.attrs.indentLevel;

    if (claimPartOnCursor.node.textContent.length === 0) {
      indentLevel = indentLevel - 1;
    }

    const canSplit =
      $from.parentOffset > 0 || claimPartOnCursor.node.textContent.length > 0;

    if (!isRootClaimPartStart && canSplit) {
      const maybeTr = splitClaimPart(
        state,
        {
          numberingTemplate: claimPartOnCursor.node.attrs.numberingTemplate,
          numberingType: listType
            ? listType
            : claimPartOnCursor.node.attrs.numberingType,
          indentLevel: Math.max(0, indentLevel),
          startNewParagraph,
        },
        claimPartOnCursor
      );

      if (maybeTr) {
        dispatch?.(maybeTr);
        return true;
      }
    }

    const startPos = state.selection.$anchor.pos;
    const parentEndPos =
      startPos + $from.parent.content.size - $from.parentOffset;

    if (isRootClaimPartStart) {
      const newClaimPart = state.schema.nodes.claimPart.createAndFill(
        {
          numberingTemplate: claimPartOnCursor.node.attrs.numberingTemplate,
          numberingType: 'none',
          indentLevel: 0,
          startNewParagraph,
        },
        [state.schema.nodes.paragraph.create(null)]
      );
      if (!newClaimPart) {
        return false;
      }
      if (dispatch) {
        const tr = state.tr;

        tr.insert(parentEndPos - 2, newClaimPart);
        dispatch(
          tr.setSelection(
            TextSelection.near(tr.doc.resolve(state.selection.to + 1))
          )
        );
      }
      return true;
    }

    const nodesToMove = [] as Node[];

    state.doc.nodesBetween(startPos, parentEndPos, (node, pos) => {
      if (pos >= startPos - 1) {
        nodesToMove.push(node);
      }
    });

    const fragment = Fragment.fromArray(nodesToMove);
    const nodeAfter = $to.nodeAfter;

    const newClaimPartContent = getNewClaimPartContent(
      state,
      fragment,
      nodeAfter
    );

    const newClaimPart = state.schema.nodes.claimPart.createAndFill(
      {
        numberingTemplate: claimPartOnCursor.node.attrs.numberingTemplate,
        numberingType: listType
          ? listType
          : claimPartOnCursor.node.attrs.numberingType,
        indentLevel: Math.max(0, indentLevel),
        startNewParagraph,
      },
      [state.schema.nodes.paragraph.create({}, newClaimPartContent)]
    );

    if (!newClaimPart) {
      return false;
    }

    if (dispatch) {
      const afterPos = $to.after();
      const tr = state.tr;
      tr.delete(state.selection.from, afterPos);

      tr.insert(tr.mapping.map(afterPos), newClaimPart);
      dispatch(
        tr.setSelection(
          TextSelection.near(tr.doc.resolve(state.selection.to + 1))
        )
      );
    }
    return true;
  };

  function splitClaimPart(
    state: EditorState,
    itemAttrs: Record<string, unknown>,
    claimPartOnCursor: NodeOnCursor
  ): Transaction | null {
    const itemType = state.schema.nodes.claimPart;
    const { $from, $to, node } = state.selection as NodeSelection;
    if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
      return null;
    }
    const grandParent = $from.node(-1);
    if (grandParent.type != itemType) {
      return null;
    }
    if (
      $from.parent.content.size == 0 &&
      $from.node(-1).childCount == $from.indexAfter(-1)
    ) {
      return null;
    }
    const nextType =
      $to.pos == $from.end() && itemAttrs.numberingType !== 'custom'
        ? grandParent.contentMatchAt(0).defaultType
        : null;
    const tr = state.tr.delete($from.pos, $to.pos);
    const types = nextType
      ? [
          { type: itemType, attrs: itemAttrs },
          { type: nextType, attrs: itemAttrs },
        ]
      : undefined;
    if (!canSplit(tr.doc, $from.pos, 2, types)) {
      return null;
    }
    tr.split($from.pos, 2, types);
    if (types === undefined) {
      const item = findParentNodeOfType(itemType)(tr.selection);
      if (item) {
        tr.setNodeMarkup(item.pos, itemType, itemAttrs);
        if (itemAttrs.numberingType === 'custom') {
          const claimPartNode = claimPartOnCursor.node;
          let listNumberingNode = null as Node | null;
          claimPartNode.forEach((childNode) => {
            if (childNode.type === state.schema.nodes[LIST_NUMBERING]) {
              listNumberingNode = childNode;
            }
          });
          const node = state.schema.nodes[LIST_NUMBERING].create(
            listNumberingNode?.attrs,
            listNumberingNode?.content
          );
          tr.insert(item.pos + 1, node);
        }
      }
    }
    return tr;
  }

  function getNewClaimPartContent(
    state: EditorState,
    fragment: Fragment,
    nodeAfter: Node | null
  ) {
    let newClaimPartContent = null;

    if (fragment && fragment.size > 0) {
      newClaimPartContent = fragment;
    } else if (nodeAfter && nodeAfter.isText && nodeAfter.text) {
      newClaimPartContent = state.schema.text(nodeAfter.text);
    }

    return newClaimPartContent;
  }
};
