import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import QueryBuilderSegmentListItem from '../Common/QueryBuilderSegmentListItem';
import { Col } from './SynonymsListHeader';
import { useQueryContext } from 'components/TsdElement/Attributes/Query/QueryContext';
import { Action, QueryBaseItem, QuerySynonymOption, QuerySegment } from 'types';

interface Props {
  item: QueryBaseItem;
  selected: boolean;
  isTruncatedAtStart?: boolean;
  isTruncatedAtEnd?: boolean;
  searchInTitle?: boolean;
  searchInDescription?: boolean;
  searchInClaims?: boolean;
  searchInAbstract?: boolean;
}

const SynonymsListItem: React.FC<Props> = ({
  item,
  selected,
  isTruncatedAtStart,
  isTruncatedAtEnd,
  searchInTitle,
  searchInDescription,
  searchInClaims,
  searchInAbstract,
}) => {
  const { dispatch } = useQueryContext();
  const { id, value } = item;
  const segment = QuerySegment.Synonyms;

  const handleOptionChange = (
    e: CheckboxChangeEvent,
    option: QuerySynonymOption
  ) => {
    const checked = e.target.checked;
    dispatch({
      type: Action.OptionChangeRequest,
      payload: { id, option, checked, segment },
    });
  };

  return (
    <QueryBuilderSegmentListItem
      item={item}
      selected={selected}
      segment={QuerySegment.Synonyms}
    >
      <Content>
        <Col flex={1.5}>
          <Checkbox
            checked={isTruncatedAtStart}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.IsTruncatedAtStart)
            }
          />
        </Col>
        <Col flex={4.5} style={{ justifyContent: 'flex-start' }}>
          {value}
        </Col>
        <Col flex={1.5}>
          <Checkbox
            checked={isTruncatedAtEnd}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.IsTruncatedAtEnd)
            }
          />
        </Col>
        <Col>
          <Checkbox
            checked={searchInTitle}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.SearchInTitle)
            }
          />
        </Col>
        <Col>
          <Checkbox
            checked={searchInAbstract}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.SearchInAbstract)
            }
          />
        </Col>
        <Col>
          <Checkbox
            checked={searchInDescription}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.SearchInDescription)
            }
          />
        </Col>
        <Col>
          <Checkbox
            checked={searchInClaims}
            disabled={!selected}
            onChange={(e) =>
              handleOptionChange(e, QuerySynonymOption.SearchInClaims)
            }
          />
        </Col>
      </Content>
    </QueryBuilderSegmentListItem>
  );
};

export default SynonymsListItem;

const Content = styled.div`
  display: flex;
  margin-left: -10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
