import { DecorationSet } from 'prosemirror-view';
import { Plugin, PluginKey } from 'prosemirror-state';
import { DOMSerializer, Node } from 'prosemirror-model';
import { undoDepth } from 'prosemirror-history';
import { EditorDocument } from '../../../types';
import { schema } from '../schema/schema';
import { renderDecorations } from './renderDecorations';
import { Change, ChangeSet } from './index';

export interface TrackChangesState {
  changeSet: ChangeSet;
  decorationSet: DecorationSet;
  hasEditorChanges: boolean;
}

export const trackChangesPluginKey = new PluginKey<TrackChangesState>(
  'track-changes'
);

export const trackChangesPlugin = ({
  initialChanges,
  referenceDoc,
  disableRendering,
}: {
  referenceDoc: EditorDocument | null;
  initialChanges: Change[] | null;
  disableRendering?: boolean;
}) => {
  let domSerializer: DOMSerializer;
  return new Plugin<TrackChangesState>({
    key: trackChangesPluginKey,
    props: {
      decorations(state) {
        return this?.getState?.(state)?.decorationSet;
      },
    },
    state: {
      init(config, state) {
        domSerializer =
          state.schema.cached?.domSerializer ||
          DOMSerializer.fromSchema(state.schema);

        if (initialChanges && referenceDoc) {
          const editorDoc = Node.fromJSON(schema, referenceDoc);
          const initialChangeset: ChangeSet = ChangeSet.create(
            editorDoc,
            undefined,
            initialChanges.map((change) => Change.fromJSON(change))
          );

          const decorations = renderDecorations(
            initialChangeset,
            domSerializer,
            disableRendering
          );
          return {
            changeSet: initialChangeset,
            decorationSet: DecorationSet.create(state.doc, decorations),
            hasEditorChanges: false,
          };
        }

        return {
          changeSet: ChangeSet.create(state.doc, undefined, []),
          decorationSet: DecorationSet.empty,
          hasEditorChanges: false,
        };
      },

      apply(tr, value, state) {
        const { changeSet: oldChangeSet } = value;

        if (tr.getMeta('addToHistory') === false || !tr.docChanged) {
          return value;
        }

        const undoActions = undoDepth(state);
        const changeSet = oldChangeSet.addSteps(tr.doc, tr.mapping.maps, {});

        const decorations = renderDecorations(
          changeSet,
          domSerializer,
          disableRendering
        );

        return {
          changeSet,
          decorationSet: DecorationSet.create(tr.doc, decorations),
          hasEditorChanges: undoActions > 0,
        };
      },
    },
  });
};
