import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Modal, Button, Input, Space, Tag, message } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { T, Api } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';

interface Props {
  tsd: T.Tsd;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteTsdModal: React.FC<Props> = ({ tsd, isOpen, onClose }) => {
  const history = useHistory();
  const t = useTranslation();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const deleteTsdMutation = useMutation(
    (tsdId: string) => Api.tsd.deleteTsd(tsdId),
    {
      onSuccess() {
        message.success(t('DELETE_TSD.SUCCESS'));
        history.push(generateUrl(ROUTES.HOME));
      },
    }
  );

  const handleDeleteClick = () => {
    deleteTsdMutation.mutate(tsd.id);
  };

  return (
    <Modal
      title={t('DELETE_TSD.TITLE')}
      open={isOpen}
      destroyOnClose
      onCancel={onClose}
      style={{ top: 140 }}
      width={550}
      footer={null}
    >
      <Body>
        <Space direction="vertical">
          <Trans
            t={t}
            i18nKey="DELETE_TSD.CONFIRM"
            values={{ name: tsd.name }}
            components={{
              tag: <StyledTag />,
            }}
            parent="p"
          />
          <Input
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            autoComplete="off"
            autoFocus
          />
        </Space>
      </Body>
      <Footer>
        <Space>
          <Button onClick={onClose}>{t('ACTION.CANCEL')}</Button>
          <Button
            loading={deleteTsdMutation.isLoading}
            onClick={handleDeleteClick}
            type="primary"
            disabled={confirmationText !== tsd.name}
          >
            {t('ACTION.DELETE')}
          </Button>
        </Space>
      </Footer>
    </Modal>
  );
};

export default DeleteTsdModal;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const StyledTag = styled(Tag)`
  white-space: pre-wrap;
  display: inline;
  margin-left: 5px;
  padding: 2px 7px;
`;
