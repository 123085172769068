/* eslint-disable @typescript-eslint/no-explicit-any */
import { Schema } from 'prosemirror-model';
import React from 'react';
import {
  EditorState,
  AllSelection,
  TextSelection,
  Transaction,
} from 'prosemirror-state';
import type { MutableAttrs } from 'prosemirror-tables';

import applyMark from '../util/applyMark';
import { MARK_FONT_TYPE } from '../../schema/marks/markNames';
import UICommand from './UICommand';

function setFontType(
  tr: Transaction,
  schema: Schema,
  name: string
): Transaction {
  const markType = schema.marks[MARK_FONT_TYPE];
  if (!markType) {
    return tr;
  }
  const { selection } = tr;
  if (
    !(selection instanceof TextSelection || selection instanceof AllSelection)
  ) {
    return tr;
  }
  const attrs: MutableAttrs | null = name ? { name } : null;
  tr = applyMark(tr, schema, markType, attrs);
  return tr;
}

class FontTypeCommand extends UICommand {
  _label: JSX.Element | null = null;

  constructor(name: string) {
    super();
    this.name = name;
    this._label = name ? (
      <span style={{ fontFamily: name }}>{name}</span>
    ) : null;
  }

  renderLabel = (): any => this._label;

  isEnabled = (state: EditorState): boolean => {
    const { schema, selection, tr } = state;
    if (
      !(selection instanceof TextSelection || selection instanceof AllSelection)
    ) {
      return false;
    }
    const markType = schema.marks[MARK_FONT_TYPE];
    if (!markType) {
      return false;
    }

    const { from, to } = selection;
    if (to === from + 1) {
      const node = tr.doc.nodeAt(from);
      if (node && node.isAtom && !node.isText && node.isLeaf) {
        // An atomic node (e.g. Image) is selected.
        return false;
      }
    }

    return true;
  };

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, selection } = state;
    const tr = setFontType(state.tr.setSelection(selection), schema, this.name);
    if (tr.docChanged || tr.storedMarks) {
      dispatch && dispatch(tr);
      return true;
    }
    return false;
  };
}

export default FontTypeCommand;
