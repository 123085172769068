import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import isElementFullyVisible from '../../toolbar/util/isElementFullyVisible';
import bindScrollHandler from '../../toolbar/util/bindScrollHandler';

const ImageAlignValues = {
  NONE: {
    value: null,
    text: 'In Line with Text',
  },
  CENTER: {
    value: 'center',
    text: 'Top and Bottom',
  },
} as const;

type ImageAlign = keyof typeof ImageAlignValues;
type ImageAlignValue = (typeof ImageAlignValues)[ImageAlign]['value'];

export type ImageInlineEditorValue = {
  readonly align?: ImageAlignValue;
};

type Props = {
  active: boolean;
  value: ImageInlineEditorValue | null | undefined;
  anchor: HTMLSpanElement | null;
  children: React.ReactNode;
  onSelect: (val: ImageInlineEditorValue) => void;
};

const ImageInlineEditor: React.FC<Props> = ({
  active,
  value,
  anchor,
  children,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const align = value?.align || null;

  const handleClick = (clickedAlign: ImageAlignValue) => {
    onSelect({ align: clickedAlign });
  };

  const buttons = Object.keys(ImageAlignValues).map((key) => {
    const { value: alignValue, text } = ImageAlignValues[key as ImageAlign];
    return (
      <AlignButton
        active={align === alignValue}
        key={key}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(alignValue);
        }}
      >
        {text}
      </AlignButton>
    );
  });

  useEffect(() => {
    if (!anchor) {
      return;
    }
    const handleVisibility = () => {
      const isVisible = isElementFullyVisible(anchor) && active;
      setOpen(isVisible);
    };
    handleVisibility();
    const scrollHandle = bindScrollHandler(anchor, handleVisibility);
    return () => {
      scrollHandle.dispose();
    };
  }, [anchor, active]);

  return (
    <Popover
      isOpen={open}
      positions={['bottom']}
      content={<Outer onClick={(e) => e.stopPropagation()}>{buttons}</Outer>}
      align="center"
      onClickOutside={() => setOpen(false)}
      parentElement={anchor ?? undefined}
      containerStyle={{ zIndex: '10000000' }}
    >
      <div>{children}</div>
    </Popover>
  );
};

const AlignButton = styled.button<{ active: boolean }>`
  background: ${(props) =>
    props.active ? props.theme.colors.black30 : props.theme.colors.black20};
  border: none;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  height: 25px;
  &:hover {
    background: ${(props) => props.theme.colors.black40};
  }
`;

const Outer = styled.div`
  background: ${(props) => props.theme.colors.white100};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 6px;
  padding: 4px 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.46);
  height: 35px;
  align-items: center;
`;

export default ImageInlineEditor;
