import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Colors } from '../../../../../theme';
import useTranslation from '../../../../../translations';

const translationMapDouble = {
  1: 'MULTI_DOC_SEARCH.OPEN_LEFT',
  2: 'MULTI_DOC_SEARCH.OPEN_RIGHT',
};

const translationMapTriple = {
  1: 'MULTI_DOC_SEARCH.OPEN_LEFT',
  2: 'MULTI_DOC_SEARCH.OPEN_CENTER',
  3: 'MULTI_DOC_SEARCH.OPEN_RIGHT',
};

interface Props {
  activePosition: number;
  visibleDocCount: number;
  onClick: (viewerIndex: number) => void;
}

const SearchResultsListItemAction: React.FC<Props> = ({
  activePosition,
  visibleDocCount,
  onClick,
}) => {
  const t = useTranslation();

  const tKey =
    visibleDocCount === 2
      ? translationMapDouble[activePosition as 1 | 2]
      : translationMapTriple[activePosition as 1 | 2 | 3];

  return (
    <Tooltip
      title={t(tKey)}
      placement="top"
      color={Colors.gradient100}
      arrowPointAtCenter
      overlayInnerStyle={{
        width: 90,
        color: Colors.white87,
        textAlign: 'center',
      }}
      destroyTooltipOnHide
    >
      <Trigger onClick={() => onClick(activePosition - 1)}>
        {Array.from({ length: visibleDocCount }, (_, i) => (
          <Rect key={i} $active={activePosition === i + 1} />
        ))}
      </Trigger>
    </Tooltip>
  );
};

export default SearchResultsListItemAction;

const Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 30px;
  gap: 3px;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: ${(props) => props.theme.colors.white10};
  }
`;

const Rect = styled.div<{ $active: boolean }>`
  height: 14px;
  width: 7px;
  border-radius: 1px;
  background: ${(props) =>
    props.$active ? props.theme.colors.white100 : props.theme.colors.white20};
`;
