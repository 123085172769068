import { Progress } from 'antd';
import { useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import styled, { CSSObject } from 'styled-components';

interface Props {
  percent: number;
  colors: string[];
  invertedProgress?: boolean;
  style?: CSSObject;
  height?: number;
}

const ProgressBar: React.FC<Props> = ({
  percent,
  colors,
  style = {},
  height = 4,
  invertedProgress = false,
}) => {
  const percentValue = useMotionValue(percent);

  const inputRange = useMemo(() => {
    const range = [];

    for (let i = 0; i < colors.length; i++) {
      range.push((100 / (colors.length - 1)) * i);
    }

    return range;
  }, [colors]);

  useEffect(() => {
    percentValue.set(percent);
  }, [percent]);

  const strokeColor = useTransform(
    percentValue,
    inputRange,
    invertedProgress ? colors.reverse() : colors
  );

  return (
    <StyledProgress
      showInfo={false}
      percent={percent}
      style={style}
      strokeColor={strokeColor.get()}
      height={height}
    />
  );
};

export default ProgressBar;

const StyledProgress = styled(Progress)<{ height: number }>`
  margin: -4px 0;
  .ant-progress-line {
    font-size: 12px;
  }
  .ant-progress-bg {
    height: ${(props) => props.height}px !important;
  }
`;
