import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { Spinner } from '../../../Spinner';

// TODO: extract to shared button when new button styleguide is implemented

export interface Props {
  label: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  style?: CSSObject;
  className?: string;
  htmlType?: 'button' | 'submit';
  onClick?: () => void;
  dataTestId?: string;
}

const HeaderButton: React.FC<Props> = ({
  label,
  disabled,
  style,
  className,
  htmlType = 'button',
  onClick,
  loading,
  dataTestId,
}) => (
  <Outer>
    <StyledButton
      disabled={disabled}
      styleOverride={style}
      className={className}
      type={htmlType}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {label}
      {loading && <Spinner size="small" color="white" />}
    </StyledButton>
  </Outer>
);

export default HeaderButton;

const Outer = styled.div<{ isDisabled?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button<{ styleOverride?: CSSObject }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 56px;
  padding: 16px 24px;
  margin: 0;
  border-radius: 6px;
  min-width: 180px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: ${(props) => props.theme.colors.primary100};
  color: ${(props) => props.theme.colors.white87};
  &:hover {
    background-color: #848b9f;
  }
  &:active {
    background-color: #53596a;
  }
  &:disabled {
    opacity: 0.4;
    background-image: none;
    pointer-events: none;
  }
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;
