import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../Spinner';
import { TsdElementIpcClass } from '../../../types';
import { hover } from '../../../utils';
import useTranslation from '../../../translations';
import EmptyNotification from '../../EmptyNotification';
import TechFieldItem from './TechFieldItem';

interface Props {
  cpcclasses?: TsdElementIpcClass[];
  isLoading: boolean;
}

const TechFieldsOverview: React.FC<Props> = ({
  cpcclasses = [],
  isLoading,
}) => {
  const t = useTranslation();
  return (
    <Outer>
      {isLoading && (
        <SpinIndicator>
          <Spinner tip={t('COMPETITOR.CHART_PROGRESS')} size="large" />
        </SpinIndicator>
      )}
      {!isLoading && cpcclasses.length === 0 && (
        <NoData>
          <EmptyNotification
            message={t('COMPETITOR.TECH_FIELD_ANALYSIS.NO_CPC_IPC_FOUND')}
            style={{ maxHeight: 100, minHeight: 50 }}
          />
        </NoData>
      )}
      {!isLoading &&
        cpcclasses.length > 0 &&
        cpcclasses.map((cpc) => (
          <TechFieldItemWrapper key={cpc.ipcClassId}>
            <TechFieldItem
              cpcCode={cpc.ipcClass.code}
              cpcDescription={cpc.ipcClass.description}
            />
          </TechFieldItemWrapper>
        ))}
    </Outer>
  );
};

export default TechFieldsOverview;

const SpinIndicator = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Outer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
  flex: 1;
  padding-bottom: 30px;
`;

const TechFieldItemWrapper = styled.div`
  margin: 0 -40px;
  padding: 6px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  ${hover()};
  > div:last-child {
    visibility: visible;
  }
  &:hover {
    color: ${(props) => props.theme.colors.success};
  }
`;

const NoData = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
