import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { ComponentTypeToolbarItemProps } from '../config';
import { Icon } from '../../../Icon';
import { Colors } from '../../../../theme';
import useTranslation from '../../../../translations';
import TableGridSizeEditor, {
  TableGridSizeEditorValue,
} from './TableGridSizeEditor';

function hasParentElement(
  current: HTMLElement,
  parent?: HTMLElement | null
): boolean {
  if (!parent) {
    return false;
  }
  if (current === parent) {
    return true;
  }
  if (current.parentElement) {
    return hasParentElement(current.parentElement, parent);
  }
  return false;
}

const TableGridSizeMenu: React.FC<ComponentTypeToolbarItemProps> = ({
  dispatch,
  editorState,
  editorView,
  command,
}) => {
  const t = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [buttonRef, setButtonRef] = React.useState<HTMLElement | null>(null);
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null);
  const isMouseInside = useRef(false);

  const openPopover = () => setIsPopoverOpen(true);

  const onSelect = (val: TableGridSizeEditorValue) => {
    command?.executeWithUserInput(editorState, val, dispatch, editorView);
    setIsPopoverOpen(false);
  };

  const onMouseOut: MouseEventHandler = debounce(() => {
    if (isMouseInside.current) {
      return;
    }
    setIsPopoverOpen(false);
  }, 200);

  useEffect(() => {
    if (buttonRef?.offsetParent === null) {
      setIsPopoverOpen(false);
    }
  }, [buttonRef?.offsetParent]);

  useEffect(
    () => () => {
      setIsPopoverOpen(false);
    },
    []
  );

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      isMouseInside.current = hasParentElement(
        e.target as HTMLElement,
        gridElement
      );
    };

    document.addEventListener('mouseover', callback);
    const gridMouseLeave = () => {
      setIsPopoverOpen(false);
    };

    gridElement?.addEventListener('mouseleave', gridMouseLeave);
    return () => {
      document.removeEventListener('mouseover', callback);
      gridElement?.removeEventListener('mouseleave', gridMouseLeave);
    };
  }, [gridElement]);

  return (
    <Popover
      positions={['right']}
      align={'start'}
      isOpen={isPopoverOpen}
      containerStyle={{
        zIndex: '10000',
        marginLeft: '5px',
        marginTop: '-20px',
      }}
      ref={setButtonRef}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={<TableGridSizeEditor ref={setGridElement} onSelect={onSelect} />}
    >
      <TriggerButton
        onMouseEnter={openPopover}
        onMouseOut={onMouseOut}
        active={isPopoverOpen}
      >
        <Icon className="icn-table" color={Colors.white87} />
        {t('PM.ACTION.INSERT_TABLE')}
      </TriggerButton>
    </Popover>
  );
};

const TriggerButton = styled.button<{ active: boolean }>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 20px;
  line-height: 24px;
  ${(props) => props.active && `background: ${Colors.primary200_20}`}
`;

export default TableGridSizeMenu;
