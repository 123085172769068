import { Location } from 'history';

export const createRedirectQueryParam = (location: Location): string =>
  `${location.pathname}${location.search}${location.hash}`;

export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
