import { useEffect } from 'react';
import { isElementChildOf } from '../utils';

const useClickOutsideListener = (
  htmlElement: HTMLElement | false | undefined | null,
  listener: () => void
): void => {
  useEffect(() => {
    if (!htmlElement) {
      return;
    }

    const clickAwayListener = (e: MouseEvent) => {
      const isChild = isElementChildOf(htmlElement, e.target as HTMLElement);
      if (!isChild) {
        listener();
      }
    };
    if (htmlElement) {
      window.addEventListener('mousedown', clickAwayListener);
    }
    return () => {
      window.removeEventListener('mousedown', clickAwayListener);
    };
  }, [htmlElement, listener]);
};

export default useClickOutsideListener;
