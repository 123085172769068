import React from 'react';
import {
  ComponentTypeToolbarItemProps,
  ToolbarItemSubGroup,
  UIToolbarItem,
} from '../config';
import findActiveFontType, {
  FONT_TYPE_NAME_DEFAULT,
} from '../util/findActiveFontType';
import FontTypeCommand from '../commands/FontTypeCommand';
import { FONT_TYPE_NAMES } from '../../schema/marks/fontType';
import CommandMenuButton from './CommandMenuButton';

const FONT_TYPE_COMMANDS: Record<string, UIToolbarItem> = {
  [FONT_TYPE_NAME_DEFAULT]: { command: new FontTypeCommand(''), icon: '' },
};

FONT_TYPE_NAMES.forEach((name) => {
  FONT_TYPE_COMMANDS[name] = { command: new FontTypeCommand(name), icon: '' };
});

const COMMAND_GROUPS: ToolbarItemSubGroup = { group: [FONT_TYPE_COMMANDS] };

const FontTypeCommandMenuButton: React.FC<ComponentTypeToolbarItemProps> = ({
  dispatch,
  editorState,
  editorView,
}) => {
  const fontType = findActiveFontType(editorState);
  return (
    <CommandMenuButton
      commandGroups={COMMAND_GROUPS}
      dispatch={dispatch}
      editorState={editorState}
      editorView={editorView}
      label={fontType}
    />
  );
};

export default FontTypeCommandMenuButton;
