import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { useEffect, useRef, useState } from 'react';
import { TABLE_COMMANDS_GROUP } from '../config';
import CommandMenuButton from '../renderers/CommandMenuButton';
import { IconButton } from '../../../Button';
import { Colors } from '../../../../theme';

type Props = {
  editorState: EditorState;
  editorView: EditorView;
};

const TableCellMenu: React.FC<Props> = ({ editorState, editorView }) => {
  const [active, setActive] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div ref={menuRef}>
      <CommandMenuButton
        commandGroups={TABLE_COMMANDS_GROUP}
        dispatch={editorView.dispatch}
        editorState={editorState}
        editorView={editorView}
        anchor={
          <IconButton
            style={{
              color: Colors.charcoal100,
              background: Colors.primary200_40,
              borderRadius: 4,
              fontSize: 12,
              height: 12,
            }}
            buttonStyle={{
              width: 12,
              height: 12,
              position: 'absolute',
              top: 2,
              right: 3,
            }}
            className={active ? 'icn-chevron-up' : 'icn-chevron-down'}
            onClick={() => {
              setActive(!active);
            }}
          />
        }
      />
    </div>
  );
};

export default TableCellMenu;
