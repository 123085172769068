import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Button, Input, Checkbox, message } from 'antd';
import useTranslation from '../../translations';
import { QueryKey } from '../../queries';
import { Api, T, queryClient } from '../../..';
import { useInfiniteQuery } from '../../hooks';
import { Icon } from '../Icon';
import { useSessionContext } from '../../contexts';
import NotesList from './NotesList';
import NotesContextPicker from './NotesContextPicker';
import StyledIconButton from './StyledIconButton';
import { NoteContextItem } from './Notes';
import NotesFilter, { AvailableNoteOption } from './NotesFilter';

const { TextArea } = Input;

interface Props {
  noteContextItems: NoteContextItem[];
  onClose: () => void;
}

type NewNote = {
  text: string;
  isPublic: boolean;
};

const EMPTY_NOTE = {
  text: '',
  isPublic: true,
};

const NotesContent: React.FC<Props> = ({ noteContextItems, onClose }) => {
  const t = useTranslation();
  const {
    session: { userId },
  } = useSessionContext();

  const [newNote, setNewNote] = useState<NewNote>(EMPTY_NOTE);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [activeNoteContext, setActiveNoteContext] = useState<NoteContextItem>(
    noteContextItems[0]
  );
  const [includeUserNotes, setIncludeUserNotes] = useState<boolean>(true);
  const [includePublicNotes, setIncludePublicNotes] = useState<boolean>(true);
  const [userNotesFilter, setUserNotesFilter] = useState<
    ('private' | 'public') | null
  >(null);
  const includeAllUserNotes = includeUserNotes && userNotesFilter == null;

  const [isTextAreaFocus, setIsTextAreaFocus] = useState<boolean>(false);

  useEffect(() => {
    if (noteContextItems.length) {
      setActiveNoteContext(noteContextItems[0]);
    }
  }, [noteContextItems]);

  const isNewNoteEmpty = newNote.text.trim().length === 0;

  const notesQuery = useInfiniteQuery<T.UserNote, T.NotesQueryParams>({
    queryParams: {
      principalEntityId: activeNoteContext.id,
      context: activeNoteContext.context,
      isPublic: true,
    },
    queryKey: QueryKey.UserNotes,
    apiFunction: Api.notes.searchNotes,
  });

  const createNote = useMutation((request: T.CreateUserNote) =>
    Api.notes.createNote(request)
  );

  const updateNote = useMutation((request: T.UpdateUserNote) =>
    Api.notes.updateNote(request)
  );

  const deleteNote = useMutation(
    ({ noteId, context }: { noteId: string; context: T.NoteContext }) =>
      Api.notes.deleteNote(noteId, context)
  );

  const { infiniteData } = notesQuery;

  const invalidateNotes = async () => {
    await queryClient.invalidateQueries(QueryKey.UserNotes);
  };

  const resetNewNote = () => {
    setNewNote(EMPTY_NOTE);
    setIsTextAreaFocus(false);
  };

  const handleNewNoteRequest = () => {
    createNote.mutate(
      {
        principalEntityId: activeNoteContext.id,
        context: activeNoteContext.context,
        text: newNote.text,
        isPublic: newNote.isPublic,
      },
      {
        onSuccess() {
          invalidateNotes();
          resetNewNote();
        },
      }
    );
  };

  const handleUpdateNoteRequest = async (
    noteId: string,
    text: string,
    isPublic: boolean
  ) => {
    try {
      await updateNote.mutateAsync({
        context: activeNoteContext.context,
        noteId,
        text,
        isPublic,
      });
      message.success(t('NOTES.UPDATE_SUCCESS'));
      await invalidateNotes();
    } catch {
      message.error(t('NOTES.UPDATE_FAIL'));
      throw new Error();
    }
  };

  const handleDeleteNoteRequest = (noteId: string) => {
    deleteNote.mutate(
      {
        noteId,
        context: activeNoteContext.context,
      },
      {
        onSuccess() {
          invalidateNotes();
          message.success(t('NOTES.DELETE_SUCCESS'));
        },
        onError() {
          message.error(t('NOTES.DELETE_FAIL'));
        },
      }
    );
  };

  const handleNewNoteChange = (value: Partial<NewNote>) => {
    setNewNote((prev) => ({ ...prev, ...value }));
  };

  const handleFilterNotes = (options: React.Key[]) => {
    const includesUserPublic = options.includes(AvailableNoteOption.UserPublic);
    const includesUserNote = options.includes(AvailableNoteOption.UserNotes);
    const includesUserPrivate = options.includes(
      AvailableNoteOption.UserPrivate
    );
    const includesPublic = options.includes(AvailableNoteOption.Public);
    const newUserNotesFilter = includesUserPublic ? 'public' : 'private';

    setIncludeUserNotes(
      includesUserPublic || includesUserNote || includesUserPrivate
    );
    setIncludePublicNotes(includesPublic);

    setUserNotesFilter(
      includesUserNote || (options.length === 1 && includesPublic)
        ? null
        : newUserNotesFilter
    );
  };

  const handleCheckOption = (checkedOptions: React.Key[]) => {
    setCheckedKeys(checkedOptions);
    handleFilterNotes(checkedOptions);
  };

  const notes = useMemo(() => {
    const filterNote = (note: T.UserNote) => {
      const createdByUser = note.createdById === userId;
      if (includeAllUserNotes && createdByUser) {
        return true;
      }
      if (note.isPublic) {
        switch (true) {
          case includePublicNotes && !createdByUser:
          case includeUserNotes &&
            createdByUser &&
            userNotesFilter === 'public':
          case !includePublicNotes && !includeUserNotes:
            return true;
        }
      } else {
        if (userNotesFilter === 'private') {
          return true;
        }
        return false;
      }
    };
    return infiniteData.filter(filterNote);
  }, [
    includeUserNotes,
    includePublicNotes,
    userNotesFilter,
    infiniteData,
    userId,
    includeAllUserNotes,
  ]);

  return (
    <Outer>
      <Header>
        <Title>{t('NOTES.TITLE')}</Title>
        <StyledIconButton
          icon={<i className="icn-clear" />}
          onClick={onClose}
        />
      </Header>
      <Inner>
        <TextAreaWrapper isTextAreaFocus={isTextAreaFocus}>
          <StyledTextArea
            value={newNote.text}
            onChange={(e) => handleNewNoteChange({ text: e.target.value })}
            placeholder={t('NOTES.PLACEHOLDER')}
            disabled={createNote.isLoading}
            autoSize={{ minRows: 1 }}
            onFocus={(e) => {
              e.stopPropagation();
              setIsTextAreaFocus(true);
            }}
            onBlur={(e) => {
              e.stopPropagation();
              isNewNoteEmpty && setIsTextAreaFocus(false);
            }}
          />
          <Actions hide={isNewNoteEmpty}>
            <Checkbox
              checked={!newNote.isPublic}
              onChange={(e) =>
                handleNewNoteChange({ isPublic: !e.target.checked })
              }
            >
              <FlexCenter>
                <Icon className="icn-locked" size={16} />
                {t('NOTES.SAVE_AS_PRIVATE')}
              </FlexCenter>
            </Checkbox>
            <ActionButtons>
              <CancelButton
                type="default"
                disabled={createNote.isLoading}
                onClick={resetNewNote}
              >
                {t('ACTION.CANCEL')}
              </CancelButton>
              <SaveButton
                type="primary"
                loading={createNote.isLoading}
                disabled={isNewNoteEmpty}
                onClick={handleNewNoteRequest}
              >
                {t('ACTION.SAVE')}
              </SaveButton>
            </ActionButtons>
          </Actions>
        </TextAreaWrapper>
        <Controls>
          <Filters>
            {noteContextItems.length > 1 && (
              <NotesContextPicker
                items={noteContextItems}
                value={activeNoteContext.context}
                onChange={(c) => setActiveNoteContext(c)}
              />
            )}
          </Filters>
          <NotesFilter checkedKeys={checkedKeys} onChange={handleCheckOption} />
        </Controls>
      </Inner>
      <NotesList
        notesQuery={notesQuery}
        notes={notes}
        userId={userId}
        onUpdateNote={handleUpdateNoteRequest}
        onDeleteNote={handleDeleteNoteRequest}
      />
    </Outer>
  );
};

export default NotesContent;

const Outer = styled.div`
  padding: 40px 40px 20px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white87};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .ant-input:hover {
    border: unset;
    outline: none;
  }
  .ant-input[disabled] {
    background-color: unset;
    border-color: unset;
  }
`;

const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  background-color: transparent;
  padding: 18px 12px 12px 20px;
  border: unset;
  outline: none;
  cursor: pointer;
  &:focus {
    border: unset;
    box-shadow: unset;
  }
  &:focus-within {
    border: unset;
    outline: none;
  }
`;

const Controls = styled.div`
  display: flex;
`;

const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`;

const Actions = styled.div<{ hide: boolean }>`
  display: flex;
  flex-direction: row;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  gap: 8px;
  padding: 18px 12px 12px 20px;
  justify-content: space-between;
  align-items: center;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const TextAreaWrapper = styled.div<{ isTextAreaFocus: boolean }>`
  background-color: ${(props) => props.theme.colors.primary200_20};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  gap: 30px;
  border: 2px solid
    ${(props) =>
      props.isTextAreaFocus ? props.theme.colors.blue600 : 'transparent'};
`;
const FlexCenter = styled.div`
  display: flex;
  gap: 5px;
  align-items: baseline;
  font-size: 15px;
`;

const CancelButton = styled(Button)`
  border-radius: 5px;
  border: 2px solid ${(props) => props.theme.colors.white20};
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.white20};
    color: ${(props) => props.theme.colors.white87};
  }
`;
const SaveButton = styled(Button)`
  border-radius: 5px;
  border: 2px solid transparent;
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.primary100};
  &:hover,
  &:focus {
    border: 2px solid transparent;
    color: ${(props) => props.theme.colors.white87};
    background-color: ${(props) => props.theme.colors.primary100};
  }
`;
