import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { truncate } from '../../utils';
import AvatarCircle from './AvatarCircle';

interface Props {
  displayName: string;
  givenName?: string;
  surname?: string;
  mail?: string;
  mobilePhone?: string;
  additionalContent?: ReactElement;
}

const AvatarPopover: React.FC<Props> = ({
  displayName,
  givenName,
  surname,
  mail,
  mobilePhone,
  additionalContent,
}) => (
  <PopoverContent>
    <Header>
      <AvatarCircle
        displayName={displayName}
        givenName={givenName}
        surname={surname}
        size={48}
      />
      <DisplayName>{displayName}</DisplayName>
    </Header>
    <Details>
      <Row>
        <Icon className="icn-email" />
        <Text>{mail ?? '-'}</Text>
      </Row>
      <Row>
        <Icon className="icn-phone" />
        <Text>{mobilePhone ?? '-'}</Text>
      </Row>
      {additionalContent && <Row>{additionalContent}</Row>}
    </Details>
  </PopoverContent>
);

export default AvatarPopover;

const PopoverContent = styled.div`
  min-width: 220px;
  max-width: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Details = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.white30};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 8px;
  min-width: 0;
`;

const Text = styled.div`
  ${truncate()}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  font-size: 18px;
  margin-left: 10px;
`;
