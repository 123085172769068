import React from 'react';
import styled from 'styled-components';
import { Modal, message } from 'antd';
import { useMutation } from 'react-query';
import { T, Api, queryClient } from '@ipos/shared';
import { QueryKey } from 'queries';
import useTranslation from 'translations';

interface Props {
  tsd: T.Tsd;
  onClose: () => void;
}

const TsdPublishModal: React.FC<Props> = ({ tsd, onClose }) => {
  const t = useTranslation();
  const { id, isPublished } = tsd;

  let title = t('TSD_PUBLISHING.PUBLISH.TITLE');
  let prompt = t('TSD_PUBLISHING.PUBLISH.PROMPT');
  let okText = t('TSD_PUBLISHING.PUBLISH.OK_TEXT');

  if (isPublished) {
    title = t('TSD_PUBLISHING.UNPUBLISH.TITLE');
    prompt = t('TSD_PUBLISHING.UNPUBLISH.PROMPT');
    okText = t('TSD_PUBLISHING.UNPUBLISH.OK_TEXT');
  }

  const publishTsdMutation = useMutation(
    (request: T.UpdateTsd) => Api.tsd.updateTsd(request),
    {
      onSuccess(_, req) {
        message.success(
          req.isPublished
            ? t('TSD_PUBLISHING.PUBLISH.SUCCESS')
            : t('TSD_PUBLISHING.UNPUBLISH.SUCCESS')
        );
        queryClient.invalidateQueries([QueryKey.TsdData, id]);
        onClose();
      },
      onError(_, req) {
        message.error(
          req.isPublished
            ? t('TSD_PUBLISHING.PUBLISH.ERROR')
            : t('TSD_PUBLISHING.UNPUBLISH.ERROR')
        );
      },
    }
  );

  const handlePublishClick = () => {
    publishTsdMutation.mutate({
      id,
      isPublished: !isPublished,
    });
  };

  return (
    <Modal
      title={title}
      open={true}
      width={400}
      okText={okText}
      cancelText={t('ACTION.CANCEL')}
      confirmLoading={publishTsdMutation.isLoading}
      onOk={handlePublishClick}
      onCancel={onClose}
    >
      <Text>{prompt}</Text>
    </Modal>
  );
};

export default TsdPublishModal;

const Text = styled.p`
  color: ${(props) => props.theme.colors.white70};
  white-space: pre-wrap;
`;
