import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { getUser } from '../msGraph/graphAPI';
import { AadUser } from '../types';
import { QueryKey } from './';

const useAadUser = (
  aadObjectId: string,
  options?: UseQueryOptions<AadUser, AxiosError>
): UseQueryResult<AadUser, AxiosError> =>
  useQuery<AadUser, AxiosError>(
    [QueryKey.AadUser, aadObjectId],
    () => getUser(aadObjectId),
    options
  );

export default useAadUser;
