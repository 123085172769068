import React, { useMemo } from 'react';
import { Bar, BarConfig } from '@ant-design/charts';
import styled, { useTheme } from 'styled-components';
import { BarChartConfig, StyleMapping } from '../../types';

interface Props {
  title: string;
  config: BarChartConfig;
}

const BarChart: React.FC<Props> = ({ config, title }) => {
  const {
    data,
    series,
    xAxis,
    yAxis,
    tooltip,
    isStack,
    legend = undefined,
  } = config;

  // TODO: into a custom hook
  // const colorPalette = useColorPalette();
  const theme = useTheme();
  const colorPalette = useMemo(
    () => [
      theme.colors.visualization.aqua300,
      theme.colors.visualization.green300,
      theme.colors.visualization.plum300,
      theme.colors.visualization.red300,
      theme.colors.visualization.gold300,
    ],
    []
  );

  // reduce chart series into a hash<seriesName, color>
  const colors = series.reduce<StyleMapping>((prev, curr, idx) => {
    const retVal = { ...prev };
    let c = colorPalette[idx % colorPalette.length];
    if (curr.color) {
      c = curr.color;
    }
    retVal[curr.name] = c;
    return retVal;
  }, {});

  const barConfig: BarConfig = {
    data: data ?? [],
    xField: 'y',
    yField: 'x',
    seriesField: 'name',
    color: ({ name }) => colors[name],
    isStack: isStack ?? false,
    legend,
    label: { position: 'middle' },
    tooltip: tooltip ? tooltip : false,
    xAxis: xAxis
      ? {
          type: xAxis.type,
          title: xAxis.title
            ? {
                text: xAxis.title,
                style: { fill: 'white', fontSize: 14, fillOpacity: 0 },
              }
            : undefined,
          label: { style: { fill: 'white', fillOpacity: 0.5 } },
          grid: {
            line: {
              style: { opacity: 0.2 },
            },
          },
        }
      : undefined,
    yAxis: yAxis
      ? {
          title: yAxis.title
            ? {
                text: yAxis.title,
                style: { fill: 'white', fontSize: 14, fillOpacity: 0.6 },
              }
            : undefined,
          type: yAxis.type,
          label: { style: { fill: 'white' } },
        }
      : undefined,
  };
  return (
    <Outer>
      <Header>
        <Label>{title}</Label>
      </Header>
      <Bar {...barConfig} />
    </Outer>
  );
};

export default BarChart;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  align-items: center;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 4px;
`;
