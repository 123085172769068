import React from 'react';
import { Typography } from 'antd';
import { Components } from '@ipos/shared';
import { useTsd } from 'queries';
import useTranslation from 'translations';

const { Title } = Typography;
const { TsdTree } = Components;

interface Props {
  tsdId: string | null;
  onCheck: (tsdNodeIds: string[]) => void;
}

const ImportTsdNodesTree: React.FC<Props> = ({ tsdId, onCheck }) => {
  const t = useTranslation();
  const selectedTsdId = tsdId ?? '';
  const tsdQuery = useTsd(selectedTsdId, { enabled: selectedTsdId !== '' });

  const getPlaceholder = () => {
    if (tsdQuery.isError) {
      return t('SOMETHING_WENT_WRONG');
    }
    if (tsdQuery.isIdle) {
      return t('TREE.NO_SELECTION');
    }
    return t('TREE.NO_TREE');
  };

  return (
    <TsdTree
      root={tsdQuery.data?.rootNode}
      isLoading={tsdQuery.isLoading}
      placeholder={<Title level={5}>{getPlaceholder()}</Title>}
      checkable
      onCheck={onCheck}
      style={{ height: 600 }}
    />
  );
};

export default ImportTsdNodesTree;
