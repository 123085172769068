import type { Attrs, NodeSpec, Node, AttributeSpec } from 'prosemirror-model';
import type {
  MutableAttrs,
  TableNodesOptions,
  TableNodes,
} from 'prosemirror-tables';
import { v4 as uuid } from 'uuid';
import { toCSSColor } from '../../util';

const NO_VISIBLE_BORDER_WIDTH = new Set(['0pt', '0px']);

export function tableNodes(options: TableNodesOptions): TableNodes {
  const extraAttrs = options.cellAttributes || {};
  const cellAttrs: Record<string, AttributeSpec> = {
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
    nodeId: { default: null },
    showBorders: { default: true },
  };
  for (const prop in extraAttrs) {
    cellAttrs[prop] = { default: extraAttrs[prop].default };
  }
  return {
    table: {
      attrs: {
        nodeId: { default: null },
        autogeneratedId: { default: uuid() },
        class: { default: null },
      },
      content: 'table_row+',
      tableRole: 'table',
      isolating: true,
      group: options.tableGroup,
      parseDOM: [
        {
          tag: 'table',
          getAttrs(dom) {
            if (typeof dom === 'string') {
              return {};
            }
            return {
              nodeId: dom.getAttribute('nodeId'),
              deleted: dom.classList.contains('deleted'),
            };
          },
        },
      ],
      toDOM(node) {
        const attrs = {
          nodeId: node.attrs.nodeId,
          autogeneratedId: node.attrs.autogeneratedId,
          class: node.attrs.class,
        };
        if (node.attrs.deleted) {
          attrs.class = (attrs.class ? attrs.class + ' ' : '') + 'deleted';
        }
        return ['table', attrs, ['tbody', 0]];
      },
    },
    table_row: {
      content: '(table_cell | table_header)*',
      tableRole: 'row',
      parseDOM: [{ tag: 'tr' }],
      toDOM() {
        return ['tr', 0];
      },
    },
    table_cell: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'cell',
      isolating: true,
      parseDOM: [
        { tag: 'td', getAttrs: (dom) => getCellAttrs(dom, extraAttrs) },
      ],
      toDOM(node) {
        return ['td', setCellAttrs(node, extraAttrs), 0];
      },
    },
    table_header: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'header_cell',
      isolating: true,
      parseDOM: [
        { tag: 'th', getAttrs: (dom) => getCellAttrs(dom, extraAttrs) },
      ],
      toDOM(node) {
        return ['th', setCellAttrs(node, extraAttrs), 0];
      },
    },
  };
}

const tableNodesSpecs = tableNodes({
  tableGroup: 'block',
  cellContent: 'block+',
  cellAttributes: {
    borderColor: {
      default: null,
      getFromDOM(dom) {
        const { borderColor, borderWidth } = dom.style;

        if (NO_VISIBLE_BORDER_WIDTH.has(borderWidth)) {
          return 'transparent';
        }

        return (borderColor && toCSSColor(borderColor)) || null;
      },
      setDOMAttr(value, attrs) {
        if (value) {
          attrs.style = (attrs.style || '') + `;border-color: ${value};`;
        }
      },
    },
    background: {
      default: null,
      getFromDOM(dom) {
        return dom.style.backgroundColor || null;
      },
      setDOMAttr(value, attrs) {
        if (value) {
          attrs.style = (attrs.style || '') + `;background-color: ${value};`;
        }
      },
    },
  },
});

function getCellAttrs(dom: HTMLElement | string, extraAttrs: Attrs): Attrs {
  if (typeof dom === 'string') {
    return {};
  }
  const widthAttr = dom.getAttribute('data-colwidth');
  const width = widthAttr ? Number(widthAttr) : null;
  const colspan = Number(dom.getAttribute('colspan') || 1);
  const result: MutableAttrs = {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') || 1),
    colwidth: width,
  };
  for (const prop in extraAttrs) {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) {
      result[prop] = value;
    }
  }
  return result;
}

function setCellAttrs(node: Node, extraAttrs: Attrs): Attrs {
  const attrs: MutableAttrs = {};
  if (node.attrs.colspan != 1) {
    attrs.colspan = node.attrs.colspan;
  }
  if (node.attrs.rowspan != 1) {
    attrs.rowspan = node.attrs.rowspan;
  }
  if (node.attrs.colwidth) {
    attrs['data-colwidth'] = node.attrs.colwidth;
  }
  if (node.attrs.showBorders) {
    attrs.style = 'border-color: #000000';
  }
  for (const prop in extraAttrs) {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) {
      setter(node.attrs[prop], attrs);
    }
  }
  return attrs;
}

const nodeSpec: NodeSpec = {
  attrs: {
    nodeId: { default: null },
    marginLeft: { default: null },
    class: { default: null },
  },
  parseDOM: [
    {
      tag: 'table',
      getAttrs(dom) {
        if (!(dom instanceof HTMLElement)) {
          return null;
        }
        const { marginLeft } = dom.style;
        if (marginLeft && /\d+px/.test(marginLeft)) {
          return {
            marginLeft: parseFloat(marginLeft),
            nodeId: dom.getAttribute('nodeId'),
          };
        }
        return { nodeId: dom.getAttribute('nodeId') };
      },
    },
  ],
  toDOM(node) {
    const { marginLeft } = node.attrs;
    const domAttrs: MutableAttrs = {};
    if (marginLeft) {
      domAttrs.style = `margin-left: ${marginLeft}px`;
    }

    domAttrs.nodeId = node.attrs.nodeId;

    // Combine existing class with 'deleted' if present
    const classes = [node.attrs.class].filter(Boolean);
    if (node.attrs.deleted) {
      classes.push('deleted');
    }
    domAttrs.class = classes.join(' ');

    return ['table', domAttrs, 0];
  },
};

// Override the default table node spec to support custom attributes.
const TableNodeSpec = Object.assign({}, tableNodesSpecs.table, nodeSpec);
Object.assign(tableNodesSpecs, { table: TableNodeSpec });

export default tableNodesSpecs;
