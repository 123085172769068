import React from 'react';
import { AadUser } from '../../../types';
import UserContextProvider, { useAadUserContext } from './UserContextProvider';

interface Props {
  userId?: string;
  loadingFallback?: React.ReactNode;
  idleFallback?: React.ReactNode;
  errorFallback?: React.ReactNode;
  defaultFallbacks?: boolean;
  render: (user: AadUser) => React.ReactElement | null;
}

const UserRenderer: React.FC<React.PropsWithChildren<Props>> = ({
  userId,
  loadingFallback,
  idleFallback,
  errorFallback,
  defaultFallbacks,
  render,
}) => (
  <UserContextProvider
    userId={userId}
    loadingFallback={loadingFallback}
    idleFallback={idleFallback}
    errorFallback={errorFallback}
    defaultFallbacks={defaultFallbacks}
  >
    <UserRendererContent render={render} />
  </UserContextProvider>
);

const UserRendererContent: React.FC<{
  render: (user: AadUser) => React.ReactElement | null;
}> = ({ render }) => {
  const user = useAadUserContext();
  return render(user);
};

export default UserRenderer;
