import React from 'react';
import { Tooltip } from 'antd';
import { Colors } from '../../../theme';

interface Props {
  title: string;
  children: React.ReactNode;
}

const TranslationPickerTooltip: React.FC<Props> = ({ title, children }) => (
  <Tooltip
    overlayStyle={{ marginTop: 4 }}
    overlayInnerStyle={{
      backgroundColor: Colors.primary300,
      backgroundImage: 'none',
      fontSize: 11,
      color: Colors.white87,
      minHeight: 0,
      borderRadius: 3,
    }}
    title={title}
    color={Colors.primary300}
    destroyTooltipOnHide
  >
    {children}
  </Tooltip>
);

export default TranslationPickerTooltip;
