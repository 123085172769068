export enum Language {
  Unknown = 'unknown',
  Aa = 'aa',
  Ab = 'ab',
  Af = 'af',
  Am = 'am',
  Ar = 'ar',
  As = 'as',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Ca = 'ca',
  Co = 'co',
  Cs = 'cs',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dz = 'dz',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Ha = 'ha',
  Hi = 'hi',
  Hr = 'hr',
  Hu = 'hu',
  Hy = 'hy',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ik = 'ik',
  In = 'in',
  Is = 'is',
  It = 'it',
  Iw = 'iw',
  Ja = 'ja',
  Ji = 'ji',
  Jw = 'jw',
  Ka = 'ka',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Ks = 'ks',
  Ku = 'ku',
  Ky = 'ky',
  La = 'la',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lv = 'lv',
  Mg = 'mg',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mo = 'mo',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Ne = 'ne',
  Nl = 'nl',
  Nb = 'nb',
  No = 'no',
  Oc = 'oc',
  Om = 'om',
  Or = 'or',
  Pa = 'pa',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sd = 'sd',
  Sg = 'sg',
  Sh = 'sh',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Vi = 'vi',
  Vo = 'vo',
  Wo = 'wo',
  Xh = 'xh',
  Yo = 'yo',
  Zh = 'zh',
  Zu = 'zu',
}
