import React from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
import { Utils, Components, Colors } from '@ipos/shared';
import useTranslation from 'translations';

const { formatDateTime } = Utils;
const { Icon } = Components;

interface Props {
  isPublished: boolean;
  lastPublishedOn?: string;
}

const TsdPublishIndicator: React.FC<Props> = ({
  isPublished,
  lastPublishedOn,
}) => {
  const t = useTranslation();

  let title = t('TSD_PUBLISHING.PUBLISH.POPOVER.TITLE');
  let text = t('TSD_PUBLISHING.PUBLISH.POPOVER.TEXT');
  let icon = <StyledIcon className="icn-visible" color={Colors.success} />;

  if (!isPublished) {
    title = t('TSD_PUBLISHING.UNPUBLISH.POPOVER.TITLE');
    text =
      lastPublishedOn != null
        ? t('TSD_PUBLISHING.UNPUBLISH.POPOVER.UNDER_MAINTANANCE')
        : t('TSD_PUBLISHING.UNPUBLISH.POPOVER.NEVER_PUBLISHED');
    icon = <StyledIcon className="icn-hidden" color={Colors.error} />;
  }

  return (
    <Popover
      content={
        <PopoverContent>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {lastPublishedOn && (
            <LastPublished>
              <span>{t('TSD_PUBLISHING.LABEL')}</span>
              <span>{formatDateTime(lastPublishedOn, 'DD.MM.YYYY.')}</span>
            </LastPublished>
          )}
        </PopoverContent>
      }
      trigger="hover"
      placement="top"
      destroyTooltipOnHide
    >
      {icon}
    </Popover>
  );
};

export default TsdPublishIndicator;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white70};
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.white70};
`;

const LastPublished = styled.div`
  display: flex;
  gap: 5px;
  font-style: italic;
  font-size: 13px;
  span {
    color: ${(props) => props.theme.colors.white60};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 26px;
  cursor: pointer;
`;
