import { MarkSpec } from 'prosemirror-model';
import { isTransparent, toCSSColor } from '../../util';

const TextHighlightMarkSpec: MarkSpec = {
  attrs: {
    highlightColor: { default: '' },
  },
  inline: true,
  group: 'inline',
  parseDOM: [
    {
      tag: 'span[style*=background-color]',
      getAttrs: (dom) => {
        if (!(dom instanceof HTMLElement)) {
          return null;
        }
        const { backgroundColor } = dom.style;
        const color = toCSSColor(backgroundColor);
        return {
          highlightColor: isTransparent(color) ? '' : color,
        };
      },
    },
  ],

  toDOM(node) {
    const { highlightColor } = node.attrs;
    let style = '';
    if (highlightColor) {
      style += `background-color: ${highlightColor};`;
    }
    return ['span', { style }, 0];
  },
};

export default TextHighlightMarkSpec;
