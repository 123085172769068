import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useTsdElementIpcClasses = (
  tsdElementId: string,
  tsdNodeId?: string,
  options?: UseQueryOptions<T.TsdElementIpcClass[], AxiosError>
): UseQueryResult<T.TsdElementIpcClass[], AxiosError> =>
  useQuery<T.TsdElementIpcClass[], AxiosError>(
    [QueryKey.TsdElementIpcClasses, tsdElementId, tsdNodeId],
    () => Api.tsd.getTsdElementIpcClasses(tsdElementId, tsdNodeId),
    options
  );

export default useTsdElementIpcClasses;
