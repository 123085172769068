import React from 'react';
import { Typography } from 'antd';
import { T } from '@ipos/shared';
import { useAppContext } from 'contexts';

const { Text } = Typography;

interface Props {
  values: Partial<Record<T.Language, T.TsdElementTranslation>>;
  skip: string[];
}

const Synonyms: React.FC<Props> = ({ values, skip }) => {
  const { languages } = useAppContext();

  // make sure name isn't repeated in synonyms
  const synonyms = Object.values(values)
    .filter((n) => !skip.includes(n.value))
    .sort(
      (a, b) => languages.indexOf(a.language) - languages.indexOf(b.language)
    )
    .map((n) => n.value)
    .join(', ');

  if (!synonyms?.length) {
    return null;
  }

  return (
    <Text style={{ fontSize: 13 }} type="secondary" title={synonyms} ellipsis>
      {synonyms}
    </Text>
  );
};

export default Synonyms;
