import { NodeSpec } from 'prosemirror-model';

function getAttrs(dom: HTMLElement | string) {
  if (typeof dom === 'string') {
    return {};
  }
  const nodeId = dom.getAttribute('nodeId') || null;
  const level = dom.nodeName.match(/\d/);
  return { level: level ? +level[0] : 1, nodeId };
}

const heading: NodeSpec = {
  content: 'inline*',
  group: 'block',
  attrs: {
    level: { default: 1 },
    nodeId: { default: null },
  },
  defining: true,
  parseDOM: [
    { tag: 'h1', getAttrs },
    { tag: 'h2', getAttrs },
    { tag: 'h3', getAttrs },
    { tag: 'h4', getAttrs },
    { tag: 'h5', getAttrs },
    { tag: 'h6', getAttrs },
  ],
  toDOM(node) {
    return ['h' + node.attrs.level, { nodeId: node.attrs.nodeId }, 0];
  },
};

export default heading;
