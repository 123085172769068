import {
  baseKeymap as pmBaseKeymap,
  chainCommands,
  createParagraphNear,
  exitCode,
  newlineInCode,
  splitBlock,
} from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { keymap } from 'prosemirror-keymap';
import { history } from 'prosemirror-history';
import { inputRules } from 'prosemirror-inputrules';
import {
  createDedentListCommand,
  createIndentListCommand,
  createListPlugins,
  listInputRules,
  listKeymap,
} from 'prosemirror-flat-list';
import { columnResizing, goToNextCell, tableEditing } from 'prosemirror-tables';

import 'prosemirror-gapcursor/style/gapcursor.css';
import { schema } from '../schema/schema';
import { numberedParagraphCommand } from '../schema/nodes/numberedParagraph';
import TableCellMenuPlugin from '../toolbar/tableCellMenu/TableCellMenuPlugin';
import { CustomTableView } from '../plugins/TableView/TableView';
import { default as baseKeymap } from './keymap';
import { liftEmptyBlock } from './customCommands';
import { claimPartEnter } from './claimPartEnter';
import { claimEnter } from './claimEnter';
import { claimPartIndent } from './claimPartIndent';
import { claimPartDedent } from './claimPartDedent';
import { claimPartDelete } from './claimPartDelete';
import { listItemEnter } from './listItemEnter';
import { claimPartInputRule } from './claimPartInputRule';
import { insertHardBreak } from './insertHardBreak';

const listPlugins = createListPlugins({ schema });
const listInputRulePlugin = inputRules({ rules: listInputRules });
const claimPartInputRulePlugin = inputRules({
  rules: [claimPartInputRule(schema)],
});

export const basePlugins = [
  listInputRulePlugin,
  claimPartInputRulePlugin,
  ...listPlugins,
  keymap({
    ...listKeymap,
    ...pmBaseKeymap,
    ...baseKeymap,
    Enter: chainCommands(
      claimPartEnter(),
      numberedParagraphCommand,
      listItemEnter(),
      newlineInCode,
      createParagraphNear,
      liftEmptyBlock,
      splitBlock,
      pmBaseKeymap.Enter
    ),
    Delete: chainCommands(claimPartDelete, pmBaseKeymap.Delete),
    'Mod-Enter': chainCommands(claimEnter, exitCode),
    Tab: chainCommands(
      claimPartIndent,
      goToNextCell(1),
      createIndentListCommand()
    ),
    'Shift-Tab': chainCommands(
      claimPartDedent,
      goToNextCell(-1),
      createDedentListCommand()
    ),
    'Alt-Shift-Enter': chainCommands(claimPartEnter(true, 'none')),
    'Alt-Enter': chainCommands(claimPartEnter(false, 'none')),
    'Shift-Enter': chainCommands(insertHardBreak),
  }),
  dropCursor(),
  gapCursor(),
  history(),
  tableEditing(),
  new TableCellMenuPlugin(),
  columnResizing({
    View: CustomTableView,
  }),
];
