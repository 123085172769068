import { useQueries } from 'react-query';
import { Api } from '../..';
import { QueryKey } from './index';

// TODO: Add proper types

const useMultipleDocumentMetadata = (
  documentIds: string[],
  enabled?: boolean
) =>
  useQueries(
    documentIds.map((documentId) => ({
      queryKey: [QueryKey.DocumentMetadata, documentId],
      queryFn: () => Api.document.getMetadata(documentId),
      enabled,
    }))
  );

export default useMultipleDocumentMetadata;
