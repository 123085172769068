import { Node } from 'prosemirror-model';
import { TableView } from 'prosemirror-tables';

export class CustomTableView extends TableView {
  constructor(node: Node, cellMinWidth: number) {
    super(node, cellMinWidth);
    this.initializeDeletedClass(node);
  }

  update(node: Node): boolean {
    const updated = super.update(node);

    if (updated) {
      this.updateDeletedClass(node);
    }

    return updated;
  }

  private initializeDeletedClass(node: Node): void {
    const tableElement = this.table as HTMLTableElement;

    if (this.isEveryLeafDeleted(node)) {
      tableElement.classList.add('deleted');
    }
  }

  private updateDeletedClass(node: Node): void {
    const tableElement = this.table as HTMLTableElement;

    if (this.isEveryLeafDeleted(node)) {
      tableElement.classList.add('deleted');
    } else {
      tableElement.classList.remove('deleted');
    }
  }

  private isEveryLeafDeleted(node: Node): boolean {
    let allDeleted = true;

    node.descendants((tableChild: Node) => {
      if (tableChild.isLeaf) {
        if (
          !tableChild.attrs?.isDeleted &&
          !Boolean(tableChild.marks.find((m) => m.type.name === 'deleted'))
        ) {
          allDeleted = false;
          return false;
        }
      }
    });

    return allDeleted;
  }
}
