import { Plugin, PluginKey, Transaction } from 'prosemirror-state';

interface Options {
  ignoreTrackChanges?: boolean;
}

function removetrackChangesAttr(tr: Transaction) {
  let changed = false;
  tr.doc.descendants((node, pos) => {
    if (node.type.name !== 'claimPart') {
      return;
    }
    const attrs = {
      ...node.attrs,
    };

    if (attrs.isDeleted) {
      attrs.isDeleted = false;
    }
    if (attrs.isInserted) {
      attrs.isInserted = false;
    }
    if (attrs.baseClaimNumber) {
      attrs.baseClaimNumber = undefined;
    }

    const before = JSON.stringify(node.attrs);
    const after = JSON.stringify(attrs);

    if (before !== after) {
      changed = true;
      tr.setNodeMarkup(pos, undefined, attrs);
    }
  });

  return changed;
}

const claimPartsPluginKey = new PluginKey('claim_parts_plugin');
const claimPartsPlugin = (options?: Options) =>
  new Plugin({
    key: claimPartsPluginKey,
    state: {
      init: (_, state) =>
        options?.ignoreTrackChanges ? removetrackChangesAttr(state.tr) : false,
      apply: (tr) => {
        if (!options?.ignoreTrackChanges) {
          return false;
        }
        if (!tr.docChanged) {
          return false;
        }
        return removetrackChangesAttr(tr);
      },
    },
    appendTransaction: (transactions, oldState, newState) => {
      const isDocChanged = transactions.some((tr) => tr.docChanged);
      if (!isDocChanged) {
        return null;
      }
      if (!options?.ignoreTrackChanges) {
        return null;
      }
      const changed = claimPartsPluginKey.getState(newState);
      if (changed) {
        return newState.tr;
      }
      return null;
    },
  });

export default claimPartsPlugin;
