import styled, { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';

export const Normal = styled.input<{ $hasError?: boolean }>`
  border-radius: 4px;
  font-size: 12px;
  height: 26px;
  padding: 6px 8px 6px 8px;
  transition: border, box-shadow 0.2s ease-in-out;
  border: solid 1px ${(props) => props.theme.colors.primary200_40};
  background-color: ${(props) => props.theme.colors.primary900};
  ${down('lg')} {
    font-size: 13px;
  }
  ${up('lg')} {
    font-size: 15px;
    height: 30px;
  }
  &:focus {
    box-shadow: 0 0 0 1px #7b90aa, 0 0 0 3px rgba(255, 255, 255, 0.05);
    outline: none;
  }
  ${(props) =>
    props.$hasError &&
    css`
      box-shadow: 0 0 0 1px ${props.theme.colors.error},
        0 0 0 3px rgba(255, 255, 255, 0.05);
      &:focus {
        box-shadow: 0 0 0 1px ${props.theme.colors.error},
          0 0 0 3px rgba(255, 255, 255, 0.05);
      }
    `};
`;
export const Void = styled.input<{ $hasError?: boolean }>`
  background: none;
  color: ${(props) => props.theme.colors.white87};
  font-size: 12px;
  padding: 2px 10px 2px 0;
  display: block;
  border: none;
  transition: border 0.2s ease-in-out;
  border-radius: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary200_40};
  ${down('lg')} {
    font-size: 13px;
  }
  ${up('lg')} {
    font-size: 15px;
  }
  &:focus {
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.success};
  }
  ${(props) =>
    props.$hasError &&
    css`
      border-bottom: 1px solid ${props.theme.colors.error};
      &:focus {
        border-bottom: 1px solid ${props.theme.colors.error};
      }
    `};
`;
