import { Attrs, DOMOutputSpec, Node, NodeSpec } from 'prosemirror-model';
import type { MutableAttrs } from 'prosemirror-tables';
import toCSSLineSpacing from '../../toolbar/util/toCSSLineSpacing';
import convertToCSSPTValue from '../../util';
import { clamp } from '../../images/imageViewPlugin/utils';
// This assumes that every 36pt maps to one indent level.
export const INDENT_MARGIN_PT_SIZE = 36;
export const MIN_INDENT_LEVEL = 0;
export const MAX_INDENT_LEVEL = 7;
export const ATTRIBUTE_INDENT = 'data-indent';

export const ALIGN_PATTERN = /(left|right|center|justify)/;

const paragraph: NodeSpec = {
  attrs: {
    align: { default: null },
    color: { default: null },
    leftIndent: { default: null },
    lineSpacing: { default: null },
    nodeId: { default: null },
    justification: { default: null },
  },
  content: 'inline*',
  group: 'block',
  parseDOM: [{ tag: 'p', getAttrs }],
  toDOM,
};

export function parseAttrs(nodeAttrs: Attrs): MutableAttrs {
  const { align, color, leftIndent, lineSpacing, nodeId, justification } =
    nodeAttrs;

  const attrs: MutableAttrs = {};

  let style = '';
  if (align && align !== 'left') {
    style += `text-align: ${align};`;
  } else {
    if (justification === 'Center') {
      style += `text-align: center;`;
    }
  }

  if (lineSpacing) {
    const cssLineSpacing = toCSSLineSpacing(lineSpacing);
    style += `line-height: ${cssLineSpacing};`;
  }
  if (color) {
    style += `color: ${color};`;
  }

  style && (attrs.style = style);

  if (leftIndent) {
    attrs[ATTRIBUTE_INDENT] = String(leftIndent);
  }

  if (nodeId) {
    attrs.nodeId = nodeId;
    attrs['data-testid'] = nodeId;
  }

  return attrs;
}

export function getAttrs(dom: HTMLElement | string) {
  if (typeof dom === 'string') {
    return {};
  }
  const { lineHeight, textAlign, marginLeft, color } = dom.style;

  let align: string | null = dom.getAttribute('align') || textAlign || '';
  align = ALIGN_PATTERN.test(align) ? align : null;
  const attribute = dom?.getAttribute(ATTRIBUTE_INDENT);
  let leftIndent = attribute && parseInt(attribute, 10);

  if (!leftIndent && marginLeft) {
    leftIndent = convertMarginLeftToIndentValue(marginLeft);
  }

  leftIndent = leftIndent || MIN_INDENT_LEVEL;

  const lineSpacing = lineHeight ? toCSSLineSpacing(lineHeight) : null;

  const nodeId = dom.getAttribute('nodeId') || '';
  return { align, color, leftIndent, lineSpacing, nodeId };
}

function toDOM(node: Node): DOMOutputSpec {
  const attrs = parseAttrs(node.attrs);

  return ['p', attrs, 0];
}

export const toParagraphDOM = toDOM;
export const getParagraphNodeAttrs = getAttrs;

export function convertMarginLeftToIndentValue(marginLeft: string): number {
  const ptValue = convertToCSSPTValue(marginLeft);
  return clamp(
    MIN_INDENT_LEVEL,
    Math.floor(ptValue / INDENT_MARGIN_PT_SIZE),
    MAX_INDENT_LEVEL
  );
}

export default paragraph;
