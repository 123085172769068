import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const Content: React.FC<Props> = ({ children }) => <Outer>{children}</Outer>;

export default Content;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;
