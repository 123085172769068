import { useMemo } from 'react';
import { useTsdElementIpcClasses, useTsdElementSynonyms } from '.';
import { QueryBase, QueryBaseResult } from 'types';

const useQueryBuilderBase = (
  tsdElementId: string,
  tsdNodeId?: string
): QueryBaseResult => {
  const ipcClassesQuery = useTsdElementIpcClasses(tsdElementId, tsdNodeId);
  const synonymsQuery = useTsdElementSynonyms(tsdElementId, tsdNodeId);

  const queryBase = useMemo((): QueryBase | undefined => {
    if (!ipcClassesQuery.data || !synonymsQuery.data) {
      return undefined;
    }

    return {
      classes: ipcClassesQuery.data.map(
        ({ ipcClass, id, isBaseAttribute }) => ({
          id,
          isBaseAttribute,
          value: ipcClass.code,
        })
      ),
      synonyms: synonymsQuery.data.map(({ id, value, isBaseAttribute }) => ({
        isBaseAttribute,
        id,
        value,
      })),
    };
  }, [ipcClassesQuery.data, synonymsQuery.data]);

  const isLoading: boolean =
    ipcClassesQuery.isLoading || synonymsQuery.isLoading;

  const isError: boolean = ipcClassesQuery.isError || synonymsQuery.isError;

  return { isLoading, isError, queryBase };
};

export default useQueryBuilderBase;
