import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import useTranslation from '../../translations';
import CountryFlag from '../CountryFlag';
import { Language } from '../../types';

const { Option } = Select;

interface Props {
  value: string;
  options?: Language[];
  disabledOptions?: Language[];
  disabled?: boolean;
  onChange: (value: string) => void;
}

const LanguagePicker: React.FC<Props> = ({
  value,
  options = [],
  disabled = false,
  disabledOptions = [],
  onChange,
}) => {
  const t = useTranslation();

  const handleChange = (newValue: string): void => {
    onChange(newValue);
  };

  return (
    <Select value={value} onChange={handleChange} disabled={disabled}>
      {options.map((opt) => (
        <Option key={opt} value={opt} disabled={disabledOptions.includes(opt)}>
          <Flex>
            <CountryFlag countryCode={opt} />
            <Label>{t(`LANGUAGE.${opt.toUpperCase()}`)}</Label>
          </Flex>
        </Option>
      ))}
    </Select>
  );
};

export default LanguagePicker;

const Label = styled.span`
  margin-left: 5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
