import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { clamp } from './utils';
import { DEFAULT_MAX_SIZE, DEFAULT_MIN_SIZE } from './defaults';

function setSize(el: HTMLElement, width: number, height: number): void {
  el.style.width = Math.round(width) + 'px';
  el.style.height = Math.round(height) + 'px';
}

export const ResizeDirection = {
  top_right: setSize,
  bottom_right: setSize,
  bottom_left: setSize,
  top_left: setSize,
};

export type Direction = keyof typeof ResizeDirection;

interface Props {
  direction: Direction;
  height: number;
  onResizeEnd: (w: number, height: number) => void;
  width: number;
}

const ImageResizeBoxControl: React.FC<Props> = ({
  direction,
  height,
  onResizeEnd,
  width,
}) => {
  const activeRef = useRef(false);
  const elRef = useRef<HTMLSpanElement | null>(null);
  const hRef = useRef('');
  const rafIDRef = useRef<null | number>(0);
  const wRef = useRef('');
  const x1Ref = useRef(0);
  const x2Ref = useRef(0);
  const y1Ref = useRef(0);
  const y2Ref = useRef(0);
  const wwRef = useRef(0);
  const hhRef = useRef(0);

  useEffect(
    () => () => {
      _end();
    },
    []
  );

  const _syncSize = (): void => {
    const el = elRef?.current;
    const parent: HTMLSpanElement | null = elRef.current?.parentElement || null;

    if (!activeRef?.current || !el || !parent) {
      return;
    }

    const dx =
      (x2Ref.current - x1Ref.current) * (/left/.test(direction) ? -1 : 1);
    const dy =
      (y2Ref.current - y1Ref.current) * (/top/.test(direction) ? -1 : 1);

    const resizeFunction = ResizeDirection[direction];
    const aspect = width / height;
    let ww = clamp(DEFAULT_MIN_SIZE, width + Math.round(dx), DEFAULT_MAX_SIZE);
    let hh = clamp(DEFAULT_MIN_SIZE, height + Math.round(dy), DEFAULT_MAX_SIZE);

    if (resizeFunction === setSize) {
      hh = Math.max(ww / aspect, DEFAULT_MIN_SIZE);
      ww = hh * aspect;
    }

    resizeFunction(parent, Math.round(ww), Math.round(hh));
    wwRef.current = ww;
    hhRef.current = hh;
  };

  const _start = (e: React.MouseEvent): void => {
    if (activeRef) {
      _end();
    }

    activeRef.current = true;

    const parent: HTMLSpanElement | null = elRef.current?.parentElement || null;
    if (!parent) {
      return;
    }
    parent?.classList.add(direction);

    x1Ref.current = e.clientX;
    y1Ref.current = e.clientY;
    x2Ref.current = x1Ref.current;
    y2Ref.current = y1Ref.current;
    wRef.current = parent.style.width;
    hRef.current = parent.style.height;
    wwRef.current = width;
    hhRef.current = height;

    document.addEventListener('mousemove', _onMouseMove, true);
    document.addEventListener('mouseup', _onMouseUp, true);
  };

  const _end = (): void => {
    if (!activeRef) {
      return;
    }
    const parent: HTMLSpanElement | null = elRef.current?.parentElement || null;
    if (!parent) {
      return;
    }
    activeRef.current = false;
    document.removeEventListener('mousemove', _onMouseMove, true);
    document.removeEventListener('mouseup', _onMouseUp, true);

    parent.style.width = `${wRef}px`;
    parent.style.height = `${hRef}px`;
    parent.className = 'image-resize-box';

    rafIDRef?.current && cancelAnimationFrame(rafIDRef.current);
    rafIDRef.current = null;
  };

  const _onMouseDown = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    _end();
    _start(e);
  };

  const _onMouseMove = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    x2Ref.current = e.clientX;
    y2Ref.current = e.clientY;
    rafIDRef.current = requestAnimationFrame(_syncSize);
  };

  const _onMouseUp = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    x2Ref.current = e.clientX;
    y2Ref.current = e.clientY;
    const parent: HTMLSpanElement | null = elRef.current?.parentElement || null;
    if (!parent) {
      return;
    }
    parent.classList.remove(direction);

    _end();
    onResizeEnd(wwRef.current, hhRef.current);
  };

  const className = cx({
    'image-resize-box-control': true,
    [direction]: true,
  });

  return <span className={className} ref={elRef} onMouseDown={_onMouseDown} />;
};

export default ImageResizeBoxControl;
