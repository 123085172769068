import React from 'react';
import styled from 'styled-components';
import IpcClassListItem from './IpcClassListItem';
import { T } from '@ipos/shared';
import useTranslation from 'translations';

interface Props {
  data: T.TsdElementIpcClass[];
  onRemoveRequest: (id: string) => void;
}

const IpcClassList: React.FC<Props> = ({ data, onRemoveRequest }) => {
  const t = useTranslation();

  const checkForWarnings = (
    data: T.TsdElementIpcClass[],
    item: T.TsdElementIpcClass
  ): string[] => {
    const warnings: string[] = [];
    // check if class is doubled
    if (
      data.find(
        (d) =>
          d.id != item.id &&
          d.isBaseAttribute &&
          d.ipcClass.code == item.ipcClass.code
      ) != null
    ) {
      warnings.push(t('ATTRIBUTES.WARNINGS.DOUBLE_CLASS_ENTRY'));
    }
    return warnings;
  };

  return (
    <Outer>
      {data.map((d) => (
        <IpcClassListItem
          key={d.id}
          data={d}
          warnings={checkForWarnings(data, d)}
          onRemoveRequest={onRemoveRequest}
        />
      ))}
    </Outer>
  );
};

export default IpcClassList;

const Outer = styled.div`
  margin-top: 20px;
  flex: 1;
  overflow: hidden auto;
`;
