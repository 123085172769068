/* eslint-disable @typescript-eslint/no-explicit-any */
class WebFontLoader {
  _implementation: any = null;

  setImplementation(impl: any): void {
    this._implementation = impl;
  }

  load(params: any): void {
    const impl = this._implementation;
    if (impl?.load !== undefined) {
      impl?.load?.(params);
    } else {
      console.warn('Method WebFontLoader.load does not have an implementation');
    }
  }
}

const loader = new WebFontLoader();

export default loader;
