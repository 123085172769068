import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { QueryKey } from '../queries';

const useDocumentOcrProcess = (
  documentId: string,
  options?: UseQueryOptions<T.DocumentOcrProcess, AxiosError>
): UseQueryResult<T.DocumentOcrProcess, AxiosError> =>
  useQuery<T.DocumentOcrProcess, AxiosError>(
    [QueryKey.DocumentOcrProcess, documentId],
    () => Api.document.getPdfOcrProcess(documentId),
    options
  );

export default useDocumentOcrProcess;
