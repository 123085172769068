import React, { useEffect, useRef, useState } from 'react';

enum CursorType {
  GRAB = 'grab',
  GRABBING = 'grabbing',
  DEFAULT = '',
}

interface ScrollPosition {
  x: number;
  y: number;
  left: number;
  top: number;
}

interface Props {
  ref: React.RefObject<HTMLElement>;
  enabled: boolean;
}

type Hook = (props: Props) => {
  isScrollingByDrag: boolean;
};

const DRAG_ACTIVATION_DELAY_MS = 500;

const useDragToScroll: Hook = ({ ref, enabled = false }) => {
  const [isScrollingByDrag, setIsScrollingByDrag] = useState(false);
  const [activated, setActivated] = useState(false);
  const mouseIsDown = useRef(false);

  const pos: ScrollPosition = { x: 0, y: 0, left: 0, top: 0 };

  const changeCursor = (cursorType: CursorType) => {
    if (!ref.current) {
      return;
    }

    ref.current.style.cursor = cursorType;

    const childElements = ref.current.querySelectorAll<HTMLElement>('*');

    childElements.forEach((element) => {
      element.style.cursor = cursorType;
    });
  };

  changeCursor(activated ? CursorType.GRABBING : CursorType.DEFAULT);

  const disableUserSelect = () => {
    if (!ref.current) {
      return;
    }
    ref.current.style.userSelect = 'none';
    const childElements = ref.current.querySelectorAll<HTMLElement>('*');
    childElements.forEach((element) => {
      element.style.userSelect = 'none';
    });
  };

  const mouseDownHandler = function (e: MouseEvent) {
    if (!ref.current || !enabled) {
      return;
    }

    mouseIsDown.current = true;

    setTimeout(() => {
      if (mouseIsDown.current === true && ref.current) {
        setActivated(true);
        pos.left = ref.current.scrollLeft;
        pos.top = ref.current.scrollTop;
        pos.x = e.clientX;
        pos.y = e.clientY;

        ref.current.addEventListener('mousemove', mouseMoveHandler);
        ref.current.addEventListener('mouseup', mouseUpHandler);
      }
    }, DRAG_ACTIVATION_DELAY_MS);
  };

  const mouseMoveHandler = function (e: MouseEvent) {
    if (!ref.current || !mouseIsDown.current) {
      return;
    }

    setIsScrollingByDrag(true);

    // how far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // scroll the element
    ref.current.scrollTop = pos.top - dy;
    ref.current.scrollLeft = pos.left - dx;

    // store latest position of the scroll
    pos.top = ref.current.scrollTop;
    pos.left = ref.current.scrollLeft;
    pos.x = e.clientX;
    pos.y = e.clientY;
  };

  const mouseUpHandler = () => {
    mouseIsDown.current = false;
    setActivated(false);

    if (!ref.current) {
      return;
    }

    setTimeout(() => {
      setIsScrollingByDrag(false);
    }, 500);

    ref.current.removeEventListener('mousemove', mouseMoveHandler);
  };

  useEffect(() => {
    if (!enabled) {
      return;
    }

    disableUserSelect();

    ref.current?.addEventListener('mousedown', mouseDownHandler);
    ref.current?.addEventListener('mouseup', mouseUpHandler);

    return () => {
      ref.current?.removeEventListener('mousedown', mouseDownHandler);
      ref.current?.removeEventListener('mouseup', mouseUpHandler);
      ref.current?.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [enabled]);

  return {
    isScrollingByDrag,
  };
};

export default useDragToScroll;
