import React from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { Action, QueryBaseItem, QuerySegment } from 'types';
import { useQueryContext } from 'components/TsdElement/Attributes/Query/QueryContext';

interface Props {
  item: QueryBaseItem;
  segment: QuerySegment;
  selected: boolean;
  children: React.ReactNode;
}

const QueryBuilderSegmentListItem: React.FC<Props> = ({
  segment,
  item,
  selected,
  children,
}) => {
  const { dispatch } = useQueryContext();
  const { id, value, isBaseAttribute } = item;

  const handleChange = () => {
    dispatch({
      type: Action.SelectChangeRequest,
      payload: {
        id,
        segment,
        name: value,
      },
    });
  };

  return (
    <Outer isBaseAttribute={isBaseAttribute}>
      <StyledSwitch size="small" checked={selected} onChange={handleChange} />
      {children}
    </Outer>
  );
};

export default QueryBuilderSegmentListItem;

const Outer = styled.div<{ isBaseAttribute: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.primary300};
  &:nth-child(2) {
    border-top: none;
  }
  background-color: ${(props) => {
    switch (true) {
      case !props.isBaseAttribute:
        return 'rgba(0, 125, 0, 0.15)';
      default:
        return 'transparent';
    }
  }};
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;
