/* eslint-disable @typescript-eslint/no-explicit-any */
import url from 'url';
import Color from 'color';
import * as Sentry from '@sentry/react';
import {
  NodeType,
  MarkType,
  Node,
  ResolvedPos,
  Mark,
  DOMParser as ProseDOMParser,
  Slice,
} from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { EditorState, Selection } from 'prosemirror-state';
import {
  chainCommands,
  createParagraphNear,
  newlineInCode,
  splitBlockKeepMarks,
} from 'prosemirror-commands';
import { TFunction } from 'i18next';
import { message } from 'antd';
import { ReferenceOption } from '../../types';
import { Api } from '../../..';
import { FONT_PT_SIZES } from './toolbar/renderers/FontSizeCommandMenuButton';
import { schema } from './schema/schema';
import { MARK_FONT_TYPE } from './schema/marks/markNames';
import { FONT_TYPE_NAME_DEFAULT } from './toolbar/util/findActiveFontType';

export function parseDoc(
  doc: any,
  editorDocumentId: string,
  t: TFunction
): { parsedDoc?: Node; status: 'ok' | 'error' } {
  let parsedDoc;
  try {
    parsedDoc = doc ? schema.nodeFromJSON(doc) : undefined;
  } catch (e) {
    if (e instanceof RangeError) {
      console.warn(e.message);
      const tags: Record<string, any> = {
        type: 'editor-load-failure',
      };
      Sentry.captureException(new Error('Loading document failed'), {
        tags,
        extra: {
          docId: editorDocumentId,
          message: e.message,
        },
      });
    }
    const value = `<p>${t('RICH_TEXT.INVALID_DOCUMENT_STRUCTURE')}</p>`;
    const dom = new DOMParser().parseFromString(value, 'text/html');
    const parsedDoc = ProseDOMParser.fromSchema(schema).parse(dom);
    return { parsedDoc, status: 'error' };
  }
  // sanity Check
  try {
    parsedDoc?.check();
  } catch (e) {
    if (e instanceof Error) {
      console.warn(e.message);
    }
  }

  return { parsedDoc, status: 'ok' };
}

export function canInsert(state: EditorState, nodeType: NodeType) {
  try {
    const $from = state.selection.$from;
    for (let d = $from.depth; d >= 0; d--) {
      const index = $from.index(d);
      if ($from.node(d).canReplaceWith(index, index, nodeType)) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

export const enter = chainCommands(
  newlineInCode,
  createParagraphNear,
  splitBlockKeepMarks
);

export const findParentNodeClosestToPos = (
  $pos: ResolvedPos,
  predicate: (node: Node) => boolean
) => {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    if (predicate(node)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      };
    }
  }
};

export const findParentNode =
  (predicate: (node: Node) => boolean) =>
  ({ $from }: { $from: ResolvedPos }) =>
    findParentNodeClosestToPos($from, predicate);

export const hasParentNode =
  (predicate: (node: Node) => boolean) => (selection: Selection) =>
    !!findParentNode(predicate)(selection);

export const findParentNodeOfType =
  (nodeType: NodeType) => (selection: Selection) =>
    findParentNode((node) => equalNodeType(nodeType, node))(selection);

export const equalNodeType = (nodeType: NodeType, node: Node) =>
  (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
  node.type === nodeType;

const RGBA_PATTERN = /^rgba/i;
const RGBA_TRANSPARENT = 'rgba(0,0,0,0)';

const ColorMaping: Record<string, string> = {
  transparent: RGBA_TRANSPARENT,
  inherit: '',
};

export function isTransparent(source: any): boolean {
  if (!source) {
    return true;
  }
  const hex = toCSSColor(source);
  return !hex || hex === RGBA_TRANSPARENT;
}

export function toCSSColor(source: any): string {
  if (!source) {
    return '';
  }
  if (source in ColorMaping) {
    return ColorMaping[source];
  }

  if (source && RGBA_PATTERN.test(source)) {
    const color = Color(source);
    if (color.alpha() === 0) {
      ColorMaping[source] = RGBA_TRANSPARENT;
      return RGBA_TRANSPARENT;
    }
    const rgba = color.toString();
    ColorMaping[source] = rgba.toString();
    return rgba;
  }

  let hex = '';
  try {
    hex = Color(source).hex().toLowerCase();
    ColorMaping[source] = hex;
  } catch (ex) {
    console.warn('unable to convert to hex', source);
    ColorMaping[source] = '';
  }
  return hex;
}

const SIZE_PATTERN = /([\d\.]+)(px|pt)/i;

export const PX_TO_PT_RATIO = 0.7518796992481203; // 1 / 1.33.
export const PT_TO_PX_RATIO = 1.33;

export default function convertToCSSPTValue(styleValue: string): number {
  const matches = styleValue.match(SIZE_PATTERN);
  if (!matches) {
    return 0;
  }
  let value = parseFloat(matches[1]);
  const unit = matches[2];
  if (!value || !unit) {
    return 0;
  }
  if (unit === 'px') {
    value = PX_TO_PT_RATIO * value;
  }
  return value;
}

export function toClosestFontPtSize(styleValue: string): number {
  const originalPTValue = convertToCSSPTValue(styleValue);

  if (FONT_PT_SIZES.includes(originalPTValue)) {
    return originalPTValue;
  }

  return FONT_PT_SIZES.reduce(
    (prev, curr) =>
      Math.abs(curr - originalPTValue) < Math.abs(prev - originalPTValue)
        ? curr
        : prev,
    Number.NEGATIVE_INFINITY
  );
}

export function isOffline(): boolean {
  if (window.navigator.hasOwnProperty('onLine')) {
    return !window.navigator.onLine;
  }
  return false;
}

const cached: Record<string, boolean> = {};

export function canUseCSSFont(fontName: string): Promise<boolean> {
  const doc: Document = document;

  if (cached.hasOwnProperty(fontName)) {
    return Promise.resolve(cached[fontName]);
  }

  if (
    !doc.fonts ||
    !doc.fonts.check ||
    !doc.fonts.ready ||
    !doc.fonts.status ||
    !doc.fonts.values
  ) {
    // Feature is not supported, install the CSS anyway
    // https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet/check#Browser_compatibility
    // TODO: Polyfill this.
    console.log('FontFaceSet is not supported');
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    // https://stackoverflow.com/questions/5680013/how-to-be-notified-once-a-web-font-has-loaded
    // All fonts in use by visible text have loaded.
    const check = () => {
      if (doc.fonts.status !== 'loaded') {
        setTimeout(check, 350);
        return;
      }
      // Do not use `doc.fonts.check()` because it may return falsey result.
      const fontFaces = Array.from(doc.fonts.values());
      const matched = fontFaces.find((ff) => ff.family === fontName);
      const result = !!matched;
      cached[fontName] = result;
      resolve(result);
    };
    doc.fonts.ready.then(check);
  });
}

const addedElements = new Map();

function createElement<T extends HTMLElement>(
  tag: string,
  attrs: { [key in keyof T]?: any }
): HTMLElement {
  const el: HTMLElement = document.createElement(tag);
  Object.keys(attrs).forEach((key) => {
    if (key === 'className' && attrs[key]) {
      el[key] = attrs[key];
    } else {
      el.setAttribute(key, attrs[key as keyof HTMLElement]);
    }
  });
  return el;
}

export function injectStyleSheet(urlStr: string): void {
  const parsedURL = url.parse(urlStr);
  const { protocol } = parsedURL;
  const protocolPattern = /^(http:|https:)/;
  if (!protocolPattern.test(protocol || '')) {
    if (protocolPattern.test(window.location.protocol)) {
      parsedURL.protocol = window.location.protocol;
    } else {
      parsedURL.protocol = 'http:';
    }
  }
  const href = url.format(parsedURL);
  if (addedElements.has(href)) {
    return;
  }
  const el = createElement<HTMLLinkElement>('link', {
    crossOrigin: 'anonymous',
    href,
    rel: 'stylesheet',
  });
  addedElements.set(href, el);
  const root = document.head || document.documentElement || document.body;
  root && root.appendChild(el);
}

type Result = {
  mark: Mark | null;
  from: {
    node: Node | null;
    pos: number;
  };
  to: {
    node: Node | null;
    pos: number;
  };
};

export function findNodesWithSameMark(
  doc: Node,
  from: number,
  to: number,
  markType: MarkType
): Result | null {
  let ii = from;
  const finder = (mark: Mark) => mark.type === markType;
  let firstMark = null;
  let fromNode = null;
  let toNode = null;

  while (ii <= to) {
    const node = doc.nodeAt(ii);
    if (!node || !node.marks) {
      return null;
    }
    const mark = node.marks.find(finder);
    if (!mark) {
      return null;
    }
    if (firstMark && mark !== firstMark) {
      return null;
    }
    fromNode = fromNode || node;
    firstMark = firstMark || mark;
    toNode = node;
    ii++;
  }

  let fromPos = from;
  let toPos = to;

  let jj = 0;
  ii = from - 1;
  while (ii > jj) {
    const node = doc.nodeAt(ii);
    const mark = node && node.marks.find(finder);
    if (!mark || mark !== firstMark) {
      break;
    }
    fromPos = ii;
    fromNode = node;
    ii--;
  }

  ii = to + 1;
  jj = doc.nodeSize - 2;
  while (ii < jj) {
    const node = doc.nodeAt(ii);
    const mark = node && node.marks.find(finder);
    if (!mark || mark !== firstMark) {
      break;
    }
    toPos = ii;
    toNode = node;
    ii++;
  }

  return {
    mark: firstMark,
    from: {
      node: fromNode,
      pos: fromPos,
    },
    to: {
      node: toNode,
      pos: toPos,
    },
  };
}

/**
 * It detects the most used font type in the content
 */
export const detectMostUsedFont = (editorView: EditorView) => {
  const fontList: Record<string, number> = {};

  const detectFont = (node: Node) => {
    if (!node.type.isText) {
      node.forEach((childNode) => {
        detectFont(childNode);
      });
      return;
    }

    const fontMark = node.marks.find((m) => m.type.name === MARK_FONT_TYPE);

    if (!fontMark) {
      if (fontList[FONT_TYPE_NAME_DEFAULT]) {
        fontList[FONT_TYPE_NAME_DEFAULT]++;
        return;
      }

      fontList[FONT_TYPE_NAME_DEFAULT] = 1;
      return;
    }

    if (fontList[fontMark.attrs.name]) {
      fontList[fontMark.attrs.name]++;
      return;
    }

    fontList[fontMark.attrs.name] = 1;
  };

  detectFont(editorView.state.doc);

  if (Object.keys(fontList).length === 0) {
    return FONT_TYPE_NAME_DEFAULT;
  }

  return Object.keys(fontList).reduce((a, b) =>
    fontList[a] > fontList[b] ? a : b
  );
};

export const getTextContentOfSlice = (slice: Slice) => {
  let textContent = '';
  slice.content.descendants((node) => {
    if (node.isText) {
      textContent += node.text;
    }
  });
  return textContent;
};

export const findChildNodesByType = (node: Node, type: string | string[]) => {
  const nodes: Node[] = [];

  const targetTypes = Array.isArray(type) ? type : [type];

  node.descendants((childNode) => {
    if (targetTypes.includes(childNode.type.name)) {
      nodes.push(childNode);
    }
  });

  return nodes;
};

export const parseHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return ProseDOMParser.fromSchema(schema).parse(doc);
};

export const REGEX_REFERENCE_PATTERN = /^(.+?)\s*\(\s*(\d+)\s*\)$/;

export const transformReferenceMenuOptions = (menuOptions: ReferenceOption[]) =>
  menuOptions.map((item) => {
    const matches = item.text.match(REGEX_REFERENCE_PATTERN);
    if (matches && matches.length === 3) {
      return { label: matches[1], value: +matches[2] };
    }
    return { label: item.text, value: 0, id: item.id };
  });

export const deleteClaim = async (
  editorContentId: string,
  claimNumber: number
) => {
  try {
    const response = await Api.oa.deleteEditorClaims(
      editorContentId,
      `${claimNumber}`
    );
    return response;
  } catch (error) {
    message.error('Error deleting claim');
    console.error('Error deleting claim:', error);
    throw error;
  }
};

export type ClaimPosition = 'above' | 'below';

export const insertNewClaim = async (
  editorContentId: string,
  claimNumber: number,
  editorView?: EditorView,
  position?: ClaimPosition
) => {
  if (!editorView) {
    return false;
  }
  const emptyClaimPart = editorView.state.schema.nodes.claimPart.createAndFill(
    {},
    editorView.state.schema.nodes.paragraph.create({})
  );
  if (!emptyClaimPart) {
    return false;
  }

  const newClaimNumber = position === 'above' ? claimNumber : claimNumber + 1;

  const newClaim = editorView.state.schema.nodes.claim.createAndFill(
    {
      claimNumber: newClaimNumber,
    },
    [emptyClaimPart]
  );
  if (!newClaim) {
    return false;
  }
  try {
    const response = await Api.oa.insertEditorClaims(editorContentId, newClaim);
    return response;
  } catch (error) {
    message.error('Error inserting new claim');
    console.error('Error inserting new claim:', error);
    throw error;
  }
};

export async function getBase64Image(img: HTMLImageElement): Promise<string> {
  return new Promise((resolve, reject) => {
    const maxSizeInMB = 4;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const width = img.width;
    const height = img.height;
    const aspectRatio = width / height;
    const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
    const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
    canvas.width = newWidth;
    canvas.height = newHeight;
    if (ctx) {
      const quality = 0.8;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      const dataURL = canvas.toDataURL('image/png', quality);
      resolve(dataURL);
    } else {
      reject(new Error('Canvas context not available'));
    }
  });
}

export const processImagesAndCopy = async (
  div: HTMLDivElement,
  text: string
) => {
  try {
    const images = div.querySelectorAll('img');
    const base64Promises = Array.from(images).map(async (img) => {
      const base64 = await getBase64Image(img as HTMLImageElement);
      img.src = base64;
    });

    await Promise.all(base64Promises);

    const html = div.innerHTML;

    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([text], { type: 'text/plain' }),
      }),
    ]);

    message.success('Copied to clipboard');
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

export const splitCamelCase = (str: string): string =>
  str.replace(/([a-z])([A-Z])/g, '$1 $2');
