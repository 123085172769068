import { fromHTMlElement } from './rects';

export default function isElementFullyVisible(el: HTMLElement | null): boolean {
  if (!el) {
    return false;
  }
  const { x, y, w, h } = fromHTMlElement(el);
  // Only checks the top-left point.
  const nwEl = w && h ? el.ownerDocument.elementFromPoint(x + 1, y + 1) : null;

  if (!nwEl) {
    return false;
  }

  if (nwEl === el) {
    return true;
  }

  return el.contains(nwEl);
}
