/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node as PMNode } from 'prosemirror-model';
import { Decoration, EditorView, NodeView } from 'prosemirror-view';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import { isElementXPercentInViewport } from './utils';

export type NodeViewProps = {
  selected: boolean;
  id: string;
  editorDocumentId?: string;
};

const SELECTED_NODE_CLASS_NAME = 'ProseMirror-selectednode';
interface ReactNodeViewContext {
  node: PMNode;
  view: EditorView;
  getPos: () => number | undefined;
  decorations: readonly Decoration[];
}

const ReactNodeViewContext = React.createContext<Partial<ReactNodeViewContext>>(
  {
    node: undefined,
    view: undefined,
    getPos: undefined,
    decorations: undefined,
  }
);

class ReactNodeView<T extends Record<string, unknown>> implements NodeView {
  componentRef: React.RefObject<HTMLDivElement>;
  component: React.FC<any>;
  updateState: React.Dispatch<React.SetStateAction<undefined>>;
  node: PMNode;
  view: EditorView;
  getPos: () => number | undefined;
  decorations: readonly Decoration[];
  _selected: null | boolean = false;
  dom: HTMLElement;
  id: string;
  visible = false;
  initialRender = false;
  portal: React.ReactPortal | null = null;
  rest: T;
  editorDocumentId?: string;
  contentDOM?: HTMLElement;
  removeScrollListener?: () => void;

  constructor(
    node: PMNode,
    view: EditorView,
    getPos: () => number | undefined,
    decorations: readonly Decoration[],
    component: React.FC<any>,
    rest: T,
    editorDocumentId?: string
  ) {
    this.dom = document.createElement('div');
    this.node = node;
    this.dom.setAttribute('nodeId', node.attrs.nodeId);
    this.id = uuid();
    this.view = view;
    this.getPos = getPos;
    this.decorations = decorations;
    this.component = component;
    this.updateState = () => {
      console.warn('empty');
    };
    this.componentRef = React.createRef();
    this.removeScrollListener = undefined;
    this.editorDocumentId = editorDocumentId;
    this.rest = rest;
  }

  private checkIfVisible = () => {
    const visible = isElementXPercentInViewport(this.dom, 10);
    if (visible) {
      this.visible = visible;
      // @ts-ignore
      this.updateState({});
      this.initialRender = true;
    }
  };

  onScroll = () => {
    if (this.dom && !this.visible && !this.initialRender) {
      this.checkIfVisible();
    }
  };

  startListeningForScroll() {
    if (this.dom) {
      let iteratorNode: HTMLElement | null = this.dom;
      while (iteratorNode) {
        iteratorNode = iteratorNode.parentNode as HTMLElement;
        if (iteratorNode?.classList.contains('ProseMirror')) {
          break;
        }
      }
      if (iteratorNode) {
        iteratorNode.addEventListener('scroll', this.onScroll);
        this.removeScrollListener = () => {
          iteratorNode?.removeEventListener('scroll', this.onScroll);
        };
      }
    }
  }

  init() {
    this.dom = document.createElement('div');
    this.dom.classList.add('image-view');

    if (!this.node.isLeaf) {
      this.contentDOM = document.createElement('div');
      this.contentDOM.classList.add('ProseMirror__contentDOM');
      this.dom.appendChild(this.contentDOM);
    }
    this.portal = this.renderPortal(this.dom);
    if (this.node.attrs.align) {
      this.dom.classList.add(`align-${this.node.attrs.align}`);
    }
    return {
      nodeView: this,
      portal: this.portal,
    };
  }
  selectNode() {
    this._selected = true;
    this.dom?.classList.add(SELECTED_NODE_CLASS_NAME);
    // @ts-ignore
    this.updateState?.({});
  }

  deselectNode() {
    this._selected = false;
    this.dom?.classList.remove(SELECTED_NODE_CLASS_NAME);
    // @ts-ignore
    this.updateState?.({});
  }

  renderPortal(container: HTMLElement) {
    const Component: React.FC = (props) => {
      if (this.dom.parentNode && !this.removeScrollListener) {
        this.startListeningForScroll();
      }
      const [, updateState] = React.useState();
      const [componentRef, setComponentRef] = useState<HTMLDivElement | null>(
        null
      );
      // @ts-ignore
      this.updateState = React.useCallback(() => updateState({}), []);
      useEffect(() => {
        if (componentRef != null && this.contentDOM != null) {
          if (!this.node.isLeaf) {
            componentRef.firstChild?.appendChild(this.contentDOM);
          }
        }
        if (componentRef) {
          this.checkIfVisible();
        }
      }, [componentRef]);

      const content = useMemo(() => {
        if (!this.visible && this.rest.lazyLoad) {
          return <div />;
        } else {
          return (
            <this.component
              {...props}
              selected={this._selected || false}
              id={this.id}
              editorDocumentId={this.editorDocumentId}
              {...this.rest}
            />
          );
        }
      }, [
        this._selected,
        this.visible,
        this.editorDocumentId,
        this.rest,
        this.id,
      ]);

      return (
        <div ref={setComponentRef} className="ProseMirror__reactComponent">
          <ReactNodeViewContext.Provider
            value={{
              node: this.node,
              view: this.view,
              getPos: this.getPos,
              decorations: this.decorations,
            }}
          >
            {content}
          </ReactNodeViewContext.Provider>
        </div>
      );
    };

    return ReactDOM.createPortal(<Component />, container, this.id);
  }

  update(node: PMNode) {
    if (node.type !== this.node.type) {
      return false;
    }
    this.node = node;
    const { align } = node.attrs;
    let className = 'image-view';
    if (align) {
      className += ' align-' + align;
    }
    this.dom.className = className;
    // @ts-ignore
    this.updateState?.();
    return true;
  }

  destroy() {
    this._selected = false;
    this.contentDOM = undefined;
  }
}

interface CreateReactNodeView extends ReactNodeViewContext {
  component: React.FC<any>;
  onCreatePortal: (portal: any) => void;
  [key: string]: any;
  editorDocumentId?: string;
}

export const createReactNodeView = ({
  node,
  view,
  getPos,
  decorations,
  component,
  onCreatePortal,
  editorDocumentId,
  ...rest
}: CreateReactNodeView) => {
  const reactNodeView = new ReactNodeView(
    node,
    view,
    getPos,
    decorations,
    component,
    rest,
    editorDocumentId
  );
  const { nodeView, portal } = reactNodeView.init();
  onCreatePortal(portal);

  return nodeView;
};
export const useReactNodeView = () => useContext(ReactNodeViewContext);

export default ReactNodeView;
