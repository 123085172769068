import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import TutorialsTooltip from './TutorialsTooltip';

interface Props {
  tags: string[];
}

const TutorialsTags: React.FC<Props> = ({ tags }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const measureItemRef = useRef<HTMLDivElement | null>(null);

  const [sliceIndex, setSliceIndex] = useState(0);

  // TODO: Extend the algorithm to optmize tag order so most space is filled when line is overflowing
  useEffect(() => {
    const container = containerRef.current;
    const measureItem = measureItemRef.current;
    if (!container || !measureItem) {
      return;
    }

    const containerWidth = container.clientWidth;
    let totalWidth = 0;
    let count = 0;

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      measureItem.innerText = tag;
      const isLastItem = i === tags.length - 1;
      const extraContent = isLastItem ? 0 : 14 + 8; // account for extra content: dot (8px) and gaps (2 x 7px)
      const itemWidth = measureItem.offsetWidth + extraContent;

      totalWidth += itemWidth;

      if (
        totalWidth + 35 <= containerWidth || // account for count indicator and gap
        (isLastItem && totalWidth <= containerWidth)
      ) {
        count++;
      } else {
        break;
      }
    }

    setSliceIndex(count);
  }, [tags]);

  const visibleItems = tags.slice(0, sliceIndex);
  const invisibleItems = tags.slice(sliceIndex);
  const hiddenCount = invisibleItems.length;
  const hasHiddenTags = hiddenCount > 0;

  return (
    <Outer ref={containerRef}>
      {visibleItems.map((tag, index, arr) => (
        <React.Fragment key={tag}>
          <Text>{tag}</Text>
          {(index < arr.length - 1 || hasHiddenTags) && <Dot>•</Dot>}
        </React.Fragment>
      ))}
      {hasHiddenTags && (
        <TutorialsTooltip
          title={invisibleItems.join('\n')}
          placement="right"
          style={{ whiteSpace: 'pre' }}
        >
          <Text>+{hiddenCount}</Text>
        </TutorialsTooltip>
      )}
      <MeasureItem ref={measureItemRef} />
    </Outer>
  );
};

export default TutorialsTags;

const Outer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  gap: 7px;
`;

const Text = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white87};
`;

const Dot = styled.div`
  width: 8px;
  font-size: 22px;
  font-weight: 500;
  line-height: 17px;
  color: ${(props) => props.theme.colors.white40};
`;

const MeasureItem = styled.div`
  position: absolute;
  z-index: -10;
  visibility: hidden;
  top: 0;
  left: 0;
  font-size: 11px;
  font-weight: 500;
`;
