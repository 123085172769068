import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Popover, PopoverPosition } from 'react-tiny-popover';
import { message } from 'antd';
import { PrimaryButton } from '../../Button';
import useTranslation from '../../../translations';
import { useUpdateUserSettings } from '../../../mutations';
import { UserSettings } from '../../../types';
import BasicDropdownItem, { BasicDropdownConfig } from './BasicDropdownItem';
import { T } from '@ipos/shared';

interface Props {
  items: BasicDropdownConfig[];
  triggerStyle?: CSSProperties;
  containerStyle?: Partial<CSSStyleDeclaration>;
  triggerRender?: (isActive: boolean, onClick: () => void) => void;
  popoverPosition?: PopoverPosition[];
  settings?: UserSettings;
}

const BasicDropdown: React.FC<Props> = ({
  items,
  triggerStyle,
  containerStyle,
  triggerRender,
  popoverPosition = ['bottom'],
  settings,
}) => {
  const t = useTranslation();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [triggerWidth, setTriggerWidth] = useState(0);
  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  const onUpdateSuccess = () => {
    message.success(t('USER_SETTINGS.SETTINGS_UPDATED'));
  };
  const { mutate: updateUserSettings } = useUpdateUserSettings(onUpdateSuccess);

  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const handleItemClick = (option: BasicDropdownConfig) => {
    const payload = {
      ...settings,
      preferredGlossary: option.value as T.PreferredGlossary,
    };
    setSelectedOption(option.title);
    updateUserSettings(payload);
    closePopover();
  };

  useEffect(() => {
    if (triggerButtonRef.current) {
      setTriggerWidth(triggerButtonRef.current?.offsetWidth);
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    const currentOption = items?.find(
      (item) => item.value === settings?.preferredGlossary
    );
    setSelectedOption(currentOption?.title || t('GLOSSARY.WITHOUT_GLOSSARY'));
  }, [items, settings]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={popoverPosition}
      align="start"
      containerStyle={{
        ...containerStyle,
        zIndex: '10000',
        width: `${triggerWidth || 0}px`,
      }}
      onClickOutside={closePopover}
      content={
        <Outer onClick={(e) => e.stopPropagation()}>
          {items.map((item) => (
            <BasicDropdownItem
              key={item.title}
              {...item}
              onClick={() => handleItemClick(item)}
            />
          ))}
        </Outer>
      }
    >
      {triggerRender?.(isPopoverOpen, togglePopover) || (
        <Trigger onClick={togglePopover} style={triggerStyle}>
          <StyledButton
            testId="dropdown-glossary"
            ref={triggerButtonRef}
            active={isPopoverOpen}
          >
            <ButtonContent>
              <ButtonText>{selectedOption}</ButtonText>
              <ArrowIcon active={isPopoverOpen} className="icn-chevron-down" />
            </ButtonContent>
          </StyledButton>
        </Trigger>
      )}
    </Popover>
  );
};

export default BasicDropdown;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 20px 0;
  border-radius: 12px;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
`;

const Trigger = styled.div`
  width: 100%;
`;
const StyledButton = styled(PrimaryButton)<{ active: boolean }>`
  width: 100%;
  min-width: 200px;
  padding: 10px 11px 10px 20px;
  background: transparent;
  border: none;
  color: ${(props) => props.theme.colors.white70};
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.primary200_30};
  transition: border 0.3s ease-in-out;
  border: 2px solid
    ${(props) => (props.active ? props.theme.colors.blue200 : 'transparent')};
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_30};
  }
`;

const ButtonContent = styled.div`
  width: 100%;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonText = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
`;

const ArrowIcon = styled.i<{ active: boolean }>`
  font-size: 20px;
  color: ${(props) => props.theme.colors.white70};
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;
`;
