import { Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import isElementFullyVisible from '../util/isElementFullyVisible';
import bindScrollHandler, { ScrollHandle } from '../util/bindScrollHandler';
import findActionableCell from '../util/findActionableCell';
import createPopUp, { PopUpHandle } from '../renderers/popup/createPopUp';
import { atAnchorTopRight } from '../renderers/popup/PopUpPosition';
import TableCellMenu from './TableCellMenu';

class TableCellTooltipView {
  _cellElement: null | HTMLElement = null;
  _popUp: PopUpHandle | null = null;
  _scrollHandle: ScrollHandle | null = null;

  constructor(editorView: EditorView) {
    this.update(editorView);
  }

  update(view: EditorView): void {
    const { state, editable } = view;

    const result = findActionableCell(state);

    if (!result || !editable) {
      this.destroy();
      return;
    }

    // These is screen coordinate.
    const domFound = view.domAtPos(result.pos + 1);
    if (!domFound) {
      this.destroy();
      return;
    }

    let cellEl: HTMLElement | null = domFound.node as HTMLElement;
    const popUp = this._popUp;
    const viewPops = {
      editorState: state,
      editorView: view,
    };

    if (cellEl && !isElementFullyVisible(cellEl)) {
      cellEl = null;
    }

    if (!cellEl) {
      // Closes the popup.
      popUp && popUp.close();
      this._cellElement = null;
    } else if (popUp && cellEl === this._cellElement) {
      // Updates the popup.
      popUp.update(viewPops);
    } else {
      // Creates a new popup.
      popUp && popUp.close();
      this._cellElement = cellEl as HTMLElement;
      this._popUp = createPopUp(TableCellMenu, viewPops, {
        anchor: cellEl,
        autoDismiss: false,
        onClose: this._onClose,
        position: atAnchorTopRight,
      });
      this._onOpen();
    }
  }

  destroy = (): void => {
    this._popUp && this._popUp.close();
    this._popUp = null;
  };

  _onOpen = (): void => {
    const cellEl = this._cellElement;
    if (!cellEl) {
      return;
    }
    this._scrollHandle = bindScrollHandler(cellEl, this._onScroll);
  };

  _onClose = (): void => {
    this._popUp = null;
    this._scrollHandle && this._scrollHandle.dispose();
    this._scrollHandle = null;
  };

  _onScroll = (): void => {
    const popUp = this._popUp;
    const cellEl = this._cellElement;
    if (!popUp || !cellEl) {
      return;
    }
    if (!isElementFullyVisible(cellEl)) {
      popUp.close();
    }
  };
}

// https://prosemirror.net/examples/tooltip/
const SPEC = {
  view(editorView: EditorView) {
    return new TableCellTooltipView(editorView);
  },
};

class TableCellMenuPlugin extends Plugin {
  constructor() {
    super(SPEC);
  }
}

export default TableCellMenuPlugin;
