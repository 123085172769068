import React, { useState } from 'react';
import { Modal } from 'antd';
import { Permissions } from '@ipos/shared';
import { DeleteTsdElementModal } from 'components/TsdElement';
import { MenuBarOption } from 'components/common';
import useTranslation from 'translations';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';

const { WithPermission } = Permissions;

const DeleteTsdElementMenuOption: React.FC = () => {
  const t = useTranslation();
  const { tsdElement } = useTsdElementContext();
  const { frequency } = tsdElement;

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const showUsageNotification = () => {
    Modal.warning({
      title: t('DELETE_ELEMENT.IN_USE_TITLE'),
      content: t('DELETE_ELEMENT.IN_USE_CONTENT'),
      okText: t('ACTION.CLOSE'),
      okType: 'default',
      autoFocusButton: null,
      centered: true,
    });
  };

  const handleOptionClick = () => {
    if (frequency > 0) {
      showUsageNotification();
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModalRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdDelete}>
      <MenuBarOption
        onClick={handleOptionClick}
        iconClassName="icn-delete"
        title={t('DELETE_ELEMENT.TITLE')}
      />
      {isModalOpen && (
        <DeleteTsdElementModal
          tsdElement={tsdElement}
          isOpen={isModalOpen}
          onClose={handleCloseModalRequest}
        />
      )}
    </WithPermission>
  );
};

export default DeleteTsdElementMenuOption;
