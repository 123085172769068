/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Menu as MenuInner,
  SubMenu,
  MenuItem,
  MenuReposition,
  MenuViewScroll,
  MenuDirection,
  MenuAlign,
  MenuPosition,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/transitions/slide.css';
import '@szhsin/react-menu/dist/core.css';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import styled, { css, CSSObject } from 'styled-components';

export interface MenuConfig<T> {
  name: string;
  items?: (MenuEntry<T> | React.ReactNode)[];
  subMenu?: MenuConfig<T>[];
}

export interface MenuEntry<T> {
  label: React.ReactNode;
  disabled?: boolean;
  checked?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  value: T;
  onItemClick?: (value: T) => void;
}

function isMenuEntry<T>(
  entry: MenuEntry<T> | React.ReactNode
): entry is MenuEntry<T> {
  return (entry as MenuEntry<T>).value !== undefined;
}

type RenderProp<M, R = React.ReactNode> = R | ((modifiers: M) => R);

interface Props<T> {
  anchor:
    | RenderProp<
        Readonly<{ open: boolean }>,
        React.ReactElement<any, string | React.JSXElementConstructor<any>>
      >
    | React.ReactElement;
  menuConfig: MenuConfig<T>;
  position?: MenuPosition;
  reposition?: MenuReposition;
  viewScroll?: MenuViewScroll;
  direction?: MenuDirection;
  boundingBoxRef?: React.RefObject<HTMLElement>;
  closeMenu?: (open: boolean) => void;
  align?: MenuAlign;
  arrow?: boolean;
  portal?: boolean;
  transition?: boolean;
  style?: CSSObject;
  menuItemStyle?: CSSObject;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DropdownMenu = <T,>({
  menuConfig,
  anchor,
  arrow = false,
  transition = false,
  portal = false,
  position,
  reposition,
  boundingBoxRef,
  viewScroll,
  direction,
  align,
  style,
  menuItemStyle,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const renderMenuItems = useCallback(
    (m: MenuConfig<T>, key: string) =>
      m?.items?.map((d, index) => {
        if (isMenuEntry(d)) {
          return (
            <MenuItem
              disabled={d.disabled}
              checked={d.checked}
              onClick={() => d.onItemClick?.(d.value)}
              key={`${key}-${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                overflow: 'hidden',
                ...menuItemStyle,
              }}
            >
              {d.label}
              {d.children}
            </MenuItem>
          );
        }
        return d;
      }),
    []
  );

  const renderSubmenu = useCallback(
    (m: MenuConfig<T>, key: string) => (
      <SubMenu key={key} label={m.name}>
        {renderMenuItems(m, key)}
        {m.subMenu?.map((subMenu, index) =>
          renderSubmenu(subMenu, `${subMenu.name}-${key}-${index}`)
        )}
      </SubMenu>
    ),
    []
  );
  if (!menuConfig.subMenu?.length && menuConfig.items?.length) {
    return (
      <Menu
        menuButton={anchor}
        arrow={arrow}
        portal={portal}
        boundingBoxRef={boundingBoxRef}
        position={position}
        reposition={reposition}
        transition={transition}
        viewScroll={viewScroll}
        direction={direction}
        align={align}
        style={style}
      >
        {renderMenuItems(menuConfig, 'first-level')}
      </Menu>
    );
  }
  return (
    <Menu
      menuButton={anchor}
      arrow={arrow}
      portal={portal}
      position={position}
      reposition={reposition}
      transition={transition}
      viewScroll={viewScroll}
      direction={direction}
      align={align}
      style={style}
    >
      {menuConfig.items?.length
        ? renderSubmenu(menuConfig, 'first-level')
        : menuConfig.subMenu?.map((subMenu, index) =>
            renderSubmenu(subMenu, `${subMenu.name}-${index}`)
          )}
    </Menu>
  );
};

const Menu = styled(MenuInner)<{ style?: CSSObject }>`
  ${menuSelector.stateOpen} {
    border-radius: 6px;
    width: 250px;
    z-index: 10000;
    padding: 8px;
    background: ${(props) => props.theme.colors.primary600};
    border: 1px solid ${(props) => props.theme.colors.white20};
    ${(props) =>
      css`
        ${props.style}
      `}
  }

  ${menuSelector.stateOpening} {
    background: ${(props) => props.theme.colors.primary600};
  }

  ${menuSelector.stateClosing} {
    background: ${(props) => props.theme.colors.primary600};
  }

  ${menuItemSelector.name} {
    padding: 8px;
    border-radius: 6px;
  }
  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      content: url('data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22chevron-right%22%20class%3D%22svg-inline--fa%20fa-chevron-right%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M285.476%20272.971L91.132%20467.314c-9.373%209.373-24.569%209.373-33.941%200l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505%20256%2034.484%20101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373%2024.569-9.373%2033.941%200L285.475%20239.03c9.373%209.372%209.373%2024.568.001%2033.941z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }
  ${menuItemSelector.hover} {
    background: ${(props) => props.theme.colors.primary800};
  }
`;

export default DropdownMenu;
