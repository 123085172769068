import React, { CSSProperties } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { ReactComponent as Epo } from '../../../assets/patentOffice/EPO.svg';
import WorldFlag from './WorldFlag';

interface Props {
  countryCode?: string;
  showLabel?: boolean;
  size?: number;
  style?: CSSProperties;
}

const preprocessCountryCode = (originalCode: string): string | null => {
  if (!originalCode) {
    return null;
  }

  const upperCased = originalCode.toUpperCase();

  switch (upperCased) {
    case 'CS':
      return 'CZ';
    case 'DA':
      return 'DK';
    case 'EL':
      return 'GR';
    case 'EP':
      return 'EU';
    case 'EN':
      return 'GB';
    case 'ET':
      return 'EE';
    case 'JA':
      return 'JP';
    case 'SV':
      return 'SE';
    case 'UK':
      return 'UA';
    case 'SL':
      return 'SI';
    case 'ZH':
      return 'Cn';
    case 'KO':
      return 'KR';
    case 'NB':
    case 'NN':
      return 'NO';
    default:
      return upperCased;
  }
};

const CountryFlag: React.FC<Props> = ({
  countryCode,
  showLabel,
  size = 24,
  style,
}) => {
  if (!countryCode) {
    return null;
  }
  const code = preprocessCountryCode(countryCode);

  let content;

  switch (true) {
    case code === 'PCT/EP':
    case code === 'EP':
      content = <Epo />;
      break;
    case code === 'WO':
      content = <WorldFlag />;
      break;
    case code != null:
      content = (
        <StyledReactCountryFlag
          countryCode={code as string}
          svg
          style={{
            width: '150%',
            height: '150%',
          }}
        />
      );
      break;
    default:
      content = <Fallback>?</Fallback>;
  }

  return (
    <Outer>
      <Flag $size={size} style={style}>
        <Inner>{content}</Inner>
      </Flag>
      {showLabel && <Label>{code}</Label>}
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Label = styled.div``;

const Flag = styled.div<{ $size: number }>`
  display: flex;
  flex-shrink: 0;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border: 1px solid ${(props) => props.theme.colors.white20};
  border-radius: 50%;
  position: relative;
`;

const Fallback = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary500};
`;

const Inner = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  overflow: hidden;
`;

const StyledReactCountryFlag = styled(ReactCountryFlag)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default CountryFlag;
