import React from 'react';
import styled from 'styled-components';
import { DocumentPickerItem } from '../types';
import useTranslation from '../../../translations';
import DocumentPickerDropdownItem from './DocumentPickerDropdownItem';

interface Props {
  items: DocumentPickerItem[];
  onItemCheck: (itemId: string, checked: boolean) => void;
  onItemOpenChange: (itemId: string, open: boolean) => void;
  onCheckAll: (checked: boolean) => void;
  disableItemsWithNoTextLayer: boolean;
}

const DocumentPickerDropdown: React.FC<Props> = ({
  items,
  onItemCheck,
  onItemOpenChange,
  onCheckAll,
  disableItemsWithNoTextLayer,
}) => {
  const t = useTranslation();

  return (
    <Outer onClick={(e) => e.stopPropagation()}>
      <Header>
        <HeaderButton onClick={() => onCheckAll(true)}>
          {t('ACTION.SELECT_ALL')}
        </HeaderButton>
        <HeaderButton onClick={() => onCheckAll(false)}>
          {t('ACTION.CLEAR')}
        </HeaderButton>
      </Header>
      <Inner>
        {items.map((item) => (
          <DocumentPickerDropdownItem
            key={item.id}
            item={item}
            onCheck={onItemCheck}
            onOpenChange={onItemOpenChange}
            disableIfNoTextLayer={disableItemsWithNoTextLayer}
          />
        ))}
      </Inner>
    </Outer>
  );
};

export default DocumentPickerDropdown;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  border-radius: 12px;
  background: linear-gradient(135deg, #3b3f4f 0%, #31323f 100%);
  max-height: 600px;
  .dropdown-item__wrapper {
    &:hover {
      background: ${(props) => props.theme.colors.primary200_20};
      .dropdown-item__icon {
        color: ${(props) => props.theme.colors.white70};
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white10};
  padding: 0 20px;
  user-select: none;
`;

const Inner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 10px 0;
  overflow-y: auto;
`;

const HeaderButton = styled.div`
  cursor: pointer;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.blue300};
  &:hover {
    color: ${(props) => props.theme.colors.success};
  }
`;
