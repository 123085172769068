import { BaseDto } from './common';

export enum GlossaryType {
  Shared = 0,
  Group = 1,
}

export enum PreferredGlossary {
  NoGlossary = 'NoGlossary',
  CompanyGlossary = 'CompanyGlossary',
  PhilipaGlossary = 'PhilipaGlossary',
}

export interface GlossaryEntry {
  id: string;
  translations: Record<string, string>;
  preferredLanguage?: string;
}

export interface Glossary extends BaseDto {
  entriesLanguages: string[];
  supportedLanguages: string[];
  duplicatedEntryIds: string[];
}

export interface GlossaryLanguageColumn {
  key: string;
  visible: boolean;
}

export interface GlossaryLanguageColumnWithWidth
  extends GlossaryLanguageColumn {
  width: number;
  minWidth: number;
  maxWidth: number;
}
