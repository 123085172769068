import React, { useState } from 'react';
import { Button } from 'antd';
import AddSynonymModal from './AddSynonymModal';
import { Permissions } from '@ipos/shared';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

const AddSynonymsButton: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={handleOpenRequest} type="primary">
        {t('SYNONYMS.ADD')}
      </Button>
      {isModalOpen && <AddSynonymModal onCloseRequest={handleCloseRequest} />}
    </WithPermission>
  );
};

export default AddSynonymsButton;
