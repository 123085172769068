import { NodeSpec } from 'prosemirror-model';

const mathInline: NodeSpec = {
  group: 'inline math',
  content: 'text*', // important!
  inline: true, // important!
  atom: true, // important!
  toDOM: () => ['math-inline', { class: 'math-node' }, 0],
  parseDOM: [
    {
      tag: 'math-inline', // important!
    },
  ],
};
export default mathInline;
