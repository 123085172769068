import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../../../Icon';
import { Colors } from '../../../../theme';
import useTranslation from '../../../../translations';

interface Props {
  label: string;
  active: boolean;
  children?: React.ReactNode;
  icon?: string;
}
const TableCommandLabel: React.FC<Props> = ({
  label,
  active,
  children,
  icon,
}) => {
  const t = useTranslation();
  const hasNumbers = /\d/.test(label);
  const translatedLabel = hasNumbers
    ? label
    : t(`PM.ACTION.${label.toUpperCase()}`);

  const content = children || (
    <>
      {icon && <StyledIcon className={icon} active={active} />}
      <Label active={active}>{translatedLabel}</Label>
    </>
  );

  return (
    <Outer label={label} active={active}>
      {content}
    </Outer>
  );
};

const Outer = styled.div<{ active: boolean; label?: string }>`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  &:hover {
    ${(props) =>
      props.active &&
      css`
        &:hover {
          background: ${Colors.primary200_20};
        }
      `}
  }

  ${(props) =>
    props?.label?.toLowerCase().includes('delete') &&
    props.active &&
    css`
      &:hover {
        background: ${Colors.visualization.red500_30} !important;
      }
    `}
`;

const StyledIcon = styled(Icon)<{ active: boolean }>`
  color: ${(props) => (props.active ? Colors.white87 : Colors.white30)};
`;

const Label = styled.span<{ active: boolean }>`
  font-size: 15px;
  color: ${(props) => (props.active ? Colors.white87 : Colors.white30)};
`;

export default TableCommandLabel;
