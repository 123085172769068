import 'isomorphic-fetch';
import { Client } from '@microsoft/microsoft-graph-client';
import { AadUser } from '../types';
import { ClientCredentialAuthProvider } from './ClientCredentialAuthProvider';

const BASIC_PROPS = 'id, displayName, mail, mobilePhone, surname, givenName';

function createAuthenticatedClient(): Client {
  return Client.initWithMiddleware({
    defaultVersion: 'v1.0',
    debugLogging: false,
    authProvider: new ClientCredentialAuthProvider(),
  });
}

let msGraphClient: Client;

interface GetUsersInGroupParams {
  (props: {
    groupId: string;
    properties?: string[];
    searchTerm?: string;
    orderBy?: string;
    top?: number;
    skipToken?: string;
  }): Promise<{ value: AadUser[]; '@odata.nextLink'?: string }>;
}

export const getUsersInGroup: GetUsersInGroupParams = async ({
  groupId,
  properties = BASIC_PROPS,
  searchTerm,
  orderBy,
  top,
  skipToken,
}) => {
  msGraphClient ??= createAuthenticatedClient();
  const request = msGraphClient
    .api(`/groups/${groupId}/members`)
    .count(true)
    .select(properties);
  if (top !== undefined) {
    request.top(top);
  }
  if (orderBy !== undefined) {
    request.orderby(orderBy);
  }
  if (skipToken !== undefined) {
    request.skipToken(skipToken);
  }
  if (searchTerm !== undefined && searchTerm !== '') {
    request
      .header('ConsistencyLevel', 'eventual')
      .search(`"displayName:${searchTerm}"`);
  }
  return request
    .get()
    .then((response) => response)
    .catch((error) => {
      console.error(error);
    });
};

export const getMe = async (properties = BASIC_PROPS): Promise<AadUser> => {
  msGraphClient ??= createAuthenticatedClient();
  return msGraphClient
    .api('/me')
    .select(properties)
    .get()
    .catch((error) => {
      console.error(error);
    });
};

export const getUser = async (
  userId: string,
  properties = BASIC_PROPS
): Promise<AadUser> => {
  msGraphClient ??= createAuthenticatedClient();
  return msGraphClient.api(`/users/${userId}`).select(properties).get();
};
