import i18next, { TFunction } from 'i18next';
import { APP_LANGUAGES } from '../../constants';
import { ApplicationLanguage, Language } from '../../types';

const LANG_KEY = 'current_language';

export * from './helpers';

export const changeLanguage = (languageCode: string): Promise<TFunction> =>
  i18next.changeLanguage(languageCode);

export const setLanguageInStorage = (language: ApplicationLanguage): void => {
  localStorage.setItem(LANG_KEY, JSON.stringify(language));
};

export const getCurrentLanguage = (): ApplicationLanguage | null => {
  const localStorageLanguage = localStorage.getItem(LANG_KEY);
  return localStorageLanguage === null
    ? null
    : JSON.parse(localStorageLanguage);
};

export const getDefaultLanguage = (): ApplicationLanguage => {
  const lang = APP_LANGUAGES.find((l) => l.default);
  if (lang) {
    return lang;
  }
  return APP_LANGUAGES[0];
};

export const getLanguageByCode = (
  languageCode: string
): ApplicationLanguage | null =>
  APP_LANGUAGES.find((l) => l.languageCode === languageCode) ?? null;

export const getCurrentLanguageValue = (): Language => {
  const currentLanguage = getCurrentLanguage() || getDefaultLanguage();
  switch (currentLanguage.name) {
    case 'German':
      return Language.De;
    default:
      return Language.En;
  }
};
