import { EditorState, Transaction } from 'prosemirror-state';

import { undo, undoDepth } from 'prosemirror-history';
import UICommand from './UICommand';

class HistoryUndoCommand extends UICommand {
  isEnabled = (state: EditorState): boolean => undoDepth(state) > 0;

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => undo(state, dispatch);
}

export default HistoryUndoCommand;
