import React, { useState } from 'react';
import { Button } from 'antd';
import EditGraphics from './EditGraphics';
import { Permissions, Components } from '@ipos/shared';

const { Icon } = Components;
const { WithPermission } = Permissions;

const EditGraphicsButton: React.FC<{ uploadsCount?: number }> = ({
  uploadsCount,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button
        onClick={handleOptionClick}
        type="primary"
        icon={
          <Icon className="icn-edit" size={14} style={{ marginRight: 3 }} />
        }
      >
        {uploadsCount}
      </Button>
      <EditGraphics isOpen={isModalOpen} onClose={handleCloseRequest} />
    </WithPermission>
  );
};

export default EditGraphicsButton;
