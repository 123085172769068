import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import TsdTreeTags from '../TsdTreeTags';
import CpcTags from '../CpcTags';
import { T } from '@ipos/shared';
import useTranslation from 'translations';

const { Text } = Typography;

interface Props {
  tsdElement: T.TsdElementBasicInfo;
  onSelectElement: (elementId: string) => void;
}

const TsdElementSearchResultsItem: React.FC<Props> = ({
  tsdElement,
  onSelectElement,
}) => {
  const t = useTranslation();
  const { id, name, cpcCodes, tsdNames } = tsdElement;

  return (
    <Outer key={id} onClick={() => onSelectElement(id)}>
      <Title>{name}</Title>
      <Inner>
        <Text style={{ marginRight: 5 }}>
          {t('CREATE_ELEMENT.USED_IN_TSDS')}
        </Text>
        <TsdTreeTags tsdNames={tsdNames} />
      </Inner>
      <Inner>
        <Text style={{ marginRight: 5 }}>{t('CREATE_ELEMENT.BASE_CPC')}</Text>
        <CpcTags codes={cpcCodes} />
      </Inner>
    </Outer>
  );
};

export default TsdElementSearchResultsItem;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  background: ${(props) => props.theme.colors.primary800};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary500};
  min-height: 60px;
  background-color: #373842;
  &:hover {
    background-color: #494b5a;
    cursor: pointer;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
