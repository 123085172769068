import qs from 'querystring';
import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/ai';

// ----- AI TEMPLATES -----

export const createPrompt = (
  payload: T.CreateAiPrompt
): Promise<T.StoredAiPrompt> =>
  Api.post<T.StoredAiPrompt>(API_BASE, payload).then(
    (response) => response.data
  );

export const updatePrompt = (
  payload: T.UpdateAiPrompt
): Promise<T.StoredAiPrompt> =>
  Api.put<T.StoredAiPrompt>(`${API_BASE}/${payload.id}`, payload).then(
    (response) => response.data
  );

export const getPrompt = (promptId: string): Promise<T.StoredAiPrompt> =>
  Api.get<T.StoredAiPrompt>(`${API_BASE}/${promptId}`).then(
    (response) => response.data
  );

export const deletePrompt = (promptId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/${promptId}`).then((response) => response.data);

export const searchPrompts: T.PaginatedApiFunction<
  T.StoredAiPrompt,
  T.QueryParams
> = (params: T.QueryParams): Promise<T.StoredAiPrompt[]> =>
  Api.post(`${API_BASE}/search`, { ...params }).then(
    (response) => response.data
  );

export const previewPrompt = (
  payload: T.ExecuteAiPrompt
): Promise<T.AiResponse> =>
  Api.post<T.AiResponse>(`${API_BASE}/preview`, payload).then(
    (response) => response.data
  );

export const previewStoredPrompt = (
  payload: T.ExecuteStoredAiPrompt
): Promise<T.AiResponse> =>
  Api.post<T.AiResponse>(`${API_BASE}/${payload.id}/preview`, payload).then(
    (response) => response.data
  );

export const executePrompt = (
  payload: T.ExecuteAiPrompt
): Promise<T.AiResponse> =>
  Api.post<T.AiResponse>(`${API_BASE}/execute`, payload).then(
    (response) => response.data
  );

export const executeStoredPrompt = (
  payload: T.ExecuteStoredAiPrompt
): Promise<T.AiResponse> =>
  Api.post<T.AiResponse>(`${API_BASE}/${payload.id}/execute`, payload).then(
    (response) => response.data
  );

// ----- AI ASSISTANTS -----

export const getAssitantAttachments = (
  assistantType: T.AssistantType,
  targetId: string,
  claimNumber?: string | null,
  priorArtCitationNumber?: string | null
): Promise<T.AiAttachmentItem[]> => {
  const queryParams: Record<string, string | undefined> = {};
  if (claimNumber && priorArtCitationNumber) {
    queryParams.claimNumber = claimNumber;
    queryParams.priorArtCitationNumber = priorArtCitationNumber;
  }
  const url = `${API_BASE}/assistants/${assistantType}/attachmentIds/${targetId}?${qs.stringify(
    queryParams
  )}`;
  return Api.get(url).then((response) => response.data);
};

export const getAiResponse = (
  data: T.AiSubmitForm
): Promise<T.AiAssistantResponse> =>
  Api.post<T.AiAssistantResponse>(
    `${API_BASE}/assistants/${data.assistantType}?${qs.stringify({
      claimNumber: data.claimNumber,
      priorArtCitationNumber: data.priorArtCitationNumber,
    })}`,
    data.payload
  ).then((response) => response.data);

export const getAiStoredResponses = (
  attachmentId: string
): Promise<T.AiAssistantResponse[]> =>
  Api.get<T.AiAssistantResponse[]>(
    `${API_BASE}/assistants/for-attachment-id/${attachmentId}`
  ).then((response) => response.data);

export const deleteAiResponse = (id: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/assistants/${id}`).then(
    (response) => response.data
  );
