import React, { useState } from 'react';
import { TsdPublishModal } from 'components/Tsd';
import { T, Permissions } from '@ipos/shared';
import { MenuBarOption } from 'components/common';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  tsd: T.Tsd;
}

const PublishTsdMenuOption: React.FC<Props> = ({ tsd }) => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  let title = t('TSD_PUBLISHING.PUBLISH.TITLE');
  let icon = 'icn-visible';

  if (tsd.isPublished) {
    title = t('TSD_PUBLISHING.UNPUBLISH.TITLE');
    icon = 'icn-hidden';
  }

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <MenuBarOption
        onClick={() => setModalOpen(true)}
        iconClassName={icon}
        title={title}
      />
      {isModalOpen && (
        <TsdPublishModal tsd={tsd} onClose={() => setModalOpen(false)} />
      )}
    </WithPermission>
  );
};

export default PublishTsdMenuOption;
