import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { Icon } from '../Icon';

interface Props {
  iconClassName: string;
  tooltipText: string;
  testId?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const TsdTreeControlsButton: React.FC<Props> = ({
  iconClassName,
  tooltipText,
  testId,
  active = false,
  disabled = false,
  onClick,
}) => (
  <Tooltip
    title={tooltipText}
    placement="top"
    mouseLeaveDelay={0}
    destroyTooltipOnHide
  >
    <Outer
      onClick={onClick}
      className={classNames({ active, disabled })}
      data-testid={testId}
    >
      <Icon className={iconClassName} size={22} />
    </Outer>
  </Tooltip>
);

export default TsdTreeControlsButton;

const Outer = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: 0;
  color: ${(props) => props.theme.colors.white100};
  transition: all 0.2s;
  font-size: 18px;
  & + & {
    margin-left: 10px;
  }
  &:hover,
  &.active {
    cursor: pointer;
    color: ${(props) => props.theme.colors.success};
  }
  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
`;
