import React, { useEffect, useRef, useState } from 'react';
import {
  ContentBlock,
  DraftHandleValue,
  Editor,
  DraftEditorCommand,
  EditorState,
  DefaultDraftBlockRenderMap,
} from 'draft-js';
import styled from 'styled-components';
import 'draft-js/dist/Draft.css';
import { usePreviousStable } from '../../hooks';
import {
  getBlockAfterSelectedBlock,
  getBlockBeforeSelectedBlock,
  getSelectedBlock,
} from '../../utils';
import { DiffTuple } from '../../types';
import {
  applyDeletedEntityToHomogenousSelection,
  applyDeletedEntityToSelection,
  applyNewTextOverSelection,
  applyTextAfterCursor,
  convertEditorStateToDiff,
  deleteWordWithMultipleEntities,
  DiffEntityTypes,
  getEditorStateFromTuples,
  getEntitiesInNextWord,
  getEntitiesInPreviousWord,
  getSelectionEntity,
  getTargetText,
  handleFreshlyBackwardDeletedText,
  handleFreshlyForwardDeletedText,
  moveSelectionToTheEnd,
  moveSelectionToTheStart,
  selectNextWord,
  selectPreviousWord,
  isSelectionAtTheOfBlock,
} from './DiffUtils';

const NOT_HANDLED = 'not-handled';
const HANDLED = 'handled';

const blockRenderMap = DefaultDraftBlockRenderMap.set('br', { element: 'br' });

interface Props {
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onTextUpdate?: (updatedText: string) => void;
  onFocus?: () => void;
  readOnly?: boolean;
  focused?: boolean;
  diffState?: Array<DiffTuple>;
  blockStyleFn?: (block: ContentBlock) => unknown;
}

const DiffRenderer: React.FC<Props> = ({
  diffState,
  placeholder,
  readOnly,
  onFocus,
  onTextUpdate,
  onBlur,
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const containerRef = useRef(null);
  const previousDiffState = usePreviousStable(diffState);

  useEffect(() => {
    if (diffState === previousDiffState || focused) {
      return;
    }
    const currentValue = editorState.getCurrentContent();
    const newDiffState = getEditorStateFromTuples(diffState);
    const newValue = newDiffState.getCurrentContent();
    if (currentValue === newValue) {
      return;
    }
    setEditorState(newDiffState);
  }, [focused, diffState, previousDiffState]);

  useEffect(() => {
    if (!diffState) {
      return;
    }
    if (onTextUpdate) {
      const newValue = convertEditorStateToDiff(editorState);
      const oldValue = diffState && getTargetText(diffState);
      if (newValue === oldValue) {
        return;
      }
      onTextUpdate(newValue);
    }
  }, [editorState]);

  const onChange = (es: EditorState) => {
    setEditorState(es);
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = () => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBeforeInput = (
    chars: string,
    es: EditorState
  ): DraftHandleValue => {
    const currentSelection = es.getSelection();
    const anchor = currentSelection.getAnchorOffset();
    const focus = currentSelection.getFocusOffset();
    if (anchor !== focus) {
      setEditorState(applyNewTextOverSelection(es, chars));
    } else {
      setEditorState(applyTextAfterCursor(es, chars));
    }
    return HANDLED;
  };

  const handlePaste = (
    text: string,
    html: string | undefined,
    es: EditorState
  ): DraftHandleValue => {
    const currentEditorState = es;
    const currentBlock = getSelectedBlock(currentEditorState);
    if (!currentBlock) {
      return NOT_HANDLED;
    }
    const selection = currentEditorState.getSelection();
    const anchor = selection.getAnchorOffset();
    const focus = selection.getFocusOffset();
    // If we are pasting on a text selection which is not equal to pasted text
    if (anchor !== focus) {
      setEditorState(applyNewTextOverSelection(es, text));
      return HANDLED;
    }
    setEditorState(applyTextAfterCursor(es, text));
    return HANDLED;
  };

  const handleKeyCommand = (
    command: DraftEditorCommand,
    es: EditorState
  ): DraftHandleValue => {
    const selection = es.getSelection();
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const startBlockKey = selection.getStartKey();
    const endBlockKey = selection.getEndKey();
    const previousBlock = getBlockBeforeSelectedBlock(es);
    const nextBlock = getBlockAfterSelectedBlock(es);
    const currentAnchor = selection.getAnchorOffset();
    const selectedBlocksWithEntities = getSelectionEntity(es);

    switch (command) {
      case 'split-block': {
        const anchor = selection.getAnchorOffset();
        const focus = selection.getFocusOffset();
        if (anchor === focus) {
          return NOT_HANDLED;
        }
        return HANDLED;
      }
      case 'backspace-word': {
        if (startBlockKey !== endBlockKey || start !== end) {
          // If we are spanning over multiple blocks, don't handle that for now
          if (Object.keys(selectedBlocksWithEntities).length > 1) {
            return HANDLED;
          }
          const block = selectedBlocksWithEntities[startBlockKey];
          const entitiesInSelection = block.entities;
          if (entitiesInSelection.length > 1) {
            applyDeletedEntityToSelection(es, selectedBlocksWithEntities);
            return HANDLED;
          }
          const singleInstanceInSelection =
            entitiesInSelection[0].instance.getType();
          switch (singleInstanceInSelection) {
            case DiffEntityTypes.NO_CHANGE: {
              const deletedText = applyDeletedEntityToHomogenousSelection(es);
              setEditorState(moveSelectionToTheStart(deletedText));
              return HANDLED;
            }
            case DiffEntityTypes.INSERTED: {
              return NOT_HANDLED;
            }
            case DiffEntityTypes.DELETED: {
              setEditorState(moveSelectionToTheStart(es));
              return HANDLED;
            }
            default:
              return NOT_HANDLED;
          }
        }
        const blocksWithEntities = getEntitiesInPreviousWord(es);
        const blockWithEntities = blocksWithEntities[startBlockKey];
        const entitiesInPreviousWord = blockWithEntities.entities;
        const selectedWord = selectPreviousWord(es);
        if (entitiesInPreviousWord.length > 1) {
          setEditorState(
            deleteWordWithMultipleEntities(es, blocksWithEntities, true)
          );
          return HANDLED;
        }
        const singleInstanceType = entitiesInPreviousWord[0].instance.getType();
        switch (singleInstanceType) {
          case DiffEntityTypes.NO_CHANGE: {
            const deletedWord = applyDeletedEntityToHomogenousSelection(
              EditorState.forceSelection(es, selectedWord)
            );
            setEditorState(moveSelectionToTheStart(deletedWord));
            return HANDLED;
          }
          case DiffEntityTypes.INSERTED: {
            return NOT_HANDLED;
          }
          case DiffEntityTypes.DELETED: {
            setEditorState(
              moveSelectionToTheStart(
                EditorState.forceSelection(es, selectedWord)
              )
            );
            return HANDLED;
          }
          default:
            return NOT_HANDLED;
        }
      }
      case 'delete-word': {
        if (startBlockKey !== endBlockKey || start !== end) {
          if (Object.keys(selectedBlocksWithEntities).length > 1) {
            return HANDLED;
          }
          const currentBlock = getSelectedBlock(es);
          const entity =
            selectedBlocksWithEntities[currentBlock?.getKey() as string]
              .entities[0];
          const singleInstanceInSelection = entity.instance.getType();
          switch (singleInstanceInSelection) {
            case DiffEntityTypes.NO_CHANGE: {
              const deletedText = applyDeletedEntityToHomogenousSelection(es);
              setEditorState(moveSelectionToTheEnd(deletedText));
              return HANDLED;
            }
            case DiffEntityTypes.INSERTED: {
              return NOT_HANDLED;
            }
            case DiffEntityTypes.DELETED: {
              setEditorState(moveSelectionToTheEnd(es));
              return HANDLED;
            }
            default:
              return NOT_HANDLED;
          }
        }
        const selectedWord = selectNextWord(es);
        const blocksWithEntities = getEntitiesInNextWord(es);
        const blockWithEntities = blocksWithEntities[startBlockKey];

        const singleInstanceType =
          blockWithEntities.entities[0].instance.getType();
        switch (singleInstanceType) {
          case DiffEntityTypes.NO_CHANGE: {
            const deletedWord = applyDeletedEntityToHomogenousSelection(
              EditorState.forceSelection(es, selectedWord)
            );
            setEditorState(moveSelectionToTheEnd(deletedWord));
            return HANDLED;
          }
          case DiffEntityTypes.INSERTED: {
            return NOT_HANDLED;
          }
          case DiffEntityTypes.DELETED: {
            setEditorState(
              moveSelectionToTheEnd(
                EditorState.forceSelection(es, selectedWord)
              )
            );
            return HANDLED;
          }
          default:
            return NOT_HANDLED;
        }
      }
      case 'delete': {
        if (nextBlock && isSelectionAtTheOfBlock(es)) {
          return NOT_HANDLED;
        }

        if (startBlockKey !== endBlockKey || start !== end) {
          setEditorState(
            applyDeletedEntityToSelection(es, selectedBlocksWithEntities)
          );
          return HANDLED;
        }
        const currentBlock = getSelectedBlock(es);
        const entity =
          selectedBlocksWithEntities[currentBlock?.getKey() as string]
            .entities[0];
        if (entity.instance.getType() === DiffEntityTypes.NO_CHANGE) {
          const newEditorState = handleFreshlyForwardDeletedText(editorState);
          setEditorState(newEditorState);
        } else if (entity.instance.getType() === DiffEntityTypes.DELETED) {
          setEditorState(
            EditorState.forceSelection(
              es,
              es.getSelection().merge({
                focusOffset: selection.getAnchorOffset() + 1,
                anchorOffset: selection.getAnchorOffset() + 1,
              })
            )
          );
        } else if (entity.instance.getType() === DiffEntityTypes.INSERTED) {
          return NOT_HANDLED;
        }
        return HANDLED;
      }
      case 'backspace': {
        if (previousBlock && currentAnchor === 0) {
          return NOT_HANDLED;
        }
        const blocksWithEntities = getSelectionEntity(es);
        if (start !== end) {
          setEditorState(applyDeletedEntityToSelection(es, blocksWithEntities));
          return HANDLED;
        }

        const currentBlock = getSelectedBlock(es);
        const entity =
          blocksWithEntities[currentBlock?.getKey() as string].entities[0];
        if (entity.instance.getType() === DiffEntityTypes.NO_CHANGE) {
          const newEditorState = handleFreshlyBackwardDeletedText(editorState);
          setEditorState(newEditorState);
        } else if (entity.instance.getType() === DiffEntityTypes.DELETED) {
          setEditorState(
            EditorState.forceSelection(
              es,
              es.getSelection().merge({
                focusOffset: selection.getAnchorOffset() - 1,
                anchorOffset: selection.getAnchorOffset() - 1,
              })
            )
          );
        } else if (entity.instance.getType() === DiffEntityTypes.INSERTED) {
          return NOT_HANDLED;
        }
        return HANDLED;
      }
      default:
        return NOT_HANDLED;
    }
  };
  return (
    <Outer tabIndex={0} onFocus={handleFocus} onBlur={handleBlur}>
      <Editor
        placeholder={placeholder}
        blockRenderMap={blockRenderMap}
        readOnly={readOnly}
        ref={containerRef}
        editorState={editorState}
        handlePastedText={handlePaste}
        handleKeyCommand={handleKeyCommand}
        handleBeforeInput={handleBeforeInput}
        onChange={onChange}
      />
    </Outer>
  );
};

export default DiffRenderer;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
  box-sizing: content-box;
  .DraftEditor-root {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: -10px -10px -10px -35px;
    padding: 10px 10px 10px 40px;
    :hover,
    :focus {
      color: ${(props) => props.theme.colors.white100};
    }
  }
`;
