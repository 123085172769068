import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/uploads';

export const getFileAsBase64 = (uploadId: string): Promise<string> =>
  Api.get<string>(`${API_BASE}/${uploadId}/as-base-64`).then(
    (response) => response.data
  );

export const getFile = (uploadId: string): Promise<Blob> =>
  Api.get<Blob>(`${API_BASE}/${uploadId}/download`, {
    responseType: 'blob',
  }).then((response) => response.data);

export const upload = (files: File[]): Promise<T.BlobResource[]> => {
  const formData = new FormData();
  files.forEach((file: File) => {
    formData.append('files', file, file.name);
  });
  return Api.post<T.BlobResource[]>(`${API_BASE}`, formData).then(
    (response) => response.data
  );
};
