import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { T, Components } from '@ipos/shared';

const {
  Uploads: { Image },
  Icon,
} = Components;

interface Props {
  uploads: T.TsdElementUpload[];
  onCloseRequest: () => void;
}

const GraphicsPreviewModal: React.FC<Props> = ({ onCloseRequest, uploads }) => {
  const [activeId, setActiveId] = useState<string>(
    uploads?.length ? uploads[0].id : ''
  );

  const previewedUpload = uploads.find((u) => u.id === activeId);

  if (previewedUpload == null) {
    return null;
  }

  const handlePreviousClick = () => {
    const currentIndex = uploads.findIndex((x) => x.id === activeId);
    const newIndex = (currentIndex > 0 ? currentIndex : uploads.length) - 1;
    setActiveId(uploads[newIndex].id);
  };

  const handleNextClick = () => {
    const currentIndex = uploads.findIndex((x) => x.id === activeId);
    const newIndex = currentIndex < uploads.length - 1 ? currentIndex + 1 : 0;
    setActiveId(uploads[newIndex].id);
  };

  const handleCancel = () => {
    onCloseRequest();
  };

  const isNavigationDisabled = uploads.length < 2;

  return (
    <Modal
      open
      onCancel={handleCancel}
      width={800}
      destroyOnClose
      footer={null}
    >
      <PreviewContent>
        <PreviewImage key={previewedUpload.id}>
          <Image
            uploadId={previewedUpload.upload.id}
            style={{ objectFit: 'contain' }}
          />
        </PreviewImage>
        <PreviewDetails>
          <Button
            icon={<Icon className="icn-left-outlined" />}
            type="link"
            disabled={isNavigationDisabled}
            size="large"
            onClick={handlePreviousClick}
          />
          {previewedUpload.upload.fileName}
          <Button
            icon={<Icon className="icn-right-outlined" />}
            type="link"
            disabled={isNavigationDisabled}
            size="large"
            onClick={handleNextClick}
          />
        </PreviewDetails>
      </PreviewContent>
    </Modal>
  );
};

export default GraphicsPreviewModal;

const PreviewContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PreviewImage = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PreviewDetails = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
`;
