import { css } from 'styled-components';

export default css`
  [data-claim-part='PreambleDelimiter'] {
    font-weight: bold;
  }

  [data-claim-part='PreambleDelimiter'] [data-claim-part] {
    font-weight: normal;
  }

  [data-claim-part='Dependencies'] {
    border-bottom: 1px solid #444;
    display: inline-block;
  }
`;
