import React, { useState } from 'react';
import styled from 'styled-components';
import { B2, Small } from '../../Atoms/Atoms';
import { AvatarCircle } from '../../Avatar';
import {
  PatentApplicationStakeholderRole,
  CreateCompetithorReport,
  CompetithorReportInputName,
  CompetithorReportParams,
  PatStatReports,
} from '../../../types';
import useTranslation from '../../../translations';
import CompetitorReportModal from '../CompetitorReportModal';

interface Props {
  name?: string;
  type?: string;
  psnId?: string;
  style?: React.CSSProperties;
}

const PatstatStakeholderItem: React.FC<Props> = ({
  name,
  type,
  psnId,
  style,
}) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  let competitorReportRequest = null;

  switch (type) {
    case PatentApplicationStakeholderRole.Applicant:
      competitorReportRequest = {
        title: name,
        description: t('COMPETITOR.COMPANY_INFOGRAPHIC.DESCRIPTION'),
        inputs: [
          {
            name: CompetithorReportInputName.ApplicantName,
            value: name,
          },
        ],
        reportParams: {
          [CompetithorReportParams.Name]: name,
        },
        patStatReportId: PatStatReports.CompanyInfographic,
      };
      break;
    case PatentApplicationStakeholderRole.Inventor:
      competitorReportRequest = {
        title: name,
        description: t('COMPETITOR.INVENTOR_INFOGRAPHIC.DESCRIPTION'),
        inputs: [
          {
            name: CompetithorReportInputName.InventorPsnId,
            value: psnId,
          },
        ],
        reportParams: {
          [CompetithorReportParams.PsnId]: psnId,
        },
        patStatReportId: PatStatReports.InventorInfographic,
      };
      break;
    default:
      break;
  }

  return (
    <Outer
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenRequest?.();
      }}
    >
      <AvatarCircle withHighlight={false} size={30} displayName={name || '?'} />
      <Inner>
        <Name>{name}</Name>
        <Type>{type}</Type>
        <PsnID>{psnId}</PsnID>
      </Inner>
      {isModalOpen && (
        <CompetitorReportModal
          onCloseRequest={(e) => {
            e.stopPropagation();
            handleCloseRequest?.();
          }}
          reportRequest={competitorReportRequest as CreateCompetithorReport}
        />
      )}
    </Outer>
  );
};

export default PatstatStakeholderItem;

const Name = styled(B2)`
  line-height: 13px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.white70};
  margin-bottom: 3px;
`;

const Type = styled(Small)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white40};
`;

const PsnID = styled(Small)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white40};
`;

const Outer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  &:hover {
    ${Name} {
      color: ${(props) => props.theme.colors.white87};
    }
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;
