import { object, string, array, ObjectSchema } from 'yup';
import { T } from '@ipos/shared';

const addTsdElementCompanySchema: ObjectSchema<T.PatstatCompanyGroup> = object({
  name: string().trim().required('VALIDATION.REQUIRED'),
  city: string().ensure(),
  countryCode: string().ensure(),
  companyNameVariations: array()
    .required('VALIDATION.REQUIRED')
    .min(1, 'VALIDATION.REQUIRED'),
});

export default addTsdElementCompanySchema;
