import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { Components, Colors } from '@ipos/shared';
import useTranslation from 'translations';

const { Icon, Spinner } = Components;

interface Props {
  isLoading: boolean;
  isElementSelected: boolean;
  matchingElementsCount?: number;
  onFormReset: () => void;
  onShowExistingElements: () => void;
}

const TsdElementNameButton: React.FC<Props> = ({
  isLoading,
  isElementSelected,
  matchingElementsCount = 0,
  onFormReset,
  onShowExistingElements,
}) => {
  const t = useTranslation();

  const getButtonConfig = () => {
    switch (true) {
      case isLoading:
        return {
          title: t('CREATE_ELEMENT.SEARCH_IN_PROGRESS'),
          icon: <Spinner customSize={16} color={Colors.white50} />,
          onClick: undefined,
          disabled: true,
        };
      case isElementSelected:
        return {
          title: t('CREATE_ELEMENT.CLEAR_SELECTION'),
          icon: (
            <Icon
              className="icn-clear"
              style={{ verticalAlign: 'text-bottom' }}
            />
          ),
          onClick: onFormReset,
          disabled: false,
        };
      case matchingElementsCount > 0:
        return {
          title: t('CREATE_ELEMENT.SHOW_ELEMENTS'),
          icon: <>{matchingElementsCount}</>,
          onClick: onShowExistingElements,
          disabled: false,
        };
      default:
        return {
          title: t('CREATE_ELEMENT.NO_ELEMENTS_FOUND'),
          icon: <>{0}</>,
          onClick: undefined,
          disabled: true,
        };
    }
  };

  const { title, icon, onClick, disabled } = getButtonConfig();

  return (
    <Outer>
      <Tooltip title={title} placement="top" mouseLeaveDelay={0}>
        <Button
          type="primary"
          icon={icon}
          onClick={onClick}
          disabled={disabled}
        />
      </Tooltip>
    </Outer>
  );
};

export default TsdElementNameButton;

const Outer = styled.div`
  margin-left: 5px;
`;
