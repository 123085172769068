import qs from 'querystring';
import { T } from '../../index';
import Api from './base';

const API_BASE = '/v1/companies';

export const getCompanyGroups = (
  searchTerm: string,
  pagination: { pageSize: number; skip: number }
): Promise<T.CompanyGroup[]> =>
  Api.get<T.CompanyGroup[]>(
    `${API_BASE}/company-groups/?${qs.stringify({
      searchTerm,
      ...pagination,
    })}`
  ).then((response) => response.data);

export const updateCompanyGroup = (
  data: T.UpdateCompanyGroup
): Promise<T.CompanyGroup> =>
  Api.put<T.CompanyGroup>(
    `${API_BASE}/company-groups/${data.companyGroupId}`,
    data
  ).then((response) => response.data);

export const deleteCompanyGroup = (companyGroupId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/company-groups/${companyGroupId}`).then(
    (response) => response.data
  );
