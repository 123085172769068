import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Modal, message } from 'antd';
import { Components, Permissions, Utils, Api, queryClient } from '@ipos/shared';
import { QueryKey } from 'queries';
import useTranslation from 'translations';

const { WithPermission } = Permissions;
const { initBrowserDownload } = Utils;
const {
  Layout: { HeaderItem },
} = Components;

const ExportAllTsdsHeaderItem: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { refetch } = useQuery(
    QueryKey.AllTsdsJsonDownload,
    Api.tsd.exportAllTsds,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    const handleModalStateChange = async () => {
      await queryClient.cancelQueries(QueryKey.AllTsdsJsonDownload);
      queryClient.removeQueries(QueryKey.AllTsdsJsonDownload);

      if (!isModalOpen) {
        return;
      }

      try {
        const { data } = await refetch();
        if (data) {
          const [bytes, filename] = data;
          initBrowserDownload(filename, bytes);
        }
      } catch {
        message.error(t('TSD_EXPORT.ERROR'));
      } finally {
        setModalOpen(false);
      }
    };
    handleModalStateChange();
  }, [isModalOpen]);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdExport}>
      <HeaderItem
        label={t('HEADER.ITEM.EXPORT_TSDS')}
        iconClassName="icn-export"
        onClick={() => setModalOpen(true)}
      />
      {isModalOpen && (
        <Modal
          title={t('TSD_EXPORT.TITLE_ALL')}
          okText={t('TSD_EXPORT.GENERATING_FILE')}
          cancelText={t('ACTION.CANCEL')}
          okButtonProps={{
            loading: true,
          }}
          onCancel={() => setModalOpen(false)}
          open={true}
          width={400}
        >
          <p>{t('TSD_EXPORT.MESSAGE')}</p>
        </Modal>
      )}
    </WithPermission>
  );
};

export default ExportAllTsdsHeaderItem;
