import qs from 'querystring';
import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/patstat';

export const getCompanyNameVariations = (
  name: string
): Promise<T.PatstatCompanyNameVariation[]> =>
  Api.get<T.PatstatCompanyNameVariation[]>(
    `${API_BASE}/company-name-variations?${qs.stringify({
      name,
    })}`
  ).then((response) => response.data);

export const getApplicantsInventorsByPublicationNumber = (
  publicationNumber: string
): Promise<T.PatstatApplicantsInventors[]> =>
  Api.get<T.PatstatApplicantsInventors[]>(
    `${API_BASE}/applicants-and-inventors?${qs.stringify({
      publicationNumber,
    })}`
  ).then((response) => response.data);

export const getCpcClassesByPublicationNumber = (
  publicationNumber: string
): Promise<T.PatstatCpcClass[]> =>
  Api.get<T.PatstatCpcClass[]>(
    `${API_BASE}/cpc-classes?${qs.stringify({
      publicationNumber,
    })}`
  ).then((response) => response.data);

export const getIpcClassesByPublicationNumber = (
  publicationNumber: string
): Promise<T.PatstatIpcClass[]> =>
  Api.get<T.PatstatIpcClass[]>(
    `${API_BASE}/ipc-classes?${qs.stringify({
      publicationNumber,
    })}`
  ).then((response) => response.data);
