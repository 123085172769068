import { useState, useEffect } from 'react';

const useTsdElementNameSearch = (
  elementName: string
): {
  search: string;
  clearSearch: () => void;
} => {
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearch(elementName);
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [elementName]);

  const clearSearch = () => {
    setSearch('');
  };

  return {
    search,
    clearSearch,
  };
};

export default useTsdElementNameSearch;
