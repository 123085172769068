import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { QueryKey } from './index';

const useDocumentMetadata = (
  documentId: string,
  options?: UseQueryOptions<T.DocumentMetadata, AxiosError>
): UseQueryResult<T.DocumentMetadata, AxiosError> =>
  useQuery<T.DocumentMetadata, AxiosError>(
    [QueryKey.DocumentMetadata, documentId],
    () => Api.document.getMetadata(documentId),
    options
  );

export default useDocumentMetadata;
