import React, { useRef, useState } from 'react';
import { Modal, Button } from 'antd';
import QueryBuilder from './QueryBuilder';
import QueryContextProvider from './QueryContext';
import { Permissions, T } from '@ipos/shared';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  query?: T.TsdElementQuery;
  baseQuery?: T.TsdElementQuery;
}

const OpenQueryBuilderButton: React.FC<Props> = ({ baseQuery, query }) => {
  const t = useTranslation();
  const hasUnsavedChangesRef = useRef<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleQueryChange = (hasUnsavedChanges: boolean) => {
    // store state using useRef instead of useState to avoid a common react useState condition with stale state
    // https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
    hasUnsavedChangesRef.current = hasUnsavedChanges;
  };

  const handleCloseModal = () => {
    if (!hasUnsavedChangesRef.current) {
      // if there are no unsaved changes, close the modal...
      setIsModalOpen(false);
      return;
    }

    Modal.confirm({
      title: t('UNSAVED_CHANGES'),
      content: t('DISCARD_UNSAVED_CHANGES'),
      okText: t('OK'),
      cancelText: t('ACTION.CANCEL'),
      onOk() {
        setIsModalOpen(false);
      },
      centered: true,
    });
  };

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={() => setIsModalOpen(true)} type="primary">
        {t('QUERY.OPEN_QUERY_BUILDER')}
      </Button>
      <Modal
        centered
        width={'100%'}
        wrapClassName={'query-builder-modal'}
        style={{ maxWidth: 1400, padding: 0 }}
        title={t('QUERY.QUERY_BUILDER')}
        open={isModalOpen}
        onCancel={handleCloseModal}
        destroyOnClose
        footer={null}
      >
        <QueryContextProvider
          tsdElementQuery={query}
          tsdElementBaseQuery={baseQuery}
        >
          <QueryBuilder
            onDone={handleCloseModal}
            onChange={handleQueryChange}
          />
        </QueryContextProvider>
      </Modal>
    </WithPermission>
  );
};

export default OpenQueryBuilderButton;
