/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { SyntheticEvent, useMemo } from 'react';

import styled from 'styled-components';
import UICommand from '../commands/UICommand';
import { FlatButton, IconButton } from '../../../Button';

type Props = {
  command: UICommand;
  disabled?: boolean | null;
  dispatch: (tr: Transaction) => void;
  editorState: EditorState;
  editorView?: EditorView;
  icon?: string | React.ReactNode | null;
  label?: string | any | null;
  title?: string | null;
};

const CommandButton: React.FC<Props> = (props) => {
  const {
    label,
    command,
    editorState,
    editorView,
    icon,
    title,
    disabled = !editorView,
  } = props;
  const onUIClick = (event: SyntheticEvent): void => {
    execute(command, event);
  };

  const execute = (value: any, event: SyntheticEvent): void => {
    const { editorState, dispatch, editorView } = props;
    command.execute(editorState, dispatch, editorView, event);
  };

  return useMemo(() => {
    if (label) {
      return (
        <FlatButton
          testId={command.name}
          onClick={onUIClick}
          style={{ padding: '0 8px', margin: '0 2px' }}
          active={command.isActive(editorState)}
          disabled={!command.isEnabled(editorState) || false}
          leftIcon={icon}
          title={label}
        >
          {label}
        </FlatButton>
      );
    } else {
      if (typeof icon === 'string') {
        return (
          <StyledIconButton
            testId={command.name}
            onClick={onUIClick}
            disabled={!command.isEnabled(editorState) || false}
            active={command.isActive(editorState)}
            buttonStyle={{ borderRadius: 3 }}
            className={icon}
          />
        );
      } else if (isElementType(icon)) {
        return (
          <StyledIconButton
            testId={command.name}
            onClick={onUIClick}
            disabled={!command.isEnabled(editorState) || false}
            active={command.isActive(editorState)}
            buttonStyle={{ borderRadius: 3 }}
            icon={icon}
          />
        );
      }
      return null;
    }
  }, [editorState, disabled, icon, label, title, command]);
};

export function isElementType(element: any): element is React.ElementType {
  return typeof element === 'object' && typeof element.render === 'function';
}

export const StyledIconButton = styled(IconButton)`
  &:first-child {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white70};
  }
`;

export default CommandButton;
