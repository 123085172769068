import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import AppCardList from '../../AppCardList/AppCardList';
import useTranslation from '../../../translations';
import NavigationItem from './NavigationItem';

const AppsMenu: React.FC = () => {
  const t = useTranslation();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  return (
    <>
      {isPopoverOpen && <Backdrop />}
      <Popover
        align="start"
        positions={['right']}
        containerStyle={{ marginLeft: '45px' }}
        isOpen={isPopoverOpen}
        onClickOutside={closePopover}
        content={
          <Outer onClick={(e) => e.stopPropagation()}>
            <AppCardList />
          </Outer>
        }
      >
        <Trigger onClick={togglePopover}>
          <NavigationItem
            iconClassName="icn-apps"
            tooltipText={t('NAVIGATION_TOOLTIPS.APPLICATIONS')}
            iconStyle={{ fontSize: 34 }}
            wrapperStyle={
              isPopoverOpen
                ? { position: 'absolute', zIndex: 20, bottom: 0, left: -40 }
                : {}
            }
            isSettingsItem
            active={isPopoverOpen}
          />
        </Trigger>
      </Popover>
    </>
  );
};

export default AppsMenu;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 10px;
  border-radius: 16px;
  background: #22232d;
  min-width: 436px;
`;

const Trigger = styled.div`
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: ${(props) => props.theme.colors.black60};
`;
