import React from 'react';
import styled from 'styled-components';
import { Tag, Space } from 'antd';
import { T, Components } from '@ipos/shared';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';
import useTranslation from 'translations';

const { CountryFlag } = Components;

interface Props {
  item: T.TsdElementTextModule;
  warnings?: string[];
  onRemoveRequest: (id: string) => void;
  onEditRequest: (item: T.TsdElementTextModule) => void;
}

const TextModulesListItem: React.FC<Props> = ({
  item,
  warnings,
  onRemoveRequest,
  onEditRequest,
}) => {
  const t = useTranslation();
  const { id, text, textModuleTypeName, language, isBaseAttribute } = item;

  return (
    <TsdElementAttributeListItem
      warnings={warnings}
      isBaseAttribute={isBaseAttribute}
      onRemoveRequest={() => onRemoveRequest(id)}
      onEditRequest={() => onEditRequest(item)}
      confirmRemovalTitle={t('TEXT_MODULES.DELETE_PROMPT')}
    >
      <TextModule>
        <Space style={{ marginBottom: 8 }}>
          <StyledTag>{textModuleTypeName}</StyledTag>
          <CountryFlag countryCode={language} size={18} />
        </Space>
        {text}
      </TextModule>
    </TsdElementAttributeListItem>
  );
};

export default TextModulesListItem;

const StyledTag = styled(Tag)`
  min-width: 100px;
  text-align: center;
`;

const TextModule = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
`;
