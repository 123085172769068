import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import styled from 'styled-components';
import { getUsersInGroup } from '../../../msGraph/graphAPI';
import { useSessionContext } from '../../../contexts';
import { AadUser } from '../../../types';
import useTranslation from '../../../translations';

const { Option } = Select;

interface Props {
  disabledIds?: string[];
  placeholder?: string;
  searchButtonLabel?: string;
  autoFocus?: boolean;
  onSelect: (aadUsers: AadUser[]) => void;
}

const UserSearch: React.FC<Props> = ({
  disabledIds,
  placeholder,
  searchButtonLabel,
  autoFocus,
  onSelect,
}) => {
  const t = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searching, setIsSearching] = useState<boolean>(false);
  const [users, setUsers] = useState<AadUser[] | null>();
  const [selection, setSelection] = useState<AadUser[]>([]);
  const { session } = useSessionContext();

  useEffect(() => {
    if (!searchTerm) {
      setUsers(null);
    }

    const doSearch = async () => {
      try {
        setIsSearching(true);
        const response = await getUsersInGroup({
          groupId: session.ownerGroupId,
          searchTerm,
          orderBy: 'displayName',
        });
        setUsers(response.value);
      } catch {
        setUsers(null);
      } finally {
        setIsSearching(false);
      }
    };
    doSearch();
  }, [searchTerm]);

  const options = users?.map((user) => (
    <Option
      key={user.id}
      value={user.id}
      disabled={disabledIds?.includes(user.id)}
    >
      <div>
        {user.displayName}
        <Mail>{user.mail}</Mail>
      </div>
    </Option>
  ));

  const handleDoneClick = () => {
    onSelect(selection);
    setSelection([]);
  };

  const handleSelectionChange = (ids: string[]) => {
    if (!users) {
      return;
    }
    const newSelection = ids.reduce((p: AadUser[], c) => {
      const existing = selection.find((s) => s.id === c);
      if (existing) {
        return [...p, existing];
      }
      const addition = users.find((u) => u.id === c);
      if (addition) {
        return [...p, addition];
      }
      return p;
    }, []);

    setSelection(newSelection);
  };

  return (
    <Outer>
      <Select
        mode="multiple"
        allowClear
        showSearch
        filterOption={false}
        onSearch={(v) => setSearchTerm(v)}
        onChange={handleSelectionChange}
        value={selection.map((s) => s.id)}
        style={{ width: '100%' }}
        placeholder={placeholder}
        notFoundContent={null}
        autoFocus={autoFocus}
      >
        {options}
      </Select>
      <Button
        type="primary"
        onClick={handleDoneClick}
        loading={searching}
        disabled={!selection.length}
      >
        {searchButtonLabel ?? t('ACTION.ADD')}
      </Button>
    </Outer>
  );
};

export default UserSearch;

const Outer = styled.div`
  display: flex;
`;

const Mail = styled.div`
  font-size: 12px;
  opacity: 0.8;
`;
