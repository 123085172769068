import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { EditorDocument } from '../types';
import { QueryKey } from './index';

const useEditorContent = (
  editorDocumentId: string | undefined,
  options?: UseQueryOptions<EditorDocument | null, AxiosError>
): UseQueryResult<EditorDocument | null, AxiosError> =>
  useQuery<EditorDocument | null, AxiosError>(
    [QueryKey.EditorContent, editorDocumentId],
    () => Api.oa.getEditorContent(editorDocumentId),
    options
  );

export default useEditorContent;
