import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkbox, Popover } from 'antd';
import { Trans } from 'react-i18next';
import { EPC_LANGUAGES } from '../../../constants';
import useTranslation from '../../../translations';
import CountryItem from './CountryItem';

const NATIONAL_LAW_LINK =
  'https://www.epo.org/law-practice/legal-texts/html/natlaw/en/iv/index.htm';
const LONDON_AGREEMENT_LINK =
  'https://www.epo.org/law-practice/legal-texts/london-agreement.html';

interface Props {
  checked: boolean;
  onCheck: (checked: boolean) => void;
}

const EpcLanguageSelection: React.FC<Props> = ({ checked, onCheck }) => {
  const t = useTranslation();
  const { colors } = useTheme();

  return (
    <Outer checked={checked}>
      <Checkbox checked={checked} onChange={(e) => onCheck(e.target.checked)} />
      <Label>{t('TRANSLATION_DOWNLOAD.EPC_LABEL')}</Label>
      <Popover
        content={
          <PopoverContent>
            <Info>
              <Trans
                t={t}
                i18nKey="TRANSLATION_DOWNLOAD.EPC_INFO"
                components={{
                  nl: <InfoHighlight href={NATIONAL_LAW_LINK} />,
                  la: <InfoHighlight href={LONDON_AGREEMENT_LINK} />,
                }}
              />
            </Info>
            <Countries>
              {EPC_LANGUAGES.map((l) => (
                <CountryItem
                  key={l}
                  language={l}
                  style={{ gap: 12 }}
                  nameStyle={{ color: colors.white70 }}
                />
              ))}
            </Countries>
            <NotSupported>
              {t('TRANSLATION_DOWNLOAD.EPC_NOT_SUPPORTED')}
            </NotSupported>
          </PopoverContent>
        }
        trigger="hover"
        placement="rightTop"
        destroyTooltipOnHide
        align={{ offset: [0, -50] }}
        overlayInnerStyle={{
          borderRadius: 10,
          backgroundColor: '#22232d',
          boxShadow: '0 30px 60px 0 rgba(0, 0, 0, 0.17)',
          padding: '16px 10px 20px 29px',
        }}
      >
        <InfoIcon className="icn-info-outlined" />
      </Popover>
    </Outer>
  );
};

export default EpcLanguageSelection;

const Outer = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  .ant-checkbox-inner {
    ${(props) =>
      !props.checked && `border-color: ${props.theme.colors.white20}`};
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.white87};
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
`;

const Info = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.white50};
`;

const InfoHighlight = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue500};
`;

const Countries = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 360px;
  gap: 10px;
`;

const NotSupported = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.white40};
`;

const InfoIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 20px;
  color: ${(props) => props.theme.colors.blue100_30};
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
    color: ${(props) => props.theme.colors.white87};
  }
`;
