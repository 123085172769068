import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { DocumentMetadata, SpinSize } from '../types';
import { useDocumentAsBase64 } from '../queries';
import { Spinner } from './Spinner';

export type DocThumbTypes = 'dark' | 'light';

interface Props {
  document?: DocumentMetadata;
  placeholder?: React.ReactNode;
  spinSize?: SpinSize;
  styleOverride?: CSSObject;
  type?: DocThumbTypes;
  hoverStyle?: boolean;
}

const DocThumb: React.FC<Props> = ({
  document,
  placeholder,
  spinSize = 'small',
  styleOverride,
  type = 'light',
  hoverStyle = false,
}) => {
  const thumbnailUri = document?.thumbnailUri ?? '';

  const { data, isLoading, isIdle, isError } = useDocumentAsBase64(
    thumbnailUri,
    { enabled: thumbnailUri !== '' }
  );

  let content: React.ReactNode;
  switch (true) {
    case placeholder && data == null:
      content = placeholder;
      break;
    case isIdle:
    case isLoading:
    case isError:
      content = <Spinner size={spinSize} />;
      break;
    case data != null:
      content = <StyledImg src={data as string} />;
  }

  return (
    <Outer
      hoverStyle={hoverStyle}
      type={type}
      isEmpty={!data}
      style={styleOverride}
    >
      {content}
    </Outer>
  );
};

export default DocThumb;

const Outer = styled.div<{
  type: DocThumbTypes;
  isEmpty: boolean;
  hoverStyle: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  ${(props) => {
    switch (props.type) {
      case 'dark':
        return props.isEmpty
          ? `background-color: ${props.theme.colors.white10};`
          : `background-color: ${props.theme.colors.white100};`;
      case 'light':
        return `background-color: ${props.theme.colors.white100};`;
      default:
        return `background-color: ${props.theme.colors.white100};`;
    }
  }};
  ${(props) => props.hoverStyle && 'opacity: 0.6;'}
`;

const StyledImg = styled.img`
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
