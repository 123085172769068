import { EditorState, Transaction } from 'prosemirror-state';

import { redo, redoDepth } from 'prosemirror-history';
import UICommand from './UICommand';

class HistoryRedoCommand extends UICommand {
  isEnabled = (state: EditorState): boolean => redoDepth(state) > 0;

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => redo(state, dispatch);
}

export default HistoryRedoCommand;
