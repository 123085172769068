import { RequestSignup } from '../types';
import Api from './base';

const API_BASE = '/v1/auth';

export const verifySignup = (token: string): Promise<boolean> =>
  Api.post(`${API_BASE}/verify-signup/${token}`).then(
    (response) => response.data
  );

export const requestSignup = (request: RequestSignup): Promise<boolean> =>
  Api.post(`${API_BASE}/request-signup`, request).then(
    (response) => response.data
  );

export const getGroupUsers = (groupId: string): Promise<boolean> =>
  Api.post(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`).then(
    (response) => response.data
  );

export const checkEmail = (email: string): Promise<boolean> =>
  Api.get(`${API_BASE}/check-email/${email}`).then((response) => response.data);
