import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { Language } from '../../../types';
import CountryFlag from '../../CountryFlag';
import { Icon } from '../../Icon';
import LanguagePickerItem from './LanguagePickerItem';

interface Props {
  languages: (Language | { language: Language; content: React.ReactNode })[];
  activeLanguage?: Language;
  highlightedLanguages?: Language[];
  header?: React.ReactNode | ((onClose: () => void) => void);
  onChange: (language: Language) => void;
  triggerRender?: (isActive: boolean, onClick: () => void) => void;
}

const LanguagePicker: React.FC<Props> = ({
  languages,
  activeLanguage,
  highlightedLanguages,
  header,
  onChange,
  triggerRender,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prev) => !prev);

  const handleLanguageClick = (language: Language) => {
    onChange(language);
    closePopover();
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom']}
        align="start"
        containerStyle={{ marginTop: '8px', zIndex: '1000' }}
        onClickOutside={closePopover}
        content={
          <Outer onClick={(e) => e.stopPropagation()}>
            {header && (
              <>
                {typeof header === 'function' ? header(closePopover) : header}
                <Divider />
              </>
            )}
            <Inner>
              {languages.map((l) => {
                let lang: Language;
                let content: React.ReactNode;

                if (typeof l === 'object') {
                  lang = l.language;
                  content = l.content;
                } else {
                  lang = l;
                }

                return (
                  <LanguagePickerItem
                    key={lang}
                    language={lang}
                    highlighted={
                      !highlightedLanguages ||
                      highlightedLanguages.includes(lang)
                    }
                    content={content}
                    onClick={handleLanguageClick}
                  />
                );
              })}
            </Inner>
          </Outer>
        }
      >
        {triggerRender?.(isPopoverOpen, togglePopover) || (
          <Trigger $active={isPopoverOpen} onClick={togglePopover}>
            <CountryFlag countryCode={activeLanguage} showLabel size={18} />
            <Icon className="icn-chevron-down" />
          </Trigger>
        )}
      </Popover>
    </>
  );
};

export default LanguagePicker;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(
    120deg,
    ${(props) => props.theme.colors.primary600},
    ${(props) => props.theme.colors.primary800}
  );
`;

const Inner = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(10, auto);
  gap: 0 20px;
`;

const Divider = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.white10};
`;

const Trigger = styled.div<{
  $active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0 5px;
  font-size: 14px;
  font-weight: bold;
  gap: 5px;
  height: 30px;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
  ${(props) =>
    props.$active && `background-color: ${props.theme.colors.primary200_20};`};
`;
