import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { truncate } from '../../utils';
import TsdTreeControlsButton from './TsdTreeControlsButton';

interface Props {
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onScrollToTop: () => void;
  onScrollToBottom: () => void;
  onScrollToSelected?: () => void;
  disableAll?: boolean;
  tsdName?: string;
  customContent?: React.ReactNode;
  style?: React.CSSProperties;
}

const TsdTreeControls: React.FC<Props> = ({
  onExpandAll,
  onCollapseAll,
  onScrollToTop,
  onScrollToBottom,
  onScrollToSelected,
  disableAll,
  tsdName,
  customContent,
  style,
}) => {
  const t = useTranslation();

  return (
    <TreeControls style={style}>
      <TitleWrapper>
        {tsdName && <TsdName>{tsdName}</TsdName>}
        {customContent}
      </TitleWrapper>
      <TsdTreeControlsButton
        testId="tsd-tree-controls-expand-all"
        iconClassName="icn-sort-default"
        tooltipText={t('TSD_TREE.CONTROLS.EXPAND_ALL')}
        onClick={onExpandAll}
        disabled={disableAll}
      />
      <TsdTreeControlsButton
        testId="tsd-tree-controls-collapse-all"
        iconClassName="icn-collapse-all"
        tooltipText={t('TSD_TREE.CONTROLS.COLLAPSE_ALL')}
        onClick={onCollapseAll}
        disabled={disableAll}
      />
      <TsdTreeControlsButton
        testId="tsd-tree-controls-to-top"
        iconClassName="icn-chevron-up"
        tooltipText={t('TSD_TREE.CONTROLS.TO_TOP')}
        onClick={onScrollToTop}
        disabled={disableAll}
      />
      <TsdTreeControlsButton
        testId="tsd-tree-controls-to-bottom"
        iconClassName="icn-chevron-down"
        tooltipText={t('TSD_TREE.CONTROLS.TO_BOTTOM')}
        onClick={onScrollToBottom}
        disabled={disableAll}
      />
      <TsdTreeControlsButton
        testId="tsd-tree-controls-to-selected"
        iconClassName="icn-locate"
        tooltipText={t('TSD_TREE.CONTROLS.TO_SELECTED')}
        onClick={onScrollToSelected}
        disabled={disableAll || !onScrollToSelected}
      />
    </TreeControls>
  );
};

export default TsdTreeControls;

const TreeControls = styled.div`
  height: 48px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary700};
  border-radius: 8px;
  margin-bottom: 10px;
`;

const TsdName = styled.div`
  font-weight: 700;
  font-size: 16px;
  ${truncate()}
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  margin-right: 20px;
`;
