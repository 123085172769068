import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
import CountryFlag from '../CountryFlag';
import { Language } from '../../types';
import ContentLanguageItem from './ContentLanguageItem';

interface Props {
  value: Language;
  languages: Language[];
  style?: React.CSSProperties;
  onChange: (lang: Language) => void;
}

const ContentLanguageBase: React.FC<Props> = ({
  value,
  languages,
  style,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleLanguageChange = (lang: Language) => {
    onChange(lang);
    setIsOpen(false);
  };

  const popoverContent = (
    <Outer data-testid="content-language-popover-container">
      {languages.map((l) => (
        <ContentLanguageItem
          key={l}
          language={l}
          active={l === value}
          onClick={handleLanguageChange}
        />
      ))}
    </Outer>
  );

  return (
    <Popover
      open={isOpen}
      placement="right"
      content={popoverContent}
      trigger="click"
      onOpenChange={(v) => setIsOpen(v)}
      destroyTooltipOnHide
    >
      <Trigger style={style} data-testid="content-language-trigger">
        <CountryFlag countryCode={value} size={24} />
      </Trigger>
    </Popover>
  );
};

export default ContentLanguageBase;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
`;
