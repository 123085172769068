import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { InferType } from 'yup';
import { Space, message, Button, Modal, Input, Typography } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextModuleTypeSelector from './TextModuleTypeSelector';
import { Api, Components, T, Hooks, Utils } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { addTsdElementTextModuleSchema } from 'validations';
import { useAppContext } from 'contexts';
import { QueryKey } from 'queries';
import useTranslation from 'translations';

const { useInvalidateQueries } = Hooks;
const { getCurrentLanguageValue } = Utils;
const { Text } = Typography;
const { FormField, LanguagePicker } = Components.Form;
const { TextArea } = Input;

interface Props {
  textModule?: T.TsdElementTextModule;
  onCloseRequest: () => void;
}

type FormValues = InferType<typeof addTsdElementTextModuleSchema>;

const SaveTextModuleModal: React.FC<Props> = ({
  textModule,
  onCloseRequest,
}) => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { languages, preferredLanguage } = useAppContext();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const appLanguage = getCurrentLanguageValue();

  const isEditMode = textModule !== undefined;

  const methods = useForm({
    defaultValues: {
      textModuleTypeId: textModule?.textModuleTypeId ?? '',
      language: textModule?.language ?? preferredLanguage,
      text: textModule?.text ?? '',
    },
    resolver: yupResolver(addTsdElementTextModuleSchema),
  });

  const createTextModuleMutation = useMutation(
    (values: T.CreateTsdElementTextModule) =>
      Api.tsd.addTsdElementTextModule(values),
    {
      onSuccess: async () => {
        message.success(t('TEXT_MODULES.ADD_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementTextModules, tsdElementId, tsdNodeId, appLanguage]
        );
        onCloseRequest();
      },
    }
  );

  const updateTextModuleMutation = useMutation(
    (values: T.UpdateTsdElementTextModule) =>
      Api.tsd.updateTsdElementTextModule(values),
    {
      onSuccess: async () => {
        message.success(t('TEXT_MODULES.UPDATE_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementTextModules, tsdElementId, tsdNodeId, appLanguage]
        );
        onCloseRequest();
      },
    }
  );

  const handleSubmit = (values: FormValues) => {
    applyChange(() => {
      if (textModule) {
        updateTextModuleMutation.mutate({
          ...values,
          tsdElementId,
          textModuleId: textModule.id,
          preferredLanguage: appLanguage,
        });
      } else {
        createTextModuleMutation.mutate({
          ...values,
          tsdElementId,
          tsdNodeId,
          preferredLanguage: appLanguage,
        });
      }
    });
  };

  return (
    <Modal
      width={700}
      onCancel={onCloseRequest}
      destroyOnClose
      title={t(
        isEditMode ? 'TEXT_MODULES.UPDATE_TITLE' : 'TEXT_MODULES.ADD_TITLE'
      )}
      open={true}
      footer={null}
    >
      <Outer>
        <Text>{t('TEXT_MODULES.INTRO')}</Text>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Inner>
              <FormField
                name="textModuleTypeId"
                label={t('TEXT_MODULES.TYPE')}
                component={TextModuleTypeSelector}
                style={{ flex: 1 }}
              />
              <FormField
                name="language"
                label={t('LANGUAGE.LABEL')}
                component={LanguagePicker}
                options={languages}
                style={{ width: 150 }}
              />
            </Inner>
            <FormField
              name="text"
              label={t('TEXT_MODULES.TEXT')}
              component={TextArea}
              rows={10}
              autoFocus
            />
            <Footer>
              <Space>
                <Button onClick={onCloseRequest}>{t('ACTION.CANCEL')}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={
                    createTextModuleMutation.isLoading ||
                    updateTextModuleMutation.isLoading ||
                    isInvalidating
                  }
                >
                  {t(
                    isEditMode
                      ? 'TEXT_MODULES.UPDATE_BUTTON'
                      : 'TEXT_MODULES.ADD_NEW_BUTTON'
                  )}
                </Button>
              </Space>
            </Footer>
          </Form>
        </FormProvider>
      </Outer>
    </Modal>
  );
};

export default SaveTextModuleModal;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Inner = styled.div`
  display: flex;
  gap: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
