import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { T, Components } from '@ipos/shared';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';
import useTranslation from 'translations';

const { CountryFlag } = Components;
const { Paragraph } = Typography;

interface Props {
  item: T.TsdElementDescription;
  warnings: string[];
  onRemoveRequest: (item: T.TsdElementDescription) => void;
  onEditRequest: (item: T.TsdElementDescription) => void;
}

const DescriptionListItem: React.FC<Props> = ({
  item,
  warnings,
  onRemoveRequest,
  onEditRequest,
}) => {
  const t = useTranslation();
  const { value, language, isBaseAttribute } = item;

  return (
    <TsdElementAttributeListItem
      warnings={warnings}
      isBaseAttribute={isBaseAttribute}
      onRemoveRequest={() => onRemoveRequest(item)}
      onEditRequest={() => onEditRequest(item)}
      confirmRemovalTitle={t('DESCRIPTIONS.DELETE_TITLE')}
    >
      <Inner>
        <CountryFlag
          countryCode={language}
          size={18}
          style={{ marginTop: 3 }}
        />
        <Text
          ellipsis={{
            rows: 4,
            expandable: false,
          }}
        >
          {value}
        </Text>
      </Inner>
    </TsdElementAttributeListItem>
  );
};

export default DescriptionListItem;

const Inner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  min-width: 0;
  padding: 8px 10px 8px 0;
`;

const Text = styled(Paragraph)`
  flex: 1;
  margin-bottom: 0 !important;
  white-space: pre-wrap;
`;
