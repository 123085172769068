import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import SHARED_ROUTES from '../../utils/routing';
import { PageError as ErrorIcon } from '../../icons';
import { PrimaryButton } from '../Button';

const PageError: React.FC = () => {
  const t = useTranslation();

  return (
    <Content>
      <ErrorIcon />
      <Title>{t('BACKEND_ERROR')}</Title>
      <SubTitle>{t('BACKEND_ERROR_DETAILS')}</SubTitle>
      <BackToLoginButton
        onClick={() => {
          window.location.href = SHARED_ROUTES.LOGIN.path;
        }}
      >
        {t('SIGNUP.BACK_TO_LOGIN')}
      </BackToLoginButton>
    </Content>
  );
};

export default PageError;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.white87};
  font-size: 24px;
  line-height: 24px;
`;

const SubTitle = styled.div`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white60};
`;

const BackToLoginButton = styled(PrimaryButton)`
  width: 250px;
  height: 44px;
  padding: 10px 80px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.blue600};
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: ${(props) => props.theme.colors.blue500};
  }
`;
