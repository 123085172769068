import ResizeObserver from 'resize-observer-polyfill';

type ClientRectLikeReadOnly = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type ResizeCallback = (r: ResizeObserverEntry) => void;

type Entries = ResizeObserverEntry[];

// Lightweight utilities to make observing resize of DOM element easier
// with `ResizeObserver`.
// See https://developers.google.com/web/updates/2016/10/resizeobserver
// Usage:
//   `ResizeObserver.observe(element, (entry) => console.log(entry))`
//   `ResizeObserver.unobserve(element)`

export type ResizeObserverEntry = {
  target: Element;
  contentRect: ClientRectLikeReadOnly;
};

let instance: ResizeObserver | null = null;

const nodesObserving: Map<Element, Array<ResizeCallback>> = new Map();

function onResizeObserve(entries: Entries): void {
  entries.forEach(handleResizeObserverEntry);
}

function handleResizeObserverEntry(entry: ResizeObserverEntry): void {
  const node = entry.target;
  const callbacks = nodesObserving.get(node);
  const executeCallback = (cb: ResizeCallback) => cb(entry);
  callbacks && callbacks.forEach(executeCallback);
}

export function observe(
  node: HTMLElement,
  callback: (entry: ResizeObserverEntry) => void
): void {
  const el: HTMLElement = node;
  const observer = instance || (instance = new ResizeObserver(onResizeObserve));
  if (nodesObserving.has(el)) {
    // Already observing node.
    const callbacks = nodesObserving.get(el);
    callbacks?.push(callback);
  } else {
    const callbacks = [callback];
    nodesObserving.set(el, callbacks);
    observer.observe(el);
  }
}

export function unobserve(node: HTMLElement, callback?: ResizeCallback): void {
  const observer = instance;
  if (!observer) {
    return;
  }
  const el: HTMLElement = node;
  observer.unobserve(el);

  if (callback) {
    const callbacks: ResizeCallback[] | null = nodesObserving.has(el)
      ? (nodesObserving.get(el) as ResizeCallback[]).filter(
          (cb: ResizeCallback) => cb !== callback
        )
      : null;
    if (callbacks && callbacks.length) {
      nodesObserving.set(el, callbacks);
    } else {
      nodesObserving.delete(el);
    }
  } else {
    // Delete all callbacks for the node.
    nodesObserving.delete(el);
  }

  if (!nodesObserving.size) {
    // We have nothing to observe. Stop observing, which stops the
    // ResizeObserver instance from receiving notifications of
    // DOM resizing. Until the observe() method is used again.
    // According to specification a ResizeObserver is deleted by the garbage
    // collector if the target element is deleted.
    observer.disconnect();
    instance = null;
  }
}

export default {
  observe,
  unobserve,
};
