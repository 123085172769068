import React from 'react';
import { useMutation } from 'react-query';
import { message, notification } from 'antd';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import AddTextModuleButton from './AddTextModuleButton';
import TextModulesList from './TextModulesList';
import { Api, Components, Hooks, Utils } from '@ipos/shared';
import { useAppContext } from 'contexts';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { QueryKey, useTsdElementTextModules } from 'queries';
import useTranslation from 'translations';

const { Notification } = Components;
const { useInvalidateQueries } = Hooks;
const { getCurrentLanguageValue } = Utils;

const TextModules: React.FC = () => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { preferredLanguage } = useAppContext();
  const { invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const appLanguage = getCurrentLanguageValue();

  const textModulesQuery = useTsdElementTextModules(
    tsdElementId,
    tsdNodeId,
    appLanguage
  );

  const deleteTextModuleMutation = useMutation(
    (textModuleId: string) =>
      Api.tsd.deleteTsdElementTextModule(tsdElementId, textModuleId),
    {
      onSuccess: () => {
        message.success(t('TEXT_MODULES.DELETE_SUCCESS'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementTextModules, tsdElementId, tsdNodeId, appLanguage]
        );
      },
      onError: () => {
        notification.error({
          message: t('ATTRIBUTES.DELETE_FAIL'),
          description: t('TEXT_MODULES.DELETE_FAIL'),
        });
      },
    }
  );

  const handleRemoveRequest = (textModuleId: string) => {
    applyChange(() => deleteTextModuleMutation.mutate(textModuleId));
  };

  if (textModulesQuery.isLoading || textModulesQuery.isIdle) {
    return <Notification spin spinSize="large" />;
  }

  if (textModulesQuery.isError) {
    return <Notification error />;
  }

  const { data: textModules } = textModulesQuery;

  return (
    <TsdElementAttributesPane
      showWarning={textModules.length === 0}
      warningText={t('TEXT_MODULES.NO_ENTRIES')}
    >
      <div>
        <AddTextModuleButton />
      </div>
      <TextModulesList
        data={textModules}
        onRemoveRequest={handleRemoveRequest}
      />
    </TsdElementAttributesPane>
  );
};

export default TextModules;
