import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { Icon } from '../Icon';
import { Colors } from '../../theme';
import { auth as authApi } from '../../api';
import { useDebounce } from '../../hooks';
import { QueryKey } from '../../queries';
import { Spinner } from '../Spinner';
import useTranslation from '../../translations';
import { truncate } from '../../utils';

enum ValidationStatus {
  Success,
  Error,
  Default,
}

const emailSchema = yup.object({
  email: yup.string().email().required(),
});

interface Props {
  value: string;
  showErrorWhenEmpty: boolean;
  onChange: (value: string) => void;
  onValidChange: (valid: boolean) => void;
}

const EmailChecker: React.FC<Props> = ({
  value,
  showErrorWhenEmpty,
  onChange,
  onValidChange,
}) => {
  const t = useTranslation();
  const debouncedValue = useDebounce(value, 800);

  const {
    data: isValidSignUpEmail,
    isLoading,
    isError: isValidationError,
    error,
  } = useQuery<boolean, AxiosError>(
    [QueryKey.AuthEmailValidation, debouncedValue],
    async (): Promise<boolean> => {
      try {
        await emailSchema.validate({
          email: debouncedValue,
        });
      } catch {
        throw new Error('SIGNUP.ERRORS.INVALID_EMAIL_FORMAT');
      }
      try {
        return await authApi.checkEmail(debouncedValue);
      } catch {
        throw new Error('SIGNUP.ERRORS.VALIDATION');
      }
    },
    {
      enabled: debouncedValue !== '',
      cacheTime: 0,
      onSettled(isValid) {
        onValidChange(!!isValid);
      },
    }
  );

  let icon: React.ReactNode = (
    <Icon className="icn-error-outlined" size={19} color={Colors.error} />
  );
  let message: string | undefined;
  let status = ValidationStatus.Default;

  switch (true) {
    case showErrorWhenEmpty && debouncedValue.trim() === '':
      message = 'SIGNUP.ERRORS.INVALID_EMAIL_FORMAT';
      status = ValidationStatus.Error;
      break;
    case isValidationError:
      message = error?.message;
      status = ValidationStatus.Error;
      break;
    case isLoading:
      icon = <Spinner customSize={16} color={Colors.secondary200} />;
      status = ValidationStatus.Default;
      break;
    case isValidSignUpEmail !== undefined && isValidSignUpEmail:
      icon = (
        <Icon
          className="icn-done-outlined"
          size={19}
          color={Colors.secondary200}
        />
      );
      message = 'SIGNUP.VALID_EMAIL';
      status = ValidationStatus.Success;
      break;
    case isValidSignUpEmail !== undefined && isValidSignUpEmail === false:
      message = 'SIGNUP.ERRORS.INVALID_EMAIL_CHECK';
      status = ValidationStatus.Error;
      break;
    default:
      icon = null;
  }

  return (
    <Outer>
      <Inner status={status}>
        <StyledInput
          name="email"
          placeholder={t('SIGNUP.EMAIL_PLACEHOLDER')}
          type="text"
          autoComplete="off"
          autoFocus
          value={value}
          onChange={(e) => onChange(e.target.value)}
          data-testid="email-checker"
        />
        {icon}
      </Inner>
      {message && <ValidationMsg status={status}>{t(message)}</ValidationMsg>}
    </Outer>
  );
};

export default EmailChecker;

function getBorderColor(status: ValidationStatus): string {
  switch (status) {
    case ValidationStatus.Success:
      return `border-color: ${Colors.secondary200};`;
    case ValidationStatus.Error:
      return `border-color: ${Colors.error};`;
    default:
      return '';
  }
}

const Outer = styled.div`
  position: relative;
  width: 300px;
  height: 44px;
  min-width: 0;
`;

const Inner = styled.div<{
  status: ValidationStatus;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary200_30};
  padding-right: 12px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: border-color 0.3s linear;
  &:focus-within {
    border-color: ${(props) => props.theme.colors.blue700};
    ${(props) => getBorderColor(props.status)}
  }
  ${(props) => getBorderColor(props.status)}
`;

const StyledInput = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  line-height: 1.71;
  padding: 10px 10px 10px 20px;
  color: ${(props) => props.theme.colors.white87};
  &::placeholder {
    color: ${(props) => props.theme.colors.white40};
  }
`;

const ValidationMsg = styled.div<{ status: ValidationStatus }>`
  position: absolute;
  top: 100%;
  width: 100%;
  line-height: 1.75;
  font-size: 12px;
  animation-name: antFadeIn;
  animation-duration: 0.3s;
  color: ${(props) => props.theme.colors.secondary200};
  ${truncate()}
  ${({ status, theme }) =>
    status === ValidationStatus.Error && `color: ${theme.colors.error};`}
`;
