import { MarkSpec } from 'prosemirror-model';

const strong: MarkSpec = {
  parseDOM: [
    { tag: 'strong' },
    // This works around a Google Docs misbehavior where
    // pasted content will be inexplicably wrapped in `<b>`
    // tags with a font-weight normal.
    {
      tag: 'b',
      getAttrs: (node) => {
        if (node instanceof HTMLElement) {
          return node.style.fontWeight !== 'normal' && null;
        }
        return null;
      },
    },
    {
      style: 'font-weight',
      getAttrs: (value) => {
        if (typeof value === 'string') {
          return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null;
        }
        return null;
      },
    },
  ],
  toDOM() {
    return ['strong', 0];
  },
};

export default strong;
