import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { FlatButton } from '../Button';
import useTranslation from '../../translations';
import { useGetPaStakeholders } from '../../queries';
import { ModalState, SCREEN } from '../../types';
import { Icon } from '../Icon';
import StakeholdersArray from './StakeholdersArray';
import StakeholdersModal from './Modal';

interface Props {
  patentApplicationId: string;
  interactive?: boolean;
  avatarStyle?: CSSObject;
}

const Stakeholders: React.FC<Props> = ({
  patentApplicationId,
  interactive = true,
  avatarStyle,
}) => {
  const t = useTranslation();
  const [modalState, setModalState] = useState<ModalState>({
    currentScreen: SCREEN.VIEW_STAKEHOLDERS,
    panelOpen: false,
    activeStakeholder: null,
    breadcrumbs: new Set(),
  });

  const { data: stakeholders } = useGetPaStakeholders(patentApplicationId);
  return (
    <Outer>
      {interactive && (
        <StakeholdersModal
          setModalState={setModalState}
          patentApplicationId={patentApplicationId}
          modalState={modalState}
        />
      )}
      <StakeholdersArray
        stakeholders={stakeholders}
        avatarStyle={avatarStyle}
      />
      {interactive && (
        <AddButton
          leftIcon={<Icon className="icn-add" size={22} />}
          onClick={() =>
            setModalState((prev) => ({ ...prev, panelOpen: true }))
          }
        >
          {t('STAKEHOLDERS.ADD_RESPONSIBLE_COUNSEL')}
        </AddButton>
      )}
    </Outer>
  );
};

const AddButton = styled(FlatButton)`
  width: 150px;
`;

const Outer = styled.div`
  display: flex;
  align-items: center;
`;

export default Stakeholders;
