import React, { useContext, useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { DocumentListItem, DocumentMetadata } from '../../types';
import { DocumentPickerItem } from './types';

const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

type OpenSearchInView = (
  document: DocumentMetadata,
  index: number,
  searchTerm: string
) => void;

interface ContextState {
  isSearchActive: boolean;
  visibleDocCount: number;
  documents: DocumentPickerItem[];
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  openSearchInView: OpenSearchInView;
  isDownloadActive: boolean;
  setDownloadActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiDocContext = React.createContext<ContextState | undefined>(
  undefined
);

interface Props {
  currentDocuments: DocumentMetadata[];
  visibleDocCount: number;
  documentList?: DocumentListItem[];
  openSearchInView: OpenSearchInView;
}

const mapDocToItem = (d: DocumentMetadata): DocumentPickerItem => ({
  id: d.id,
  name: d.name,
  checked: false,
  hasOcrLayer: !!d.selectableTextUris?.length,
  document: d,
});

const mapDocList = (l: DocumentListItem): DocumentPickerItem => ({
  id: `${l.name}${uuid()}`,
  name: l.name,
  open: true,
  subItems:
    l.documents.length > 0
      ? l.documents.map<DocumentPickerItem>(mapDocToItem)
      : l.subLists.map(mapDocList),
});

const filterDefaultGuids = (items: DocumentPickerItem[]) =>
  items.filter((item) => {
    if (item.id === DEFAULT_GUID) {
      return false; // Filter out objects with default GUID
    } else {
      // Filter out child objects recursively
      if (item.subItems) {
        item.subItems = filterDefaultGuids(item.subItems);
        if (!item.subItems.length) {
          // Filter out parents with no child objects (list containers, not documents)
          return false;
        }
      }
      return true; // Keep objects without default GUID
    }
  });

export const MultiDocProvider: React.FC<React.PropsWithChildren<Props>> = ({
  currentDocuments,
  documentList,
  visibleDocCount,
  children,
  openSearchInView,
}) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const [isDownloadActive, setDownloadActive] = useState(false);

  const documents = useMemo<DocumentPickerItem[]>(() => {
    if (!documentList) {
      return currentDocuments.map(mapDocToItem);
    }
    const list = documentList.map(mapDocList);
    return filterDefaultGuids(list);
  }, [currentDocuments, documentList]);

  return (
    <MultiDocContext.Provider
      value={{
        isSearchActive,
        documents,
        visibleDocCount,
        setSearchActive,
        openSearchInView,
        isDownloadActive,
        setDownloadActive,
      }}
    >
      {children}
    </MultiDocContext.Provider>
  );
};

export const useMultiDocContext = (): ContextState => {
  const context = useContext(MultiDocContext);
  if (!context) {
    throw new Error(
      'useMultiDocContext must be used within a MultiDocProvider'
    );
  }
  return context;
};
