import React, { useContext } from 'react';
import { useApplication } from '../components/Application';
import { UserSessionQuery, PayPerUseServiceType } from '../types';
import { useGroupSettings, usePayPerUseServiceStatus } from '../queries';

export const UserSessionQueryContext = React.createContext<
  UserSessionQuery | undefined
>(undefined);

export const UserSessionQueryProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { session, isSessionError, isSessionLoading, isBackendUnreachable } =
    useApplication();
  const enabled = session != null;

  const groupSettingsQuery = useGroupSettings({
    enabled,
  });
  const translationServiceQuery = usePayPerUseServiceStatus(
    PayPerUseServiceType.Translation,
    { enabled }
  );

  const isLoading =
    isSessionLoading ||
    groupSettingsQuery.isLoading ||
    translationServiceQuery.isLoading;

  const isError =
    isSessionError ||
    groupSettingsQuery.isError ||
    translationServiceQuery.isError;

  return (
    <UserSessionQueryContext.Provider
      value={{
        session,
        groupSettings: groupSettingsQuery.data,
        translationService: translationServiceQuery.data,
        isLoading,
        isError,
        isBackendUnreachable,
      }}
    >
      {children}
    </UserSessionQueryContext.Provider>
  );
};

export function useUserSessionQueryContext(): UserSessionQuery {
  const context = useContext(UserSessionQueryContext);
  if (!context) {
    throw new Error(
      'useUserSessionQueryContext must be used within UserSessionQueryProvider'
    );
  }
  return context;
}
