export { default as useAadUser } from './useAadUser';
export { default as useDocumentAsBase64 } from './useDocumentAsBase64';
export { default as useDocumentAsBlob } from './useDocumentAsBlob';
export { default as useDocumentAsBlobById } from './useDocumentAsBlobById';
export { default as useDocumentMetadata } from './useDocumentMetadata';
export { default as useDocumentOcrProcess } from './useDocumentOcrProcess';
export { default as useFileAsBlob } from './useFileAsBlob';
export { default as usePatentPublication } from './usePatentPublication';
export { default as useGetPaStakeholders } from './useGetPaStakeholders';
export { default as useGroupSettings } from './useGroupSettings';
export { default as useDocumentProxy } from './useDocumentProxy';
export { default as useAnnotationSets } from './useAnnotationSets';
export { default as useCompetithorReportsSearch } from './useCompetithorReportsSearch';
export { default as usePatentHistory } from './usePatentHistory';
export { default as usePatentApplicationList } from './usePatentApplicationList';
export { default as usePatentRevisionById } from './usePatentRevisionById';
export { default as useEditorContent } from './useEditorContent';
export { default as useEditorClaimsContent } from './useEditorClaimsContent';
export { default as usePayPerUseServiceStatus } from './usePayPerUseServiceStatus';
export { default as useGetPatstatApplicantsInventors } from './useGetPatstatApplicantsInventors';
export { default as useGetTsdElementIpcClasses } from './useGetTsdElementIpcClasses';
export { default as useGetPatstatCpcClasses } from './useGetPatstatCpcClasses';
export { default as useGetPatstatIpcClasses } from './useGetPatstatIpcClasses';
export { default as useTutorials } from './useTutorials';
export { default as useGetUserSettings } from './useGetUserSettings';
export { default as useSearchDocument } from './useSearchDocument';
export { default as useGetContactByUserIdentity } from './useGetContactByUserIdentity';

export enum QueryKey {
  EditorChangeset = 'shared.editorChangeset',
  EditorContent = 'shared.editorContent',
  PatentApplicationList = 'shared.patentApplicationList',
  PatentRevisionById = 'shared.patentRevisionById',
  AuthEmailValidation = 'shared.authEmailValidation',
  GroupSettings = 'shared.groupSettings',
  AadUser = 'shared.aadUser',
  GroupUsers = 'shared.groupUsers',
  PatentHistory = 'PatentHistory',
  DocumentAsBlob = 'shared.documentAsBlob',
  DocumentAsBlobById = 'shared.documentAsBlobById',
  DocumentAsBase64 = 'shared.documentAsBase64',
  DocumentMetadata = 'shared.documentMetadata',
  DocumentOcrProcess = 'shared.documentOcrProcess',
  FileAsBlob = 'shared.fileAsBlob',
  Session = 'shared.session',
  AnnotationSets = 'shared.annotationSets',
  PaStakeholders = 'shared.paStakeholders',
  DocumentProxy = 'shared.documentProxy',
  PatentPublication = 'shared.patentPublication',
  DocTranslations = 'docViewer.translations',
  DocTranslationState = 'docViewer.translation-state',
  CompetithorReportsSearch = 'shared.competithorReportsSearch',
  PayPerUseServiceStatus = 'shared.payPerUseServiceStatus',
  PatstatApplicantsInventors = 'shared.patstatApplicantsInventors',
  TsdElementIpcClasses = 'shared.tsdElementIpcClasses',
  ListColumnFilter = 'shared.listColumnFilter',
  PatstatCpcClass = 'shared.patstatCpcClass',
  PatstatIpcClass = 'shared.patstatIpcClass',
  PatentPublicationBatchImportProcess = 'shared.patentPublicationBatchImportProcess',
  SearchPrompts = 'shared.searchPrompts',
  UserSettings = 'shared.userSettings',
  UserNotes = 'shared.userNotes',
  AiAssistantAttachments = 'shared.aiAssistantAttachments',
  OfficeAction = 'shared.officeAction',
  OfficeActionReviewResult = 'shared.officeActionReviewResult',
  StoredAiResponses = 'shared.storedAiResponses',
  SearchDocument = 'shared.searchDocument',
  SearchMultipleDocuments = 'shared.searchMultipleDocuments',
  DocumentsAsZip = 'shared.documentsAsZip',
  UndoRedo = 'shared.undoRedo',
  GetContact = 'shared.getContact',
  GetContactByUserIdentity = 'shared.getContactByUserIdentity',
  GetAppVersion = 'shared.getAppVersion',
  Tutorials = 'shared.tutorials',
}
