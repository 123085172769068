import { BaseDto } from './common';
import { Language } from './language';

export interface OwnerGroupSettings extends BaseDto {
  tsdLanguages: Language[];
  inComPortalServerUrl: string;
  inComPortalIntegrationActive: boolean;
  patBaseEnabled: boolean;
  ownerGroupId: string;
}

export enum PayPerUseServiceType {
  Translation = 'Translation',
  AiTextCompletion = 'AiTextCompletion',
}

export interface PayPerUseServiceSettings {
  monthlyLimit: number;
  singleRequestLimit: number;
}

export interface PayPerUseServiceStatus {
  settings: PayPerUseServiceSettings;
  maxMonthlyCost: number;
  quantityThisMonth: number;
  costThisMonth: number;
  usageLast30Days: [Date, number][];
  usagePerMonth: [Date, number][];
}
