import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import useTranslation from '../../../translations';
import { Language } from '../../../types';
import { DownArrowIcon } from './CountryPicker';

const { Option } = Select;

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

const LanguagePicker: React.FC<Props> = ({ value, onChange, defaultValue }) => {
  const t = useTranslation();

  const handleChange = (newValue: string[]): void => {
    onChange(newValue);
  };

  const languages = Object.values(Language).map((v) => (
    <Option key={v} value={v}>
      <Flex>
        <Label>{t(`LANGUAGE.${v.toUpperCase()}`)}</Label>
      </Flex>
    </Option>
  ));

  return (
    <Outer>
      <Select
        suffixIcon={<DownArrowIcon className="icn-chevron-down" />}
        mode="multiple"
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        placeholder="Select"
        showArrow
      >
        {languages}
      </Select>
    </Outer>
  );
};

export default LanguagePicker;

const Label = styled.span``;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Outer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 15px;
    background: none;
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    transition: border 0.2s ease-in-out;
    border: none;
    width: 100%;
    border-radius: 0;
    input {
      &:focus {
        outline: none;
      }
      outline: none;
      border: none;
      box-shadow: none;
      background: none;
      display: flex;
      flex: 1;
    }
    .ant-select-selection-placeholder {
      padding: 0;
      left: 0px;
    }
  }
  .ant-select-arrow {
    top: 9px;
    right: 9px;
  }
  .ant-select {
    width: 100%;
  }
`;
