import { Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../theme';

interface Props {
  label?: string;
}

const CommandGroupDivider: React.FC<Props> = ({ label }) => {
  if (!label) {
    return null;
  }
  return (
    <Outer>
      <Label>{label}</Label>
      <StyledDivider />
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px;
`;

const Label = styled.div`
  margin-top: 10px;
  font-size: 11px;
  color: ${Colors.white30};
`;

const StyledDivider = styled(Divider)`
  color: ${Colors.white30};
  margin: 0;
`;

export default CommandGroupDivider;
