import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { queryClient } from '../../../..';
import { QueryKey, useGetPaStakeholders } from '../../../queries';
import { ModalState, SCREEN } from '../../../types';
import Modal from '../../Modal';
import { Icon } from '../../Icon';
import StakeholdersOverview from './StakeholdersOverview';
import ModalTitle from './ModalTitle';

interface Props {
  patentApplicationId: string;
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
}

const StakeholdersModal: React.FC<Props> = ({
  patentApplicationId,
  modalState,
  setModalState,
}) => {
  const { data: stakeholders } = useGetPaStakeholders(patentApplicationId, {
    enabled: modalState.panelOpen,
  });

  const onStakeholderUpdate = useCallback(() => {
    queryClient.invalidateQueries([
      QueryKey.PaStakeholders,
      patentApplicationId,
    ]);
    queryClient.invalidateQueries([QueryKey.GroupUsers]);
  }, []);

  useEffect(() => {
    setModalState((prev) => ({
      ...prev,
      breadcrumbs: new Set(prev.breadcrumbs.add(modalState.currentScreen)),
    }));
  }, [modalState.currentScreen]);

  const backToPrevious = useCallback(() => {
    setModalState((prev) => {
      const previousScreen = [...prev.breadcrumbs][prev.breadcrumbs.size - 2];
      const updatedBreadcrumbs = new Set<SCREEN>(prev.breadcrumbs);
      updatedBreadcrumbs.delete(prev.currentScreen);
      return {
        ...prev,
        activeStakeholder: null,
        breadcrumbs: updatedBreadcrumbs,
        currentScreen: previousScreen
          ? previousScreen
          : SCREEN.VIEW_STAKEHOLDERS,
      };
    });
  }, [setModalState, modalState]);

  const closeModal = useCallback(
    () => setModalState((prev) => ({ ...prev, panelOpen: false })),
    [setModalState]
  );

  return (
    <Modal
      destroyOnClose
      title={
        <Flex>
          <ModalTitle
            modalState={modalState}
            onBackToPrevious={backToPrevious}
          />
        </Flex>
      }
      open={modalState.panelOpen}
      width="70%"
      style={{
        maxWidth: 660,
      }}
      bodyStyle={{
        height: 'calc(100vh - 300px)',
        minHeight: 0,
        display: 'flex',
        padding: 0,
      }}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IconClose className="icn-clear" />}
    >
      <StakeholdersOverview
        backToPrevious={backToPrevious}
        modalState={modalState}
        setModalState={setModalState}
        onStakeholdersUpdate={onStakeholderUpdate}
        stakeholders={stakeholders}
        patentApplicationId={patentApplicationId}
      />
    </Modal>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: ${(props) => props.theme.colors.white50};
    font-size: 20px;
    cursor: pointer;
    font-weight: 400;
    &:last-child {
      font-weight: 700;
      color: ${(props) => props.theme.colors.white87};
    }
  }
`;

const IconClose = styled(Icon)`
  font-size: 24px;
`;

export default StakeholdersModal;
