import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';
import { Tree, Modal, message } from 'antd';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { TsdNode, MoveTsdNode } from '../../types';
import { usePermissionCheck } from '../../permissions';
import { Api } from '../../..';
import { Spinner } from '../Spinner';
import useTranslation from '../../translations';
import TsdTreeSearch from './TsdTreeSearch';
import TsdTreeControls from './TsdTreeControls';
import TsdTreePlaceholder from './TsdTreePlaceholder';
import useTsdTreeStructure from './useTsdTreeStructure';
import useTsdTreeHelpers from './useTsdTreeHelpers';
import useScrollToNode from './useScrollToNode';
import useTsdTreeData from './useTsdTreeData';

const { confirm } = Modal;

type NodeDragEventData = NodeDragEventParams & {
  dragNode: EventDataNode<unknown>;
  dropToGap: boolean;
};

type NodeExpandEventData = {
  node: EventDataNode<unknown>;
  expanded: boolean;
};

type NodeCheckEventData = {
  node: EventDataNode<unknown>;
  checked: boolean;
};

interface Props {
  root?: TsdNode | null;
  selectedTsdNodeId?: string;
  draggable?: boolean;
  selectable?: boolean;
  checkable?: boolean;
  searchable?: boolean;
  onSelect?: (tsdNode: TsdNode) => void;
  onCheck?: (tsdNodeIds: string[]) => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
  placeholder?: React.ReactNode;
  showControls?: boolean;
  controlsStyle?: React.CSSProperties;
  showTsdNameInControls?: boolean;
  additionalControlsContent?: React.ReactNode;
  tsdNodeIds?: string[];
  onAddNode?: (tsdNodeId: string) => void;
  onRemoveNode?: (tsdNodeId: string) => void;
}

const TsdTree: React.FC<Props> = ({
  root,
  selectedTsdNodeId,
  draggable = false,
  selectable = false,
  checkable = false,
  searchable = false,
  onSelect,
  onCheck,
  style,
  isLoading,
  placeholder,
  showControls = true,
  controlsStyle,
  showTsdNameInControls,
  additionalControlsContent,
  onAddNode,
  onRemoveNode,
  tsdNodeIds,
}) => {
  const t = useTranslation();
  const queryClient = useQueryClient();
  const allowedToEdit = usePermissionCheck(
    AzureGroupsConfig.PermissionCompanyTsdEdit
  );
  const isInitialLoadRef = useRef<boolean>(true);

  const [search, setSearch] = useState<string>('');

  const { tree, prevTree, tsdId, hasTsdChanged } = useTsdTreeStructure(root);

  const {
    findNodeById,
    getAllParentNodeIds,
    getBranchNodeIds,
    generateConfirmMessage,
  } = useTsdTreeHelpers(tree);

  const [expandedNodeIds, setExpandedNodeIds] = useState<Set<string>>(() =>
    getAllParentNodeIds(tree)
  );
  const [checkedNodeIds, setCheckedNodeIds] = useState<Set<string>>(new Set());

  const treeContainerRef = useRef<HTMLDivElement>(null);
  const { scrollToNode } = useScrollToNode({
    treeContainerRef,
    setExpandedNodeIds,
    findNodeById,
  });

  const handleScrollToSelected = useCallback(() => {
    if (selectedTsdNodeId) {
      scrollToNode(selectedTsdNodeId);
    }
  }, [selectedTsdNodeId, scrollToNode]);

  // Jump to selected node when TSD changes
  useEffect(() => {
    if ((isInitialLoadRef.current && tsdId) || hasTsdChanged) {
      handleScrollToSelected();
    }
  }, [tsdId, hasTsdChanged, handleScrollToSelected]);

  // Adjust expanded nodes when tree structure has changed while TSD remains the same
  useEffect(() => {
    if (isInitialLoadRef.current || !tsdId || hasTsdChanged) {
      return;
    }
    setExpandedNodeIds((prevState) => {
      // Filter out deleted parent nodes
      const parentNodeIds = getAllParentNodeIds(tree);
      const expandedIds = Array.from(prevState);
      let updatedExpandedIds = expandedIds.filter((nodeId) =>
        parentNodeIds.has(nodeId)
      );
      // Add new parent nodes to extended
      if (prevTree && prevTree[0]?.tsdId === tree[0]?.tsdId) {
        const prevParentNodeIds = getAllParentNodeIds(prevTree);
        const newParentNodeIds = Array.from(parentNodeIds).filter(
          (nodeId) => !prevParentNodeIds.has(nodeId)
        );
        updatedExpandedIds = [...updatedExpandedIds, ...newParentNodeIds];
      }
      return new Set(updatedExpandedIds);
    });
  }, [tsdId, hasTsdChanged, tree, getAllParentNodeIds]);

  // Reset expanded and checked nodes when TSD has changed
  useEffect(() => {
    if ((isInitialLoadRef.current && tsdId) || hasTsdChanged) {
      const parentNodeIds = getAllParentNodeIds(tree);
      setExpandedNodeIds(parentNodeIds);
      setCheckedNodeIds(new Set<string>());
    }
  }, [tsdId, hasTsdChanged, tree, getAllParentNodeIds]);

  // Update initial loading flag according to root node change
  useEffect(() => {
    // Tsd tree has mounted
    if (isInitialLoadRef.current && tsdId) {
      isInitialLoadRef.current = false;
      // Tsd tree has unmounted
    } else if (isInitialLoadRef.current === false && !tsdId) {
      isInitialLoadRef.current = true;
    }
  }, [tsdId]);

  const moveNodeMutation = useMutation(
    (moveNodeData: MoveTsdNode) => Api.tsd.moveNode(moveNodeData),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['tsdData', tsdId]);
        message.success(t('TSD_TREE.MOVE_NODE.SUCCESS'));
      },
    }
  );

  const handleSelect = (selectedKeys: React.Key[]) => {
    if (!selectedKeys.length || !onSelect) {
      return;
    }
    const [tsdNodeId] = selectedKeys;
    const selectedTsdNode = findNodeById(tsdNodeId as string).targetNode;
    if (selectedTsdNode) {
      onSelect(selectedTsdNode);
    }
  };

  const handleDrop = (info: NodeDragEventData) => {
    if (!tsdId) {
      return;
    }
    const { dragNode, node, dropToGap } = info;

    const tsdNode = findNodeById(node.key as string).targetNode;
    const draggedTsdNode = findNodeById(dragNode.key as string).targetNode;

    if (!tsdNode || !draggedTsdNode) {
      return;
    }

    let parentTsdNodeId = tsdNode.parentNodeId ?? tsdNode.id;
    let moveAfterTsdNodeId: string | undefined = tsdNode.id;

    // Guard against dropping as root node sibling
    if (dropToGap && parentTsdNodeId === moveAfterTsdNodeId) {
      return;
    }
    // Node is dropped as first child of parent node
    if (!dropToGap) {
      parentTsdNodeId = tsdNode.id;
      moveAfterTsdNodeId = undefined;
    }

    confirm({
      title: t('TSD_TREE.MOVE_NODE.TITLE'),
      content: generateConfirmMessage(draggedTsdNode, parentTsdNodeId),
      okText: t('ACTION.CONFIRM'),
      cancelText: t('ACTION.CANCEL'),
      onOk() {
        return new Promise((resolve) => {
          moveNodeMutation.mutate(
            {
              tsdId,
              parentTsdNodeId,
              moveAfterTsdNodeId,
              tsdNodeId: draggedTsdNode.id,
            },
            {
              onSettled: resolve,
            }
          );
        });
      },
    });
  };

  const handleExpand = (
    _expandedKeys: Key[],
    info: NodeExpandEventData
  ): void | undefined => {
    const { node, expanded } = info;
    const updatedNodeIds = new Set(expandedNodeIds);
    if (expanded) {
      updatedNodeIds.add(node.key as string);
    } else {
      updatedNodeIds.delete(node.key as string);
    }
    setExpandedNodeIds(updatedNodeIds);
  };

  const handleExpandAll = () => {
    const parentNodeIds = getAllParentNodeIds(tree);
    setExpandedNodeIds(parentNodeIds);
  };

  const handleCollapseAll = () => {
    if (root) {
      setExpandedNodeIds(new Set<string>([root.id]));
    }
  };

  const handleScrollToTop = () => {
    treeContainerRef.current?.scrollTo(0, 0);
  };

  const handleScrollToBottom = () => {
    const treeContainer = treeContainerRef.current;
    if (treeContainer) {
      treeContainer.scrollTo(0, treeContainer.scrollHeight);
    }
  };

  const getUncheckedInBetweenNodeIds = (
    parentIds: string[],
    checkedIds: string[]
  ) => {
    const uncheckedNodeIds: string[] = [];
    for (const nodeId of parentIds) {
      if (checkedIds.includes(nodeId)) {
        break;
      }
      uncheckedNodeIds.push(nodeId);
    }
    return uncheckedNodeIds.length === parentIds.length ? [] : uncheckedNodeIds;
  };

  const getUpdatedCheckedNodeIds = (
    targetNode: TsdNode,
    parentsToRoot: TsdNode[],
    isChecked: boolean
  ) => {
    const checkedIds = Array.from(checkedNodeIds);
    const branchIds = getBranchNodeIds(targetNode);
    if (isChecked) {
      const parentNodeIds = parentsToRoot.map(({ id }) => id);
      const inBetweenIds = getUncheckedInBetweenNodeIds(
        parentNodeIds,
        checkedIds
      );
      return new Set([...checkedIds, ...branchIds, ...inBetweenIds]);
    }
    return new Set(checkedIds.filter((nodeId) => !branchIds.includes(nodeId)));
  };

  const handleCheck = (_checked: unknown, info: NodeCheckEventData) => {
    const { node, checked: isChecked } = info;
    const nodeId = node.key as string;
    const { targetNode, parentsToRoot } = findNodeById(nodeId);
    if (!targetNode) {
      return;
    }

    const updatedCheckedIds = getUpdatedCheckedNodeIds(
      targetNode,
      parentsToRoot,
      isChecked
    );
    setCheckedNodeIds(updatedCheckedIds);
    onCheck?.(Array.from(updatedCheckedIds));
  };

  const treeData = useTsdTreeData({
    tree,
    search: searchable ? search : undefined,
    tsdNodeIds,
    onAddNode,
    onRemoveNode,
  });

  const getTsdTreeContent = () => {
    if (isLoading) {
      return <TsdTreePlaceholder placeholder={<Spinner size="large" />} />;
    }
    if (root) {
      const checkedKeys = Array.from(checkedNodeIds);
      const isDraggable =
        draggable && allowedToEdit && checkedKeys.length === 0;
      return (
        <Tree
          style={{ width: '100%', height: '100%', paddingRight: 5 }}
          selectedKeys={selectedTsdNodeId ? [selectedTsdNodeId] : []}
          expandedKeys={Array.from(expandedNodeIds)}
          checkedKeys={{ checked: checkedKeys, halfChecked: [] }}
          draggable={isDraggable && { icon: false }}
          selectable={selectable}
          checkable={checkable}
          checkStrictly
          onExpand={handleExpand}
          onDrop={handleDrop}
          onSelect={handleSelect}
          onCheck={handleCheck}
          blockNode
          showLine={{ showLeafIcon: false }}
        >
          {treeData}
        </Tree>
      );
    }
    return <TsdTreePlaceholder placeholder={placeholder} />;
  };

  return (
    <Outer data-testid="tsd-tree" style={style}>
      {searchable && <TsdTreeSearch search={search} onChange={setSearch} />}
      {showControls && (
        <TsdTreeControls
          onExpandAll={handleExpandAll}
          onCollapseAll={handleCollapseAll}
          onScrollToTop={handleScrollToTop}
          onScrollToBottom={handleScrollToBottom}
          onScrollToSelected={
            selectedTsdNodeId ? handleScrollToSelected : undefined
          }
          disableAll={!root}
          tsdName={showTsdNameInControls ? root?.name : undefined}
          customContent={additionalControlsContent}
          style={controlsStyle}
        />
      )}
      <TreeWrapper
        ref={treeContainerRef}
        isSelectionDisabled={onSelect === undefined}
      >
        {getTsdTreeContent()}
      </TreeWrapper>
    </Outer>
  );
};

export default TsdTree;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
  height: 100%;
`;

const TreeWrapper = styled.div<{
  isSelectionDisabled: boolean;
}>`
  flex: 1;
  overflow: auto;
  padding: 8px 0 8px 5px;

  .ant-tree-show-line .ant-tree-switcher {
    background-color: transparent;
  }
  .ant-tree-switcher-leaf-line::before {
    border-left-color: ${(props) => props.theme.colors.white100};
  }
  .ant-tree-indent-unit::before {
    border-right-color: ${(props) => props.theme.colors.white100};
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: ${(props) => props.theme.colors.blue300};
    .ant-tree-title {
      .tsd-tree__node {
        color: ${(props) => props.theme.colors.white87};
      }
    }
  }
  .ant-tree-node-content-wrapper {
    ${(props) => props.isSelectionDisabled && 'cursor: default'}
  }
  .ant-tree-node-content-wrapper:not(.ant-tree-node-selected):hover {
    ${(props) => props.isSelectionDisabled && 'background-color: transparent'}
`;
