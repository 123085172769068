import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';
import { Components, Colors } from '@ipos/shared';
import useTranslation from 'translations';

const { Icon } = Components;

interface Props {
  title: string;
  testId?: string;
  count?: number;
  disabled?: boolean;
}

const TsdElementAttributeTitle: React.FC<Props> = ({
  title,
  testId,
  count,
  disabled = 0,
}) => {
  const t = useTranslation();

  return (
    <Outer data-testid={testId}>
      {title}
      {count ? (
        <StyledBadge count={count} />
      ) : (
        !disabled && (
          <Icon
            className="icn-info-outlined"
            title={t('ATTRIBUTES.MISSING_DATA')}
            color={Colors.warning}
          />
        )
      )}
    </Outer>
  );
};

export default TsdElementAttributeTitle;

const Outer = styled.div`
  padding-left: 8px;
  display: flex;
  align-items: center;
  .anticon {
    margin-right: 0px;
  }
  gap: 6px;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background-color: ${(props) => props.theme.colors.primary800};
  }
`;
