import { EditorView } from 'prosemirror-view';
import type { EditorState, Transaction } from 'prosemirror-state';
import { DOMSerializer, Fragment, Node } from 'prosemirror-model';
import { schema } from '../schema/schema';
import { uniqueNodeId } from '../uniqueNodeId';
import { processImagesAndCopy } from '../util';

export type OnTransactionChange = (
  tr: Transaction,
  oldState: EditorState,
  newState: EditorState
) => void;

export interface CreateEditorViewProps {
  root: HTMLElement | null;
  readonlyState: boolean;
  getTargetEditorView: () => EditorView | null;
  editorState: EditorState;
  onTransactionChange: OnTransactionChange;
  attributes?: Record<string, string>;
}
export function createEditorView({
  root,
  readonlyState,
  getTargetEditorView,
  editorState,
  onTransactionChange,
  attributes,
}: CreateEditorViewProps) {
  const view = new EditorView(root, {
    attributes,
    editable: () => !readonlyState,
    handleScrollToSelection: () => true,
    dispatchTransaction: (tr) => {
      const targetEditorView = getTargetEditorView();
      bindTransaction(tr, view, targetEditorView, onTransactionChange);
    },
    // handlePaste,
    handleDOMEvents: {
      copy: handleCopyDOMEvent,
    },
    state: editorState,
  });

  return view;
}

function bindTransaction(
  tr: Transaction,
  editingEditorView: EditorView,
  targetEditorView: EditorView | null,
  onChange: OnTransactionChange
) {
  const oldState = editingEditorView.state;
  const updatedTr = uniqueNodeId(
    tr,
    oldState,
    editingEditorView.state.apply(tr)
  );
  const newState = editingEditorView.state.apply(updatedTr);

  editingEditorView.updateState(newState);
  targetEditorView?.updateState(targetEditorView?.state.apply(updatedTr));
  onChange(tr, oldState, newState);
}

function handleCopyDOMEvent(view: EditorView, event: ClipboardEvent) {
  if (!event.clipboardData) {
    return false;
  }

  const { state } = view;
  const { selection } = state;
  const { $from, $to } = selection;
  const { doc } = state;

  const from = $from.pos;
  const to = $to.pos;

  const slice = doc.slice(from, to);

  const nodeTypesToCheck = [
    'section',
    'paragraph',
    'embeddedObject',
    'image',
    'text',
  ];

  const copiedNodes: Node[] = [];
  let foundNodeType: string | null = null;

  slice.content.descendants((node) => {
    if (!foundNodeType || node.type === schema.nodes[foundNodeType]) {
      if (nodeTypesToCheck.includes(node.type.name) && !foundNodeType) {
        foundNodeType = node.type.name;
      }
      if (foundNodeType && node.type === schema.nodes[foundNodeType]) {
        copiedNodes.push(node);
      }
    }
  });

  if (copiedNodes.length === 0) {
    return false;
  }

  // Create a new slice from the collected nodes
  const newSlice = Fragment.fromArray(copiedNodes);

  // Serialize the slice to a string
  const serializer = DOMSerializer.fromSchema(schema);
  const div = document.createElement('div');
  serializer.serializeFragment(newSlice, { document }, div);
  const text = newSlice.textBetween(0, newSlice.size, '\n');

  processImagesAndCopy(div, text);

  event.preventDefault();
}

// TODO: causes wrong behaviour needs revision
// function handlePaste(
//   view: EditorView,
//   _e: ClipboardEvent,
//   slice: Slice
// ): boolean {
//   console.log(_e);
//   const { tr } = view.state;
//   const font = detectMostUsedFont(view);
//   const sliceNode = parseHTML(getTextContentOfSlice(slice));

//   // get only the paragraph nodes which are not empty
//   const paragraphNodes: Node[] = [];
//   sliceNode.descendants((node) => {
//     if (node.type.name === 'paragraph' && node.textContent !== '') {
//       paragraphNodes.push(node);
//     }
//   });

//   const newSlice = new Slice(
//     Fragment.fromArray(paragraphNodes),
//     slice.openStart,
//     slice.openEnd
//   );

//   const startPosition = tr.selection.$anchor.pos;
//   tr.replaceSelection(newSlice); // it adds the slice content to the document
//   const endPosition = tr.selection.$anchor.pos;

//   // add the font mark to the pasted content
//   const fontMarkType = schema.marks[MARK_FONT_TYPE];
//   if (fontMarkType) {
//     const mark = fontMarkType.create({ name: font });
//     tr.addMark(startPosition, endPosition, mark);
//   }

//   view.dispatch(tr);

//   return true;
// }
