import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  PatstatReportItems,
  PatstatApplicantsInventors,
  PatstatCpcClass,
  PatstatIpcClass,
} from '../../../types';
import { hover } from '../../../utils';
import { Spinner } from '../../Spinner';
import useTranslation from '../../../translations';
import { B2 } from '../../Atoms/Atoms';
import EmptyNotification from '../../EmptyNotification';
import PatstatStakeholderItem from './PatstatStakeholderItem';
import PatstatCpcClassItem from './PatstatCpcClassItem';
import PatstatIpcClassItem from './PatstatIpcClassItem';

interface Props {
  stakeholders?: PatstatApplicantsInventors[];
  cpcclasses?: PatstatCpcClass[];
  ipcclasses?: PatstatIpcClass[];
  publicationNumber: string;
  isLoading: boolean;
}

const PatstatStatisticsOverview: React.FC<Props> = ({
  stakeholders = [],
  cpcclasses = [],
  ipcclasses = [],
  publicationNumber,
  isLoading,
}) => {
  const t = useTranslation();

  const PATSTAT_STAKEHOLDERS = useMemo(
    () => [
      {
        type: PatstatReportItems.Applicant,
        title: t('COMPETITOR.ROLES.APPLICANTS'),
        pubNr: publicationNumber,
        members:
          stakeholders?.filter(
            (s) => s.type === PatstatReportItems.Applicant
          ) || [],
      },
      {
        type: PatstatReportItems.Inventor,
        title: t('COMPETITOR.ROLES.INVENTORS'),
        pubNr: publicationNumber,
        members:
          stakeholders?.filter((s) => s.type === PatstatReportItems.Inventor) ||
          [],
      },
    ],
    [stakeholders]
  );

  const PATSTAT_IPCCLASSES = useMemo(
    () => [
      {
        type: PatstatReportItems.IpcClass,
        title: t('COMPETITOR.ROLES.IPC_CLASSES'),
        pubNr: publicationNumber,
        members: ipcclasses || [],
      },
    ],
    [ipcclasses]
  );

  const PATSTAT_CPCCLASSES = useMemo(
    () => [
      {
        type: PatstatReportItems.CpcClass,
        title: t('COMPETITOR.ROLES.CPC_CLASSES'),
        pubNr: publicationNumber,
        members: cpcclasses || [],
      },
    ],
    [cpcclasses]
  );

  return (
    <Outer>
      {isLoading && (
        <SpinIndicator>
          <Spinner tip={t('COMPETITOR.CHART_PROGRESS')} size="large" />
        </SpinIndicator>
      )}
      {!isLoading &&
        PATSTAT_STAKEHOLDERS.map((sg) => {
          const stakeholdersCount =
            sg?.members?.length > 0 ? `(${sg?.members?.length})` : '';
          return (
            <Section key={sg.type}>
              <SectionHeader>
                <SectionTitle>{`${
                  sg.type === PatstatReportItems.Applicant
                    ? t('COMPETITOR.COMPANY_INFOGRAPHIC.LIST_HEADER')
                    : t('COMPETITOR.INVENTOR_INFOGRAPHIC.LIST_HEADER')
                } ${stakeholdersCount}`}</SectionTitle>
              </SectionHeader>
              <Inner>
                {sg?.members?.length === 0 && (
                  <NoData>
                    <EmptyNotification
                      message={t('NO_DATA_FOUND')}
                      style={{ maxHeight: 100, minHeight: 50 }}
                    />
                  </NoData>
                )}
                {sg?.members?.map((m, i) => (
                  <ItemWrapper key={`${m.type}-${i}`}>
                    <PatstatStakeholderItem
                      name={m.name}
                      type={m.type}
                      psnId={m.psnId}
                    />
                  </ItemWrapper>
                ))}
              </Inner>
            </Section>
          );
        })}
      {!isLoading &&
        PATSTAT_IPCCLASSES.map((sg) => {
          const ipcCount =
            sg?.members?.length > 0 ? `(${sg?.members?.length})` : '';
          return (
            <Section key={sg.type}>
              <SectionHeader>
                <SectionTitle>{`${t(
                  'COMPETITOR.TECH_FIELD_ANALYSIS.LIST_HEADER_IPC'
                )} ${ipcCount}`}</SectionTitle>
              </SectionHeader>
              <Inner>
                {sg?.members?.length === 0 && (
                  <NoData>
                    <EmptyNotification
                      message={t('NO_DATA_FOUND')}
                      style={{ maxHeight: 100, minHeight: 50 }}
                    />
                  </NoData>
                )}
                {sg?.members?.map((m, i) => (
                  <ItemWrapper key={`${m.ipcClassSymbol}-${i}`}>
                    <PatstatIpcClassItem
                      ipcCode={m.ipcClassSymbol}
                      ipcDescription={m.title}
                    />
                  </ItemWrapper>
                ))}
              </Inner>
            </Section>
          );
        })}
      {!isLoading &&
        PATSTAT_CPCCLASSES.map((sg) => {
          const cpcCount =
            sg?.members?.length > 0 ? `(${sg?.members?.length})` : '';
          return (
            <Section key={sg.type}>
              <SectionHeader>
                <SectionTitle>{`${t(
                  'COMPETITOR.TECH_FIELD_ANALYSIS.LIST_HEADER_CPC'
                )} ${cpcCount}`}</SectionTitle>
              </SectionHeader>
              <Inner>
                {sg?.members?.length === 0 && (
                  <NoData>
                    <EmptyNotification
                      message={t('NO_DATA_FOUND')}
                      style={{ maxHeight: 100, minHeight: 50 }}
                    />
                  </NoData>
                )}
                {sg?.members?.map((m, i) => (
                  <ItemWrapper key={`${m.cpc}-${i}`}>
                    <PatstatCpcClassItem
                      cpcCode={m.cpc}
                      cpcDescription={m.title}
                    />
                  </ItemWrapper>
                ))}
              </Inner>
            </Section>
          );
        })}
    </Outer>
  );
};

const SpinIndicator = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary200_40};
`;

const SectionTitle = styled(B2)`
  color: ${(props) => props.theme.colors.white60};
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const Outer = styled.div`
  overflow-y: auto;
  min-height: 300px;
  flex: 1;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemWrapper = styled.div`
  margin: 0 -40px;
  padding: 6px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  ${hover()};
  > div:last-child {
    visibility: visible;
  }
`;

const NoData = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PatstatStatisticsOverview;
