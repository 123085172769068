import React, { useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Popover, Progress, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import useTranslation from '../../translations';
import { useForceUpdate } from '../../hooks';
import { T } from '../../..';
import { H2 } from '../Atoms/Atoms';
import { IconButton } from '../Button';
import { truncate } from '../../utils';
import LegalRemediesPopup from './LegalRemediesPopup';

enum DateUnit {
  Day,
  Month,
  Year,
}

type DueDate = {
  days: number;
  months: number;
  years: number;
  daysLeft: number;
};

function calculatePercentage(daysLeft: number): number {
  if (daysLeft >= 0) {
    return Math.max(0, 100 - daysLeft);
  }
  return 100;
}

function parseDueDate(dueDate: Dayjs): DueDate {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const currentDateUtc = dayjs.utc(currentDate);
  const dueDateDiff = dueDate.diff(currentDateUtc);
  const daysLeft = Math.floor(dayjs.duration(dueDateDiff).asDays());
  const diffDuration = dayjs.duration(Math.abs(dueDateDiff));
  const [years, months, days] = diffDuration.format('Y M D').split(' ');
  return {
    days: +days,
    months: +months,
    years: +years,
    daysLeft,
  };
}

function getCurrentDay(): number {
  return dayjs().day();
}

interface Props {
  dueDate: Dayjs;
  legalRemedies?: T.LegalRemedies | null;
  lossOfRights?: T.LossOfRights | null;
  disableTooltip?: boolean;
}

const DueDate: React.FC<Props> = ({
  dueDate,
  legalRemedies,
  lossOfRights,
  disableTooltip,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const { forceUpdate } = useForceUpdate();
  // Update component when new day begins
  const currentDayRef = useRef<number>(getCurrentDay());
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDay = getCurrentDay();
      if (currentDay !== currentDayRef.current) {
        forceUpdate();
        currentDayRef.current = currentDay;
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const colorFn = (v: number): string => {
    switch (true) {
      case v < 33.33:
        return theme.colors.success;
      case v < 66.66:
        return theme.colors.warning;
      default:
        return theme.colors.error;
    }
  };

  const translateDateUnit = (unit: DateUnit, value: number): string => {
    const absValue = Math.abs(value);
    switch (unit) {
      case DateUnit.Day:
        return absValue === 1 ? t('TIME.DAY') : t('TIME.DAYS');
      case DateUnit.Month:
        return absValue === 1 ? t('TIME.MONTH') : t('TIME.MONTHS');
      case DateUnit.Year:
        return absValue === 1 ? t('TIME.YEAR') : t('TIME.YEARS');
    }
  };

  const translateDateUnitString = (
    unit: DateUnit,
    value: number,
    discardZeroValue = false
  ) => {
    if (discardZeroValue && value === 0) {
      return '';
    }
    return `${value} ${translateDateUnit(unit, value)}`;
  };

  const getDueDateShort = (date: DueDate): [number, string] => {
    const { days, months, years, daysLeft } = date;
    switch (true) {
      case daysLeft <= 99 && daysLeft >= 0:
        return [daysLeft, translateDateUnit(DateUnit.Day, daysLeft)];
      case years > 0:
        return [years, translateDateUnit(DateUnit.Year, years)];
      case months > 0:
        return [months, translateDateUnit(DateUnit.Month, months)];
      default:
        return [days, translateDateUnit(DateUnit.Day, days)];
    }
  };

  const getDueDateLong = (date: DueDate): string => {
    const { days, months, years, daysLeft } = date;
    if (daysLeft === 0) {
      return t('HOME_LIST.DUE_DATE.DUE_TODAY');
    }
    const y = translateDateUnitString(DateUnit.Year, years, true);
    const m = translateDateUnitString(DateUnit.Month, months, true);
    const d = translateDateUnitString(DateUnit.Day, days, true);
    const dateString = `${y && `${y} `}${m && `${m} `}${d}`;
    if (daysLeft > 0) {
      return t('HOME_LIST.DUE_DATE.DUE_IN', { dateString });
    }
    return t('HOME_LIST.DUE_DATE.OVERDUE_LONG', { dateString });
  };

  const dd = parseDueDate(dueDate);
  const dueDateShort = getDueDateShort(dd);
  const dueDateLong = getDueDateLong(dd);
  const percentage = calculatePercentage(dd.daysLeft);
  const overdue = dd.daysLeft < 0;
  const dueToday = dd.daysLeft === 0;

  let dueDateRender;

  switch (true) {
    case overdue:
      dueDateRender = (
        <Short>
          <H2>{dueDateShort[0]}</H2>
          <Unit>{dueDateShort[1]}</Unit>
          <Unit>{t('HOME_LIST.DUE_DATE.OVERDUE')}</Unit>
        </Short>
      );
      break;
    case dueToday:
      dueDateRender = (
        <Short>
          <Unit>{t('HOME_LIST.DUE_DATE.DUE_TODAY')}</Unit>
        </Short>
      );
      break;
    default:
      dueDateRender = (
        <Short>
          <H2>{dueDateShort[0]}</H2>
          <Unit>{dueDateShort[1]}</Unit>
        </Short>
      );
  }

  const outerContent = (
    <Outer data-testid="lifespan-indication">
      <Inner>
        {dueDateRender}
        {legalRemedies && lossOfRights && (
          <Popover
            color="#22232D"
            overlayStyle={{ padding: 20 }}
            content={
              <LegalRemediesPopup
                legalRemedies={legalRemedies}
                lossOfRights={lossOfRights}
              />
            }
            placement="right"
            destroyTooltipOnHide
          >
            <IconButton
              className="icn-error-solid"
              style={{ color: theme.colors.error }}
            />
          </Popover>
        )}
      </Inner>
      {percentage != null && (
        <StyledProgress
          showInfo={false}
          percent={percentage}
          strokeColor={colorFn(percentage)}
        />
      )}
      <Long>{dueDate.format('D MMM YYYY')}</Long>
    </Outer>
  );

  if (disableTooltip) {
    return outerContent;
  }

  return (
    <Tooltip placement="left" title={dueDateLong} destroyTooltipOnHide>
      {outerContent}
    </Tooltip>
  );
};

export default DueDate;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Short = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  flex: 1;
  ${truncate()}
`;

const StyledProgress = styled(Progress)`
  margin: -4px 0;
  .ant-progress-line {
    font-size: 12px;
  }
  .ant-progress-bg {
    height: 4px !important;
  }
`;

const Unit = styled.div``;

const Long = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.white50};
`;
