import { useReducer, DispatchWithoutAction } from 'react';

/**
 * Forcefully updating React component is considered an anti-pattern.
 * Use only where absolutely necessary!
 */
function useForceUpdate(): {
  forceUpdate: DispatchWithoutAction;
} {
  const forceUpdate = useReducer((x) => x + 1, 0)[1];
  return {
    forceUpdate,
  };
}

export default useForceUpdate;
