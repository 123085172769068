import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from './data.json';

interface Props {
  style?: React.CSSProperties;
}

const DolphinLoader: React.FC<Props> = ({ style }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const instance = lottie.loadAnimation({
      container: ref.current, // the dom element that will contain the animation
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      animationData,
    });

    return () => {
      instance.destroy();
    };
  }, []);

  return <Outer ref={ref} style={style} />;
};

const Outer = styled.div`
  height: 160px;
  width: 200px;
`;

export default DolphinLoader;
