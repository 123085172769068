import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import {
  Button,
  Input,
  message,
  Popover,
  Space,
  Table,
  Popconfirm,
} from 'antd';
import { Api, T, Components, Hooks, Permissions } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { QueryKey } from 'queries';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { useDebouncedInput, useInvalidateQueries } = Hooks;
const { CountryFlag, Notification } = Components;
const { usePermissionCheck } = Permissions;

interface Props {
  filterValues: string[];
  onCreateGroupRequest: (value: string) => void;
  onEditGroupRequest: (value: T.CompanyGroup) => void;
  onDone: () => void;
}

const AllocateExistingCompanyGroup: React.FC<Props> = ({
  filterValues,
  onCreateGroupRequest,
  onEditGroupRequest,
  onDone,
}) => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { preferredLanguage } = useAppContext();
  const { applyChange } = useElementUsageCheck();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();
  const canEditCompanyGroups = usePermissionCheck(
    AzureGroupsConfig.PermissionCompanyTsdCompanyGroupEdit
  );

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [search, handleSearchChange] = useDebouncedInput(setSearchTerm);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const newlySelectedKeys = useMemo(
    () =>
      selectedKeys
        .map((key) => `${key}`)
        .filter((key) => !filterValues.includes(key)),
    [selectedKeys, filterValues]
  );

  const companyGroupsQuery = useQuery(
    [QueryKey.CompanyGroups, searchTerm],
    () => Api.companies.getCompanyGroups(searchTerm, { skip: 0, pageSize: 100 })
  );

  const assignCompanyGroupsMutation = useMutation(
    (existingCompanyGroupIds: string[]) =>
      Api.tsd.createTsdElementCompanyGroup({
        tsdElementId,
        tsdNodeId,
        existingCompanyGroupIds,
      }),
    {
      onSuccess: async () => {
        message.success(t('COMPANIES.ASSIGN_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementCompanies, tsdElementId, tsdNodeId]
        );
        onDone();
      },
    }
  );

  const deleteCompanyGroupMutation = useMutation(
    (companyGroupId: string) =>
      Api.companies.deleteCompanyGroup(companyGroupId),
    {
      onSuccess: () => {
        message.success(t('COMPANIES.DELETE_COMPANY_GROUP.SUCCESS'));
        invalidateQueries(QueryKey.CompanyGroups);
      },
      onError: () => {
        message.error(t('COMPANIES.DELETE_COMPANY_GROUP.IN_USE'));
      },
    }
  );

  const handleDeleteCompanyGroup = useCallback(
    (companyGroupId: string) => {
      deleteCompanyGroupMutation.mutate(companyGroupId, {
        onSuccess: () => {
          if (newlySelectedKeys.includes(companyGroupId)) {
            setSelectedKeys((prevKeys) =>
              prevKeys.filter((key) => `${key}` !== companyGroupId)
            );
          }
        },
      });
    },
    [newlySelectedKeys]
  );

  const handleEditCompanyGroup = useCallback(
    (companyGroupId: string) => {
      const companyGroup = companyGroupsQuery.data?.find(
        (c) => c.id === companyGroupId
      );
      if (companyGroup) {
        onEditGroupRequest(companyGroup);
      }
    },
    [companyGroupsQuery.data]
  );

  const columns = useMemo(() => {
    const cols = [
      {
        title: t('COMPANIES.NAME'),
        key: 'name',
        dataIndex: 'name',
        width: 450,
        ellipsis: true,
      },
      {
        title: t('COMPANIES.CITY'),
        key: 'city',
        dataIndex: 'city',
      },
      {
        title: t('COMPANIES.COUNTRY'),
        key: 'countryCode',
        dataIndex: 'countryCode',
        render: (value: string) => <CountryFlag countryCode={value} />,
      },
      {
        title: t('COMPANIES.NAME_VARIATIONS'),
        key: 'countryCode',
        dataIndex: 'companiesInGroup',
        render: (nameVariations: T.Company[]) => {
          const content = (
            <ul>
              {nameVariations.map((v) => (
                <li key={v.id}>
                  {v.name}({v.numberOfApplications})
                </li>
              ))}
            </ul>
          );
          return (
            <Popover
              title={`${t('COMPANIES.NAME_VARIATIONS')} (${
                nameVariations.length
              })`}
              content={content}
            >
              {nameVariations.length}
            </Popover>
          );
        },
      },
    ];
    if (canEditCompanyGroups) {
      cols.push({
        title: '',
        key: 'actionButtons',
        dataIndex: 'id',
        render: (companyGroupId: string) => (
          <>
            <Button
              type="link"
              onClick={() => handleEditCompanyGroup(companyGroupId)}
              icon={<StyledIcon className="icn-edit" />}
            />
            <Popconfirm
              placement="top"
              title={
                <div style={{ maxWidth: 220 }}>
                  {t('COMPANIES.DELETE_COMPANY_GROUP.CONFIRMATION')}
                </div>
              }
              onConfirm={() => handleDeleteCompanyGroup(companyGroupId)}
              okText={t('YES')}
              cancelText={t('NO')}
              disabled={filterValues.includes(companyGroupId)}
            >
              <Button
                type="link"
                icon={<StyledIcon className="icn-delete" />}
                disabled={filterValues.includes(companyGroupId)}
              />
            </Popconfirm>
          </>
        ),
      });
    }
    return cols;
  }, [filterValues, handleDeleteCompanyGroup, handleEditCompanyGroup]);

  const handleNewGroupRequest = () => {
    onCreateGroupRequest(searchTerm);
  };

  const handleSelectionChange = (keys: React.Key[]) => {
    setSelectedKeys(keys);
  };

  const handleAssignCompanyGroups = () => {
    applyChange(() => assignCompanyGroupsMutation.mutate(newlySelectedKeys));
  };

  if (companyGroupsQuery.isLoading || companyGroupsQuery.isIdle) {
    return <Notification spin />;
  }

  if (companyGroupsQuery.isError) {
    return <Notification error />;
  }

  const { data: companyGroups } = companyGroupsQuery;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Input
        value={search}
        onChange={handleSearchChange}
        placeholder={t('COMPANIES.SEARCH_GROUP_PLACEHOLDER')}
        autoFocus
      />
      {companyGroups.length === 0 ? (
        <Space>
          {t('COMPANIES.NO_MATCH_FOUND')}
          <Button onClick={handleNewGroupRequest} type="link">
            {searchTerm
              ? t('COMPANIES.CLICK_TO_CREATE_WITH_NAME', { value: searchTerm })
              : t('COMPANIES.CLICK_TO_CREATE')}
          </Button>
        </Space>
      ) : (
        <>
          <Table
            rowKey="id"
            dataSource={companyGroups}
            columns={columns}
            pagination={{ pageSize: 6 }}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys: [...selectedKeys, ...filterValues],
              onChange: handleSelectionChange,
              getCheckboxProps: (record: T.CompanyGroup) => ({
                disabled: filterValues.includes(record.id),
              }),
            }}
          />
          <Footer>
            <Button onClick={onDone}>{t('ACTION.CANCEL')}</Button>
            <Button
              type="primary"
              onClick={handleAssignCompanyGroups}
              loading={
                assignCompanyGroupsMutation.isLoading ||
                deleteCompanyGroupMutation.isLoading ||
                isInvalidating
              }
              disabled={newlySelectedKeys.length === 0}
            >
              {t('COMPANIES.ADD_COMPANY_GROUPS', {
                count: newlySelectedKeys.length,
              })}
            </Button>
          </Footer>
        </>
      )}
    </Space>
  );
};

export default AllocateExistingCompanyGroup;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledIcon = styled.i`
  font-size: 20px;
  vertical-align: text-bottom;
`;
