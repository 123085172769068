import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { authRequest, clearAuthData } from '../../auth/msalInstance';
import { LOGIN_REDIRECT_URL_KEY } from '../../constants';
import useTranslation from '../../translations';
import SHARED_ROUTES, { generateUrl } from '../../utils/routing';
import { MsButton, Outer, LinkButton } from './Common';

const Login: React.FC = () => {
  const t = useTranslation();
  const { inProgress, instance } = useMsal();
  const { state } = useLocation<{ redirect?: string } | undefined>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    window.sessionStorage.setItem(
      LOGIN_REDIRECT_URL_KEY,
      state?.redirect || '/'
    );
  }, [state]);

  const loginCallback = useCallback(async () => {
    try {
      await instance.loginRedirect({
        ...authRequest,
      });
    } catch {
      clearAuthData();
      setError(true);
    }
  }, []);

  return (
    <Outer>
      <Title>{t('LOGIN.TITLE')}</Title>
      <Subtitle>{t('LOGIN.ALREADY_REGISTERED')}</Subtitle>
      <MsButton
        loading={inProgress === InteractionStatus.Login}
        label={t('LOGIN.LOGIN_WITH_MS')}
        style={{ margin: '13px 0 40px 0' }}
        onClick={loginCallback}
      />
      {error && <ErrorMsg>{t('LOGIN.ERROR')}</ErrorMsg>}
      <FirstTime>
        <Line />
        <Subtitle>{t('LOGIN.FIRST_TIME')}</Subtitle>
        <Line />
      </FirstTime>
      <Text>{t('LOGIN.FIRST_TIME_TIP')}</Text>
      <LinkButton to={generateUrl(SHARED_ROUTES.SIGNUP)} style={{ width: 250 }}>
        {t('SIGNUP.BUTTON')}
      </LinkButton>
      <TutorialPrompt>
        <Trans
          t={t}
          i18nKey="LOGIN.TUTORIAL_LINK"
          components={{
            tag: (
              <StyledLink
                // TODO: Regenerate the link so that "Evaluathor" is dropped, because it's a general signup tutorial
                href="https://philipavideos.blob.core.windows.net/video/20231101_Vv_Evaluathor_Login_Tutorial_Finalversion.mp4"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </TutorialPrompt>
    </Outer>
  );
};

export default Login;

const Title = styled.div`
  margin: 20px 0 33px;
  font-size: 22px;
  line-height: 1.36;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white100};
`;

const Subtitle = styled.div`
  font-size: 15px;
  line-height: 1.6;
  color: ${(props) => props.theme.colors.white100};
`;

const ErrorMsg = styled.div`
  margin: -35px 0 20px 0;
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  white-space: pre;
`;

const FirstTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Line = styled.div`
  width: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.white50};
`;

const Text = styled.div`
  width: 250px;
  height: 32px;
  margin: 0 0 16px;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: ${(props) => props.theme.colors.white50};
`;

const TutorialPrompt = styled.div`
  margin-top: auto;
  margin-bottom: -20px;
  font-size: 10px;
  line-height: 2.4;
  color: ${(props) => props.theme.colors.white50};
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.white87};
  font-weight: bold;
  &:focus,
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.white87};
    text-decoration: underline;
  }
`;
