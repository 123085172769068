import React from 'react';
import { PopoverPosition } from 'react-tiny-popover';
import useTranslation from '../../../translations';
import { UserSettings, PreferredGlossary } from '../../../types';
import { BasicDropdownConfig } from './BasicDropdownItem';
import BasicDropdown from './BasicDropDown';

interface Props {
  popoverPosition?: PopoverPosition[];
  containerStyle?: Partial<CSSStyleDeclaration>;
  settings?: UserSettings;
}
const GlossaryPicker: React.FC<Props> = ({
  popoverPosition,
  containerStyle,
  settings,
}) => {
  const t = useTranslation();

  const dropdownItems = [
    {
      title: t('GLOSSARY.WITHOUT_GLOSSARY'),
      value: PreferredGlossary.NoGlossary,
    },
    {
      title: t('GLOSSARY.COMPANY_GLOSSARY'),
      value: PreferredGlossary.CompanyGlossary,
    },
    {
      title: t('GLOSSARY.PHILIPA_GLOSSARY'),
      value: PreferredGlossary.PhilipaGlossary,
    },
  ] as BasicDropdownConfig[];

  return (
    <BasicDropdown
      settings={settings}
      items={dropdownItems}
      popoverPosition={popoverPosition}
      containerStyle={containerStyle}
    />
  );
};

export default GlossaryPicker;
