import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { upload } from '../../../../api/uploads';
import * as T from '../../../../types';
import useTranslation from '../../../../translations';

const { Dragger } = Upload;

interface Props {
  onSelect: (uploads: T.BlobResource[]) => void;
  onCloseRequest: () => void;
  allowMultiple?: boolean;
}

const UploadImageModal: React.FC<Props> = ({
  allowMultiple = false,
  onSelect,
  onCloseRequest,
}) => {
  const t = useTranslation();
  const [files, setFiles] = useState<Array<UploadFile>>([]);
  const handleChange = (info: UploadChangeParam): void => {
    if (allowMultiple) {
      // if multiple allowed, push selection to list
      setFiles(info.fileList);
    } else {
      const selectedFiles = info.fileList.map(
        (file) => file.originFileObj as File
      );
      // otherwise, start uploading immediately
      initializeUpload(selectedFiles);
    }
  };

  const initializeUpload = (filesToUpload: File[]) => {
    submitUploads(filesToUpload, {
      onSuccess: () => {
        setFiles([]);
      },
    });
  };

  const { mutate: submitUploads, isLoading: isSubmitting } = useMutation(
    (f: File[]) => upload(f),
    {
      onSuccess: (uploads) => {
        onSelect(uploads);
      },
    }
  );

  const handleUploadClick = () => {
    const selectedFiles = files.map((file) => file.originFileObj as File);
    // button click handler if 'allowMultiple' is set as true
    initializeUpload(selectedFiles);
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      title={t('RICH_TEXT.MEDIA.IMAGE.UPLOAD_TITLE')}
      onOk={handleUploadClick}
      onCancel={onCloseRequest}
      footer={allowMultiple}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Content>
        <StyledDragger
          disabled={!allowMultiple && files.length > 0}
          fileList={files}
          beforeUpload={() => false}
          multiple={allowMultiple}
          accept={'image/png,image/jpg,image/jpeg'}
          onChange={handleChange}
        >
          <p className="ant-upload-text">
            {t('RICH_TEXT.MEDIA.IMAGE.CLICK_OR_DRAG')}
          </p>
        </StyledDragger>
      </Content>
    </Modal>
  );
};

export default UploadImageModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
`;

const StyledDragger = styled(Dragger)`
  padding: 20px;
  width: 100%;
  height: 200px;
`;
