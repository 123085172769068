import React from 'react';
import usePermissionCheck from './usePermissionCheck';

interface Props {
  permissions: string | string[];
  children: React.ReactNode;
  forbiddenComponent?: React.ReactNode;
}

const WithPermission: React.FC<Props> = ({
  permissions,
  children,
  forbiddenComponent,
}) => {
  const isAllowed = usePermissionCheck(permissions);
  return <>{isAllowed ? children : forbiddenComponent}</>;
};

export default WithPermission;
