import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import useTranslation from '../translations';
import { Icon } from './Icon';

interface Props {
  text?: string;
}

const CopyToClipboard: React.FC<Props> = ({ text }) => {
  const t = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [isCopyDisabled, setCopyDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!tooltipVisible) {
      return;
    }
    const timeout = setTimeout(() => setTooltipVisible(false), 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [tooltipVisible]);

  const handleClick = async () => {
    if (!text) {
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      setTooltipVisible(true);
    } catch {
      // User denied clipboard write permission
      setCopyDisabled(true);
    }
  };

  return (
    <Tooltip
      destroyTooltipOnHide
      placement="top"
      title={t('COPIED_TO_CLIPBOARD')}
      open={tooltipVisible}
    >
      <Button
        title={t('COPY_TO_CLIPBOARD')}
        disabled={!text || isCopyDisabled}
        icon={
          <Icon
            className={tooltipVisible ? 'icn-checkmark' : 'icn-copy'}
            style={{ verticalAlign: 'text-bottom' }}
          />
        }
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default CopyToClipboard;
