import React, { useMemo } from 'react';
import { EditorView } from 'prosemirror-view';
import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import { Icon } from '../../../Icon';
import useTranslation from '../../../../translations';
import { ReferenceOption } from '../../../../types';
import { PluginState, selectReference } from './referencePlugin';
import { HighlightMatch } from './HighlightMatch';

interface Props {
  editorState: PluginState;
  editorView: EditorView;
  menuOptions: ReferenceOption[];
}

const ClaimReferenceMenu: React.FC<Props> = ({
  editorState,
  editorView,
  menuOptions,
}) => {
  const t = useTranslation();
  const unknownOption = editorState.text;

  const menu: ReferenceOption[] = useMemo(() => {
    const getItems = (suggestions: ReferenceOption[], value: string) =>
      value
        ? matchSorter(suggestions, value, {
            keys: ['text'],
          })
        : suggestions;

    return getItems(menuOptions, unknownOption);
  }, [unknownOption, menuOptions]);

  const onItemClick = (
    suggestion: ReferenceOption,
    isNewOption: boolean
  ): void => {
    selectReference({
      view: editorView,
      from: editorState.range.from,
      to: editorState.range.to,
      isNewOption,
      ref: suggestion.text,
    });
    editorView.focus();
  };

  return (
    <Menu>
      {unknownOption && (
        <MenuItem onClick={() => onItemClick({ text: unknownOption }, true)}>
          <Icon className="icn-add" /> {t('CLAIMS_PLUGIN.ADD_NEW_REFERENCE')}
        </MenuItem>
      )}
      {menu.map((i: ReferenceOption) => (
        <MenuItem
          key={i?.id}
          onClick={() => onItemClick({ text: i.text }, false)}
        >
          {HighlightMatch(i.text, unknownOption)}
        </MenuItem>
      ))}
    </Menu>
  );
};

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  border-radius: 12px;
  background: linear-gradient(135deg, #3b3f4f 0%, #31323f 100%);
  padding: 20px 0;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white87};
  padding: 5px 20px;
  &:hover {
    background: ${(props) => props.theme.colors.primary200_20};
  }
  i {
    font-size: 20px;
    color: ${(props) => props.theme.colors.white60};
    margin-right: 10px;
  }
`;
export default ClaimReferenceMenu;
