import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useFormContext, useController, get } from 'react-hook-form';
import useTranslation from '../../../../translations';

const SearchInput: React.FC = () => {
  const t = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { field } = useController({
    name: 'searchText',
    control,
  });

  const error = get(errors, 'searchText');

  return (
    <StyledInput
      placeholder={
        error
          ? t('MULTI_DOC_SEARCH.ENTER_SEARCH_TERM')
          : t('MULTI_DOC_SEARCH.SEARCH')
      }
      autoFocus
      autoComplete="off"
      $error={error != null}
      {...field}
    />
  );
};

export default SearchInput;

const StyledInput = styled(Input)<{ $error: boolean }>`
  height: 40px;
  width: 300px;
  margin: 0;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
  background-color: ${(props) => props.theme.colors.primary200_30};
  &.ant-input {
    box-shadow: none;
    border: 2px solid transparent;
  }
  &::placeholder {
    font-size: 15px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.white40};
    ${(props) => props.$error && `color: ${props.theme.colors.red500};`}
  }
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.colors.blue600};
  }
  ${(props) =>
    props.$error && `border-color: ${props.theme.colors.red500} !important;`}
`;
