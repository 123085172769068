import React from 'react';
import styled from 'styled-components';
import { useVeryRichContext } from '../../contexts';
import { useClickOutsideListener } from '../../hooks';
import useTranslation from '../../translations';
import { EditorContentType } from '../../types';
import { Spinner } from '../Spinner';
import { StyledEditorContainer } from './styles/styles';

import 'prosemirror-view/style/prosemirror.css';

export const DiffEditorView: React.FC = () => {
  const [focused, setFocused] = React.useState(false);
  const {
    diffEditorElementRef,
    contentType,
    onUnFocusDiff,
    hasLoadingFailed,
    loading,
  } = useVeryRichContext();
  const t = useTranslation();
  useClickOutsideListener(diffEditorElementRef.current, () => {
    if (!focused) {
      return;
    }
    setFocused(false);
    onUnFocusDiff();
  });

  if (loading) {
    return (
      <LoadingWrapper>
        <Spinner size="large" />
      </LoadingWrapper>
    );
  }

  if (hasLoadingFailed) {
    return <div>{t('RICH_TEXT.ERROR_LOADING_DOCUMENT')}</div>;
  }
  return (
    <StyledEditorContainer
      type="diff"
      contentType={contentType || EditorContentType.Description}
      onClick={() => setFocused(true)}
    >
      <div ref={diffEditorElementRef} />
    </StyledEditorContainer>
  );
};

export default DiffEditorView;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
`;
