import React from 'react';
import styled from 'styled-components';

export type BasicDropdownConfig = {
  title: string;
  onClick?: () => void;
  value: string;
};

const BasicDropdownItem: React.FC<BasicDropdownConfig> = ({
  title,
  onClick,
}) => (
  <Item onClick={onClick}>
    <StyledSpan>{title}</StyledSpan>
  </Item>
);

export default BasicDropdownItem;

const StyledIcon = styled.i`
  font-size: 20px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.white60};
`;

const StyledSpan = styled.span`
  transition: color 0.3s ease;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
`;

const Item = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 34px;
  padding: 5px 20px;
  gap: 10px;
  transition: background 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.white87};
    }
  }
`;
