import { Plugin, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Node, Schema } from 'prosemirror-model';
import { LIST_NUMBERING } from '../schema/nodes/nodeNames';

function removeEmptyClaimParts(tr: Transaction, doc: Node, schema: Schema) {
  const claimPartType = schema.nodes.claimPart;
  const paragraphType = schema.nodes.paragraph;
  const listNumberingType = schema.nodes[LIST_NUMBERING];

  doc.descendants((node, pos) => {
    if (node.type === claimPartType) {
      let hasParagraph = false;
      let onlyListNumbering = true;

      node.forEach((child) => {
        if (child.type === paragraphType) {
          hasParagraph = true;
        }
        if (child.type !== listNumberingType) {
          onlyListNumbering = false;
        }
      });

      if (!hasParagraph && onlyListNumbering) {
        tr.delete(pos, pos + node.nodeSize);
      }
    }
  });

  return tr;
}

function isAllChildrenDeleted(node: Node) {
  let allChildrenDeleted = true;

  node.descendants((child) => {
    if (!child.isLeaf) {
      return;
    }

    if (
      !child.attrs.isDeleted &&
      !child.marks.find((mark) => mark.type.name === 'deleted')
    ) {
      allChildrenDeleted = false;
    }
  });

  return allChildrenDeleted;
}

export const removeEmptyClaimPartPlugin = new Plugin({
  state: {
    init(_, { doc, schema }) {
      const decorations: Decoration[] = [];
      doc.descendants((node, pos) => {
        if (
          node.type === schema.nodes.claimPart &&
          isAllChildrenDeleted(node)
        ) {
          decorations.push(
            Decoration.node(pos, pos + node.nodeSize, {
              class: 'deleted',
            })
          );
        }
      });

      return DecorationSet.create(doc, decorations);
    },
    apply(tr, oldSet) {
      if (tr.docChanged) {
        const schema = tr.doc.type.schema;
        let newSet = oldSet.map(tr.mapping, tr.doc);

        tr.doc.descendants((node, pos) => {
          if (
            node.type === schema.nodes.claimPart &&
            isAllChildrenDeleted(node)
          ) {
            const decoration = Decoration.node(pos, pos + node.nodeSize, {
              class: 'deleted',
            });
            newSet = newSet.add(tr.doc, [decoration]);
          }
        });

        return newSet;
      }
      return oldSet;
    },
  },

  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
  appendTransaction(transactions, oldState, newState) {
    let tr = newState.tr;
    let docChanged = false;

    if (transactions.some((tr) => tr.docChanged)) {
      tr = removeEmptyClaimParts(tr, newState.doc, newState.schema);

      if (tr.steps.length > 0) {
        docChanged = true;
      }
    }

    return docChanged ? tr : null;
  },
});
