import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Tree } from 'antd';
import { Popover } from 'react-tiny-popover';
import useTranslation from '../../translations';
import { Icon } from '../Icon';

const { TreeNode } = Tree;

interface Props {
  onChange: (checkedOptions: React.Key[]) => void;
  checkedKeys: React.Key[];
}
export enum AvailableNoteOption {
  Public = 'Public',
  UserNotes = 'UserNotes',
  UserPublic = 'UserPublic',
  UserPrivate = 'UserPrivate',
}

const NotesFilter: React.FC<Props> = ({ checkedKeys, onChange }) => {
  const t = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const treeData = [
    {
      title: t('NOTES.PUBLIC'),
      key: AvailableNoteOption.Public,
    },
    {
      title: t('NOTES.USERNOTES'),
      key: AvailableNoteOption.UserNotes,
      children: [
        {
          title: t('NOTES.USERPUBLIC'),
          key: AvailableNoteOption.UserPublic,
        },
        {
          title: t('NOTES.USERPRIVATE'),
          key: AvailableNoteOption.UserPrivate,
        },
      ],
    },
  ];
  const handleCheck = (_checked: unknown) => {
    onChange(_checked as React.Key[]);
  };

  const getTranslatedKeys = () => {
    let keysToTranslate = checkedKeys;

    if (checkedKeys.includes(AvailableNoteOption.UserNotes)) {
      keysToTranslate = checkedKeys.filter(
        (key) =>
          key !== AvailableNoteOption.UserPublic &&
          key !== AvailableNoteOption.UserPrivate
      );
    }

    const translatedKeys = keysToTranslate.map((key) =>
      t(`NOTES.${String(key).toUpperCase()}`)
    );

    if (translatedKeys.length > 1) {
      return translatedKeys.join(', ');
    } else {
      return translatedKeys;
    }
  };

  return (
    <Popover
      isOpen={popoverOpen}
      positions={['bottom']}
      onClickOutside={() => setPopoverOpen(!popoverOpen)}
      align="start"
      containerStyle={{
        borderRadius: '14px',
        background: 'linear-gradient(135deg, #3b3f4f 0%, #31323f 100%)',
        boxShadow: '0 30px 60px 0 rgba(0, 0, 0, 0.17)',
        padding: '20px',
        marginTop: '5px',
      }}
      content={
        <PopoverContainer>
          <Tree
            checkedKeys={checkedKeys}
            onCheck={handleCheck}
            defaultExpandAll
            checkable
          >
            {treeData.map((node) => (
              <TreeNode title={node.title} key={node.key}>
                {node.children?.map((childNode) => (
                  <TreeNode title={childNode.title} key={childNode.key} />
                ))}
              </TreeNode>
            ))}
          </Tree>
        </PopoverContainer>
      }
    >
      <Trigger
        active={popoverOpen}
        style={{ cursor: 'pointer' }}
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        {checkedKeys.length > 0 && checkedKeys.length < 4
          ? getTranslatedKeys()
          : t('NOTES.ALL_NOTES')}
        <Icon size={16} className="icn-chevron-down" />
      </Trigger>
    </Popover>
  );
};

export default NotesFilter;

const Trigger = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  transition: transform 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.white87}
  font-size: 15px;
  border-radius: 4px;
  border: 2px solid ${(props) =>
    props.active ? props.theme.colors.blue600 : 'transparent'};
    &:hover {
      cursor:pointer;
      background: ${(props) => props.theme.colors.white05}
    }
    ${(props) =>
      props.active &&
      css`
        i {
          transform: rotate(180deg);
        }
      `}
`;
const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  font-size: 15px;
  .ant-tree-switcher {
    display: none;
  }
  .ant-tree .ant-tree-treenode {
    padding: 8px 0 4px 0;
  }
`;
