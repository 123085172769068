import { DOMOutputSpec, Node, NodeSpec } from 'prosemirror-model';
import { Command, Selection } from 'prosemirror-state';
import { NUMBERED_PARAGRAPH } from './nodeNames';
import { getAttrs, parseAttrs } from './paragraph';

const numberedParagraph: NodeSpec = {
  attrs: {
    align: { default: null },
    color: { default: null },
    leftIndent: { default: null },
    lineSpacing: { default: null },
    nodeId: { default: null },
  },
  content: 'inline*',
  group: 'block',
  parseDOM: [{ tag: 'p', getAttrs }],
  toDOM,
};

function toDOM(node: Node): DOMOutputSpec {
  const attrs = parseAttrs(node.attrs);
  attrs.class = 'numbered-paragraph';

  return ['p', attrs, 0];
}

export const numberedParagraphCommand: Command = (state, dispatch) => {
  const sel = state.selection,
    { $from } = sel;
  const nodeType = $from.node().type;
  const parentEmpty = $from.parent.content.size === 0;
  if (parentEmpty) {
    return false;
  }
  if (!sel.empty) {
    return false;
  }
  const paragraphEnd = state.doc.resolve($from.end());
  // If the cursor is at the end of the paragraph, create a new paragraph
  // after the current paragraph.
  if (sel.$to.pos !== paragraphEnd.pos) {
    return false;
  }

  if (nodeType && nodeType.name === NUMBERED_PARAGRAPH) {
    const newNumberedParagraph = nodeType.createAndFill();
    if (newNumberedParagraph) {
      const pos = $from.after();
      const tr = state.tr.insert(pos, newNumberedParagraph);
      tr.setSelection(Selection.near(tr.doc.resolve(pos)));
      dispatch?.(tr.scrollIntoView());
      return true;
    }
  }
  return false;
};

export default numberedParagraph;
