import React from 'react';
import styled from 'styled-components';
import { AvatarCircle } from '../../../Avatar';

interface Props {
  fullName: string;
  style?: React.CSSProperties;
}

const CommentUserInfo: React.FC<Props> = ({ fullName, style }) => (
  <Outer style={style}>
    <AvatarCircle displayName={fullName} size={30} />
    <Name>{fullName}</Name>
  </Outer>
);

export default CommentUserInfo;

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Name = styled.div`
  color: ${(props) => props.theme.colors.black70};
`;
