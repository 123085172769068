import { EditorView } from 'prosemirror-view';
import { Schema } from 'prosemirror-model';
import {
  BlobResource,
  EditorContentType,
  ImagePluginSettings,
} from '../../../../types';
import {
  handleClipboardItemsClaims,
  handleClipboardItemsDescription,
} from './utils';

interface pasteHanadlerProps {
  editorType: EditorContentType;
  schema: Schema;
  uploadFunction: (id: string, file: File) => Promise<BlobResource>;
  pluginSettings?: ImagePluginSettings;
  editorDocumentId?: string;
}
export default ({
    editorType,
    pluginSettings,
    schema,
    editorDocumentId,
    uploadFunction,
  }: pasteHanadlerProps) =>
  (view: EditorView, event: ClipboardEvent): boolean | void => {
    const clipboardItems = event?.clipboardData?.items;
    if (clipboardItems && clipboardItems.length > 0) {
      switch (editorType) {
        case EditorContentType.Description:
          return handleClipboardItemsDescription({
            clipboardItems,
            view,
            schema,
            event,
            uploadFunction,
            pluginSettings,
            editorDocumentId,
          });
        case EditorContentType.Claims:
          return handleClipboardItemsClaims({
            uploadFunction,
            clipboardItems,
            view,
            schema,
            event,
            editorDocumentId,
          });
        default:
          return;
      }
    }

    return true;
  };
