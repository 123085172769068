import React from 'react';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import AddDescriptionButton from './AddDescriptionButton';
import DescriptionList from './DescriptionList';
import { Components } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useTsdElementDescriptions } from 'queries';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { Notification } = Components;

const Descriptions: React.FC = () => {
  const t = useTranslation();
  const { languages } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const query = useTsdElementDescriptions(tsdElementId, tsdNodeId);

  if (query.isLoading || query.isIdle) {
    return <Notification spin spinSize="large" />;
  }
  if (query.isError) {
    return <Notification error />;
  }

  const { data } = query;

  const disabledLanguages = data
    .filter((d) => d.tsdNodeId == tsdNodeId)
    .map((d) => d.language);

  const disableAddNew =
    disabledLanguages.filter((l) => languages.includes(l)).length ===
    languages.length;

  return (
    <TsdElementAttributesPane
      showWarning={!data.length}
      warningText={t('DESCRIPTIONS.NO_DESCRIPTIONS_YET')}
    >
      <div>
        <AddDescriptionButton
          disabled={disableAddNew}
          disabledLanguages={disabledLanguages}
        />
      </div>
      <DescriptionList data={data} disabledLanguages={disabledLanguages} />
    </TsdElementAttributesPane>
  );
};

export default Descriptions;
