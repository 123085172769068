import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HeaderBackProps } from '../../../types';
import { Icon } from '../../Icon';
import { Colors } from '../../../theme';

const HeaderBack: React.FC<HeaderBackProps> = ({
  to,
  disabled,
  title,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  const content = (
    <Content title={title}>
      <Icon className="icn-arrow-left" color={Colors.white100} size={24} />
    </Content>
  );

  return (
    <Outer isDisabled={disabled} onClick={handleClick}>
      {to != null ? <Link to={to}>{content}</Link> : content}
    </Outer>
  );
};

export default HeaderBack;

const Outer = styled.div<{ isDisabled?: boolean }>`
  margin-left: -26px;
  margin-right: 4px;
  cursor: pointer;
  user-select: none;
  ${(props) => props.isDisabled && 'opacity: 0.5; pointer-events: none;'}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
`;
