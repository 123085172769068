import React from 'react';
import styled from 'styled-components';
import CompanyListItem from './CompanyListItem';
import { T } from '@ipos/shared';
import useTranslation from 'translations';

interface Props {
  data: T.TsdElementCompanyGroup[];
  onRemoveRequest: (id: string) => void;
}

const CompaniesList: React.FC<Props> = ({ data, onRemoveRequest }) => {
  const t = useTranslation();

  const checkForWarnings = (
    data: T.TsdElementCompanyGroup[],
    item: T.TsdElementCompanyGroup
  ): string[] => {
    const warnings: string[] = [];
    // check if company group is doubled
    if (
      data.find(
        (d) =>
          d.id != item.id &&
          d.isBaseAttribute &&
          d.companyGroup.name == item.companyGroup.name &&
          d.companyGroup.countryCode == item.companyGroup.countryCode &&
          d.companyGroup.city == item.companyGroup.city
      ) != null
    ) {
      warnings.push(t('ATTRIBUTES.WARNINGS.DOUBLE_COMPANY_ENTRY'));
    }
    return warnings;
  };

  return (
    <Outer>
      {data.map((item) => (
        <CompanyListItem
          key={item.id}
          item={item}
          warnings={checkForWarnings(data, item)}
          onRemoveRequest={onRemoveRequest}
        />
      ))}
    </Outer>
  );
};

export default CompaniesList;

const Outer = styled.div`
  margin-top: 20px;
  flex: 1;
  overflow: hidden auto;
`;
