import React from 'react';
import { useMutation } from 'react-query';
import { message, notification } from 'antd';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import IpcClassList from './IpcClassList';
import AddIpcClassesButton from './AddIpcClassesButton';
import { Api, Components, Hooks } from '@ipos/shared';
import useTranslation from 'translations';
import { QueryKey, useTsdElementIpcClasses } from 'queries';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';

const { Notification } = Components;
const { useInvalidateQueries } = Hooks;

const IpcClasses: React.FC = () => {
  const t = useTranslation();
  const { invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const { preferredLanguage } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const ipcClassesQuery = useTsdElementIpcClasses(tsdElementId, tsdNodeId);

  const deleteClassMutation = useMutation(
    (tsdElementClassId: string) =>
      Api.tsd.deleteTsdElementClass(tsdElementId, tsdElementClassId),
    {
      onSuccess: () => {
        message.success(t('IPC.CLASS_DELETED_SUCCESSFULLY'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementIpcClasses, tsdElementId, tsdNodeId]
        );
      },
      onError: () => {
        notification.error({
          message: t('ATTRIBUTES.DELETE_FAIL'),
          description: t('IPC.DELETE_FAIL'),
        });
      },
    }
  );

  const handleRemoveRequest = (tsdElementClassId: string): void => {
    applyChange(() => deleteClassMutation.mutate(tsdElementClassId));
  };

  if (ipcClassesQuery.isLoading || ipcClassesQuery.isIdle) {
    return <Notification spin spinSize="large" />;
  }

  if (ipcClassesQuery.isError) {
    return <Notification error />;
  }

  const { data: ipcClasses } = ipcClassesQuery;

  return (
    <TsdElementAttributesPane
      showWarning={!ipcClasses.length}
      warningText={t('IPC.NO_CLASS_YET')}
    >
      <div>
        <AddIpcClassesButton
          selectedClassIds={ipcClasses.map((d) => d.ipcClass.id)}
        />
      </div>
      <IpcClassList data={ipcClasses} onRemoveRequest={handleRemoveRequest} />
    </TsdElementAttributesPane>
  );
};

export default IpcClasses;
