import React from 'react';
import styled from 'styled-components';
import { T, Components } from '@ipos/shared';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';
import useTranslation from 'translations';

const { CountryFlag, Icon } = Components;

interface Props {
  warnings?: string[];
  item: T.TsdElementSynonym;
  onRemoveRequest: (item: T.TsdElementSynonym) => void;
  onEditRequest: (item: T.TsdElementSynonym) => void;
}

const SynonymListItem: React.FC<Props> = ({
  warnings,
  item,
  onRemoveRequest,
  onEditRequest,
}) => {
  const t = useTranslation();
  const { value, language, isBaseAttribute, isPrimary, isAutoTranslation } =
    item;

  return (
    <TsdElementAttributeListItem
      warnings={warnings}
      isBaseAttribute={isBaseAttribute}
      onRemoveRequest={() => onRemoveRequest(item)}
      onEditRequest={() => onEditRequest(item)}
      confirmRemovalTitle={t('SYNONYMS.DELETE_TITLE')}
    >
      <Inner>
        <CountryFlag countryCode={language} size={18} />
        {value}
        {isPrimary && <Icon className="icn-favorite" size={16} />}
        {isAutoTranslation && <Icon className="icn-robot" />}
      </Inner>
    </TsdElementAttributeListItem>
  );
};

export default SynonymListItem;

const Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
