import { EditorState, Transaction } from 'prosemirror-state';
import { Schema } from 'prosemirror-model';
import applyMark from '../util/applyMark';
import isTextStyleMarkCommandEnabled from '../util/isTextStyleMarkCommandEnabled';
import { MARK_FONT_SIZE } from '../../schema/marks/markNames';
import UICommand from './UICommand';

function setFontSize(tr: Transaction, schema: Schema, pt: number): Transaction {
  const markType = schema.marks[MARK_FONT_SIZE];
  if (!markType) {
    return tr;
  }
  const attrs = pt ? { pt } : null;
  tr = applyMark(tr, schema, markType, attrs);
  return tr;
}

class FontSizeCommand extends UICommand {
  _popUp = null;
  _pt = 0;

  constructor(pt: number) {
    super();
    this._pt = pt;
  }

  isEnabled = (state: EditorState): boolean =>
    isTextStyleMarkCommandEnabled(state, MARK_FONT_SIZE);

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, selection } = state;
    const tr = setFontSize(state.tr.setSelection(selection), schema, this._pt);
    if (tr.docChanged || tr.storedMarks) {
      // If selection is empty, the color is added to `storedMarks`, which
      // works like `toggleMark`
      // (see https://prosemirror.net/docs/ref/#commands.toggleMark).
      dispatch && dispatch(tr);
      return true;
    }
    return false;
  };
}

export default FontSizeCommand;
