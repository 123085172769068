import React from 'react';
import styled from 'styled-components';
import { Language } from '../../../types';
import CountryFlag from '../../CountryFlag';
import useTranslation from '../../../translations';

interface Props {
  language: Language;
}

const LanguageItem: React.FC<Props> = ({ language }) => {
  const t = useTranslation();

  return (
    <Outer>
      <CountryFlag countryCode={language} size={20} />
      <span>{t(`LANGUAGE.${language.toUpperCase()}`)}</span>
    </Outer>
  );
};

export default LanguageItem;

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 15px;
`;
