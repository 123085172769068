import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { message, Space } from 'antd';
import styled from 'styled-components';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import OpenQueryBuilderButton from './OpenQueryBuilderButton';
import QueriesListItem from './QueriesListItem';
import { T, Api, Components, Hooks } from '@ipos/shared';
import { useElementUsageCheck } from 'hooks';
import useTranslation from 'translations';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';

const { Notification, Icon } = Components;
const { useInvalidateQueries } = Hooks;

const Queries: React.FC = () => {
  const { tsdElementId, isLibraryView, tsdNodeId } = useTsdElementContext();
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const { applyChange } = useElementUsageCheck();
  const { invalidateQueries } = useInvalidateQueries();

  const queriesQuery = useQuery<T.TsdElementQuery[]>(
    [QueryKey.TsdElementQueries, tsdElementId, tsdNodeId],
    () => Api.tsd.getTsdElementQueries(tsdElementId, tsdNodeId)
  );

  const deleteQueryMutation = useMutation(
    (queryId: string) => Api.tsd.deleteTsdElementQuery(tsdElementId, queryId),
    {
      onSuccess: () => {
        message.success(t('QUERY.DELETE_SUCCESS'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementQueries, tsdElementId, tsdNodeId]
        );
      },
    }
  );

  if (queriesQuery.isLoading || queriesQuery.isIdle) {
    return <Notification spin spinSize="large" />;
  }

  if (queriesQuery.isError) {
    return <Notification error />;
  }

  const handleRemoveQueryRequest = (queryId: string) => {
    applyChange(() => deleteQueryMutation.mutate(queryId));
  };

  // get the first base query
  const [baseQuery] = queriesQuery.data.filter((x) => x.isBaseAttribute);
  const hasBaseQuery = baseQuery != null;

  if (isLibraryView) {
    return (
      <TsdElementAttributesPane
        showWarning={!hasBaseQuery}
        warningText={t('QUERY.NO_QUERY_FOUND')}
      >
        <>
          <div>
            <OpenQueryBuilderButton query={baseQuery} />
          </div>
          <QueriesList>
            {hasBaseQuery && (
              <QueriesListItem
                query={baseQuery}
                onRemove={handleRemoveQueryRequest}
              />
            )}
          </QueriesList>
        </>
      </TsdElementAttributesPane>
    );
  }

  // find a node specific query (NOT a base attribute)
  const [nodeQuery] = queriesQuery.data.filter((x) => !x.isBaseAttribute);
  const hasNodeQuery = nodeQuery != null;
  const hasAnyQuery = hasBaseQuery || hasNodeQuery;

  return (
    <TsdElementAttributesPane
      showWarning={!hasAnyQuery}
      warningText={t('QUERY.NO_QUERY_FOUND')}
    >
      <Space>
        <OpenQueryBuilderButton query={nodeQuery} baseQuery={baseQuery} />
        {!hasNodeQuery && hasAnyQuery && (
          <MissingNodeQuery>
            <Icon className="icn-info-outlined" size={21} />
            <span>{t('QUERY.NO_NODE_QUERY_FOUND')}</span>
          </MissingNodeQuery>
        )}
      </Space>
      <QueriesList>
        {hasNodeQuery && (
          <QueriesListItem
            query={nodeQuery}
            onRemove={handleRemoveQueryRequest}
          />
        )}
        {hasBaseQuery && <QueriesListItem query={baseQuery} />}
      </QueriesList>
    </TsdElementAttributesPane>
  );
};

export default Queries;

const QueriesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MissingNodeQuery = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.warning};
`;
