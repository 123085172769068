import { useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { SessionQuery } from '../../types';
import { clearAuthData, msalInstance } from '../../auth/msalInstance';
import { inIframe } from '../../utils/location';
import authenticationStateMachine, {
  AuthenticationEvents,
  BackendUnreachable,
  LoggedIn,
  LoggedOut,
  SetSessionData,
} from './authenticationStateMachine';

const useApplication = (): SessionQuery => {
  const [state, send] = useMachine(authenticationStateMachine, {
    actions: {
      clearSession: () => {
        if (!inIframe()) {
          clearAuthData();
        } else {
          window.location.reload();
        }
      },
      setSession: assign({
        session: (_context, event) => (event as SetSessionData).data,
      }),
      setIframeInitialized: assign({
        isIFrameInitialized: true,
      }),
    },
  });

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((message) => {
      if (
        [
          EventType.ACQUIRE_TOKEN_FAILURE,
          EventType.HANDLE_REDIRECT_END,
          EventType.HANDLE_REDIRECT_START,
          EventType.LOGIN_SUCCESS,
        ].includes(message.eventType)
      ) {
        send({ type: message.eventType } as AuthenticationEvents);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return {
    session: state.context.session,
    isSessionError: state.matches(LoggedOut),
    isBackendUnreachable: state.matches(BackendUnreachable),
    isSessionLoading: [LoggedIn, LoggedOut, BackendUnreachable].every(
      (s) => !state.matches(s)
    ),
  };
};

export default useApplication;
