import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  label?: React.ReactNode;
  labelSuffix?: React.ReactNode;
  children?: React.ReactNode;
  emptyContent?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

const LabelValue: React.FC<Props> = ({
  label,
  labelSuffix,
  children,
  emptyContent = '-',
  style,
  labelStyle,
}) => (
  <Outer style={style}>
    {label && (
      <Text style={labelStyle} type="secondary">
        {label}
        {labelSuffix}
      </Text>
    )}
    {children || emptyContent}
  </Outer>
);

export default LabelValue;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  div.ant-typography,
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;
