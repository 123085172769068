import { object, string, mixed, ObjectSchema } from 'yup';
import { T } from '@ipos/shared';

const createTsdElementSchema: ObjectSchema<T.CreateTsdElement> = object({
  synonym: string().trim().required('VALIDATION.REQUIRED'),
  description: string().ensure(),
  language: mixed<T.Language>().required('VALIDATION.REQUIRED'),
});

export default createTsdElementSchema;
