import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useApplication } from '../components/Application';
import { createRedirectQueryParam } from '../utils/location';
import SHARED_ROUTES, { generateUrl } from '../utils/routing';

const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { isSessionError } = useApplication();
  const location = useLocation();

  return isAuthenticated && !isSessionError ? (
    <>{children}</>
  ) : (
    <Redirect
      to={{
        pathname: generateUrl(SHARED_ROUTES.LOGIN),
        state: {
          redirect: createRedirectQueryParam(location),
        },
      }}
    />
  );
};

export default PrivateRoute;
