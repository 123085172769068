import { useMemo, useRef } from 'react';
import { TsdNode } from '../../types';
import { usePreviousStable } from '../../hooks';

type TsdTreeSctructure = {
  tree: TsdNode[];
  prevTree?: TsdNode[];
  tsdId?: string;
  hasTsdChanged: boolean;
};

const usePreviousTree = (root?: TsdNode | null) => {
  const prevTreeRef = useRef<{
    root?: TsdNode | null;
    current?: TsdNode[];
    previous?: TsdNode[];
  }>({
    root,
    current: root ? [root] : [],
    previous: undefined,
  });
  if (prevTreeRef.current.root !== root) {
    prevTreeRef.current.root = root;
    prevTreeRef.current.previous = prevTreeRef.current.current;
    prevTreeRef.current.current = root ? [root] : [];
  }
  return prevTreeRef.current.previous;
};

const useTsdTreeStructure = (root?: TsdNode | null): TsdTreeSctructure => {
  const tree = useMemo(() => (root ? [root] : []), [root]);
  const prevTree = usePreviousTree(root);
  const tsdId = root?.tsdId;
  const prevTsdId = usePreviousStable(tsdId);
  const hasTsdChanged = !!prevTsdId && !!tsdId && prevTsdId !== tsdId;

  return {
    tree,
    prevTree,
    tsdId,
    hasTsdChanged,
  };
};

export default useTsdTreeStructure;
