import { MarkSpec } from 'prosemirror-model';

const link: MarkSpec = {
  attrs: {
    href: {},
    title: { default: null },
  },
  inclusive: false,
  parseDOM: [
    {
      tag: 'a[href]',
      getAttrs(dom) {
        if (dom instanceof HTMLElement) {
          return {
            href: dom.getAttribute('href'),
            title: dom.getAttribute('title'),
          };
        }
        return null;
      },
    },
  ],
  toDOM(node) {
    return ['a', node.attrs, 0];
  },
};

export default link;
