import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Popover } from 'antd';
import { Avatar } from '..';
import useTranslation from '../../translations';
import { AvatarCircle } from '../Avatar';
import { T } from '../../..';

interface Props {
  stakeholders?: T.PatentApplicationStakeholder[];
  avatarStyle?: CSSObject;
}

const StakeholdersArray: React.FC<Props> = ({
  stakeholders,
  avatarStyle = {
    border: '2px solid #31323f',
  },
}) => {
  const t = useTranslation();
  if (stakeholders && stakeholders.length > 0) {
    const hasMoreStakeHolders = stakeholders.length > 3;
    const visibleStakeHolders = hasMoreStakeHolders
      ? stakeholders.slice(0, 3)
      : stakeholders;

    if (!hasMoreStakeHolders) {
      return (
        <FlexCenter>
          {visibleStakeHolders.map((s, index) => (
            <Wrapper key={s.id} $order={stakeholders.length - index}>
              <Avatar
                user={{
                  id: s.contact.id,
                  displayName: s.contact.displayName || '',
                  mail: s.contact.email,
                  mobilePhone: s.contact.phone,
                }}
                additionalContent={
                  <div>{t(`STAKEHOLDERS.ROLES.${s?.role?.toUpperCase()}`)}</div>
                }
              />
            </Wrapper>
          ))}
        </FlexCenter>
      );
    }

    const restOfStakeholders = stakeholders.slice(3);

    return (
      <FlexCenter>
        {visibleStakeHolders.map((s, index) => (
          <Wrapper key={s.id} $order={stakeholders.length - index}>
            <Avatar
              style={avatarStyle}
              user={{
                id: s.contact.id,
                displayName: s.contact.displayName || '',
                mail: s.contact.email,
                mobilePhone: s.contact.phone,
              }}
              additionalContent={
                <div>{t(`STAKEHOLDERS.ROLES.${s?.role?.toUpperCase()}`)}</div>
              }
            />
          </Wrapper>
        ))}
        <Popover
          content={
            <Flex>
              {restOfStakeholders.map((s, index) => (
                <Wrapper key={s.id} $order={stakeholders.length - index}>
                  <Avatar
                    style={avatarStyle}
                    user={{
                      id: s.contact.id,
                      displayName: s.contact.displayName || '',
                      mail: s.contact.email,
                      mobilePhone: s.contact.phone,
                    }}
                    additionalContent={
                      <div>
                        {t(`STAKEHOLDERS.ROLES.${s?.role?.toUpperCase()}`)}
                      </div>
                    }
                  />
                </Wrapper>
              ))}
            </Flex>
          }
          trigger="hover"
          placement="bottomLeft"
        >
          <Wrapper $order={stakeholders.length}>
            <Avatar
              withPopover={false}
              style={avatarStyle}
              user={{
                displayName: '+',
                givenName: '+',
                id: 'rest',
                surname: `${restOfStakeholders.length}`,
              }}
            />
          </Wrapper>
        </Popover>
      </FlexCenter>
    );
  }

  return (
    <Popover
      title={t('STAKEHOLDERS.NO_STAKEHOLDERS')}
      trigger="hover"
      placement="bottom"
    >
      <div>
        <AvatarCircle style={{ fontSize: '20px' }} size={38} displayName="?" />
      </div>
    </Popover>
  );
};

export default StakeholdersArray;

const Wrapper = styled.div<{ $order: number }>`
  display: flex;
  position: relative;
  margin-right: -5px;
  z-index: ${(props) => props.$order};
  &:hover {
    z-index: 10;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
`;
