/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node as PMNode } from 'prosemirror-model';

export interface WorkerDiffRequest {
  type: 'diff' | 'jsonPatch';
  firstEntry: PMNode | string;
  secondEntry: PMNode | string;
  index?: number;
  messageId: number;
}

export type PatchOperationResponse = {
  type: 'jsonPatch';
  index?: number;
  messageId: number;
  patches: Operation[];
};

export type DiffOperationResponse = {
  type: 'diff';
  index?: number;
  messageId: number;
  diffs: DiffTuple[];
};

export type DiffTuple = [DiffOperation, string];

export enum DiffOperation {
  DIFF_DELETE = -1,
  DIFF_INSERT = 1,
  DIFF_EQUAL = 0,
}

export type CalculateDiff = (
  firstEntry: string,
  secondEntry: string,
  index?: number
) => Promise<DiffTuple[]>;

export type CalculatePatches = (
  firstEntry: any,
  secondEntry: any
) => Promise<Operation[]>;

export interface BaseOperation {
  path: string;
}

export interface AddOperation<T> extends BaseOperation {
  op: 'add';
  value: T;
}

export interface RemoveOperation extends BaseOperation {
  op: 'remove';
}

export interface ReplaceOperation<T> extends BaseOperation {
  op: 'replace';
  value: T;
}

export interface MoveOperation extends BaseOperation {
  op: 'move';
  from: string;
}

export interface CopyOperation extends BaseOperation {
  op: 'copy';
  from: string;
}

export interface TestOperation<T> extends BaseOperation {
  op: 'test';
  value: T;
}

export interface GetOperation<T> extends BaseOperation {
  op: '_get';
  value: T;
}

export interface OperationResult<T> {
  removed?: any;
  test?: boolean;
  newDocument: T;
}
export type Operation =
  | AddOperation<any>
  | RemoveOperation
  | ReplaceOperation<any>
  | MoveOperation
  | CopyOperation
  | TestOperation<any>
  | GetOperation<any>;
