import React from 'react';
import { Components, T, Colors } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationMostUsedCompanyGroups } from 'queries';

const { Visualizations } = Components;
const { RadialBarChart } = Visualizations;

const MostUsedCompanyGroupsRadialBarChart: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationMostUsedCompanyGroups();

  if (!data) {
    return null;
  }

  const config: T.RadialBarChartConfig = {
    series: [
      {
        name: 'Number of company groups',
        color: Colors.visualization.aqua300,
      },
    ],
    data: data ?? [],
    description: t('STATS.TOP5_MOST_USED_COMPANY_GROUPS'),
    tooltip: {
      formatter: (v) => ({
        name: t('STATS.OCCURRENCES_TOOLTIP'),
        value: v.y,
      }),
    },
    xAxis: {
      title: t('STATS.MOST_USED_COMPANY_GROUPS'),
    },
  };

  return <RadialBarChart config={config} />;
};

export default MostUsedCompanyGroupsRadialBarChart;
