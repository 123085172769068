import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Stack } from 'immutable';
import {
  DefaultDraftBlockRenderMap,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import styled, { css, CSSObject } from 'styled-components';
import 'draft-js/dist/Draft.css';
import { useMutation } from 'react-query';
import { getSelectedBlock, removeBlock } from '../../utils';

import { upload } from '../../api/uploads';
import blockRendererFn from './blockRendererFn';
import RichTextControls from './RichTextControls';
import {
  convertFromHtmlWithOptions,
  DEFAULT_VALUE,
  getEditorStateFromValue,
  styleMap,
  toHtml,
  DraftHandleValues,
} from './Utils';
import getNewImageBlock from './MediaBlock/getNewImageBlock';

const blockRenderMap = DefaultDraftBlockRenderMap.set('br', { element: 'br' });

interface Props {
  value: string | undefined;
  placeholder?: string;
  key?: string | number;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: () => void;
  style?: CSSProperties;
  customToolbar?: React.ReactNode;
  plugins?: EditorPlugin[];
  disabled?: boolean;
  hideToolbar?: boolean;
}

const RichText: React.FC<Props> = ({
  key,
  placeholder,
  style,
  value = DEFAULT_VALUE,
  plugins,
  disabled,
  onFocus,
  hideToolbar,
  customToolbar,
  onClick,
  onBlur,
  onChange,
}) => {
  const editorStateValue = useMemo(
    () => getEditorStateFromValue(value),
    [value]
  );
  const [editorState, setEditorState] = useState<EditorState>(editorStateValue);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    clearTimeout(timer.current);
    const currentValue = toHtml(editorState.getCurrentContent());
    if (plugins && !editorState.getDecorator()) {
      return;
    }
    if (currentValue === value) {
      return;
    }
    const contentState = convertFromHtmlWithOptions(value ?? DEFAULT_VALUE);
    const newEditorState = EditorState.moveSelectionToEnd(
      EditorState.createWithContent(contentState)
    );
    // hacky hack god help me I am going to kill somebody
    // @ts-ignore
    newEditorState._immutable = newEditorState._immutable
      // @ts-ignore
      .set('undoStack', new Stack())
      // @ts-ignore
      .set('redoStack', new Stack());
    setEditorState(newEditorState);
  }, [value]);

  const onChangeCallback = (
    newEditorState: EditorState,
    previousEditorState: EditorState
  ) => {
    if (!onChange) {
      return;
    }
    const oldValue = toHtml(previousEditorState.getCurrentContent());
    const newValue = toHtml(newEditorState.getCurrentContent());

    if (newValue === oldValue) {
      return;
    }
    onChange(newValue);
  };

  const _onChange = (es: EditorState) => {
    clearTimeout(timer.current);
    setEditorState(es);
    timer.current = setTimeout(() => onChangeCallback(es, editorState), 0);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const { mutate: submitUploads } = useMutation((f: File[]) => upload(f), {
    onSuccess: (uploads) => {
      const newEditorState = getNewImageBlock(uploads[0].id, editorState);
      setEditorState(newEditorState);
    },
  });
  const initializeUpload = (filesToUpload: File[]) => {
    submitUploads(filesToUpload);
  };
  const handlePastedFiles = (files: File[]) => {
    initializeUpload(files);
    return DraftHandleValues.Handled;
  };

  const handlePastedText = (text: string): DraftHandleValue => {
    const stateFromCopiedText = getEditorStateFromValue(text);
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      stateFromCopiedText.getCurrentContent().getBlockMap()
    );
    const nextEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-fragment'
    );
    _onChange(nextEditorState);
    return DraftHandleValues.Handled;
  };

  const handleKeyCommand = (
    command: DraftEditorCommand,
    es: EditorState
  ): DraftHandleValue => {
    const selectedBlock = getSelectedBlock(es);

    if (selectedBlock?.getType() === 'atomic') {
      switch (command) {
        case 'backspace':
        case 'delete':
          const contentStateWithoutBlock = removeBlock(
            es.getCurrentContent(),
            selectedBlock.getKey()
          );
          const newEditorState = EditorState.push(
            es,
            contentStateWithoutBlock,
            'remove-range'
          );
          setEditorState(newEditorState);
          return DraftHandleValues.Handled;
        default:
          break;
      }
    }

    const newContent = RichUtils.handleKeyCommand(es, command);
    if (newContent) {
      setEditorState(newContent);
      return DraftHandleValues.Handled;
    } else {
      return DraftHandleValues.NotHandled;
    }
  };
  return (
    <Outer style={style} onClick={onClick}>
      {!disabled && !hideToolbar && (
        <RichTextControls editorState={editorState} onChange={_onChange} />
      )}
      <Editor
        key={key}
        placeholder={placeholder}
        readOnly={disabled}
        editorState={editorState}
        onChange={_onChange}
        customStyleMap={styleMap}
        onBlur={handleBlur}
        handlePastedFiles={handlePastedFiles}
        plugins={plugins}
        blockRenderMap={blockRenderMap}
        onFocus={handleFocus}
        handleKeyCommand={handleKeyCommand}
        blockRendererFn={blockRendererFn(setEditorState, editorState)}
        handlePastedText={handlePastedText}
      />
      {customToolbar}
    </Outer>
  );
};

export default RichText;

const Outer = styled.div<{ style?: CSSProperties }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  min-height: 0;

  .DraftEditor-root {
    width: 100%;
    height: 100%;
    padding: 5px 0;
    overflow: auto;

    .public-DraftEditorPlaceholder-hasFocus
      .public-DraftEditorPlaceholder-inner {
      display: none;
    }
  }
  ${(props) =>
    css`
      ${props.style as CSSObject}
    `};
`;
