import React, { useState } from 'react';
import { T, Permissions } from '@ipos/shared';
import { DeleteTsdModal } from 'components/Tsd';
import { MenuBarOption } from 'components/common';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  tsd: T.Tsd;
}

const DeleteTsdMenuOption: React.FC<Props> = ({ tsd }) => {
  const t = useTranslation();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOptionClick = () => setModalOpen(true);
  const handleCloseModalRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdDelete}>
      <MenuBarOption
        onClick={handleOptionClick}
        iconClassName="icn-delete"
        title={t('DELETE_TSD.TITLE')}
      />
      {isModalOpen && (
        <DeleteTsdModal
          tsd={tsd}
          isOpen={isModalOpen}
          onClose={handleCloseModalRequest}
        />
      )}
    </WithPermission>
  );
};

export default DeleteTsdMenuOption;
