import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const Actions: React.FC<Props> = ({ children }) => <Outer>{children}</Outer>;

export default Actions;

const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.primary700};
  padding: 20px 0px 0px;
`;
