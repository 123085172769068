export function isAuthLoaded(): Promise<boolean> {
  const promise = new Promise<boolean>((resolve) => {
    window.addEventListener(
      'message',
      (event) => {
        const { action, data } = event.data;
        if (action == 'returnData') {
          for (const key in data) {
            localStorage.setItem(key, data[key]);
          }
          resolve(true);
        }
      },
      false
    );

    setTimeout(() => resolve(false), 2000);
  });
  window.parent.postMessage({ action: 'get' }, '*');
  return promise;
}
