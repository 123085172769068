import React from 'react';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import QueryString from '../QueryString';
import { useQueryContext } from '../QueryContext';
import QueryExpertModeSwitch from '../QueryExpertModeSwitch';
import Actions from './Common/Actions';
import Content from './Common/Content';
import { Components } from '@ipos/shared';
import { Action, QueryStringType } from 'types';
import useTranslation from 'translations';

const { Notification } = Components;

interface Props {
  onDone: () => void;
  onBack: () => void;
}

const QueryTest: React.FC<Props> = ({ onDone, onBack }) => {
  const t = useTranslation();
  const { dispatch, query, saveQuery, isSaving, baseQuery } = useQueryContext();

  const handleModeChange = (isInExpertMode: boolean) => {
    dispatch({ type: Action.SwitchModeRequest, payload: { isInExpertMode } });

    if (!isInExpertMode) {
      onBack();
    }
  };

  const handleGooglePatentsStringChange = (value: string) => {
    dispatch({
      type: Action.QueryStringChangeRequest,
      payload: { value, type: QueryStringType.GooglePatents },
    });
  };

  const handlePatbaseStringChange = (value: string) => {
    dispatch({
      type: Action.QueryStringChangeRequest,
      payload: { value, type: QueryStringType.PatBase },
    });
  };

  const handleDoneClick = async () => {
    await saveQuery();
    onDone();
  };

  if (!query) {
    return <Notification error />;
  }

  const { isInExpertMode, googlePatentsQuery, patBaseQuery } = query;

  return (
    <Outer>
      <Content>
        <QueryExpertModeSwitch
          checked={isInExpertMode}
          onChange={handleModeChange}
        />
        <QueryString
          label={t('QUERY.GOOGLE_PATENTS')}
          queryText={googlePatentsQuery}
          queryPrefix="https://patents.google.com?"
          baseQueryText={baseQuery?.googlePatentsQuery}
          disabled={!isInExpertMode}
          onChange={handleGooglePatentsStringChange}
          rows={5}
        />
        <QueryString
          label={t('QUERY.PATBASE')}
          queryText={patBaseQuery}
          baseQueryText={baseQuery?.patBaseQuery}
          disabled={!isInExpertMode}
          onChange={handlePatbaseStringChange}
          showTestButton={false}
          rows={5}
        />
      </Content>
      <Actions>
        <Button onClick={onDone}>{t('ACTION.CANCEL')}</Button>
        <Space>
          {!isInExpertMode && (
            <Button type="primary" onClick={onBack}>
              {t('QUERY.BACK_TO_SETUP')}
            </Button>
          )}
          <Button type="primary" onClick={handleDoneClick} loading={isSaving}>
            {t('DONE')}
          </Button>
        </Space>
      </Actions>
    </Outer>
  );
};

export default QueryTest;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
