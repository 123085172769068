import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import useTranslation from '../../../translations';
import { DownArrowIcon } from './CountryPicker';

const { Option } = Select;

enum Gender {
  Male = 'm',
  Female = 'f',
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  defaultValue: string;
}

const GenderPicker: React.FC<Props> = ({ value, onChange, defaultValue }) => {
  const t = useTranslation();

  const handleChange = (newValue: string): void => {
    onChange(newValue);
  };

  const gender = Object.values(Gender).map((v) => (
    <Option key={v} value={v}>
      <Flex>
        <Label>{t(`STAKEHOLDERS.PROPERTIES.GENDER.${v.toUpperCase()}`)}</Label>
      </Flex>
    </Option>
  ));
  return (
    <Outer>
      <Select
        suffixIcon={<DownArrowIcon className="icn-chevron-down" />}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        placeholder="Select"
      >
        {gender}
      </Select>
    </Outer>
  );
};

export default GenderPicker;

const Label = styled.span``;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Outer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 15px;
    background: none;
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    transition: border 0.2s ease-in-out;
    border: none;
    width: 100%;
    border-radius: 0;
    padding: 0;
    input {
      &:focus {
        outline: none;
      }
      outline: none;
      border: none;
      box-shadow: none;
      background: none;
      display: flex;
      flex: 1;
    }
  }
  .ant-select-arrow {
    top: 9px;
    right: 9px;
  }
  .ant-select {
    width: 100%;
  }
`;
