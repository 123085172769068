import RBush from 'rbush';
import { RefObject } from 'react';
import {
  AnnotatedPageLocation,
  Annotation,
  DocumentListItem,
  DocumentMetadata,
  PageLocation,
  SelectableText,
} from '../../types';
import {
  SelectableTextBBox,
  DOMBoundingBox,
  BBoxWithTooltip,
  CanvasProjection,
  DocumentLocation,
} from './types';

import { ANNOTATION_STRUCTURE } from './AnnotationsToolbar/annotationsUtil';

const OFFSET_X = -5;
const OFFSET_Y = -2;
const OFFSET_WIDTH = 10;
const OFFSET_HEIGHT = 3;
const TOOLTIP_OFFSET = 10;

export function findDocumentById(
  documentList: DocumentListItem[],
  documentId: string
): DocumentMetadata | null {
  for (const item of documentList) {
    const doc = item.documents.find((d) => d.id === documentId);
    if (doc) {
      return doc;
    }
    if (item.subLists.length > 0) {
      const subDoc = findDocumentById(item.subLists, documentId);
      if (subDoc) {
        return subDoc;
      }
    }
  }
  return null;
}

export function findPageNumber(
  target: HTMLElement,
  container: HTMLDivElement
): number {
  if (
    target.parentElement?.classList.contains('react-pdf__Page__textContent') &&
    container.contains(target)
  ) {
    const parent = target.parentElement.parentElement;
    return parent?.dataset?.pageNumber
      ? parseInt(parent?.dataset.pageNumber, 10)
      : -1;
  }
  return -1;
}

export function unprojectSelectionRectangle(
  top: number,
  left: number,
  width: number,
  height: number,
  pageWidth: number,
  pageHeight: number
): PageLocation {
  return {
    x: left / pageWidth,
    y: top / pageHeight,
    width: width / pageWidth,
    height: height / pageHeight,
  };
}

export function mapSelectableTextToBBox(
  projectedAnnotations: SelectableText[]
): SelectableTextBBox[] {
  return projectedAnnotations.map((selectableText, index) => {
    const {
      text,
      location: { x, y, width, height },
    } = selectableText;
    return {
      minX: x,
      minY: y,
      maxX: x + width,
      maxY: y + height,
      text: text || '',
      originalSelectableText: selectableText,
      index,
    };
  });
}

export function drawTextHighlights(
  canvas: HTMLCanvasElement,
  bboxes: SelectableTextBBox[]
): void {
  const ctx = canvas?.getContext('2d');
  ctx?.clearRect(0, 0, canvas.width, canvas.height);
  if (!ctx) {
    return;
  }

  bboxes.forEach((bbox) => {
    const {
      height: projectedHeight,
      width: projectedWidth,
      x,
      y,
    } = projectAidToImage({
      annotation: bbox.originalSelectableText,
      pageWidth: canvas.width,
      pageHeight: canvas.height,
    });
    ctx.fillStyle = 'rgba(54,165,169,0.5)';
    ctx.fillRect(x, y, projectedWidth, projectedHeight);
  });
}

export function calculateBoundingBox(
  selectionRectangle: HTMLDivElement,
  mouseStartPositionEvent: MouseEvent,
  mouseClientX: number,
  mouseClientY: number,
  offsetX: number,
  offsetY: number
): DOMBoundingBox {
  const mouseStartPositionY = mouseStartPositionEvent.clientY - offsetY;
  const mouseStartPositionX = mouseStartPositionEvent.clientX - offsetX;
  const diffX = mouseClientX - mouseStartPositionEvent.clientX;
  const diffY = mouseClientY - mouseStartPositionEvent.clientY;
  let top;
  let left;
  let width;
  let height;
  if (diffX > 0 && diffY > 0) {
    top = mouseStartPositionY;
    left = mouseStartPositionX;
    width = diffX;
    height = diffY;
  } else if (diffX < 0 && diffY > 0) {
    top = mouseStartPositionY;
    left = mouseClientX - offsetX;
    width = Math.abs(diffX);
    height = diffY;
  } else if (diffX > 0 && diffY < 0) {
    top = mouseClientY - offsetY;
    left = mouseStartPositionX;
    width = diffX;
    height = Math.abs(diffY);
  } else {
    top = mouseClientY - offsetY;
    left = mouseClientX - offsetX;
    width = Math.abs(diffX);
    height = Math.abs(diffY);
  }

  return {
    top,
    left,
    width,
    height,
  };
}

export function drawRectangle(
  selectionRectangle: HTMLDivElement,
  top: number,
  left: number,
  width: number,
  height: number
): void {
  selectionRectangle.style.display = 'block';
  selectionRectangle.style.top = `${top}px`;
  selectionRectangle.style.left = `${left}px`;
  selectionRectangle.style.width = `${width}px`;
  selectionRectangle.style.height = `${height}px`;
}

export function getTextForCopying(
  currentSelections: Record<string, SelectableTextBBox[] | undefined>
): string {
  return Object.keys(currentSelections).reduce((prev, current) => {
    if (!currentSelections[current]?.length) {
      return prev;
    }
    const sortedSelection = [
      ...(currentSelections[current] as SelectableTextBBox[]),
    ].sort((a, b) => a.index - b.index);
    return `${prev} ${sortedSelection.map((ts) => ts.text).join(' ')}`;
  }, '');
}

export function repositionAndSetTooltip(
  selectionRectangle: HTMLDivElement,
  tooltipText: string
): void {
  if (!selectionRectangle.children?.[0]) {
    return;
  }
  const tooltip = selectionRectangle.firstChild as HTMLDivElement;
  tooltip.textContent = tooltipText;
  const rectBounds = selectionRectangle.getBoundingClientRect();
  const tooltipBounds = tooltip.getBoundingClientRect();
  if (window.innerWidth - rectBounds.right < 350) {
    tooltip.style.left = `${
      rectBounds.left - TOOLTIP_OFFSET - tooltipBounds.width
    }px`;
  } else {
    tooltip.style.left = `${rectBounds.right + TOOLTIP_OFFSET}px`;
  }
  tooltip.style.top = `${rectBounds.top + TOOLTIP_OFFSET}px`;
}

export function getVisiblePages(visibilities: boolean[]): number[] {
  return visibilities
    .map((v, index) => (v ? index + 1 : null))
    .filter((v) => v !== null) as number[];
}

export function getNonCachedPages(
  pages: number[],
  currentCache: Record<string, RBush<SelectableTextBBox>>
): number[] {
  return pages.filter(
    (v) =>
      v !== null && Object.keys(currentCache).every((c) => c !== `${v - 1}`)
  );
}

export function cacheSelectableText(
  selectableTextPages: Record<number, SelectableText[]>,
  currentCache: Record<string, RBush<SelectableTextBBox>>
): void {
  Object.entries(selectableTextPages).forEach(([key, selectableText]) => {
    const pageIndex = parseInt(key, 10) - 1;
    const boundingBoxes = mapSelectableTextToBBox(selectableText);
    currentCache[pageIndex] = new RBush<SelectableTextBBox>();
    currentCache[pageIndex].load(boundingBoxes);
  });
}

export function getIntersects(
  entries: IntersectionObserverEntry[]
): Record<number, boolean> {
  return entries.reduce<Record<number, boolean>>((acc, entry) => {
    const stringIndex = entry.target.getAttribute('data-page-index');
    const pageIndex = parseInt(stringIndex as string, 10);
    return {
      ...acc,
      [pageIndex]: entry.isIntersecting,
    };
  }, {});
}

export function mapAnnotationsToBBoxes(
  projectedAnnotations: CanvasProjection[]
): BBoxWithTooltip[] {
  return projectedAnnotations.map(({ x, y, width, height, text }) => ({
    minX: x,
    minY: y,
    maxX: x + width,
    maxY: y + height,
    text: text || '',
  }));
}

export function projectAidToImage(props: {
  annotation: SelectableText;
  pageWidth: number;
  pageHeight: number;
}): CanvasProjection {
  const { annotation, pageWidth, pageHeight } = props;
  const { x, y, width, height } = annotation.location;
  return {
    x: x * pageWidth + OFFSET_X,
    y: y * pageHeight + OFFSET_Y,
    width: width * pageWidth + OFFSET_WIDTH,
    height: height * pageHeight + OFFSET_HEIGHT,
    text: annotation.text,
  };
}

export function mapToCanvasCoordinates(
  canvas: HTMLCanvasElement | HTMLDivElement,
  annotations: AnnotatedPageLocation[]
): CanvasProjection[] {
  const pageBCRect = canvas.getBoundingClientRect();

  return annotations.map((annotation) =>
    projectAidToImage({
      annotation: {
        text: annotation.text || '',
        location: annotation.pageLocation,
      },
      pageWidth: pageBCRect.width,
      pageHeight: pageBCRect.height,
    })
  );
}

export function drawAnnotations(
  canvas: HTMLCanvasElement,
  annotations: AnnotatedPageLocation[]
): void {
  const pageBCRect = canvas.getBoundingClientRect();
  const ctx = canvas?.getContext('2d');
  ctx?.clearRect(0, 0, canvas.width, canvas.height);
  if (!ctx) {
    return;
  }
  annotations.forEach((annotation) => {
    let annotationStructure;

    if (annotation.annotationSetTitle) {
      annotationStructure = ANNOTATION_STRUCTURE.find(
        (a) => a.key === annotation.annotationSetTitle
      );
    }

    const { height, width, x, y } = projectAidToImage({
      annotation: {
        text: annotation.text || '',
        location: annotation.pageLocation,
      },
      pageWidth: pageBCRect.width,
      pageHeight: pageBCRect.height,
    });

    if (annotationStructure?.style.backgroundColor) {
      ctx.globalAlpha = 0.35;
      ctx.fillStyle = annotationStructure.style.backgroundColor;
      ctx.fillRect(x, y, width, height);
    }

    if (annotationStructure?.style.underlineColor) {
      ctx.globalAlpha = 1;
      ctx.fillStyle = annotationStructure.style.underlineColor;
      ctx.fillRect(x, y + height, width, 2);
    }

    if (
      !annotationStructure?.style?.backgroundColor &&
      !annotationStructure?.style?.underlineColor
    ) {
      ctx.globalAlpha = 1;
      ctx.fillStyle = 'rgba(197, 101, 129, 0.5)';
      ctx.fillRect(x, y, width, height);
    }

    ctx.globalAlpha = 1;
  });
}

export function drawHighlighter(
  dummyAnnotationRef: RefObject<HTMLDivElement>,
  annotationsUnderMouse?: BBoxWithTooltip[]
): void {
  if (!dummyAnnotationRef?.current) {
    return;
  }
  if (!annotationsUnderMouse || annotationsUnderMouse.length === 0) {
    dummyAnnotationRef.current.style.display = 'none';
    return;
  }
  const annotation = annotationsUnderMouse[0];
  const left = annotation.minX;
  const top = annotation.minY;
  const width = annotation.maxX - left;
  const height = annotation.maxY - top;
  dummyAnnotationRef.current.style.display = 'block';
  dummyAnnotationRef.current.style.left = `${left}px`;
  dummyAnnotationRef.current.style.top = `${top}px`;
  dummyAnnotationRef.current.style.width = `${width}px`;
  dummyAnnotationRef.current.style.height = `${height}px`;

  if (dummyAnnotationRef.current.firstChild) {
    dummyAnnotationRef.current.firstChild.textContent = annotation.text;

    const tooltipDiv = dummyAnnotationRef.current.querySelector('div');
    const parentDiv = dummyAnnotationRef.current.parentElement;

    if (!tooltipDiv || !parentDiv) {
      return;
    }

    const difference = tooltipDiv.clientWidth + left - parentDiv.clientWidth;

    tooltipDiv.style.left = difference > 0 ? `${difference * -1}px` : '50%';
  }
}

export function mapLocations(
  annotations?: Annotation[] | null
): AnnotatedPageLocation[] {
  if (!annotations) {
    return [];
  }
  const tempMap = new Map();
  const allAnnotations = annotations.reduce<AnnotatedPageLocation[]>(
    (prev, current) => {
      const text = current.popUpText;
      if (!current.locations) {
        return prev;
      }
      const annotatedPageLocations: AnnotatedPageLocation[] =
        current.locations.map((l) => ({
          ...l,
          text,
          annotationSetTitle: current.annotationSetTitle,
          navigationLinks: current.navigationLinks,
        }));
      return [...prev, ...annotatedPageLocations];
    },
    []
  );

  // This filters out duplicates until we find a way to show two overlapping annotations
  allAnnotations.forEach((a) => {
    tempMap.set(JSON.stringify(a.pageLocation), a);
  });

  return [...tempMap.values()];
}

export function isNumberValid(currentPage: number): boolean {
  return !(!currentPage || Number.isNaN(currentPage));
}

export function drawSearchMatches(
  canvas: HTMLCanvasElement,
  searchMatches: DocumentLocation[][],
  pageNumber: number,
  activeMatchIndex: number | null
): void {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  const matchesToDraw: { location: DocumentLocation; active: boolean }[] = [];

  searchMatches.forEach((match, i) => {
    const active = i === activeMatchIndex;
    match.forEach((location) => {
      if (location.pageNumber === pageNumber) {
        matchesToDraw.push({ active, location });
      }
    });
  });

  const pageBCRect = canvas.getBoundingClientRect();
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  matchesToDraw.forEach(({ location, active }) => {
    const { height, width, x, y } = projectAidToImage({
      annotation: {
        text: '',
        location: location.pageLocation,
      },
      pageWidth: pageBCRect.width,
      pageHeight: pageBCRect.height,
    });

    ctx.globalCompositeOperation = 'xor';
    ctx.globalAlpha = 0.35;
    ctx.fillStyle = active ? 'green' : 'red';
    ctx.fillRect(x, y, width, height);
  });
}

export function getPageVisibilityPercentage(
  container: HTMLDivElement,
  page: HTMLDivElement
) {
  const containerRect = container.getBoundingClientRect();
  const pageRect = page.getBoundingClientRect();

  const topVisibleHeight = Math.max(
    0,
    Math.min(pageRect.bottom, containerRect.bottom) - pageRect.top
  );
  const bottomVisibleHeight = Math.max(
    0,
    pageRect.bottom - Math.max(pageRect.top, containerRect.top)
  );

  const topPercentageVisible = topVisibleHeight / pageRect.height;
  const bottomPercentageVisible = bottomVisibleHeight / pageRect.height;

  return {
    top: topPercentageVisible,
    bottom: bottomPercentageVisible,
  };
}

export function checkIsCanvasProjectionInView(
  container: HTMLDivElement,
  page: HTMLDivElement,
  canvasProjection: CanvasProjection
) {
  const { top, bottom } = getPageVisibilityPercentage(container, page);
  const { y, height } = canvasProjection;

  switch (true) {
    // Entire page visible
    case top >= 1 && bottom >= 1:
      return true;
    // Bottom part visible
    case top >= 1 && bottom < 1:
      return y > 1 - bottom;
    //  Top part visible
    case top < 1 && bottom >= 1:
      return y + height < top;
    // Middle part visible - page is cut from both ends
    case top < 1 && bottom < 1:
      return y + height < top && y > 1 - bottom;
    default:
      return false;
  }
}
