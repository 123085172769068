import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import DropdownMenu from '../../DropdownMenu';
import {
  DocumentListItem,
  DocumentMetadata,
  DocumentSource,
} from '../../../types';
import { MenuConfig } from '../../DropdownMenu/DropdownMenu';
import useTranslation from '../../../translations';
import {
  DocumentEntry,
  DropdownAnchor,
  ExternalLink,
  FlexCenter,
  ToggleIcon,
} from './styled';
import { findDocumentName } from './Toolbar';

interface DocumentInfoProps {
  document: DocumentMetadata;
  documentsToSelect?: DocumentListItem[];
  onPreviousScreen?: () => void;
  onDocumentChange?: (d: DocumentMetadata) => void;
}

export const getDocumentName = (document: string) => {
  const isJSONLikeStructure =
    document.startsWith('{') && document.endsWith('}');

  if (isJSONLikeStructure) {
    const citedLiteratureRegexp = /CitedLiterature\s*=\s*([^,]+)/;
    const citedLiteratureMatch = document.match(citedLiteratureRegexp);

    if (citedLiteratureMatch) {
      return citedLiteratureMatch[1];
    } else {
      const citationNumberRegex = /CitationNumber\s*=\s*(\d+)/;
      const citationFormatRegex = /CitationNumberFormat\s*=\s*([^\s,]+)/;
      const citationNumberMatch = document.match(citationNumberRegex);
      const citationFormatMatch = document.match(citationFormatRegex);
      const citationNumber = citationNumberMatch ? citationNumberMatch[1] : '1';
      const citationFormat = citationFormatMatch
        ? citationFormatMatch[1]
        : 'D{0}';

      return citationFormat.replace('{0}', citationNumber);
    }
  } else {
    return document;
  }
};

const DocumentInfo: FC<DocumentInfoProps> = ({
  document,
  documentsToSelect,
  onPreviousScreen,
  onDocumentChange,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const documentName = getDocumentName(document.name);

  let docInfo = (
    <FlexCenter>
      {onPreviousScreen && (
        <Tooltip title={t('ACTION.BACK')}>
          <ToggleIcon
            style={{ marginRight: '7px' }}
            className="icn-arrow-left"
            onClick={onPreviousScreen}
          />
        </Tooltip>
      )}
      <Tooltip
        overlayClassName="document-info-tooltip"
        title={documentName}
        placement={'bottomRight'}
        color={theme.colors.black}
      >
        <DocumentEntry>{documentName}</DocumentEntry>
      </Tooltip>
    </FlexCenter>
  );

  if (documentsToSelect) {
    const documentName = getDocumentName(
      findDocumentName(documentsToSelect, document.id) || document.name
    );
    docInfo = (
      <DropdownMenu
        anchor={
          <DropdownAnchor>
            <Tooltip
              overlayClassName="document-info-tooltip"
              title={documentName}
              placement={'bottomRight'}
              color={theme.colors.black}
            >
              <DocumentEntry>{documentName}</DocumentEntry>
            </Tooltip>
            <i
              style={{ fontSize: '16px', lineHeight: '13px' }}
              className="icn-chevron-down"
            />
          </DropdownAnchor>
        }
        menuConfig={constructDocumentsMenu(documentsToSelect, onDocumentChange)}
      />
    );
  }

  return <>{docInfo}</>;
};

const constructMenuItems = (
  documents: DocumentMetadata[],
  onDocumentChange?: (documentReference: DocumentMetadata) => void
) =>
  documents.map((d) => ({
    label: getDocumentName(d.name),
    value: d,
    children: d.source === DocumentSource.None && (
      <ExternalLink className="icn-link" />
    ),
    onItemClick: (metadata: DocumentMetadata) => {
      if (d.source === DocumentSource.None && d.uri !== null) {
        window.open(d.uri, '_blank');
        return;
      }
      onDocumentChange?.(metadata);
    },
  }));

const constructSubmenu = (
  items: DocumentListItem[],
  onDocumentChange?: (documentReference: DocumentMetadata) => void
): MenuConfig<DocumentMetadata>[] =>
  items.map((item) => ({
    name: getDocumentName(item.name),
    items: constructMenuItems(item.documents, onDocumentChange),
    subMenu: constructSubmenu(item.subLists, onDocumentChange),
  }));

const constructDocumentsMenu = (
  items: DocumentListItem[],
  onDocumentChange?: (documentReference: DocumentMetadata) => void
): MenuConfig<DocumentMetadata> => ({
  name: '',
  items:
    items.length === 1
      ? constructMenuItems(items[0].documents, onDocumentChange)
      : [],
  subMenu: constructSubmenu(
    items.length === 1 ? items[0].subLists : items,
    onDocumentChange
  ),
});

export default DocumentInfo;
