/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormContext, useController, get } from 'react-hook-form';
import styled, { css, CSSObject } from 'styled-components';
import useTranslation from '../../translations';

interface Props {
  name: string;
  component: any;
  label?: React.ReactNode;
  wrapperTestId?: string;
  testId?: string;
  defaultValue?: string | null;
  passValue?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  componentStyle?: React.CSSProperties;
  errorStyle?: CSSObject;
  labelStyle?: CSSObject;
  onChange?: (value: any) => boolean | undefined;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onFocus?: () => void;
  componentRef?: React.Ref<any>;
  [any: string]: any;
}

const FormField: React.FC<Props> = ({
  name,
  component: Component,
  label,
  componentStyle,
  errorStyle,
  labelStyle,
  testId,
  defaultValue,
  required,
  hideErrors,
  componentRef,
  style,
  wrapperTestId,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  ...componentProps
}) => {
  const t = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const handleChange = (value: any) => {
    let newValue;

    if (value?.target) {
      switch (value.target.type) {
        case 'checkbox':
          newValue = value.target.checked;
          break;
        default:
          newValue = value.target.value;
      }
    } else {
      newValue = value;
    }

    // apply changes to form if the custom onChange handler doesn't return false
    const applyToForm = onChange == null || onChange(newValue) !== false;

    if (applyToForm) {
      field.onChange(newValue);
    }
  };

  const handleBlur = () => {
    field.onBlur();
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const error = get(errors, name);
  const isError = error != undefined;
  const additionalProps: Record<string, any> = {};

  // To avoid pesky React errors, we only append isError for styled components.
  if (Component?.styledComponentId !== undefined) {
    additionalProps.$hasError = isError;
  }

  return (
    <Outer $hasError={isError} style={style} data-testid={wrapperTestId}>
      <Label styleOverride={labelStyle}>
        {label}
        {!!required && '*'}
      </Label>
      <Component
        {...field}
        checked={field.value}
        {...additionalProps}
        style={componentStyle}
        ref={componentRef}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        data-testid={testId}
        {...componentProps}
      />
      {!hideErrors && isError && (
        <ValidationMsg styleOverride={errorStyle}>
          {t(error?.message)}
        </ValidationMsg>
      )}
    </Outer>
  );
};

export default FormField;

const Outer = styled.div<{ $hasError: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  ${({ $hasError, theme }) =>
    $hasError &&
    css`
      .ant-input,
      .ant-picker,
      .ant-select:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select-customize-input.domain-picker,
      .ant-select-customize-input.dropdown-select {
        border: 1px solid ${theme.colors.error};
        transition: border-color 0.3s linear;
      }
    `}
`;

const ValidationMsg = styled.span<{ styleOverride?: CSSObject }>`
  position: absolute;
  top: 100%;
  color: ${(props) => props.theme.colors.error};
  animation-name: antFadeIn;
  animation-duration: 0.3s;
  ${(props) =>
    css`
      ${props.styleOverride as CSSObject}
    `};
`;
const Label = styled.span<{ styleOverride?: CSSObject }>`
  font-size: 15px;
  ${(props) =>
    css`
      ${props.styleOverride as CSSObject}
    `};
`;
