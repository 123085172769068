import React from 'react';
import styled from 'styled-components';
import { Empty } from '../../icons';
import useTranslation from '../../translations';

interface Props {
  message?: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const EmptyNotification: React.FC<Props> = ({ message, style, textStyle }) => {
  const t = useTranslation();

  return (
    <Outer style={style}>
      <Empty />
      <Text style={textStyle}>{message ?? t('NO_DATA_AVAILABLE')}</Text>
    </Outer>
  );
};

export default EmptyNotification;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 25px;
`;

const Text = styled.div`
  text-align: center;
  width: 280px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.white60};
`;
