import React, { useMemo } from 'react';
import { RadialBar, RadialBarConfig } from '@ant-design/charts';
import { useTheme } from 'styled-components';
import { RadialBarChartConfig, StyleMapping } from '../../types';

interface Props {
  config: RadialBarChartConfig;
}

const RadialBarChart: React.FC<Props> = ({ config }) => {
  const { data, series, xAxis, yAxis, description, tooltip } = config;

  // TODO: into a custom hook
  // const colorPalette = useColorPalette();
  const theme = useTheme();
  const colorPalette = useMemo(
    () => [
      theme.colors.visualization.aqua300,
      theme.colors.visualization.green300,
      theme.colors.visualization.plum300,
      theme.colors.visualization.red300,
      theme.colors.visualization.gold300,
    ],
    []
  );

  //reduce chart series into a hash<seriesName, color>
  const colors = series.reduce<StyleMapping>((prev, curr, idx) => {
    const retVal = { ...prev };
    let c = colorPalette[idx % colorPalette.length];
    if (curr.color) {
      c = curr.color;
    }
    retVal[curr.name] = c;
    return retVal;
  }, {});

  const radialBarConfig: RadialBarConfig = {
    colorField: 'name',
    data: data ?? [],
    xField: 'x',
    yField: 'y',
    color: ({ name }) => colors[name],
    label: {
      position: 'top',
      style: {
        fill: 'white',
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
      },
    },
    radius: 1,
    innerRadius: 0.5,
    maxAngle: 250,
    type: 'line',
    tooltip: tooltip
      ? {
          formatter: tooltip.formatter,
        }
      : false,
    annotations: description
      ? [
          {
            type: 'text',
            autoAdjust: true,
            position: ['50%', '50%'],
            content: description,
            style: {
              textAlign: 'center',
              fontSize: 14,
              fontFamily: "'Roboto', sans-serif",
              fill: 'white',
              fillOpacity: 0.8,
            },
          },
        ]
      : undefined,
    xAxis: xAxis
      ? {
          title: xAxis.title
            ? {
                text: xAxis.title,
                style: { fill: 'white', fontSize: 14, fillOpacity: 0.6 },
              }
            : undefined,
          label: { style: { fill: 'white' } },
        }
      : undefined,
    yAxis: yAxis
      ? {
          title: yAxis.title
            ? {
                text: yAxis.title,
                style: { fill: 'white', fontSize: 14, fillOpacity: 0.6 },
              }
            : undefined,
          label: { style: { fill: 'white' } },
        }
      : undefined,
  };
  return <RadialBar {...radialBarConfig} />;
};

export default RadialBarChart;
