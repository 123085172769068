import { T } from '../../index';
import { UserSettings } from '../types/userSettings';
import Api from './base';

const API_BASE = '/v1/settings';

export const getUserSettings = (): Promise<UserSettings> =>
  Api.get<UserSettings>(`${API_BASE}`).then((response) => response.data);

export const updateUserSettings = (
  entry: UserSettings
): Promise<T.UserSettings> =>
  Api.put<T.UserSettings>(`${API_BASE}`, entry).then(
    (response) => response.data
  );
