import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, message, Space } from 'antd';
import { useMutation } from 'react-query';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CompanyNameVariationsPicker from './CompanyNameVariationsPicker';
import { Api, Components, T, Hooks } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { QueryKey } from 'queries';
import { addTsdElementCompanySchema } from 'validations';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { Form } = Components;
const { useInvalidateQueries } = Hooks;
const { FormField, CountryPicker } = Form;

export type SaveCompanyGroupInput = {
  groupName: string;
  editedGroup: T.CompanyGroup | null;
};

interface Props {
  defaultValue: SaveCompanyGroupInput;
  onDone: () => void;
}

const CreateCompanyGroup: React.FC<Props> = ({ defaultValue, onDone }) => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { preferredLanguage } = useAppContext();
  const { applyChange } = useElementUsageCheck();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();

  const { groupName, editedGroup } = defaultValue;

  useEffect(() => {
    if (editedGroup) {
      setSarchEnabled(true);
    }
  }, [editedGroup]);

  const methods = useForm<T.PatstatCompanyGroup>({
    defaultValues: {
      name: editedGroup?.name ?? groupName,
      city: editedGroup?.city ?? '',
      countryCode: editedGroup?.countryCode ?? '', // TODO: add default value / nullable
      companyNameVariations:
        editedGroup?.companiesInGroup.map((c) => ({
          name: c.name,
          psnId: c.psnId,
          numberOfApplications: c.numberOfApplications,
          earliestFilingYear: c.earliestFilingYear,
          latestFilingYear: c.latestFilingYear,
        })) ?? [],
    },
    resolver: yupResolver(addTsdElementCompanySchema),
  });

  const [isSearchEnabled, setSarchEnabled] = useState<boolean>(false);
  const searchTerm = methods.watch('name');

  const createCompanyGroupMutation = useMutation(
    (newCompanyGroup: T.PatstatCompanyGroup) =>
      Api.tsd.createTsdElementCompanyGroup({
        tsdElementId,
        tsdNodeId,
        newCompanyGroup,
      }),
    {
      onSuccess: async () => {
        message.success(t('COMPANIES.CREATE_AND_ASSIGN_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementCompanies, tsdElementId, tsdNodeId]
        );
        onDone();
      },
    }
  );

  const editCompanyGroupMutation = useMutation(
    (updatedCompanyGroup: T.UpdateCompanyGroup) =>
      Api.companies.updateCompanyGroup(updatedCompanyGroup),
    {
      onSuccess: async () => {
        message.success(t('COMPANIES.UPDATE_SUCCESS'));
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementCompanies, tsdElementId, tsdNodeId],
          QueryKey.CompanyGroups
        );
        onDone();
      },
    }
  );

  const handleSubmit = (values: T.PatstatCompanyGroup) => {
    if (defaultValue.editedGroup) {
      editCompanyGroupMutation.mutate({
        ...values,
        companyGroupId: defaultValue.editedGroup.id,
      });
    } else {
      applyChange(() => createCompanyGroupMutation.mutate(values));
    }
  };

  const handleSearchClick = () => {
    setSarchEnabled(true);
  };

  const handleSearchEnd = () => {
    setSarchEnabled(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Space direction="vertical" size="middle">
          <Inner>
            <Space size="middle">
              <FormField
                name="name"
                label={t('COMPANIES.NAME')}
                component={Input}
                autoFocus
                required
                autoComplete="off"
                disabled={editedGroup !== null}
              />
              <Button
                type="primary"
                style={{ marginTop: 24 }}
                disabled={searchTerm.trim() === ''}
                onClick={handleSearchClick}
              >
                {t('COMPANIES.SEARCH')}
              </Button>
            </Space>
            <Space size="middle" style={{ marginTop: 10 }}>
              <FormField
                name="city"
                label={t('COMPANIES.CITY')}
                component={Input}
                autoComplete="off"
              />
              <FormField
                name="countryCode"
                label={t('COMPANIES.COUNTRY')}
                component={CountryPicker}
                autoComplete="off"
                style={{ width: 180 }}
              />
            </Space>
          </Inner>
          <FormField
            name="companyNameVariations"
            label={t('COMPANIES.NAME_VARIATIONS')}
            component={CompanyNameVariationsPicker}
            searchTerm={searchTerm}
            isSearchEnabled={isSearchEnabled}
            defaultSelectedKeys={editedGroup?.companiesInGroup.map(
              (c) => c.name
            )}
            onSearchEnd={handleSearchEnd}
          />
          <Footer>
            <Button onClick={onDone}>{t('ACTION.CANCEL')}</Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={
                createCompanyGroupMutation.isLoading ||
                editCompanyGroupMutation.isLoading ||
                isInvalidating
              }
            >
              {t(
                editedGroup
                  ? 'COMPANIES.UPDATE_COMPANY_GROUP'
                  : 'COMPANIES.CREATE_COMPANY_GROUP'
              )}
            </Button>
          </Footer>
        </Space>
      </form>
    </FormProvider>
  );
};

export default CreateCompanyGroup;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
