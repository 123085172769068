import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';

const useTsd = (
  tsdId: string,
  options?: UseQueryOptions<T.Tsd, AxiosError>
): UseQueryResult<T.Tsd, AxiosError> => {
  const { preferredLanguage } = useAppContext();
  return useQuery<T.Tsd, AxiosError>(
    [QueryKey.TsdData, tsdId, preferredLanguage],
    () => Api.tsd.getTsdById(tsdId, preferredLanguage),
    options
  );
};

export default useTsd;
