import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, message, Tooltip } from 'antd';
import { useMutation } from 'react-query';
import useTranslation from '../../translations';
import Notification from '../Notification';
import { useInvalidateQueries } from '../../hooks';
import { CreateCompetithorReport } from '../../types';
import { QueryKey, useCompetithorReportsSearch } from '../../queries';
import { createNewReport } from '../../api/competithor';
import { Colors } from '../../theme';
import CompetitorReportModalContent from './CompetitorReportModalContent';

interface Props {
  onCloseRequest: (e: React.MouseEvent) => void;
  reportRequest: CreateCompetithorReport;
  modalStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  hideCompetitorBrandLogo?: boolean;
}

const CompetitorReportModal: React.FC<Props> = ({
  onCloseRequest,
  reportRequest,
  modalStyle,
  bodyStyle,
  hideCompetitorBrandLogo,
}) => {
  const t = useTranslation();
  const { invalidateQueries } = useInvalidateQueries();

  const [competitorReportId, setCompetitorReportId] = useState('');

  const createNewReportMutation = useMutation(
    (newReport: CreateCompetithorReport) => createNewReport(newReport),
    {
      onSuccess: () => {
        message.success(t('COMPETITOR.SUCCESSFUL_INITIALIZATION'));
        invalidateQueries([QueryKey.CompetithorReportsSearch]);
      },
    }
  );

  const { isLoading, isIdle, isError, data } = useCompetithorReportsSearch({
    patStatReportId: reportRequest.patStatReportId,
    inputs: reportRequest.inputs,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setCompetitorReportId(data[0].id);
    } else if (data && data.length === 0) {
      createNewReportMutation.mutate({
        title: reportRequest.title,
        description: reportRequest.description,
        inputs: reportRequest.inputs,
        reportParams: reportRequest.reportParams,
        patStatReportId: reportRequest.patStatReportId,
      });
    }
  }, [data]);

  useEffect(() => {
    if (createNewReportMutation.data) {
      setCompetitorReportId(createNewReportMutation.data);
    }
  }, [createNewReportMutation.data]);

  if (isLoading || isIdle) {
    return (
      <Notification
        spin
        spinSize="small"
        style={{
          // TODO: find a better way to handle the loading spinner so it doesn't interfere with other elements
          position: 'absolute',
          right: '-130%',
          top: '-15px',
        }}
      />
    );
  }

  if (isError) {
    return (
      <ErrorWrap>
        <Tooltip
          title={t('SOMETHING_WENT_WRONG')}
          placement="top"
          color={Colors.gradient100}
        >
          <i
            className="icn-error-outlined"
            style={{
              cursor: 'pointer',
              fontSize: 16,
              color: Colors.red500,
            }}
          />
        </Tooltip>
      </ErrorWrap>
    );
  }

  if (data && data.length === 0 && !createNewReportMutation.data) {
    return null;
  }

  return (
    <Modal
      destroyOnClose
      onCancel={onCloseRequest}
      style={{
        top: 25,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        zIndex: 100000,
        ...modalStyle,
      }}
      bodyStyle={{
        padding: 5,
        height: `calc(100vh - 50px)`,
        width: `calc(100vw - 50px)`,
        ...bodyStyle,
      }}
      closeIcon={
        <Icon>
          <i
            className="icn-clear"
            style={{
              fontSize: 28,
            }}
          />
        </Icon>
      }
      open={true}
      footer={null}
      maskClosable={false}
    >
      <CompetitorReportModalContent
        competitorReportId={competitorReportId}
        hideCompetitorBrandLogo={hideCompetitorBrandLogo}
      />
    </Modal>
  );
};

export default CompetitorReportModal;

const Icon = styled.span`
  width: 52px;
  height: 52px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  padding: 12px;
  border-radius: 52px;
  box-shadow: 0 10px 20px 0 ${(props) => props.theme.colors.black20};
  background-color: ${(props) => props.theme.colors.primary100};
  transition: background-color 0.1s ease-in;
  ${css`
    &:hover {
      z-index: 99;
      background-color: ${(props) => props.theme.colors.white50};
    }
  `}
`;

const ErrorWrap = styled.div`
  position: absolute;
  top: 0;
  right: -23px;
`;
