import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RequestSignup } from '../../types';
import signupSchema from '../../validations/signupSchema';
import { auth as authApi } from '../../api';
import useTranslation from '../../translations';
import SHARED_ROUTES, { generateUrl } from '../../utils/routing';
import EmailChecker from './EmailChecker';
import {
  Outer,
  Prompt,
  AuthAction,
  ActionButton,
  LinkButton,
  Result,
  ResultStatus,
} from './Common';

const Signup: React.FC = () => {
  const t = useTranslation();
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

  const methods = useForm<RequestSignup>({
    defaultValues: {
      email: '',
      fullName: '',
      context: `${window.location.origin}/signup-verification/{0}`,
    },
    resolver: yupResolver(signupSchema),
  });

  const { isSuccess, isLoading, error, mutate, reset } = useMutation<
    boolean,
    AxiosError,
    RequestSignup
  >((data) => authApi.requestSignup(data));

  const email = methods.watch('email');
  const isSubmitted = methods.formState.submitCount > 0;

  // Disable submit while debouncing new input
  useEffect(() => {
    setIsValidEmail(false);
  }, [email]);

  const handleEmailChange = (value: string) => {
    methods.setValue('email', value);
  };

  const handleEmailValidationChange = (isValid: boolean) => {
    setIsValidEmail(isValid);
  };

  const handleSubmit = (data: RequestSignup) => {
    if (isValidEmail) {
      mutate(data);
    }
  };

  const getErrorContent = useCallback(
    (code?: string): [ResultStatus, string, string, React.ReactNode] => {
      let status: ResultStatus = 'error';
      let title = t('SIGNUP.REQUEST_FAILED_TITLE');
      let subtitle = t('SOMETHING_WENT_WRONG');
      let extra = (
        <ActionButton
          onClick={() => {
            methods.reset();
            reset();
          }}
        >
          {t('SIGNUP.BACK_TO_SIGNUP')}
        </ActionButton>
      );
      switch (code) {
        case 'AuthInvalidEmailForSignup':
          subtitle = t('SIGNUP.ERRORS.INVALID_EMAIL_REQUEST');
          break;
        case 'AuthSignupMaxRetriesReached':
          subtitle = t('SIGNUP.ERRORS.MAX_RETRIES_REACHED');
          break;
        case 'AuthSignedUpAlready':
          status = 'warning';
          title = t('SIGNUP.ERRORS.SIGNED_UP_ALREADY_TITLE');
          subtitle = t('SIGNUP.ERRORS.SIGNED_UP_ALREADY_SUBTITLE');
          extra = (
            <LinkButton to={generateUrl(SHARED_ROUTES.LOGIN)}>
              {t('SIGNUP.BACK_TO_LOGIN')}
            </LinkButton>
          );
          break;
      }
      return [status, title, subtitle, extra];
    },
    []
  );

  let content;
  switch (true) {
    case error != null:
      const errorCode = error?.response?.data?.Code;
      const [status, title, subtitle, extra] = getErrorContent(errorCode);
      content = (
        <Result
          status={status}
          title={title}
          subTitle={subtitle}
          extra={extra}
        />
      );
      break;
    case isSuccess:
      content = (
        <Result
          status="success"
          title={t('SIGNUP.REQUEST_SUCCESS_TITLE')}
          subTitle={t('SIGNUP.REQUEST_SUCCESS_DESC')}
          subText={t('SIGNUP.REQUEST_SUCCESS_TIP')}
          extra={
            <LinkButton to={generateUrl(SHARED_ROUTES.LOGIN)}>
              {t('SIGNUP.BACK_TO_LOGIN')}
            </LinkButton>
          }
        />
      );
      break;
    default:
      content = (
        <>
          <Title>{t('SIGNUP.TITLE')}</Title>
          <MainContent>
            <FormProvider {...methods}>
              <StyledForm onSubmit={methods.handleSubmit(handleSubmit)}>
                <EmailChecker
                  value={email}
                  showErrorWhenEmpty={isSubmitted}
                  onChange={handleEmailChange}
                  onValidChange={handleEmailValidationChange}
                />
                <ActionButton htmlType="submit" loading={isLoading}>
                  {t('SIGNUP.BUTTON')}
                </ActionButton>
              </StyledForm>
            </FormProvider>
          </MainContent>
          <Prompt action={AuthAction.Login} />
        </>
      );
  }

  return <Outer>{content}</Outer>;
};

export default Signup;

const Title = styled.div`
  margin: 30px 0 45px;
  font-size: 24px;
  line-height: 1.42;
  color: ${(props) => props.theme.colors.white87};
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  gap: 30px;
`;
