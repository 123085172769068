import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Popover } from 'react-tiny-popover';
import useTranslation from '../../translations';
import { T } from '../../..';
import { truncate } from '../../utils';
import { NoteContextItem } from './Notes';

interface Props {
  items: NoteContextItem[];
  value: T.NoteContext;
  onChange: (item: NoteContextItem) => void;
}

const NotesContextPicker: React.FC<Props> = ({ items, value, onChange }) => {
  const t = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const closePopover = () => setIsPopoverOpen(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);

  const handleChange = (item: NoteContextItem) => {
    onChange(item);
    closePopover();
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      align="start"
      containerStyle={{ marginTop: '5px' }}
      onClickOutside={closePopover}
      content={
        <Content onClick={(e) => e.stopPropagation()}>
          {items.map((item) => (
            <Item
              key={item.context}
              active={item.context === value}
              onClick={() => handleChange(item)}
            >
              {t(`NOTES.${item.context.toUpperCase()}`)}
            </Item>
          ))}
        </Content>
      }
    >
      <Trigger active={isPopoverOpen} onClick={togglePopover}>
        <NoteContextName>{t(`NOTES.${value.toUpperCase()}`)}</NoteContextName>
        <ChevronIcon className="icn-chevron-down" />
      </Trigger>
    </Popover>
  );
};

export default NotesContextPicker;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  border-radius: 4px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.13);
  background-image: linear-gradient(
    120deg,
    ${(props) => props.theme.colors.primary400},
    ${(props) => props.theme.colors.primary500}
  );
`;

const HIGHLIGHTED_ITEM_STYLE = css`
  background-color: ${(props) => props.theme.colors.white10};
  color: ${(props) => props.theme.colors.white87};
`;

const Item = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  transition: all 0.2s ease-in;
  &:first-child {
    border-radius: 4px 4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
  color: ${(props) => props.theme.colors.white60};
  &:hover {
    ${HIGHLIGHTED_ITEM_STYLE}
  }
  ${(props) => props.active && HIGHLIGHTED_ITEM_STYLE}
`;

const Trigger = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  border-radius: 3px;
  transition: background-color 0.2s ease-in;
  ${(props) =>
    props.active && `background-color: ${props.theme.colors.primary200_20};`}
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
`;

const NoteContextName = styled.span`
  min-width: 0;
  color: ${(props) => props.theme.colors.white70};
  ${truncate()}
`;

const ChevronIcon = styled.i`
  margin-left: 6px;
  font-size: 24px;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.white40};
`;
