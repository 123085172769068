import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { DocumentFormat } from '../../../types';
import useTranslation from '../../../translations';

interface Props {
  format: DocumentFormat;
  checked: boolean;
  indeterminate?: boolean;
  showLabel?: boolean;
  onCheck: (format: DocumentFormat, checked: boolean) => void;
}

const FormatSelectionItem: React.FC<Props> = ({
  format,
  checked,
  indeterminate,
  showLabel = false,
  onCheck,
}) => {
  const t = useTranslation();

  return (
    <Outer checked={checked}>
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={(e) => onCheck(format, e.target.checked)}
      />
      {showLabel && <Label>{t(`DOC_DOWNLOAD.${format.toUpperCase()}`)}</Label>}
    </Outer>
  );
};

export default FormatSelectionItem;

const Outer = styled.div<{ checked?: boolean }>`
  width: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-checkbox-inner {
    ${(props) =>
      !props.checked && `border-color: ${props.theme.colors.white20}`};
  }
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white60};
`;
