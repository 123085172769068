import React from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { T, Components } from '@ipos/shared';
import useTranslation from 'translations';
import { TsdElementAttributeListItem } from 'components/TsdElement/Attributes';

const {
  Uploads: { Image },
} = Components;

interface Props {
  item: T.TsdElementUpload;
  onRemoveRequest?: (id: string) => void;
}

const EditGraphicsList: React.FC<Props> = ({ item, onRemoveRequest }) => {
  const t = useTranslation();
  const { upload, id, isBaseAttribute } = item;
  const { fileName } = upload;

  return (
    <TsdElementAttributeListItem
      isBaseAttribute={isBaseAttribute}
      confirmRemovalTitle={t('GRAPHICS.DELETE_CONFIRMATION')}
      onRemoveRequest={() => onRemoveRequest && onRemoveRequest(id)}
    >
      <Space>
        <ImageWrapper>
          <Image uploadId={upload.id} />
        </ImageWrapper>
        {fileName}
      </Space>
    </TsdElementAttributeListItem>
  );
};

export default EditGraphicsList;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
`;
