import React, { useState } from 'react';
import { TutorialsModal } from '../../Tutorials';
import useTranslation from '../../../translations';
import NavigationItem from './NavigationItem';

const TutorialsMenu: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <NavigationItem
        iconClassName="icn-patent-pulse"
        tooltipText={t('NAVIGATION_TOOLTIPS.TUTORIALS')}
        iconStyle={{ fontSize: 34 }}
        isSettingsItem
        active={isModalOpen}
        onClick={() => setModalOpen(true)}
      />
      {isModalOpen && <TutorialsModal onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default TutorialsMenu;
