import React, { useState } from 'react';
import { Button, Modal, message, Typography, Space } from 'antd';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import IpcClassSelector from './IpcClassSelector';
import { Api, Permissions, T, Hooks } from '@ipos/shared';
import useTranslation from 'translations';
import { QueryKey } from 'queries';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';

const { useInvalidateQueries } = Hooks;
const { Text } = Typography;
const { WithPermission } = Permissions;

interface Props {
  selectedClassIds: string[];
}

const AddIpcClassesButton: React.FC<Props> = ({ selectedClassIds }) => {
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { preferredLanguage } = useAppContext();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const [newlySelectedIds, setNewlySelectedIds] = useState<string[]>([]);

  const t = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const assignIpcClassesMutation = useMutation(
    (classIdsToAdd: string[]) =>
      Api.tsd.addTsdElementIpcClasses(tsdElementId, classIdsToAdd, tsdNodeId),
    {
      onSuccess: async (response: T.TsdElementIpcClass[]) => {
        message.success(
          t('IPC.CLASSES_ADDED_SUCCESSFULLY', { count: response.length })
        );
        await invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementIpcClasses, tsdElementId, tsdNodeId]
        );
      },
    }
  );

  const closeModal = () => {
    setIsModalVisible(false);
    setNewlySelectedIds([]);
  };

  const handleCancelModal = () => {
    closeModal();
  };

  const handleSelection = (classes: string[]): void => {
    const newSelections = classes.reduce((p: string[], c: string) => {
      if (selectedClassIds.includes(c)) {
        return p;
      } else {
        return [...p, c];
      }
    }, []);

    setNewlySelectedIds(newSelections);
  };

  const handleCreate = () => {
    applyChange(() =>
      assignIpcClassesMutation.mutate(newlySelectedIds, {
        onSuccess: () => {
          closeModal();
        },
      })
    );
  };

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={() => setIsModalVisible(true)} type="primary">
        {t('IPC.ADD')}
      </Button>
      <Modal
        width={800}
        onCancel={handleCancelModal}
        destroyOnClose
        title={t('IPC.ADD_IPC_CLASSES_TITLE')}
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            {t('ACTION.CANCEL')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={newlySelectedIds.length === 0}
            loading={assignIpcClassesMutation.isLoading || isInvalidating}
            onClick={handleCreate}
          >
            {t('IPC.ADD_CLASSES', { count: newlySelectedIds.length })}
          </Button>,
        ]}
        open={isModalVisible}
      >
        <Outer>
          <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <Text>{t('IPC.INTRO')}</Text>
            <IpcClassSelector
              onSelect={handleSelection}
              filterValues={selectedClassIds}
            />
          </Space>
        </Outer>
      </Modal>
    </WithPermission>
  );
};

export default AddIpcClassesButton;

const Outer = styled.div`
  height: 360px;
`;
