import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { auth as authApi } from '../../api';
import { Spinner } from '../Spinner';
import useTranslation from '../../translations';
import SHARED_ROUTES, { generateUrl } from '../../utils/routing';
import { Subtitle } from './Common/Result';
import {
  Outer,
  LinkButton,
  Result,
  ResultStatus,
  ActionButton,
} from './Common';

const REDIRECT_TIMEOUT = 5000;
const ENABLE_SUBMISSION_DELAY = 100;

const VerifySignup: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [input, setInput] = useState('');
  const [enableSubmission, setEnableSubmission] = useState(false);
  const isValidInput = input.trim().length >= 6;

  const { isLoading, isError, isSuccess, mutate, error } = useMutation<
    boolean,
    AxiosError,
    string
  >((tokenParam: string) => authApi.verifySignup(tokenParam), {
    onSuccess() {
      setTimeout(
        () => history.push(generateUrl(SHARED_ROUTES.LOGIN)),
        REDIRECT_TIMEOUT
      );
    },
  });

  // Introduce timoute to try to confuse the email bot
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isValidInput) {
      setEnableSubmission(false);
      return;
    }
    if (isValidInput && !enableSubmission) {
      timeout = setTimeout(() => {
        setEnableSubmission(true);
      }, ENABLE_SUBMISSION_DELAY);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [enableSubmission, isValidInput]);

  const handleVerificationRequest = () => {
    const securityCode = input.trim().toLowerCase();
    mutate(`${token}${securityCode}`);
  };

  const getErrorContent = useCallback(
    (code?: string): [ResultStatus, string, string, React.ReactNode] => {
      let status: ResultStatus = 'error';
      let title = t('SIGNUP.VERIFICATION.FAILED_TITLE');
      let subtitle = t('SOMETHING_WENT_WRONG');
      let extra = (
        <LinkButton to={generateUrl(SHARED_ROUTES.SIGNUP)}>
          {t('SIGNUP.BACK_TO_SIGNUP')}
        </LinkButton>
      );
      switch (code) {
        case 'AuthExpiredRequest':
          status = 'timeout';
          title = t('SIGNUP.VERIFICATION.TIMED_OUT_TITLE');
          subtitle = t('SIGNUP.ERRORS.EXPIRED_TOKEN');
          break;
        case 'AuthSignedUpAlready':
          status = 'warning';
          title = t('SIGNUP.ERRORS.SIGNED_UP_ALREADY_TITLE');
          subtitle = t('SIGNUP.ERRORS.SIGNED_UP_ALREADY_SUBTITLE');
          extra = (
            <LinkButton to={generateUrl(SHARED_ROUTES.LOGIN)}>
              {t('SIGNUP.BACK_TO_LOGIN')}
            </LinkButton>
          );
          break;
        case 'AuthInvalidSelfSignupToken':
          subtitle = t('SIGNUP.ERRORS.INVALID_TOKEN');
      }
      return [status, title, subtitle, extra];
    },
    []
  );

  let content;
  switch (true) {
    case isLoading:
      content = (
        <Loading>
          <Spinner size="large" tip={t('SIGNUP.VERIFICATION.TIP')} />
        </Loading>
      );
      break;
    case isError:
      const errorCode = error?.response?.data?.Code;
      const [status, title, subtitle, extra] = getErrorContent(errorCode);
      content = (
        <Result
          status={status}
          title={title}
          subTitle={subtitle}
          extra={extra}
        />
      );
      break;
    case isSuccess:
      content = (
        <Result
          status="success"
          title={t('SIGNUP.VERIFICATION.COMPLETED_TITLE')}
          subTitle={t('SIGNUP.VERIFICATION.COMPLETED_DESC')}
          subText={t('SIGNUP.VERIFICATION.REDIRECT', {
            sec: REDIRECT_TIMEOUT / 1000,
          })}
          extra={
            <LinkButton to={generateUrl(SHARED_ROUTES.LOGIN)}>
              {t('SIGNUP.BACK_TO_LOGIN')}
            </LinkButton>
          }
        />
      );
      break;
    default:
      content = (
        <>
          <Title>{t('SIGNUP.VERIFICATION.TITLE')}</Title>
          <MainContent>
            <Subtitle>{t('SIGNUP.VERIFICATION.SUBTITLE')}</Subtitle>
            <StyledInput
              placeholder={t('SIGNUP.VERIFICATION.PLACEHOLDER')}
              type="text"
              autoComplete="off"
              autoFocus
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <ActionButton
              onClick={handleVerificationRequest}
              disabled={!isValidInput || !enableSubmission}
            >
              {t('SIGNUP.VERIFICATION.COMPLETE')}
            </ActionButton>
          </MainContent>
        </>
      );
  }

  return <Outer>{content}</Outer>;
};

export default VerifySignup;

const Loading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
`;

const Title = styled.div`
  margin: 30px 0 20px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.white87};
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const StyledInput = styled.input`
  outline: none;
  height: 45px;
  border-radius: 6px;
  width: 300px;
  padding: 0 10px;
  margin-top: 5px;
  color: ${(props) => props.theme.colors.white87};
  background-color: ${(props) => props.theme.colors.primary200_30};
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  &::placeholder {
    color: ${(props) => props.theme.colors.white40};
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blue700};
  }
`;
