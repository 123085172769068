import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import debounce from 'lodash.debounce';
import createPopUp, {
  PopUpHandle,
} from '../../toolbar/renderers/popup/createPopUp';
import { atAnchorTopRight } from '../../toolbar/renderers/popup/PopUpPosition';
import Modal from '../comment/Modal';
import { T } from '../../../../..';
import { findCommentAtPos } from './utils';

interface Options {
  session: T.Session;
}

export interface IComment {
  id: string;
  createdById: string;
  createdOn: string;
  modifiedOn: string;
  text: string;
  fullName: string;
  resolved: boolean;
  from: number;
  to: number;
}

export const commentPluginKey = new PluginKey('commentPlugin');

const commentPlugin = (options: Options) =>
  new Plugin({
    key: commentPluginKey,
    state: {
      init: () => ({ isOpen: false }),
      apply(tr, value) {
        const meta = tr.getMeta(commentPluginKey);
        if (meta && meta.isOpen !== undefined) {
          return { ...value, isOpen: meta.isOpen };
        }
        return value;
      },
    },
    props: {
      decorations(state: EditorState) {
        const { from, to } = state.selection;
        if (!from || !to) {
          return DecorationSet.empty;
        }
        return DecorationSet.create(state.doc, [
          Decoration.inline(from, to, { class: 'comment' }),
        ]);
      },
      handleClickOn(view, pos) {
        const comments = findCommentAtPos(pos, view.state) as IComment[];
        if (comments && comments.length > 0) {
          const { from, to } = comments[0];
          view.dispatch(
            view.state.tr
              .setSelection(TextSelection.create(view.state.doc, from, to))
              .setMeta(commentPluginKey, { isOpen: true })
          );
          return true;
        }
        return false;
      },
    },
    view(editorView) {
      return new CommentView(editorView, options);
    },
  });

class CommentView {
  editorView: EditorView;
  _popUp: PopUpHandle | null = null;
  debouncedShowPopUp: (view: EditorView) => void;
  options: Options;

  constructor(editorView: EditorView, options: Options) {
    this.editorView = editorView;
    this.options = options;
    this.showPopUp = this.showPopUp.bind(this);
    this._onClose = this._onClose.bind(this);
    this.debouncedShowPopUp = debounce(this.showPopUp, 200);
  }

  update(view: EditorView) {
    const { isOpen } = commentPluginKey.getState(view.state);
    if (view.state.selection.empty || !isOpen) {
      this.destroy();
      return;
    }
    this.debouncedShowPopUp(view);
  }

  showPopUp(view: EditorView) {
    const { isOpen } = commentPluginKey.getState(view.state);
    const { from, to } = view.state.selection;
    const domFound = view.domAtPos(from);
    const el: HTMLElement | null = view.dom.parentElement as HTMLElement;
    const parentNode = domFound.node.parentNode as HTMLElement;

    if (from === to || !isOpen) {
      this.destroy();
      return;
    }

    const comments: IComment[] = [];
    const commentMarkType = view.state.schema.marks.comment;

    view.state.doc.nodesBetween(from, to, (node) => {
      if (!node.isInline) {
        return;
      }

      node.marks.forEach((mark) => {
        if (mark.type === commentMarkType && mark.attrs.comments) {
          comments.push(...mark.attrs.comments);
        }
      });
    });

    const popUp = this._popUp;
    const viewProps = {
      editorState: view.state,
      editorView: view,
      anchor: el,
      session: this.options.session,
      parentNode,
      comments: comments.filter(
        (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
      ),
      onClose: this.destroy.bind(this),
    };

    if (!el || !parentNode) {
      this._popUp && this._popUp.close();
    } else {
      popUp && popUp.close();
      this._popUp = createPopUp(Modal, viewProps, {
        anchor: el,
        autoDismiss: false,
        onClose: this._onClose,
        position: atAnchorTopRight,
      });
    }
  }

  destroy() {
    if (this._popUp) {
      this._popUp.close();
      this._popUp = null;
      this.editorView.dispatch(
        this.editorView.state.tr.setMeta(commentPluginKey, {
          isOpen: false,
        })
      );
    }
  }

  _onClose() {
    this._popUp = null;
  }
}

export default commentPlugin;
