import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../../../Button';
import { Icon } from '../../../Icon';
import { IComment } from './commentPlugin';

interface ModalHeaderProps {
  onMoveUp: () => void;
  onMoveDown: () => void;
  currentIndex: number;
  onResolve: (currentIndex: number) => void;
  onClose: () => void;
  comments: IComment[];
  isVisible: boolean;
}
const ModalHeader: React.FC<ModalHeaderProps> = ({
  onMoveDown,
  onMoveUp,
  currentIndex,
  onResolve,
  onClose,
  comments,
  isVisible,
}) => (
  <Outer isVisible={isVisible}>
    <Flex>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onMoveUp();
        }}
        leftIcon={<Icon className="icn-chevron-up" size={16} />}
      />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onMoveDown();
        }}
        leftIcon={<Icon className="icn-chevron-down" size={16} />}
      />
      <CommentIndex>
        {currentIndex + 1} of {comments.length}
      </CommentIndex>
    </Flex>
    <Flex>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onResolve(currentIndex);
        }}
        leftIcon={<Icon className="icn-done-outlined" size={16} />}
      />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        leftIcon={<Icon className="icn-clear" size={16} />}
      />
    </Flex>
  </Outer>
);

const Outer = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: space-between;
  margin-bottom: 12px;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const IconButton = styled(FlatButton)`
  margin: 0;
  padding: 6px;
  color: ${(props) => props.theme.colors.black60};
  &:hover {
    background-color: transparent;
  }
`;

const CommentIndex = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary900};
  margin-left: 8px;
`;

export default ModalHeader;
