import {
  Plugin,
  PluginKey,
  TextSelection,
  Transaction,
  EditorState,
} from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { ResolvedPos } from 'prosemirror-model';
import { v4 as uuid } from 'uuid';
import createPopUp, {
  PopUpHandle,
} from '../../toolbar/renderers/popup/createPopUp';
import { atAnchorBottomLeft } from '../../toolbar/renderers/popup/PopUpPosition';
import { CLAIM_REFERENCE } from '../../schema/nodes/nodeNames';
import { ReferenceOption } from '../../../../types';
import {
  REGEX_REFERENCE_PATTERN,
  transformReferenceMenuOptions,
} from '../../util';
import ClaimReferenceMenu from './ClaimReferenceMenu';

export interface PluginState {
  active: boolean;
  range: { from: number; to: number };
  text: string;
  index: number;
  query: string;
}
export interface AddReference {
  view: EditorView;
  from: number;
  to: number;
  isNewOption: boolean;
  ref: string;
}

interface Options {
  setReferenceList?: React.Dispatch<React.SetStateAction<ReferenceOption[]>>;
}

interface PluginOptions {
  trigger: string;
}

function getNewState(): PluginState {
  return {
    active: false,
    range: { from: 0, to: 0 },
    text: '',
    index: 0,
    query: '',
  };
}

function getMatch(
  $position: ResolvedPos,
  opts: PluginOptions
):
  | { range: { from: number; to: number }; query: string; text: string }
  | undefined {
  const parastart = $position.before();
  const text = $position.doc.textBetween(
    parastart,
    $position.end(),
    '\0',
    '\0'
  );
  const { trigger } = opts;
  const regex = new RegExp(`(^|\\s)${trigger}\\s*(.*)`);

  const match = regex.exec(text);

  if (match) {
    match.index = match[0].startsWith(' ') ? match.index + 1 : match.index;
    match[0] = match[0].startsWith(' ')
      ? match[0].substring(1, match[0].length)
      : match[0];
    const from = $position.start() + match.index,
      to = from + match[0].length;
    if (from < $position.pos && to >= $position.pos) {
      return { range: { from, to }, query: match[1] || '', text: match[2] };
    }
  }
}

function getUniqueClaimReferences(editorView: EditorView): ReferenceOption[] {
  const uniqueLabels = new Set<string>();
  const menuOptions: ReferenceOption[] = [];

  editorView.state.doc.descendants((node) => {
    if (node.type.name === CLAIM_REFERENCE) {
      const normalizedText = node.attrs.text;
      if (!uniqueLabels.has(normalizedText)) {
        uniqueLabels.add(normalizedText);
        menuOptions.push({
          text: node.attrs.text,
          id: uuid(),
        });
      }
    }
  });
  return menuOptions;
}

export function selectReference(refToAdd: AddReference) {
  const { view, ref, from, to, isNewOption } = refToAdd;
  const menuOptions = getUniqueClaimReferences(view);

  const transformedArray = transformReferenceMenuOptions(menuOptions);
  const highestIndex = transformedArray.reduce(
    (max, item) => Math.max(max, item.value),
    0
  );

  const refTextClean = ref.replace(REGEX_REFERENCE_PATTERN, '').trim();
  const attrs = {
    text: isNewOption ? `${refTextClean} (${highestIndex + 1})` : ref,
  };

  const node = view.state.schema.nodes.claimReference.create(attrs);

  const tr = view.state.tr.replaceWith(from, to, node);

  view.dispatch(tr);
}

class ClaimReferenceTooltipView {
  _referenceElement: null | HTMLElement = null;
  _popUp: PopUpHandle | null = null;
  key: PluginKey;
  options?: Options;
  menuOptions: ReferenceOption[];

  constructor(editorView: EditorView, key: PluginKey, options?: Options) {
    this.key = key;
    this.options = options;
    this.menuOptions = [];
    this.update(editorView);
  }

  update(view: EditorView): void {
    const state = this.key.getState(view.state);
    const domFound = view.domAtPos(view.state.selection.$from.pos);
    const paraDOM = domFound.node as HTMLElement;

    const textDOM = paraDOM.querySelectorAll('.claim-reference');

    this.menuOptions = getUniqueClaimReferences(view);
    this.options?.setReferenceList?.(this.menuOptions);

    if (!textDOM) {
      return;
    }

    textDOM.forEach((el: Element) => {
      const referenceEl: HTMLElement | null = el as HTMLElement;
      const popUp = this._popUp;
      const viewPops = {
        editorState: state,
        editorView: view,
        menuOptions: this.menuOptions,
      };

      if (
        !referenceEl ||
        el.getAttribute('data-part-editable') === 'false' ||
        el.getAttribute('contenteditable')
      ) {
        popUp && popUp.close();
        this._referenceElement = null;
      } else {
        popUp && popUp.close();
        this._referenceElement = referenceEl;

        this._popUp = createPopUp(ClaimReferenceMenu, viewPops, {
          anchor: referenceEl,
          autoDismiss: false,
          onClose: this._onClose,
          position: atAnchorBottomLeft,
        });
      }
    });
  }

  destroy = (): void => {
    this._popUp && this._popUp.close();
    this._popUp = null;
  };

  _onOpen = (): void => {
    const cellEl = this._referenceElement;
    if (!cellEl) {
      return;
    }
  };

  _onClose = (): void => {
    this._popUp = null;
  };
}

export const ReferencePluginKey = new PluginKey('autosuggestions');

const referencePlugin = (options?: Options) => {
  const SPEC = {
    key: ReferencePluginKey,
    state: {
      init() {
        return getNewState();
      },
      apply(tr: Transaction) {
        const { selection } = tr;
        const pluginOptions: PluginOptions = {
          trigger: '#',
        };
        if (
          !(selection instanceof TextSelection) ||
          selection.from !== selection.to
        ) {
          return getNewState();
        }

        const $position = selection.$from;
        const match = getMatch($position, pluginOptions);

        const newState: PluginState = getNewState();

        if (match) {
          newState.active = true;
          newState.range = match.range;
          newState.text = match.text;
          newState.query = match.query;
        }

        return newState;
      },
    },
    props: {
      decorations(state: EditorState) {
        const { active, range } = ReferencePluginKey.getState(state);

        if (!active) {
          return DecorationSet.empty;
        }

        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: 'span',
            class: 'claim-reference',
          }),
        ]);
      },
    },
    view(editorView: EditorView) {
      return new ClaimReferenceTooltipView(editorView, this.key, options);
    },
  };

  return new Plugin(SPEC);
};

export default referencePlugin;
