import { Command, Transaction } from 'prosemirror-state';
import { Fragment, Node, Slice } from 'prosemirror-model';
import { ReplaceAroundStep } from 'prosemirror-transform';
import { findParentNode, findParentNodeOfType } from '../util';

export const claimPartIndent: Command = (state, dispatch) => {
  const claimPartOnCursor = findParentNodeOfType(state.schema.nodes.claimPart)(
    state.selection
  );
  const claimPartPos =
    claimPartOnCursor && state.doc.resolve(claimPartOnCursor.start);

  if (!claimPartOnCursor || !claimPartPos) {
    return false;
  }

  const currentClaimPart = claimPartOnCursor.node;
  const currentIndentLevel = currentClaimPart.attrs.indentLevel ?? 0;

  const claimPartType = state.schema.nodes.claimPart;
  const { $from, $to } = state.selection;
  const range = $from.blockRange(
    $to,
    (node) => node.childCount > 0 && node.firstChild!.type === claimPartType
  );
  if (!range) {
    return false;
  }
  const startIndex = range.startIndex;
  if (startIndex == 0) {
    return false;
  }
  const parent = range.parent,
    nodeBefore = parent.child(startIndex - 1);
  if (nodeBefore.type !== claimPartType) {
    return false;
  }
  const claimPartParent = findParentNode((node) => node.type === claimPartType)(
    { $from: state.doc.resolve(claimPartOnCursor.pos - 2) }
  );

  if (dispatch) {
    const indentLevel = currentIndentLevel + 1;
    const attrs =
      currentIndentLevel === 0
        ? currentClaimPart.attrs
        : claimPartParent?.node.attrs ?? currentClaimPart.attrs;
    const slice = new Slice(Fragment.from(claimPartType.create()), 1, 0);
    const before = range.start;
    const after = range.end;
    let tr = state.tr;
    tr = tr.step(
      new ReplaceAroundStep(before - 1, after, before, after, slice, 0, true)
    );
    const pos = tr.mapping.map(before);
    const $pos = tr.doc.resolve(pos);
    const node = $pos.node();

    tr.setNodeMarkup(pos, null, {
      ...currentClaimPart.attrs,
      numberingTemplate: attrs.numberingTemplate,
      numberingType: attrs.numberingType,
      indentLevel,
    });
    if (node && node.type.name === 'claimPart') {
      walkThroughClaimPart(node, $pos.before() + 2, tr, indentLevel);
    }
    dispatch(tr.scrollIntoView());
  }
  return true;
};

function walkThroughClaimPart(
  node: Node,
  pos: number,
  tr: Transaction,
  level = 0
) {
  let modified = false;
  node.descendants((childNode, childPos) => {
    if (childNode.type.name !== 'claimPart') {
      return;
    }

    const currentLevel = childNode.attrs.indentLevel ?? 0;
    if (currentLevel !== level) {
      tr.setNodeMarkup(childPos + pos, null, {
        ...childNode.attrs,
        indentLevel: level,
      });
      modified = true;
    }

    // walkThroughClaimPart(childNode, childPos + pos, tr, level + 1);
    return false;
  });

  return modified;
}
