import React from 'react';

import findActiveFontSize from '../util/findActiveFontSize';
import FontSizeCommand from '../commands/FontSizeCommand';
import {
  ComponentTypeToolbarItemProps,
  ToolbarItemSubGroup,
  UIToolbarItem,
} from '../config';
import CommandMenuButton from './CommandMenuButton';

export const FONT_PT_SIZES = [
  8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 90,
];

const FONT_PT_SIZE_COMMANDS = FONT_PT_SIZES.reduce<
  Record<string, UIToolbarItem>
>((memo, size) => {
  memo[` ${size} `] = { command: new FontSizeCommand(size) };
  return memo;
}, {});

const COMMAND_GROUPS: ToolbarItemSubGroup = {
  group: [
    { Default: { command: new FontSizeCommand(0), icon: '' } },
    FONT_PT_SIZE_COMMANDS,
  ],
};

const FontSizeCommandMenuButton: React.FC<ComponentTypeToolbarItemProps> = ({
  dispatch,
  editorState,
  editorView,
}) => {
  const fontSize = findActiveFontSize(editorState);
  return (
    <CommandMenuButton
      commandGroups={COMMAND_GROUPS}
      dispatch={dispatch}
      editorState={editorState}
      editorView={editorView}
      label={`${fontSize}`}
    />
  );
};

export default FontSizeCommandMenuButton;
