import { Node } from 'prosemirror-model';
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

interface ClaimTextModificationDetectorPluginState {
  dirtyClaimNodes: Node[];
  initialNodes: Record<string, Node>;
}

export const claimTextModificationDetectorPluginKey =
  new PluginKey<ClaimTextModificationDetectorPluginState>(
    'claim-text-modification-detector'
  );

const claimTextModificationDetector = () =>
  new Plugin<ClaimTextModificationDetectorPluginState>({
    key: claimTextModificationDetectorPluginKey,
    state: {
      init: (_, editorState: EditorState) => {
        const initialNodes: Record<string, Node> = {};

        editorState.doc.descendants((node) => {
          if (node.type.name === 'claim') {
            initialNodes[node.attrs.nodeId] = node;
          }
        });

        return {
          dirtyClaimNodes: [],
          initialNodes,
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(claimTextModificationDetectorPluginKey);
        if (meta) {
          return {
            dirtyClaimNodes: meta.dirtyClaimNodes,
            initialNodes: meta.initialNodes || value.initialNodes,
          };
        }

        return value;
      },
    },
    view: () => new ClaimTextModificationDetectorView(),
  });

class ClaimTextModificationDetectorView {
  update(editorView: EditorView, prevState: EditorState) {
    if (editorView.state.doc.eq(prevState.doc)) {
      return;
    }

    const pluginState = claimTextModificationDetectorPluginKey.getState(
      editorView.state
    );

    if (!pluginState?.initialNodes) {
      return;
    }

    const dirtyClaimNodes: Node[] = [];

    editorView.state.doc.descendants((node) => {
      if (node.type.name !== 'claim') {
        return;
      }

      const nodeIds = Object.keys(pluginState.initialNodes);

      if (!nodeIds.includes(node.attrs.nodeId)) {
        return;
      }

      const initialNode = pluginState.initialNodes[node.attrs.nodeId];

      if (initialNode.textContent !== node.textContent) {
        dirtyClaimNodes.push(node);
      }
    });

    editorView.dispatch(
      editorView.state.tr.setMeta(claimTextModificationDetectorPluginKey, {
        dirtyClaimNodes,
      })
    );
  }
}

export default claimTextModificationDetector;
