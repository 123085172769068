import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { InputRef } from 'antd/lib/input/Input';
import { Button, Input } from 'antd';
import { Popover } from 'react-tiny-popover';
import { useWindowKeyPressEventListener } from '../../../hooks';
import { FormField } from '../../Form';

const RequiredValueSchema = yup.object({
  value: yup.string().required('NO_EMPTY'),
});

interface InputInterface {
  value: string;
}

const ConfirmationInput: React.FC<{
  value: string;
  onChange?: (value: string) => void;
  onCancel?: () => void;
  onConfirm?: (value: string) => void;
  disabled?: boolean;
  loading?: boolean;
  required?: boolean;
  suggestions?: string[];
  suggestionsTitle?: string;
}> = ({
  loading,
  suggestions,
  suggestionsTitle,
  value,
  onCancel,
  onChange,
  onConfirm,
  disabled,
  required,
}) => {
  const inputRef = useRef<InputRef>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(suggestions != null);

  const methods = useForm<InputInterface>({
    defaultValues: {
      value,
    },
    resolver: required ? yupResolver(RequiredValueSchema) : undefined,
  });

  useLayoutEffect(() => {
    if (!suggestionsRef.current || !inputRef.current) {
      return;
    }

    suggestionsRef.current.style.width = `${inputRef.current.input?.clientWidth}px`;
  });

  useWindowKeyPressEventListener((e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      onCancel?.();
    }
  });

  const onSubmit = (data: InputInterface) => {
    onConfirm?.(data.value);
  };

  useEffect(() => {
    if (!onChange) {
      return;
    }
    const subscription = methods.watch(
      ({ value }) => value !== undefined && onChange?.(value)
    );
    return () => {
      if (!onChange) {
        return;
      }
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  const suggestionsContent = useMemo(() => {
    if (!suggestions?.length) {
      return null;
    }
    const handleSuggestionClick = (s: string) => {
      if (loading || disabled) {
        return;
      }
      methods.setValue('value', s);
      inputRef.current?.focus();
      setIsPopoverOpen(false);
    };
    return (
      <Suggestions ref={suggestionsRef} data-testid="suggestions-dropdown">
        {suggestionsTitle && (
          <SuggestionTitle>{suggestionsTitle}</SuggestionTitle>
        )}
        {suggestions?.map((s) => (
          <Suggestion key={s} onClick={() => handleSuggestionClick(s)}>
            {s}
          </Suggestion>
        ))}
      </Suggestions>
    );
  }, [suggestions, setIsPopoverOpen]);

  const onEnterPressed = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      setIsPopoverOpen(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Popover
        containerStyle={{ zIndex: '10000' }}
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={suggestionsContent as JSX.Element}
        align="start"
        positions={['bottom']}
      >
        <StyledForm
          onSubmit={methods.handleSubmit(onSubmit)}
          data-testid="claim-revision-confirmation-input"
        >
          <Outer
            data-testid="confirmation-input-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <FormField
              testId="confirmation-input-form-field"
              style={{ flexGrow: 1 }}
              onKeyDown={onEnterPressed}
              name="value"
              onFocus={() => setIsPopoverOpen(true)}
              component={Input}
              componentRef={inputRef}
              autoComplete="off"
              autoFocus
              disabled={disabled || loading}
            />
            {onConfirm && (
              <AcceptButton
                htmlType="submit"
                data-testid="confirmation-input-accept"
                loading={loading}
                type="primary"
                icon={<StyledIcon className="icn-checkmark" />}
              />
            )}
            {onCancel && (
              <CancelButton
                type="primary"
                data-testid="confirmation-input-cancel"
                disabled={loading}
                icon={<StyledIcon className="icn-clear" />}
                onClick={onCancel}
              />
            )}
          </Outer>
        </StyledForm>
      </Popover>
    </FormProvider>
  );
};

export default ConfirmationInput;

const Outer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledIcon = styled.i`
  font-size: 18px;
`;

const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-top: 5px;
  border-radius: 4px;
  min-width: 200px;
  max-width: 260px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.13);
  padding: 10px 0;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.gradient300},
    ${(props) => props.theme.colors.gradient100}
  );
`;

const SuggestionTitle = styled.div`
  padding: 5px 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary200_87};
`;

const Suggestion = styled.div`
  display: flex;
  flex: 0;
  padding: 5px 20px;
  color: ${(props) => props.theme.colors.white87};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_60};
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.white40};
  border-radius: 4px;
  margin-left: 5px;
  &:hover {
    border-color: transparent;
  }
`;

const AcceptButton = styled(StyledButton)`
  &:hover {
    background-color: ${(props) => props.theme.colors.secondary300};
  }
`;
const CancelButton = styled(StyledButton)`
  &:hover {
    background-color: ${(props) => props.theme.colors.error};
  }
`;
