import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Colors } from '../../../../../theme';
import useTranslation from '../../../../../translations';
import { truncate } from '../../../../../utils';
import { getDocumentName } from '../../../Toolbar/DocumentInfo';
import SearchResultsListItemHits from './SearchResultsListItemHits';

export interface SearchDocument {
  id: string;
  name: string;
  hitCount: number;
  hasOcr: boolean;
}

interface Props {
  item: SearchDocument;
  onOpenSearchInView: (documentId: string, index: number) => void;
}

const SearchResultsListItem: React.FC<Props> = ({
  item,
  onOpenSearchInView,
}) => {
  const t = useTranslation();
  const { id, name, hitCount, hasOcr } = item;

  const handleOpenSearchInView = (index: number) => {
    onOpenSearchInView(id, index);
  };

  return (
    <Outer $enableOpener={hitCount > 0}>
      <Name>{getDocumentName(name)}</Name>
      {hasOcr ? (
        <SearchResultsListItemHits
          hitCount={hitCount}
          onOpenSearchInView={handleOpenSearchInView}
        />
      ) : (
        <Tooltip
          title={t('MULTI_DOC_SEARCH.NO_TEXT_LAYER')}
          placement="topRight"
          color={Colors.gradient100}
          arrowPointAtCenter
          overlayInnerStyle={{ width: 150, color: Colors.white87 }}
          destroyTooltipOnHide
        >
          <InfoIcon className="icn-error-solid " />
        </Tooltip>
      )}
    </Outer>
  );
};

export default SearchResultsListItem;

const Outer = styled.div<{ $enableOpener: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 12px;
  gap: 25px;
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.white05};
    .search-result-item__actions {
      ${(props) => props.$enableOpener && 'visibility: visible;'}
    }
  }
`;

const Name = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  min-width: 0;
  color: ${(props) => props.theme.colors.white87};
  ${truncate()}
`;

const InfoIcon = styled.i`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white70};
`;
