import { Node } from 'prosemirror-model';
import { Api } from '../../../../..';
import { BlobResource } from '../../../../types';
import { dataURIToFile, fetchAndUploadFile, uploadBlobAsFile } from './utils';

const processImageUpload = async (
  docId: string,
  imgSrc: string | null,
  src: string,
  onUploadFile: (docId: string, file: File) => Promise<BlobResource>
): Promise<string | null> => {
  if (imgSrc) {
    const blob = await Api.document.getAsBlobByUri(`/${imgSrc}`);
    const imageUri = await uploadBlobAsFile(blob, docId, onUploadFile);
    return imageUri;
  } else {
    const fileFromHTML = dataURIToFile(src, encodeURIComponent('image'));
    const { uri } = await onUploadFile(docId, fileFromHTML);
    return uri;
  }
};

const processObjectUpload = async (
  objectSrc: string | null,
  docId: string,
  onUploadFile: (docId: string, file: File) => Promise<BlobResource>
): Promise<string | null> => {
  if (objectSrc) {
    return fetchAndUploadFile(objectSrc, docId, onUploadFile);
  } else {
    return null;
  }
};

interface ProcessedImage {
  imageUri: string | null;
  objectUri: string | null;
}

export const processImage = async (
  docId: string,
  node: Node,
  onUploadFile: (docId: string, file: File) => Promise<BlobResource>
): Promise<ProcessedImage> => {
  const [imageUri, objectUri] = await Promise.all([
    processImageUpload(docId, node.attrs.imgSrc, node.attrs.src, onUploadFile),
    processObjectUpload(node.attrs.objectSrc, docId, onUploadFile),
  ]);
  return { imageUri, objectUri };
};
