import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tree } from 'antd';
import { TsdNode } from '../../types';
import TsdNodeContentActions from './TsdNodeContentActions';

const { TreeNode } = Tree;

type Params = {
  tree: TsdNode[];
  search?: string;
  tsdNodeIds?: string[];
  onAddNode?: (tsdNodeId: string) => void;
  onRemoveNode?: (tsdNodeId: string) => void;
};

const filterNodes = (data: TsdNode[], search: string): TsdNode[] =>
  data
    .filter(
      (node) =>
        node.name.toLowerCase().includes(search.toLowerCase()) ||
        filterNodes(node.children, search).length > 0
    )
    .map((node) => ({
      ...node,
      children: filterNodes(node.children, search),
    }));

const renderNodes = ({
  tree,
  search,
  tsdNodeIds,
  onAddNode,
  onRemoveNode,
}: Params): React.ReactNode => {
  const filteredTree = search ? filterNodes(tree, search) : tree;

  return filteredTree.map(({ children, name, id }) => (
    <TreeNode
      data-testid={`tree-node-${id}`}
      key={id}
      title={
        <TreeNodeContent
          id={id}
          hasChildren={children.length > 0}
          className="tsd-tree__node"
        >
          {name}
          {tsdNodeIds && onAddNode && onRemoveNode && (
            <TsdNodeContentActions
              tsdNodeIds={tsdNodeIds}
              id={id}
              onAddNode={onAddNode}
              onRemoveNode={onRemoveNode}
            />
          )}
        </TreeNodeContent>
      }
    >
      {renderNodes({
        tree: children || [],
        search,
        tsdNodeIds,
        onAddNode,
        onRemoveNode,
      })}
    </TreeNode>
  ));
};

const useTsdTreeData = (params: Params): React.ReactNode => {
  const { tree, search, tsdNodeIds } = params;
  return useMemo(() => renderNodes(params), [tree, search, tsdNodeIds]);
};

export default useTsdTreeData;

const TreeNodeContent = styled.div<{ hasChildren: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.hasChildren ? 700 : 400)};
  color: ${(props) =>
    props.hasChildren
      ? props.theme.colors.white87
      : props.theme.colors.white50};
  &:hover {
    > button {
      opacity: 1;
    }
  }
`;
