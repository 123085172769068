import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { B2, Small } from '../../Atoms/Atoms';
import ContactEntry from './ContactEntry';
import { T } from '@ipos/shared';

interface Props {
  contact?: T.Contact;
}

const ContactCard: React.FC<Props> = ({ contact }) => {
  const t = useTranslation();

  return (
    <Outer>
      <ContactEntry title={contact?.displayName || t('N/A')} />
      <Property>
        <Label>{t('STAKEHOLDERS.PROPERTIES.PHONE')}</Label>
        <Title>{contact?.phone || t('N/A')}</Title>
      </Property>
      <Property>
        <Label>{t('STAKEHOLDERS.PROPERTIES.EMAIL')}</Label>
        <Title>{contact?.email || t('N/A')}</Title>
      </Property>
      <Property>
        <Label>{t('STAKEHOLDERS.PROPERTIES.COMPANY')}</Label>
        <Title>{contact?.company || t('N/A')}</Title>
      </Property>
      <Property>
        <Label>{t('STAKEHOLDERS.PROPERTIES.DEPARTMENT')}</Label>
        <Title>{contact?.department || t('N/A')}</Title>
      </Property>
      <Property>
        <Label>{t('STAKEHOLDERS.PROPERTIES.LOCATION')}</Label>
        <Title>{t('N/A')}</Title>
      </Property>
    </Outer>
  );
};

const Property = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  margin-top: 14px;
`;

const Label = styled(Small)`
  font-size: 13px;
  font-weight: normal;
  line-height: 11px;
  color: ${(props) => props.theme.colors.white40};
  margin-bottom: 5px;
`;
const Title = styled(B2)`
  font-size: 15px;
`;

const Outer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  width: 300px;
`;

export default ContactCard;
