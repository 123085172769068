import React, { useState } from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import {
  CreateCompetithorReport,
  CompetithorReportInputName,
  CompetithorReportParams,
  PatStatReports,
} from '../../../types';
import useTranslation from '../../../translations';
import CompetitorReportModal from '../CompetitorReportModal';
import CpcClassTag from './CpcClassTag';

interface Props {
  cpcCode: string;
  cpcDescription?: string;
  style?: React.CSSProperties;
}

const TechFieldItem: React.FC<Props> = ({ cpcCode, cpcDescription, style }) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  const competitorReportRequest: CreateCompetithorReport = {
    title: cpcCode,
    description: t('COMPETITOR.TECH_FIELD_ANALYSIS.DESCRIPTION_CPC'),
    inputs: [
      {
        name: CompetithorReportInputName.CpcClassCode,
        value: cpcCode,
      },
    ],
    reportParams: {
      [CompetithorReportParams.Cpc]: cpcCode,
    },
    patStatReportId: PatStatReports.CpcTechFieldAnalysis,
  };

  return (
    <Outer
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenRequest?.();
      }}
    >
      <Inner>
        <Space align="baseline">
          <CpcClassTag code={cpcCode} />
          <CpcDescription>{cpcDescription}</CpcDescription>
        </Space>
      </Inner>
      {isModalOpen && (
        <CompetitorReportModal
          onCloseRequest={(e) => {
            e.stopPropagation();
            handleCloseRequest?.();
          }}
          reportRequest={competitorReportRequest as CreateCompetithorReport}
        />
      )}
    </Outer>
  );
};

export default TechFieldItem;

const Outer = styled.div`
  display: flex;
  width: 90%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  padding: 10px 0;
`;

const CpcDescription = styled.div`
  display: flex;
`;
