import qs from 'querystring';
import { T } from '../..';
import Api, { handleBlobResponse } from './base';

const API_BASE = '/v1/tsds';

export const searchTsds: T.PaginatedApiFunction<T.Tsd, T.TsdQueryParams> = (
  params: T.TsdQueryParams
): Promise<T.Tsd[]> =>
  Api.post<T.Tsd[]>(`${API_BASE}/search`, { ...params }).then(
    (response) => response.data
  );

export const getTsdById = (
  tsdId: string,
  language: T.Language
): Promise<T.Tsd> =>
  Api.get<T.Tsd>(`${API_BASE}/${tsdId}/?${qs.stringify({ language })}`).then(
    (response) => response.data
  );

export const createTsd = (data: T.CreateTsdElement): Promise<T.Tsd> =>
  Api.post<T.Tsd>(`${API_BASE}`, data).then((response) => response.data);

export const updateTsd = (data: T.UpdateTsd): Promise<string> =>
  Api.put<string>(`${API_BASE}/${data.id}`, data).then(
    (response) => response.data
  );

export const deleteTsd = (tsdId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/${tsdId}`).then((response) => response.data);

export const exportTsd = (
  tsdId: string,
  language?: string
): Promise<[Blob, string]> =>
  Api.get<Blob>(`${API_BASE}/${tsdId}/export?${qs.stringify({ language })}`, {
    responseType: 'blob',
  }).then(handleBlobResponse);

export const exportAllTsds = (): Promise<[Blob, string]> =>
  Api.get<Blob>(`${API_BASE}/export-all`, {
    responseType: 'blob',
  }).then(handleBlobResponse);

export const searchTsdElements: T.PaginatedApiFunction<
  T.TsdElementBasicInfo,
  T.TsdElementQueryParams
> = (params: T.TsdElementQueryParams): Promise<T.TsdElementBasicInfo[]> =>
  Api.post<T.TsdElementBasicInfo[]>(`${API_BASE}/elements/search`, {
    ...params,
  }).then((response) => response.data);

export const getTsdElementCpcFrequencies = (): Promise<T.ListFilterItem[]> =>
  Api.get<T.ListFilterItem[]>(`${API_BASE}/elements/frequencies/classes`).then(
    (response) => response.data
  );

export const getTsdElementTreeFrequencies = (
  language: T.Language
): Promise<T.ListFilterItem[]> =>
  Api.get<T.ListFilterItem[]>(
    `${API_BASE}/elements/frequencies/trees?${qs.stringify({
      language,
    })}`
  ).then((response) => response.data);

export const createTsdElement = (
  data: T.CreateTsdElement
): Promise<T.TsdElement> =>
  Api.post<T.TsdElement>(`${API_BASE}/elements`, data).then(
    (response) => response.data
  );

export const getTsdElement = (
  tsdElementId: string,
  language: T.Language,
  tsdNodeId?: string
): Promise<T.TsdElement> =>
  Api.get<T.TsdElement>(
    `${API_BASE}/elements/${tsdElementId}?${qs.stringify({
      tsdNodeId,
      language,
    })}`
  ).then((response) => response.data);

export const deleteTsdElement = (tsdElementId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/elements/${tsdElementId}`).then(
    (response) => response.data
  );

export const createNode = (data: T.CreateTsdNode): Promise<T.TsdNode> =>
  Api.post<T.TsdNode>(`${API_BASE}/${data.tsdId}/nodes`, data).then(
    (response) => response.data
  );

export const deleteNode = (tsdId: string, tsdNodeId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/${tsdId}/nodes/${tsdNodeId}`).then(
    (response) => response.data
  );

export const moveNode = (data: T.MoveTsdNode): Promise<T.TsdNode> =>
  Api.post<T.TsdNode>(`${API_BASE}/${data.tsdId}/nodes/move`, data).then(
    (response) => response.data
  );

export const importNodes = (data: T.ImportTsdNode): Promise<void> =>
  Api.post<void>(
    `${API_BASE}/${data.destinationTsdId}/nodes/import`,
    data
  ).then((response) => response.data);

export const getTsdNodeById = (
  tsdId: string,
  tsdNodeId: string,
  language: T.Language
): Promise<T.TsdNode> =>
  Api.get<T.TsdNode>(
    `${API_BASE}/${tsdId}/nodes/${tsdNodeId}?${qs.stringify({
      language,
    })}`
  ).then((response) => response.data);

export const searchTsdNodes: T.PaginatedApiFunction<
  T.TsdNodeBasicInfo,
  T.TsdNodeSearchParams
> = (params: T.TsdNodeSearchParams): Promise<T.TsdNodeBasicInfo[]> =>
  Api.post<T.TsdNodeBasicInfo[]>(`${API_BASE}/nodes/`, params).then(
    (response) => response.data
  );

export const uploadTsdElementGraphics = (
  files: File[],
  tsdElementId: string,
  tsdNode?: string
): Promise<T.PatentUpload[]> => {
  const formData = new FormData();

  files.forEach((file: File) => {
    formData.append('files', file, file.name);
  });

  formData.append('tsdElementId', tsdElementId);

  if (tsdNode) {
    formData.append('tsdNodeId', tsdNode);
  }

  return Api.post<T.PatentUpload[]>(
    `${API_BASE}/uploads/elements/${tsdElementId}/graphics`,
    formData
  ).then((response) => response.data);
};

export const deleteTsdElementGraphics = (
  tsdElementId: string,
  tsdElementUploadId: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/uploads/elements/${tsdElementId}/graphics/${tsdElementUploadId}`
  ).then((response) => response.data);

export const getTsdElementIpcClasses = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementIpcClass[]> =>
  Api.get<T.TsdElementIpcClass[]>(
    `${API_BASE}/elements/${tsdElementId}/ipc-classes?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const addTsdElementIpcClasses = (
  tsdElementId: string,
  ipcClassIds: string[],
  tsdNodeId?: string
): Promise<T.TsdElementIpcClass[]> =>
  Api.post<T.TsdElementIpcClass[]>(
    `${API_BASE}/elements/${tsdElementId}/ipc-classes`,
    { tsdElementId, ipcClassIds, tsdNodeId }
  ).then((response) => response.data);

export const deleteTsdElementClass = (
  tsdElementId: string,
  tsdElementClassId: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/elements/${tsdElementId}/ipc-classes/${tsdElementClassId}`
  ).then((response) => response.data);

export const getTsdElementTextModuleTypes = (): Promise<
  T.TsdElementTextModuleType[]
> =>
  Api.get<T.TsdElementTextModuleType[]>(
    `${API_BASE}/elements/text-module-types`
  ).then((response) => response.data);

export const getTsdElementTextModules = (
  tsdElementId: string,
  tsdNodeId?: string,
  language?: T.Language
): Promise<T.TsdElementTextModule[]> =>
  Api.get<T.TsdElementTextModule[]>(
    `${API_BASE}/elements/${tsdElementId}/text-modules?${qs.stringify({
      tsdNodeId,
      language,
    })}`
  ).then((response) => response.data);

export const addTsdElementTextModule = (
  data: T.CreateTsdElementTextModule
): Promise<T.TsdElementTextModule> =>
  Api.post<T.TsdElementTextModule>(
    `${API_BASE}/elements/${data.tsdElementId}/text-modules`,
    data
  ).then((response) => response.data);

export const updateTsdElementTextModule = (
  data: T.UpdateTsdElementTextModule
): Promise<T.TsdElementTextModule> =>
  Api.put<T.TsdElementTextModule>(
    `${API_BASE}/elements/${data.tsdElementId}/text-modules/${data.textModuleId}`,
    data
  ).then((response) => response.data);

export const deleteTsdElementTextModule = (
  tsdElementId: string,
  textModuleId: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/elements/${tsdElementId}/text-modules/${textModuleId}`
  ).then((response) => response.data);

export const createTsdElementCompanyGroup = (
  data: T.CreateTsdElementCompanyGroup
): Promise<T.TsdElementCompanyGroup> =>
  Api.post<T.TsdElementCompanyGroup>(
    `${API_BASE}/elements/${data.tsdElementId}/company-groups`,
    data
  ).then((response) => response.data);

export const getTsdElementCompanyGroups = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementCompanyGroup[]> =>
  Api.get<T.TsdElementCompanyGroup[]>(
    `${API_BASE}/elements/${tsdElementId}/company-groups?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const deleteTsdElementCompanyGroup = (
  tsdElementId: string,
  tsdElementCompanyGroupId: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/elements/${tsdElementId}/company-groups/${tsdElementCompanyGroupId}`
  ).then((response) => response.data);

export const getTsdCount = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/count`).then(
    (response) => response.data[0]
  );

export const getTsdElementCount = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/elements-count`).then(
    (response) => response.data[0]
  );

export const getTsdUniqueCreatorsCount = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/unique-creators-count`).then(
    (response) => response.data[0]
  );

export const getTsdElementCompanyCoverage = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/element-company-coverage`).then(
    (response) => response.data[0]
  );

export const getTsdElementCpcCoverage = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/element-cpc-coverage`).then(
    (response) => response.data[0]
  );

export const getTsdElementImageCoverage = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/element-image-coverage`).then(
    (response) => response.data[0]
  );

export const getTsdElementQueryCoverage = (): Promise<T.DataPoint> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/element-query-coverage`).then(
    (response) => response.data[0]
  );

export const getTsdLargestTrees = (
  language?: T.Language
): Promise<T.DataPoint[]> =>
  Api.get<T.DataPoint[]>(
    `${API_BASE}/stats/largest-trees?${qs.stringify({ language })}`
  ).then((response) => response.data);

export const getMostUsedCpcClasses = (): Promise<T.DataPoint[]> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/most-used-cpc-classes`).then(
    (response) => response.data
  );

export const getMostUsedCompanyGroups = (): Promise<T.DataPoint[]> =>
  Api.get<T.DataPoint[]>(`${API_BASE}/stats/most-used-company-groups`).then(
    (response) => response.data
  );

export const getMostUsedTsdElements = (
  language?: T.Language
): Promise<T.DataPoint[]> =>
  Api.get<T.DataPoint[]>(
    `${API_BASE}/stats/most-used-tsd-elements?${qs.stringify({ language })}`
  ).then((response) => response.data);

export const getTsdElementQueries = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementQuery[]> =>
  Api.get<T.TsdElementQuery[]>(
    `${API_BASE}/elements/${tsdElementId}/queries?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const getTsdElementQuery = (
  tsdElementId: string,
  tsdQueryId: string
): Promise<T.TsdElementQuery> =>
  Api.get<T.TsdElementQuery>(
    `${API_BASE}/elements/${tsdElementId}/queries/${tsdQueryId}`
  ).then((response) => response.data);

export const createTsdElementQuery = (
  data: T.CreateTsdElementQuery
): Promise<T.TsdElementQuery> =>
  Api.post<T.TsdElementQuery>(
    `${API_BASE}/elements/${data.tsdElementId}/queries`,
    data
  ).then((response) => response.data);

export const updateTsdElementQuery = (
  data: T.TsdElementQuery
): Promise<T.TsdElementQuery> =>
  Api.put<T.TsdElementQuery>(
    `${API_BASE}/elements/${data.tsdElementId}/queries/${data.id}`,
    data
  ).then((response) => response.data);

export const deleteTsdElementQuery = (
  tsdElementId: string,
  tsdQueryId: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/elements/${tsdElementId}/queries/${tsdQueryId}`
  ).then((response) => response.data);

export const getTsdElementUploads = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementUpload[]> =>
  Api.get<T.TsdElementUpload[]>(
    `${API_BASE}/uploads/elements/${tsdElementId}?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const getTsdElementUsage = (
  tsdElementId: string,
  language?: T.Language
): Promise<T.TsdElementUsage[]> =>
  Api.get<T.TsdElementUsage[]>(
    `${API_BASE}/elements/${tsdElementId}/usage?${qs.stringify({
      language,
    })}`
  ).then((response) => response.data);

export const addTsdElementSynonym = (
  data: T.CreateTsdElementSynonym
): Promise<T.TsdElementSynonym> =>
  Api.post<T.TsdElementSynonym>(
    `${API_BASE}/elements/${data.tsdElementId}/synonyms`,
    data
  ).then((response) => response.data);

export const getTsdElementSynonynms = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementSynonym[]> =>
  Api.get<T.TsdElementSynonym[]>(
    `${API_BASE}/elements/${tsdElementId}/synonyms?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const deleteTsdElementSynonym = (
  tsdElementId: string,
  id: string
): Promise<void> =>
  Api.delete<void>(`${API_BASE}/elements/${tsdElementId}/synonyms/${id}`).then(
    (response) => response.data
  );

export const updateTsdElementSynonym = (
  data: T.UpdateTsdElementSynonym
): Promise<T.UpdateTsdElementSynonym> =>
  Api.put<T.TsdElementSynonym>(
    `${API_BASE}/elements/${data.tsdElementId}/synonyms/${data.id}`,
    data
  ).then((response) => response.data);

export const addTsdElementDescription = (
  data: T.CreateTsdElementDescription
): Promise<T.TsdElementDescription> =>
  Api.post<T.TsdElementDescription>(
    `${API_BASE}/elements/${data.tsdElementId}/descriptions`,
    data
  ).then((response) => response.data);

export const getTsdElementDescriptions = (
  tsdElementId: string,
  tsdNodeId?: string
): Promise<T.TsdElementDescription[]> =>
  Api.get<T.TsdElementDescription[]>(
    `${API_BASE}/elements/${tsdElementId}/descriptions?${qs.stringify({
      tsdNodeId,
    })}`
  ).then((response) => response.data);

export const updateTsdElementDescription = (
  data: T.UpdateTsdElementDescription
): Promise<T.TsdElementDescription> =>
  Api.put<T.TsdElementDescription>(
    `${API_BASE}/elements/${data.tsdElementId}/descriptions/${data.id}`,
    data
  ).then((response) => response.data);

export const deleteTsdElementDescription = (
  tsdElementId: string,
  id: string
): Promise<void> =>
  Api.delete<void>(
    `${API_BASE}/elements/${tsdElementId}/descriptions/${id}`
  ).then((response) => response.data);

export const getTsdNodeSummary = (
  tsdId: string,
  tsdNodeId: string,
  language?: T.Language
): Promise<T.TsdNodeSummary> =>
  Api.get<T.TsdNodeSummary>(
    `${API_BASE}/${tsdId}/nodes/${tsdNodeId}/summary?${qs.stringify({
      language,
    })}`
  ).then((response) => response.data);

export const getTsdNodeSummaryById = (
  tsdNodeId: string,
  language?: T.Language
): Promise<T.TsdNodeSummary> =>
  Api.get<T.TsdNodeSummary>(
    `${API_BASE}/nodes/${tsdNodeId}/summary?${qs.stringify({
      language,
    })}`
  ).then((response) => response.data);

export const getPriorArt = (
  params: T.PriorArtSearchParams
): Promise<T.PriorArtSearchResult> => {
  const { tsdId, tsdNodeId } = params;
  return Api.post<T.PriorArtSearchResult>(
    `${API_BASE}/${tsdId}/nodes/${tsdNodeId}/prior-art`,
    params
  ).then((response) => response.data);
};

export const getSynonymSuggestions = (
  params: T.SynonymSuggestionsSearchParams
): Promise<string[]> => {
  const { sortBy, ...otherParams } = params;

  return Api.get<string[]>(
    `${API_BASE}/suggestions?${qs.stringify({
      ...otherParams,
      // it does not support multi sorting
      sortBy: typeof sortBy === 'string' ? sortBy : '',
    })}`
  ).then((response) => response.data);
};

export const getFavoriteTechnologies: T.PaginatedApiFunction<
  T.TsdNodeSummary,
  T.TsdFavoriteTechParams
> = (params: T.TsdFavoriteTechParams): Promise<T.TsdNodeSummary[]> =>
  Api.post<T.TsdNodeSummary[]>(`${API_BASE}/favorite-technologies/search`, {
    ...params,
  }).then((response) => response.data);

export const createFavoriteTechnology = (tsdNodeId: string): Promise<string> =>
  Api.post<string>(`${API_BASE}/favorite-technologies`, { tsdNodeId }).then(
    (response) => response.data
  );

export const deleteFavoriteTechnology = (technologyId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/favorite-technologies/${technologyId}`).then(
    (response) => response.data
  );
