import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import QuerySetup from './Tabs/QuerySetup';
import QueryTest from './Tabs/QueryTest';
import { useQueryContext } from './QueryContext';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';

const { Icon } = Components;
const { TabPane } = Tabs;

const TABS = {
  SETUP: 'SETUP',
  TEST: 'TEST',
};

interface Props {
  onDone: () => void;
  onChange: (hasUnsavedChanges: boolean) => void;
}

const QueryBuilder: React.FC<Props> = ({ onDone, onChange }) => {
  const t = useTranslation();
  const { query, hasUnsavedChanges } = useQueryContext();
  const [activeTab, setActiveTab] = useState<string>(
    query?.isInExpertMode ? TABS.TEST : TABS.SETUP
  );

  useEffect(() => {
    onChange(hasUnsavedChanges);
  }, [hasUnsavedChanges]);

  return (
    <Outer>
      <Tabs
        tabPosition="left"
        activeKey={activeTab}
        onChange={(v: string) => setActiveTab(v)}
        destroyInactiveTabPane
        type="card"
      >
        <TabPane
          tab={
            <Flex>
              <Icon className="icn-settings" /> {t('QUERY.QUICK_SETUP')}
            </Flex>
          }
          key={TABS.SETUP}
          disabled={query?.isInExpertMode}
        >
          <QuerySetup onDone={onDone} onTest={() => setActiveTab(TABS.TEST)} />
        </TabPane>
        <TabPane
          tab={
            <Flex>
              <Icon className="icn-play-solid" /> {t('QUERY.TEST_QUERIES')}
            </Flex>
          }
          disabled={hasUnsavedChanges}
          key={TABS.TEST}
        >
          <QueryTest onDone={onDone} onBack={() => setActiveTab(TABS.SETUP)} />
        </TabPane>
      </Tabs>
    </Outer>
  );
};

export default QueryBuilder;

const Outer = styled.div`
  height: 700px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .ant-tabs {
    height: 100%;
    background: ${(props) => props.theme.colors.primary800};
    .ant-tabs-nav-list {
      width: 240px;
    }
    .ant-tabs-nav-wrap {
      background: #1f1f1f;
    }
    .ant-tabs-tabpane {
      padding: 20px;
      height: 100%;
    }
    .ant-tabs-content {
      height: 100%;
    }
    .ant-tabs-tab {
      padding: 15px;
      font-size: 17px;
      border-top: none;
      border-left: none;
      border-right: none;
      margin-bottom: 0 !important;
      background-color: transparent;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      background: ${(props) => props.theme.colors.primary800};
      border-right-color: transparent;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
