import React, { lazy, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { Login, Signup, VerifySignup } from '../Login';
import PageLoad from '../PageLoad';
import axiosInstance from '../../api/base';
import PrivateRoute from '../../auth/PrivateRoute';
import PublicRoute from '../../auth/PublicRoute';
import { showUnauthorisedNotification } from '../Session';
import { useUserSessionQueryContext, SessionProvider } from '../../contexts';
import { useApplicationContext } from '../../contexts/ApplicationProvider';
import SHARED_ROUTES, { checkIsPublicPath } from '../../utils/routing';
import useTranslation from '../../translations';
import PageError from '../PageError';
const MultiDocViewer = lazy(() => import('../MultiDocViewer'));
const AISupport = lazy(() => import('../AISupport'));
const AiTemplates = lazy(() => import('../AiTemplates'));

const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const t = useTranslation();
  const { appName } = useApplicationContext();
  const { isLoading, isBackendUnreachable, session } =
    useUserSessionQueryContext();

  useEffect(() => {
    if (!session) {
      return;
    }
    const interceptor = axiosInstance.interceptors.response.use(
      undefined,
      (error) => {
        if (error?.response?.status === 401) {
          const isPublicPath = checkIsPublicPath();
          if (!isPublicPath) {
            showUnauthorisedNotification();
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [session]);

  if (isBackendUnreachable) {
    return (
      <Outer>
        <Inner>
          <PageError />
        </Inner>
      </Outer>
    );
  }

  if (isLoading) {
    return (
      <Outer>
        <Inner>
          <PageLoad message={t('LOADING_APP', { appName })} />
        </Inner>
      </Outer>
    );
  }

  return (
    <Outer>
      <Switch>
        <Route path={SHARED_ROUTES.LOGIN.path}>
          <PublicRoute>
            <Login />
          </PublicRoute>
        </Route>
        <Route path={SHARED_ROUTES.SIGNUP.path}>
          <PublicRoute>
            <Signup />
          </PublicRoute>
        </Route>
        <Route path={SHARED_ROUTES.SIGNUP_VERIFICATION.path}>
          <PublicRoute>
            <VerifySignup />
          </PublicRoute>
        </Route>
        <Route path={SHARED_ROUTES.ROOT.path}>
          <PrivateRoute>
            <SessionProvider>
              <Switch>
                <Route exact path={SHARED_ROUTES.DOC_VIEWER.path}>
                  <MultiDocViewer />
                </Route>
                <Route exact path={SHARED_ROUTES.AI_SUPPORT.path}>
                  <AISupport />
                </Route>
                <Route exact path={SHARED_ROUTES.AI_TEMPLATES.path}>
                  <AiTemplates />
                </Route>
                <Route>{children}</Route>
              </Switch>
            </SessionProvider>
          </PrivateRoute>
        </Route>
      </Switch>
    </Outer>
  );
};

export default Wrapper;

const Outer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.gradient100} 0%,
    ${(props) => props.theme.colors.gradient200} 104%
  );
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
