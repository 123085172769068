import { EditorState, TextSelection, Transaction } from 'prosemirror-state';

import { SyntheticEvent } from 'react';
import insertTable from '../util/insertTable';
import { TableGridSizeEditorValue } from '../renderers/TableGridSizeEditor';
import UICommand from './UICommand';

class TableInsertCommand extends UICommand {
  _popUp = null;

  shouldRespondToUIEvent = (e: SyntheticEvent | MouseEvent): boolean =>
    e.type === UICommand.EventType.MOUSEENTER;

  isEnabled = (state: EditorState): boolean => {
    const tr = state;
    const { selection } = tr;
    if (selection instanceof TextSelection) {
      return selection.from === selection.to;
    }
    return false;
  };

  executeWithUserInput = (
    state: EditorState,
    inputs: TableGridSizeEditorValue,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    if (dispatch) {
      const { selection, schema } = state;
      let { tr } = state;
      if (inputs) {
        const { rows, cols } = inputs;
        tr = tr.setSelection(selection);
        tr = insertTable(tr, schema, rows, cols);
      }
      dispatch(tr);
    }
    return false;
  };
}

export default TableInsertCommand;
