import { useRef } from 'react';

/**
 * Provides stable previous value (even when parent re-renders regardless of value change)
 * @param {T} value Value to be returned as previous
 * @param {T} shouldSetInitial Explicitly set the same initial previous value
 * @returns {T} Previous value
 */
function usePreviousStable<T>(
  value?: T,
  shouldSetInitial = false
): T | undefined {
  const ref = useRef<{ target?: T; previous?: T }>({
    target: value,
    previous: shouldSetInitial ? value : undefined,
  });
  if (ref.current.target !== value) {
    ref.current.previous = ref.current.target;
    ref.current.target = value;
  }
  return ref.current.previous;
}

export default usePreviousStable;
