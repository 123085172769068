import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
const cacheLocation = 'localStorage' as BrowserCacheLocation;

// @ts-ignore
const tenantId = window.AZURE_TENANT_ID || process.env.NX_PUBLIC_TENANT_ID; // AZURE_TENANT_ID (deployed); NX_PUBLIC_TENANT_ID (local)
// @ts-ignore
const clientId = window.AZURE_CLIENT_ID || process.env.NX_PUBLIC_CLIENT_ID; // AZURE_CLIENT_ID (deployed); NX_PUBLIC_CLIENT_ID (local)

// Msal Configurations
const config: Configuration = {
  auth: {
    navigateToLoginRequestUrl: true,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId: clientId as string,
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation,
    storeAuthStateInCookie: true,
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
    },
  },
};

// Authentication Parameters
export const authRequest = {
  redirectUri: `${window.location.origin}/login`,
  scopes: [`${clientId}/user_impersonation`],
};

export function clearAuthData(): void {
  const targetStorage =
    cacheLocation === BrowserCacheLocation.LocalStorage
      ? localStorage
      : sessionStorage;
  Object.keys(targetStorage)
    .filter((x) => x.indexOf('windows.net') > -1)
    .forEach((x) => targetStorage.removeItem(x));
}

const msalInstance = new PublicClientApplication(config);

export { msalInstance };
