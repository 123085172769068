import React, { useEffect, useCallback, useState } from 'react';
import { Radio, Space, Popover } from 'antd';
import styled from 'styled-components';
import TsdElementAttributeListItem from '../TsdElementAttributeListItem';
import TestQueryStringButton from './TestQueryStringButton';
import QueryPreview from './QueryPreview';
import { Components, T, Colors } from '@ipos/shared';
import useTranslation from 'translations';

const { CopyToClipboard, Icon } = Components;

interface Props {
  query: T.TsdElementQuery;
  onRemove?: (id: string) => void;
}

enum OPTIONS {
  PREVIEW = 'PREVIEW',
  GOOGLE = 'GOOGLE',
  PATBASE = 'PATBASE',
}

const QueriesListItem: React.FC<Props> = ({ query, onRemove }) => {
  const t = useTranslation();
  const { isInExpertMode, googlePatentsQuery, patBaseQuery } = query;

  const getActiveView = useCallback(() => {
    if (!isInExpertMode) {
      return OPTIONS.PREVIEW;
    }
    if (googlePatentsQuery) {
      return OPTIONS.GOOGLE;
    }
    if (patBaseQuery) {
      return OPTIONS.PATBASE;
    }
    return null;
  }, [isInExpertMode, googlePatentsQuery, patBaseQuery]);

  const [activeView, setActiveView] = useState<OPTIONS | null>(getActiveView);

  useEffect(() => {
    const updatedActiveView = getActiveView();
    setActiveView(updatedActiveView);
  }, [getActiveView]);

  let actionsContent;
  let queryContent;

  switch (activeView) {
    case OPTIONS.PREVIEW:
      queryContent = <QueryPreview query={query} />;
      break;
    case OPTIONS.GOOGLE:
      queryContent = <String>{googlePatentsQuery}</String>;
      actionsContent = (
        <Space>
          <CopyToClipboard text={googlePatentsQuery} />
          <TestQueryStringButton
            queryString={`https://patents.google.com?${googlePatentsQuery}`}
          />
        </Space>
      );
      break;
    case OPTIONS.PATBASE:
      queryContent = <String>{patBaseQuery}</String>;
      actionsContent = <CopyToClipboard text={patBaseQuery} />;
      break;
  }

  return (
    <TsdElementAttributeListItem
      isBaseAttribute={query.isBaseAttribute}
      confirmRemovalTitle={t('QUERY.CONFIRM_DELETE')}
      onRemoveRequest={onRemove && (() => onRemove(query.id))}
    >
      <Outer>
        <Inner>
          <RadioGroup>
            <Radio.Group
              onChange={(e) => setActiveView(e.target.value)}
              value={activeView}
            >
              <Radio value={OPTIONS.PREVIEW} disabled={isInExpertMode}>
                <span
                  title={
                    isInExpertMode
                      ? t('QUERY.PREVIEW_NOT_AVAILABLE')
                      : undefined
                  }
                >
                  {t('QUERY.PREVIEW')}
                </span>
              </Radio>
              <Radio value={OPTIONS.GOOGLE} disabled={!googlePatentsQuery}>
                {t('QUERY.GOOGLE_PATENTS')}
              </Radio>
              <Radio value={OPTIONS.PATBASE} disabled={!patBaseQuery}>
                {t('QUERY.PATBASE')}
              </Radio>
            </Radio.Group>
            {isInExpertMode && (
              <Popover content={t('QUERY.MODE_EXPERT_NOTIFICATION')}>
                <Icon
                  className="icn-info-outlined"
                  size={24}
                  color={Colors.warning}
                  style={{ cursor: 'pointer' }}
                />
              </Popover>
            )}
          </RadioGroup>
          {queryContent}
        </Inner>
        {actionsContent}
      </Outer>
    </TsdElementAttributeListItem>
  );
};

export default QueriesListItem;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 10px 0;
`;

const RadioGroup = styled.div`
  display: flex;
  margin-bottom: 10px;
  .ant-radio-inner {
    background-color: #262932;
  }
`;

const Inner = styled.div`
  margin-right: 40px;
`;

const String = styled.div`
  word-break: break-all;
  padding: 10px 0;
  min-height: 60px;
  margin-bottom: 10px;
`;
