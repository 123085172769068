import React, { useCallback, useRef, useState } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';
import styled from 'styled-components';

interface Props {
  testId?: string;
  content: React.ReactNode;
}

const Popover: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  children,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onClick = useCallback(() => {
    setIsPopoverOpen((prev) => !prev);
  }, [setIsPopoverOpen]);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const onMenuClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // close menu on clicking item
    if (e.target !== menuRef?.current) {
      setIsPopoverOpen(false);
    }
  };

  return (
    <div
      onClick={(e) => {
        // https://jwwnz.medium.com/react-portals-and-event-bubbling-8df3e35ca3f1
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <TinyPopover
        isOpen={isPopoverOpen}
        positions={['right']}
        align={'end'}
        containerStyle={{ zIndex: '100000' }}
        reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
        onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
        content={
          <Outer ref={menuRef} onClick={onMenuClick}>
            {content}
          </Outer>
        }
      >
        <div onClick={onClick}>{children}</div>
      </TinyPopover>
    </div>
  );
};

const Outer = styled.div`
  display: flex;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.13);
  background-image: linear-gradient(
    140deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end} 100%
  );
`;

export default Popover;
