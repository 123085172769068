import React from 'react';
import styled from 'styled-components';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import ToolbarButton from '../toolbar/ToolbarButton';
import { COMMAND_GROUPS, CommandGroup } from '../toolbar/config';
import '../toolbar/styles.less';
import ListToggleCommand from './toolbar/ListToggleCommand';
import SearchReplaceControls from './SearchReplaceControls';
import CustomListToggleCommand from './toolbar/CustomListToggleCommand';

interface Props {
  editorView: EditorView | undefined;
  editorState: EditorState | undefined;
  disabledCommands?: string[];
  enableSearch?: boolean;
}

const Toolbar: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  editorView,
  editorState,
  disabledCommands,
  enableSearch = false,
}) => {
  if (!editorView || !editorState) {
    return null;
  }

  const defaultDisabledCommands = [
    'Text Size',
    'Font Type',
    'Headings...',
    'undo',
    'redo',
  ];

  const ClaimsCommandGroups: CommandGroup[] = [];

  COMMAND_GROUPS.forEach((commandGroup) => {
    const copiedCommandGroup = { ...commandGroup };

    Object.keys(commandGroup).forEach((label) => {
      if ((disabledCommands || defaultDisabledCommands).includes(label)) {
        delete copiedCommandGroup[label];
      }
    });

    ClaimsCommandGroups.push(copiedCommandGroup);
  });

  const indexForListCommands = ClaimsCommandGroups.findIndex(
    (cg) => Object.keys(cg)[0] === 'UL'
  );

  ClaimsCommandGroups[indexForListCommands] = {
    UL: {
      icon: 'icn-bullet-list',
      group: [
        {
          bullet: { command: new ListToggleCommand('bullet') },
          circle: { command: new ListToggleCommand('circle') },
          square: { command: new ListToggleCommand('square') },
          diamond: { command: new ListToggleCommand('diamond') },
        },
      ],
    },
    OL: {
      icon: 'icn-numbered-list',
      group: [
        {
          decimal: { command: new ListToggleCommand('decimal') },
          'lower-alpha': { command: new ListToggleCommand('lower-alpha') },
          'lower-roman': { command: new ListToggleCommand('lower-roman') },
          'upper-alpha': { command: new ListToggleCommand('upper-alpha') },
          'upper-roman': { command: new ListToggleCommand('upper-roman') },
        },
      ],
    },
    CUSTOM: {
      command: new CustomListToggleCommand(),
      icon: 'icn-custom-numbered-list',
    },
  };
  return (
    <Outer>
      {ClaimsCommandGroups.map((group, index) => (
        <ToolbarButton
          key={index}
          group={group}
          index={index}
          editorView={editorView}
          editorState={editorState}
        />
      ))}
      {children}
      {enableSearch && <SearchReplaceControls editorView={editorView} />}
    </Outer>
  );
};

const Outer = styled.div`
  padding: 8px;
  z-index: 10000;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  background: ${({ theme }) => theme.colors.primary400};
  flex-wrap: wrap;
`;

export default Toolbar;
