import styled from 'styled-components';

// IMPORTANT: Atoms are not up to date with styleguide and should not be used in new components
// TODO: Remove or replace Atoms when new font styleguide is defined

const H1 = styled.h1`
  color: ${(props) => props.theme.fonts.colors.h1};
  font-size: ${(props) => props.theme.fonts.sizes.h1}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.h1}px;
  font-weight: 300;
  margin: 0;
`;
const H2 = styled.h2`
  color: ${(props) => props.theme.fonts.colors.h2};
  font-size: ${(props) => props.theme.fonts.sizes.h2}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.h2}px;
  margin: 0;
`;
const H3 = styled.h3`
  color: ${(props) => props.theme.fonts.colors.h3};
  font-size: ${(props) => props.theme.fonts.sizes.h3}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.h3}px;
  margin: 0;
`;
const Title = styled.span`
  color: ${(props) => props.theme.fonts.colors.title};
  font-size: ${(props) => props.theme.fonts.sizes.title}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.title}px;
  font-weight: bold;
`;
const B1 = styled.span`
  color: ${(props) => props.theme.fonts.colors.b1};
  font-size: ${(props) => props.theme.fonts.sizes.b1}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.h1}px;
`;
const B2 = styled.span`
  color: ${(props) => props.theme.fonts.colors.b2};
  font-size: ${(props) => props.theme.fonts.sizes.b2}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.b2}px;
`;
const Normal = styled.span`
  color: ${(props) => props.theme.fonts.colors.normal};
  font-size: ${(props) => props.theme.fonts.sizes.normal}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.normal}px;
  font-weight: 300;
`;
const Small = styled.span`
  color: ${(props) => props.theme.fonts.colors.small};
  font-size: ${(props) => props.theme.fonts.sizes.small}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.small}px;
  font-weight: bold;
`;
const XSmall = styled.span`
  color: ${(props) => props.theme.fonts.colors.xsmall};
  font-size: ${(props) => props.theme.fonts.sizes.xsmall}px;
  line-height: ${(props) => props.theme.fonts.lineHeights.xsmall}px;
  font-weight: bold;
`;

export { H1, H2, H3, Normal, Small, XSmall, B1, B2, Title };
