/** Determine if element is visible within specific ancestor
 * Does not check if element is also visible in the browser viewport */
export const isVisibleWithinAncestor = (
  container: HTMLElement,
  element: HTMLElement,
  isFullyVisible = false
): boolean => {
  const { bottom, height, top } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  if (isFullyVisible) {
    return top >= containerRect.top && bottom <= containerRect.bottom;
  }

  return top <= containerRect.top
    ? containerRect.top - top <= height
    : bottom - containerRect.bottom <= height;
};

export const initBrowserDownload = (fileName: string, blob: Blob): void => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
};

export const isElementChildOf = (
  parent: HTMLElement,
  target: HTMLElement
): boolean => {
  let iterator = target as HTMLElement | null;
  while (iterator !== null) {
    if (parent === iterator) {
      return true;
    }
    iterator = iterator.parentElement;
  }
  return false;
};

export const getFileNameFromContentDispositionHeader = (
  header: string | null | undefined
): string => {
  if (!header) {
    return 'unknown';
  }
  if (header && header.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(header);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return 'unknown';
};
