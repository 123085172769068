import React, { useState } from 'react';
import { Permissions } from '@ipos/shared';
import { DeleteTsdNodeModal } from 'components/TsdTree';
import { MenuBarOption } from 'components/common';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  tsdId: string;
  tsdNodeId: string;
  disabled?: boolean;
}

const DeleteTsdNodeMenuOption: React.FC<Props> = ({
  disabled,
  tsdId,
  tsdNodeId,
}) => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOptionClick = () => setModalOpen(true);
  const handleCloseRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <MenuBarOption
        onClick={handleOptionClick}
        iconClassName="icn-delete"
        title={t('DELETE_NODE.TITLE')}
        disabled={disabled}
      />
      <DeleteTsdNodeModal
        tsdId={tsdId}
        tsdNodeId={tsdNodeId}
        isOpen={isModalOpen}
        onCloseRequest={handleCloseRequest}
      />
    </WithPermission>
  );
};

export default DeleteTsdNodeMenuOption;
