import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HeaderNavItemProps } from '../../../../types';

interface Props {
  item: HeaderNavItemProps;
  onClick: () => void;
}

const NavDropdownItem: React.FC<Props> = ({ item, onClick }) => {
  const { title, to, disabled } = item;

  const content = (
    <Outer isDisabled={disabled} onClick={() => onClick()}>
      <Title>{title}</Title>
    </Outer>
  );

  return to != null ? <Link to={to}>{content}</Link> : content;
};

export default NavDropdownItem;

const Outer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  margin: 0 -20px;
  padding: 0 20px;
  transition: all 0.3s ease;
  user-select: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
  ${(props) => props.isDisabled && 'opacity: 0.5; pointer-events: none;'}
`;

const Title = styled.span`
  font-size: 15px;
  line-height: 1.6;
  color: ${(props) => props.theme.colors.white87};
`;
