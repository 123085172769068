import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import useTranslation from 'translations';

interface Props {
  warnings: string[];
}

const TsdElementAttributesWarnings: React.FC<Props> = ({ warnings }) => {
  const t = useTranslation();
  if (!warnings.length) {
    return null;
  }

  const content = (
    <>
      {warnings.map((item) => (
        <p key={item}>{item}</p>
      ))}
    </>
  );

  return (
    <Popover
      content={content}
      title={t('ATTRIBUTES.WARNINGS.TITLE', { count: warnings.length })}
    >
      <WarningOutlinedStyled className="icn-warning" />
    </Popover>
  );
};

export default TsdElementAttributesWarnings;

const WarningOutlinedStyled = styled.i`
  color: ${(props) => props.theme.colors.warning};
  font-size: 22px;
  margin-right: 10px;
`;
