import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Annotation,
  DocumentListItem,
  DocumentMetadata,
  Language,
} from '../../../types';
import { findDocumentById } from '../util';
import TranslationTool from '../TranslationTool/TranslationTool';
import AnnotationsToolbar from '../AnnotationsToolbar/AnnotationsToolbar';
import TranslationStateBar from '../TranslationTool/TranslationStateBar';
import ZoomTool from './ZoomTool';
import { Outer, StyledDivider, Toolbox } from './styled';
import PaginationTool from './PaginationTool';
import ActionButtons from './ActionButtons';
import DocumentInfo from './DocumentInfo';

export const SHOW_ANNOTATION_TOOLBAR_ID = 'SHOW_ANNOTATION_TOOLBAR';
export const SEARCH_ID = 'SEARCH';
export const TEXT_SELECTION_TOOL_ID = 'TEXT_SELECTION_TOOL';
export const DOWNLOAD_ID = 'DOWNLOAD';

export function findDocumentName(
  documentsList: DocumentListItem[],
  documentId: string
): string | null {
  return findDocumentById(documentsList, documentId)?.name || null;
}

export interface Props {
  document: DocumentMetadata;
  annotationFilter: string;
  setAnnotationFilter: (annotationSetKey: string) => void;
  activeAnnotation?: Annotation | null;
  onClickClearActiveAnnotation?: () => void;
  currentPageNumber: string;
  totalNumberOfPages: number;
  currentZoom: number;
  advancedOCRSelectionActive: boolean;
  selectedAnnotationSets?: Record<string, boolean>;
  setSelectedAnnotationSets?: (s: Record<string, boolean>) => void;
  advancedOCRSelectionEnabled: boolean;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onAdvancedOCRSelection: () => void;
  isSearchActive: boolean;
  onSearchToggle: () => void;
  onDocumentChange?: (d: DocumentMetadata) => void;
  documentsToSelect?: DocumentListItem[];
  style?: React.CSSProperties;
  hideAnnotations?: boolean;
  onPageNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setZoom: (e: number) => void;
  translationToolEnabled: boolean;
  onLanguageChange: (
    language: Language,
    pageNumbers?: string,
    isRetranslate?: boolean
  ) => void;
  startTranslate: (language: Language, pageNumbers?: string) => void;
  onPreviousScreen?: () => void;
  viewerIndex?: number;
}

const Toolbar: React.FC<Props> = ({
  currentPageNumber,
  totalNumberOfPages,
  onPreviousPage,
  activeAnnotation,
  onClickClearActiveAnnotation,
  onAdvancedOCRSelection,
  isSearchActive,
  onSearchToggle,
  advancedOCRSelectionActive,
  advancedOCRSelectionEnabled,
  selectedAnnotationSets,
  setSelectedAnnotationSets,
  onNextPage,
  onPageNumberChange,
  currentZoom,
  setZoom,
  document,
  annotationFilter,
  setAnnotationFilter,
  documentsToSelect,
  style,
  hideAnnotations,
  onDocumentChange,
  translationToolEnabled,
  onLanguageChange,
  startTranslate,
  onPreviousScreen,
  viewerIndex,
}) => {
  const authenticLanguage = document.parentDocument
    ? document.parentDocument.contentLanguage
    : document.contentLanguage;
  const [showZoomDropdown, setShowZoomDropdown] = useState(false);
  const [showPagesNavigations, setShowPagesNavigations] = useState(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [showAnnotationToolbar, setShowAnnotationToolbar] = useState(
    !hideAnnotations
  );

  return (
    <ToolbarWrapper className={'toolbar-wrapper'}>
      <Outer style={style} ref={toolbarRef} className={'toolbar-content'}>
        <DocumentInfo
          document={document}
          documentsToSelect={documentsToSelect}
          onPreviousScreen={onPreviousScreen}
          onDocumentChange={onDocumentChange}
        />
        <Toolbox>
          {translationToolEnabled && (
            <TranslationTool
              document={document}
              onLanguageChange={onLanguageChange}
            />
          )}
          <StyledDivider />
          <PaginationTool
            onNextPage={onNextPage}
            onPageNumberChange={onPageNumberChange}
            showPagesNavigations={showPagesNavigations}
            currentPageNumber={currentPageNumber}
            totalNumberOfPages={totalNumberOfPages}
            onPreviousPage={onPreviousPage}
          />
          <StyledDivider />
          <ZoomTool
            currentZoom={currentZoom}
            setZoom={setZoom}
            showZoomDropdown={showZoomDropdown}
          />
          <StyledDivider />
          <ActionButtons
            toolbarRef={toolbarRef}
            onAdvancedOCRSelection={onAdvancedOCRSelection}
            isSearchActive={isSearchActive}
            onSearchToggle={onSearchToggle}
            advancedOCRSelectionActive={advancedOCRSelectionActive}
            advancedOCRSelectionEnabled={advancedOCRSelectionEnabled}
            document={document}
            authenticLanguage={authenticLanguage}
            showAnnotationToolbar={showAnnotationToolbar}
            setShowAnnotationToolbar={setShowAnnotationToolbar}
            setShowZoomDropdown={setShowZoomDropdown}
            setShowPagesNavigations={setShowPagesNavigations}
            viewerIndex={viewerIndex}
          />
        </Toolbox>
      </Outer>

      {showAnnotationToolbar &&
        document.contentLanguage === authenticLanguage && (
          <AnnotationsToolbar
            onClose={() => setShowAnnotationToolbar(false)}
            activeAnnotation={activeAnnotation}
            onClickClearActiveAnnotation={onClickClearActiveAnnotation}
            document={document}
            annotationFilter={annotationFilter}
            setAnnotationFilter={setAnnotationFilter}
            selectedAnnotationSets={selectedAnnotationSets}
            setSelectedAnnotationSets={setSelectedAnnotationSets}
          />
        )}

      {document.translatedPageIndices && (
        <TranslationStateBar
          document={document}
          onRetranslate={startTranslate}
        />
      )}
    </ToolbarWrapper>
  );
};

export default Toolbar;

const ToolbarWrapper = styled.div`
  position: relative;
  z-index: 100;
`;
