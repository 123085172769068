import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { Logo } from '../Layout';
import { T } from '../../..';

interface Props {
  name: string;
  description: string;
  link?: string;
  appIdentifier?: T.ApplicationIdentifier;
  logoStyle?: React.CSSProperties;
  cardStyle?: React.CSSProperties;
  cardNameStyle?: React.CSSProperties;
  cardDescriptionStyle?: React.CSSProperties;
}

const AppCardListItem: React.FC<Props> = ({
  name,
  description,
  link,
  appIdentifier,
  logoStyle,
  cardStyle,
  cardNameStyle,
  cardDescriptionStyle,
}) => {
  const t = useTranslation();

  return (
    <Outer
      style={cardStyle}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      disabled={!link}
    >
      <Inner>
        <Logo
          withGlow={false}
          style={{
            width: '24px',
            height: '24px',
            ...logoStyle,
          }}
          appContext={appIdentifier}
        />
        <AppName style={cardNameStyle}>{t(name)}</AppName>
      </Inner>
      <AppDescription style={cardDescriptionStyle}>
        {t(description)}
      </AppDescription>
    </Outer>
  );
};

const Outer = styled.a<{ disabled: boolean }>`
  width: 240px;
  height: 124px;
  padding: 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  background: linear-gradient(
    111deg,
    #3e4153 -7.94%,
    rgba(59, 61, 74, 0) 114.81%
  );
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(176, 194, 255, 0.1) 0%,
        rgba(176, 194, 255, 0.1) 100%
      ),
      linear-gradient(111deg, #3e4153 -7.94%, rgba(59, 61, 74, 0) 114.81%);
  }
  ${(props) => props.disabled && `pointer-events: none; opacity: 0.7;`}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const AppName = styled.span`
  font-size: 16px;
  margin-left: 16px;
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
`;

const AppDescription = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.white50};
`;

export default AppCardListItem;
