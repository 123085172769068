import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import IpcClasses from './Ipc';
import Companies from './Companies';
import Queries from './Query';
import Synonyms from './Synonyms';
import Descriptions from './Descriptions';
import TextModules from './TextModules';
import TsdElementAttributeTitle from './TsdElementAttributeTitle';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTranslation from 'translations';

const { TabPane } = Tabs;

const TsdElementAttributes: React.FC = () => {
  const { tsdElement } = useTsdElementContext();
  const t = useTranslation();

  const {
    ipcClassCount,
    companiesCount,
    queriesCount,
    synonymsCount,
    textModulesCount,
    descriptionsCount,
  } = tsdElement;

  return (
    <Outer>
      <Tabs defaultActiveKey="1" size="large">
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-synonyms"
              title={t('ATTRIBUTES.SYNONYMS')}
              count={synonymsCount}
            />
          }
          key="synonyms"
        >
          <Synonyms />
        </TabPane>
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-descriptions"
              title={t('ATTRIBUTES.DESCRIPTIONS')}
              count={descriptionsCount}
            />
          }
          key="descriptions"
        >
          <Descriptions />
        </TabPane>
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-ipcs"
              title={t('ATTRIBUTES.CPCS')}
              count={ipcClassCount}
            />
          }
          key="ipcs"
        >
          <IpcClasses />
        </TabPane>
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-companies"
              title={t('ATTRIBUTES.COMPANIES')}
              count={companiesCount}
            />
          }
          key="companies"
        >
          <Companies />
        </TabPane>
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-query"
              title={t('ATTRIBUTES.QUERY')}
              count={queriesCount}
            />
          }
          key="queries"
        >
          <Queries />
        </TabPane>
        <TabPane
          tab={
            <TsdElementAttributeTitle
              testId="tsd-element-tab-text-modules"
              title={t('ATTRIBUTES.TEXT_MODULES')}
              count={textModulesCount}
            />
          }
          key="text-modules"
        >
          <TextModules />
        </TabPane>
      </Tabs>
    </Outer>
  );
};

export default TsdElementAttributes;

const Outer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
  margin-top: 10px;
  .ant-tabs {
    width: 100%;
  }
`;
