import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Button, Upload, message, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import styled from 'styled-components';
import { UploadFile } from 'antd/lib/upload/interface';
import EditGraphicsList from './EditGraphicsList';
import { Api, Hooks } from '@ipos/shared';
import useTsdElementUploads from 'queries/useTsdElementUploads';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTranslation from 'translations';
import { useAppContext } from 'contexts';
import { QueryKey } from 'queries';
import { useElementUsageCheck } from 'hooks';

const { useInvalidateQueries } = Hooks;
const { Dragger } = Upload;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EditGraphics: React.FC<Props> = ({ isOpen, onClose }) => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const { invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();

  const [files, setFiles] = useState<Array<UploadFile>>([]);
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { data: uploads } = useTsdElementUploads(tsdElementId, tsdNodeId);

  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
    }
  }, [isOpen]);

  const { mutate: submitUploads, isLoading: isSubmitting } = useMutation(
    (files: File[]) =>
      Api.tsd.uploadTsdElementGraphics(files, tsdElementId, tsdNodeId),
    {
      onSuccess: () => {
        message.success(
          t('GRAPHICS.IMAGES_UPLOADED_SUCCESSFULLY', { count: files.length })
        );
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementUploads, tsdElementId, tsdNodeId]
        );
      },
    }
  );

  const { mutate: deleteUpload } = useMutation(
    (tsdElementUploadId: string) =>
      Api.tsd.deleteTsdElementGraphics(tsdElementId, tsdElementUploadId),
    {
      onSuccess: () => {
        message.success(t('GRAPHICS.IMAGE_DELETED_SUCCESSFULLY'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementUploads, tsdElementId, tsdNodeId]
        );
      },
    }
  );

  const handleChange = (info: UploadChangeParam): void => {
    setFiles(info.fileList);
  };

  const handleUploadClick = () => {
    const fileObjects = files.map((file) => file.originFileObj);
    applyChange(() =>
      submitUploads(fileObjects as File[], {
        onSuccess: () => {
          setFiles([]);
        },
      })
    );
  };

  const handleDeleteConfirmation = (tsdElementUploadId: string) => {
    applyChange(() => deleteUpload(tsdElementUploadId));
  };

  if (!uploads) {
    return null;
  }

  return (
    <Modal
      title={t('GRAPHICS.EDIT_IMAGES')}
      style={{ top: 140 }}
      open={isOpen}
      destroyOnClose
      onCancel={onClose}
      width={1000}
      footer={null}
    >
      <Content>
        <EditGraphicsList
          data={uploads}
          onRemoveRequest={handleDeleteConfirmation}
        />
        <NewUploads>
          <Dragger
            fileList={files}
            beforeUpload={() => false}
            multiple
            accept={'image/png,image/jpg,image/jpeg'}
            onChange={handleChange}
          >
            <p className="ant-upload-text">{t('GRAPHICS.CLICK_OR_DRAG')}</p>
            <p className="ant-upload-hint">{t('GRAPHICS.SINGLE_OR_BULK')}</p>
          </Dragger>
          {files.length > 0 && (
            <StyledButton
              type="primary"
              loading={isSubmitting}
              onClick={handleUploadClick}
            >
              {isSubmitting
                ? t('GRAPHICS.UPLOADING')
                : t('GRAPHICS.UPLOAD', { count: files.length })}
            </StyledButton>
          )}
        </NewUploads>
      </Content>
    </Modal>
  );
};

export default EditGraphics;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 600px;
`;

const NewUploads = styled.div`
  flex-direction: column;
  width: 400px;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
`;
