import React from 'react';
import styled from 'styled-components';
import {
  useVeryRichContext,
  useVeryRichEditorStateContext,
} from '../../../contexts';
import { COMMAND_GROUPS } from './config';
import ToolbarButton from './ToolbarButton';

import './styles.less';

const Toolbar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { cleanEditorRef } = useVeryRichContext();
  const { editorState } = useVeryRichEditorStateContext();
  // Editor view will always be defined because of the editor. This is a bit hacky, but this is React for you.
  const editorView = cleanEditorRef?.current;
  if (!editorView || !editorState) {
    return null;
  }
  return (
    <Outer>
      {COMMAND_GROUPS.map((group, index) => (
        <ToolbarButton
          key={index}
          group={group}
          index={index}
          editorView={editorView}
          editorState={editorState}
        />
      ))}
      {children}
    </Outer>
  );
};

const Outer = styled.div`
  padding: 8px;
  z-index: 10000;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
`;

export default Toolbar;
