import React, { useMemo } from 'react';
import dayjs, { QUnitType } from 'dayjs';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { Colors } from '../../theme';
import ProgressBar from '../ProgressBar';

type LifespanSize = 'default' | 'small';

const STYLE_CONFIG = {
  default: {
    shortValueStyle: { fontSize: 19 },
    shortUnitStyle: { fontSize: 14 },
    longStyle: { fontSize: 12 },
    negativeStyle: { fontSize: 15 },
  },
  small: {
    shortValueStyle: { fontSize: 16 },
    shortUnitStyle: { fontSize: 12 },
    longStyle: { fontSize: 11 },
    negativeStyle: { fontSize: 13 },
  },
};

interface Props {
  toDate: Date;
  fromDate?: Date;
  currentDate?: Date;
  format?: string;
  unit?: QUnitType;
  size?: LifespanSize;
  invertedProgress?: boolean;
  negativePlaceholder?: string;
  style?: React.CSSProperties;
}

const Lifespan: React.FC<Props> = ({
  toDate,
  fromDate,
  currentDate = new Date(),
  format = 'D MMM YYYY',
  unit = 'day',
  size = 'default',
  invertedProgress = false,
  negativePlaceholder,
  style,
}) => {
  const t = useTranslation();

  const calculatedDiff = useMemo(
    () => dayjs(toDate).diff(currentDate, unit),
    [toDate, currentDate, unit]
  );

  let percent;
  if (fromDate != null) {
    // calculate the passed time as percentage (fromDate -> currentDate -> toDate)
    percent =
      ((currentDate.getTime() - fromDate.getTime()) /
        (toDate.getTime() - fromDate.getTime())) *
      100;

    if (toDate < currentDate) {
      percent = 100;
    }
  }

  const progressColors = useMemo(() => {
    const colors = [Colors.blue500, Colors.warning, Colors.error];

    if (invertedProgress) {
      return colors.reverse();
    }

    return colors;
  }, [invertedProgress]);

  const { shortUnitStyle, shortValueStyle, longStyle, negativeStyle } =
    STYLE_CONFIG[size];

  let title: JSX.Element = (
    <Short>
      <Value style={shortValueStyle}>{calculatedDiff}</Value>
      <Unit style={shortUnitStyle}>
        {t(`TIME_SPAN.${unit?.toUpperCase()}`)}
      </Unit>
    </Short>
  );

  if (calculatedDiff < 0) {
    title = (
      <Negative style={negativeStyle}>
        {negativePlaceholder || t('EXPIRED')}
      </Negative>
    );
  }

  return (
    <Outer data-testid="lifespan-indication" style={style}>
      {title}
      {percent != null && (
        <ProgressBar colors={progressColors} percent={percent} height={5} />
      )}
      <Long style={longStyle}>{dayjs(toDate).format(format)}</Long>
    </Outer>
  );
};

export default Lifespan;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Short = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
`;

const Negative = styled.div`
  line-height: 1.2;
  color: ${(props) => props.theme.colors.white70};
`;

const Value = styled.div`
  font-weight: 600;
  line-height: 1.1;
  color: ${(props) => props.theme.colors.white70};
`;

const Unit = styled.div`
  font-weight: 400;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.white50};
`;

const Long = styled.div`
  color: ${(props) => props.theme.colors.white50};
`;
