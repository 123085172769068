import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Modal, message } from 'antd';
import { Permissions, Utils, Api, queryClient } from '@ipos/shared';
import { MenuBarOption } from 'components/common';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { WithPermission } = Permissions;
const { initBrowserDownload } = Utils;

interface Props {
  tsdId: string;
}

const ExportTsdMenuOption: React.FC<Props> = ({ tsdId }) => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { refetch } = useQuery(
    [QueryKey.TsdJsonDownload, tsdId, preferredLanguage],
    () => Api.tsd.exportTsd(tsdId, preferredLanguage),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    const handleModalStateChange = async () => {
      await queryClient.cancelQueries([
        QueryKey.TsdJsonDownload,
        tsdId,
        preferredLanguage,
      ]);
      queryClient.removeQueries([
        QueryKey.TsdJsonDownload,
        tsdId,
        preferredLanguage,
      ]);

      if (!isModalOpen) {
        return;
      }

      try {
        const { data } = await refetch();
        if (data) {
          const [bytes, filename] = data;
          initBrowserDownload(filename, bytes);
        }
      } catch {
        message.error(t('TSD_EXPORT.ERROR'));
      } finally {
        setModalOpen(false);
      }
    };
    handleModalStateChange();
  }, [isModalOpen, tsdId, preferredLanguage]);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <MenuBarOption
        onClick={() => setModalOpen(true)}
        iconClassName="icn-export"
        title={t('TSD_EXPORT.TITLE')}
      />
      {isModalOpen && (
        <Modal
          title={t('TSD_EXPORT.TITLE')}
          okText={t('TSD_EXPORT.GENERATING_FILE')}
          cancelText={t('ACTION.CANCEL')}
          okButtonProps={{
            loading: true,
          }}
          onCancel={() => setModalOpen(false)}
          open={true}
          width={400}
        >
          <p>{t('TSD_EXPORT.MESSAGE')}</p>
        </Modal>
      )}
    </WithPermission>
  );
};

export default ExportTsdMenuOption;
