import React, { memo, useEffect, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import styled from 'styled-components';
import RBush from 'rbush';
import throttle from 'lodash.throttle';
import { AnnotatedPageLocation, DocumentNavigationLink } from '../../types';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { A4_ASPECT_RATIO } from '../../constants';
import { BBoxWithTooltip, DocumentLocation } from './types';
import {
  drawAnnotations,
  drawHighlighter,
  drawSearchMatches,
  mapAnnotationsToBBoxes,
  mapToCanvasCoordinates,
  projectAidToImage,
} from './util';

interface PageWrapperProps {
  className?: string;
  pdfWidth: number;
  searchMatches: DocumentLocation[][];
  activeMatchIndex: number | null;
  scale: number;
  index: number;
  page: HTMLDivElement;
  annotations?: AnnotatedPageLocation[] | null;
  visible: boolean;
  advancedOCRSelectionActive: boolean;
  pageRef: (element: HTMLDivElement | null, index: number) => void;
  hasBackdrop?: boolean;
  aspectRatio?: number;
  onClickNavigationLink?: (documentId: string, pageIndex: number) => void;
}

const PageWrapper: React.FC<PageWrapperProps> = memo(
  ({
    visible,
    className,
    pdfWidth,
    page,
    searchMatches,
    activeMatchIndex,
    pageRef,
    index,
    scale,
    aspectRatio,
    annotations,
    hasBackdrop,
    onClickNavigationLink,
  }) => {
    const defaultHeight = (aspectRatio || A4_ASPECT_RATIO) * (pdfWidth || 0);
    const mappedBoundingBoxes = useRef<RBush<BBoxWithTooltip>>();
    const dummyAnnotationRef = useRef<HTMLDivElement>(null);
    const dummyNavigationLinksDropdownRef = useRef<HTMLDivElement>(null);
    const [canvas, setAnnotationsCanvasRef] =
      useState<HTMLCanvasElement | null>(null);
    const [searchCanvas, setSearchCanvas] = useState<HTMLCanvasElement | null>(
      null
    );

    const [
      activeAnnotationForNavigationLinks,
      setActiveAnnotationForNavigationLinks,
    ] = useState<AnnotatedPageLocation | null>(null);

    const handleNavigationLinkClick = (link: DocumentNavigationLink) => {
      if (!onClickNavigationLink) {
        return;
      }

      const documentId = link.uri.split('/')[2];
      onClickNavigationLink(documentId, link.pageIndex);
    };

    useEffect(() => {
      if (!page || !annotations?.length) {
        return;
      }
      const callback = (e: MouseEvent) => {
        const pageRect = page.getBoundingClientRect();
        const yPosition = e.clientY - pageRect.top;
        const xPosition = e.clientX - pageRect.left;
        const annotationsUnderMouse = mappedBoundingBoxes?.current?.search({
          minX: xPosition,
          minY: yPosition,
          maxX: xPosition + 2,
          maxY: yPosition + 2,
        });
        requestAnimationFrame(() =>
          drawHighlighter(dummyAnnotationRef, annotationsUnderMouse)
        );
      };
      const throttledMouseCallback = throttle(callback, 30);

      page.addEventListener('mousemove', throttledMouseCallback, true);
      return () => {
        page.removeEventListener('mousemove', throttledMouseCallback, true);
      };
    }, [page, annotations]);

    /**
     * Navigation Links
     * ----------------
     * This effect is responsible to detect clicks on annotations and
     * trigger the appropriate action for the navigation links
     */
    useEffect(() => {
      if (!page || !annotations?.length) {
        setActiveAnnotationForNavigationLinks(null);
        return;
      }

      const callback = (e: MouseEvent) => {
        if (!dummyNavigationLinksDropdownRef.current) {
          return;
        }

        const pageBCRect = page.getBoundingClientRect();

        const clickedAnnotation = annotations
          .filter((a) => a.pageNumber === index && a.navigationLinks.length > 0)
          .find((a) => {
            const { height, width, x, y } = projectAidToImage({
              annotation: {
                text: a.text || '',
                location: a.pageLocation,
              },
              pageWidth: pageBCRect.width,
              pageHeight: pageBCRect.height,
            });

            const yPosition = e.clientY - pageBCRect.top;
            const xPosition = e.clientX - pageBCRect.left;

            return (
              xPosition > x &&
              xPosition < x + width &&
              yPosition > y &&
              yPosition < y + height
            );
          });

        dummyNavigationLinksDropdownRef.current.style.display = 'none';

        if (!clickedAnnotation?.navigationLinks?.length) {
          return;
        }

        if (clickedAnnotation.navigationLinks.length === 1) {
          return handleNavigationLinkClick(
            clickedAnnotation.navigationLinks[0]
          );
        }

        const { x: dropdownX, y: dropdownY } = projectAidToImage({
          annotation: {
            text: clickedAnnotation.text || '',
            location: clickedAnnotation.pageLocation,
          },
          pageWidth: pageBCRect.width,
          pageHeight: pageBCRect.height,
        });

        const left = dropdownX;
        const top = dropdownY + 20;
        dummyNavigationLinksDropdownRef.current.style.display = 'block';
        dummyNavigationLinksDropdownRef.current.style.left = `${left}px`;
        dummyNavigationLinksDropdownRef.current.style.top = `${top}px`;

        return setActiveAnnotationForNavigationLinks(clickedAnnotation);
      };

      const throttledClickCallback = throttle(callback, 30);

      page.addEventListener('click', throttledClickCallback, true);

      return () => {
        page.removeEventListener('click', throttledClickCallback, true);
      };
    }, [page, annotations]);

    useEffect(() => {
      if (!annotations?.length || !canvas) {
        return;
      }
      const currentPageAnnotations = annotations.filter(
        (aa) => aa.pageNumber === index
      );
      const projectedAnnotations = mapToCanvasCoordinates(
        canvas,
        currentPageAnnotations
      );
      const boundingBoxes = mapAnnotationsToBBoxes(projectedAnnotations);
      const tree = new RBush<BBoxWithTooltip>();
      mappedBoundingBoxes.current = tree;
      tree.load(boundingBoxes);
    });

    useEffect(() => {
      const ctx = canvas?.getContext('2d');
      if (!canvas || !ctx || !annotations) {
        return;
      }
      const currentPageAnnotations = annotations.filter(
        (aa) => aa.pageNumber === index
      );
      requestAnimationFrame(() =>
        drawAnnotations(canvas, currentPageAnnotations)
      );
    });

    useEffect(() => {
      if (!searchCanvas) {
        return;
      }
      requestAnimationFrame(() =>
        drawSearchMatches(
          searchCanvas,
          searchMatches,
          index + 1,
          activeMatchIndex
        )
      );
    }, [searchCanvas, searchMatches, index, activeMatchIndex]);

    return (
      <div
        style={{
          width: `${pdfWidth * scale}px`,
          height: `${defaultHeight * scale}px`,
          position: 'relative',
          marginBottom: '10px',
        }}
        ref={(node) => pageRef(node, index)}
        data-page-index={index}
      >
        <div
          style={{
            width: `${pdfWidth * scale}px`,
            height: `${defaultHeight * scale}px`,
            position: 'relative',
          }}
        >
          {visible && (
            <>
              <DummyAnnotationPlaceholder ref={dummyAnnotationRef}>
                <AnnotationTooltip />
              </DummyAnnotationPlaceholder>
              <DummyNavigationLinksDropdown
                ref={dummyNavigationLinksDropdownRef}
              >
                {activeAnnotationForNavigationLinks?.pageNumber === index &&
                  activeAnnotationForNavigationLinks?.navigationLinks.map(
                    (link, linkIdx) => (
                      <NavigationLink
                        onClick={() => handleNavigationLinkClick(link)}
                        key={`page_${index + 1}_link_${linkIdx}`}
                      >
                        <span>{link.label}</span>
                        <Icon
                          className="icn-external-link"
                          size={16}
                          color="inherit"
                        />
                      </NavigationLink>
                    )
                  )}
              </DummyNavigationLinksDropdown>
              <Page
                loading={<Spinner />}
                className={className}
                scale={scale}
                width={pdfWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
              />
              <Canvas
                className="annotation-canvas"
                width={pdfWidth * scale}
                height={defaultHeight * scale}
                ref={(node) => {
                  setAnnotationsCanvasRef(node);
                }}
              />
              <Canvas
                width={pdfWidth * scale}
                height={defaultHeight * scale}
                ref={(node) => setSearchCanvas(node)}
              />
              {hasBackdrop && <Backdrop />}
            </>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.visible === nextProps.visible &&
    prevProps.className === nextProps.className &&
    prevProps.searchMatches === nextProps.searchMatches &&
    prevProps.activeMatchIndex === nextProps.activeMatchIndex &&
    prevProps.pdfWidth === nextProps.pdfWidth &&
    prevProps.advancedOCRSelectionActive ===
      nextProps.advancedOCRSelectionActive &&
    prevProps.scale === nextProps.scale &&
    prevProps.page === nextProps.page &&
    prevProps.annotations === nextProps.annotations &&
    prevProps.index === nextProps.index
);

const AnnotationTooltip = styled.div`
  background-image: linear-gradient(
    118deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end}
  );
  border-radius: 6px;
  position: absolute;
  min-width: 300px;
  bottom: calc(100% + 5px);
  left: 50%;
  padding: 8px;
  overflow-wrap: break-word;
  display: flex;
  text-align: justify;
`;

const DummyAnnotationPlaceholder = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 100;
  display: none;
`;

const DummyNavigationLinksDropdown = styled.div`
  position: absolute;
  z-index: 1000;
  background-image: linear-gradient(
    118deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end}
  );
  border-radius: 12px;
  left: 0;
  top: 0;
  padding: 14px;
  color: ${(props) => props.theme.colors.white70};
  display: none;
`;

const NavigationLink = styled.button`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;

  &:hover {
    color: white;
  }
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  bottom: 0;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
`;

export default PageWrapper;
