import React from 'react';
import { Button } from 'antd';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';

const { Icon } = Components;

interface Props {
  queryString: string | undefined;
  type?: 'ghost' | 'dashed';
}

const TestQueryStringButton: React.FC<Props> = ({
  type = 'ghost',
  queryString,
}) => {
  const t = useTranslation();

  const handleTestClick = () => {
    if (queryString) {
      window.open(queryString, '_blank');
    }
  };

  return (
    <Button
      type={type}
      icon={
        <Icon
          className="icn-play-solid"
          style={{ verticalAlign: 'text-bottom', marginRight: 5 }}
        />
      }
      onClick={handleTestClick}
      disabled={!queryString}
    >
      {t('QUERY.TEST')}
    </Button>
  );
};

export default TestQueryStringButton;
