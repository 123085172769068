import { NodeSpec } from 'prosemirror-model';

export const claimReference: NodeSpec = {
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {
    text: {
      default: '',
    },
    editable: {
      default: false,
    },
  },
  selectable: false,
  draggable: false,

  toDOM: (node) => {
    const attrs = {
      class: 'claim-reference',
      'data-part-editable': node.attrs.editable,
    };

    return ['span', attrs, node.attrs.text];
  },
};
