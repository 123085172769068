import React, { useMemo } from 'react';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import AddSynonymsButton from './AddSynonymsButton';
import SynonymsList from './SynonymsList';
import { Components, T } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTranslation from 'translations';
import { useTsdElementSynonyms } from 'queries';
import { useAppContext } from 'contexts';

const { Notification } = Components;

const sortByLanguages =
  (languages: T.Language[]) =>
  (a: T.TsdElementSynonym, b: T.TsdElementSynonym) =>
    Number(b.isPrimary) - Number(a.isPrimary) ||
    languages?.indexOf(a.language) - languages?.indexOf(b.language);

const Synonyms: React.FC = () => {
  const t = useTranslation();
  const { languages = [] } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const query = useTsdElementSynonyms(tsdElementId, tsdNodeId);
  const sortFunction = useMemo(() => sortByLanguages(languages), [languages]);

  if (query.isLoading || query.isIdle) {
    return <Notification spin spinSize="large" />;
  }
  if (query.isError) {
    return <Notification error />;
  }

  const { data } = query;
  const sorted = [...data].sort(sortFunction);

  return (
    <TsdElementAttributesPane
      showWarning={!data.length}
      warningText={t('SYNONYMS.NO_SYNONYMS_YET')}
    >
      <div>
        <AddSynonymsButton />
      </div>
      <SynonymsList data={sorted} />
    </TsdElementAttributesPane>
  );
};

export default Synonyms;
