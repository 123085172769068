import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { FlatButton, IconButton } from '../../../Button';
import { ComponentTypeToolbarItemProps } from '../config';

const BASIC_SYMBOLS = [
  '-',
  '–',
  '—',
  '―',
  '%',
  '‰',
  '‱',
  '!',
  '?',
  '.',
  ',',
  ';',
  ':',
  '(',
  ')',
  '[',
  ']',
  '{',
  '}',
  '#',
  '@',
  '&',
  '*',
  '+',
  '=',
  '<',
  '>',
  '§',
  '¶',
];

const SymbolMenu: React.FC<ComponentTypeToolbarItemProps> = ({
  editorView,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const handleItemClick = (item: string) => {
    if (!editorView) {
      return;
    }
    const { schema, tr } = editorView.state;
    const textNode = schema.text(item);
    editorView.dispatch(tr.insert(tr.selection.from, textNode));
    closePopover();
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      align="center"
      containerStyle={{ marginTop: '10px' }}
      onClickOutside={closePopover}
      content={
        <Outer onClick={(e) => e.stopPropagation()}>
          {BASIC_SYMBOLS.map((item) => (
            <FlatButton
              key={item}
              onClick={() => handleItemClick(item)}
              style={{ margin: 0, width: 30, height: 30 }}
            >
              {item}
            </FlatButton>
          ))}
        </Outer>
      }
    >
      <Trigger active={isPopoverOpen} onClick={togglePopover}>
        <StyledButton
          className="icn-symbol"
          buttonStyle={{ borderRadius: 3 }}
        />
      </Trigger>
    </Popover>
  );
};

export default SymbolMenu;

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 150px;
  max-width: 200px;
  padding: 10px 5px;
  border-radius: 12px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
`;

const StyledButton = styled(IconButton)`
  &:first-child {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white70};
  }
`;

const Trigger = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
