import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';
import { msalInstance } from '../auth/msalInstance';

export class ClientCredentialAuthProvider implements AuthenticationProvider {
  public async getAccessToken(): Promise<string> {
    const accounts = msalInstance.getAllAccounts();
    const authResult = await msalInstance.acquireTokenSilent({
      redirectUri: `${window.location.origin}/login`,
      scopes: [
        'offline_access',
        'https://graph.microsoft.com/User.Read',
        'https://graph.microsoft.com/GroupMember.Read.All',
      ],
      account: accounts[0],
    });

    const { accessToken } = authResult;

    return accessToken;
  }
}
