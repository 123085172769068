import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Colors } from '@ipos/shared';

interface Props {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  placement?: TooltipPlacement;
  disabled?: boolean;
}

const TutorialsTooltip: React.FC<Props> = ({
  title,
  children,
  style,
  placement,
  disabled,
}) => (
  <Tooltip
    open={disabled ? false : undefined}
    placement={placement}
    color={Colors.gradient100}
    overlayInnerStyle={{
      fontWeight: 400,
      fontSize: 15,
      color: Colors.white87,
      minHeight: 0,
      borderRadius: 8,
      padding: '16px',
      lineHeight: '24px',
      ...style,
    }}
    title={title}
    destroyTooltipOnHide
  >
    {children}
  </Tooltip>
);

export default TutorialsTooltip;
