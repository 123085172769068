import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import QueryBuilderClasses from '../Segments/CPCs/QueryBuilderClasses';
import QueryBuilderSynonyms from '../Segments/Synonyms/QueryBuilderSynonyms';
import QueryPreview from '../QueryPreview';
import { useQueryContext } from '../QueryContext';
import Actions from './Common/Actions';
import Content from './Common/Content';
import { Components, T } from '@ipos/shared';
import { Action, QueryBase, QueryBaseItem } from 'types';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useQueryBuilderBase } from 'queries';
import useTranslation from 'translations';

interface Props {
  onDone: () => void;
  onTest: () => void;
}

const { Notification } = Components;

const QuerySetup: React.FC<Props> = ({ onDone, onTest }) => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { dispatch, query, isSaving, saveQuery, baseQuery, hasUnsavedChanges } =
    useQueryContext();

  const { isLoading, isError, queryBase } = useQueryBuilderBase(
    tsdElementId,
    tsdNodeId
  );

  useEffect(() => {
    if (!query && queryBase) {
      const onlyNodeAttributes = (item: QueryBaseItem) => !item.isBaseAttribute;

      let newClasses: T.TsdQueryIpcClass[] = queryBase.classes
        .filter(onlyNodeAttributes)
        .map((c) => ({
          id: c.id,
          code: c.value,
          isTruncated: false,
        }));

      let newSynonyms: T.TsdQuerySynonym[] = queryBase.synonyms
        .filter(onlyNodeAttributes)
        .map((p) => ({
          id: p.id,
          value: p.value,
          searchInTitle: true,
          searchInAbstract: true,
          searchInClaims: true,
        }));

      if (baseQuery) {
        newClasses = [...newClasses, ...baseQuery.classes];
        newSynonyms = [...newSynonyms, ...baseQuery.synonyms];
      }

      dispatch({
        type: Action.InitQueryRequest,
        payload: {
          isInExpertMode: false,
          googlePatentsQuery: undefined,
          patBaseQuery: undefined,
          classes: newClasses,
          synonyms: newSynonyms,
        },
      });
    }
  }, [query, queryBase]);

  if (isLoading || !query) {
    return <Notification spin style={{ height: 600 }} spinSize="large" />;
  }

  if (isError) {
    return <Notification error style={{ height: 600 }} />;
  }

  const { classes, synonyms } = queryBase as QueryBase;

  const handleTestClick = async () => {
    if (hasUnsavedChanges) {
      await saveQuery();
    }

    onTest();
  };

  const handleDoneClick = async () => {
    if (hasUnsavedChanges) {
      await saveQuery();
    }
    onDone();
  };

  return (
    <Outer>
      <Content>
        <Attributes>
          <QueryBuilderClasses items={classes} />
          <QueryBuilderSynonyms items={synonyms} />
        </Attributes>
      </Content>
      <QueryPreview query={query} />
      <Actions>
        <Button onClick={onDone}>{t('ACTION.CANCEL')}</Button>
        <Space>
          <Button type="primary" onClick={handleTestClick} loading={isSaving}>
            {hasUnsavedChanges
              ? t('QUERY.TEST_QUERIES_WITH_SAVE_ACTION')
              : t('QUERY.TEST_QUERIES_ACTION')}
          </Button>
          <Button type="primary" onClick={handleDoneClick} loading={isSaving}>
            {t('DONE')}
          </Button>
        </Space>
      </Actions>
    </Outer>
  );
};

export default QuerySetup;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Attributes = styled.div`
  display: flex;
  width: 100%;
  > :nth-child(1) {
    flex: 1;
  }
  > :nth-child(2) {
    flex: 2;
  }
`;
