import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { Api } from '../..';
import { UploadDescriptionEditorOleResponse } from '../types';
import useTranslation from '../translations';

export interface UploadDescriptionOleProps {
  editorDocumentId: string;
  revisionId: string;
  imageSourceId: string;
  sectionId: string;
  contentNodeId: string;
  file: FormData;
  handleUploadProgress?: (progressEvent: ProgressEvent) => void;
}

const useUploadDescriptionOleObject = (
  handleUploadProgress?: (progressEvent: ProgressEvent) => void,
  onSuccess?: (response: UploadDescriptionEditorOleResponse) => void
) => {
  const t = useTranslation();
  const {
    mutateAsync: uploadDescriptionOleObject,
    isLoading: isUploadingDescriptionOleObject,
  } = useMutation<
    UploadDescriptionEditorOleResponse,
    AxiosError,
    UploadDescriptionOleProps
  >(
    ({
      editorDocumentId,
      revisionId,
      imageSourceId,
      sectionId,
      contentNodeId,
      file,
    }) =>
      Api.oa.postUploadDescriptionOleObject({
        editorDocumentId,
        revisionId,
        imageSourceId,
        sectionId,
        contentNodeId,
        file,
        handleUploadProgress,
      }),
    {
      onSuccess,
      onError: (error) => {
        message.error(
          error.response?.data?.Message || t('SOMETHING_WENT_WRONG')
        );
      },
    }
  );

  return {
    uploadDescriptionOleObject,
    isUploadingDescriptionOleObject,
  };
};

export default useUploadDescriptionOleObject;
