import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useTsdElementCompanyGroups = (
  tsdElementId: string,
  tsdNodeId?: string,
  options?: UseQueryOptions<T.TsdElementCompanyGroup[], AxiosError>
): UseQueryResult<T.TsdElementCompanyGroup[], AxiosError> =>
  useQuery<T.TsdElementCompanyGroup[], AxiosError>(
    [QueryKey.TsdElementCompanies, tsdElementId, tsdNodeId],
    () => Api.tsd.getTsdElementCompanyGroups(tsdElementId, tsdNodeId),
    options
  );

export default useTsdElementCompanyGroups;
