import qs from 'querystring';
import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/ipc-classes';

export const getClasses = (
  searchTerm: string,
  pagination: { pageSize: number; skip: number }
): Promise<T.IpcClass[]> =>
  Api.get<T.IpcClass[]>(
    `${API_BASE}/?${qs.stringify({ searchTerm, ...pagination })}`
  ).then((response) => response.data);
