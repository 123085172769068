import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import TsdElementUsage from './TsdElementUsage';
import { useTsdElementContext } from './TsdElementContext';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';

const { Icon } = Components;

const TsdElementUsagePopup: React.FC = () => {
  const t = useTranslation();
  const { tsdElement } = useTsdElementContext();
  const { frequency } = tsdElement;

  return (
    <Popover
      content={
        <Content>
          <TsdElementUsage />
        </Content>
      }
      trigger="click"
      placement="bottom"
      destroyTooltipOnHide
    >
      <Outer>
        {t('USAGE_INDICATION.MESSAGE', { frequency })}
        <Icon className="icn-info-outlined" size={24} />
      </Outer>
    </Popover>
  );
};

export default TsdElementUsagePopup;

const Outer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  width: 280px;
  min-height: 80px;
`;
