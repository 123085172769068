import React, { useCallback, useRef, Dispatch, SetStateAction } from 'react';
import { isVisibleWithinAncestor } from '../../utils';
import { TsdNode } from '../../types';
import { FindNodeResult } from './useTsdTreeHelpers';

const LOOP_INTERVAL = 50;
const LOOP_COUNT = 60;
const NODE_ENTRY_DELAY = 300;

const useScrollToNode = ({
  treeContainerRef,
  setExpandedNodeIds,
  findNodeById,
}: {
  treeContainerRef: React.RefObject<HTMLElement>;
  setExpandedNodeIds: Dispatch<SetStateAction<Set<string>>>;
  findNodeById: (id: string) => FindNodeResult;
}): {
  scrollToNode: (nodeId: string) => void;
} => {
  const scrollToNodeIdRef = useRef<string | null>(null);

  const executeScrollToNode = (nodeId: string) => {
    // Account for duration of ant tree expand animation
    setTimeout(() => {
      document.getElementById(nodeId)?.scrollIntoView();
      scrollToNodeIdRef.current = null;
    }, NODE_ENTRY_DELAY);
  };

  const initiateScrollToNode = (nodeId: string) => {
    scrollToNodeIdRef.current = nodeId;
    const loop = (id: string, counter = 0) => {
      setTimeout(() => {
        // Node is not found within set interval or function is invoked with new nodeId
        if (counter === LOOP_COUNT || id !== scrollToNodeIdRef.current) {
          return;
        }
        const targetNode = document.getElementById(id);
        // Node has not appeared in the dom yet
        if (targetNode === null || treeContainerRef.current === null) {
          loop(id, counter + 1);
          return;
        }
        const isNodeVisible = isVisibleWithinAncestor(
          treeContainerRef.current,
          targetNode,
          true
        );
        // Node is fully visible within tree cointaner
        if (isNodeVisible) {
          return;
        }
        // Jump to node
        executeScrollToNode(id);
      }, LOOP_INTERVAL);
    };
    // Initiate scroll to node sequence
    loop(nodeId);
  };

  const expandParentNodes = (parentsToRoot: TsdNode[]) => {
    setExpandedNodeIds((prevExpandedNodeIds) => {
      // Check if there are collapsed parent nodes
      const collapsedParentNodes = parentsToRoot.filter(
        (node) => !prevExpandedNodeIds.has(node.id)
      );
      if (collapsedParentNodes.length === 0) {
        return prevExpandedNodeIds;
      }
      // Expand collapsed parent nodes
      const updatedNodeIds = new Set(prevExpandedNodeIds);
      collapsedParentNodes.forEach((node) => {
        updatedNodeIds.add(node.id);
      });
      return updatedNodeIds;
    });
  };

  const scrollToNode = useCallback(
    (nodeId: string) => {
      const { targetNode, parentsToRoot } = findNodeById(nodeId);
      // Check if node exists
      if (targetNode) {
        expandParentNodes(parentsToRoot);
        initiateScrollToNode(nodeId);
      }
    },
    [findNodeById]
  );

  return {
    scrollToNode,
  };
};

export default useScrollToNode;
