import { TFunction } from 'i18next';
import { PatentRevisionDocumentType } from '../../types';

const REVISION_DOCUMENT_TYPE_TRANSLATION = {
  [PatentRevisionDocumentType.Claims]: 'REVISION_DOCUMENT_TYPE.CLAIMS',
  [PatentRevisionDocumentType.Description]:
    'REVISION_DOCUMENT_TYPE.DESCRIPTION',
  [PatentRevisionDocumentType.Figures]: 'REVISION_DOCUMENT_TYPE.FIGURES',
  [PatentRevisionDocumentType.Sequences]: 'REVISION_DOCUMENT_TYPE.SEQUENCES',
  [PatentRevisionDocumentType.Abstract]: 'REVISION_DOCUMENT_TYPE.ABSTRACT',
};

export const translatePatentRevisionDocType = (
  type: PatentRevisionDocumentType,
  t: TFunction
): string => t(REVISION_DOCUMENT_TYPE_TRANSLATION[type]);
