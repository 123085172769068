import React from 'react';
import styled from 'styled-components';
import { SortOrder } from '../../types';

interface Props {
  sorting?: SortOrder;
  onChange: (value: SortOrder) => void;
}

const ColumnSort: React.FC<Props> = ({ sorting, onChange }) => (
  <Outer
    onClick={() =>
      onChange(sorting !== SortOrder.ASC ? SortOrder.ASC : SortOrder.DESC)
    }
  >
    <StyledIcon
      className="icn-sort-ascending"
      active={sorting === SortOrder.ASC}
    />
    <StyledIcon
      className="icn-sort-descending"
      active={sorting === SortOrder.DESC}
    />
  </Outer>
);

export default ColumnSort;

const StyledIcon = styled.i<{ active: boolean }>`
  display: flex;
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 20px;
  transition: color 0.3s ease;
  color: ${(props) =>
    props.active ? props.theme.colors.white100 : props.theme.colors.primary100};
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;

  &:hover {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.success};
    }
  }
`;
