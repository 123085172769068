import React, { useState } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { Icon } from '../Icon';
import { SurfaceTypes } from '../../types';

interface Props {
  children?: React.ReactNode;
  gridGap?: number;
  testId?: string;
  leftDrawerTestId?: string;
  type?: SurfaceTypes;
  childrenWrapperStyle?: CSSObject;
  style?: CSSObject;
  backgroundStyle?: CSSObject;
  onClick?: () => void;
  leftDrawer?: React.ReactNode;
  leftDrawerHandlerStyle?: CSSObject;
  leftDrawerLabel?: string;
}
const Surface: React.FC<Props> = ({
  onClick,
  type,
  testId,
  leftDrawerTestId,
  children,
  leftDrawer,
  leftDrawerLabel,
  style,
  backgroundStyle,
  childrenWrapperStyle,
  leftDrawerHandlerStyle,
  gridGap,
}) => {
  const [drawerVisible, setDrawerVisibility] = useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const gap = gridGap ?? 40;

  const handleClick = () => {
    if (onClick != null) {
      onClick();
    }
  };

  const isInteractive = onClick != null;

  return (
    <Outer
      data-testid={testId}
      onClick={handleClick}
      interactive={isInteractive}
      styleOverride={style}
      isDrawerOpen={drawerVisible}
      onMouseLeave={() => setIsMouseOver(false)}
      onMouseEnter={() => setIsMouseOver(isInteractive)}
    >
      <Background
        drawerVisible={drawerVisible}
        styleOverride={backgroundStyle}
        highlighted={isInteractive && isMouseOver}
        type={type}
      />
      <Children style={childrenWrapperStyle}>{children}</Children>
      {leftDrawer && (
        <LeftDrawerOuter type={type} visible={drawerVisible} gridGap={gap}>
          <DrawerBackgroundFix visible={drawerVisible} />
          <DrawerButton
            data-testid={leftDrawerTestId}
            className="drawer-trigger"
            style={leftDrawerHandlerStyle}
            onClick={() => setDrawerVisibility(!drawerVisible)}
            gridGap={gap}
          >
            <Icon
              className={
                drawerVisible ? 'icn-chevron-right' : 'icn-chevron-left'
              }
              style={{ marginBottom: 10 }}
              color="inherit"
              size={20}
            />
            <span color="inherit">{leftDrawerLabel}</span>
          </DrawerButton>
          <LeftDrawerContent
            className="drawer-content"
            visible={drawerVisible}
            gridGap={gap}
          >
            {/* @ts-ignore */}
            {React.cloneElement(leftDrawer, { drawerVisible })}
          </LeftDrawerContent>
        </LeftDrawerOuter>
      )}
    </Outer>
  );
};

const Outer = styled.div<{
  styleOverride?: CSSObject;
  isDrawerOpen: boolean;
  interactive: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  min-height: 0;
  position: relative;
  cursor: ${(props) => (props.interactive ? 'pointer' : 'auto')};
  z-index: ${(props) => (props.isDrawerOpen ? 1 : 0)};
  ${(props) =>
    css`
      ${props.styleOverride as CSSObject}
    `};
`;

const Background = styled.div<{
  type?: SurfaceTypes;
  styleOverride?: CSSObject;
  highlighted?: boolean;
  drawerVisible: boolean;
}>`
  z-index: 1;
  position: absolute;
  ${(props) =>
    props.drawerVisible
      ? css`
          border-bottom-right-radius: 14px;
          border-top-right-radius: 14px;
        `
      : css`
          border-radius: 14px;
        `}
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.4s;
  ${(props) => {
    switch (props.type) {
      case 'dark':
        return `background-color: ${props.theme.colors.gradient100};`;
      case 'light':
        return `background-color: ${props.theme.colors.primary800};`;
      default:
        return 'border-radius: 0px;';
    }
  }};
  ${(props) =>
    props.highlighted &&
    css`
      ${() => {
        switch (props.type) {
          case 'dark':
            return `background-color: ${props.theme.colors.gradient200};`;
          case 'light':
            return `background-color: ${props.theme.colors.gradient100};`;
          default:
            return 'border-radius: 0px;';
        }
      }};
    `}
  ${(props) =>
    css`
      ${props.styleOverride as CSSObject}
    `};
`;

const Children = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
  z-index: 2;
`;

const LeftDrawerOuter = styled.div<{
  type?: SurfaceTypes;
  visible: boolean;
  gridGap: number;
}>`
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.4s;
  width: calc(100% + ${(props) => props.gridGap}px);
  ${(props) =>
    props.visible &&
    css`
      transform: translateX(-100%);
    `};
  .drawer-trigger {
    ${(props) => {
      switch (props.type) {
        case 'dark':
          return `background-color: ${props.theme.colors.gradient100};`;
        case 'light':
          return `background-color: ${props.theme.colors.primary800};`;
        default:
          return 'border-radius: 0px;';
      }
    }};
  }
`;

const DrawerBackgroundFix = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 50%;
  transition: all 0s;
  transition-delay: 0.2s;
  opacity: 0;
  pointer-events: none;
  ${(props) =>
    props.visible &&
    css`
      right: -20px;
      opacity: 1;
    `};
  background-color: transparent;
`;

const LeftDrawerContent = styled.div<{ visible: boolean; gridGap: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - ${(props) => (props.visible ? 0 : props.gridGap)}px);
  transition: all 0s;
  transition-delay: 0.2s;
  box-sizing: border-box;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    #30323d,
    ${(props) => props.theme.colors.gradient100}
  );
  ${(props) =>
    props.visible
      ? css`
          border-bottom-left-radius: 14px;
          border-top-left-radius: 14px;
        `
      : css`
          border-radius: 14px;
        `}
`;

const DrawerButton = styled.div<{ gridGap: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
  right: 100%;
  margin-right: -1px;
  padding: 12px 4px 15px;
  font-size: 12px;
  text-align: center;
  width: ${(props) => props.gridGap - 5}px;
  min-height: 150px;
  transition: color 0.3s ease;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  user-select: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white70};
  span {
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  &:hover {
    color: ${(props) => props.theme.colors.white87};
  }
`;

export default Surface;
