import { EditorState, Transaction } from 'prosemirror-state';
import { Node } from 'prosemirror-model';
import UICommand from '../../toolbar/commands/UICommand';
import { LIST_NUMBERING } from '../../schema/nodes/nodeNames';

class CustomListToggleCommand extends UICommand {
  constructor() {
    super();
  }

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, tr, selection } = state;
    const { from, to } = selection;
    let modified = false;

    const claimParts = [] as { node: Node; pos: number }[];
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (node.type === schema.nodes.claimPart) {
        claimParts.push({ node, pos });
      }
    });

    // Process claimPart nodes in reverse order to avoid position shifting issues
    for (let i = claimParts.length - 1; i >= 0; i--) {
      const { node, pos } = claimParts[i];

      tr.setNodeMarkup(tr.mapping.map(pos), null, {
        ...node.attrs,
        numberingType: 'custom',
        indentLevel: 1,
        numberingPrefix: 'R<sup>1</sup>',
        numberingTemplate: 'R<sup>1</sup>',
      });
      const textNode1 = schema.text('R');
      const superscriptMark = schema.marks.superscript.create();
      const textNode2 = schema.text('1', [superscriptMark]);

      const listNumberingNode = schema.nodes[LIST_NUMBERING].create(null, [
        textNode1,
        textNode2,
      ]);

      const insertPos = tr.mapping.map(pos + 1);
      tr.insert(insertPos, listNumberingNode);
      modified = true;
    }

    if (!modified) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

export default CustomListToggleCommand;
