import { useContext } from 'react';
import { ListConfigurationContext } from './types';
import { LocalConfigurationContext } from './LocalConfigurationProvider';
import { RemoteConfigurationContext } from './RemoteConfigurationProvider';

const useListConfiguration = (): ListConfigurationContext | null => {
  const localConfigurationContext = useContext(LocalConfigurationContext);
  const remoteConfigurationContext = useContext(RemoteConfigurationContext);
  return localConfigurationContext || remoteConfigurationContext;
};

export default useListConfiguration;
