import { BaseDto, BlobResource, BaseProcess } from './common';
import { Language } from './language';

export interface PageLocation {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface DocumentLocation {
  pageNumber: number;
  pageLocation: PageLocation;
}

export interface DocumentNavigationLink {
  pageIndex: number;
  label: string;
  uri: string;
}

export interface AnnotatedPageLocation extends DocumentLocation {
  text?: string;
  annotationSetTitle?: string;
  navigationLinks: DocumentNavigationLink[];
}

export interface Annotation {
  propertyId?: string | null;
  popUpText: string;
  annotationSetTitle?: string;
  locations: DocumentLocation[];
  navigationLinks: DocumentNavigationLink[];
}

export enum DocumentFormat {
  PDF = 'pdf',
  DOCX = 'docx',
}

export interface SelectableText {
  text: string;
  location: PageLocation;
}

export enum DocumentSource {
  None = 'None',
  Imported = 'Imported',
  Epo = 'Epo',
  PatBase = 'PatBase',
  Google = 'Google',
  Generated = 'Generated',
  FormatConversion = 'FormatConversion',
  Translation = 'Translation',
}

export enum TranslationStateErrorCode {
  ServiceNotEnabled = 'ServiceNotEnabled',
  ThirdPartyServiceDown = 'ThirdPartyServiceDown',
  ServiceMonthlyLimitExceeded = 'ServiceMonthlyLimitExceeded',
  ServiceSingleRequestLimitExceeded = 'ServiceSingleRequestLimitExceeded',
}

export interface DocumentMetadata extends BaseDto {
  source: DocumentSource;
  name: string;
  parentDocument?: DocumentMetadata;
  numPages?: number;
  contentLanguage: Language;
  uri: string;
  pdfUri?: string;
  thumbnailUri?: string;
  selectableTextUris?: string[] | null;
  annotations?: Annotation[] | null;
  blobResource?: BlobResource;
  translatedPageIndices?: null | number[];
  width?: number;
  height?: number;
}

export type DocTranslations =
  | { [key in Language]?: DocumentMetadata }
  | Record<string, never>;

export interface DocumentListItem {
  name: string;
  documents: DocumentMetadata[];
  subLists: DocumentListItem[];
}

export interface DocumentOcrProcess extends BaseProcess {
  documentId?: string;
}
