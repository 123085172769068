import React, { useState } from 'react';
import { Dropdown } from 'antd';
import styled, { css } from 'styled-components';
import { FilterType, FilterValue } from '../../types';
import { Icon } from '../Icon';
import ColumnFilterDropdown from './ColumnFilterDropdown';

interface Props {
  columnKey: string;
  type: FilterType;
  value: FilterValue[] | undefined;
  hideFilter?: boolean;
  onApplyFilter: (columnKey: string, value: FilterValue[]) => void;
}

const ColumnFilter: React.FC<Props> = ({
  columnKey,
  type,
  value,
  hideFilter,
  onApplyFilter,
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const isActive = value != null && value.length > 0;

  if (hideFilter) {
    return null;
  }

  return (
    <Dropdown
      open={menuOpen}
      onOpenChange={(v) => setMenuOpen(v)}
      trigger={['click']}
      overlay={
        <ColumnFilterDropdown
          columnKey={columnKey}
          type={type}
          isOpen={menuOpen}
          value={value}
          onApplyFilter={onApplyFilter}
          setMenuOpen={setMenuOpen}
        />
      }
      placement="bottomCenter"
    >
      <Trigger
        onClick={() => setMenuOpen(true)}
        active={isActive}
        data-testid={`list-filter-${columnKey}`}
      >
        <Icon
          size={16}
          className={isActive ? 'icn-filter' : 'icn-filter-outlined'}
        />
      </Trigger>
    </Dropdown>
  );
};

export default ColumnFilter;

const Trigger = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary100};
  &:hover {
    color: white;
  }
  i {
    transition: color 0.3s ease;
  }
  ${(props) =>
    props.active &&
    css`
      color: white;
    `}
`;
