import { iposApi } from '../api/base';

export function updateObject<T>(original: T, updated: Partial<T>): T {
  return { ...original, ...updated };
}

export async function asyncMap<T, U>(
  array: T[],
  asyncCallback: (item: T, index: number, array: T[]) => Promise<U>
): Promise<U[]> {
  const results: U[] = [];

  for (let i = 0; i < array.length; i++) {
    const result = await asyncCallback(array[i], i, array);
    results.push(result);
  }

  return results;
}

export function resolveIposApiUri(src: string) {
  return `${iposApi}/v1${src}`;
}

// Parse string as enum
export function parseEnum<T extends string>(
  str: string | undefined | null,
  enumObj: { [key: string]: T }
): T | undefined {
  if (str && Object.values(enumObj).includes(str as T)) {
    return str as T;
  }
}

export function replaceAllOccurrences(
  text: string,
  search: string,
  replacement: string
): string {
  const regex = new RegExp(search, 'g');
  return text.replace(regex, replacement);
}
