import { BrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';
import IposTheme from '../theme';
import { msalInstance } from '../auth/msalInstance';
import { queryClient } from '../services/queryClient';
import { PageLoad } from '../components';
import { UserSessionQueryProvider } from '../contexts';
import { ApplicationProvider } from '../contexts/ApplicationProvider';
import { ApplicationIdentifier } from '../types';

interface Props {
  appContext: ApplicationIdentifier;
}

const ProviderWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  appContext,
}) => (
  <Suspense fallback={<PageLoad fixed />}>
    <ApplicationProvider appContext={appContext}>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <UserSessionQueryProvider>
              <ThemeProvider theme={IposTheme}>{children}</ThemeProvider>
            </UserSessionQueryProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </MsalProvider>
    </ApplicationProvider>
  </Suspense>
);

export default ProviderWrapper;
