import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip, message } from 'antd';
import { EditorState, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Colors } from '../../../../theme';
import useTranslation from '../../../../translations';
import isElementFullyVisible from '../../toolbar/util/isElementFullyVisible';
import bindScrollHandler from '../../toolbar/util/bindScrollHandler';
import { selectReference } from '../claimReferenceMenu/referencePlugin';
import { commentPluginKey } from '../comment/commentPlugin';

interface Props {
  editorState: EditorState;
  editorView: EditorView;
  anchor: HTMLElement | null;
  onClose: () => void;
}

const TOOLTIP_STYLE = {
  maxWidth: '130px',
  color: Colors.white87,
  padding: '5px 8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
  marginBottom: '-7px',
};

const toolButtonsConfig = [
  {
    icon: 'icn-reference',
    action: 'ADD_NEW_REFERENCE',
    tooltipKey: 'CLAIMS_PLUGIN.ADD_NEW_REFERENCE',
  },
  { icon: 'icn-copy', action: 'COPY', tooltipKey: 'ACTION.COPY' },
  {
    icon: 'icn-comment',
    action: 'ADD_NEW_COMMENT',
    tooltipKey: 'ACTION.COMMENT',
  },
];

const Toolbar: React.FC<Props> = ({
  editorState,
  editorView,
  anchor,
  onClose,
}) => {
  const t = useTranslation();

  const [showToolbar, setShowToolbar] = useState(true);

  useEffect(() => {
    const handleToolbarVisibility = () => {
      setShowToolbar(isElementFullyVisible(anchor));
    };

    handleToolbarVisibility();
    if (anchor) {
      bindScrollHandler(anchor, handleToolbarVisibility);
    }
  }, [anchor]);

  const setCursorAfterSelection = (to: number) => {
    const tr = editorView.state.tr;
    const newPos = to;
    tr.setSelection(TextSelection.create(tr.doc, newPos));
    editorView.dispatch(tr);
    editorView.focus();
  };

  const handleAction = (action: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    const { from, to } = editorState.selection;
    const textSelected = editorState.doc.textBetween(from, to, ' ');

    switch (action) {
      case 'ADD_NEW_REFERENCE':
        selectReference({
          view: editorView,
          from,
          to,
          isNewOption: true,
          ref: textSelected,
        });
        break;
      case 'ADD_NEW_COMMENT':
        editorView.dispatch(
          editorView.state.tr
            .setSelection(editorView.state.selection)
            .setMeta(commentPluginKey, { isOpen: true })
        );
        onClose();
        editorView.focus();
        break;
      case 'COPY':
        navigator.clipboard
          .writeText(textSelected)
          .then(() => message.success(t('COPIED_TO_CLIPBOARD')))
          .catch(() => message.error(t('FAILED_TO_COPY_CLIPBOARD')));
        setCursorAfterSelection(to);
        break;
      default:
        console.log('Unknown action');
    }
  };

  return (
    <>
      {showToolbar && (
        <ButtonWrapper>
          {toolButtonsConfig.map((btn, index) => (
            <Tooltip
              key={index}
              placement="top"
              title={t(btn.tooltipKey)}
              overlayInnerStyle={TOOLTIP_STYLE}
              color={Colors.primary300}
            >
              <StyledIcon
                className={btn.icon}
                onClick={(e) => handleAction(btn.action, e)}
              />
            </Tooltip>
          ))}
        </ButtonWrapper>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary200};
  padding: 3px;
  border-radius: 5px;
`;

const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.white87};
  width: 30px;
  height: 30px;
  &:hover {
    background: ${(props) => props.theme.colors.white20};
  }
`;

export default Toolbar;
