import React, { useState } from 'react';
import { Button } from 'antd';
import SaveDescriptionModal from './SaveDescriptionModal';
import { Permissions, T } from '@ipos/shared';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  disabled: boolean;
  disabledLanguages: T.Language[];
}

const AddDescriptionButton: React.FC<Props> = ({
  disabled,
  disabledLanguages,
}) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={handleOpenRequest} type="primary" disabled={disabled}>
        {t('DESCRIPTIONS.ADD_NEW_BUTTON')}
      </Button>
      {isModalOpen && (
        <SaveDescriptionModal
          disabledLanguages={disabledLanguages}
          onCloseRequest={handleCloseRequest}
        />
      )}
    </WithPermission>
  );
};

export default AddDescriptionButton;
