import React, { useState } from 'react';
import { Permissions } from '@ipos/shared';
import { CreateTsdElementModal } from 'components/TsdElement';
import { MenuBarOption } from 'components/common';
import useTranslation from 'translations';

const { WithPermission } = Permissions;

interface Props {
  tsdId: string;
  tsdNodeId: string;
}

const AddTsdNodeMenuOption: React.FC<Props> = ({ tsdId, tsdNodeId }) => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOptionClick = () => setModalOpen(true);
  const handleCloseRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <MenuBarOption
        onClick={handleOptionClick}
        iconClassName="icn-add"
        title={t('CREATE_ELEMENT.ADD_NODE')}
      />
      <CreateTsdElementModal
        tsdId={tsdId}
        parentNodeId={tsdNodeId}
        isOpen={isModalOpen}
        onCloseRequest={handleCloseRequest}
      />
    </WithPermission>
  );
};

export default AddTsdNodeMenuOption;
