import React from 'react';
import { useMutation } from 'react-query';
import { message, notification } from 'antd';
import TsdElementAttributesPane from '../TsdElementAttributesPane';
import AddCompaniesButton from './AddCompaniesButton';
import CompaniesList from './CompaniesList';
import { Api, Components, Hooks } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTranslation from 'translations';
import { QueryKey, useTsdElementCompanyGroups } from 'queries';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';

const { Notification } = Components;
const { useInvalidateQueries } = Hooks;

const Companies: React.FC = () => {
  const t = useTranslation();
  const { invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const { preferredLanguage } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const companiesQuery = useTsdElementCompanyGroups(tsdElementId, tsdNodeId);

  const deleteCompanyMutation = useMutation(
    (tsdElementCompanyGroupId: string) =>
      Api.tsd.deleteTsdElementCompanyGroup(
        tsdElementId,
        tsdElementCompanyGroupId
      ),
    {
      onSuccess: () => {
        message.success(t('COMPANIES.DELETE_SUCCESS'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementCompanies, tsdElementId, tsdNodeId]
        );
      },
      onError: () => {
        notification.error({
          message: t('ATTRIBUTES.DELETE_FAIL'),
          description: t('COMPANIES.DELETE_FAIL'),
        });
      },
    }
  );

  const handleRemoveRequest = (tsdElementCompanyGroupId: string) => {
    applyChange(() => deleteCompanyMutation.mutate(tsdElementCompanyGroupId));
  };

  if (companiesQuery.isLoading || companiesQuery.isIdle) {
    return <Notification spin spinSize="large" />;
  }

  if (companiesQuery.isError) {
    return <Notification error />;
  }

  const { data: companies } = companiesQuery;

  return (
    <TsdElementAttributesPane
      showWarning={!companies.length}
      warningText={t('COMPANIES.NO_COMPANY_YET')}
    >
      <div>
        <AddCompaniesButton
          tsdElementCompanyGroupIds={companies.map((i) => i.companyGroupId)}
        />
      </div>
      <CompaniesList data={companies} onRemoveRequest={handleRemoveRequest} />
    </TsdElementAttributesPane>
  );
};

export default Companies;
