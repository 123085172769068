import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Page } from 'react-pdf';
import { transparentize } from 'polished';
import { FixedSizeList as List } from 'react-window';
import { Normal } from '../Atoms/Atoms';
import { useTranslation } from '../../translations';
import { Icon } from '../Icon';

interface Props {
  totalNumberOfPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const ThumbnailPreview: React.FC<Props> = ({
  totalNumberOfPages,
  currentPage,
  setCurrentPage,
}) => {
  const t = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const thumbnailsListRef = useRef<HTMLDivElement>(null);

  const updateListHeight = () => {
    if (thumbnailsListRef.current) {
      let height = thumbnailsListRef.current.clientHeight;
      if (height === 0) {
        height = 600;
      }

      setListHeight(height);
    }
  };

  if (expanded && listHeight === 0) {
    setTimeout(() => {
      updateListHeight();
    }, 500);
  }

  useEffect(() => {
    window.addEventListener('resize', updateListHeight);
    return () => window.removeEventListener('resize', updateListHeight);
  }, []);

  const ThumbnailItem = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => (
    <PageWrapper style={style} onClick={() => setCurrentPage(index + 1)}>
      <Page
        loading="Loading..."
        renderTextLayer={false}
        renderAnnotationLayer={false}
        className={`thumbnail-page-preview${
          currentPage === index + 1 ? ' thumbnail-page-preview--current' : ''
        }`}
        height={100}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
      />
      <Normal>{index + 1}</Normal>
    </PageWrapper>
  );

  return (
    <Outer expanded={expanded}>
      <ThumbnailsListWrapper expanded={expanded}>
        <CloseIcon
          className="icn-clear"
          onClick={() => setExpanded(false)}
          transition
        />
        <ThumbnailsText>{t('PAGE_THUMBNAILS')}</ThumbnailsText>
        <ThumbnailsList ref={thumbnailsListRef}>
          <List
            key={listHeight}
            height={listHeight}
            itemCount={totalNumberOfPages}
            itemSize={100}
            width={100}
          >
            {ThumbnailItem}
          </List>
        </ThumbnailsList>
      </ThumbnailsListWrapper>
      {!expanded && (
        <IconWrapper onClick={() => setExpanded(true)}>
          <OpenIcon className="icn-files-outlined" transition />
        </IconWrapper>
      )}
    </Outer>
  );
};

const CloseIcon = styled(Icon)`
  font-size: 24px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.primary700};
  &:hover {
    color: ${(props) => props.theme.colors.white40};
  }
`;

const OpenIcon = styled(Icon)`
  font-size: 24px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.primary100};
`;

const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  &:hover {
    ${OpenIcon} {
      color: ${(props) => props.theme.colors.primary400};
    }
  }
`;

const ThumbnailsText = styled(Normal)`
  font-weight: 500;
  margin-bottom: 28px;
  margin-top: 12px;
  color: ${(props) => props.theme.colors.primary700};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThumbnailsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  align-self: center;
  padding: 0 5px;
`;

const ThumbnailsListWrapper = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
  padding: 12px;
  height: 100%;
`;

const Outer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.primary700};
  position: absolute;
  z-index: 10;
  top: ${(props) => (props.expanded ? 0 : 40)}px;
  width: ${(props) => (props.expanded ? 'auto' : 48)}px;
  max-width: 180px;
  height: ${(props) => (props.expanded ? '100%' : '48px')};
  cursor: pointer;
  min-height: 0;
  border-radius: ${(props) => (props.expanded ? 0 : '0 6px 6px 0')};
  background-color: ${(props) =>
    props.expanded
      ? transparentize(0.45, props.theme.colors.primary700)
      : transparentize(0.8, props.theme.colors.primary700)};
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
`;

export default ThumbnailPreview;
