import { useEffect } from 'react';

interface Parameters {
  target?: HTMLElement | null;
  onIntersect: () => void;
  root?: HTMLElement | null;
  rootMargin?: string;
  threshold?: number;
  enabled?: boolean;
}

const useIntersectionObserver = ({
  target,
  onIntersect,
  root,
  rootMargin = '0px',
  threshold = 1.0,
  enabled = true,
}: Parameters): void => {
  useEffect(() => {
    if (!enabled || !target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root,
        rootMargin,
        threshold,
      }
    );
    observer.observe(target);
    return () => {
      observer.disconnect();
    };
  }, [enabled, target, onIntersect, root, rootMargin, threshold]);
};

export default useIntersectionObserver;
