import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { ApplicationIdentifier } from '../../../types';
import useTranslation from '../../../translations';

interface Props {
  appContext?: ApplicationIdentifier;
}

const VERTICAL_TITLES = [
  ApplicationIdentifier.EVALUATOR,
  ApplicationIdentifier.COMPETITOR,
  ApplicationIdentifier.PATENTOR,
];

const AppTitle: React.FC<Props> = ({ appContext }) => {
  const t = useTranslation();

  if (!appContext) {
    return null;
  }

  const shouldRotate = VERTICAL_TITLES.includes(appContext);

  const appName = (
    <Trans
      t={t}
      i18nKey={`STYLED_APP.${appContext.toUpperCase()}`}
      components={{
        Regular: <Regular />,
        Bold: <Bold />,
      }}
    />
  );

  return (
    <TitleWrapper hasPadding={!shouldRotate}>
      <Title shouldRotate={shouldRotate}>{appName}</Title>
    </TitleWrapper>
  );
};

export default AppTitle;

const TitleWrapper = styled.div<{ hasPadding: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
`;

const Title = styled.div<{ shouldRotate: boolean }>`
  text-align: center;
  ${(props) =>
    props.shouldRotate &&
    `
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  `}
`;

const Regular = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.colors.white87};
  font-weight: 300;
  line-height: normal;
`;

const Bold = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.colors.white87};
  font-weight: bold;
  line-height: normal;
`;
