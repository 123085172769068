import { T } from '../..';
import { BaseDto, Contact } from './common';
import { FigureImage, PatentRevisionPreview } from './patents';

export interface PatentApplicationInvention extends BaseDto {
  evaluationIds: string[];
  inventionDocumentIds: string[];
  patentApplicationFamilyId: string;
  tsdNodeIds: string[];
}

export interface PatentDraft extends BaseDto {
  applicantReference: string | null;
  applicationDocumentId: string | null;
  lawFirmReference: string | null;
  projectReference: string | null;
  productReference: string | null;
  title: string | null;
  patentOffice: T.PatentOffice;
  mostImportantAspect: string | null;
  language: T.PatentDraftLanguage;
  dueDate: string | null;
  engineeringDomain: T.EngineeringDomain;
  patentLifeCycleState: T.PatentLifeCycleState;
  filingType: T.FilingType;
  filedDate: Date | null;
  stakeholders: T.PatentApplicationStakeholder[];
  applicationDateAndIdentifier: string | null;
  leadInventor: T.Contact;
  currentState: string;
  costCenter: string | null;
  patentRevisions: PatentRevisionPreview[];
  inventions: PatentApplicationInvention[];
}

export interface UpdatePatentDraft {
  applicantReference: string | null;
  lawFirmReference: string | null;
  title: string | null;
  patentOffice: T.PatentOffice;
  language: T.PatentDraftLanguage;
  dueDate: string | null;
  costCenter: string | null;
}

export interface PatentDraftListFilters {
  applicants: Contact[];
  attorneys: Contact[];
}

export enum PatentDraftLanguage {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
}

export enum PatentDraftState {
  Overview = 'Overview',
  Drafting = 'Drafting',
  EditApplication = 'EditApplication',
}

export interface PatentDraftListItem extends BaseDto {
  applicantReference: string | null;
  applicants: T.Contact[];
  lawFirmReference: string | null;
  projectReference: string | null;
  title: string | null;
  patentOffice: T.PatentOffice;
  mostImportantAspect: string | null;
  language: T.PatentDraftLanguage;
  dueDate: string | null;
  engineeringDomain: T.EngineeringDomain;
  patentLifeCycleState: T.PatentLifeCycleState;
  filingType: T.FilingType;
  filedDate: Date | null;
  stakeholders: T.PatentApplicationStakeholderDisplayNameAndRole[];
  applicationDateAndIdentifier: string | null;
  leadInventor: T.Contact;
  currentState: string;
  costCenter: string | null;
  patentRevisions: PatentRevisionPreview[];
  inventions: PatentApplicationInvention[];
  responsibleCouncel: T.Contact | null;
  tsDs: string[] | null;
  figureImages: FigureImage[];
}

export enum PatentDraftScreen {
  Claims = 'claims',
  Figures = 'figures',
  ClaimReviews = 'claimReview',
  Description = 'description',
  FinalReview = 'finalReview',
}

export interface ReferenceOption {
  id?: string;
  text: string;
}
