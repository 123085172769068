import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PatentOffice } from '../../types';
import { ReactComponent as Epo } from '../../../assets/patentOffice/EPO.svg';
import { ReactComponent as Germany } from '../../../assets/patentOffice/germany.svg';
import { ReactComponent as Usa } from '../../../assets/patentOffice/usa.svg';
import { ReactComponent as Pct } from '../../../assets/patentOffice/pct.svg';
import { ReactComponent as Cnipa } from '../../../assets/patentOffice/CNIPA.svg';
import { ReactComponent as Japan } from '../../../assets/patentOffice/japan.svg';
import { ReactComponent as Korea } from '../../../assets/patentOffice/korea.svg';
import { ReactComponent as Taiwan } from '../../../assets/patentOffice/taiwan.svg';

const IconStyles = {
  height: '25px',
  width: '25px',
  display: 'flex',
  alignItems: 'center',
};

interface Props {
  office: PatentOffice;
  size?: number;
  showLabel?: boolean;
  direction?: 'vertical' | 'horizontal';
  iconStyleOverWrites?: CSSProperties;
}

const PatentOfficeIcon: React.FC<Props> = ({
  office,
  showLabel,
  size,
  direction = 'vertical',
  iconStyleOverWrites = {},
}) => {
  const iconStyle: CSSProperties = {
    ...IconStyles,
    ...iconStyleOverWrites,
  };
  if (size !== undefined) {
    iconStyle.minHeight = `${size}px !important`;
    iconStyle.minWidth = `${size}px !important`;
  }

  const iconMap: { [key in PatentOffice]: React.ReactNode } = {
    [PatentOffice.WO]: <Pct style={iconStyle} />,
    [PatentOffice.EPO]: <Epo style={iconStyle} />,
    [PatentOffice.USPTO]: <Usa style={iconStyle} />,
    [PatentOffice.DPMA]: <Germany style={iconStyle} />,
    [PatentOffice.CNIPA]: <Cnipa style={iconStyle} />,
    [PatentOffice.JPO]: <Japan style={iconStyle} />,
    [PatentOffice.KIPO]: <Korea style={iconStyle} />,
    [PatentOffice.TIPO]: <Taiwan style={iconStyle} />,
  };

  const icon = iconMap.hasOwnProperty(office) ? (
    iconMap[office]
  ) : (
    <Germany style={iconStyle} />
  );

  return (
    <Outer direction={direction}>
      {icon}
      {showLabel && (
        <PatentOfficeLabel direction={direction}>{office}</PatentOfficeLabel>
      )}
    </Outer>
  );
};

export default PatentOfficeIcon;

const PatentOfficeLabel = styled.div<{ direction: 'vertical' | 'horizontal' }>`
  margin-left: ${({ direction }) => (direction === 'horizontal' ? '6px' : '0')};
  margin-top: ${({ direction }) => (direction === 'vertical' ? '10px' : '0')};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white87};
  font-size: 15px;
`;

const Outer = styled.div<{ direction: 'vertical' | 'horizontal' }>`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === 'vertical' ? 'column' : 'row'};
  align-items: center;
`;
