import React from 'react';
import { Modal, Space } from 'antd';
import { TsdTreeTags } from 'components/TsdElement';
import useTranslation from 'translations';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useTsdElementUsage } from 'queries';

const useElementUsageCheck = (): {
  applyChange: (executeChange: () => void) => void;
} => {
  const t = useTranslation();

  const {
    tsdElement: { id, frequency },
    isLibraryView,
  } = useTsdElementContext();

  const { data: tsds } = useTsdElementUsage(id);

  const getModalContent = () => {
    if (tsds === undefined) {
      return t('CONFIRM_ACTION.CONTENT_FALLBACK', { frequency });
    }

    return (
      <Space direction="vertical">
        {t('CONFIRM_ACTION.CONTENT')}
        <TsdTreeTags tsdNames={tsds.map((t) => t.tsdName)} />
        {t('CONFIRM_ACTION.PROMPT')}
      </Space>
    );
  };

  const applyChange = (executeChange: () => void) => {
    if (isLibraryView && frequency > 0) {
      Modal.confirm({
        title: t('CONFIRM_ACTION.TITLE'),
        content: getModalContent(),
        okText: t('YES'),
        cancelText: t('ACTION.CANCEL'),
        onOk() {
          executeChange();
        },
        width: 530,
        centered: true,
      });
    } else {
      executeChange();
    }
  };

  return {
    applyChange,
  };
};

export default useElementUsageCheck;
