import { EventType } from '@azure/msal-browser';
import { createMachine } from 'xstate';
import { DoneInvokeEvent } from 'xstate/lib/types';
import { AxiosError } from 'axios';
import { HttpStatusCode, Session } from '../../types';
import { inIframe } from '../../utils/location';
import { isAuthLoaded } from '../../utils/auth';
import { QueryKey } from '../../queries';
import { authRequest, msalInstance } from '../../auth/msalInstance';
import { Api, queryClient } from '../../..';

interface AuthenticationState {
  session: undefined | Session;
  isIFrameInitialized: boolean;
}

// Authentication Events

const INIT_IFRAME = 'INIT_IFRAME';
const SET_SESSION = 'SET_SESSION';

type LoginSuccess = {
  type: typeof EventType.LOGIN_SUCCESS;
};

type RedirectStart = {
  type: typeof EventType.HANDLE_REDIRECT_START;
};
type AcquireTokenFailure = {
  type: typeof EventType.ACQUIRE_TOKEN_FAILURE;
};

type RedirectEnd = {
  type: typeof EventType.HANDLE_REDIRECT_END;
};

type InitIframeEvent = {
  type: typeof INIT_IFRAME;
};

export type SetSessionData = {
  type: typeof SET_SESSION;
  data: Session;
};

export type AuthenticationEvents =
  | InitIframeEvent
  | SetSessionData
  | RedirectStart
  | RedirectEnd
  | AcquireTokenFailure
  | LoginSuccess;

// Authentication States
export const Initial = 'INITIAL';
export const InitializingMsal = 'INITIALIZING_MSAL';
export const IframeInitialized = 'IFRAME_INITIALIZED';
export const LoggedIn = 'LOGGED_IN';
export const LoggedOut = 'LOGGED_OUT';
export const AcquiringSilentToken = 'ACQUIRING_SILENT_TOKEN';
export const SessionError = 'SESSION_ERROR';
export const BackendUnreachable = 'BACKEND_UNREACHABLE';

const authenticationStateMachine = createMachine<
  AuthenticationState,
  AuthenticationEvents
>(
  {
    id: 'authentication',
    initial: InitializingMsal,
    context: {
      isIFrameInitialized: false,
      session: undefined,
    },
    states: {
      [InitializingMsal]: {
        id: InitializingMsal,
        on: {
          [EventType.HANDLE_REDIRECT_START]: {
            target: InitializingMsal,
          },
          [EventType.LOGIN_SUCCESS]: {
            target: Initial,
          },
          [EventType.HANDLE_REDIRECT_END]: {
            target: Initial,
          },
        },
      },
      [Initial]: {
        id: Initial,
        invoke: {
          src: () => () => {
            if (inIframe()) {
              return isAuthLoaded();
            } else {
              return Promise.resolve();
            }
          },
          onDone: [
            { actions: 'setIframeInitialized', target: IframeInitialized },
          ],
        },
      },
      [IframeInitialized]: {
        id: IframeInitialized,
        on: {
          [EventType.ACQUIRE_TOKEN_FAILURE]: {
            actions: 'clearSession',
            target: LoggedOut,
          },
        },
        invoke: {
          src: () => () =>
            queryClient.fetchQuery<Session, AxiosError>(
              QueryKey.Session,
              Api.session.getSession,
              {
                retry: (attemptIndex, error) =>
                  attemptIndex < 5 &&
                  error?.response?.status !== HttpStatusCode.UNAUTHORIZED,
                retryDelay: (attemptIndex) => 2 ** (attemptIndex + 1) * 100,
              }
            ),
          onError: [
            {
              cond: 'isAuthenticationError',
              actions: 'clearSession',
              target: SessionError,
            },
            {
              cond: 'isBackendUnreachableError',
              target: BackendUnreachable,
            },
            {
              target: LoggedOut,
            },
          ],
          onDone: [{ actions: 'setSession', target: LoggedIn }],
        },
      },
      [BackendUnreachable]: {
        id: BackendUnreachable,
        type: 'final',
      },
      [SessionError]: {
        id: SessionError,
        always: [{ target: AcquiringSilentToken }],
      },
      [AcquiringSilentToken]: {
        invoke: {
          src: () => () => {
            const accounts = msalInstance.getAllAccounts();
            if (!accounts.length) {
              return Promise.reject();
            }
            return msalInstance.acquireTokenSilent({
              ...authRequest,
              account: accounts[0],
            });
          },
          onError: [
            {
              actions: 'clearSession',
              target: LoggedOut,
            },
          ],
          onDone: [{ target: IframeInitialized }],
        },
      },
      [LoggedIn]: {
        type: 'final',
        id: LoggedIn,
      },
      [LoggedOut]: {
        type: 'final',
        id: LoggedOut,
      },
    },
  },
  {
    guards: {
      isAuthenticationError: (context, event) => {
        const authenticationEvent = (event as DoneInvokeEvent<AxiosError>)
          ?.data;
        if (authenticationEvent?.isAxiosError) {
          if (
            authenticationEvent.response?.status === HttpStatusCode.UNAUTHORIZED
          ) {
            return true;
          }
        }
        return false;
      },
      isBackendUnreachableError: (context, event) => {
        const authenticationEvent = (event as DoneInvokeEvent<AxiosError>)
          ?.data;
        if (authenticationEvent?.isAxiosError) {
          if (
            authenticationEvent.response?.status !== HttpStatusCode.UNAUTHORIZED
          ) {
            return true;
          }
        }
        return false;
      },
    },
  }
);

export default authenticationStateMachine;
