import { useState, useCallback, useEffect, ChangeEventHandler } from 'react';

const useDebouncedInput = (
  onChange: (value: string) => void,
  defaultValue = '',
  delay = 400
): [
  string,
  ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  (v: string) => void
] => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => setValue(e.target.value), []);

  useEffect(() => {
    const handlerTimer = setTimeout(() => {
      onChange(value);
    }, delay);
    return () => {
      clearTimeout(handlerTimer);
    };
  }, [value]);

  const reset = useCallback((v: string) => setValue(v ?? ''), []);

  return [value, handleChange, reset];
};

export default useDebouncedInput;
