/* eslint-disable */
import { EditorState, Selection, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { SyntheticEvent } from 'react';

export type IsActiveCall = (state: EditorState) => boolean;

export type FindNodeTypeInSelectionCall = (selection: Selection) => any;

const EventType = {
  CLICK: 'mouseup',
  MOUSEENTER: 'mouseenter',
};

function dryRunEditorStateProxyGetter(
  state: EditorState,
  propKey: string
): any {
  // @ts-ignore
  const val = state[propKey];
  if (propKey === 'tr' && val instanceof Transaction) {
    return val.setMeta('dryrun', true);
  }
  return val;
}

function dryRunEditorStateProxySetter(
  state: EditorState,
  propKey: string,
  propValue: any
): boolean {
  // @ts-ignore
  state[propKey] = propValue;
  // Indicate success
  return true;
}

class UICommand {
  private _name: string = 'ui-command';
  static EventType = EventType;
  shouldRespondToUIEvent = (e: SyntheticEvent | MouseEvent): boolean =>
    e.type === UICommand.EventType.CLICK;

  renderLabel = (state: EditorState): any => null;

  isActive = (state: EditorState): boolean => false;

  isEnabled = (state: EditorState, view?: EditorView): boolean => true;

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView,
    event?: SyntheticEvent
  ): boolean => {
    this.waitForUserInput(state, dispatch, view, event)
      .then((inputs) => {
        this.executeWithUserInput(state, inputs, dispatch, view);
      })
      .catch((error) => {
        console.error(error);
      });
    return false;
  };

  waitForUserInput = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView,
    event?: SyntheticEvent
  ): Promise<any> => Promise.resolve(undefined);

  executeWithUserInput = (
    state: EditorState,
    inputs: any,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView
  ): boolean => false;

  cancel(): void {
    // subclass should overwrite this.
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }
}

export default UICommand;
