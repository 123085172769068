import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  DocumentListItem,
  DocumentMetadata,
  DocViewerParams,
} from '../../types';
import { useDocumentProxy } from '../../queries';
import useMultipleDocumentMetadata from '../../queries/useMultipleDocumentMetadata';
import { DocViewerData } from './types';
import { findDocumentById } from './util';

const getState = (
  documents: DocumentMetadata[],
  defaultVisibleDocCount?: string,
  startingPages?: string
): DocViewerData => ({
  documents,
  docCountToShow:
    (defaultVisibleDocCount && parseInt(defaultVisibleDocCount, 10)) ||
    documents.length,
  startAtPage: startingPages?.split(',').map(Number),
  searchTerm: [],
});

interface HookShape {
  (
    props: DocViewerParams & {
      setState: Dispatch<SetStateAction<DocViewerData>>;
    }
  ): {
    documentsToSelect?: DocumentListItem[];
  };
}

const useDocViewerDocumentMetadataList: HookShape = ({
  firstDocumentId,
  secondDocumentId,
  thirdDocumentId,
  defaultVisibleDocCount,
  startingPages,
  rootId,
  applicationIdentifier,
  setState,
}) => {
  const shouldFetchList = !!rootId;

  const documentIds = useMemo(
    () =>
      [firstDocumentId, secondDocumentId, thirdDocumentId].filter(
        (d) => d !== undefined
      ) as string[],
    [firstDocumentId, secondDocumentId, thirdDocumentId]
  );

  const { data: documentsToSelect } = useDocumentProxy(
    rootId as string,
    applicationIdentifier,
    {
      enabled: shouldFetchList,
      onSuccess: (data) => {
        const documents = documentIds.map((docId) =>
          findDocumentById(data, docId)
        ) as DocumentMetadata[]; // TODO: handle documentIds missmatch (nulls)
        setState(getState(documents, defaultVisibleDocCount, startingPages));
      },
    }
  );

  // If we dont have the list, fetch the documents manually
  const queries = useMultipleDocumentMetadata(documentIds, !shouldFetchList);

  useEffect(() => {
    // TODO: handle error state when one of the queries fail
    if (shouldFetchList || queries.some((q) => !q.data)) {
      return;
    }
    const documents = queries.map((q) => q.data) as DocumentMetadata[];
    setState((prevState) => {
      const shouldUpdate = documents.some(
        (d, i) => d.id !== prevState.documents[i]?.id
      );
      return shouldUpdate
        ? getState(documents, defaultVisibleDocCount, startingPages)
        : prevState;
    });
  }, [queries, shouldFetchList, startingPages, defaultVisibleDocCount]);

  return {
    documentsToSelect,
  };
};

export default useDocViewerDocumentMetadataList;
