import React, { useCallback, useEffect, useRef } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { BaseDto } from '../../../types';

interface Props<TItem extends BaseDto> {
  children: React.ReactNode;
  data: TItem[] | null;
  last?: boolean;
  scrollableElementRef?: React.RefObject<HTMLDivElement>;
}

// This is a wrapper component that fades out the ListItem when it is scrolled out of view
const FadeOutEffect = <TItem extends BaseDto>({
  children,
  scrollableElementRef,
  data,
  last,
}: Props<TItem>) => {
  const ref = useRef<HTMLDivElement>(null);

  const tableBodyOffset = useMotionValue(0);

  const onScrollTableBody = useCallback(() => {
    if (
      !scrollableElementRef ||
      !scrollableElementRef.current ||
      !ref.current
    ) {
      return;
    }

    const tableBodyRefBounds =
      scrollableElementRef.current.getBoundingClientRect();

    if (!ref.current) {
      return;
    }

    const listItemBounds = ref.current?.getBoundingClientRect();

    if (!listItemBounds) {
      return;
    }

    const difference = Math.min(
      100,
      Math.max(-200, listItemBounds.bottom - tableBodyRefBounds.bottom)
    );

    tableBodyOffset.set(difference);
  }, []);

  useEffect(() => {
    if (!scrollableElementRef || !scrollableElementRef.current) {
      return;
    }

    onScrollTableBody();

    scrollableElementRef.current.addEventListener('scroll', onScrollTableBody);
    window.addEventListener('resize', onScrollTableBody);

    return () => {
      scrollableElementRef?.current?.removeEventListener(
        'scroll',
        onScrollTableBody
      );
      window.removeEventListener('resize', onScrollTableBody);
    };
  }, [onScrollTableBody, data]);

  const opacity = useTransform(
    tableBodyOffset,
    last ? [-50, 100] : [-200, 100],
    [1, 0]
  );

  return (
    <motion.div style={{ opacity }} ref={ref}>
      {children}
    </motion.div>
  );
};

export default FadeOutEffect;
