import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { InputRef } from 'antd/lib/input/Input';
import { Icon } from '../Icon';
import { useDebouncedInput } from '../../hooks';
import useTranslation from '../../translations';

const PRIMARY_VARIANT = 'primary';
const SECONDARY_VARIANT = 'secondary';

type Variant = typeof PRIMARY_VARIANT | typeof SECONDARY_VARIANT;

interface Props {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  focusOnMount?: boolean;
  hideStartIcon?: boolean;
  variant?: Variant;
}

const SearchInput: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  focusOnMount,
  hideStartIcon,
  variant = PRIMARY_VARIANT,
}) => {
  const t = useTranslation();
  const inputRef = useRef<InputRef>(null);
  const [debouncedValue, handleDebouncedChange, reset] = useDebouncedInput(
    onChange,
    value
  );

  // TODO: double-check this logic when unifying this component
  useEffect(() => {
    if (value !== debouncedValue) {
      reset(value ?? '');
    }
  }, [reset, value]);

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  }, [focusOnMount]);

  return (
    <StyledInput
      onClick={(e) => e.preventDefault()}
      data-testid="list-search-input"
      value={debouncedValue}
      onChange={handleDebouncedChange}
      placeholder={placeholder ?? t('SEARCH')}
      prefix={!hideStartIcon && <Icon className="icn-search" size={20} />}
      allowClear
      ref={inputRef}
      variant={variant}
    />
  );
};

export default SearchInput;

const StyledInput = styled(Input)<{ variant?: Variant }>`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: inherit;
  color: ${(props) => props.theme.fonts.colors.b1};
  font-size: ${(props) => props.theme.fonts.sizes.b1}px;
  input {
    border: none;
    background-color: transparent;
  }
  i {
    color: ${(props) => props.theme.colors.white70};
  }
  ${(props) =>
    props.variant === SECONDARY_VARIANT &&
    css`
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 10px 12px;
      &:hover,
      &:focus,
      &:active {
        border: none;
      }
      &.ant-input-affix-wrapper {
        border: none;
        width: auto;
        flex-grow: 2;
      }
      & .ant-input-suffix {
        display: none;
      }
    `}
`;
