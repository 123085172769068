import React from 'react';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationTsdEditorsCount } from 'queries';

const { Visualizations } = Components;
const { Number } = Visualizations;

const TsdEditorsCount: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationTsdEditorsCount();

  if (!data) {
    return null;
  }

  return <Number value={data?.y} label={t('STATS.EDITORS_COUNT')} />;
};

export default TsdEditorsCount;
