import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import {
  useGetPatstatApplicantsInventors,
  useGetPatstatCpcClasses,
  useGetPatstatIpcClasses,
} from '../../../queries';
import Modal from '../../Modal';
import PatstatStatisticsOverview from './PatstatStatisticsOverview';

interface Props {
  publicationNumber: string;
  onCloseRequest: () => void;
}

const PatstatStatisticsModal: React.FC<Props> = ({
  publicationNumber,
  onCloseRequest,
}) => {
  const t = useTranslation();

  const { data: stakeholders, isLoading: isLoadingStakeholders } =
    useGetPatstatApplicantsInventors(publicationNumber);

  const { data: cpcclasses, isLoading: isLoadingCpcClasses } =
    useGetPatstatCpcClasses(publicationNumber);

  const { data: ipcclasses, isLoading: isLoadingIpcClasses } =
    useGetPatstatIpcClasses(publicationNumber);

  return (
    <Modal
      onCancel={onCloseRequest}
      title={
        <Flex>
          {t('COMPETITOR.PATSTAT_STATISTICS', {
            publicationNr: publicationNumber,
          })}
        </Flex>
      }
      width="70%"
      style={{
        maxWidth: '600px',
      }}
      bodyStyle={{
        maxHeight: '600px',
        minHeight: 0,
        display: 'flex',
        padding: 0,
      }}
      open={true}
      footer={null}
    >
      <PatstatStatisticsOverview
        publicationNumber={publicationNumber}
        stakeholders={stakeholders}
        cpcclasses={cpcclasses}
        ipcclasses={ipcclasses}
        isLoading={
          isLoadingStakeholders || isLoadingCpcClasses || isLoadingIpcClasses
        }
      />
    </Modal>
  );
};

const Flex = styled.div`
  display: flex;
`;

export default PatstatStatisticsModal;
