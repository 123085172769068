import React, { useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import {
  getCurrentLanguage,
  getDefaultLanguage,
  getLanguageByCode,
  changeLanguage,
  setLanguageInStorage,
} from '../../utils';
import CountryFlag from '../CountryFlag';
import useTranslation from '../../translations';

const { Option } = Select;

const ApplicationLanguage: React.FC = () => {
  const t = useTranslation();

  const [currentLang, setCurrentLang] = useState(() => {
    const currentLanguage = getCurrentLanguage() || getDefaultLanguage();
    return currentLanguage.languageCode;
  });

  const handleLanguageChange = async (languageCode: string) => {
    const selectedLanguage = getLanguageByCode(languageCode);
    if (!selectedLanguage) {
      return;
    }

    try {
      await changeLanguage(languageCode);
      setLanguageInStorage(selectedLanguage);
      setCurrentLang(languageCode);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Outer>
      <Select value={currentLang} onChange={handleLanguageChange}>
        <Option value="de-de">
          <Inner>
            <CountryFlag countryCode={'DE'} />
            {t('LANGUAGE.DE')}
          </Inner>
        </Option>
        <Option value="en-US">
          <Inner>
            <CountryFlag countryCode={'US'} />
            {t('LANGUAGE.EN')}
          </Inner>
        </Option>
      </Select>
    </Outer>
  );
};

export default ApplicationLanguage;

const Outer = styled.div`
  margin-right: 10px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;
