import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { DocumentPickerDisplayItem } from '../types';

interface Props {
  items: DocumentPickerDisplayItem[];
  onRemoveItem: (itemId: string) => void;
}

const getItemText = (item: DocumentPickerDisplayItem) =>
  `${item.name} (${item.count})`;

const DocumentPickerInputItems: React.FC<Props> = ({ items, onRemoveItem }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const measureItemRef = useRef<HTMLDivElement | null>(null);

  const [hiddenCount, setHiddenCount] = useState(0);
  const [visibleItems, setVisibleItems] = useState<DocumentPickerDisplayItem[]>(
    []
  );

  useEffect(() => {
    const container = containerRef.current;
    const measureItem = measureItemRef.current;
    if (!container || !measureItem) {
      return;
    }

    const containerWidth = container.clientWidth - 2; // account for padding
    let totalWidth = 0;
    let count = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      measureItem.innerText = getItemText(item);
      const itemWidth = measureItem.offsetWidth + 50 + 7; // account for extra content and gap

      totalWidth += itemWidth;
      const isLastItem = i + 1 === items.length;

      if (
        totalWidth + 30 + 7 <= containerWidth || // account for count indicator and gap
        (isLastItem && totalWidth <= containerWidth)
      ) {
        count++;
      } else {
        break;
      }
    }

    const invisibleItems = items.slice(count);
    const invisibleDocCount = invisibleItems.reduce(
      (acc, item) => acc + item.count,
      0
    );
    setHiddenCount(invisibleDocCount);
    setVisibleItems(items.slice(0, count));
  }, [items]);

  const handleRemoveItem = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    onRemoveItem(id);
  };

  return (
    <Outer ref={containerRef}>
      {visibleItems.map((item) => (
        <Item key={item.name}>
          <ItemText>{getItemText(item)}</ItemText>
          <StyledIcon
            className="icn-clear"
            onClick={(e) => handleRemoveItem(e, item.id)}
          />
        </Item>
      ))}
      {hiddenCount > 0 && (
        <Item style={{ padding: '0px 7px' }}>+{hiddenCount}</Item>
      )}
      <MeasureItem ref={measureItemRef} />
    </Outer>
  );
};

export default DocumentPickerInputItems;

const Outer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  gap: 7px;
  padding-left: 2px;
`;

const Item = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 4px 8px 4px 13px;
  gap: 5px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.primary200_20};
`;

const ItemText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
`;

const StyledIcon = styled.i`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 3px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.white60};
  &:hover {
    color: ${(props) => props.theme.colors.white70};
  }
`;

const MeasureItem = styled.div`
  position: absolute;
  z-index: -10;
  visibility: hidden;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
`;
