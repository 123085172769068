import { flatListGroup } from 'prosemirror-flat-list';
import { NodeSpec } from 'prosemirror-model';

const listNumbering: NodeSpec = {
  content: 'text*',
  group: `${flatListGroup} block`,
  attrs: {},
  defining: true,
  selectable: true,
  parseDOM: [{ tag: 'span' }],
  toDOM: (node) => ['span', { ...node.attrs, class: 'list-numbering' }, 0],
};

export default listNumbering;
