import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

function useImportPatentPublicationsBatch(
  options?: UseMutationOptions<
    string,
    AxiosError,
    T.PatentPublicationBatchImportRequest
  >
): UseMutationResult<
  string,
  AxiosError,
  T.PatentPublicationBatchImportRequest
> {
  return useMutation<string, AxiosError, T.PatentPublicationBatchImportRequest>(
    (payload: T.PatentPublicationBatchImportRequest) =>
      Api.pb.importPatentPublications(payload),
    options
  );
}

export default useImportPatentPublicationsBatch;
