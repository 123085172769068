import React, { useMemo } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { AutoComplete, Popover } from 'antd';
import { ToolbarIcon } from '../Utils';
import useTranslation from '../../../translations';
import { textBlocksList } from '../../../constants';
import Button from './Button';
const options = textBlocksList.map((tb: string) => ({
  value: tb,
}));

const { Option } = AutoComplete;

const TextBlocksPicker: React.FC<{
  editorState: EditorState;
  onChange: (newState: EditorState) => void;
}> = ({ editorState, onChange }) => {
  const t = useTranslation();

  const onSelect = (textBlock: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'textBlock',
      'IMMUTABLE',
      { textBlock }
    );

    const targetSelection = contentStateWithEntity.getSelectionAfter();
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let textBlockContent = Modifier.insertText(
      contentStateWithEntity,
      targetSelection,
      `[${textBlock}]`,
      undefined,
      entityKey
    );

    textBlockContent = Modifier.insertText(
      textBlockContent,
      textBlockContent.getSelectionAfter(),
      ' '
    );

    const newEditorState = EditorState.push(
      editorState,
      textBlockContent,
      'insert-fragment'
    );

    onChange(
      EditorState.forceSelection(
        newEditorState,
        textBlockContent.getSelectionAfter()
      )
    );
  };

  const textBlocks = useMemo(
    () =>
      options.map(({ value }, index) => (
        <Option
          key={value}
          value={value}
          data-testid={`dropdown-text-block-entry-${index}`}
        >
          {value}
        </Option>
      )),
    [options]
  );

  return (
    <Popover
      trigger="click"
      placement="topLeft"
      title={
        <AutoComplete
          dropdownRender={(dropdown) => (
            <div data-testid="add-text-block-dropdown">{dropdown}</div>
          )}
          style={{ width: 300 }}
          onSelect={onSelect}
          filterOption={(inputValue, option) => {
            // @ts-ignore
            const children = option?.children?.toLowerCase();
            return (
              children.indexOf(inputValue?.toLowerCase()) >= 0 ||
              children.indexOf(inputValue?.toLowerCase())
            );
          }}
          placeholder={t('RICH_TEXT.CONTROLS.SEARCH_FOR_TEXT_TEMPLATES')}
        >
          {textBlocks}
        </AutoComplete>
      }
    >
      <Button
        data-testid="add-text-block"
        label="RICH_TEXT.CONTROLS.TEXT_TEMPLATES"
        icon={<ToolbarIcon className="icn-placeholder" />}
      />
    </Popover>
  );
};

export default TextBlocksPicker;
