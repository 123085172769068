type Annotation = {
  key: string;
  style: {
    backgroundColor?: string;
    legendColor?: string;
    underlineColor?: string;
  };
  parentKey?: string;
};

const objectionStyle = {
  style: {
    backgroundColor: '#FF96D2',
    legendColor: '#FFDAEF',
  },
};

const objectionChildrenProps = {
  ...objectionStyle,
  parentKey: 'OBJECTIONS',
};

const inventivenessChildrenProps = {
  parentKey: 'INVENTIVENESS',
  style: {
    underlineColor: '#FF96D2',
  },
};

const INVENTIVENESS_CHILDREN_ANNOTATIONS: Annotation[] = [
  {
    key: 'CLOSEST_PRIOR_ART',
    ...inventivenessChildrenProps,
  },
  {
    key: 'DISTINGUISHING_FEATURES',
    ...inventivenessChildrenProps,
  },
  {
    key: 'TECHNICAL_EFFECTS',
    ...inventivenessChildrenProps,
  },
  {
    key: 'OBJECTIVE_TECHNICAL_PROBLEMS',
    ...inventivenessChildrenProps,
  },
  {
    key: 'INVENTIVE_STEP_AND_SKILLED_PERSON',
    ...inventivenessChildrenProps,
  },
];

const OBJECTIONS_CHILDREN_ANNOTATIONS = [
  {
    key: 'UNSUPPORTED_AMENDMENTS',
    ...objectionChildrenProps,
  },
  {
    key: 'NOVELTY',
    ...objectionChildrenProps,
  },
  {
    key: 'INVENTIVENESS',
    ...objectionChildrenProps,
  },
  {
    key: 'CLARITY',
    ...objectionChildrenProps,
  },
  {
    key: 'INSUFFICIENT_DISCLOSURE',
    ...objectionChildrenProps,
  },
  {
    key: 'PATENTABILITY',
    ...objectionChildrenProps,
  },
  {
    key: 'INDUSTRIAL_APPLICABILITY',
    ...objectionChildrenProps,
  },
  {
    key: 'NON_UNITY',
    ...objectionChildrenProps,
  },
  {
    key: 'FORMALITIES',
    ...objectionChildrenProps,
  },
];

export const ANNOTATION_STRUCTURE: Annotation[] = [
  {
    key: 'BIBLIOGRAPHIC_DATA',
    style: {
      backgroundColor: '#7C52FF',
      legendColor: '#D1C2FF',
    },
  },

  {
    key: 'LINKS_TO_PRIOR_ART',
    style: {
      backgroundColor: '#699AFF',
      underlineColor: '#1C7FF6',
      legendColor: '#CBDCFF',
    },
  },

  {
    key: 'PRIOR_ART',
    style: {
      backgroundColor: '#37FFAF',
      legendColor: '#B9FFE3',
    },
  },

  {
    key: 'CLAIM_FEATURES',
    style: {
      backgroundColor: '#FFF942',
      legendColor: '#FFFCA0',
    },
  },

  {
    key: 'SUGGESTED_AMENDMENTS',
    style: {
      backgroundColor: '#FF9E1E',
      legendColor: '#FFDDB0',
    },
  },

  {
    key: 'OBJECTIONS',
    ...objectionStyle,
  },

  ...OBJECTIONS_CHILDREN_ANNOTATIONS,
  ...INVENTIVENESS_CHILDREN_ANNOTATIONS,
];

export const getAnnotationStyle = (key: string) => {
  const annotation = ANNOTATION_STRUCTURE.find((a) => a.key === key);
  return annotation?.style;
};

export const getAnnotationIdByKey = (
  key: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return;
  }

  const index = Object.values(annotationSets).indexOf(key);
  return Object.keys(annotationSets)[index];
};

export const getRootAnnotationKey = (key: string): string => {
  const annotation = ANNOTATION_STRUCTURE.find((a) => a.key === key);

  if (!annotation?.parentKey) {
    return key;
  }

  return getRootAnnotationKey(annotation.parentKey);
};

const findChildAnnotationIds = (
  annotationId: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return [];
  }

  const childIds: string[] = [];

  // id might be
  // - an annotation set id that is coming from db
  // - a placeholder id that is generated by the frontend (e.g. placeholder-OBJECTIONS)
  const find = (id: string) => {
    const key = annotationSets[id] || id.replace('placeholder-', '');

    const childAnnotations = ANNOTATION_STRUCTURE.filter(
      (a) => a.parentKey === key
    );

    childAnnotations.forEach((a) => {
      const childAnnotationId =
        getAnnotationIdByKey(a.key, annotationSets) || `placeholder-${a.key}`;
      childIds.push(childAnnotationId);
      find(childAnnotationId);
    });
  };

  find(annotationId);

  return childIds;
};

const findRootAnnotationIds = (
  annotationId: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return [];
  }

  const rootIds: string[] = [];

  // id might be
  // - an annotation set id that is coming from db
  // - a placeholder id that is generated by the frontend (e.g. placeholder-OBJECTIONS)
  const find = (id: string) => {
    const key = annotationSets[id] || id.replace('placeholder-', '');
    const annotation = ANNOTATION_STRUCTURE.find((a) => a.key === key);

    if (!annotation?.parentKey) {
      return;
    }

    const rootId =
      getAnnotationIdByKey(annotation.parentKey, annotationSets) ||
      `placeholder-${annotation.parentKey}`;
    rootIds.push(rootId);
    find(rootId);
  };

  find(annotationId);

  return rootIds;
};

export const produceSelectedAnnotationSetsOnToggleAnnotationSet = (
  annotationId: string,
  value: boolean,
  annotationSets: Record<string, string> | undefined,
  selectedAnnotationSets: Record<string, boolean> | undefined
) => {
  // if the annotation is not selected, then all its children should not be selected
  // if the annotation is selected, then all its children should be selected
  const childAnnotationIds: string[] = findChildAnnotationIds(
    annotationId,
    annotationSets
  );

  const newSelectedAnnotationSets = {
    ...selectedAnnotationSets,
    [annotationId]: value,
  };

  childAnnotationIds.forEach((id) => {
    newSelectedAnnotationSets[id] = value;
  });

  // if a annotation is selected, and it's parent annotations are not selected, we should make them selected.
  // if a child annotation is not selected, and all its siblings are not selected, then the root annotation should not be selected
  const rootAnnotationIds: string[] = findRootAnnotationIds(
    annotationId,
    annotationSets
  );

  rootAnnotationIds.forEach((id) => {
    if (value) {
      newSelectedAnnotationSets[id] = value;
      return;
    }

    const childIds = findChildAnnotationIds(id, annotationSets);
    const allChildrenAreNotSelected = childIds.every(
      (childId) => !newSelectedAnnotationSets[childId]
    );

    if (allChildrenAreNotSelected) {
      newSelectedAnnotationSets[id] = value;
    }
  });

  return newSelectedAnnotationSets;
};
