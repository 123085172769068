import React, { useState } from 'react';
import styled from 'styled-components';
import { B2, Small } from '../../Atoms/Atoms';
import {
  CreateCompetithorReport,
  CompetithorReportInputName,
  CompetithorReportParams,
  PatStatReports,
} from '../../../types';
import useTranslation from '../../../translations';
import CompetitorReportModal from '../CompetitorReportModal';

interface Props {
  cpcCode: string;
  cpcDescription?: string;
  style?: React.CSSProperties;
}

const PatstatCpcClassItem: React.FC<Props> = ({
  cpcCode,
  cpcDescription,
  style,
}) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  const competitorReportRequest: CreateCompetithorReport = {
    title: cpcCode,
    description: t('COMPETITOR.TECH_FIELD_ANALYSIS.DESCRIPTION_CPC'),
    inputs: [
      {
        name: CompetithorReportInputName.CpcClassCode,
        value: cpcCode,
      },
    ],
    reportParams: {
      [CompetithorReportParams.Cpc]: cpcCode,
    },
    patStatReportId: PatStatReports.CpcTechFieldAnalysis,
  };

  return (
    <Outer
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenRequest?.();
      }}
    >
      <ListItemIcon>
        <StyledIcon className="icn-tech-field" />
      </ListItemIcon>
      <Inner>
        <CpcCode>{cpcCode}</CpcCode>
        <CpcDescription>{cpcDescription}</CpcDescription>
      </Inner>
      {isModalOpen && (
        <CompetitorReportModal
          onCloseRequest={(e) => {
            e.stopPropagation();
            handleCloseRequest?.();
          }}
          reportRequest={competitorReportRequest as CreateCompetithorReport}
        />
      )}
    </Outer>
  );
};

export default PatstatCpcClassItem;

const StyledIcon = styled.i`
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.white60};
  font-size: 18px;
`;

const CpcCode = styled(B2)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white70};
  transition: color 0.3s ease;
`;

const Outer = styled.div`
  display: flex;
  width: 90%;
  height: 100%;

  &:hover {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.white87};
    }
    ${CpcCode} {
      color: ${(props) => props.theme.colors.white87};
    }
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  gap: 3px;
`;

const CpcDescription = styled(Small)`
  line-height: 13px;
  color: ${(props) => props.theme.colors.white40};
`;

const ListItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.primary100};
  cursor: pointer;
`;
