import React, { useLayoutEffect } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CompetitorLogo } from '../../../assets/appLogo/Competitor.svg';
import useTranslation from '../../translations';
import { DEVICE } from '../../constants';
import { useCompetitor } from '../../hooks';

interface Props {
  competitorReportId: string;
  hideCompetitorBrandLogo?: boolean;
}

const CompetitorReportModalContent: React.FC<Props> = ({
  competitorReportId,
  hideCompetitorBrandLogo,
}) => {
  const [iframe, setIframe] = React.useState<HTMLIFrameElement | null>(null);
  const t = useTranslation();
  const [, competitorAppUri] = useCompetitor();
  useLayoutEffect(() => {
    function messageHandler(event: MessageEvent) {
      if (!iframe) {
        return;
      }
      const { action, data } = event.data;
      if (action == 'returnData') {
        for (const key in data) {
          localStorage.setItem(key, data[key]);
        }
      } else if (action == 'get') {
        const data: {
          action: 'get' | 'returnData';
          data: Record<string, string | null>;
        } = {
          action: 'returnData',
          data: {},
        };
        for (const key in localStorage) {
          if (key.indexOf('windows.net') > -1) {
            if (!key) {
              continue;
            }
            data.data[key] = localStorage.getItem(key);
          }
        }
        iframe.contentWindow?.postMessage(
          data,
          // @ts-ignore
          window.COMPETITOR_APP_BASE_URL
        );
      }
    }
    window.addEventListener('message', messageHandler, false);

    return () => {
      window.removeEventListener('message', messageHandler, false);
    };
  }, [iframe]);

  return (
    <Outer>
      {!hideCompetitorBrandLogo && (
        <CompetitorBrand>
          <StyledLink
            href={`${competitorAppUri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Space>
              <LogoWrapper>
                <CompetitorLogo />
              </LogoWrapper>
              <AppTitle>
                {t('COMPETITOR.PROVIDED_BY')} {t('APPS.COMPETITOR')}
              </AppTitle>
            </Space>
          </StyledLink>
        </CompetitorBrand>
      )}
      <iframe
        ref={setIframe}
        src={`${competitorAppUri}/reports/${competitorReportId}?embedded=true`}
        style={{ border: 'none' }}
        width={'100%'}
        height={'100%'}
      ></iframe>
    </Outer>
  );
};

export default CompetitorReportModalContent;

const Outer = styled.div`
  min-width: 0;
  width: 100%;
  height: calc(100% - 20px);
`;

const StyledLink = styled.a`
  display: flex;
  color: ${(props) => props.theme.colors.white50};
  &:hover {
    color: ${(props) => props.theme.colors.white100};
    cursor: pointer;
  }
`;

const CompetitorBrand = styled.div`
  position: absolute;
  bottom: 20px;
  right: 95px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
`;

const LogoWrapper = styled.div`
  width: 16px;
  height: 16px;
  filter: drop-shadow(8px 8px 12px black);
  @media ${DEVICE.laptopS} {
    width: 18px;
    height: 18px;
  }
  @media ${DEVICE.laptopM} {
    width: 18px;
    height: 18px;
  }
  @media ${DEVICE.laptopL2} {
    width: 20px;
    height: 20px;
  }
  @media ${DEVICE.laptopL} {
    width: 20px;
    height: 20px;
  }
  @media ${DEVICE.desktopS} {
    width: 20px;
    height: 20px;
  }
  @media ${DEVICE.desktopS2} {
    width: 25px;
    height: 25px;
  }
  @media ${DEVICE.desktopHD} {
    width: 25px;
    height: 25px;
  }
  @media ${DEVICE.desktop4K} {
    width: 30px;
    height: 30px;
  }
`;

const AppTitle = styled.div`
  white-space: nowrap;
  filter: drop-shadow(8px 8px 5px black);
  font-size: 8px;
  @media ${DEVICE.laptopS} {
    font-size: 9px;
  }
  @media ${DEVICE.laptopM} {
    font-size: 9px;
  }
  @media ${DEVICE.laptopL2} {
    font-size: 10px;
  }
  @media ${DEVICE.laptopL} {
    font-size: 10px;
  }
  @media ${DEVICE.desktopS} {
    font-size: 10px;
  }
  @media ${DEVICE.desktopS2} {
    font-size: 12px;
  }
  @media ${DEVICE.desktopHD} {
    font-size: 12px;
  }
  @media ${DEVICE.desktop4K} {
    font-size: 14px;
  }
`;
