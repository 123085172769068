import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import CountryFlag from '../../CountryFlag';
import { Language } from '../../../types';
import { TranslatedIcon, AuthenticTag } from '../common';

interface Props {
  language: Language;
  style?: React.CSSProperties;
  nameStyle?: React.CSSProperties;
  hasTranslation?: boolean;
  isAuthenticLanguage?: boolean;
}

const CountryItem: React.FC<Props> = ({
  language,
  style,
  nameStyle,
  hasTranslation,
  isAuthenticLanguage,
}) => {
  const t = useTranslation();

  return (
    <Outer style={style}>
      <CountryFlag countryCode={language} size={20} />
      <CountryName style={nameStyle}>
        {t(`LANGUAGE.${language.toUpperCase()}`)}
      </CountryName>
      {hasTranslation && !isAuthenticLanguage && <TranslatedIcon />}
      {isAuthenticLanguage && <AuthenticTag />}
    </Outer>
  );
};

export default CountryItem;

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CountryName = styled.span`
  color: ${(props) => props.theme.colors.white87};
`;
