import { EditorState, Transaction } from 'prosemirror-state';
import UICommand from '../../toolbar/commands/UICommand';

export type AlignType = 'left' | 'center' | 'right';

class TextAlignCommand extends UICommand {
  _type: AlignType;

  constructor(type: AlignType) {
    super();
    this._type = type;
  }

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, tr, selection } = state;
    const { from, to } = selection;
    let modified = false;

    state.doc.nodesBetween(from, to, (node, pos) => {
      if (node.type === schema.nodes.paragraph) {
        tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          align: this._type,
        });
        modified = true;
      }
    });

    if (!modified) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

export default TextAlignCommand;
