import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';

const useTsdNode = (
  tsdId: string,
  tsdNodeId: string,
  options?: UseQueryOptions<T.TsdNode, AxiosError>
): UseQueryResult<T.TsdNode, AxiosError> => {
  const { preferredLanguage } = useAppContext();
  return useQuery<T.TsdNode, AxiosError>(
    [QueryKey.TsdNodeData, tsdId, tsdNodeId, preferredLanguage],
    () => Api.tsd.getTsdNodeById(tsdId, tsdNodeId, preferredLanguage),
    options
  );
};
export default useTsdNode;
