import React from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import ActionButton from './ActionButton';

interface Props {
  to: LocationDescriptor;
  style?: React.CSSProperties;
}

const LinkButton: React.FC<React.PropsWithChildren<Props>> = ({
  to,
  children,
  style,
}) => (
  <Link to={to} data-testid="link-btn">
    <ActionButton style={style}>{children}</ActionButton>
  </Link>
);

export default LinkButton;
