import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { QueryKey } from '.';

const useDocumentAsBlob = (
  uri: string,
  options?: UseQueryOptions<Blob, AxiosError>
): UseQueryResult<Blob, AxiosError> =>
  useQuery<Blob, AxiosError>(
    [QueryKey.DocumentAsBlob, uri],
    () => Api.document.getAsBlobByUri(uri),
    options
  );

export default useDocumentAsBlob;
