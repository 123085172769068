import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Node } from 'prosemirror-model';
import { Colors } from '../../../../theme';
import isElementFullyVisible from '../../toolbar/util/isElementFullyVisible';
import bindScrollHandler from '../../toolbar/util/bindScrollHandler';

interface Options {
  undoClaimTranslation: (claimId: string) => void;
  redoClaimTranslation: (claimId: string) => void;
  retranslateClaimTranslation: (claimId: string) => void;
}
type Props = {
  node: Node;
  claimEl: HTMLElement | null;
  options: Options;
};

const TOOLTIP_STYLE = {
  maxWidth: '130px',
  color: Colors.white87,
  padding: '5px 8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
  marginBottom: '-7px',
};

export const ClaimMenu: React.FC<Props> = ({ node, claimEl, options }) => {
  const [showToolbar, setShowToolbar] = useState(true);
  const [showMenuToolbar, setMenuToolbar] = useState(false);

  const handleMouseEnter = () => {
    isElementFullyVisible(claimEl) && setShowToolbar(true);
  };

  const handleMouseLeave = () => {
    setShowToolbar(false);
  };

  const handleMenuMouseEnter = () => {
    setMenuToolbar(true);
  };

  const handleMenuMouseLeave = () => {
    setMenuToolbar(false);
  };

  useEffect(() => {
    const handleToolbarVisibility = () => {
      if (!isElementFullyVisible(claimEl)) {
        setShowToolbar(false);
      }
    };
    handleToolbarVisibility();
    if (claimEl) {
      claimEl.addEventListener('mouseenter', handleMouseEnter);
      claimEl.addEventListener('mouseleave', handleMouseLeave);
      bindScrollHandler(claimEl, handleToolbarVisibility);
    }

    return () => {
      if (claimEl) {
        claimEl.removeEventListener('mouseenter', handleMouseEnter);
        claimEl.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [claimEl]);

  const menuBtns = [
    {
      icon: 'icn-undo',
      tooltipTitle: `Undo Claim ${node.attrs.claimNumber}`,
      onClick: () => options.undoClaimTranslation(node.attrs.claimTraceId),
    },
    {
      icon: 'icn-redo',
      tooltipTitle: `Redo Claim ${node.attrs.claimNumber}`,
      onClick: () => options.redoClaimTranslation(node.attrs.claimTraceId),
    },
    {
      icon: 'icn-retry',
      tooltipTitle: `Retranslate Claim ${node.attrs.claimNumber}`,
      onClick: () =>
        options.retranslateClaimTranslation(node.attrs.claimTraceId),
    },
  ];

  return (
    <>
      {(showToolbar || showMenuToolbar) && (
        <ButtonWrapper
          onMouseEnter={handleMenuMouseEnter}
          onMouseLeave={handleMenuMouseLeave}
        >
          {menuBtns.map((btn, index) => (
            <Tooltip
              key={index}
              placement="top"
              title={btn.tooltipTitle}
              overlayInnerStyle={TOOLTIP_STYLE}
              color={Colors.primary300}
            >
              <StyledIcon
                className={btn.icon}
                onClick={btn.onClick}
                style={{ fontSize: '18px' }}
              />
            </Tooltip>
          ))}
        </ButtonWrapper>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary200};
  padding: 3px;
  border-radius: 5px;
  margin-top: -35px;
`;
const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.white87};
  width: 30px;
  height: 30px;
  &:hover {
    background: ${(props) => props.theme.colors.white20};
  }
`;
