import React from 'react';
import styled from 'styled-components';
import CpcTag from './CpcTag';

const MAX_LENGTH = 9;

interface Props {
  codes: string[];
}

const CpcTags: React.FC<Props> = ({ codes }) => {
  const moreCount = codes.length - MAX_LENGTH;
  const moreText = moreCount > 0 ? `+${moreCount}` : null;

  return (
    <Outer>
      {codes.length > 0
        ? codes
            .slice(0, MAX_LENGTH)
            .map((code) => <CpcTag key={code} code={code} />)
        : '-'}
      {moreText && <More>{moreText}</More>}
    </Outer>
  );
};

export default CpcTags;

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const More = styled.div`
  color: ${(props) => props.theme.colors.white60};
`;
