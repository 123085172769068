import React, {
  Dispatch,
  FC,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import useTranslation from '../../../translations';
import { DocumentMetadata, Language } from '../../../types';
import DownloadTool from './DownloadTool';
import {
  FlexCenter,
  ToggleIcon,
  TOOLBAR_PADDING_LEFT,
  TOOLBAR_PADDING_RIGHT,
} from './styled';
import ViewMoreMenu from './ViewMoreMenu';
import {
  DOWNLOAD_ID,
  SEARCH_ID,
  SHOW_ANNOTATION_TOOLBAR_ID,
  TEXT_SELECTION_TOOL_ID,
} from './Toolbar';

interface ActionButtonProps {
  onAdvancedOCRSelection: () => void;
  isSearchActive: boolean;
  onSearchToggle: () => void;
  advancedOCRSelectionActive: boolean;
  advancedOCRSelectionEnabled: boolean;
  document: DocumentMetadata;
  authenticLanguage: Language;
  toolbarRef: React.RefObject<HTMLDivElement>;
  showAnnotationToolbar: boolean;
  setShowAnnotationToolbar: Dispatch<SetStateAction<boolean>>;
  setShowZoomDropdown: Dispatch<SetStateAction<boolean>>;
  setShowPagesNavigations: Dispatch<SetStateAction<boolean>>;
  viewerIndex?: number;
}

interface UpdateDropdownItemsArgs {
  toolbarWidth: number;
  childrenWidth: number;
  actionButtonWidth: number;
}

const ZOOM_DROPDOWN_SHOW_LIMIT = 600;
const PAGES_NAVIGATIONS_SHOW_LIMIT = 543;

const ActionButtons: FC<ActionButtonProps> = ({
  onAdvancedOCRSelection,
  isSearchActive,
  onSearchToggle,
  advancedOCRSelectionActive,
  advancedOCRSelectionEnabled,
  document,
  authenticLanguage,
  toolbarRef,
  showAnnotationToolbar,
  setShowAnnotationToolbar,
  setShowZoomDropdown,
  setShowPagesNavigations,
  viewerIndex,
}) => {
  const actionButtonsRef = useRef<HTMLDivElement>(null);
  const t = useTranslation();
  const theme = useTheme();

  const handleShowAnnotationToolbar = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setShowAnnotationToolbar((prev) => !prev);
  };

  const showMoreDropdownItems = [
    {
      id: SHOW_ANNOTATION_TOOLBAR_ID,
      title: t('ANNOTATIONS'),
      iconClassName: 'icn-marker',
      active: showAnnotationToolbar,
      isVisible: document.contentLanguage === authenticLanguage,
      onClick: handleShowAnnotationToolbar,
    },
    {
      id: SEARCH_ID,
      title: t('MULTI_DOC_SEARCH.SEARCH'),
      iconClassName: 'icn-search',
      active: isSearchActive,
      isVisible: true,
      onClick: onSearchToggle,
    },
    {
      id: TEXT_SELECTION_TOOL_ID,
      title: t('TEXT_SELECTION_TOOL'),
      iconClassName: 'icn-selection',
      active: advancedOCRSelectionActive,
      isVisible: advancedOCRSelectionEnabled,
      onClick: onAdvancedOCRSelection,
    },
    {
      id: DOWNLOAD_ID,
      title: t('ACTION.DOWNLOAD'),
      iconClassName: 'icn-download',
      active: false,
      isVisible: true,
    },
  ];

  const [visibleItemsCount, setVisibleItemsCount] = useState<number>(0);

  useLayoutEffect(() => {
    if (!toolbarRef.current) {
      return;
    }

    const updateDropdownItems = ({
      toolbarWidth,
      childrenWidth,
      actionButtonWidth,
    }: UpdateDropdownItemsArgs) => {
      let tempChildrenWidth = childrenWidth;
      let tempVisibleItemsCount = 0;
      showMoreDropdownItems.forEach(() => {
        if (
          ZOOM_DROPDOWN_SHOW_LIMIT + actionButtonWidth < toolbarWidth &&
          tempChildrenWidth + actionButtonWidth < toolbarWidth
        ) {
          tempChildrenWidth += actionButtonWidth;
          tempVisibleItemsCount += 1;
        }
      });

      setVisibleItemsCount(tempVisibleItemsCount);
    };

    const checkWidth = () => {
      if (!toolbarRef.current || !actionButtonsRef.current) {
        return;
      }

      const toolbarWidth = toolbarRef.current.offsetWidth;
      const allActionButtonsWidth = actionButtonsRef.current.offsetWidth;
      const actionButtonWidth =
        actionButtonsRef.current.children[0].getBoundingClientRect().width;
      const toolbarGap =
        parseInt(getComputedStyle(toolbarRef.current).gap) || 0;
      // calculate the width of all children in the toolbar except the action buttons
      const childrenWidth =
        [...toolbarRef.current.children].reduce(
          (total, child) => total + child.getBoundingClientRect().width,
          0
        ) +
        toolbarGap * toolbarRef.current.children.length -
        1 +
        TOOLBAR_PADDING_LEFT +
        TOOLBAR_PADDING_RIGHT -
        allActionButtonsWidth;

      updateDropdownItems({
        toolbarWidth,
        childrenWidth,
        actionButtonWidth,
      });
      setShowZoomDropdown(toolbarWidth >= ZOOM_DROPDOWN_SHOW_LIMIT);
      setShowPagesNavigations(toolbarWidth >= PAGES_NAVIGATIONS_SHOW_LIMIT);
    };

    checkWidth();

    const resizeObserver = new ResizeObserver(checkWidth);

    resizeObserver.observe(toolbarRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [toolbarRef.current]);

  return (
    <>
      <FlexCenter ref={actionButtonsRef}>
        {showMoreDropdownItems.slice(0, visibleItemsCount).map((item) => {
          const toggleIcon = (
            <ToggleIcon
              active={item.active}
              className={item.iconClassName}
              data-id={item.id}
              onClick={item.onClick}
            />
          );
          return item.isVisible ? (
            <Tooltip
              key={item.id}
              title={item.title}
              color={theme.colors.primary300}
              overlayClassName="action-button-tooltip"
              align={{
                offset: [0, 5],
              }}
            >
              {item.id === DOWNLOAD_ID ? (
                <DownloadTool
                  document={document}
                  toggleIcon={toggleIcon}
                  viewerIndex={viewerIndex}
                />
              ) : (
                toggleIcon
              )}
            </Tooltip>
          ) : null;
        })}
        <ViewMoreMenu
          items={showMoreDropdownItems.slice(visibleItemsCount)}
          document={document}
        />
      </FlexCenter>
    </>
  );
};

export default ActionButtons;
