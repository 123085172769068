import React from 'react';
import styled from 'styled-components';
import TsdTreeTag from './TsdTreeTag';

interface Props {
  tsdNames: string[];
  maxRenderCount?: number;
}

const TsdTreeTags: React.FC<Props> = ({ tsdNames, maxRenderCount }) => {
  let uniqueNames = Array.from(new Set<string>(tsdNames));
  let moreText;

  if (maxRenderCount && maxRenderCount > 0) {
    const moreCount = tsdNames.length - maxRenderCount;
    moreText = moreCount > 0 ? `+${moreCount}` : null;
    uniqueNames = uniqueNames.slice(0, maxRenderCount);
  }

  return (
    <Outer>
      {uniqueNames.length > 0
        ? uniqueNames.map((tsdName) => (
            <TsdTreeTag key={tsdName} name={tsdName} />
          ))
        : '-'}
      {moreText && <More>{moreText}</More>}
    </Outer>
  );
};

export default TsdTreeTags;

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const More = styled.div`
  color: ${(props) => props.theme.colors.white60};
`;
