import React from 'react';
import { Select as AntdSelect, SelectProps } from 'antd';
import styled from 'styled-components';
import { Icon } from '../Icon';
import useTranslation from '../../translations';

function Select<T>({ children, ...props }: SelectProps<T>) {
  const t = useTranslation();

  return (
    <SelectWrapper>
      <AntdSelect<T>
        {...props}
        placeholder={t('FORM.SELECT.PLACEHOLDER')}
        clearIcon={<Icon className="icn-clear" size={16} />}
      >
        {children}
      </AntdSelect>
    </SelectWrapper>
  );
}

Select.Option = AntdSelect.Option;

export default Select;

const SelectWrapper = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    background: ${({ theme }) => theme.colors.primary200_30} !important;
    border: none !important;
    padding: 5px 16px !important;
    border-radius: 5px !important;
    height: initial !important;
  }

  .ant-select-clear {
    width: 30px !important;
    height: 30px !important;
    margin-top: -15px !important;
    right: 7px !important;
    padding: 5px !important;
    border-radius: 3px !important;
    background-color: #565866 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
