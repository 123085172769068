import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import BaseButton, { Props } from './BaseButton';

const SuccessButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ leftIcon, rightIcon, testId, style, onClick, children, ...rest }, ref) => (
    <StyledButton
      {...rest}
      ref={ref}
      styleOverride={style}
      data-testid={testId}
      onClick={onClick}
    >
      {leftIcon && (
        <div style={{ marginRight: children != undefined ? '8px' : '0px' }}>
          {leftIcon}
        </div>
      )}
      {children}
      {rightIcon && <div style={{ marginLeft: '8px' }}>{rightIcon}</div>}
    </StyledButton>
  )
);

const StyledButton = styled(BaseButton)<{ styleOverride?: CSSObject }>`
  background: ${(props) => props.theme.colors.secondary200};
  &:hover {
    background: ${(props) => props.theme.colors.primary100};
  }
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

export default SuccessButton;
