import { Command, Selection } from 'prosemirror-state';
import { findParentNodeOfType } from '../util';
import { claimsPlugin } from '../plugins';

export const claimEnter: Command = (state, dispatch) => {
  const claim = findParentNodeOfType(state.schema.nodes.claim)(state.selection);
  if (!claim) {
    return false;
  }

  const emptyClaimPart = state.schema.nodes.claimPart.createAndFill(
    {
      isInserted: true,
    },
    state.schema.nodes.paragraph.create({})
  );
  if (!emptyClaimPart) {
    return false;
  }
  const enableClaimAdvantage =
    claimsPlugin.key.getState(state)?.includeClaimAdvantage;
  const emptyClaimAdvantage = enableClaimAdvantage
    ? state.schema.nodes.claimAdvantage.createAndFill(
        { claimNumber: claim.node.attrs.calculatedClaimNumber + 1 },
        state.schema.nodes.paragraph.create({})
      )
    : null;
  const newClaim = state.schema.nodes.claim.createAndFill(
    {
      isInserted: true,
    },
    emptyClaimAdvantage
      ? [emptyClaimPart, emptyClaimAdvantage]
      : [emptyClaimPart]
  );
  if (!newClaim) {
    return false;
  }

  if (dispatch) {
    const tr = state.tr;
    const claim = findParentNodeOfType(state.schema.nodes.claim)(tr.selection);
    if (claim) {
      const afterPos = claim.pos + claim.node.nodeSize;
      tr.insert(afterPos, newClaim);
      tr.setSelection(Selection.near(tr.doc.resolve(afterPos)));
      tr.scrollIntoView();
    }
    dispatch(tr);
  }

  return true;
};
