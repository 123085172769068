import React from 'react';
import styled from 'styled-components';
import CountryFlag from '../../CountryFlag';
import useTranslation from '../../../translations';
import { Language } from '../../../types';

interface Props {
  language: Language;
  highlighted?: boolean;
  content?: React.ReactNode;
  onClick: (language: Language) => void;
}

const LanguagePickerItem: React.FC<Props> = ({
  language,
  highlighted = false,
  content,
  onClick,
}) => {
  const t = useTranslation();

  return (
    <LanguageItem onClick={() => onClick(language)} $highlighted={highlighted}>
      <CountryFlag countryCode={language} size={20} />
      <LanguageTitle>{t(`LANGUAGE.${language.toUpperCase()}`)}</LanguageTitle>
      {content}
    </LanguageItem>
  );
};

export default LanguagePickerItem;

const LanguageItem = styled.div<{
  $highlighted?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  height: 34px;
  width: 200px;
  transition: background 0.3s ease;
  opacity: ${(props) => (props.$highlighted ? 1 : 0.5)};
  &:hover {
    background: ${(props) => props.theme.colors.white10};
    .language-picker-item--show-on-hover {
      visibility: visible;
    }
  }
`;

const LanguageTitle = styled.div`
  color: ${(props) => props.theme.colors.white100};
  font-size: 14px;
  margin-left: 8px;
  transition: color 0.3s ease;
`;
