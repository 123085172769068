import React, { useRef } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

interface Props {
  title: string;
  value?: string | null;
  onChange: (value: string) => void;
}

const CustomDatePicker: React.FC<Props> = ({
  title,
  value,
  onChange,
  ...props
}) => {
  const containerRef = useRef(null);
  return (
    <Outer ref={containerRef}>
      <DatePicker
        {...props}
        dropdownAlign={{ offset: [-18, 20] }}
        value={value ? moment(value) : null}
        onChange={(val) => {
          val && onChange(val.toISOString());
        }}
        suffixIcon={null}
        clearIcon={null}
        format="DD MMMM YYYY"
      />
      <Title>{title}</Title>
    </Outer>
  );
};

const Title = styled.span`
  font-size: 12px;
  user-select: none;
  width: 100%px;
  color: ${(props) => props.theme.colors.primary200};
`;

const Outer = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;

  .ant-picker {
    background: none;
    color: rgba(255, 255, 255, 0.87);
    transition: border 0.2s ease-in-out;
    padding: 2px 10px 2px 0px;
    border: none;
    &:focus {
      outline: none;
    }
    input {
      font-size: 15px;
      border: none;
    }
    &.ant-picker-focused {
      outline: none;
      box-shadow: none;
    }
  }
`;

export default CustomDatePicker;
