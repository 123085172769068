import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import useTranslation from '../../../translations';
import { NavigationItem } from '../../Layout';
import { GlossaryPicker } from '../GlossaryPicker';
import { TranslationQuota } from '../TranslationQuota';
import { useGetUserSettings } from '../../../queries';

const TranslationSettings: React.FC = () => {
  const t = useTranslation();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const { data: userSettings } = useGetUserSettings({ enabled: isPopoverOpen });

  const closePopover = () => setPopoverOpen(false);
  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const content: JSX.Element = (
    <Outer onClick={(e) => e.stopPropagation()}>
      <Row>
        <Text>{t('TRANSLATION_SETTINGS.TRANSLATION_SETTINGS')}</Text>
      </Row>
      <Row>
        <Label>{t('TRANSLATION_SETTINGS.GLOSSARY')}</Label>
        <GlossaryPicker settings={userSettings} popoverPosition={['top']} />
      </Row>
      <Row alignStart>
        <Label>{t('TRANSLATION_SETTINGS.TRANSLATION_QUOTA')}</Label>
        <TranslationQuota />
      </Row>
    </Outer>
  );

  return (
    <Popover
      align="start"
      positions={['right']}
      isOpen={isPopoverOpen}
      content={content}
      onClickOutside={closePopover}
    >
      <div onClick={togglePopover}>
        <NavigationItem
          tooltipText={t('TRANSLATION_SETTINGS.TOOLTIP')}
          iconClassName="icn-settings"
          iconStyle={{ fontSize: 34 }}
          isSettingsItem
          active={isPopoverOpen}
        />
      </div>
    </Popover>
  );
};

export default TranslationSettings;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px;
  padding: 28px 20px;
  border-radius: 12px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
  min-width: 436px;
`;

const Row = styled.div<{ alignStart?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.alignStart ? 'start' : 'center')};
  gap: 10px;
`;
const Label = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.white87};
  min-width: 150px;
`;
const Text = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white40};
`;
