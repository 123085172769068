import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import useTranslation from '../../../translations';
import { useSessionContext } from '../../../contexts';

function formatNumShort(num: number): string {
  if (!num) {
    return 'N/A';
  }
  const million = 1000000;
  const thousand = 1000;

  const formatNumber = (divisor: number, unit: string) => {
    const formatted = (num / divisor).toFixed(2);
    return formatted.endsWith('.00')
      ? `${Math.floor(num / divisor)}${unit}`
      : `${formatted}${unit}`;
  };
  const formatAsThousandOrOriginal =
    num >= thousand ? formatNumber(thousand, 'k') : num.toString();
  return num >= million
    ? formatNumber(million, 'm')
    : formatAsThousandOrOriginal;
}

const strokeColor = { from: '#4180CC', to: '#88CCFF' };

const TranslationQuota: React.FC = () => {
  const t = useTranslation();
  const [progress, setProgress] = useState(0);
  const { translationService } = useSessionContext();

  const { quantityThisMonth, settings } = translationService;

  useEffect(() => {
    const percentage = Math.round(
      (quantityThisMonth * 100) / settings.monthlyLimit
    );
    setProgress(percentage);
  }, [quantityThisMonth, settings.monthlyLimit]);

  return (
    <Outer>
      <Progress
        percent={progress}
        strokeWidth={8}
        showInfo={false}
        strokeColor={strokeColor}
      />
      <Status>
        {`${formatNumShort(quantityThisMonth)} / ${formatNumShort(
          settings.monthlyLimit
        )} `}
        {t('TRANSLATION_SETTINGS.CHARACTERS')}
      </Status>
    </Outer>
  );
};

export default TranslationQuota;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Status = styled.div`
  font-size: 15px;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.white60};
`;
