import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Utils } from '@ipos/shared';

const { truncate } = Utils;

export const NameRenderer: React.FC<{ name: string }> = ({ name }) => (
  <Value title={name}>{name}</Value>
);

export const DateRenderer: React.FC<{ date: string }> = ({ date }) => (
  <Value>{dayjs(date).format('D MMM YYYY')}</Value>
);

const Value = styled.div`
  ${truncate()}
`;
