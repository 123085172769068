import React from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import QueryBuilderSegmentListItem from '../Common/QueryBuilderSegmentListItem';
import { useQueryContext } from 'components/TsdElement/Attributes/Query/QueryContext';
import { Action, QueryBaseItem, QueryClassesOption, QuerySegment } from 'types';

interface Props {
  item: QueryBaseItem;
  selected: boolean;
  isTruncated?: boolean;
}

const ClassesListItem: React.FC<Props> = ({ item, isTruncated, selected }) => {
  const { dispatch } = useQueryContext();
  const { id, value } = item;

  const handleTruncationChange = (e: CheckboxChangeEvent) => {
    dispatch({
      type: Action.OptionChangeRequest,
      payload: {
        id,
        option: QueryClassesOption.IsTruncated,
        checked: e.target.checked,
        segment: QuerySegment.Classes,
      },
    });
  };

  return (
    <QueryBuilderSegmentListItem
      item={item}
      selected={selected}
      segment={QuerySegment.Classes}
    >
      <Content>
        {value}
        <Checkbox
          checked={isTruncated}
          disabled={!selected}
          onChange={handleTruncationChange}
        />
      </Content>
    </QueryBuilderSegmentListItem>
  );
};

export default ClassesListItem;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
`;
