import { DOMOutputSpec, NodeSpec } from 'prosemirror-model';
const brDOM: DOMOutputSpec = ['br'];

const hardBreak: NodeSpec = {
  inline: true,
  group: 'inline',
  selectable: false,
  parseDOM: [{ tag: 'br' }],
  toDOM() {
    return brDOM;
  },
};
export default hardBreak;
