import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { HeaderNavItemProps } from '../../../../types';
import { useTranslation } from '../../../../translations';
import { Icon } from '../../../Icon';
import { Colors } from '../../../../theme';
import NavDropdownItem from './NavDropdownItem';

interface Props {
  items: HeaderNavItemProps[];
}

const NavDropdown: React.FC<Props> = ({ items }) => {
  const t = useTranslation();

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const closePopover = () => setPopoverOpen(false);

  const handleItemClick = (onClickHandler?: () => void) => {
    closePopover();
    onClickHandler?.();
  };

  if (!items.length) {
    return null;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      align="start"
      containerStyle={{ margin: '7px 0 0 17px' }}
      onClickOutside={closePopover}
      content={
        <Content onClick={(e) => e.stopPropagation()}>
          {items.map((item) => (
            <NavDropdownItem
              key={item.title}
              item={item}
              onClick={() => handleItemClick(item.onClick)}
            />
          ))}
        </Content>
      }
    >
      <Trigger onClick={() => setPopoverOpen((prevState) => !prevState)}>
        <SelectedItem>
          {items.find((item) => item.active)?.title ?? t('HEADER.NO_SELECTION')}
        </SelectedItem>
        <Icon className="icn-chevron-down" color={Colors.white70} size={24} />
      </Trigger>
    </Popover>
  );
};

export default NavDropdown;

const SelectedItem = styled.span`
  font-size: 20px;
  line-height: 1.45;
  color: ${(props) => props.theme.colors.white100};
`;

const Trigger = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px;
  background-image: linear-gradient(
    120deg,
    ${(props) => props.theme.colors.primary600} 0%,
    ${(props) => props.theme.colors.primary800} 100%
  );
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
`;
