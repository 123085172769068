import React from 'react';
import styled from 'styled-components';
import { DocumentMetadata, DocumentSource } from '../../../../types';
import useTranslation from '../../../../translations';
import { DOCUMENT_SOURCES_CAN_SEARCH } from '../../../../constants';
import DocumentSearchControls, { IconButton } from './DocumentSearchControls';
import DocumentSearchStatus from './DocumentSearchStatus';
import DocumentSearchOcr from './DocumentSearchOcr';

const isPdf = (fileName: string): boolean =>
  /^.*\.pdf$/.test(fileName.toLowerCase());

interface Props {
  document: DocumentMetadata;
  isSearching: boolean;
  matchCount: number;
  activeMatchIndex: number | null;
  searchText: string;
  hasOcrLayer: boolean;
  viewerIndex?: number;
  onSearchChange: React.Dispatch<React.SetStateAction<string>>;
  onActiveMatchChange: (index: number) => void;
  onClose: () => void;
}

const DocumentSearch: React.FC<Props> = ({
  document,
  isSearching,
  matchCount,
  activeMatchIndex,
  searchText,
  hasOcrLayer,
  viewerIndex,
  onSearchChange,
  onActiveMatchChange,
  onClose,
}) => {
  const t = useTranslation();

  const { source, id: documentId, name } = document;
  const canSearchDocument = DOCUMENT_SOURCES_CAN_SEARCH.includes(source);
  const isPdfDocument = isPdf(name);

  let content;
  switch (true) {
    case !isPdfDocument:
      content = (
        <DocumentSearchStatus
          text={t('SINGLE_DOC_SEARCH.NOT_AVAILABLE_INVALID_FORMAT')}
        />
      );
      break;
    case source === DocumentSource.Translation:
      content = (
        <DocumentSearchStatus
          text={t('SINGLE_DOC_SEARCH.NOT_AVAILABLE_TRANSLATED')}
        />
      );
      break;
    case source === DocumentSource.Generated:
      content = (
        <DocumentSearchStatus
          text={t('SINGLE_DOC_SEARCH.NOT_AVAILABLE_GENERATED')}
        />
      );
      break;
    case canSearchDocument && hasOcrLayer:
      content = (
        <DocumentSearchControls
          isSearching={isSearching}
          matchCount={matchCount}
          activeMatchIndex={activeMatchIndex}
          searchText={searchText}
          onSearchChange={onSearchChange}
          onActiveMatchChange={onActiveMatchChange}
        />
      );
      break;
    case canSearchDocument && !hasOcrLayer:
      content = (
        <DocumentSearchOcr documentId={documentId} viewerIndex={viewerIndex} />
      );
      break;
    default:
      content = (
        <DocumentSearchStatus text={t('SINGLE_DOC_SEARCH.NOT_AVAILABLE')} />
      );
      break;
  }

  return (
    <Outer>
      {content}
      <IconButton className="icn-clear" onClick={onClose} />
    </Outer>
  );
};

export default DocumentSearch;

const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 44px;
  padding: 0 12px;
  z-index: 1;
  background: ${(props) => props.theme.colors.white100};
  border-bottom: 1px solid ${(props) => props.theme.colors.black20};
`;
