import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import useTranslation from '../../translations';
import Tutorials from './Tutorials';

interface Props {
  onClose: () => void;
}

const TutorialsModal: React.FC<Props> = ({ onClose }) => {
  const t = useTranslation();

  return (
    <Modal
      title={t('TUTORIALS.MODAL_TITLE')}
      open={true}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      width={720}
      style={{ top: 80 }}
    >
      <Outer>
        <Tutorials />
      </Outer>
    </Modal>
  );
};

export default TutorialsModal;

const Outer = styled.div`
  max-height: min(900px, calc(100vh - 250px));
  display: flex;
  flex-direction: column;
`;
