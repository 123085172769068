import { useRef } from 'react';
import { useDocumentOcrProcess, QueryKey } from '../../../../queries';
import { ProcessStageState, UserProcessStage } from '../../../../types';
import { useStartDocumentOcr } from '../../../../mutations';
import { queryClient } from '../../../../services/queryClient';

const REFETCH_INTERVAL = 3000;

const checkIsProcessing = (stage?: UserProcessStage) => {
  if (!stage) {
    return false;
  }
  const { state } = stage;
  return (
    state !== ProcessStageState.CompletedSuccessfully &&
    state !== ProcessStageState.Failed
  );
};

const useDocumentOcrProcessing = (
  documentId: string,
  onOcrProcessSettled?: () => void
): {
  progress: number;
  isStartingOcrProcess: boolean;
  isProcessing?: boolean;
  isError?: boolean;
  fetchProcessErrorStatus?: number;
  startOcrProcess: () => void;
} => {
  const isProcessingRef = useRef(false);
  const startOcrMutation = useStartDocumentOcr();

  const { data: process, error } = useDocumentOcrProcess(documentId, {
    refetchInterval: (data) =>
      checkIsProcessing(data?.stages[0]) ? REFETCH_INTERVAL : false,
    onSuccess(data) {
      const processing = checkIsProcessing(data?.stages[0]);
      if (processing) {
        isProcessingRef.current = true;
        return;
      }
      if (isProcessingRef.current) {
        isProcessingRef.current = false;
        onOcrProcessSettled?.();
      }
    },
  });

  const stage = process?.stages[0];
  const progress = stage?.progressPercentage ?? 0;
  const isProcessing = checkIsProcessing(stage);
  const isError = stage?.state === ProcessStageState.Failed;
  const fetchProcessErrorStatus = error?.response?.status;

  const startOcrProcess = () => {
    if (isProcessing) {
      return;
    }
    startOcrMutation.mutate(documentId, {
      onSuccess() {
        queryClient.invalidateQueries([
          QueryKey.DocumentOcrProcess,
          documentId,
        ]);
      },
    });
  };

  return {
    progress,
    isStartingOcrProcess: startOcrMutation.isLoading,
    isProcessing,
    isError,
    fetchProcessErrorStatus,
    startOcrProcess,
  };
};

export default useDocumentOcrProcessing;
