import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { Icon } from '../Icon';
import { Colors } from '../../theme';

interface Props {
  label: string;
  value: number | undefined;
  warnings?: string[];
  tipFormatter?: (value: number) => React.ReactElement | string;
}

const ProgressSlider: React.FC<Props> = ({
  warnings,
  tipFormatter,
  value,
  label,
}) => {
  const t = useTranslation();
  let tooltipContent: React.ReactElement | number | string | undefined;
  if (value != null) {
    if (tipFormatter) {
      tooltipContent = tipFormatter(value);
    } else {
      tooltipContent = value;
    }
  }

  const hasWarnings = warnings && warnings.length > 0;

  return (
    <Outer>
      <Header>
        <Label>{label}</Label>
        {hasWarnings && (
          <Icon
            className="icn-warning"
            color={Colors.warning}
            title={t('STATS.NO_DATA')}
            size={20}
          />
        )}
      </Header>
      <Tooltip title={!hasWarnings ? tooltipContent : null}>
        <Track hasWarnings={hasWarnings} disabled={value == null}>
          <ValueIndicator value={value ?? 0} />
        </Track>
      </Tooltip>
    </Outer>
  );
};
export default ProgressSlider;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`;

const Label = styled.span`
  margin-right: 6px;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 4px;
`;

const Track = styled.div<{ disabled: boolean; hasWarnings?: boolean }>`
  width: 100%;
  height: 10px;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary800};
  cursor: ${(props) => (props.disabled ? 'disabled' : 'pointer')};
  background-color: ${(props) => {
    switch (true) {
      case props.hasWarnings:
        return 'rgba(216, 20, 20, 0.15)';
      default:
        return props.theme.colors.primary800;
    }
  }};
`;

const ValueIndicator = styled.div<{ value: number | undefined }>`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: ${(props) => props.value}%;
  transition: all 0.3s;
  background-color: ${(props) => props.theme.colors.primary300};
`;
