import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTsdElementContext } from './TsdElementContext';
import TsdTreeTag from './TsdTreeTag';
import { Components } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';
import { useTsdElementUsage } from 'queries';

const { Notification } = Components;
const { Title } = Typography;

const TsdElementUsage: React.FC = () => {
  const { tsdElement } = useTsdElementContext();
  const { id } = tsdElement;
  const t = useTranslation();

  const { isLoading, isIdle, isError, data } = useTsdElementUsage(id);

  if (isLoading || isIdle) {
    return <Notification text={t('USAGE_INDICATION.SEARCH')} spin />;
  }

  if (isError) {
    return <Notification error />;
  }

  if (!data?.length) {
    return <>{t('USAGE_INDICATION.NOT_USED')}</>;
  }

  return (
    <Outer>
      <Title level={5}>{t('USAGE_INDICATION.TITLE')}</Title>
      {data?.map(({ tsdId, tsdName, paths }) => (
        <Inner key={tsdId}>
          <>
            <TsdTreeTag name={tsdName} />
            {paths.length > 1 && `x ${paths.length}`}
          </>
          {paths.map(({ tsdNodeId, parentNodeName }) => (
            <div key={tsdNodeId}>
              <Link
                to={generateUrl(ROUTES.TSD_NODE, {
                  tsdId,
                  tsdNodeId,
                })}
              >
                {parentNodeName
                  ? `${parentNodeName} / ${tsdElement.name}`
                  : tsdElement.name}
              </Link>
            </div>
          ))}
        </Inner>
      ))}
    </Outer>
  );
};

export default TsdElementUsage;

const Outer = styled.div`
  min-width: 250px;
`;
const Inner = styled.div`
  margin-bottom: 20px;
`;
