import React from 'react';
import { AppHeader } from '../Layout';
import useTranslation from '../../translations';
import DocumentButtons from './DocumentButtons';
import { HeaderIcon } from './common';
import { useMultiDocContext } from './MultiDocContext';
import { MultiDocSearch } from './Search';
import { MultiDocDownload } from './MultiDocDownload';

interface Props {
  subtitle?: string;
  onViewChange: (docCount: number) => void;
}

const MultiDocViewerHeader: React.FC<Props> = ({ subtitle, onViewChange }) => {
  const t = useTranslation();
  const {
    isSearchActive,
    setSearchActive,
    isDownloadActive,
    setDownloadActive,
  } = useMultiDocContext();

  let content = null;
  switch (true) {
    case isSearchActive:
      content = <MultiDocSearch />;
      break;
    case isDownloadActive:
      content = <MultiDocDownload />;
      break;
    default:
      content = (
        <>
          <HeaderIcon
            className="icn-search"
            onClick={() => setSearchActive(true)}
          />
          <HeaderIcon
            className="icn-download"
            onClick={() => setDownloadActive(true)}
          />
          <DocumentButtons
            onSelect={onViewChange}
            style={{ margin: '0 20px 0 12px' }}
          />
        </>
      );
  }

  return (
    <AppHeader
      title={t('DOC_VIEWER.HEADER_TITLE')}
      subtitle={subtitle}
      logo
      compact
      justifyContent="flex-end"
    >
      {content}
    </AppHeader>
  );
};

export default MultiDocViewerHeader;
