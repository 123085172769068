import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import BaseButton, { Props } from './BaseButton';

const FlatButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    { leftIcon, rightIcon, testId, style, onClick, children, active, ...rest },
    ref
  ) => (
    <StyledButton
      {...rest}
      ref={ref}
      active={active}
      styleOverride={style}
      data-testid={testId}
      onClick={onClick}
    >
      {leftIcon && (
        <div style={{ marginRight: children != undefined ? '8px' : '0px' }}>
          {leftIcon}
        </div>
      )}
      {children}
      {rightIcon && <div style={{ marginLeft: '8px' }}>{rightIcon}</div>}
    </StyledButton>
  )
);

const StyledButton = styled(BaseButton)<{
  styleOverride?: CSSObject;
  active?: boolean;
}>`
  border: none;
  padding: 7px 14px;
  background-color: transparent;
  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.primary200_40};
    `};
  &:hover {
    background: ${(props) => props.theme.colors.primary200_40};
  }
  ${(props) =>
    css`
      ${props.styleOverride}
    `};
`;

export default FlatButton;
