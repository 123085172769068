import React from 'react';
import styled from 'styled-components';

interface Props {
  style?: React.CSSProperties;
}

const HeaderButtons: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
}) => <Outer style={style}>{children}</Outer>;

export default HeaderButtons;

const Outer = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
  margin-left: 30px;
`;
