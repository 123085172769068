import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useVisualizationTsdElementImageCoverage = (
  options?: UseQueryOptions<T.DataPoint, AxiosError>
): UseQueryResult<T.DataPoint, AxiosError> =>
  useQuery<T.DataPoint, AxiosError>(
    QueryKey.TsdElementImageCoverage,
    Api.tsd.getTsdElementImageCoverage,
    options
  );

export default useVisualizationTsdElementImageCoverage;
