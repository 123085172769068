import React from 'react';
import { message } from 'antd';
import useTranslation from '../../../translations';
import { Spinner } from '../../Spinner';
import { initBrowserDownload } from '../../../utils';
import { useDocumentAsBlobById } from '../../../queries';
import { DocumentMetadata, DocumentSource } from '../../../types';
import { FlexCenter } from './styled';

interface Props {
  document: DocumentMetadata;
  toggleIcon: React.ReactNode;
  helperText?: React.ReactNode;
  viewerIndex?: number;
}

const DownloadTool: React.FC<Props> = ({
  document,
  toggleIcon,
  helperText,
  viewerIndex,
}) => {
  const t = useTranslation();

  const query = useDocumentAsBlobById(
    document.id,
    {
      enabled: false,
      onSuccess: ([blob, filename]) => {
        initBrowserDownload(filename, blob);
      },
      onError: () => {
        message.error(t('SOMETHING_WENT_WRONG'));
      },
    },
    viewerIndex
  );

  const handleDownloadClick = async () => {
    await query.refetch();
  };

  if (document.source === DocumentSource.Generated) {
    // current implementation (of this flow) does not support download of generated docs
    return null;
  }

  return (
    <FlexCenter onClick={handleDownloadClick}>
      {query.isLoading ? (
        <Spinner size="small" style={{ marginRight: '12px' }} />
      ) : (
        toggleIcon
      )}
      {helperText}
    </FlexCenter>
  );
};

export default DownloadTool;
