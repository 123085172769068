import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Input } from 'antd';
import { InputRef } from 'antd/lib/input/Input';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import TsdElementSearchResults from './TsdElementSearchResults';
import TsdElementNameButton from './TsdElementNameButton';
import useTsdElementNameSearch from './useTsdElementNameSearch';
import { Api, T, Components } from '@ipos/shared';
import useTranslation from 'translations';
import { useAppContext } from 'contexts';
import { QueryKey } from 'queries';

const { FormField } = Components.Form;
const PAGE_SIZE = 20;

interface Props {
  name: string;
  elementName: string;
  isElementSelected: boolean;
  onFormReset: (element?: T.TsdElementBasicInfo) => void;
  onElementSelect: (tsdElementId: string) => void;
}

const TsdElementNameInput: React.FC<Props> = ({
  name,
  elementName,
  isElementSelected,
  onFormReset,
  onElementSelect,
}) => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const elementNameRef = useRef<InputRef>(null);

  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [isPopoverDisabled, setPopoverDisabled] = useState<boolean>(false);
  const { search, clearSearch } = useTsdElementNameSearch(elementName);
  const isSearchActive = search.trim() !== '';

  const tsdElementsQuery = useInfiniteQuery<T.TsdElementListPage>(
    [QueryKey.TsdElements, search, preferredLanguage],
    async ({ pageParam = 0 }): Promise<T.TsdElementListPage> => {
      const tsdElementList = await Api.tsd.searchTsdElements({
        skip: pageParam,
        pageSize: PAGE_SIZE + 1,
        searchTerm: search,
        language: preferredLanguage,
      });
      const hasNextPage = tsdElementList.length > PAGE_SIZE;
      return {
        data: tsdElementList.slice(0, PAGE_SIZE),
        nextCursor: hasNextPage ? pageParam + PAGE_SIZE : undefined,
      };
    },
    {
      getNextPageParam: (lastPage: T.TsdElementListPage) => lastPage.nextCursor,
      enabled: isSearchActive && !isElementSelected,
    }
  );

  const { data: infiniteData } = tsdElementsQuery;

  const tsdElements = useMemo(() => {
    if (!infiniteData) {
      return [];
    }
    return infiniteData.pages.reduce(
      (result: T.TsdElementBasicInfo[], page: T.TsdElementListPage) => [
        ...result,
        ...page.data,
      ],
      []
    );
  }, [infiniteData]);

  const matchingElementsCount = tsdElements.length;

  useEffect(() => {
    if (
      isSearchActive &&
      !isElementSelected &&
      !isPopoverDisabled &&
      matchingElementsCount
    ) {
      setPopoverOpen(true);
    }
  }, [
    isSearchActive,
    isElementSelected,
    isPopoverDisabled,
    matchingElementsCount,
  ]);

  useEffect(() => {
    if (isPopoverOpen && matchingElementsCount === 0) {
      setPopoverOpen(false);
    }
  }, [isPopoverOpen, matchingElementsCount]);

  // prevent click away from closing the modal while popover is open
  useEffect(() => {
    const evaluatePopoverClose = (e: MouseEvent) => {
      const element = e.target as HTMLDivElement;
      const isClickAway = popoverRef?.current?.contains(element) === false;
      if (isPopoverOpen && isClickAway) {
        e.stopPropagation();
        setPopoverOpen(false);
      }
    };
    document.addEventListener('click', evaluatePopoverClose, true);
    return () => {
      document.removeEventListener('click', evaluatePopoverClose, true);
    };
  }, [isPopoverOpen]);

  const handleSelectElement = (tsdElementId: string) => {
    const element = tsdElements?.find((d) => d.id === tsdElementId);
    if (element) {
      onElementSelect(tsdElementId);
      setPopoverOpen(false);
      onFormReset(element);
    }
  };

  const handleFormReset = () => {
    clearSearch();
    onElementSelect('');
    onFormReset();
    setTimeout(() => {
      elementNameRef.current?.focus();
    }, 500);
  };

  const handleShowExistingElements = () => {
    if (isPopoverOpen) {
      return;
    }
    if (isPopoverDisabled) {
      setPopoverDisabled(false);
    }
    setPopoverOpen(true);
  };

  const handleCreateNew = () => {
    setPopoverDisabled(true);
    setPopoverOpen(false);
  };

  const handlePopoverOpen = (popoverContainer: HTMLDivElement | null) => {
    popoverRef.current = popoverContainer;
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      padding={10}
      align="start"
      containerStyle={{ zIndex: '9999' }}
      content={
        <TsdElementSearchResults
          tsdElements={tsdElements}
          tsdElementsQuery={tsdElementsQuery}
          onPopoverOpen={handlePopoverOpen}
          onSelectElement={handleSelectElement}
          onCreateNew={handleCreateNew}
        />
      }
    >
      <Content>
        <FormField
          name={name}
          label={t('CREATE_ELEMENT.NAME')}
          component={Input}
          placeholder={t('CREATE_ELEMENT.PLACEHOLDER')}
          autoComplete="off"
          autoFocus
          disabled={isElementSelected}
          style={{ width: 370 }}
          componentRef={elementNameRef}
        />
        {(isSearchActive || isElementSelected) && (
          <TsdElementNameButton
            isLoading={tsdElementsQuery.isLoading}
            isElementSelected={isElementSelected}
            matchingElementsCount={matchingElementsCount}
            onFormReset={handleFormReset}
            onShowExistingElements={handleShowExistingElements}
          />
        )}
      </Content>
    </Popover>
  );
};

export default TsdElementNameInput;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
`;
