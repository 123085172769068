import React, { useState } from 'react';
import { Button, Modal, Space, Tabs, Typography } from 'antd';
import styled from 'styled-components';
import CreateCompanyGroup, {
  SaveCompanyGroupInput,
} from './CreateCompanyGroup';
import AllocateExistingCompanyGroup from './AllocateExistingCompanyGroup';
import { Permissions, T } from '@ipos/shared';
import useTranslation from 'translations';

const { TabPane } = Tabs;
const { Text } = Typography;
const { WithPermission, usePermissionCheck } = Permissions;

const TABS = {
  AllocateExistingCompanyGroups: '1',
  SaveCompanyGroup: '2',
};

const INITIAL_STATE = {
  groupName: '',
  editedGroup: null,
};

interface Props {
  tsdElementCompanyGroupIds: string[];
}

const AddCompaniesButton: React.FC<Props> = ({ tsdElementCompanyGroupIds }) => {
  const t = useTranslation();
  const canEditCompanyGroups = usePermissionCheck(
    AzureGroupsConfig.PermissionCompanyTsdCompanyGroupEdit
  );
  const [activeTab, setActiveTab] = useState<string>(
    TABS.AllocateExistingCompanyGroups
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [companyGroupData, setCompanyGroupData] =
    useState<SaveCompanyGroupInput>(INITIAL_STATE);

  const handleCloseModalRequest = () => {
    setIsModalVisible(false);
    setCompanyGroupData(INITIAL_STATE);
    setActiveTab(TABS.AllocateExistingCompanyGroups);
  };

  const handleCreateGroupRequest = (groupName: string) => {
    setCompanyGroupData({
      groupName,
      editedGroup: null,
    });
    setActiveTab(TABS.SaveCompanyGroup);
  };

  const handleEditGroupRequest = (companyGroup: T.CompanyGroup) => {
    setCompanyGroupData({
      groupName: '',
      editedGroup: companyGroup,
    });
    setActiveTab(TABS.SaveCompanyGroup);
  };

  const handleTabChange = (newKey: string) => {
    if (newKey === TABS.AllocateExistingCompanyGroups) {
      setCompanyGroupData(INITIAL_STATE);
    }
    setActiveTab(newKey);
  };

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button onClick={() => setIsModalVisible(true)} type="primary">
        {t('COMPANIES.ADD')}
      </Button>
      <Modal
        width={1000}
        style={{ top: 20 }}
        onCancel={handleCloseModalRequest}
        destroyOnClose
        title={t('COMPANIES.ADD_COMPANIES_TITLE')}
        footer={null}
        open={isModalVisible}
      >
        <Outer>
          <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <Text>{t('COMPANIES.INTRO')}</Text>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              destroyInactiveTabPane
            >
              <TabPane
                tab={t('COMPANIES.ALLOCATE_EXISTING_TAB')}
                key={TABS.AllocateExistingCompanyGroups}
              >
                <AllocateExistingCompanyGroup
                  filterValues={tsdElementCompanyGroupIds}
                  onCreateGroupRequest={handleCreateGroupRequest}
                  onEditGroupRequest={handleEditGroupRequest}
                  onDone={handleCloseModalRequest}
                />
              </TabPane>
              {canEditCompanyGroups && (
                <TabPane
                  tab={t(
                    companyGroupData.editedGroup
                      ? 'COMPANIES.UPDATE_GROUP_TAB'
                      : 'COMPANIES.CREATE_NEW_GROUP_TAB'
                  )}
                  key={TABS.SaveCompanyGroup}
                >
                  <CreateCompanyGroup
                    defaultValue={companyGroupData}
                    onDone={handleCloseModalRequest}
                  />
                </TabPane>
              )}
            </Tabs>
          </Space>
        </Outer>
      </Modal>
    </WithPermission>
  );
};

export default AddCompaniesButton;

const Outer = styled.div`
  height: 800px;
`;
