import { css } from 'styled-components';

export default css`
  .claimAdvantage {
    .ProseMirror__contentDOM {
      padding-left: 28px;
      margin-top: -12px;
      display: none;
    }
    &.collapsed {
      .ProseMirror__contentDOM {
        display: none;
      }
    }
    &.expanded {
      .ProseMirror__contentDOM {
        display: block;
      }
    }
  }
`;
