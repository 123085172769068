import React from 'react';
import { WithPermission } from '../../permissions';
import ForbiddenNotification from '../ForbiddenNotification';
import { ApplicationIdentifier } from '../../types';

const getAppPermission = (
  appContext: ApplicationIdentifier
): string | undefined => {
  const permissionsMap: { [key in ApplicationIdentifier]?: string } = {
    [ApplicationIdentifier.TSD_EDITOR]: AzureGroupsConfig.AppAccessTsd,
    [ApplicationIdentifier.EVALUATOR]: AzureGroupsConfig.AppAccessEval,
    [ApplicationIdentifier.OFFICE_ACTION_HERO]: AzureGroupsConfig.AppAccessOah,
    [ApplicationIdentifier.DDUDE]: AzureGroupsConfig.AppAccessDdude,
    [ApplicationIdentifier.COMPETITOR]: AzureGroupsConfig.AppAccessComp,
    [ApplicationIdentifier.PATENTOR]: AzureGroupsConfig.AppAccessPat,
    [ApplicationIdentifier.LIFTOFF]: AzureGroupsConfig.AppAccessFto,
  };
  return permissionsMap[appContext];
};

const WithAppPermission: React.FC<
  React.PropsWithChildren<{ appContext: ApplicationIdentifier }>
> = ({ children, appContext }) => {
  const permission = getAppPermission(appContext) || [];

  return (
    <WithPermission
      permissions={permission}
      forbiddenComponent={<ForbiddenNotification />}
    >
      {children}
    </WithPermission>
  );
};

export default WithAppPermission;
