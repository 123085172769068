import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '@ipos/shared';
import { QueryKey } from 'queries';

const useTsdElementUploads = (
  tsdElementId: string,
  tsdNodeId?: string,
  options?: UseQueryOptions<T.TsdElementUpload[], AxiosError>
): UseQueryResult<T.TsdElementUpload[], AxiosError> =>
  useQuery<T.TsdElementUpload[], AxiosError>(
    [QueryKey.TsdElementUploads, tsdElementId, tsdNodeId],
    () => Api.tsd.getTsdElementUploads(tsdElementId, tsdNodeId),
    options
  );

export default useTsdElementUploads;
