/* eslint-disable @typescript-eslint/no-explicit-any */
export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomString(length = 10): string {
  const result = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join('');
}

export function randomDate(start: Date, end = new Date()): Date {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}
type TreeLikeNode<T> = T & {
  children: TreeLikeNode<T>[];
  id: string;
};

function visitNode<T>(
  node: TreeLikeNode<T>,
  hashMap: Record<string, boolean>,
  array: TreeLikeNode<T>[]
) {
  if (!hashMap[node.id]) {
    hashMap[node.id] = true;
    array.push(node);
  }
}

function hasChildren<T>(t: TreeLikeNode<T>): boolean {
  return t.children?.length > 0;
}

export function convertTreeToArray<T>(
  root?: TreeLikeNode<T>
): Array<TreeLikeNode<T>> {
  if (!root) {
    return [];
  }

  const stack: TreeLikeNode<T>[] = [],
    array: TreeLikeNode<T>[] = [],
    hashMap = {};
  stack.push(root);

  while (stack.length !== 0) {
    const node = stack.pop();
    if (!node) {
      break;
    }
    if (!hasChildren(node)) {
      visitNode<T>(node, hashMap, array);
    } else {
      for (let i: number = node.children.length - 1; i >= 0; i--) {
        stack.push(node.children[i]);
      }
    }
  }

  return array;
}

export function randomBoolean(): boolean {
  return Math.random() < 0.5;
}

export function randomEnum<
  T extends { [s: string]: unknown } | ArrayLike<unknown>
>(anEnum: T): T[keyof T] {
  const enumValues = Object.values(anEnum) as unknown as T[keyof T][];
  // https://stackoverflow.com/questions/56044872/typescript-enum-object-values-return-value
  // Read more in link above
  if (enumValues.some((ev) => typeof ev === 'number')) {
    const filteredValues = enumValues.filter((v) => typeof v === 'number');
    return filteredValues[Math.floor(Math.random() * filteredValues.length)];
  }
  return enumValues[Math.floor(Math.random() * enumValues.length)];
}
