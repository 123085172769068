import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover, Button } from 'antd';
import { useSessionContext } from '../../contexts';
import { AvatarCircle } from '../Avatar';
import { NavigationItem } from '../Layout';
import { useTranslation } from '../../translations';
import { msalInstance } from '../../auth/msalInstance';

const SessionMenu: React.FC = () => {
  const t = useTranslation();
  const [isActive, setActive] = useState(false);
  const { session } = useSessionContext();
  const { fullName, ownerGroupName, email } = session;

  const handleSignoutClick = (): void => {
    // TODO: Research and implement logoutRedirect
    msalInstance.logout();
  };

  const content: JSX.Element = (
    <Outer>
      <BasicInfo>
        <AvatarCircle displayName={fullName} size={40} />
        <FullName>{fullName}</FullName>
      </BasicInfo>
      <AdditionalInfo>
        <OwnerGroupName>{ownerGroupName}</OwnerGroupName>
        <div>{email}</div>
        <Button
          type="primary"
          onClick={handleSignoutClick}
          style={{ marginTop: 15 }}
        >
          {t('SIGN_OUT')}
        </Button>
      </AdditionalInfo>
    </Outer>
  );

  return (
    <Popover
      placement="rightTop"
      content={content}
      trigger="click"
      destroyTooltipOnHide
      onOpenChange={(visible) => setActive(visible)}
    >
      <NavigationItem
        iconClassName="icn-user-avatar"
        tooltipText={t('NAVIGATION_TOOLTIPS.PROFILE')}
        iconStyle={{ fontSize: 34 }}
        active={isActive}
        isSettingsItem
      />
    </Popover>
  );
};

export default SessionMenu;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const BasicInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FullName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const OwnerGroupName = styled.div`
  font-weight: bold;
`;
