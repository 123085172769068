import { T } from '../..';
import Api from './base';

const API_BASE = '/v1/owner-group';

export const getSettings = (): Promise<T.OwnerGroupSettings> =>
  Api.get(`${API_BASE}/settings`).then((response) => response.data);

export const getPayPerUseServiceStatus = (
  service: T.PayPerUseServiceType
): Promise<T.PayPerUseServiceStatus> =>
  Api.get(`${API_BASE}/pay-per-use/${service}/status`).then(
    (response) => response.data
  );
