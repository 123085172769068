import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { useGetTsdElementIpcClasses } from '../../../queries';
import Modal from '../../Modal';
import { Icon } from '../../Icon';
import TechFieldsOverview from './TechFieldsOverview';

interface Props {
  tsdNodeName: string;
  tsdNodeId: string;
  tsdElementId: string;
  onCloseRequest: () => void;
}

const TechFieldsModal: React.FC<Props> = ({
  tsdNodeName,
  tsdNodeId,
  tsdElementId,
  onCloseRequest,
}) => {
  const t = useTranslation();
  const { data: cpcclasses, isLoading } = useGetTsdElementIpcClasses(
    tsdElementId,
    tsdNodeId
  );

  return (
    <Modal
      onCancel={onCloseRequest}
      title={
        <Flex>
          <ModalHeader>
            {t('COMPETITOR.TECH_FIELD_ANALYSIS.MODAL_TITLE')}
          </ModalHeader>
          <TsdElement>
            <Icon className="icn-node" size={24} />
            {tsdNodeName}
          </TsdElement>
        </Flex>
      }
      width="90%"
      style={{
        maxWidth: '800px',
      }}
      bodyStyle={{
        maxHeight: '600px',
        minHeight: 0,
        display: 'flex',
      }}
      open={true}
      footer={null}
    >
      <TechFieldsOverview cpcclasses={cpcclasses} isLoading={isLoading} />
    </Modal>
  );
};

export default TechFieldsModal;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalHeader = styled.div`
  display: flex;
  font-size: 24px;
`;

const TsdElement = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  margin-left: 15px;
  gap: 10px;
`;
