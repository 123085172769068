import { Utils } from '@ipos/shared';
const { urlGenerator } = Utils.Routing;

const ROUTES = {
  TSD_ELEMENT_ROOT: {
    id: 'TSD_ELEMENT_ROOT',
    path: '/tsd-elements/:tsdElementId',
  },
  TSD_ELEMENTS: {
    id: 'TSD_ELEMENTS',
    path: '/tsd-elements',
  },
  TSD_NODE: {
    id: 'TSD_NODE',
    path: '/tsds/:tsdId/nodes/:tsdNodeId',
  },
  TSD_ROOT: {
    id: 'TSD_ROOT',
    path: '/tsds/:tsdId',
  },
  HOME: {
    id: 'HOME',
    path: '/',
  },
};

export default ROUTES;

const generateUrl = urlGenerator(ROUTES);
export { generateUrl };
