import type { Node } from 'prosemirror-model';
import { EditorState, Plugin } from 'prosemirror-state';
import {
  makeBlockMathInputRule,
  makeInlineMathInputRule,
  mathPlugin,
  REGEX_BLOCK_MATH_DOLLARS,
  REGEX_INLINE_MATH_DOLLARS,
} from '@benrbray/prosemirror-math';
import { inputRules } from 'prosemirror-inputrules';
import type { ReactPortal } from 'react';
import { schema } from '../schema/schema';
import {
  claimNumber,
  claimPartNumber,
  claimPartsPlugin,
  claimsPlugin,
  markSelectionAsDeletedPlugin,
  preventEmptyParagraphRemovalPlugin,
  preventNodeSelectionPlugin,
  search,
} from '../plugins';
import { basePlugins } from '../config/plugins';
import { trackChangesPlugin } from '../trackChangesPlugin/trackChangesPlugin';
import {
  ClaimEditorDocumentResponse,
  EditorDocument,
  PatentOffice,
} from '../../../types';
import imageClaimPlugin from '../images/imageClaimPlugin';
import { removeEmptyClaimPartPlugin } from '../plugins/removeEmptyClaimPartPlugin';

export interface EditorStateProps {
  parsedDoc?: Node;
  documentId: string;
  editable:
    | false
    | {
        referenceDoc: EditorDocument | null;
      };
  handleCreatePortal: (portal: ReactPortal) => void;
  isCleanCopy?: boolean;
  updateOrChangeClaimRevision?: (data: ClaimEditorDocumentResponse) => void;
  patentOffice?: PatentOffice;
}

const inlineMathInputRule = makeInlineMathInputRule(
  REGEX_INLINE_MATH_DOLLARS,
  schema.nodes.math_inline
);
const blockMathInputRule = makeBlockMathInputRule(
  REGEX_BLOCK_MATH_DOLLARS,
  schema.nodes.math_display
);

export function createEditorState({
  parsedDoc,
  isCleanCopy = false,
  editable,
  documentId,
  updateOrChangeClaimRevision,
  handleCreatePortal,
  patentOffice,
}: EditorStateProps) {
  const commonPlugins: Plugin[] = [
    mathPlugin,
    preventNodeSelectionPlugin({ nodeTypes: ['claim', 'section'] }),
    claimsPlugin({
      editorContentId: documentId,
      ignoreTrackChanges: isCleanCopy,
      handleClaimInsertSuccess: updateOrChangeClaimRevision,
      patentOffice,
    }),
    claimPartsPlugin({ ignoreTrackChanges: true }),
    claimNumber(),
    claimPartNumber(),
    imageClaimPlugin({
      schema,
      editorDocumentId: documentId,
      handleCreatePortal,
      handleUploadSuccess: updateOrChangeClaimRevision,
      isCleanCopy,
    }),
    removeEmptyClaimPartPlugin,
    ...basePlugins,
  ];

  const editablePlugins: Plugin[] = !editable
    ? []
    : [
        markSelectionAsDeletedPlugin(),
        preventEmptyParagraphRemovalPlugin,
        inputRules({ rules: [inlineMathInputRule, blockMathInputRule] }),
        trackChangesPlugin({
          initialChanges: [],
          referenceDoc: editable.referenceDoc,
          disableRendering: true,
        }),
        search(),
      ];

  const plugins = commonPlugins.concat(editablePlugins);

  return EditorState.create({
    schema,
    doc: parsedDoc,
    plugins,
  });
}
