import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { PrimaryButton, VoidButton } from '../../Button';
import { PatentApplicationStakeholder } from '../../../types';
import ContactEntry from './ContactEntry';

interface Props {
  stakeholder: PatentApplicationStakeholder;
  onCancel: () => void;
  onRemove: (stakeholder: PatentApplicationStakeholder) => void;
}

const RemoveStakeholder: React.FC<Props> = ({
  stakeholder,
  onCancel,
  onRemove,
}) => {
  const t = useTranslation();
  return (
    <Outer>
      <Message>{t('STAKEHOLDERS.REMOVE_STAKEHOLDER_MESSAGE')}</Message>
      <StakeholderWrapper>
        <ContactEntry
          title={stakeholder.contact?.displayName}
          subtitle={t(`STAKEHOLDERS.ROLES.${stakeholder?.role?.toUpperCase()}`)}
        />
      </StakeholderWrapper>
      <Actions>
        <VoidButton onClick={onCancel}>{t('ACTION.CANCEL')}</VoidButton>
        <PrimaryButton onClick={() => onRemove(stakeholder)}>
          {t('ACTION.REMOVE')}
        </PrimaryButton>
      </Actions>
    </Outer>
  );
};

const Message = styled.span`
  margin-bottom: 20px;
`;
export const Actions = styled.span`
  display: flex;
  margin-top: 30px;
  align-self: flex-end;
  button {
    margin: 0;
    & + button {
      margin-left: 10px;
    }
  }
`;
const StakeholderWrapper = styled.div`
  border: solid 1px ${(props) => props.theme.colors.primary200_40};
  flex: 1;
  padding: 15px;
  border-radius: 4px;
`;

const Outer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 40px 40px;
`;

export default RemoveStakeholder;
