import { MarkSpec } from 'prosemirror-model';

const underline: MarkSpec = {
  parseDOM: [{ tag: 'u' }, { style: 'text-decoration=underline' }],
  toDOM() {
    return ['span', { style: 'text-decoration:underline' }, 0];
  },
};

export default underline;
