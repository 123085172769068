import React from 'react';
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Space, Typography } from 'antd';
import styled from 'styled-components';
import {
  DeleteTsdMenuOption,
  AddTsdNodeMenuOption,
  DeleteTsdNodeMenuOption,
  ImportNodesMenuOption,
  PublishTsdMenuOption,
  ExportTsdMenuOption,
} from '../MenuOptions';
import TsdElementNode from './TsdElementNode';
import TsdPublishIndicator from './TsdPublishIndicator';
import { ContentLanguage } from 'components/common';
import { Components, T } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import { useTsd } from 'queries';
import useTranslation from 'translations';

const { Layout, PageLoad, TsdTree, LabelValue, Aad } = Components;
const { Grid, GridItem, Surface, AppHeader } = Layout;
const { UserContextProvider, UserAvatar, UserDetails } = Aad;
const { Title } = Typography;

const Tsd: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();
  const { tsdId } = useParams<{ tsdId: string }>();
  const match = useRouteMatch<{ tsdNodeId: string }>(ROUTES.TSD_NODE.path);
  const selectedTsdNodeId = match?.params.tsdNodeId;
  const tsdQuery = useTsd(tsdId);

  if (tsdQuery.isLoading || tsdQuery.isIdle) {
    return <PageLoad />;
  }
  if (tsdQuery.isError) {
    return <PageLoad error />;
  }

  const { data: tsd } = tsdQuery;

  const handleTsdNodeSelect = (tsdNode: T.TsdNode) => {
    history.push(
      generateUrl(ROUTES.TSD_NODE, { tsdId, tsdNodeId: tsdNode.id })
    );
  };

  return (
    <>
      <AppHeader
        title={t('HEADER.TITLE.TSD_TREE')}
        subtitle={tsd.name}
        backProps={{
          to: generateUrl(ROUTES.HOME),
        }}
      />
      <Grid rowOverrides={[[1, '50px']]}>
        <GridItem position={[1, 1, 1, 12]}>
          <Outer>
            <Inner>
              <LabelValue label={t('CREATE_TSD.NAME')}>
                <TsdInfo>
                  <Title level={3}>{tsd.name}</Title>
                  <ContentLanguage />
                  <TsdPublishIndicator
                    isPublished={tsd.isPublished}
                    lastPublishedOn={tsd.lastPublishedOn}
                  />
                </TsdInfo>
              </LabelValue>
              <MenuBar>
                {selectedTsdNodeId && (
                  <>
                    <AddTsdNodeMenuOption
                      tsdId={tsdId}
                      tsdNodeId={selectedTsdNodeId}
                    />
                    <DeleteTsdNodeMenuOption
                      tsdId={tsdId}
                      disabled={tsd.rootNode.id === selectedTsdNodeId}
                      tsdNodeId={selectedTsdNodeId}
                    />
                    <ImportNodesMenuOption
                      tsdId={tsdId}
                      tsdNodeId={selectedTsdNodeId}
                    />
                  </>
                )}
                <PublishTsdMenuOption tsd={tsd} />
                <DeleteTsdMenuOption tsd={tsd} />
                <ExportTsdMenuOption tsdId={tsd.id} />
              </MenuBar>
            </Inner>
            {tsd.createdById && (
              <UserContextProvider userId={tsd.createdById}>
                <LabelValue label={t('TSD.CREATED_BY')}>
                  <Space>
                    <UserAvatar avatarSize={28} />
                    <UserDetails showEmail={false} />
                  </Space>
                </LabelValue>
              </UserContextProvider>
            )}
          </Outer>
        </GridItem>
        <GridItem position={[2, 7, 1, 5]}>
          <Surface type="light">
            <TsdContent>
              <TsdTree
                root={tsd.rootNode}
                draggable
                selectable
                selectedTsdNodeId={selectedTsdNodeId}
                onSelect={handleTsdNodeSelect}
              />
            </TsdContent>
          </Surface>
        </GridItem>
        <GridItem position={[2, 7, 6, 7]}>
          <Surface type="dark">
            <NodeDetails>
              <Route path={ROUTES.TSD_NODE.path} component={TsdElementNode} />
            </NodeDetails>
          </Surface>
        </GridItem>
      </Grid>
    </>
  );
};

export default Tsd;

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const TsdInfo = styled.div`
  display: flex;
  align-items: center;
`;

const TsdContent = styled.div`
  padding: 20px;
  height: 100%;
  min-height: 0;
`;

const NodeDetails = styled.div`
  padding: 30px;
  height: 100%;
  min-height: 0;
`;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
`;
