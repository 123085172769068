import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { useRouteMatch, RouteProps, Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Colors } from '../../../theme';
import { truncate } from '../../../utils';

export interface Props {
  icon?: React.ReactNode;
  iconClassName?: string; // use with philipa icon fonts
  iconStyle?: React.CSSProperties;
  tooltipText?: string;
  wrapperStyle?: React.CSSProperties;
  label?: string;
  to?: LocationDescriptor;
  disabled?: boolean;
  active?: boolean;
  activePath?: string | string[] | RouteProps;
  testId?: string;
  isSettingsItem?: boolean;
  onClick?: () => void;
}

const NavigationItem: React.FC<Props> = ({
  icon,
  iconClassName,
  iconStyle,
  tooltipText,
  wrapperStyle,
  label,
  to,
  disabled,
  active,
  activePath,
  testId,
  isSettingsItem,
  onClick,
}) => {
  const match = useRouteMatch(activePath ?? {});
  const isActive = active || !!match;

  const handleClick = () => {
    onClick?.();
  };

  let content = (
    <Outer
      isDisabled={disabled}
      isActive={isActive}
      data-testid={testId}
      onClick={handleClick}
      isSettingsItem={isSettingsItem}
      title={label}
      style={wrapperStyle}
    >
      {icon ||
        (iconClassName && <i className={iconClassName} style={iconStyle} />)}
      {label && <Label>{label}</Label>}
    </Outer>
  );

  if (to != null) {
    content = (
      <StyledLink to={to} $disabled={disabled}>
        {content}
      </StyledLink>
    );
  }

  if (tooltipText != null) {
    content = (
      <Tooltip
        overlayStyle={{ marginTop: 4 }}
        overlayInnerStyle={{
          backgroundColor: Colors.primary300,
          backgroundImage: 'none',
          fontSize: 12,
          color: Colors.white87,
          minHeight: 0,
          borderRadius: 3,
        }}
        placement="right"
        title={tooltipText}
        color={Colors.primary300}
        destroyTooltipOnHide
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default NavigationItem;

const Outer = styled.div<{
  isDisabled?: boolean;
  isActive?: boolean;
  isSettingsItem?: boolean;
}>`
  ${(props) => `height: ${props.isSettingsItem ? '54px' : '70px'};`}
  width: 80px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  gap: 5px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
  i {
    font-size: 24px;
  }
  & > * {
    color: ${(props) => props.theme.colors.primary100};
    transition: color 0.3s ease;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    & > * {
      ${(props) =>
        props.isSettingsItem && `color: ${props.theme.colors.white50};`}
    }
  }
  &:active {
    & > * {
      color: ${(props) =>
        props.isSettingsItem
          ? props.theme.colors.white50
          : props.theme.colors.white87};
    }
    background-color: ${(props) => props.theme.colors.primary200_20};
  }
  ${(props) =>
    props.isActive &&
    `& > * { color: ${props.theme.colors.white87} !important; }`}
  ${(props) =>
    props.isDisabled &&
    `& > * { color: rgba(255, 255, 255, 0.1) }
  pointer-events: none;`}
`;

const Label = styled.div`
  width: 100%;
  font-size: 11px;
  line-height: 1.64;
  transition: all 0.3s ease;
  text-align: center;
  ${truncate()}
`;

const StyledLink = styled(Link)<{ $disabled?: boolean }>`
  cursor: pointer;
  ${(props) => props.$disabled && `cursor: auto; pointer-events: none;`}
`;
