import { QueryClient } from 'react-query';

const defaultConfiguration = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
};

export const createQueryClient = (config = defaultConfiguration): QueryClient =>
  new QueryClient(config);
const defaultQueryClient = createQueryClient();

export { defaultQueryClient as queryClient };
