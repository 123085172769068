import React from 'react';
import styled from 'styled-components';

interface Props {
  icon: React.ReactNode;
  title?: string;
  size?: number;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const StyledIconButton: React.FC<Props> = ({
  icon,
  title,
  size = 24,
  className,
  onClick,
}) => (
  <Button title={title} size={size} className={className} onClick={onClick}>
    {icon}
  </Button>
);

export default StyledIconButton;

// TODO: Convert into actual button
const Button = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size + 10}px;
  height: ${(props) => props.size + 10}px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  i {
    font-size: ${(props) => props.size}px;
    transition: color 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.white40};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
    i {
      color: ${(props) => props.theme.colors.white70};
    }
  }
`;
