import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import useTranslation from '../../translations';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ColumnFilterSearchInput: React.FC<Props> = ({ value, onChange }) => {
  const t = useTranslation();
  return (
    <Outer>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('ITEM_LIST.FILTERING.SEARCH')}
        prefix={<i className="icn-search" />}
        allowClear
      />
    </Outer>
  );
};

export default ColumnFilterSearchInput;

const Outer = styled.div`
  margin-bottom: 15px;
  .ant-input-affix-wrapper,
  .ant-input {
    background-color: transparent;
  }
`;
