import React, { useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { useInfiniteQuery, useDebounce } from '../../hooks';
import { Language, Tsd, TsdQueryParams } from '../../types';
import { Api } from '../../..';
import { Spinner } from '../Spinner';
import useTranslation from '../../translations';

interface Props {
  language?: Language;
  defaultSearchValue?: string;
  autoFocus?: boolean;
  onSelect: (tsd: Tsd) => void;
}

const TsdSelector: React.FC<Props> = ({
  language,
  defaultSearchValue,
  autoFocus,
  onSelect,
}) => {
  const t = useTranslation();
  const [search, setSearch] = useState<string>(defaultSearchValue ?? '');
  const debouncedSearch = useDebounce(search, 300);

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    infiniteData: tsds,
  } = useInfiniteQuery<Tsd, TsdQueryParams>({
    queryParams: {
      searchTerm: debouncedSearch,
      language,
      includeNeverPublished: true,
    },
    queryKey: 'tsd_selection',
    apiFunction: Api.tsd.searchTsds,
  });

  const handleSearch = (value: string) => setSearch(value);

  const handleSelect = (tsdId: string) => {
    const selectedTsd = tsds.find((tsd) => tsd.id === tsdId);
    if (selectedTsd) {
      onSelect(selectedTsd);
    }
  };

  const handlePopupScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target as HTMLElement;
    const shouldFetchNextPage = scrollTop + offsetHeight === scrollHeight;

    if (shouldFetchNextPage && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Select
      showSearch
      allowClear
      style={{ width: '100%' }}
      placeholder={t('TSD_SELECTOR.PLACEHOLDER')}
      defaultValue={defaultSearchValue}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
      defaultActiveFirstOption={false}
      filterOption={false}
      autoFocus={autoFocus}
      dropdownRender={(menu) =>
        isLoading ? (
          <SpinWrapper>
            <Spinner />
          </SpinWrapper>
        ) : (
          menu
        )
      }
    >
      {tsds.map(({ id, name }) => (
        <Select.Option key={id} value={id} label={name}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TsdSelector;

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
`;
