import React from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  value: number | undefined;
  warnings?: string[];
  testId?: string;
}

const Number: React.FC<Props> = ({ label, value, testId }) => (
  <Outer data-testid={testId}>
    <Value>{value ?? '-'}</Value>
    <Label>{label}</Label>
  </Outer>
);

export default Number;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  font-weight: 100;
  letter-spacing: 4px;
`;

const Value = styled.div`
  font-size: 34px;
  line-height: 32px;
`;

const Label = styled.div`
  font-size: 14px;
`;
