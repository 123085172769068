import React from 'react';
import styled from 'styled-components';
import { Placeholder, PageError } from '../../../../../icons';
import { DolphinLoader } from '../../../../DolphinLoader';
import useTranslation from '../../../../../translations';

interface Props {
  status: 'loading' | 'error' | 'no-results';
}

const SearchStatus: React.FC<Props> = ({ status }) => {
  const t = useTranslation();

  const config = {
    ['loading']: {
      icon: (
        <DolphinLoader
          style={{
            width: 120,
            height: 80,
          }}
        />
      ),
      message: t('MULTI_DOC_SEARCH.SEARCHING'),
    },
    ['error']: {
      icon: <PageError width={100} height={100} />,
      message: t('MULTI_DOC_SEARCH.ERROR'),
    },
    ['no-results']: {
      icon: <Placeholder width={100} height={100} />,
      message: t('MULTI_DOC_SEARCH.NO_RESULTS'),
    },
  };

  const { icon, message } = config[status];

  return (
    <Status>
      {icon}
      {message}
    </Status>
  );
};

export default SearchStatus;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white70};
`;
