import { T } from '../..';
import { Language } from './language';
import { BaseDto } from './common';

export interface AiTemplatesParams {
  publicationId: string;
  prompt: PredefinedAiPrompt;
  responseLanguage: Language;
  term?: string;
}

export interface AiPrompt {
  text: string;
  language: Language;
  references: AiPromptReference[];
}

export interface StoredAiPrompt extends AiPrompt, BaseDto {
  name: string;
}

export interface CreateAiPrompt extends AiPrompt {
  name: string;
}

export interface UpdateAiPrompt extends AiPrompt {
  id: string;
  name: string;
}

export interface ExecuteStoredAiPrompt {
  id: string;
  referenceInstances: AiPromptReferenceInstance[];
  maxTokens: number;
}

export interface ExecuteAiPrompt extends Omit<ExecuteStoredAiPrompt, 'id'> {
  prompt: AiPrompt;
}

export interface AiPromptReference extends BaseDto {
  positionInPrompt: number;
  text: string;
  type: AiPromptReferenceType;
}

export enum AiPromptReferenceType {
  ApplicationAbstract = 'ApplicationAbstract',
  ApplicationClaims = 'ApplicationClaims',
  ApplicationDescription = 'ApplicationDescription',
  PublicationAbstract = 'PublicationAbstract',
  PublicationClaims = 'PublicationClaims',
  PublicationDescription = 'PublicationDescription',
  OfficeActionText = 'OfficeActionText',
  DocumentText = 'DocumentText',
}

export interface AiPromptReferenceInstance {
  referenceId: string;
  dataEntityId: string;
}

export interface AiResponse {
  text: string;
  numTokens: number;
}

export type AiChatParticipant = 'user' | 'ai';
export type AiChatMessage = { text: string; sender: AiChatParticipant };

export enum PredefinedAiPrompt {
  ClaimExplainer = 'ClaimExplainer',
  TechnicalEffects = 'TechnicalEffects',
  TechnicalEmbodiments = 'TechnicalEmbodiments',
  PhraseInterpretation = 'PhraseInterpretation',
  AddReferenceNumbersToClaims = 'AddReferenceNumbersToClaims',
  CreateReferenceListFromDescription = 'CreateReferenceListFromDescription',
}

export enum AiSupportReceiver {
  Attorney = 'Attorney',
  Engineer = 'Engineer',
  Paralegal = 'Paralegal',
  Examiner = 'Examiner',
}

export enum AiAssistanceResponseSize {
  Long = 'Long',
  Medium = 'Medium',
  Short = 'Short',
}

export enum AiAssistantAttachmentType {
  PdfDocument = 'PdfDocument',
  DescriptionEditorDocument = 'DescriptionEditorDocument',
  Claim = 'Claim',
  PriorArtPdfDocument = 'PriorArtPdfDocument',
}

export enum AssistantType {
  OfficeActionClarityDefender = 'OfficeActionClarityDefender',
  OfficeActionSummary = 'OfficeActionSummary',
  OfficeActionUnityDefender = 'OfficeActionUnityDefender',
  OfficeActionNoveltyDefender = 'OfficeActionNoveltyDefender',
  OfficeActionUnsupportedAmendmentsDefender = 'OfficeActionUnsupportedAmendmentsDefender',
}

export type AiAttachmentItem = {
  item1: string;
  item2: AiAssistantAttachmentType;
};

export interface AiAssistantRequest {
  responseLanguage: T.PatentDraftLanguage;
  responseSize: AiAssistanceResponseSize;
  receiver: AiSupportReceiver;
  attachmentIds: AiAttachmentItem[];
}

export interface AiSubmitForm {
  assistantType: T.AssistantType;
  payload: T.AiAssistantRequest;
  priorArtCitationNumber: number;
  claimNumber: number;
}

export interface AiAssistantResponse extends BaseDto {
  assistantType: T.AssistantType;
  responseLanguage: T.PatentDraftLanguage;
  responseSize: AiAssistanceResponseSize;
  receiver: AiSupportReceiver;
  attachmentIds: AiAttachmentItem[];
  responseText: string;
}

export enum AiResponseError {
  ServiceNotEnabled = 'ServiceNotEnabled',
  ServiceMonthlyLimitExceeded = 'ServiceMonthlyLimitExceeded',
  ServiceSingleRequestLimitExceeded = 'ServiceSingleRequestLimitExceeded',
  ThirdPartyServiceDown = 'ThirdPartyServiceDown',
  InternalCheckFailed = 'InternalCheckFailed',
}
