import * as reactI18next from 'react-i18next';
import i18next, { TOptions, StringMap } from 'i18next';
import { APP_LANGUAGES } from '../constants';
import { format } from '../utils/formatters';
import { getCurrentLanguage, getDefaultLanguage } from '../utils/localization';

import en from './en-US/shared.json';
import de from './de-de/shared.json';

interface TranslationResources {
  [lang: string]: Record<string, unknown>;
}

const LANG = getCurrentLanguage() || getDefaultLanguage();
const registeredNamespaces: string[] = [];

const registerNamespace = (
  namespace: string,
  translations: TranslationResources
): void => {
  Object.keys(translations).forEach((lang: string) => {
    i18next.addResourceBundle(lang, namespace, translations[lang]);
  });
  registeredNamespaces.unshift(namespace);
};

i18next.use(reactI18next.initReactI18next).init({
  lng: LANG.languageCode,
  fallbackLng: APP_LANGUAGES.find((lang) => lang.default)?.languageCode,
  interpolation: {
    escapeValue: false,
    format,
  },
});

// register shared translation files now
registerNamespace('shared', { en, de });

const useTranslation = (): reactI18next.TFunction<string> => {
  const { t } = reactI18next.useTranslation();

  const tWrapped = (
    key: string | string[],
    options?: TOptions<StringMap> | string
  ): string => {
    for (const ns of registeredNamespaces) {
      if (i18next.exists(key, { ns })) {
        return t(`${ns}:${key}`, options);
      }
    }

    if (options && typeof options !== 'string' && options.defaultValue) {
      return options.defaultValue;
    }

    console.warn(
      `i18n: no translation found for key ${key} by looking into ${registeredNamespaces.join(
        ', '
      )} namespaces`
    );

    // make the not translated key stand out
    return `!!${key}!!`;
  };

  return tWrapped;
};

export { registerNamespace, useTranslation, i18next };
export default useTranslation;
