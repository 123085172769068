import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { Button, Modal, Space, message } from 'antd';
import ImportTsdNodesTree from './ImportTsdNodesTree';
import { T, Api, Components, Hooks } from '@ipos/shared';
import useTranslation from 'translations';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';

const { TsdSelector } = Components;
const { useInvalidateQueries } = Hooks;

interface Props {
  tsdId: string;
  tsdNodeId: string;
  isOpen: boolean;
  onClose: () => void;
}

const ImportTsdNodesModal: React.FC<Props> = ({
  tsdId,
  tsdNodeId,
  isOpen,
  onClose,
}) => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const { invalidateQueries, isInvalidating } = useInvalidateQueries();

  const [selectedTsdId, setSelectedTsdId] = useState<string | null>(null);
  const [checkedTsdNodeIds, setCheckedTsdNodeIds] = useState<string[]>([]);

  const importNodesMutation = useMutation(
    (importNodeData: T.ImportTsdNode) => Api.tsd.importNodes(importNodeData),
    {
      onSuccess: async () => {
        message.success(t('IMPORT_NODES.SUCCESS'));
        await invalidateQueries([QueryKey.TsdData, tsdId]);
        onClose();
      },
      onError: () => {
        message.error(t('SOMETHING_WENT_WRONG'));
      },
    }
  );

  const handleTsdSelect = (tsd: T.Tsd) => {
    setSelectedTsdId(tsd.id);
  };

  const handleNodeCheck = (tsdNodeIds: string[]) => {
    setCheckedTsdNodeIds(tsdNodeIds);
  };

  const handleImportNodes = () => {
    if (!selectedTsdId) {
      return;
    }
    importNodesMutation.mutate({
      sourceTsdId: selectedTsdId,
      destinationTsdId: tsdId,
      importToTsdNodeId: tsdNodeId,
      tsdNodeIds: checkedTsdNodeIds,
    });
  };

  return (
    <Modal
      style={{ top: 60 }}
      width={800}
      onCancel={onClose}
      destroyOnClose
      title={t('IMPORT_NODES.TITLE')}
      open={isOpen}
      footer={null}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <TsdSelector
          onSelect={handleTsdSelect}
          language={preferredLanguage}
          autoFocus
        />
        <ImportTsdNodesTree tsdId={selectedTsdId} onCheck={handleNodeCheck} />
        <Footer>
          <Space>
            <Button onClick={onClose}>{t('ACTION.CANCEL')}</Button>
            <Button
              type="primary"
              disabled={checkedTsdNodeIds.length === 0}
              loading={importNodesMutation.isLoading || isInvalidating}
              onClick={handleImportNodes}
            >
              {t('IMPORT_NODES.CONFIRM')}
            </Button>
          </Space>
        </Footer>
      </Space>
    </Modal>
  );
};

export default ImportTsdNodesModal;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
