import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import useTranslation from '../../translations';
import { NavigationItem } from '../Layout';
import { useSessionContext } from '../../contexts';

dayjs.extend(utc);
dayjs.extend(tz);

const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm';

const openBugReportEmail = (email: string) => {
  try {
    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    const day = dayjs().utc().tz('Europe/Berlin');
    const reportDate = day.format(DATE_FORMAT);
    const reportTime = day.format(TIME_FORMAT);
    const subject = encodeURIComponent(`Bug on ${parsedUrl.host}`);
    const bodyTemplate = `Bug Report\n\nDate: ${reportDate}\nBerlin Time: ${reportTime}\n\nUser: ${email}\n\nScreen on which bug appeared:\n${currentURL}\n\n\nShort description:\n\n\n\nSteps to reproduce:\n\n\n\nActual result:\n\n\n\nExpected result:\n\n\n\nScreenshot/video:\n\n\n\n`;
    const encodedBody = encodeURIComponent(bodyTemplate);

    window.location.href = `mailto:support@philipa.tech?subject=${subject}&body=${encodedBody}`;
  } catch (error) {
    console.error('Error generating bug report email:', error);
  }
};

const BugReport: React.FC = () => {
  const t = useTranslation();
  const {
    session: { email },
  } = useSessionContext();

  return (
    <NavigationItem
      iconClassName="icn-bug"
      tooltipText={t('NAVIGATION_TOOLTIPS.BUG_REPORT')}
      iconStyle={{ fontSize: 34 }}
      isSettingsItem
      onClick={() => openBugReportEmail(email)}
    />
  );
};

export default BugReport;
