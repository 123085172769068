import React from 'react';
import styled from 'styled-components';
import useTranslation from 'translations';

interface Props {
  children: JSX.Element[];
  header?: React.ReactNode;
  title: string;
}

const QueryBuilderSegment: React.FC<Props> = ({ header, children, title }) => {
  const t = useTranslation();
  return (
    <Outer>
      <Title>{title}</Title>
      {children.length > 0 ? (
        <ScrollContainer>
          <Header>{header}</Header>
          {children}
        </ScrollContainer>
      ) : (
        <NoData>{t('QUERY.NO_SEGMENT_DATA')}</NoData>
      )}
    </Outer>
  );
};

export default QueryBuilderSegment;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 20px;
  }
  max-height: 380px;
  background-color: ${(props) => props.theme.colors.primary900};
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: dimgray;
  font-size: 16px;
  padding: 10px;
`;

const NoData = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScrollContainer = styled.div`
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  background-color: ${(props) => props.theme.colors.primary900};
  top: 0;
  left: 0;
  padding: 0 10px;
  height: 30px;
  z-index: 999;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary300};
`;
