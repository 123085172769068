import React from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { ListFilterItem } from '../../types';
import useTranslation from '../../translations';

interface Props {
  filter: ListFilterItem;
  checked?: boolean;
  onChange: (id: string | null, checked: boolean) => void;
}

const ColumnFilterItem: React.FC<Props> = ({ checked, onChange, filter }) => {
  const t = useTranslation();
  const { id, value, title, count } = filter;
  const label = title || value;

  return (
    <Outer onClick={() => onChange(id, !checked)}>
      <Checkbox checked={checked}>
        {label ?? t('ITEM_LIST.FILTERING.UNASSIGNED')}
      </Checkbox>
      {count != null && <Count>{count}</Count>}
    </Outer>
  );
};

export default ColumnFilterItem;

const Outer = styled.div`
  display: flex;
  padding: 5px 8px;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
  transition: background-color 0.2s ease-in;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
  * {
    pointer-events: none;
  }
`;

const Count = styled.span`
  opacity: 0.8;
`;
