import { MarkSpec } from 'prosemirror-model';

const deleted: MarkSpec = {
  parseDOM: [{ tag: 'span' }, { style: 'text-decoration=deleted' }],
  toDOM() {
    return ['span', { class: 'deleted' }, 0];
  },
};

export default deleted;
