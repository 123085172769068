import React, { useState } from 'react';
import styled from 'styled-components';
import EditGraphicsButton from './EditGraphicsButton';
import GraphicsPreviewModal from './GraphicsPreviewModal';
import { Components } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import useTsdElementUploads from 'queries/useTsdElementUploads';
import useTranslation from 'translations';

const {
  Notification,
  Uploads: { Image },
} = Components;

const TsdElementGraphics: React.FC = () => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId, isLibraryView } = useTsdElementContext();
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);

  const uploadsQuery = useTsdElementUploads(tsdElementId, tsdNodeId);

  if (uploadsQuery.isError) {
    return <Notification error />;
  }

  const { data: uploads } = uploadsQuery;

  const handleItemClick = () => setIsGalleryOpen(true);

  let cover;

  // determine a cover image (until expressed explicitly by a flag)
  // uploads are sorted by 'isBaseAttribute'
  // if looking in library, take the first base attribute upload
  // if looking in a tsd node, take the first non base, default to any if no base
  if (uploads?.length) {
    cover =
      uploads.find((u) => u.isBaseAttribute === isLibraryView) || uploads[0];
  }

  return (
    <>
      <Outer>
        {cover ? (
          <Cover onClick={handleItemClick}>
            <Image uploadId={cover.upload.id} />
          </Cover>
        ) : (
          <NoItems>{t('GRAPHICS.NO_IMAGES_MSG')}</NoItems>
        )}
        <Actions>
          <EditGraphicsButton uploadsCount={uploads?.length} />
        </Actions>
      </Outer>
      {uploads && isGalleryOpen && (
        <GraphicsPreviewModal
          uploads={uploads}
          onCloseRequest={() => setIsGalleryOpen(false)}
        />
      )}
    </>
  );
};

export default TsdElementGraphics;

const Outer = styled.div`
  display: flex;
  width: 200px;
  height: 150px; // 4:3 ratio
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 4px;
  position: relative;
  background: ${(props) => props.theme.colors.primary300};
`;

const NoItems = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Actions = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;
