/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node } from 'prosemirror-model';

export const orderedListTypes = [
  'decimal',
  'lower-alpha',
  'lower-roman',
  'upper-alpha',
  'upper-roman',
  'bengali',
];
export const unorderedListTypes = ['bullet', 'circle', 'square', 'diamond'];

export function isListNode(node: Node): boolean {
  return node.type.name === 'list';
}

export function isOrderedListType(
  type: string
): type is (typeof orderedListTypes)[number] {
  return orderedListTypes.includes(type as any);
}

export function isUnorderedListType(
  type: string
): type is (typeof unorderedListTypes)[number] {
  return unorderedListTypes.includes(type as any);
}

export const listTypes = [...orderedListTypes, ...unorderedListTypes];

export type ListType = (typeof listTypes)[number];

function createOrderedListStyle(type: (typeof orderedListTypes)[number]) {
  return `
    .prosemirror-flat-list[data-list-kind=${type}] {
      counter-reset: prosemirror-flat-list-counter-${type};
      counter-increment: prosemirror-flat-list-counter-${type};
    }
    .prosemirror-flat-list[data-list-kind=${type}] + .prosemirror-flat-list[data-list-kind=${type}] {
      counter-reset: none;
    }
    .prosemirror-flat-list[data-list-kind=${type}]::before {
      position: absolute;
      right: 100%;
      font-variant-numeric: tabular-nums;
      content: attr(data-list-numbering-template-before) counter(prosemirror-flat-list-counter-${type}, ${type}) attr(data-list-numbering-template-after);
    }
  `;
}

function createUnorderedListStyle(type: (typeof unorderedListTypes)[number]) {
  switch (type) {
    case 'bullet':
      return `
        .prosemirror-flat-list[data-list-kind=${type}] {
          list-style-type: disc;
        }
      `;
    case 'circle':
    case 'square':
      return `
        .prosemirror-flat-list[data-list-kind=${type}] {
          list-style-type: ${type};
        }
      `;
    case 'diamond':
      return `
        .prosemirror-flat-list[data-list-kind='diamond'] {
          &:after {
            content: '';
            height: 0.4em;
            width: 0.4em;
            background: black;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 0.5em;
            left: -1em;
          }
        }
      `;
    default:
      return `
        .prosemirror-flat-list[data-list-kind='bullet}'] {
          list-style-type: disc;
        }
      `;
  }
}

const bulletListStyles = [
  ...orderedListTypes.map(createOrderedListStyle),
  ...unorderedListTypes.map(createUnorderedListStyle),
];
const globalStyle = bulletListStyles.join('');
export default globalStyle;
