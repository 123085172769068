/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import dayjs, { Dayjs } from 'dayjs';
import { DATE_TIME_FORMAT } from '../constants';
import { T } from '../..';
import { getDefaultLanguage } from './localization';

export const formatDateTime = (
  value: string,
  format: string = DATE_TIME_FORMAT.DATE,
  skipUtcToLocal = false
): string => {
  try {
    if (!value) {
      return '';
    }

    if (skipUtcToLocal) {
      return dayjs(value).format(format);
    } else {
      return convertUtcToLocal(value).format(format);
    }
  } catch (e) {
    console.warn(
      `Couldn't format given input: ${value} to given format: {format}. Also skipUtcToLocal is ${skipUtcToLocal}`
    );
    return value;
  }
};

export const convertUtcToLocal = (value: string): dayjs.Dayjs =>
  dayjs(value).utc(true).local();

export interface Format {
  DATE: string;
  TIME: string;
  DATE_TIME: string;
  DATE_TIME_MINUTES: string;
  CURRENCY: string;
}

const FORMATTING: Record<string, Format> = {
  'en-US': {
    DATE: 'MMMM DD YYYY',
    TIME: 'h:mm A',
    DATE_TIME: 'MMM DD YYYY, h:mm A',
    DATE_TIME_MINUTES: 'mm [minutes and] ss [seconds]',
    CURRENCY: 'USD',
  },
  'de-de': {
    DATE: 'MMMM DD YYYY',
    TIME: 'h:mm A',
    DATE_TIME: 'MMM DD YYYY, h:mm A',
    DATE_TIME_MINUTES: 'mm [minutes and] ss [seconds]',
    CURRENCY: 'EUR',
  },
};

const LANG = getDefaultLanguage();
const id: string = LANG.languageCode.replace('-', '_').toLowerCase();
const CURRENT_FORMATTING: Format = FORMATTING[id];

export function format(value: any, format: any, lang: any): any {
  if (format === 'currency') {
    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: CURRENT_FORMATTING.CURRENCY,
    }).format(value);
  }
  if (format === 'date') {
    return formatDateTime(value, CURRENT_FORMATTING.DATE);
  }
  if (format === 'time') {
    return formatDateTime(value, CURRENT_FORMATTING.TIME);
  }
  if (format === 'date-time') {
    return formatDateTime(value, CURRENT_FORMATTING.DATE_TIME);
  }
  if (format === 'date-no-local') {
    return formatDateTime(value, CURRENT_FORMATTING.DATE, true);
  }
  if (format === 'time-no-local') {
    return formatDateTime(value, CURRENT_FORMATTING.TIME, true);
  }
  if (format === 'date-time-no-local') {
    return formatDateTime(value, CURRENT_FORMATTING.DATE_TIME, true);
  }
  if (format === 'date-time-minutes') {
    return formatDateTime(value, CURRENT_FORMATTING.DATE_TIME_MINUTES, true);
  }
  return value;
}

export function generatePriorArtLabel(
  priorArt: T.OfficeActionCitedPriorArt
): string {
  return `${formatCitationNumber(
    priorArt.citationNumberFormat,
    priorArt.citationNumber
  )} ${priorArt.citedLiterature}`;
}

const CITATION_REGEX = /{(\d+)}/g;

export function formatCitationNumber(
  placeholder: string,
  ...rest: unknown[]
): string {
  if (!placeholder) {
    return '';
  }
  return placeholder.replace(CITATION_REGEX, (match, index) =>
    typeof rest[index] == 'undefined' ? match : `${rest[index]}`
  );
}

export function isValidDate(date?: string | null): boolean | Dayjs {
  if (!date) {
    return false;
  }
  try {
    const parsedDate = dayjs(date);
    if (parsedDate.isValid()) {
      return true;
    }
    console.warn('Could not parse date', date);
    return false;
  } catch (e) {
    console.warn('Could not parse date', date);
    return false;
  }
}

export function parseDateString(date?: string | null): Dayjs | undefined {
  if (!isValidDate(date)) {
    return undefined;
  }
  return dayjs(date);
}
