import React, { CSSProperties, memo } from 'react';
import styled, { css } from 'styled-components';
import { SurfaceTypes } from '../../types';
import SearchInput from './SearchInput';
import SearchInOptions from './SearchInOptions';

interface Props {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
  type?: SurfaceTypes;
  searchInOptions?: {
    options: { label: string; value: string; selected: boolean }[];
    onChange: (
      options: { label: string; value: string; selected: boolean }[]
    ) => void;
  };
}

const Search: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  style,
  type = 'dark',
  searchInOptions,
}) => (
  <Outer style={style}>
    <Inner type={type}>
      <SearchInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {searchInOptions && (
        <SearchInOptions
          searchQuery={value}
          options={searchInOptions.options}
          onChange={searchInOptions.onChange}
        />
      )}
    </Inner>
  </Outer>
);

export default memo(
  Search,
  (prev, next) =>
    prev.type === next.type &&
    prev.value === next.value &&
    JSON.stringify(prev.searchInOptions) ===
      JSON.stringify(next.searchInOptions)
);

const Outer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 20px 0;
  justify-content: flex-start;
`;

const Inner = styled.div<{ type?: SurfaceTypes }>`
  width: 100%;
  height: 47px;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.black20};
  ${(props) =>
    props.type === 'light' &&
    css`
      background-color: ${props.theme.colors.primary200_30};
    `}
  ${(props) =>
    props.type === 'transparent' &&
    css`
      background-color: transparent;
    `}
`;
