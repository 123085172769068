export const SCREEN_SIZE = {
  mobileS: '360px',
  mobileM: '412px',
  mobileL: '480px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL2: '1366px',
  laptopL: '1440px',
  desktopS: '1600px',
  desktopS2: '1680px',
  desktopHD: '1920px',
  desktop4K: '2560px',
};

export const DEVICE = {
  mobileS: `(min-width: ${SCREEN_SIZE.mobileS})`,
  mobileM: `(min-width: ${SCREEN_SIZE.mobileM})`,
  mobileL: `(min-width: ${SCREEN_SIZE.mobileL})`,
  tablet: `(min-width: ${SCREEN_SIZE.tablet})`,
  laptopS: `(min-width: ${SCREEN_SIZE.laptopS})`,
  laptopM: `(min-width: ${SCREEN_SIZE.laptopM})`,
  laptopL2: `(min-width: ${SCREEN_SIZE.laptopL2})`,
  laptopL: `(min-width: ${SCREEN_SIZE.laptopL})`,
  desktopS: `(min-width: ${SCREEN_SIZE.desktopS})`,
  desktopS2: `(min-width: ${SCREEN_SIZE.desktopS2})`,
  desktopHD: `(min-width: ${SCREEN_SIZE.desktopHD})`,
  desktop4K: `(min-width: ${SCREEN_SIZE.desktop4K})`,
};

export const TooltipStyle: Record<string, unknown> = {
  'g2-tooltip': {
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(to right, #31323f, #3b3f4f)',
    boxShadow: 'none',
    maxWidth: '400px',
  },
  'g2-tooltip-title': {
    color: 'white',
    fontSize: '12px',
    paddingBottom: '15px',
    textAlign: 'center',
  },
  'g2-tooltip-list-item': {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  'g2-tooltip-value': {
    marginLeft: '5px',
    float: 'none',
  },
};
