import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import useTranslation from '../../translations';

interface Props {
  title: string;
  onCancel: () => void;
  isInFinalState: boolean;
  onDelete: (forceDelete: boolean) => void;
}

const DeleteRevisionModal: React.FC<Props> = ({
  onCancel,
  onDelete,
  isInFinalState,
  title,
}) => {
  const t = useTranslation();
  const [loading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal
      data-testid="delete-patent-revision-modal"
      destroyOnClose
      centered
      title={t('REVISION_TREE.DELETE_MODAL_TITLE')}
      open={true}
      okText={t('ACTION.DELETE')}
      okButtonProps={{ danger: true, loading }}
      onOk={() => {
        setIsLoading(true);
        onDelete(isInFinalState);
      }}
      onCancel={onCancel}
    >
      <Outer>
        {isInFinalState && (
          <Inner>
            <WarningIcon className="icn-warning" />
            <DangerMessage>
              {t('REVISION_TREE.DELETE_MODAL_ADDITIONAL_MESSAGE', {
                title,
              })}
            </DangerMessage>
          </Inner>
        )}
        <span>{t('REVISION_TREE.DELETE_MODAL_MESSAGE', { title })}</span>
      </Outer>
    </Modal>
  );
};

const WarningIcon = styled.i`
  margin-right: 8px;
  font-size: 30px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.warning};
`;

const DangerMessage = styled.span`
  color: ${(props) => props.theme.colors.warning};
  font-size: 16px;
  display: flex;
  vertical-align: center;
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export default DeleteRevisionModal;
