import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { Tooltip, TooltipProps } from 'antd';
import { EditorState } from 'prosemirror-state';
import { Node as PMNode } from 'prosemirror-model';
import useTranslation from '../../../../translations';
import { Colors, T } from '../../../../..';
import { ClaimPosition, deleteClaim, insertNewClaim } from '../../util';

interface Props {
  active: boolean;
  claimNumber: number;
  root: React.RefObject<HTMLDivElement>;
  editorView?: EditorView;
  editorContentId?: string;
  patentOffice?: T.PatentOffice;
  onSuccess?: (data: T.ClaimEditorDocumentResponse) => void;
}

const TOOLTIP_STYLE = {
  maxWidth: '150px',
  color: Colors.white87,
  padding: '5px 8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
  marginBottom: '-7px',
};

export const tooltipCommonProps: TooltipProps = {
  placement: 'top',
  overlayInnerStyle: TOOLTIP_STYLE,
  color: Colors.primary300,
};

const getLastClaimNumber = (state?: EditorState): number | undefined => {
  if (!state) {
    return;
  }
  const claimNodes: PMNode[] = [];
  state.doc.descendants((node) => {
    if (node.type.name === 'claim') {
      claimNodes.push(node);
    }
  });
  if (claimNodes.length > 0) {
    const lastClaim = claimNodes[claimNodes.length - 1];
    return lastClaim.attrs.claimNumber;
  }

  return;
};

const ClaimActionsMenu: React.FC<Props> = ({
  editorView,
  active,
  claimNumber,
  root,
  patentOffice,
  editorContentId,
  onSuccess,
}) => {
  const t = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const enabledActions = !!editorView?.editable && active && isFocused;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (root.current && !root.current.contains(event.target as Node)) {
        setIsFocused(false);
      } else if (root.current && root.current.contains(event.target as Node)) {
        setIsFocused(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = async (
    onClick: (
      editorContentId: string,
      claimNum: number,
      editorView?: EditorView,
      position?: ClaimPosition
    ) => Promise<T.ClaimEditorDocumentResponse | false>,
    claimNum?: number,
    editorId?: string,
    editorView?: EditorView,
    position?: ClaimPosition
  ) => {
    if (!editorId || !claimNum) {
      return;
    }
    setLoading(true);
    try {
      const response = await onClick(editorId, claimNum, editorView, position);
      if (response) {
        onSuccess?.(response);
      }
    } catch (error) {
      console.error('Error handling item click:', error);
    } finally {
      setLoading(false);
    }
  };

  const getActions = (patentOffice?: T.PatentOffice) => {
    const commonActions = [
      {
        icon: 'icn-delete',
        title: t('PM.ACTION.DELETE_CLAIM'),
        action: () => {
          handleItemClick(deleteClaim, claimNumber, editorContentId);
        },
      },
    ];

    if (patentOffice === T.PatentOffice.USPTO) {
      return [
        {
          icon: 'icn-insert-end',
          title: t('PM.ACTION.ADD_NEW_CLAIM_END'),
          action: () => {
            handleItemClick(
              insertNewClaim,
              getLastClaimNumber(editorView?.state),
              editorContentId,
              editorView,
              'below'
            );
          },
        },
        ...commonActions,
      ];
    }

    return [
      {
        icon: 'icn-insert-above',
        title: t('PM.ACTION.ADD_NEW_CLAIM_ABOVE'),
        action: () => {
          handleItemClick(
            insertNewClaim,
            claimNumber,
            editorContentId,
            editorView,
            'above'
          );
        },
      },
      {
        icon: 'icn-insert-below',
        title: t('PM.ACTION.ADD_NEW_CLAIM_BELOW'),
        action: () => {
          handleItemClick(
            insertNewClaim,
            claimNumber,
            editorContentId,
            editorView,
            'below'
          );
        },
      },
      ...commonActions,
    ];
  };

  const ACTIONS = getActions(patentOffice);

  return (
    <ButtonWrapper active={enabledActions}>
      {ACTIONS.map((item, index) => (
        <Tooltip key={index} {...tooltipCommonProps} title={item.title}>
          <StyledIcon
            onClick={() => item.action()}
            className={item.icon}
            disabled={loading}
          />
        </Tooltip>
      ))}
    </ButtonWrapper>
  );
};

export default ClaimActionsMenu;

const ButtonWrapper = styled.div<{ active: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary200};
  padding: 3px;
  border-radius: 5px;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  position: absolute;
  bottom: -30px;
  right: 0;
  z-index: 100;
`;

const StyledIcon = styled.i<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.white87} !important;
  text-decoration: none !important;
  width: 30px;
  height: 30px;
  &:hover {
    background: ${(props) => props.theme.colors.white20};
  }
  ${(props) => props.disabled && `pointer-events: none;`}
`;
