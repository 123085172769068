import { DefaultTheme } from 'styled-components';
import themeJson from './theme.json';

export const Colors = {
  warning: themeJson.colors.gold100,
  error: themeJson.colors.red500,
  success: themeJson.colors.secondary300,
  link: themeJson.colors.blue300,
  gradient: {
    background: {
      start: themeJson.colors.gradient100,
      end: themeJson.colors.gradient200,
    },
    button: {
      start: themeJson.colors.secondary100,
      end: themeJson.colors.secondary500,
    },
    appSwitchMenu: {
      start: themeJson.colors.primary600,
      end: themeJson.colors.primary800,
    },
    dropdown: {
      start: themeJson.colors.primary600,
      end: themeJson.colors.primary800,
    },
    backplate: {
      start: themeJson.colors.black10,
      end: themeJson.colors.black_0,
    },
  },
  cardHover: themeJson.colors.cardHover,
  card100: themeJson.colors.card100,
  card200: themeJson.colors.card200,
  black: themeJson.colors.black,
  black_0: themeJson.colors.black_0,
  black05: themeJson.colors.black05,
  black10: themeJson.colors.black10,
  black20: themeJson.colors.black20,
  black30: themeJson.colors.black30,
  black40: themeJson.colors.black40,
  black60: themeJson.colors.black60,
  black70: themeJson.colors.black70,
  black87: themeJson.colors.black87,
  blue100: themeJson.colors.blue100,
  blue100_30: themeJson.colors.blue100_30,
  blue100_40: themeJson.colors.blue100_40,
  blue100_70: themeJson.colors.blue100_70,
  blue300: themeJson.colors.blue300,
  blue200: themeJson.colors.blue200,
  blue500: themeJson.colors.blue500,
  blue600: themeJson.colors.blue600,
  blue700: themeJson.colors.blue700,
  blue900: themeJson.colors.blue900,
  blue900_30: themeJson.colors.blue900_30,
  solidblue900: themeJson.colors.solidblue900,
  gold100: themeJson.colors.gold100,
  gold300: themeJson.colors.gold300,
  gradient100: themeJson.colors.gradient100,
  gradient200: themeJson.colors.gradient200,
  gradient300: themeJson.colors.gradient300,
  primary100: themeJson.colors.primary100,
  primary100_hover: themeJson.colors.primary100_hover,
  primary200: themeJson.colors.primary200,
  primary200_10: themeJson.colors.primary200_10,
  primary200_20: themeJson.colors.primary200_20,
  primary200_30: themeJson.colors.primary200_30,
  primary200_40: themeJson.colors.primary200_40,
  primary200_50: themeJson.colors.primary200_50,
  primary200_60: themeJson.colors.primary200_60,
  primary200_70: themeJson.colors.primary200_70,
  primary200_87: themeJson.colors.primary200_87,
  primary300: themeJson.colors.primary300,
  primary300_10: themeJson.colors.primary300_10,
  primary400: themeJson.colors.primary400,
  primary500: themeJson.colors.primary500,
  primary550: themeJson.colors.primary550,
  primary600: themeJson.colors.primary600,
  primary700: themeJson.colors.primary700,
  primary700_hover: themeJson.colors.primary700_hover,
  primary800: themeJson.colors.primary800,
  primary800_80: themeJson.colors.primary800_80,
  primary900: themeJson.colors.primary900,
  red500: themeJson.colors.red500,
  red700: themeJson.colors.red700,
  secondary100: themeJson.colors.secondary100,
  secondary200: themeJson.colors.secondary200,
  secondary300: themeJson.colors.secondary300,
  secondary400: themeJson.colors.secondary400,
  secondary500: themeJson.colors.secondary500,
  tertiary100: themeJson.colors.tertiary100,
  tertiary500: themeJson.colors.tertiary500,
  tertiary500_60: themeJson.colors.tertiary500_60,
  tertiary700: themeJson.colors.tertiary700,
  text100: themeJson.colors.text100,
  text200: themeJson.colors.text200,
  white05: themeJson.colors.white05,
  white10: themeJson.colors.white10,
  white20: themeJson.colors.white20,
  white30: themeJson.colors.white30,
  white40: themeJson.colors.white40,
  white50: themeJson.colors.white50,
  white60: themeJson.colors.white60,
  white70: themeJson.colors.white70,
  white87: themeJson.colors.white87,
  white100: themeJson.colors.white100,
  charcoal100: themeJson.colors.charcoal100,
  ecru100: themeJson.colors.ecru100,
  visualization: {
    red100: themeJson.colors.visualization.red100,
    red200: themeJson.colors.visualization.red200,
    red300: themeJson.colors.visualization.red300,
    red400: themeJson.colors.visualization.red400,
    red500: themeJson.colors.visualization.red500,
    red500_30: themeJson.colors.visualization.red500_30,
    gold100: themeJson.colors.visualization.gold100,
    gold200: themeJson.colors.visualization.gold200,
    gold300: themeJson.colors.visualization.gold300,
    gold400: themeJson.colors.visualization.gold400,
    gold500: themeJson.colors.visualization.gold500,
    blue100: themeJson.colors.visualization.blue100,
    blue200: themeJson.colors.visualization.blue200,
    blue300: themeJson.colors.visualization.blue300,
    blue400: themeJson.colors.visualization.blue400,
    blue500: themeJson.colors.visualization.blue500,
    green100: themeJson.colors.visualization.green100,
    green200: themeJson.colors.visualization.green200,
    green300: themeJson.colors.visualization.green300,
    green400: themeJson.colors.visualization.green400,
    green500: themeJson.colors.visualization.green500,
    aqua100: themeJson.colors.visualization.aqua100,
    aqua200: themeJson.colors.visualization.aqua200,
    aqua300: themeJson.colors.visualization.aqua300,
    aqua400: themeJson.colors.visualization.aqua400,
    aqua500: themeJson.colors.visualization.aqua500,
    plum100: themeJson.colors.visualization.plum100,
    plum200: themeJson.colors.visualization.plum200,
    plum300: themeJson.colors.visualization.plum300,
    plum400: themeJson.colors.visualization.plum400,
    plum500: themeJson.colors.visualization.plum500,
  },
};

const IposTheme: DefaultTheme = {
  colors: Colors,
  fonts: {
    colors: {
      h1: themeJson.colors.white87,
      h2: themeJson.colors.white87,
      h3: themeJson.colors.white87,
      title: themeJson.colors.white87,
      b1: themeJson.colors.white87,
      b2: themeJson.colors.white87,
      normal: themeJson.colors.white87,
      small: themeJson.colors.white87,
      xsmall: themeJson.colors.white87,
    },
    sizes: {
      h1: themeJson.fontSizes.h1, // 24
      h2: themeJson.fontSizes.h2, // 20
      h3: themeJson.fontSizes.h3, // 18
      title: themeJson.fontSizes.title, // 15
      b1: themeJson.fontSizes.b1, // 14
      b2: themeJson.fontSizes.b2, // 13
      normal: themeJson.fontSizes.normal, // 12
      small: themeJson.fontSizes.small, // 11
      xsmall: themeJson.fontSizes.xsmall, // 10
    },
    lineHeights: {
      h1: themeJson.lineHeights.h1, // 34
      h2: themeJson.lineHeights.h2, // 30
      h3: themeJson.lineHeights.h3, // 28
      title: themeJson.lineHeights.title, // 25
      b1: themeJson.lineHeights.b1, // 21
      b2: themeJson.lineHeights.b2, // 21
      normal: themeJson.lineHeights.normal, // 20
      small: themeJson.lineHeights.small, // 18
      xsmall: themeJson.lineHeights.xsmall, // 16
    },
  },
  breakpoints: {
    xs: themeJson.breakpoints.xs,
    sm: themeJson.breakpoints.sm,
    md: themeJson.breakpoints.md,
    lg: themeJson.breakpoints.lg,
    xl: themeJson.breakpoints.xl,
  },
};

export default IposTheme;
