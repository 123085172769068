import React, { createContext, useState } from 'react';
import { useLocalStorage } from '../../../hooks';
import { ListConfigurationContext, StoredColumn } from './types';

export const LocalConfigurationContext =
  createContext<ListConfigurationContext | null>(null);

interface Props {
  children: React.ReactNode;
  storageKey: string;
}

const LocalConfigurationProvider: React.FC<Props> = ({
  children,
  storageKey,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [loading] = useState(false);

  const [storedColumns, setStoredColumns] = useLocalStorage<StoredColumn[]>(
    storageKey,
    []
  );

  const load: ListConfigurationContext['load'] = () =>
    Promise.resolve(storedColumns);

  const save: ListConfigurationContext['save'] = (columns) => {
    setStoredColumns(columns);
  };

  const markAsInitialized = () => {
    setInitialized(true);
  };

  return (
    <LocalConfigurationContext.Provider
      value={{
        loading,
        initialized,
        load,
        save,
        markAsInitialized,
      }}
    >
      {children}
    </LocalConfigurationContext.Provider>
  );
};

export default LocalConfigurationProvider;
