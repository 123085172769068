import { NodeSpec } from 'prosemirror-model';

const mathDisplay: NodeSpec = {
  group: 'block math',
  content: 'text*', // important!
  atom: true, // important!
  code: true, // important!
  toDOM: () => ['math-display', { class: 'math-node' }, 0],
  parseDOM: [
    {
      tag: 'math-display', // important!
    },
  ],
};
export default mathDisplay;
