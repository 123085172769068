import React from 'react';
import styled, { CSSObject } from 'styled-components';
import useTranslation from '../../../translations';

const Button: React.FC<{
  style?: CSSObject;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}> = ({ icon, label, onClick, style }) => {
  const t = useTranslation();
  const translatedLabel = t(label);

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    // mandatory in order to keep selection in draft-js editor
    e.preventDefault();
  };

  return (
    <Outer
      style={style}
      type="button"
      onMouseDown={handleMouseDown}
      onClick={onClick}
      title={translatedLabel}
    >
      {icon ?? '?'}
    </Outer>
  );
};

export default Button;

const Outer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
  & + & {
    margin-right: 2px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary500};
  }
`;
