import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { QueryKey } from '../queries';

const useDocumentAsBase64 = (
  uri: string,
  options?: UseQueryOptions<string, AxiosError>
): UseQueryResult<string, AxiosError> =>
  useQuery<string, AxiosError>(
    [QueryKey.DocumentAsBase64, uri],
    () => Api.document.getAsBase64ByUri(uri),
    options
  );

export default useDocumentAsBase64;
