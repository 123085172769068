import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import HeaderItem, { HeaderItemProps } from './HeaderItem';

export interface Props {
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
  subitems: HeaderItemProps[];
}

const HeaderItemWithSubitems: React.FC<Props> = ({
  label,
  icon,
  disabled,
  subitems,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      containerStyle={{ marginTop: '4px' }}
      onClickOutside={() => setPopoverOpen(false)}
      content={
        <Outer>
          {subitems.map((itemProps, i, arr) => (
            <Fragment key={i}>
              <HeaderItem
                {...itemProps}
                style={{ borderRadius: 3, paddingTop: 3 }}
                onClick={() => {
                  setPopoverOpen(false);
                  itemProps.onClick?.();
                }}
              />
              {i < arr.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Outer>
      }
    >
      <div>
        <HeaderItem
          label={label}
          icon={icon}
          disabled={disabled}
          active={isPopoverOpen}
          onClick={() => setPopoverOpen((prev) => !prev)}
        />
      </div>
    </Popover>
  );
};

export default HeaderItemWithSubitems;

const Outer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding: 5px;
  border-radius: 8px;
  background-color: #1e202a;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  margin: 0 9px;
  background-color: ${(props) => props.theme.colors.white20};
`;
