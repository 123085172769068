import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';

interface Props {
  targetRef: React.RefObject<HTMLDivElement>;
  loading: boolean;
  style?: React.CSSProperties;
  isCollapsed?: boolean;
}

const ObservedTarget: React.FC<Props> = ({
  targetRef,
  loading,
  style,
  isCollapsed,
}) => (
  <Target ref={targetRef} style={style} isCollapsed={isCollapsed}>
    {loading && <Spinner />}
  </Target>
);

export default ObservedTarget;

const Target = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  ${(props) => props.isCollapsed && 'min-height: 0'}
`;
