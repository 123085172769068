import React from 'react';
import styled from 'styled-components';
import './style.less';

interface Props {
  children: React.ReactNode;
  rightContent?: React.ReactNode;
}

const NavigationBar: React.FC<Props> = ({ children, rightContent }) => (
  <Outer>
    <Left>{children}</Left>
    <Right>{rightContent}</Right>
  </Outer>
);

export default NavigationBar;

const Outer = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 12px 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
  background: ${(props) => props.theme.colors.solidblue900};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2) inset;
`;

const Left = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
