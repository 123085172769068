import React from 'react';
import styled from 'styled-components';
import { DocumentPickerItem, DocumentSearchResult } from '../../../types';
import SearchResultsListItem, { SearchDocument } from './SearchResultsListItem';

interface SearchGroup {
  name: string;
  items: SearchDocument[];
}

interface Props {
  docItems: DocumentPickerItem[];
  results: DocumentSearchResult[];
  onOpenSearchInView: (documentId: string, index: number) => void;
}

const SearchResultsList: React.FC<Props> = ({
  docItems,
  results,
  onOpenSearchInView,
}) => {
  const getMatchingDocsForItem = (
    item: DocumentPickerItem,
    res: DocumentSearchResult[]
  ): SearchDocument[] => {
    const matchingDocs: SearchDocument[] = [];
    const extractDocumentMatches = (currentItem: DocumentPickerItem) => {
      const result = res.find((r) => r.documentId === currentItem.id);
      if (result) {
        matchingDocs.push({
          id: currentItem.id,
          name: currentItem.name,
          hitCount: result.matches.length,
          hasOcr: result.hasOcr,
        });
      }
      currentItem.subItems &&
        currentItem.subItems.forEach(extractDocumentMatches);
    };
    extractDocumentMatches(item);
    return matchingDocs;
  };

  const mapResultsToTopLevelItems = (
    items: DocumentPickerItem[],
    res: DocumentSearchResult[]
  ): SearchGroup[] =>
    items
      .map((item) => {
        const matches = getMatchingDocsForItem(item, res);
        return { name: item.name, items: matches };
      })
      .filter((item) => item.items.length > 0);

  const data = mapResultsToTopLevelItems(docItems, results);

  return (
    <>
      {data.map((doc) => (
        <Outer key={doc.name}>
          <Title>{doc.name}</Title>
          <>
            {doc.items.map((item) => (
              <SearchResultsListItem
                key={item.id}
                item={item}
                onOpenSearchInView={onOpenSearchInView}
              />
            ))}
          </>
        </Outer>
      ))}
    </>
  );
};

export default SearchResultsList;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 10px;
  background: ${(props) => props.theme.colors.white05};
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 34px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white50};
`;
