import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { T } from '@ipos/shared';
import { createTsdElementSchema } from 'validations';

const getFormFieldValues = (
  language: T.Language,
  element?: T.TsdElementBasicInfo
): T.CreateTsdElement => ({
  synonym: element?.name ?? '',
  description: '',
  language,
});

const useTsdElementForm = (
  preferredLanguage: T.Language
): {
  methods: UseFormReturn<T.CreateTsdElement>;
  resetForm: (element?: T.TsdElementBasicInfo) => void;
} => {
  const methods = useForm<T.CreateTsdElement>({
    defaultValues: getFormFieldValues(preferredLanguage),
    resolver: yupResolver(createTsdElementSchema),
  });

  const resetForm = (element?: T.TsdElementBasicInfo) => {
    methods.reset(getFormFieldValues(preferredLanguage, element));
  };

  return {
    methods,
    resetForm,
  };
};

export default useTsdElementForm;
