import { Command } from 'prosemirror-state';
import { joinBackward } from 'prosemirror-commands';
import { findParentNodeOfType } from '../util';

export const claimPartDelete: Command = (state, dispatch, view) => {
  const claimPartOnCursor = findParentNodeOfType(state.schema.nodes.claimPart)(
    state.selection
  );

  if (!claimPartOnCursor) {
    return false;
  }

  const { $from } = state.selection;
  if ($from.parentOffset === $from.parent.content.size) {
    return joinBackward(state, dispatch, view);
  }
  return false;
};
