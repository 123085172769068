import React, { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';
import useTranslation from '../../../translations';
import {
  CreateCompetithorReport,
  CompetithorReportInputName,
  CompetithorReportParams,
  PatStatReports,
  CompetithorReportInput,
} from '../../../types';
import { Icon } from '../../Icon';
import CompetitorReportModal from '../CompetitorReportModal';
import PatstatStatisticsModal from '../Patstat/PatstatStatisticsModal';
import TechFieldsModal from '../TechField/TechFieldsModal';

export const ApplicantNameRenderer: React.FC<{
  applicantName: string;
}> = ({ applicantName }) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  const competitorReportRequest: CreateCompetithorReport = {
    title: applicantName,
    description: t('COMPETITOR.COMPANY_INFOGRAPHIC.DESCRIPTION'),
    inputs: [
      {
        name: CompetithorReportInputName.ApplicantName,
        value: applicantName,
      },
    ],
    reportParams: {
      [CompetithorReportParams.Name]: applicantName,
    },
    patStatReportId: PatStatReports.CompanyInfographic,
  };

  return (
    <>
      <Container>
        <Button
          key={applicantName}
          onClick={handleOpenRequest}
          type="link"
          size="small"
          style={{
            fontSize: 13,
            whiteSpace: 'normal',
            textAlign: 'left',
            minWidth: '100%',
            fontWeight: 500,
            marginRight: '10px',
          }}
        >
          {applicantName}
        </Button>
        {isModalOpen && (
          <CompetitorReportModal
            onCloseRequest={handleCloseRequest}
            reportRequest={competitorReportRequest}
          />
        )}
      </Container>
    </>
  );
};

export const PatstatStatisticsReportLink: React.FC<{
  publicationNumber: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  hover?: boolean;
}> = ({ publicationNumber, style, textStyle, iconStyle, hover }) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  return (
    <>
      <Container>
        <StyledLink
          style={style}
          className={classNames({ ['prior-art-card__hover']: hover })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenRequest?.();
          }}
        >
          <StyledText style={textStyle}>{t('COMPETITOR.REPORTS')}</StyledText>
          <Icon style={iconStyle} className="icn-chart" />
        </StyledLink>
        {isModalOpen && (
          <PatstatStatisticsModal
            publicationNumber={publicationNumber}
            onCloseRequest={handleCloseRequest}
          />
        )}
      </Container>
    </>
  );
};

export const TechFieldReportLink: React.FC<{
  tsdNodeName: string;
  tsdNodeId: string;
  tsdElementId: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  customContent?: React.ReactNode;
}> = ({
  tsdNodeName,
  tsdNodeId,
  tsdElementId,
  style,
  textStyle,
  customContent,
}) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  return (
    <>
      <Container>
        <StyledLink
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenRequest?.();
          }}
        >
          {customContent || (
            <>
              <StyledText style={textStyle}>
                {t('COMPETITOR.TECH_FIELD_ANALYSIS.LINK_TITLE')}
              </StyledText>
              <Icon className="icn-chart" color="inherit" size={16} />
            </>
          )}
        </StyledLink>
        {isModalOpen && (
          <TechFieldsModal
            tsdNodeName={tsdNodeName}
            tsdNodeId={tsdNodeId}
            tsdElementId={tsdElementId}
            onCloseRequest={handleCloseRequest}
          />
        )}
      </Container>
    </>
  );
};

export const CitedPriorArtsReportLink: React.FC<{
  citedPriorArts: string;
  citationNumbers: string;
  officeActionApplicationNr: string;
  officeActionApplicationDate: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}> = ({
  citedPriorArts,
  officeActionApplicationNr,
  officeActionApplicationDate,
  citationNumbers,
  style,
  iconStyle,
}) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseRequest = () => setIsModalOpen(false);
  const handleOpenRequest = () => setIsModalOpen(true);

  const reportInputs = Array.from(citedPriorArts.split(','), (publication) => ({
    name: CompetithorReportInputName.CitedPriorArt,
    value: publication,
  })) as CompetithorReportInput[];

  const competitorReportRequest: CreateCompetithorReport = {
    title: t('COMPETITOR.CITED_PRIOR_ART_INFOGRAPHIC.REPORT_REQUEST_TITLE', {
      application: officeActionApplicationNr,
    }),
    description: citedPriorArts,
    inputs: reportInputs,
    reportParams: {
      [CompetithorReportParams.PubNumbers]: citedPriorArts,
      [CompetithorReportParams.CitNumbers]: citationNumbers,
      [CompetithorReportParams.OfficeActionApplicationDate]:
        officeActionApplicationDate,
    },
    patStatReportId: PatStatReports.CitedPriorArtInfographic,
  };

  return (
    <>
      <Container>
        <StyledLink
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenRequest?.();
          }}
        >
          <Icon style={iconStyle} className="icn-chart" />
        </StyledLink>
        {isModalOpen && (
          <CompetitorReportModal
            onCloseRequest={handleCloseRequest}
            reportRequest={competitorReportRequest}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: none;
  color: ${(props) => props.theme.colors.primary100};
  &:hover {
    color: ${(props) => props.theme.colors.success} !important;
  }
`;

const StyledText = styled.div`
  color: inherit;
  font-size: 13px;
  line-height: 20px;
  margin-right: 6px;
`;
