import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { DocumentSearchResult } from '../components/MultiDocViewer/types';
import { QueryKey } from '../queries';

const useSearchDocument = (
  documentId: string,
  searchText: string,
  ignoreCase: boolean,
  options?: UseQueryOptions<DocumentSearchResult[], AxiosError>
): UseQueryResult<DocumentSearchResult[], AxiosError> =>
  useQuery<DocumentSearchResult[], AxiosError>(
    [QueryKey.SearchDocument, documentId, searchText, ignoreCase],
    () =>
      Api.document.searchDocuments({
        documentIds: [documentId],
        searchText,
        ignoreCase,
      }),
    options
  );

export default useSearchDocument;
