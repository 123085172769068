import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'submit';
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionButton: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  disabled,
  htmlType = 'button',
  style,
  children,
  onClick,
}) => (
  <StyledButton
    style={style}
    loading={loading}
    disabled={disabled}
    htmlType={htmlType}
    type="primary"
    onClick={onClick}
  >
    {children}
  </StyledButton>
);

export default ActionButton;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 300px;
  height: 44px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  line-height: 1.71;
  background-color: #4180cc;
  color: ${(props) => props.theme.colors.white100};
  &:hover,
  &:focus,
  &:active {
    background-color: #4180cc;
    opacity: 0.9;
  }
`;
