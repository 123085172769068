import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { DolphinLoader } from '../';
import { AppHeader } from '../Layout';

interface Props {
  error?: boolean;
  message?: string;
  withAppHeader?: boolean;
  fixed?: boolean;
  style?: React.CSSProperties;
}

const PageLoad: React.FC<Props> = ({
  message,
  error = false,
  withAppHeader = false,
  fixed = false,
  style,
}) => {
  const t = useTranslation();

  const content = (
    <Content $fixed={fixed} style={style}>
      {error ? t('SOMETHING_WENT_WRONG') : <DolphinLoader />}
      {message && <Message>{message}</Message>}
    </Content>
  );

  if (withAppHeader) {
    return (
      <>
        <AppHeader />
        {content}
      </>
    );
  }

  return content;
};

export default PageLoad;

const Content = styled.div<{ $fixed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.$fixed &&
    `position: fixed; background-image: linear-gradient(to right, #22232d 0%, #383842 104%);`}
`;

const Message = styled.div`
  font-weight: 300;
  font-size: 16px;
`;
