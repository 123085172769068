import React from 'react';
import styled, { css, CSSObject } from 'styled-components';

interface Props {
  displayName: string;
  givenName?: string;
  surname?: string;
  style?: CSSObject;
  size?: number;
  withHighlight?: boolean;
}

const AvatarCircle: React.FC<Props> = ({
  displayName,
  givenName,
  surname,
  style,
  size = 30,
  withHighlight = true,
}) => {
  let initials = displayName
    .split(' ')
    .slice(0, 2)
    .map((w) => w.charAt(0))
    .join('');

  if (givenName && surname) {
    initials = `${givenName.charAt(0)}${surname.charAt(0)}`;
  }

  return (
    <Avatar $size={size} withHighlight={withHighlight} style={style}>
      {initials}
    </Avatar>
  );
};

export default AvatarCircle;

const Avatar = styled.div<{ $size: number; withHighlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.primary400};
  color: ${(props) => props.theme.colors.white87};
  cursor: pointer;
  line-height: 100%;
  font-size: ${(props) => Math.max(props.$size * 0.35, 12)}px;
  transition: background-color 0.1s ease-in;
  ${(props) =>
    props.withHighlight &&
    css`
      &:hover {
        z-index: 99;
        background-color: ${(props) => props.theme.colors.primary200};
      }
    `}
`;
