import React from 'react';
import styled from 'styled-components';
import { notification } from 'antd';
import SHARED_ROUTES from '../../utils/routing';
import useTranslation, { i18next } from '../../translations';
import { getCurrentLanguageValue } from '../../utils';

const currentLanguage = getCurrentLanguageValue();
// @ts-ignore
const i18Instance = i18next.getDataByLanguage(currentLanguage)?.shared;

const Unauthorized: React.FC = () => {
  const t = useTranslation();
  return (
    <Outer>
      <span>{t('SESSION_ERROR')}</span>
      <a href={SHARED_ROUTES.LOGIN.path}>{t('GO_TO_LOGIN')}</a>
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const showUnauthorisedNotification = () => {
  notification.error({
    duration: 3,
    key: 'unauthorized',
    message: i18Instance?.UNAUTHORIZED,
    description: <Unauthorized />,
    onClose: () => (window.location.href = SHARED_ROUTES.LOGIN.path),
  });
};
