import React from 'react';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationTsdElementQueryCoverage } from 'queries';

const { Visualizations } = Components;
const { ProgressSlider } = Visualizations;

const TsdElementQueryCoverage: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationTsdElementQueryCoverage();

  if (!data) {
    return null;
  }

  return (
    <ProgressSlider
      value={data?.y}
      label={t('STATS.QUERY_COVERAGE_TITLE')}
      tipFormatter={(v: number) =>
        t('STATS.QUERY_COVERAGE_TOOLTIP', { value: Math.round(v) })
      }
    />
  );
};
export default TsdElementQueryCoverage;
