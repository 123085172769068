import React, { useContext } from 'react';
import { EditorState } from 'prosemirror-state';
import { VeryRichContextProviderProps } from './types';

export const VeryRichContext = React.createContext<
  Partial<VeryRichContextProviderProps>
>({});

export const VeryRichEditorStateContext = React.createContext<{
  editorState: EditorState | null;
}>({
  editorState: null,
});

export function useVeryRichContext(): Required<VeryRichContextProviderProps> {
  const context = useContext(
    VeryRichContext
  ) as Required<VeryRichContextProviderProps>;
  if (!context) {
    throw new Error(
      `useVeryRichContext must be used within VeryRichContextProvider.Provider`
    );
  }
  return context;
}

export function useVeryRichEditorStateContext(): Required<{
  editorState: EditorState | null;
}> {
  const context = useContext(VeryRichEditorStateContext) as Required<{
    editorState: EditorState | null;
  }>;
  if (!context) {
    throw new Error(
      `useVeryRichEditorStateContext must be used within VeryRichEditorStateContext.Provider`
    );
  }
  return context;
}
