import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { QueryKey } from './index';

const usePayPerUseServiceStatus = (
  service: T.PayPerUseServiceType,
  options?: UseQueryOptions<T.PayPerUseServiceStatus, AxiosError>
): UseQueryResult<T.PayPerUseServiceStatus, AxiosError> =>
  useQuery<T.PayPerUseServiceStatus, AxiosError>(
    [QueryKey.PayPerUseServiceStatus, service],
    () => Api.ownerGroups.getPayPerUseServiceStatus(service),
    options
  );

export default usePayPerUseServiceStatus;
