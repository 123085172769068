import React, { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Spinner } from '../../Spinner';
import { useFileAsBlob } from '../../../queries';
import useTranslation from '../../../translations';

interface Props {
  uploadId: string;
  alt?: string;
  style?: CSSObject;
  className?: string;
  onClick?: () => void;
  onLoad?: (src: string) => void;
}

const Image: React.FC<Props> = ({
  uploadId,
  alt,
  style,
  className,
  onClick,
  onLoad,
}) => {
  const t = useTranslation();
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [loadError, setLoadError] = useState<boolean>(false);
  const { error, data: file } = useFileAsBlob(uploadId);

  useEffect(() => {
    if (!file) {
      return;
    }
    const fr = new FileReader();
    fr.readAsDataURL(file);

    const handleFileLoadError = () => {
      setLoadError(true);
    };

    const handleFileLoaded = () => {
      const loadResult = fr.result;
      if (typeof loadResult !== 'string') {
        setLoadError(true);
        return;
      }
      setSrc(loadResult);
    };

    fr.addEventListener('load', handleFileLoaded);
    fr.addEventListener('error', handleFileLoadError);
    return () => {
      fr.removeEventListener('load', handleFileLoaded);
      fr.removeEventListener('error', handleFileLoadError);
    };
  }, [file]);

  useEffect(() => {
    if (src && onLoad) {
      onLoad(src);
    }
  }, [src, onLoad]);

  const decodeBase64 = (dataUrl?: string) => {
    if (!dataUrl) {
      return;
    }
    try {
      const base64 = dataUrl.split(',')[1];
      const binaryString = window.atob(base64);
      return decodeURIComponent(
        Array.from(binaryString)
          .map((char) => '%' + char.charCodeAt(0).toString(16).padStart(2, '0'))
          .join('')
      );
    } catch (error) {
      console.error('Failed to decode base64 string:', error);
    }
  };

  const isUnknownBinaryType = src?.startsWith('data:application/octet-stream;');
  const decodedBase64 = isUnknownBinaryType ? decodeBase64(src) : undefined;

  let content: React.ReactNode;
  switch (true) {
    case error?.code === '404':
      content = <Msg>{t('UPLOADS.IMAGE.ERROR_404')}</Msg>;
      break;
    case error?.code === '500' || loadError:
      content = <Msg>{t('UPLOADS.IMAGE.ERROR_500')}</Msg>;
      break;
    case !src:
      content = <Spinner />;
      break;
    case isUnknownBinaryType && decodedBase64 != null:
      content = (
        <StyledSvg
          onClick={onClick}
          dangerouslySetInnerHTML={{
            __html: decodedBase64,
          }}
          data-upload-id={uploadId}
          style={style}
          className={className}
        />
      );
      break;
    default:
      content = (
        <StyledImg
          onClick={onClick}
          src={src}
          alt={alt}
          data-upload-id={uploadId}
          style={style}
          className={className}
        />
      );
  }

  return <Outer>{content}</Outer>;
};

export default Image;

const Outer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const Msg = styled.div`
  text-align: center;
  padding: 10px;
`;

const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const StyledSvg = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 100%;
    height: 100%;
  }
`;
