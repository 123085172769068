import React from 'react';
import styled from 'styled-components';
import { getAnnotationStyle } from './annotationsUtil';

interface Props {
  annotationKey: string;
}

const AnnotationColorBox: React.FC<Props> = ({ annotationKey }) => {
  const annotationStyle = getAnnotationStyle(annotationKey);
  return <ColorBox {...annotationStyle} />;
};

export default AnnotationColorBox;

const ColorBox = styled.div<{
  backgroundColor?: string;
  underlineColor?: string;
  legendColor?: string;
}>`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: ${({ legendColor }) => legendColor || '#484c5a'};

  ${({ underlineColor }) =>
    underlineColor &&
    `
    border-bottom: 4px solid ${underlineColor}
    `};

  // bigger than 1920px
  @media (min-width: 1921px) {
    width: 20px;
    height: 20px;
  }
`;
