import React, { useContext } from 'react';
import { ApplicationIdentifier } from '../types';
import useTranslation from '../translations';

interface ContextState {
  appContext: ApplicationIdentifier;
  appName: string;
}

interface Props {
  appContext: ApplicationIdentifier;
}

const ApplicationContext = React.createContext<ContextState | undefined>(
  undefined
);

export const ApplicationProvider: React.FC<React.PropsWithChildren<Props>> = ({
  appContext,
  children,
}) => {
  const t = useTranslation();

  return (
    <ApplicationContext.Provider
      value={{ appContext, appName: t(`APPS.${appContext.toUpperCase()}`) }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export function useApplicationContext(): ContextState {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      'useApplicationContext must be used within ApplicationProvider'
    );
  }
  return context;
}
