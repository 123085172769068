import originalFetch from 'isomorphic-fetch';
import fetchRetry from 'fetch-retry';
import { T } from '../..';
import Api from './base';
const fetch = fetchRetry(originalFetch);
const API_BASE = '/v1/patent-uploads';

export const getOneById = (patentUploadId: string): Promise<T.PatentUpload> =>
  Api.get<T.PatentUpload>(`${API_BASE}/${patentUploadId}`).then(
    (response) => response.data
  );

export const convertDocToPdf = (formData: FormData): Promise<Blob> =>
  fetch(`https://ipos-apim.azure-api.net/v7/forms/libreoffice/convert`, {
    retryDelay(attempt) {
      return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
    },
    method: 'POST',
    credentials: 'omit',
    headers: {
      ['Ocp-Apim-Subscription-Key']: '344d6534e6af4ea28e60613ea105a279',
    },
    body: formData,
  }).then((response) => response.blob());
