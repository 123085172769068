import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Modal, Button, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { T, Components, Api } from '@ipos/shared';
import { createTsdElementSchema } from 'validations';
import { useAppContext } from 'contexts';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';

const { LanguagePicker, FormField } = Components.Form;
const { TextArea } = Input;

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
}

const CreateTsdModal: React.FC<Props> = ({ isOpen, onCloseRequest }) => {
  const t = useTranslation();
  const history = useHistory();
  const { preferredLanguage, languages } = useAppContext();

  const methods = useForm<T.CreateTsdElement>({
    defaultValues: {
      synonym: '',
      description: '',
      language: preferredLanguage,
    },
    resolver: yupResolver(createTsdElementSchema),
  });

  const promptRedirectToElement = (tsdElementId: string) => {
    Modal.confirm({
      title: t('CREATE_TSD.NOTIFICATION_TITLE'),
      content: t('CREATE_TSD.NOTIFICATION_CONTENT'),
      okText: t('CREATE_TSD.NOTIFICATION_OPEN'),
      cancelText: t('CREATE_TSD.NOTIFICATION_STAY'),
      onOk() {
        onCloseRequest();
        history.push(
          generateUrl(ROUTES.TSD_ELEMENT_ROOT, {
            tsdElementId,
          })
        );
      },
      centered: true,
    });
  };

  const createTsdMutation = useMutation(
    (newTsd: T.CreateTsdElement) => Api.tsd.createTsd(newTsd),
    {
      onSuccess: (tsd) => {
        onCloseRequest();
        message.success(t('CREATE_TSD.CREATE_SUCCESS', { name: tsd.name }));
        history.push(
          generateUrl(ROUTES.TSD_NODE, {
            tsdId: tsd.id,
            tsdNodeId: tsd.rootNode.id,
          })
        );
        promptRedirectToElement(tsd.rootNode.tsdElementId);
      },
    }
  );

  const handleSubmit = (values: T.CreateTsdElement) => {
    createTsdMutation.mutate(values);
  };

  return (
    <Modal
      title={t('CREATE_TSD.TITLE')}
      open={isOpen}
      onCancel={onCloseRequest}
      style={{ top: 140 }}
      width={600}
      footer={null}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <Form
          data-testid="create-tsd-modal-form"
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Inner>
            <FormField
              name="synonym"
              label={t('CREATE_TSD.NAME')}
              component={Input}
              style={{ flex: 1 }}
              required
              autoComplete="off"
              autoFocus
            />
            <FormField
              name="language"
              label={t('CREATE_TSD.LANGUAGE')}
              options={languages}
              component={LanguagePicker}
              style={{ width: 150 }}
            />
          </Inner>
          <FormField
            name="description"
            label={t('CREATE_TSD.DESCRIPTION')}
            component={TextArea}
            rows={8}
          />
          <Footer>
            <Button onClick={onCloseRequest}>{t('ACTION.CANCEL')}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={createTsdMutation.isLoading}
            >
              {t('ACTION.CREATE')}
            </Button>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default CreateTsdModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Inner = styled.div`
  display: flex;
  gap: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
