import React, { ReactElement } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Popover } from 'antd';
import { AadUser } from '../../types';
import AvatarCircle from './AvatarCircle';
import AvatarPopover from './AvatarPopover';

interface Props {
  user: AadUser;
  avatarSize?: number;
  withPopover?: boolean;
  style?: CSSObject;
  additionalContent?: ReactElement;
}

const Avatar: React.FC<Props> = ({
  user,
  avatarSize = 30,
  withPopover = true,
  style,
  additionalContent,
}) => {
  const { displayName, mail, mobilePhone, givenName, surname } = user;

  const avatar = (
    <AvatarCircle
      displayName={displayName}
      givenName={givenName}
      size={avatarSize}
      surname={surname}
      style={style}
    />
  );

  if (withPopover) {
    return (
      <Popover
        content={
          <AvatarPopover
            displayName={displayName}
            givenName={givenName}
            additionalContent={additionalContent}
            mail={mail}
            mobilePhone={mobilePhone}
            surname={surname}
          />
        }
        destroyTooltipOnHide
        trigger="hover"
        placement="bottomRight"
      >
        <div>{avatar}</div>
      </Popover>
    );
  }

  return <Outer>{avatar}</Outer>;
};

export default Avatar;

const Outer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
