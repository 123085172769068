import React from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Space, Tabs, Typography } from 'antd';
import TsdElementUsage from './TsdElementUsage';
import {
  TsdElementGraphics,
  TsdElementDetails,
  TsdElementAttributes,
  TsdElementContext,
} from '.';
import { DeleteTsdElementMenuOption } from 'components/MenuOptions';
import { ContentLanguage } from 'components/common';
import { Components } from '@ipos/shared';
import { useTsdElement } from 'queries';
import useTranslation from 'translations';

const { LabelValue, PageLoad, Aad, Layout } = Components;
const { Title } = Typography;
const { Grid, GridItem, Surface, AppHeader } = Layout;
const { UserContextProvider, UserAvatar, UserDetails } = Aad;

const TsdElement: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();

  const { tsdElementId } = useParams<{ tsdElementId: string }>();
  const tsdElementQuery = useTsdElement(tsdElementId);

  if (tsdElementQuery.isLoading || tsdElementQuery.isIdle) {
    return <PageLoad />;
  }
  if (tsdElementQuery.isError) {
    return <PageLoad error />;
  }

  const { data: tsdElement } = tsdElementQuery;
  const { name, createdById } = tsdElement;

  return (
    <TsdElementContext.Provider value={{ tsdElement }}>
      <AppHeader
        title={t('HEADER.TITLE.TSD_ELEMENT')}
        subtitle={tsdElement.name}
        backProps={{
          onClick: history.goBack,
        }}
      />
      <Grid rowOverrides={[[1, '50px']]}>
        <GridItem position={[1, 1, 1, 12]}>
          <Header>
            <HeaderInner>
              <LabelValue label={t('TSD_ELEMENT.NAME')}>
                <Title level={3}>{name}</Title>
              </LabelValue>
              <ContentLanguage style={{ marginBottom: 4 }} />
              <MenuBar>
                <DeleteTsdElementMenuOption />
              </MenuBar>
            </HeaderInner>
            {createdById && (
              <UserContextProvider userId={createdById}>
                <LabelValue label={t('TSD_ELEMENT.CREATED_BY')}>
                  <Space>
                    <UserAvatar avatarSize={28} />
                    <UserDetails showEmail={false} />
                  </Space>
                </LabelValue>
              </UserContextProvider>
            )}
          </Header>
        </GridItem>
        <GridItem position={[2, 7, 1, 8]}>
          <Surface type="light">
            <ElementDetails>
              <Flex>
                <TsdElementGraphics />
                <TsdElementDetails />
              </Flex>
              <TsdElementAttributes />
            </ElementDetails>
          </Surface>
        </GridItem>
        <GridItem position={[2, 7, 9, 4]}>
          <Surface type="dark">
            <AdditionalDetails>
              <Tabs defaultActiveKey="1" size="large">
                <Tabs.TabPane
                  key={1}
                  tab={t('USAGE_INDICATION.ELEMENT_USAGE').toUpperCase()}
                >
                  <TsdElementUsage />
                </Tabs.TabPane>
              </Tabs>
            </AdditionalDetails>
          </Surface>
        </GridItem>
      </Grid>
    </TsdElementContext.Provider>
  );
};

export default TsdElement;

const ElementDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  min-height: 0;
`;

const AdditionalDetails = styled.div`
  padding: 20px 30px 30px 30px;
`;

const Flex = styled.div`
  display: flex;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
`;
