import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { InferType } from 'yup';
import { message, Button, Modal, Input, notification } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api, Components, T, queryClient } from '@ipos/shared';
import { addTsdElementDescriptionSchema } from 'validations';
import { QueryKey } from 'queries';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { TextArea } = Input;
const { FormField, LanguagePicker } = Components.Form;

type FormValues = InferType<typeof addTsdElementDescriptionSchema>;

interface Props {
  disabledLanguages: T.Language[];
  description?: T.TsdElementDescription;
  onCloseRequest: () => void;
}

const SaveDescriptionModal: React.FC<Props> = ({
  disabledLanguages,
  description,
  onCloseRequest,
}) => {
  const t = useTranslation();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { languages, preferredLanguage } = useAppContext();
  const { applyChange } = useElementUsageCheck();

  const isEditing = description != null;
  const disabledLangs = disabledLanguages.filter(
    (l) => l !== description?.language
  );

  const methods = useForm({
    defaultValues: {
      value: description?.value ?? '',
      language:
        description?.language ??
        languages.filter((l) => !disabledLangs.includes(l))[0],
    },
    resolver: yupResolver(addTsdElementDescriptionSchema),
  });

  const handleError = (
    notificationMessage: string,
    notificationDescription: string
  ): void => {
    notification.error({
      message: notificationMessage,
      description: notificationDescription,
    });
  };

  const handleSuccess = (successMessage: string): void => {
    message.success(successMessage);
    onCloseRequest();
    queryClient.invalidateQueries([
      QueryKey.TsdElementData,
      tsdElementId,
      preferredLanguage,
      tsdNodeId,
    ]);
    queryClient.invalidateQueries([
      QueryKey.TsdElementDescriptions,
      tsdElementId,
      tsdNodeId,
    ]);
  };

  const createDescriptionMutation = useMutation(
    (values: T.CreateTsdElementDescription) =>
      Api.tsd.addTsdElementDescription(values),
    {
      onSuccess() {
        handleSuccess(t('DESCRIPTIONS.CREATE_SUCCESS'));
      },
      onError() {
        handleError(t('ATTRIBUTES.CREATE_FAIL'), t('DESCRIPTIONS.CREATE_FAIL'));
      },
    }
  );

  const updateDescriptionMutation = useMutation(
    (values: T.UpdateTsdElementDescription) =>
      Api.tsd.updateTsdElementDescription(values),
    {
      onSuccess() {
        handleSuccess(t('DESCRIPTIONS.UPDATE_SUCCESS'));
      },
      onError() {
        handleError(t('ATTRIBUTES.UPDATE_FAIL'), t('DESCRIPTIONS.UPDATE_FAIL'));
      },
    }
  );

  const handleSubmit = (values: FormValues) => {
    applyChange(() => {
      if (description != null) {
        updateDescriptionMutation.mutate({
          ...values,
          id: description.id,
          tsdElementId,
        });
      } else {
        createDescriptionMutation.mutate({
          ...values,
          tsdNodeId,
          tsdElementId,
        });
      }
    });
  };

  return (
    <Modal
      width={600}
      onCancel={onCloseRequest}
      destroyOnClose
      title={t(
        isEditing ? 'DESCRIPTIONS.UPDATE_TITLE' : 'DESCRIPTIONS.CREATE_TITLE'
      )}
      open={true}
      footer={null}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Inner>
            <FormField
              name="language"
              label={t('DESCRIPTIONS.LANGUAGE')}
              options={languages}
              component={LanguagePicker}
              disabledOptions={disabledLangs}
              style={{ width: 130 }}
            />
            <FormField
              name="value"
              label={t('DESCRIPTIONS.DESCRIPTION')}
              component={TextArea}
              rows={8}
              autoFocus
            />
          </Inner>
          <Footer>
            <Button onClick={onCloseRequest}>{t('ACTION.CANCEL')}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                createDescriptionMutation.isLoading ||
                updateDescriptionMutation.isLoading
              }
            >
              {t(
                isEditing
                  ? 'DESCRIPTIONS.UPDATE_DESCRIPTION'
                  : 'DESCRIPTIONS.ADD_DESCRIPTION'
              )}
            </Button>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default SaveDescriptionModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
`;
