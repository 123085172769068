import React, { useEffect, useRef, useState } from 'react';
import { Input, InputRef, Popover } from 'antd';
import { EditorView } from 'prosemirror-view';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import { SearchQuery } from '../plugins/search/query';
import {
  findNext,
  findPrev,
  replaceAll,
  replaceCurrent,
  replaceNext,
  setSearchState,
} from '../plugins/search/search';
import { FlatButton, IconButton } from '../../Button';
import { Icon } from '../../Icon';

interface SearchReplaceControlsProps {
  editorView: EditorView;
}

const SearchReplaceControls: React.FC<SearchReplaceControlsProps> = ({
  editorView,
}) => {
  const t = useTranslation();
  const [searchActive, setSearchActive] = useState(false);
  const [replaceText, setReplaceText] = useState('');
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef<InputRef>(null);

  const handleSearchReplace = (
    searchValue: string = '',
    replaceValue: string = ''
  ) => {
    setSearchText(searchValue);
    setReplaceText(replaceValue);
    const query = new SearchQuery({
      search: searchValue,
      replace: replaceValue,
    });
    editorView.dispatch(setSearchState(editorView.state.tr, query));
  };

  const handleCloseSearch = () => {
    handleSearchReplace();
    setSearchActive(false);
  };

  const handlePopoverChange = (isOpen: boolean) => {
    setSearchActive(isOpen);
    handleSearchReplace();
  };

  useEffect(() => {
    searchActive && searchRef.current?.focus();
  }, [searchActive]);

  return (
    <Popover
      trigger="click"
      open={searchActive}
      onOpenChange={() => handlePopoverChange(!searchActive)}
      placement={'top'}
      destroyTooltipOnHide
      content={
        <>
          <Flex>
            <StyledInput
              ref={searchRef}
              value={searchText}
              onChange={(e) => handleSearchReplace(e.target.value, replaceText)}
              placeholder={t('ACTION.SEARCH')}
              autoFocus
              suffix={
                <Icon
                  className="icn-clear clear-icon"
                  size={18}
                  onClick={() => handleSearchReplace('', replaceText)}
                />
              }
            />
            <StyledButton
              leftIcon={<i className="icn-arrow-up" />}
              onClick={() => {
                findPrev(editorView.state, editorView.dispatch, editorView);
              }}
            />
            <StyledButton
              leftIcon={<i className="icn-arrow-down" />}
              onClick={() => {
                findNext(editorView.state, editorView.dispatch, editorView);
              }}
            />
            <StyledButton
              leftIcon={<i className="icn-clear clear-icon" />}
              onClick={handleCloseSearch}
            />
          </Flex>
          <Flex>
            <StyledInput
              value={replaceText}
              onChange={(e) => handleSearchReplace(searchText, e.target.value)}
              placeholder={t('ACTION.REPLACE_WITH')}
              suffix={
                <Icon
                  className="icn-clear clear-icon"
                  size={18}
                  onClick={() => handleSearchReplace(searchText, '')}
                />
              }
            />
            <StyledButton
              leftIcon={<i className="icn-retry" />}
              onClick={() =>
                replaceCurrent(editorView.state, editorView.dispatch)
              }
            >
              {t('ACTION.REPLACE_CURRENT')}
            </StyledButton>
            <StyledButton
              leftIcon={<i className="icn-retry" />}
              onClick={() => replaceNext(editorView.state, editorView.dispatch)}
            >
              {t('ACTION.REPLACE_NEXT')}
            </StyledButton>
            <StyledButton
              leftIcon={<i className="icn-retry" />}
              onClick={() => replaceAll(editorView.state, editorView.dispatch)}
            >
              {t('ACTION.REPLACE_ALL')}
            </StyledButton>
          </Flex>
        </>
      }
    >
      <StyledIconButton
        className="icn-search"
        buttonStyle={{ borderRadius: 3 }}
      />
    </Popover>
  );
};

export default SearchReplaceControls;

const StyledInput = styled(Input)`
  flex: 1;
  height: 32px;
  border: none;
  margin: 0;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
  background-color: ${(props) => props.theme.colors.primary200_20};
  &.ant-input-affix-wrapper {
    box-shadow: none;
    padding-right: 6px;
    border: 2px solid transparent;
    border-right-width: 2px !important;
    &:focus-within,
    &:hover {
      border-color: ${(props) => props.theme.colors.blue600};
    }
  }
  .clear-icon {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary100};
    &:hover {
      color: ${(props) => props.theme.colors.black60};
    }
  }
  input {
    border: none;
    color: ${(props) => props.theme.colors.white87};
    background-color: transparent;
    &::placeholder {
      color: ${(props) => props.theme.colors.primary100};
    }
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 0 8px 8px;
`;

const StyledButton = styled(FlatButton)`
  height: 32px;
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIconButton = styled(IconButton)`
  &:first-child {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white70};
  }
`;
