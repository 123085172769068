import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { DocumentPickerDisplayItem } from '../types';
import useTranslation from '../../../translations';
import DocumentPickerInputItems from './DocumentPickerInputItems';

interface Props {
  items: DocumentPickerDisplayItem[];
  isPopoverOpen: boolean;
  isError: boolean;
  style?: CSSProperties;
  onRemoveItem: (itemId: string) => void;
  onToggle: () => void;
}

const DocumentPickerInput = React.forwardRef<HTMLDivElement, Props>(
  ({ isPopoverOpen, items, isError, style, onRemoveItem, onToggle }, ref) => {
    const t = useTranslation();
    const iconClassName = isPopoverOpen ? 'icn-chevron-up' : 'icn-chevron-down';

    return (
      <Outer ref={ref} $error={isError} onClick={onToggle} style={style}>
        {items.length > 0 ? (
          <DocumentPickerInputItems items={items} onRemoveItem={onRemoveItem} />
        ) : (
          <Inner>
            <Message>{t('MULTI_DOC_SEARCH.SELECT_DOCUMENTS')}</Message>
          </Inner>
        )}
        <StyledIcon className={iconClassName} />
      </Outer>
    );
  }
);

export default DocumentPickerInput;

const Message = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-left: 17px;
  user-select: none;
  color: ${(props) => props.theme.colors.white40};
`;

const Outer = styled.div<{ $error?: boolean }>`
  display: flex;
  align-items: center;
  width: 300px;
  height: 40px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.primary200_30};
  padding-right: 10px;
  padding-left: 3px;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
  &:hover {
    border-color: ${(props) => props.theme.colors.blue600};
  }
  ${(props) =>
    props.$error && `border-color: ${props.theme.colors.red500} !important;`}
  ${(props) =>
    props.$error && `${Message} { color: ${props.theme.colors.red500}; }`}
`;

const Inner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.i`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${(props) => props.theme.colors.white70};
`;
