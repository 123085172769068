import { Command, Transaction } from 'prosemirror-state';
import { liftTarget, ReplaceAroundStep } from 'prosemirror-transform';
import { Fragment, NodeRange, ResolvedPos, Slice } from 'prosemirror-model';
import { findParentNodeOfType } from '../util';

export const claimPartDedent: Command = (state, dispatch) => {
  const claimPartOnCursor = findParentNodeOfType(state.schema.nodes.claimPart)(
    state.selection
  );
  const claimPartPos =
    claimPartOnCursor && state.doc.resolve(claimPartOnCursor.start);

  if (!claimPartOnCursor || !claimPartPos) {
    return false;
  }

  const currentClaimPart = claimPartOnCursor.node;
  const currentIndentLevel = currentClaimPart.attrs.indentLevel ?? 0;

  const sectionType = state.schema.nodes.section;
  const { $from, $to } = state.selection;
  let range = findListsRange($from, $to);
  if (!range) {
    return false;
  }
  if ($from.node(range.depth - 1).type === sectionType) {
    return false;
  }
  const tr = state.tr;

  if (moveRangeSiblings(tr, range)) {
    const $from = tr.doc.resolve(range.$from.pos);
    const $to = tr.doc.resolve(range.$to.pos);
    range = new NodeRange($from, $to, range.depth);
  }
  const target = liftTarget(range);
  if (target == null) {
    return false;
  }
  const indentLevel = Math.max(currentIndentLevel - 1, 0);
  tr.lift(range, target);
  const pos = tr.mapping.map(range.start);
  tr.setNodeMarkup(pos, null, {
    ...currentClaimPart.attrs,
    indentLevel,
  });
  dispatch?.(tr);

  return true;
};

function moveRangeSiblings(tr: Transaction, range: NodeRange): boolean {
  const claimPartType = tr.doc.type.schema.nodes.claimPart;
  const { $to, depth, end, parent, endIndex } = range;
  const endOfParent = $to.end(depth);

  if (end < endOfParent) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    const lastChild = parent.maybeChild(endIndex - 1);
    if (!lastChild) {
      return false;
    }

    const canAppend =
      endIndex < parent.childCount &&
      lastChild.canReplace(
        lastChild.childCount,
        lastChild.childCount,
        parent.content,
        endIndex,
        parent.childCount
      );

    if (canAppend) {
      tr.step(
        new ReplaceAroundStep(
          end - 1,
          endOfParent,
          end,
          endOfParent,
          new Slice(Fragment.from(claimPartType.create(null)), 1, 0),
          0,
          true
        )
      );
    } else {
      tr.step(
        new ReplaceAroundStep(
          end,
          endOfParent,
          end,
          endOfParent,
          new Slice(Fragment.from(claimPartType.create(null)), 0, 0),
          1,
          true
        )
      );
    }

    return true;
  }
  return false;
}

export function findListsRange(
  $from: ResolvedPos,
  $to: ResolvedPos = $from
): NodeRange | null {
  if ($to.pos < $from.pos) {
    return findListsRange($to, $from);
  }

  let range = $from.blockRange($to);

  while (range) {
    if (isListsRange(range)) {
      return range;
    }

    if (range.depth <= 0) {
      break;
    }

    range = new NodeRange($from, $to, range.depth - 1);
  }

  return null;
}

export function isListsRange(range: NodeRange): boolean {
  const { startIndex, endIndex, parent } = range;

  for (let i = startIndex; i < endIndex; i++) {
    const node = parent.child(i);
    if (node.type.name !== 'claimPart') {
      return false;
    }
  }

  return true;
}
