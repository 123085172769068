import { Plugin, PluginKey, EditorState, Transaction } from 'prosemirror-state';
interface Options {
  nodeTypes: string[];
}

export const preventNodeDeletionPluginKey = new PluginKey(
  'preventNodeDeletionPlugin'
);

const preventNodeDeletionPlugin = (options: Options) =>
  new Plugin({
    key: preventNodeDeletionPluginKey,
    state: {
      init() {
        return { allowAllNodesDeletion: false };
      },
      apply(tr, value) {
        const meta = tr.getMeta(preventNodeDeletionPluginKey);
        if (meta) {
          return { ...value, ...meta };
        }
        return value;
      },
    },
    filterTransaction(transaction: Transaction, state: EditorState) {
      const pluginState = preventNodeDeletionPluginKey.getState(state);
      if (pluginState?.allowAllNodesDeletion) {
        return true;
      }

      for (const step of transaction.steps) {
        //@ts-ignore
        if (step.jsonID === 'replace') {
          //@ts-ignore
          const deletionRanges = step.getMap().ranges;
          for (let i = 0; i < deletionRanges.length; i += 3) {
            const from = deletionRanges[i];
            const to = from + deletionRanges[i + 1];
            let deleteNode = false;
            state.doc.nodesBetween(from, to, (node, pos) => {
              if (options.nodeTypes.includes(node.type.name)) {
                const nodeStart = pos;
                if (from <= nodeStart) {
                  deleteNode = true;
                }
              }
            });
            if (deleteNode) {
              return false;
            }
          }
        }
      }
      return true;
    },
    props: {
      handleDOMEvents: {
        drop(view) {
          view.dispatch(
            view.state.tr.setMeta(preventNodeDeletionPluginKey, {
              allowAllNodesDeletion: true,
            })
          );
          setTimeout(() => {
            view.dispatch(
              view.state.tr.setMeta(preventNodeDeletionPluginKey, {
                allowAllNodesDeletion: false,
              })
            );
          }, 0);
          return false;
        },
        dragend(view) {
          view.dispatch(
            view.state.tr.setMeta(preventNodeDeletionPluginKey, {
              allowAllNodesDeletion: false,
            })
          );
          return false;
        },
      },
    },
  });

export default preventNodeDeletionPlugin;
