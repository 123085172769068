import { MarkSpec } from 'prosemirror-model';

const subscript: MarkSpec = {
  parseDOM: [{ tag: 'sub' }, { style: 'vertical-align=sub' }],
  toDOM() {
    return ['sub', 0];
  },
  excludes: 'superscript',
};

export default subscript;
