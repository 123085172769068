import React from 'react';
import { Components } from '@ipos/shared';
import { useAppContext } from 'contexts';

const { ContentLanguageBase } = Components;

interface Props {
  style?: React.CSSProperties;
}

const ContentLanguage: React.FC<Props> = ({ style }) => {
  const { preferredLanguage, languages, setPreferredLanguage } =
    useAppContext();

  return (
    <ContentLanguageBase
      value={preferredLanguage}
      languages={languages}
      style={style}
      onChange={setPreferredLanguage}
    />
  );
};

export default ContentLanguage;
