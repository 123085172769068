import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { VoidButton, PrimaryButton, FlatButton } from '../Button';
import {
  MultiSortingField as IMultiSortingField,
  SortOrder,
} from '../../types';
import useTranslation from '../../translations';
import MultiSortingField from './MultiSortingField';

interface Props {
  title: string;
  description: string;
  fields: IMultiSortingField[];
  onCloseRequest: () => void;
  onApply: (fields: IMultiSortingField[]) => void;
}

const MultiSortingModal: React.FC<Props> = ({
  title,
  fields,
  description,
  onCloseRequest,
  onApply,
}) => {
  const t = useTranslation();

  const [localFields, setLocalFields] = useState<IMultiSortingField[]>(fields);

  const onFieldValueChange = (key: string, value: SortOrder) => {
    const newLocalFields = localFields.map((field) => {
      if (field.key === key) {
        return {
          ...field,
          value,
        };
      }

      return field;
    });

    setLocalFields(newLocalFields);
  };

  const onClear = () => {
    setLocalFields(
      localFields.map((field) => ({
        ...field,
        value: null,
      }))
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (result.destination?.index === undefined) {
      return;
    }

    const newLocalFields = [...localFields];
    const [removed] = newLocalFields.splice(result.source.index, 1);
    newLocalFields.splice(result.destination.index, 0, removed);
    setLocalFields(newLocalFields);
  };

  return (
    <Modal
      data-testid="multi-sorting-modal"
      destroyOnClose
      width={500}
      onCancel={onCloseRequest}
      title={title}
      style={{ top: 150 }}
      bodyStyle={{
        paddingLeft: 40,
        paddingRight: 40,
      }}
      open={true}
      footer={null}
      maskClosable={false}
    >
      <Description>{description}</Description>

      <main>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="multiSortingFieldsDroppable">
            {(provided) => (
              <Main ref={provided.innerRef} {...provided.droppableProps}>
                {localFields.map((field, index) => (
                  <Draggable
                    key={field.key}
                    draggableId={field.key}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <DraggableWrapper
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        <div
                          {...draggableProvided.dragHandleProps}
                          data-testid={`multi-sorting-modal-drag-handle-${field.key}`}
                        >
                          <i className="icn-drag" />
                        </div>
                        <MultiSortingField
                          key={field.key}
                          field={field}
                          onChange={(value) =>
                            onFieldValueChange(field.key, value)
                          }
                        />
                      </DraggableWrapper>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </Main>
            )}
          </Droppable>
        </DragDropContext>
      </main>

      <Footer>
        <ButtonGroups>
          <ClearButton
            onClick={onClear}
            testId="multi-sorting-modal-clear-button"
          >
            {t('ACTION.CLEAR')}
          </ClearButton>
        </ButtonGroups>

        <ButtonGroups>
          <CancelButton
            onClick={onCloseRequest}
            testId="multi-sorting-modal-cancel-button"
          >
            {t('ACTION.CANCEL')}
          </CancelButton>
          <ApplyButton
            onClick={() => {
              onApply(localFields);
              onCloseRequest();
            }}
            testId="multi-sorting-modal-apply-button"
          >
            {t('ACTION.APPLY')}
          </ApplyButton>
        </ButtonGroups>
      </Footer>
    </Modal>
  );
};

export default MultiSortingModal;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.white60};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin: 0 -10px;
`;

const ButtonGroups = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ApplyButton = styled(PrimaryButton)`
  padding: 10px 20px;
`;

const CancelButton = styled(VoidButton)`
  padding: 10px 20px;
`;

const ClearButton = styled(FlatButton)`
  padding: 4px;
  color: ${({ theme }) => theme.colors.blue300};
`;

const DraggableWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
