import React from 'react';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationMostUsedCpcClasses } from 'queries';

const { Visualizations } = Components;
const { ColumnChart } = Visualizations;

const MostUsedCpcClassesColumnChart: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationMostUsedCpcClasses();

  if (!data) {
    return null;
  }

  const chartConfig = {
    data,
    series: [{ name: 'Number of Cpc Classes' }],
    tooltip: {
      formatter: (v: Record<string, string | number>) => ({
        name: t('STATS.OCCURRENCES_TOOLTIP'),
        value: v.y,
      }),
    },
    xAxis: {
      title: t('STATS.CPC_CLASS_CODES'),
    },
    yAxis: {
      title: t('STATS.CPC_CLASS_OCCURRENCES'),
    },
  };

  return (
    <ColumnChart
      config={chartConfig}
      title={t('STATS.TOP10_MOST_USED_CPC_CLASSES')}
    />
  );
};

export default MostUsedCpcClassesColumnChart;
