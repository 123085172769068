import React, { useContext, useState } from 'react';
import { T, Contexts } from '@ipos/shared';

const { useSessionContext } = Contexts;
const DEFAULT_LANGUAGE = T.Language.De;

interface ContextState {
  languages: T.Language[];
  preferredLanguage: T.Language;
  setPreferredLanguage: (lang: T.Language) => void;
}

export const AppContext = React.createContext<ContextState | undefined>(
  undefined
);

export const AppContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { groupSettings } = useSessionContext();

  const [preferredLanguage, setPreferredLanguage] =
    useState<T.Language>(DEFAULT_LANGUAGE);

  return (
    <AppContext.Provider
      value={{
        languages: groupSettings.tsdLanguages,
        preferredLanguage,
        setPreferredLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): ContextState => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext must be used within a AppContextProvider`);
  }
  return context;
};
