import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { Icon } from '../Icon';

interface Props {
  search: string;
  onChange: (value: string) => void;
}

const TsdTreeSearch: React.FC<Props> = ({ search, onChange }) => {
  const t = useTranslation();

  return (
    <Outer>
      <Icon className="icn-search" size={20} />
      <StyledInput
        placeholder={t('TSD_TREE.PLACEHOLDER.SEARCH_TSD')}
        type="text"
        autoComplete="off"
        autoFocus
        value={search}
        onChange={(e) => onChange(e.target.value)}
      />
    </Outer>
  );
};

export default TsdTreeSearch;

const Outer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.primary200_30};
  padding: 12px 20px 12px 12px;
  margin-bottom: 15px;
`;

const StyledInput = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  margin: 0 5px;
  line-height: 21px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.white87};
  &::placeholder {
    color: ${(props) => props.theme.colors.white40};
  }
`;
