import { ApplicationIdentifier } from '../types';

const appUriMap: { [key in ApplicationIdentifier]?: string } = {
  [ApplicationIdentifier.TSD_EDITOR]: 'TSD_EDITOR_APP_BASE_URL',
  [ApplicationIdentifier.EVALUATOR]: 'EVALUATOR_APP_BASE_URL',
  [ApplicationIdentifier.OFFICE_ACTION_HERO]: 'OFFICE_ACTION_HERO_APP_BASE_URL',
  [ApplicationIdentifier.DDUDE]: 'DDUDE_APP_BASE_URL',
  [ApplicationIdentifier.COMPETITOR]: 'COMPETITOR_APP_BASE_URL',
  [ApplicationIdentifier.PATENTOR]: 'PATENTOR_APP_BASE_URL',
  [ApplicationIdentifier.LIFTOFF]: 'LIFTOFF_APP_BASE_URL',
  [ApplicationIdentifier.DASHBOARD]: 'DASHBOARD_APP_BASE_URL',
};

export function getAppUrl(
  appIdentifier: ApplicationIdentifier
): string | undefined {
  const envVarName = appUriMap[appIdentifier];
  return envVarName ? window[envVarName as keyof typeof window] : undefined;
}

export function getAppUrls(): {
  [key in ApplicationIdentifier]?: string;
} {
  const appUris: { [key in ApplicationIdentifier]?: string } = {};

  Object.entries(appUriMap).forEach(([key, envVarName]) => {
    const appUri = envVarName
      ? window[envVarName as keyof typeof window]
      : undefined;

    appUris[key as ApplicationIdentifier] = appUri;
  });

  return appUris;
}
