import { css } from 'styled-components';

export default css`
  .claim {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    position: relative;

    .number {
      display: flex;
      gap: 2px;
      user-select: auto;
    }

    .claim-number {
      user-select: auto;
    }

    .old-claim-number {
      color: red;
      text-decoration: line-through;
      user-select: none;
    }

    .content,
    & > [data-node-view-content='true'] {
      flex: 1;
      display: grid;
      grid-template-columns: auto 1fr;

      & > * {
        grid-column: 2;
      }
    }

    .note {
      font-size: 12px;
      font-style: italic;
      color: rgba(0, 0, 0, 0.5);
      bottom: -25px;
      margin: 0 20px;
      position: absolute;
    }
    transition: opacity 0.2s;
    > .icn-drag {
      opacity: 0;
    }
    :hover {
      .icn-drag {
        opacity: 1;
      }
    }
  }

  [data-node-type='claim'] {
    &::after {
      content: attr(data-old-claim-number);
      color: red;
      text-decoration: line-through;
      position: absolute;
      top: 18px;
      right: 100%;
    }
  }

  .claim-part {
    display: flex;
    gap: 5px;

    & > div:first-child {
      grid-column: 1;
    }

    & > div:last-child {
      grid-column: 2;
    }

    &.preamble-delimiter > .content > p {
      font-weight: bold;
    }
    & > .content > span {
      margin-right: 8px;
    }
    & > .content > span,
    .content > span + * {
      grid-column: span 1;
    }

    &[data-hide-prefix='true'] {
      & > div:last-child {
        grid-column: span 2;
        display: grid;
        grid-template-columns: auto 1fr;
      }

      // For claim part in claim part which doesn't have prefix
      // We add a minimum padding to the left to make it look better
      &
        > .content
        > .claim-part:not(
          [data-numbering-type='bullet'],
          [data-numbering-type='circle'],
          [data-numbering-type='square'],
          [data-numbering-type='disc'],
          [data-numbering-type='diamond'],
          [data-numbering-type='decimal'],
          [data-numbering-type='lower-alpha'],
          [data-numbering-type='upper-alpha'],
          [data-numbering-type='lower-roman'],
          [data-numbering-type='upper-roman'],
          [data-part-type='CharacterizingFeature'],
          [data-numbering-type='custom']
        ) {
        display: grid;
        grid-template-columns: subgrid;
        // 33px equals 8.82mm
        margin-left: 33px;
      }
    }
  }
`;
