import { Plugin, PluginKey } from 'prosemirror-state';
import React from 'react';
import { Schema } from 'prosemirror-model';
import { ClaimEditorDocumentResponse, EditorContentType } from '../../../types';
import { Api } from '../../../..';
import { createReactNodeView } from './imageViewPlugin/ReactNodeView';
import ImageViewClaims from './imageViewPlugin/ImageViewClaims';
import pasteHandler from './imageViewPlugin/pasteHandler';

const imageActionsPluginKey = new PluginKey('imageActionsPlugin');

const imageClaimPlugin = ({
  schema,
  editorDocumentId,
  handleCreatePortal,
  handleUploadSuccess,
  isCleanCopy = false,
}: {
  schema: Schema;
  handleCreatePortal: (portal: React.ReactPortal) => void;
  editorDocumentId?: string;
  isCleanCopy?: boolean;
  handleUploadSuccess?: (data: ClaimEditorDocumentResponse) => void;
}): Plugin =>
  new Plugin({
    key: imageActionsPluginKey,
    props: {
      nodeViews: {
        embeddedObject: (node, view, getPos, decorations) =>
          createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            component: ImageViewClaims,
            onCreatePortal: handleCreatePortal,
            editorDocumentId,
            handleUploadSuccess,
            isCleanCopy,
          }),
        image: (node, view, getPos, decorations) =>
          createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            component: ImageViewClaims,
            onCreatePortal: handleCreatePortal,
            editorDocumentId,
            handleUploadSuccess,
            isCleanCopy,
          }),
      },
      handleDOMEvents: {
        paste: pasteHandler({
          editorType: EditorContentType.Claims,
          schema,
          editorDocumentId,
          uploadFunction: Api.oa.postUploadClaimEditorResource,
        }),
      },
    },
  });

export default imageClaimPlugin;
