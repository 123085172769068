import React from 'react';
import styled from 'styled-components';
import { DocumentFormat, Language } from '../../../types';
import CountryItem from './CountryItem';
import FormatSelection from './FormatSelection';

interface Props {
  language: Language;
  isAuthenticLanguage: boolean;
  checkedFormats: DocumentFormat[];
  hasTranslation?: boolean;
  onFormatCheck: (
    language: Language,
    format: DocumentFormat,
    checked: boolean
  ) => void;
}

const LanguageItem: React.FC<Props> = ({
  language,
  isAuthenticLanguage,
  checkedFormats,
  hasTranslation,
  onFormatCheck,
}) => {
  const handleFormatCheck = (format: DocumentFormat, checked: boolean) => {
    onFormatCheck(language, format, checked);
  };

  return (
    <Outer>
      <CountryItem
        language={language}
        hasTranslation={hasTranslation}
        isAuthenticLanguage={isAuthenticLanguage}
      />
      <FormatSelection
        checkedFormats={checkedFormats}
        onFormatCheck={handleFormatCheck}
      />
    </Outer>
  );
};

export default LanguageItem;

const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding-left: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white20};
`;
