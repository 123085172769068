import React from 'react';
import styled from 'styled-components';
import { T } from '../../../..';
import TreeElement from './TreeElement';

const Tree: React.FC<{
  tree: T.TreeItem[];
  activeItemId?: string;
  onFileItemId?: string;
  suggestions?: string[];
  onItemClick: (item: T.TreeItem) => void;
  onItemDoubleClick?: (item: T.TreeItem) => void;
  onItemRename?: (item: T.TreeItem, newValue: string) => Promise<void> | void;
  onItemCopy?: (item: T.TreeItem, title: string) => Promise<void> | void;
  onItemDelete?: (item: T.TreeItem) => Promise<void> | void;
  deleteConfirmation?: (
    item: T.TreeItem,
    confirm: (force: boolean) => void,
    onCancel: () => void
  ) => React.ReactNode;
}> = ({
  tree,
  activeItemId,
  onFileItemId,
  suggestions,
  onItemClick,
  onItemDoubleClick,
  onItemRename,
  onItemCopy,
  onItemDelete,
  deleteConfirmation,
}) => (
  <Wrapper>
    <TreeOuter>
      {tree.map((child) => (
        <TreeElement
          key={child.id}
          item={child}
          activeItemId={activeItemId}
          onFileItemId={onFileItemId}
          suggestions={suggestions}
          onClick={onItemClick}
          onDoubleClick={onItemDoubleClick}
          onRename={onItemRename}
          onCopy={onItemCopy}
          onDelete={onItemDelete}
          deleteConfirmation={deleteConfirmation}
        />
      ))}
    </TreeOuter>
  </Wrapper>
);

export default Tree;

const Wrapper = styled.div`
  padding: 5px;
  & > ul {
    margin-bottom: 10px;
  }
`;

const TreeOuter = styled.ul`
  &,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: ${25 / 2}px; // box-width 25
  }
  & > li {
    border-left: none;
    padding-left: 0;
    &:before {
      content: none;
      display: none;
    }
  }
`;
