/* eslint-disable */
import React, { ReactText, useContext, useReducer } from 'react';

const initialState = {};
const ReactNodeViewPortalsContext = React.createContext<{
  createPortal: (portal: React.ReactPortal) => void;
  state: Partial<State>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createPortal: () => {},
  state: {},
});

const ReactNodeViewPortalsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  return (
    <ReactNodeViewPortalsContext.Provider
      value={{
        createPortal: (portal: React.ReactPortal) =>
          portal.key &&
          dispatch({ type: 'createPortal', key: portal.key, portal }),
        state: data,
      }}
    >
      {children}
      {Object.values(data).map((obj) => obj.portal)}
    </ReactNodeViewPortalsContext.Provider>
  );
};

type State = {
  [key: string]: any;
};

type Action = {
  type: 'createPortal';
  key: ReactText;
  portal: React.ReactPortal;
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'createPortal':
      return {
        ...state,
        [action.key]: {
          portal: action.portal,
        },
      };
    default:
      return state;
  }
}

export const useReactNodeViewPortals = () =>
  useContext(ReactNodeViewPortalsContext);

export default ReactNodeViewPortalsProvider;
