import { useSessionContext } from '../contexts';

export default function usePermissionCheck(
  permissions: string | string[]
): boolean {
  const { session } = useSessionContext();

  let requiredPermissions: string[] = [];
  requiredPermissions = requiredPermissions.concat(permissions);

  return requiredPermissions.every((requiredPermission) =>
    session.azureGroups.find((p) => p === requiredPermission)
  );
}
