import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { message, notification } from 'antd';
import DescriptionListItem from './DescriptionListItem';
import SaveDescriptionModal from './SaveDescriptionModal';
import { Api, T, Hooks } from '@ipos/shared';
import { useTsdElementContext } from 'components/TsdElement/TsdElementContext';
import { QueryKey } from 'queries';
import { useElementUsageCheck } from 'hooks';
import { useAppContext } from 'contexts';
import useTranslation from 'translations';

const { useInvalidateQueries } = Hooks;

interface Props {
  data: T.TsdElementDescription[];
  disabledLanguages: T.Language[];
}

const DescriptionList: React.FC<Props> = ({ data, disabledLanguages }) => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();
  const { tsdElementId, tsdNodeId } = useTsdElementContext();
  const { invalidateQueries } = useInvalidateQueries();
  const { applyChange } = useElementUsageCheck();
  const [editedItem, setEditedItem] = useState<T.TsdElementDescription | null>(
    null
  );

  const checkForWarnings = (
    allItems: T.TsdElementDescription[],
    item: T.TsdElementDescription
  ): string[] => {
    const warnings: string[] = [];
    // check if description is doubled
    // should only allow one description per language per node/element
    if (
      allItems.find(
        (d) =>
          d.id != item.id &&
          d.tsdNodeId == item.tsdNodeId &&
          d.language == item.language
      ) != null
    ) {
      warnings.push('DESCRIPTIONS.DUPLICATE_DETECTED');
    }
    return warnings;
  };

  const deleteMutation = useMutation(
    (id: string) => Api.tsd.deleteTsdElementDescription(tsdElementId, id),
    {
      onSuccess: () => {
        message.success(t('DESCRIPTIONS.DELETE_SUCCESS'));
        invalidateQueries(
          [QueryKey.TsdElementData, tsdElementId, preferredLanguage, tsdNodeId],
          [QueryKey.TsdElementDescriptions, tsdElementId, tsdNodeId]
        );
      },
      onError: () => {
        notification.error({
          message: t('ATTRIBUTES.DELETE_FAIL'),
          description: t('DESCRIPTIONS.DELETE_FAIL'),
        });
      },
    }
  );

  const handleEditRequest = (item: T.TsdElementDescription) => {
    setEditedItem(item);
  };

  const handleRemoveRequest = (item: T.TsdElementDescription) => {
    applyChange(() => deleteMutation.mutate(item.id));
  };

  return (
    <Outer>
      {data.map((d) => (
        <DescriptionListItem
          key={d.id}
          item={d}
          warnings={checkForWarnings(data, d)}
          onRemoveRequest={handleRemoveRequest}
          onEditRequest={handleEditRequest}
        />
      ))}
      {editedItem && (
        <SaveDescriptionModal
          description={editedItem}
          disabledLanguages={disabledLanguages}
          onCloseRequest={() => setEditedItem(null)}
        />
      )}
    </Outer>
  );
};

export default DescriptionList;

const Outer = styled.div`
  margin-top: 20px;
  flex: 1;
  overflow: hidden auto;
`;
