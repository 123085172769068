import { useCallback, useEffect, useRef, useState } from 'react';
import { Node } from 'prosemirror-model';
import {
  CalculateDiff,
  CalculatePatches,
  DiffOperationResponse,
  DiffTuple,
  Operation,
  PatchOperationResponse,
  WorkerDiffRequest,
} from '../../types';
import createWorker from './worker.metaurl';

type WorkerDiffResponse = PatchOperationResponse | DiffOperationResponse;

type WorkerCallback = (response: WorkerDiffResponse) => void;

interface HookShape {
  (enabled?: boolean): {
    isCalculatingDiff: boolean;
    calculateDiff: CalculateDiff;
    calculatePatches: CalculatePatches;
    isInitialized: boolean;
  };
}

const useDiffCalculator: HookShape = (enabled = true) => {
  const messageId = useRef(0);
  const callbacks = useRef<Record<number, WorkerCallback>>({});
  const workQueue = useRef<Record<number, WorkerDiffRequest>>({});
  const worker = useRef<Worker | null>();
  const [isCalculatingDiff, setIsCalculatingDiff] = useState(false);
  const calculateDiff = (
    firstEntry: string,
    secondEntry: string,
    index?: number
  ) => {
    setIsCalculatingDiff(true);
    messageId.current++;
    const message: WorkerDiffRequest = {
      type: 'diff',
      firstEntry,
      secondEntry,
      index,
      messageId: messageId.current,
    };
    return new Promise<DiffTuple[]>((resolve) => {
      callbacks.current[messageId.current] = (response) => {
        setIsCalculatingDiff(false);
        resolve((response as DiffOperationResponse).diffs);
      };
      queueWorkForWorker(message);
    });
  };

  const calculatePatches = (
    firstEntry: Node,
    secondEntry: Node,
    index?: number
  ) => {
    messageId.current++;
    const message: WorkerDiffRequest = {
      type: 'jsonPatch',
      firstEntry,
      secondEntry,
      index,
      messageId: messageId.current,
    };
    return new Promise<Operation[]>((resolve) => {
      callbacks.current[messageId.current] = (response) => {
        resolve((response as PatchOperationResponse).patches);
      };
      queueWorkForWorker(message);
    });
  };

  const queueWorkForWorker = (message: WorkerDiffRequest) => {
    if (!worker?.current) {
      workQueue.current = {
        ...workQueue.current,
        [message.messageId]: message,
      };
    } else {
      worker.current.postMessage(message);
    }
  };

  const flushQueue = () => {
    if (Object.keys(workQueue.current).length === 0) {
      return;
    }
    Object.values(workQueue.current).forEach((message) => {
      if (!worker.current) {
        return;
      }
      worker.current.postMessage(message);
      delete workQueue.current[message.messageId];
    });
  };

  const onMessage = useCallback((event: MessageEvent) => {
    const message = event.data as WorkerDiffResponse;
    if (!message || message.messageId === undefined) {
      return;
    }
    const { messageId: mId } = message;
    const callback = callbacks.current[mId];

    if (!callback) {
      return;
    }

    delete callbacks.current[mId];
    callback(message);
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    worker.current = createWorker();
    worker.current.onmessage = onMessage;
    flushQueue();
    return () => {
      worker.current?.terminate();
      worker.current = null;
    };
  }, [onMessage, enabled]);

  return {
    isCalculatingDiff,
    calculateDiff,
    calculatePatches,
    isInitialized: worker.current !== null,
  };
};

export default useDiffCalculator;
