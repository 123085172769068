import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../../translations';
import { HeaderButton } from '../../../Layout';
import { Spinner } from '../../../Spinner';
import { Colors } from '../../../../theme';

interface Props {
  isLoading: boolean;
}

const SearchButton: React.FC<Props> = ({ isLoading }) => {
  const t = useTranslation();

  return (
    <StyledButton
      htmlType="submit"
      label={
        <>
          {isLoading ? (
            <Spinner
              size="small"
              color={Colors.white87}
              style={{ marginRight: 4 }}
            />
          ) : (
            <SearchIcon className="icn-search" />
          )}
          <span>{t('MULTI_DOC_SEARCH.SEARCH')}</span>
        </>
      }
    />
  );
};

export default SearchButton;

const SearchIcon = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.colors.white87};
`;

const StyledButton = styled(HeaderButton)`
  height: 40px;
  padding: 8px 16px;
  min-width: 120px;
  font-size: 14px;
  font-weight: 400;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
