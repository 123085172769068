import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Modal, message, Button, Input, Space, Tag } from 'antd';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Api, T, Components, Hooks } from '@ipos/shared';
import useTranslation from 'translations';
import ROUTES, { generateUrl } from 'routes';
import { QueryKey, useTsdNode } from 'queries';

const { Notification } = Components;
const { useInvalidateQueries } = Hooks;

interface Props {
  tsdId: string;
  tsdNodeId: string;
  isOpen: boolean;
  onCloseRequest: () => void;
}

const DeleteTsdNodeModal: React.FC<Props> = ({
  tsdId,
  tsdNodeId,
  isOpen,
  onCloseRequest,
}) => {
  const t = useTranslation();

  const history = useHistory();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const { data: tsdNode, isLoading, isError } = useTsdNode(tsdId, tsdNodeId);

  const deleteNodeMutation = useMutation(
    (tsdNode: T.TsdNode) => Api.tsd.deleteNode(tsdNode.tsdId, tsdNode.id),
    {
      onSuccess: async () => {
        message.success(t('DELETE_NODE.SUCCESS'));
        await invalidateQueries([QueryKey.TsdData, tsdId]);
        onCloseRequest();
      },
      onError: () => {
        message.error(t('SOMETHING_WENT_WRONG'));
      },
    }
  );

  const handleDeleteClick = () => {
    if (tsdNode === undefined) {
      return;
    }

    deleteNodeMutation.mutate(tsdNode, {
      onSuccess() {
        if (tsdNode.parentNodeId) {
          history.push(
            generateUrl(ROUTES.TSD_NODE, {
              tsdId: tsdNode.tsdId,
              tsdNodeId: tsdNode.parentNodeId,
            })
          );
        } else {
          history.push(
            generateUrl(ROUTES.TSD_ROOT, {
              tsdId: tsdNode.tsdId,
            })
          );
        }
      },
      onSettled() {
        setConfirmationText('');
      },
    });
  };

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <Notification spin />;
      case isError:
        return <Notification error />;
      default:
        return (
          <>
            <Trans
              t={t}
              i18nKey="DELETE_NODE.CONFIRMATION"
              values={{ name: tsdNode?.name }}
              components={{
                tag: <StyledTag />,
              }}
              parent="p"
            />
            <Input
              type="text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              autoComplete="off"
              autoFocus
            />
          </>
        );
    }
  };

  return (
    <Modal
      title={t('DELETE_NODE.TITLE')}
      style={{ top: 140 }}
      open={isOpen}
      destroyOnClose
      onCancel={onCloseRequest}
      width={550}
      footer={null}
    >
      <Body>
        <Space direction="vertical">{getContent()}</Space>
      </Body>
      <Footer>
        <Space>
          <Button onClick={onCloseRequest}>{t('ACTION.CANCEL')}</Button>
          <Button
            loading={deleteNodeMutation.isLoading || isInvalidating}
            onClick={handleDeleteClick}
            type="primary"
            disabled={confirmationText !== tsdNode?.name}
          >
            {t('ACTION.DELETE')}
          </Button>
        </Space>
      </Footer>
    </Modal>
  );
};

export default DeleteTsdNodeModal;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const StyledTag = styled(Tag)`
  white-space: pre-wrap;
  display: inline;
  margin-left: 5px;
  padding: 2px 7px;
`;
