import React, { useMemo } from 'react';
import { PageLoad } from '..';
import useTranslation from '../../translations';
import { ApplicationIdentifier, DocViewerParams } from '../../types';
import { useUrlSearchParams } from '../../hooks';
import './styles.less';
import MultiDocViewer from './MultiDocViewer';

function parseUrlParam(urlParam?: string | null): string | undefined {
  if (!urlParam) {
    return undefined;
  }
  if (urlParam === 'null') {
    return undefined;
  }
  if (urlParam === 'undefined') {
    return undefined;
  }
  return urlParam;
}

const MultiDocViewerWrapper: React.FC = () => {
  const t = useTranslation();
  const urlParams = useUrlSearchParams();

  const params = useMemo<Partial<DocViewerParams>>(
    () => ({
      rootId: parseUrlParam(urlParams.get('rootId') as string),
      applicationIdentifier: urlParams.get(
        'applicationIdentifier'
      ) as ApplicationIdentifier,
      firstDocumentId: parseUrlParam(urlParams.get('firstDocumentId')),
      secondDocumentId: parseUrlParam(urlParams.get('secondDocumentId')),
      thirdDocumentId: parseUrlParam(urlParams.get('thirdDocumentId')),
      startingPages: parseUrlParam(urlParams.get('startingPages')),
      defaultVisibleDocCount: parseUrlParam(
        urlParams.get('defaultVisibleDocCount')
      ),
      title: parseUrlParam(urlParams.get('title')),
      subtitle: parseUrlParam(urlParams.get('subtitle')),
      hideAnnotations:
        parseUrlParam(urlParams.get('hideAnnotations')) === 'true',
      hideHeader: parseUrlParam(urlParams.get('hideHeader')) === 'true',
    }),
    [urlParams]
  );

  if (
    !params.firstDocumentId ||
    !params.secondDocumentId ||
    !params.applicationIdentifier
  ) {
    return <PageLoad message={t('MISSING_DOCUMENT_IDS_IN_URL')} error />;
  }

  return <MultiDocViewer {...(params as DocViewerParams)} />;
};

export default MultiDocViewerWrapper;
