import React from 'react';
import { Components, Colors } from '@ipos/shared';
import useTranslation from 'translations';
import { useVisualizationMostUsedTsdElements } from 'queries';

const { Visualizations } = Components;
const { RadialBarChart } = Visualizations;

const MostUsedTsdElementsRadialBarChart: React.FC = () => {
  const t = useTranslation();
  const { data } = useVisualizationMostUsedTsdElements();

  if (!data) {
    return null;
  }

  const config = {
    data,
    series: [
      {
        name: 'Number of tsd elements',
        color: Colors.visualization.gold300,
      },
    ],
    description: t('STATS.TOP5_MOST_USED_TSD_ELEMENTS'),
    tooltip: {
      formatter: (v: Record<string, string | number>) => ({
        name: t('STATS.OCCURRENCES_TOOLTIP'),
        value: v.y,
      }),
    },
    xAxis: {
      title: t('STATS.MOST_USED_TSD_ELEMENTS'),
    },
  };

  return <RadialBarChart config={config} />;
};

export default MostUsedTsdElementsRadialBarChart;
