import React, { useCallback, useEffect, useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { getIntersects } from './util';

interface HookShape {
  (props: {
    scale: number;
    pdf?: PDFDocumentProxy;
    currentPage: number;
    wrapperRef: HTMLDivElement | null;
  }): {
    currentPageInView: number;
    visibilities: boolean[];
    pageRefs: HTMLDivElement[];
    setPageRef: (node: HTMLDivElement | null, index: number) => void;
  };
}

const useWindowObservers: HookShape = ({
  pdf,
  scale,
  currentPage,
  wrapperRef,
}) => {
  const totalNumberOfPages = pdf?.numPages || 0;
  const [visibilities, setVisibilities] = React.useState<boolean[]>([]);
  useEffect(() => {
    setVisibilities(Array.from(new Array(totalNumberOfPages), () => false));
  }, [totalNumberOfPages]);

  const [pageRefs, setPageNode] = useState<HTMLDivElement[]>([]);
  const setPageRef = useCallback(
    (node: HTMLDivElement | null, index: number) => {
      if (node !== null) {
        setPageNode((prev) => {
          const newArray = [...prev];
          newArray[index] = node;
          return newArray;
        });
      }
    },
    []
  );

  const [currentPageInView, setCurrentPageInView] =
    useState<number>(currentPage);

  const [currentPageInViewObserver, setCurrentPageInViewObserver] =
    useState<IntersectionObserver | null>(null);
  useEffect(() => {
    const observerOptions = {
      root: wrapperRef,
      rootMargin: '-50% 0px',
      threshold: 0,
    };
    const io = new IntersectionObserver((entries) => {
      const intersects = getIntersects(entries);
      const currentVisiblePage = Object.entries(intersects).find(
        ([, isVisible]) => isVisible
      );
      setCurrentPageInView(parseInt(currentVisiblePage?.[0] || '-2', 10) + 1);
    }, observerOptions);
    setCurrentPageInViewObserver(io);

    return () => io.disconnect();
  }, [wrapperRef]);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  useEffect(() => {
    const containerWidth = wrapperRef?.getBoundingClientRect().width;
    const containerHeight = wrapperRef?.getBoundingClientRect().height;
    let rightLeftRootMargin = 0;
    let topBottomRootMargin = 0;
    if (containerWidth) {
      rightLeftRootMargin = containerWidth * scale + 200;
    }
    if (containerHeight) {
      topBottomRootMargin = (containerHeight * scale) / 2 + 200;
    }
    const observerOptions = {
      root: wrapperRef,
      rootMargin: `${topBottomRootMargin}px ${rightLeftRootMargin}px`,
      threshold: 0.0,
    };

    const io = new IntersectionObserver((entries) => {
      const intersects = getIntersects(entries);
      setVisibilities((prev) =>
        prev.map((visible, index) => {
          if (intersects.hasOwnProperty(index)) {
            return intersects[index];
          }
          return visible;
        })
      );
    }, observerOptions);
    setObserver(io);

    return () => io.disconnect();
  }, [scale, wrapperRef]);
  // Start observing pages.
  useEffect(() => {
    if (observer) {
      pageRefs.forEach((page) => page && observer.observe(page));
    }
  }, [observer, pageRefs]);

  useEffect(() => {
    if (currentPageInViewObserver) {
      pageRefs.forEach(
        (page) => page && currentPageInViewObserver.observe(page)
      );
    }
  }, [currentPageInViewObserver, pageRefs]);
  return {
    visibilities,
    pageRefs,
    setPageRef,
    currentPageInView,
  };
};

export default useWindowObservers;
