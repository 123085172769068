import React from 'react';
import { Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useApplication } from '../components/Application';
import { LOGIN_REDIRECT_URL_KEY } from '../constants';

const PublicRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { isSessionError } = useApplication();
  const from = window.sessionStorage.getItem(LOGIN_REDIRECT_URL_KEY) || '/';

  return isAuthenticated && !isSessionError ? (
    <Redirect
      to={{
        pathname: from,
      }}
    />
  ) : (
    <>{children}</>
  );
};

export default PublicRoute;
