import React from 'react';
import styled from 'styled-components';
import CountryFlag from '../CountryFlag';
import { Language } from '../../types';
import useTranslation from '../../translations';

interface Props {
  language: Language;
  active: boolean;
  onClick: (lang: Language) => void;
}

const ContentLanguageItem: React.FC<Props> = ({
  language,
  active,
  onClick,
}) => {
  const t = useTranslation();

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick(language);
  };

  return (
    <Outer
      active={active}
      onClick={handleItemClick}
      data-testid={`content-language-item-${language.toUpperCase()}`}
    >
      <CountryFlag countryCode={language} size={26} />
      <Label>{t(`LANGUAGE.${language.toUpperCase()}`)}</Label>
    </Outer>
  );
};

export default ContentLanguageItem;

const Outer = styled.div<{ active?: boolean }>`
  display: flex;
  padding: 5px 10px;
  margin: 0 -15px;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  ${(props) => props.active && 'background-color: rgba(255, 255,255, 0.1)'};
`;

const Label = styled.div`
  margin-left: 10px;
`;
