import { T } from '../..';
import {
  generateSession,
  generateOwnerGroupSettings,
  generateTranslationService,
} from './mockDataGenerators';

export const mockSession: T.Session = generateSession();

export const mockOwnerGroupSettings: T.OwnerGroupSettings =
  generateOwnerGroupSettings({ ownerGroupId: mockSession.ownerGroupId });

export const mockTranslationService: T.PayPerUseServiceStatus =
  generateTranslationService();

export const mockUserSession: T.UserSession = {
  session: mockSession,
  groupSettings: mockOwnerGroupSettings,
  translationService: mockTranslationService,
};

export const mockUserSessionQuery: T.UserSessionQuery = {
  ...mockUserSession,
  isLoading: false,
  isError: false,
  isBackendUnreachable: false,
};

export const mockRestrictedSessionQuery: T.UserSessionQuery = {
  ...mockUserSessionQuery,
  session: {
    ...mockSession,
    azureGroups: [AzureGroupsConfig.PermissionCompanyTsdEdit],
  },
};
