import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { QueryKey } from './';

const useAnnotationSets = (
  documentId: string,
  options?: UseQueryOptions<Record<string, string>, AxiosError>
): UseQueryResult<Record<string, string>, AxiosError> =>
  useQuery<Record<string, string>, AxiosError>(
    [QueryKey.AnnotationSets, documentId],
    () => Api.document.getAnnotationSets(documentId),
    options
  );

export default useAnnotationSets;
