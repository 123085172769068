import { useMutation } from 'react-query';
import { message } from 'antd';
import { initBrowserDownload } from '../utils';
import useTranslation from '../translations';
import { Api } from '@ipos/shared';

export type DownloadDescriptionOleProps = {
  editorDocumentId: string;
  revisionId: string;
  imageSourceId: string;
  sectionId: string;
  contentNodeId: string;
  handleDownloadProgress?: (progressEvent: ProgressEvent) => void;
};

const useDownloadDescriptionObject = () => {
  const t = useTranslation();
  const {
    mutate: downloadDescriptionOle,
    isLoading: isDownloadingDescriptionOle,
  } = useMutation(
    ({
      editorDocumentId,
      revisionId,
      imageSourceId,
      sectionId,
      contentNodeId,
    }: DownloadDescriptionOleProps) =>
      Api.oa.getDescriptionOle({
        editorDocumentId,
        revisionId,
        imageSourceId,
        sectionId,
        contentNodeId,
      }),
    {
      mutationKey: 'DownloadDescriptionOle',
      onSuccess: (data) => {
        const [blob, fileName] = data;
        initBrowserDownload(fileName, blob);
      },
      onError: () => {
        message.error(t('FAILED_TO_DOWNLOAD'));
      },
    }
  );

  return {
    downloadDescriptionOle,
    isDownloadingDescriptionOle,
  };
};

export default useDownloadDescriptionObject;
