import React from 'react';
import styled from 'styled-components';
import DocumentSearchInput from './DocumentSearchInput';

interface Props {
  isSearching: boolean;
  matchCount: number;
  activeMatchIndex: number | null;
  searchText: string;
  onSearchChange: React.Dispatch<React.SetStateAction<string>>;
  onActiveMatchChange: (index: number) => void;
}

const DocumentSearchControls: React.FC<Props> = ({
  isSearching,
  matchCount,
  activeMatchIndex,
  searchText,
  onSearchChange,
  onActiveMatchChange,
}) => {
  const index = activeMatchIndex ?? 0;
  const displayIndex = matchCount ? index + 1 : 0;

  return (
    <Outer>
      <DocumentSearchInput
        isLoading={isSearching}
        value={searchText}
        onChange={onSearchChange}
      />
      <Inner>
        <Matches>{`${displayIndex}/${matchCount}`}</Matches>
        <Controls>
          <Divider />
          <IconButton
            className="icn-chevron-up"
            onClick={() => onActiveMatchChange(index - 1)}
            disabled={index <= 0}
          />
          <IconButton
            className="icn-chevron-down"
            onClick={() => onActiveMatchChange(index + 1)}
            disabled={index + 1 >= matchCount}
          />
        </Controls>
      </Inner>
    </Outer>
  );
};

export default DocumentSearchControls;

const Outer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const Inner = styled.div`
  flex: 0 0 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1921px) {
    font-size: 17px;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const Matches = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black70};
  @media (min-width: 1921px) {
    font-size: 17px;
  }
`;

const Divider = styled.div`
  height: 18px;
  width: 2px;
  background-color: ${(props) => props.theme.colors.black30};
  margin: 0 6px 0 12px;
`;

export const IconButton = styled.i<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  margin: 0;
  border-radius: 3px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.black70};
  transition: background 0.3s ease;
  &:hover {
    background: ${(props) => props.theme.colors.primary200_40};
  }
  @media (min-width: 1921px) {
    width: 32px;
    height: 32px;
    font-size: 24px;
  }
  ${(props) => props.disabled && `pointer-events: none; opacity: 0.5;`}
`;
