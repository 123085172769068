import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { OwnerGroupSettings } from '../types';
import { Api } from '../..';
import { QueryKey } from './';

const useGroupSettings = (
  options?: UseQueryOptions<OwnerGroupSettings, AxiosError>
): UseQueryResult<OwnerGroupSettings, AxiosError> =>
  useQuery<OwnerGroupSettings, AxiosError>(
    QueryKey.GroupSettings,
    Api.ownerGroups.getSettings,
    options
  );

export default useGroupSettings;
