import React from 'react';

import { Tooltip } from 'antd';
import styled from 'styled-components';
import { Colors } from '../../../../..';
import useTranslation from '../../../../translations';

interface Props {
  onCopyToClipboard: (e: React.MouseEvent) => Promise<void>;
  onEditRequest: () => void;
  onDeleteRequest: () => void;
  active: boolean;
  isOwnedByUser: boolean;
}

const TOOLTIP_STYLE = {
  maxWidth: '130px',
  color: Colors.white87,
  padding: '8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 5,
  boxShadow: 'none',
  marginBottom: '-7px',
};

const CommentActionButtons: React.FC<Props> = ({
  active,
  onDeleteRequest,
  onEditRequest,
  onCopyToClipboard,
  isOwnedByUser,
}) => {
  const t = useTranslation();
  return (
    <Outer active={active}>
      <Tooltip
        destroyTooltipOnHide
        title={t('ACTION.COPY')}
        placement="top"
        overlayInnerStyle={TOOLTIP_STYLE}
        color={Colors.primary300}
      >
        <StyledButton title={t('ACTION.COPY')} onClick={onCopyToClipboard}>
          <i className="icn-copy" />
        </StyledButton>
      </Tooltip>
      {isOwnedByUser && (
        <>
          <Tooltip
            title={t('ACTION.EDIT')}
            placement="top"
            overlayInnerStyle={TOOLTIP_STYLE}
            color={Colors.primary300}
          >
            <StyledButton title={t('ACTION.EDIT')} onClick={onEditRequest}>
              <i className="icn-edit" />
            </StyledButton>
          </Tooltip>
          <Tooltip
            title={t('ACTION.DELETE')}
            placement="top"
            overlayInnerStyle={TOOLTIP_STYLE}
            color={Colors.primary300}
          >
            <StyledButton title={t('ACTION.DELETE')} onClick={onDeleteRequest}>
              <i className="icn-delete" />
            </StyledButton>
          </Tooltip>
        </>
      )}
    </Outer>
  );
};

export default CommentActionButtons;

const Outer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  opacity: 0;
  ${(props) => props.active && 'opacity: 1'}
`;

const StyledButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.black60};
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
    color: ${(props) => props.theme.colors.black87};
  }
`;
