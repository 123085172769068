import React from 'react';
import styled from 'styled-components';
import { pdfjs } from 'react-pdf';
import '../../translations';
import { ProviderWrapper } from '../../provider';
import '../../styles/Shared.less';
import { ApplicationIdentifier } from '../../types';
import Wrapper from './Wrapper';
import WithAppPermission from './WithAppPermission';
import '../../services/sw';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

interface Props {
  appContext: ApplicationIdentifier;
}

const Application: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  appContext,
}) => (
  <ProviderWrapper appContext={appContext}>
    <Wrapper>
      <WithAppPermission appContext={appContext}>
        <Outer>
          <Inner>{children}</Inner>
        </Outer>
      </WithAppPermission>
    </Wrapper>
  </ProviderWrapper>
);

export default Application;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 3840px;
  max-height: 2160px;
  min-height: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  box-sizing: content-box;
  padding: 0;
`;

const Inner = styled.div`
  display: flex;
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 0;
`;
