/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useVeryRichContext } from '../../contexts';

import 'prosemirror-view/style/prosemirror.css';

import { EditorContentType } from '../../types';
import { useClickOutsideListener } from '../../hooks';
import useTranslation from '../../translations';
import { StyledEditorContainer } from './styles/styles';

const Editor: React.FC = () => {
  const [focused, setFocused] = React.useState(false);
  const {
    contentType,
    cleanEditorElementRef,
    onUnFocusClean,
    hasLoadingFailed,
  } = useVeryRichContext();
  const t = useTranslation();
  useClickOutsideListener(cleanEditorElementRef.current, () => {
    if (!focused) {
      return;
    }
    setFocused(false);

    onUnFocusClean();
  });
  if (hasLoadingFailed) {
    return <div>{t('RICH_TEXT.ERROR_LOADING_DOCUMENT')}</div>;
  }

  return (
    <StyledEditorContainer
      type="clean"
      contentType={contentType || EditorContentType.Description}
      onClick={() => setFocused(true)}
    >
      <div ref={cleanEditorElementRef} />
    </StyledEditorContainer>
  );
};

export default Editor;
