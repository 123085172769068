import React from 'react';
import styled from 'styled-components';
import { UseQueryResult } from 'react-query';
import useTranslation from '../../../../../translations';
import { HeaderIcon } from '../../../common';
import { DocumentPickerItem, DocumentSearchResult } from '../../../types';
import { scrollbar } from '../../../../../utils';
import SearchStatus from './SearchStatus';
import SearchResultsList from './SearchResultsList';

interface Props {
  docItems: DocumentPickerItem[];
  searchQuery: UseQueryResult<DocumentSearchResult[]>;
  onOpenSearchInView: (documentId: string, index: number) => void;
  onClose: () => void;
}

const SearchResults: React.FC<Props> = ({
  docItems,
  searchQuery,
  onOpenSearchInView,
  onClose,
}) => {
  const t = useTranslation();
  const { isLoading, isError, isSuccess, data } = searchQuery;
  const noResultsFound = !!data?.every((r) => r.matches.length === 0);

  let content;
  switch (true) {
    case isLoading:
      content = <SearchStatus status="loading" />;
      break;
    case isError:
      content = <SearchStatus status="error" />;
      break;
    case noResultsFound:
      content = <SearchStatus status="no-results" />;
      break;
    case data != null:
      content = (
        <SearchResultsList
          docItems={docItems}
          results={data as DocumentSearchResult[]}
          onOpenSearchInView={onOpenSearchInView}
        />
      );
  }

  return (
    <Outer>
      <Header>
        <Title>{t('MULTI_DOC_SEARCH.SEARCH_RESULTS')}</Title>
        <HeaderIcon
          className="icn-clear"
          onClick={onClose}
          style={{ marginLeft: 'auto' }}
        />
      </Header>
      <Inner $hasResults={isSuccess && !noResultsFound}>{content}</Inner>
    </Outer>
  );
};

export default SearchResults;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: 500px;
  width: 755px;
  padding: 10px 20px 20px 20px;
  border-radius: 12px;
  gap: 10px;
  background: ${(props) => props.theme.colors.primary550};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white87};
`;

const Inner = styled.div<{ $hasResults: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(props) =>
    !props.$hasResults &&
    `align-items: center; justify-content: center; margin-bottom: 10%;`}
  ${scrollbar({
    trackColor: 'transparent',
    thumbColor: 'rgba(255, 255, 255,0.1)',
    thumbHoverColor: '#676e7f',
  })}
`;
