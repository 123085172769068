import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import TsdPublishIndicator from '../../TsdPublishIndicator';
import Synonyms from './Synonyms';
import { T, Utils } from '@ipos/shared';

const { truncate } = Utils;

export const NameRenderer: React.FC<{ item: T.Tsd }> = ({ item }) => {
  const { name, names } = item;
  return (
    <Outer>
      <Value title={name}>{name}</Value>
      <Synonyms values={names} skip={[name]} />
    </Outer>
  );
};

export const DateRenderer: React.FC<{ date: string }> = ({ date }) => (
  <Value>{dayjs(date).format('D MMM YYYY')}</Value>
);

export const StatusRenderer: React.FC<{ item: T.Tsd }> = ({ item }) => {
  const { nodeCount, isPublished, lastPublishedOn } = item;
  return (
    <Status>
      <span>{nodeCount}</span>
      {!isPublished && (
        <TsdPublishIndicator
          isPublished={false}
          lastPublishedOn={lastPublishedOn}
        />
      )}
    </Status>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  min-width: 0;
`;

const Value = styled.div`
  ${truncate()}
`;

const Status = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  gap: 10px;
`;
