import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../Icon';
import { Colors } from '../../../theme';
import { ResultStatus } from './index';

interface Props {
  title: string;
  subTitle: string;
  status: ResultStatus;
  subText?: string;
  extra?: React.ReactNode;
}

const Result: React.FC<Props> = ({
  title,
  subTitle,
  status,
  subText,
  extra,
}) => {
  let icon: React.ReactNode;
  switch (status) {
    case 'success':
      icon = <StyledIcon className="icn-done-solid" color={Colors.success} />;
      break;
    case 'warning':
    case 'error':
      icon = <StyledIcon className="icn-error-solid" color={Colors.error} />;
      break;
    case 'timeout':
      icon = <StyledIcon className="icn-time-out" color={Colors.error} />;
      break;
  }

  return (
    <Outer>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
      {subText && <Subtext>{subText}</Subtext>}
      {extra && <ExtraContent>{extra}</ExtraContent>}
    </Outer>
  );
};

export default Result;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 440px;
  width: 100%;
  margin-top: 45px;
`;

const StyledIcon = styled(Icon)`
  font-size: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  aling-items: center;
  height: 50px;
  width: 50px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 1.42;
  text-align: center;
  color: ${(props) => props.theme.colors.white87};
`;

export const Subtitle = styled.div`
  font-size: 15px;
  line-height: 1.67;
  white-space: pre-line;
  margin-bottom: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.white70};
`;

const Subtext = styled.div`
  font-size: 13px;
  line-height: 1.67;
  text-align: center;
  color: ${(props) => props.theme.colors.white70};
`;

const ExtraContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;
