/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { EditorState } from 'prosemirror-state';
import * as Sentry from '@sentry/react';
import { Node } from 'prosemirror-model';
import { useDiffCalculator } from '../../hooks';
import { DocumentSyncState, Operation } from '../../types';
import { usePatchEditorContent } from '../../mutations';
import { trackChangesPluginKey } from './trackChangesPlugin/trackChangesPlugin';
interface HookShape {
  (props: {
    docReference: React.MutableRefObject<Node | null>;
    readonlyState: boolean;
    editorDocumentId?: string;
  }): {
    queueDocumentForSaving: (newState: EditorState) => void;
    documentState: DocumentSyncState;
    setDocumentState: React.Dispatch<React.SetStateAction<DocumentSyncState>>;
    popQueue: () => void;
  };
}

type PatchQueueItem = {
  startState: EditorState;
  endState: EditorState;
  changes?: any;
};

const useSaveEditorDocument: HookShape = ({
  docReference,
  editorDocumentId,
  readonlyState,
}) => {
  const saveQueue = useRef<PatchQueueItem[]>([]);
  const documentStateRef = useRef<DocumentSyncState>('synced');
  const [documentState, setDocumentState] =
    useState<DocumentSyncState>('synced');
  const { calculatePatches } = useDiffCalculator(!readonlyState);
  const { mutateAsync: patch } = usePatchEditorContent();

  const _calculatePatches = async (
    patchQueueItem: PatchQueueItem
  ): Promise<Operation[] | null> => {
    try {
      const patches = await calculatePatches(
        patchQueueItem.startState,
        patchQueueItem.endState
      );
      if (patches) {
        return patches;
      }
    } catch (e) {
      console.log(e);
      console.log(patchQueueItem.startState, patchQueueItem.endState);
    }
    return null;
  };

  const saveChanges = async (patchQueueItem: PatchQueueItem) => {
    documentStateRef.current = 'syncing';
    const patches = await _calculatePatches(patchQueueItem);
    if (!patches) {
      return;
    }
    setDocumentState('syncing');
    try {
      if (patches.length !== 0) {
        await patch({
          // @ts-ignore
          editorDocumentId,
          patches,
          changes: patchQueueItem.changes,
        });
      }

      setDocumentState('synced');
      documentStateRef.current = 'synced';
    } catch (error) {
      const tags: Record<string, any> = {
        type: 'editor-save-failure',
      };
      console.log(patchQueueItem.startState, patches);
      const extra: Record<string, any> = {
        error,
        docId: editorDocumentId,
        allPatches: patches,
      };
      Sentry.captureException(new Error('Saving editor changes failed'), {
        tags,
        extra,
      });
      setDocumentState('error');
      documentStateRef.current = 'error';
    }
  };

  const popQueue = () => {
    if (
      saveQueue.current.length === 0 ||
      documentStateRef.current === 'syncing'
    ) {
      return;
    }
    const patchQueueItem = saveQueue.current.shift();
    if (patchQueueItem) {
      saveChanges(patchQueueItem);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(popQueue, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [editorDocumentId]);

  const queueDocumentForSaving = (newState: EditorState) => {
    const oldDoc = docReference.current;
    if (!oldDoc) {
      return null;
    }
    const currentChanges = trackChangesPluginKey
      ?.getState(newState)
      ?.changeSet.toJSON();
    const newJson = newState.doc.toJSON();
    const oldJson = oldDoc.toJSON();
    saveQueue.current.push({
      startState: oldJson,
      endState: newJson,
      changes: currentChanges?.changes,
    });

    docReference.current = null;
  };

  return {
    queueDocumentForSaving,
    documentState,
    setDocumentState,
    popQueue,
  };
};

export default useSaveEditorDocument;
