import React, { createContext, useState } from 'react';
import { ListConfigurationContext, StoredColumn } from './types';

export const RemoteConfigurationContext =
  createContext<ListConfigurationContext | null>(null);

interface Props {
  children: React.ReactNode;
  loadFunction: () => Promise<StoredColumn[]>;
  saveFunction: (storedColumns: StoredColumn[]) => Promise<void>;
}

const RemoteConfigurationProvider: React.FC<Props> = ({
  children,
  loadFunction,
  saveFunction,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const load: ListConfigurationContext['load'] = async () => {
    setLoading(true);
    const storedColumns = await loadFunction();
    setLoading(false);
    return storedColumns;
  };

  const save: ListConfigurationContext['save'] = (storedColumns) => {
    saveFunction(storedColumns);
  };

  const markAsInitialized = () => {
    setInitialized(true);
  };

  return (
    <RemoteConfigurationContext.Provider
      value={{
        loading,
        initialized,
        load,
        save,
        markAsInitialized,
      }}
    >
      {children}
    </RemoteConfigurationContext.Provider>
  );
};

export default RemoteConfigurationProvider;
