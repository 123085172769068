import { LocationDescriptor } from 'history';
import { PatentApplicationStakeholder } from './patents';
import { Language } from './language';
import { TranslationStateErrorCode } from './documents';

export interface RequestSignup {
  email: string;
  context: string;
  fullName: string;
}

export interface DocViewerParams {
  rootId?: string;
  applicationIdentifier: ApplicationIdentifier;
  firstDocumentId: string;
  secondDocumentId: string;
  thirdDocumentId?: string;
  startingPages?: string;
  defaultVisibleDocCount?: string;
  title?: string;
  subtitle?: string;
  windowToUse?: Window;
  hideAnnotations?: boolean;
  hideHeader?: boolean;
}

export enum ApplicationIdentifier {
  TSD_EDITOR = 'TsdEditor',
  EVALUATOR = 'Evaluator',
  OFFICE_ACTION_HERO = 'OfficeActionHero',
  DDUDE = 'Ddude',
  COMPETITOR = 'Competitor',
  PATENTOR = 'Patentor',
  LIFTOFF = 'Liftoff',
  DASHBOARD = 'Dashboard',
}

export interface UserProcessStage extends BaseDto {
  name: string;
  executionOrder: number;
  nextStages: number[];
  state: ProcessStageState;
  progressPercentage: number;
  progressDescription: string;
  log?: StageLogEntry[];
  isLastStage?: boolean;
  uploadId?: string | null;
  uploadUri?: string;
  fileName?: string | null;
  fileSize?: number | null;
  errorCode?: ErrorCode;
  errorStackTrace?: string;
  warnings: {
    [key: string]: string;
  };
  reviewObjectUri?: string;
  reviewResultsSubmitUri?: string;
}

export interface TranslationProcessStage {
  progressPercentage: number;
  errorCode?: TranslationStateErrorCode;
}

export interface TranslationProcess {
  targetLanguage: Language;
  targetDocumentId?: string;
  targetDocumentUri: string;
  stages: TranslationProcessStage[];
}

export enum ProcessStageState {
  Pending = 'Pending',
  InProgress = 'InProgress',
  WaitingForUserInput = 'WaitingForUserInput',
  CompletedSuccessfully = 'CompletedSuccessfully',
  Failed = 'Failed',
  Skipped = 'Skipped',
}

export enum StageLogSeverity {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
}

export interface StageLogEntry {
  timestamp: string;
  severity: StageLogSeverity;
  message: string;
}

export interface UserProcessReviewStage extends UserProcessStage {
  reviewObjectUri: string;
  reviewResultsSubmitUri: string;
}

export interface BaseDto {
  id: string;
  createdById?: string;
  createdOn: string;
  modifiedOn?: string;
  modifiedById?: string;
}

export interface ApplicationLanguage {
  languageCode: string;
  LCID: string;
  name: string;
  default: boolean;
}

export enum SortOrder {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc',
}

export interface InfiniteQueryData<T, C = number> {
  data: Array<T>;
  nextCursor?: C;
}

export interface PaginatedApiFunction<T, Q> {
  (queryParams: Q): Promise<Array<T>>;
}

export interface PagingParams {
  pageSize?: number;
  skip?: number;
  sortBy?: string | { [key: string]: SortOrder };
  sortOrder?: SortOrder;
}

export enum PriorArtSource {
  GooglePatents = 'GooglePatents',
  PatBase = 'PatBase',
}

export enum PatbaseSortOrder {
  PriorityDateAscending = 'PriorityDateAscending',
  PriorityDateDescending = 'PriorityDateDescending',
  Assignee = 'Assignee',
  Inventor = 'Inventor',
  PublicationDateAscending = 'PublicationDateAscending',
  PublicationDateDescending = 'PublicationDateDescending',
  PatentNumber = 'PatentNumber',
  MostCited = 'MostCited',
  Relevance = 'Relevance',
  FamilyNumberAscending = 'FamilyNumberAscending',
  FamilyNumberDescending = 'FamilyNumberDescending',
}

export interface Contact extends BaseDto {
  isDeleted?: boolean;
  userId?: string;
  displayName?: string;
  email?: string;
  phone?: string;
  company?: string;
  department?: string;
  representation?: string;
  address?: string;
  birthday?: string;
  city?: string | null;
  languages?: Language[];
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string;
  mobile?: string | null;
  nationality?: string | null;
  postcode?: string | null;
  socialMedia?: string[];
  title?: string | null;
  country?: string | null;
}

export interface BlobResource extends BaseDto {
  fileName: string;
  uri: string;
  contentSize: number;
  contentType: string;
}

export interface BaseProcess extends BaseDto {
  stages: UserProcessStage[];
  currentStage: number;
}

export interface MultiSortingField {
  label: string;
  key: string;
  type: MultiSortingFieldType;
  value: SortOrder | null;
}

export enum MultiSortingFieldType {
  String = 'string',
  Number = 'number',
  Date = 'date',
}

export enum ErrorCode {
  AlreadyExists = 'AlreadyExists',
  AuthExpiredRequest = 'AuthExpiredRequest',
  AuthFailedSignup = 'AuthFailedSignup',
  AuthInvalidEmailForSignup = 'AuthInvalidEmailForSignup',
  AuthInvalidSelfSignupToken = 'AuthInvalidSelfSignupToken',
  AuthSignedUpAlready = 'AuthSignedUpAlready',
  AuthSignupMaxRetriesReached = 'AuthSignupMaxRetriesReached',
  DDudeExpectedCompanyPsn = 'DDudeExpectedCompanyPsn',
  ExpectedApplicationNumber = 'ExpectedApplicationNumber',
  ExpectedCountryCode = 'ExpectedCountryCode',
  ExpectedInputParameter = 'ExpectedInputParameter',
  ExpectedName = 'ExpectedName',
  ExpectedPatentRevision = 'ExpectedPatentRevision',
  ExpectedPrimarySynonym = 'ExpectedPrimarySynonym',
  ExpectedSynonym = 'ExpectedSynonym',
  FileTooLarge = 'FileTooLarge',
  Forbidden = 'Forbidden',
  FtoImportPatBaseExportIssue = 'FtoImportPatBaseExportIssue',
  FtoImportPatBaseFailedToDeserialize = 'FtoImportPatBaseFailedToDeserialize',
  FtoImportPatBaseFailedToParse = 'FtoImportPatBaseFailedToParse',
  FtoImportPatBaseInvalidFormat = 'FtoImportPatBaseInvalidFormat',
  FtoImportUnsupportedImportType = 'FtoImportUnsupportedImportType',
  InternalCheckFailed = 'InternalCheckFailed',
  InvalidClaimCombination = 'InvalidClaimCombination',
  InvalidFileContent = 'InvalidFileContent',
  InvalidPatentState = 'InvalidPatentState',
  MalformedRequest = 'MalformedRequest',
  NoApplicationNumberFound = 'NoApplicationNumberFound',
  NotAllowedPrimarySynonym = 'NotAllowedPrimarySynonym',
  NotDeletable = 'NotDeletable',
  NotDeletableInUse = 'NotDeletableInUse',
  NotEditable = 'NotEditable',
  NotFound = 'NotFound',
  NotMovable = 'NotMovable',
  NotYetSupported = 'NotYetSupported',
  OperationCanceled = 'OperationCanceled',
  PatentPublicationFamilyNotFound = 'PatentPublicationFamilyNotFound',
  PatentPublicationNotFound = 'PatentPublicationNotFound',
  ServiceMonthlyLimitExceeded = 'ServiceMonthlyLimitExceeded',
  ServiceNotEnabled = 'ServiceNotEnabled',
  ServiceSingleRequestLimitExceeded = 'ServiceSingleRequestLimitExceeded',
  ThirdPartyServiceDown = 'ThirdPartyServiceDown',
  TsdImportNotFoundDestination = 'TsdImportNotFoundDestination',
  TsdImportNotFoundSource = 'TsdImportNotFoundSource',
  Unauthorized = 'Unauthorized',
  UnauthorizedPatBase = 'UnauthorizedPatBase',
  UncaughtServerException = 'UncaughtServerException',
  Unknown = 'Unknown',
  UnknownUser = 'UnknownUser',
  UnsavedData = 'UnsavedData',
  UnsupportedDocumentType = 'UnsupportedDocumentType',
  UnsupportedFileFormat = 'UnsupportedFileFormat',
  UnsupportedFilteringMode = 'UnsupportedFilteringMode',
  UnsupportedLanguage = 'UnsupportedLanguage',
  UnsupportedPatentOffice = 'UnsupportedPatentOffice',
  WaitForOngoingProcessToComplete = 'WaitForOngoingProcessToComplete',
  WrongLifecycleState = 'WrongLifecycleState',
}

export interface AutocompleteItem<T> {
  value: T;
  label: string;
}

export enum SCREEN {
  VIEW_STAKEHOLDERS = 'VIEW_STAKEHOLDERS',
  EDIT_STAKEHOLDER = 'EDIT_STAKEHOLDER',
  ADD_STAKEHOLDER = 'ADD_STAKEHOLDER',
  ADD_INVENTOR = 'ADD_INVENTOR',
  ADD_APPLICANT = 'ADD_APPLICANT',
  ADD_EXAMINER = 'ADD_EXAMINER',
  ADD_INVENTOR_CONTACT = 'ADD_INVENTOR_CONTACT',
  ADD_APPLICANT_CONTACT = 'ADD_APPLICANT_CONTACT',
  REMOVE_STAKEHOLDER = 'REMOVE_STAKEHOLDER',
  DELETE_CONTACT = 'DELETE_CONTACT',
  ADD_IN_HOUSE_COUNSEL = 'ADD_IN_HOUSE_COUNSEL',
  ADD_EXTERNAL_COUNSEL = 'ADD_EXTERNAL_COUNSEL',
  EDIT_IN_HOUSE_COUNSEL = 'EDIT_IN_HOUSE_COUNSEL',
  EDIT_EXTERNAL_COUNSEL = 'EDIT_EXTERNAL_COUNSEL',
  EDIT_INVENTOR_CONTACT = 'EDIT_INVENTOR_CONTACT',
  EDIT_APPLICANT = 'EDIT_APPLICANT',
}

export interface ModalState {
  currentScreen: SCREEN;
  breadcrumbs: Set<SCREEN>;
  panelOpen: boolean;
  activeStakeholder: PatentApplicationStakeholder | null;
}

export enum NoteContext {
  OfficeAction = 'OfficeAction',
  PatentApplication = 'PatentApplication',
}

export interface UserNote extends BaseDto {
  noteText: string;
  isPublic: boolean;
  context: NoteContext;
}

export interface NotesQueryParams extends PagingParams {
  principalEntityId: string;
  isPublic: boolean;
  context: NoteContext;
}

export interface UpdateUserNote {
  noteId: string;
  context: NoteContext;
  isPublic: boolean;
  text: string;
}

export interface CreateUserNote {
  principalEntityId: string;
  context: NoteContext;
  isPublic: boolean;
  text: string;
}

export interface HeaderBackProps {
  to?: LocationDescriptor;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
}

export interface HeaderNavItemProps {
  title: string;
  to?: LocationDescriptor;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export enum DownloadTranslationType {
  Claims = 'Claims',
  Description = 'Description',
}

export type BlobFileDownload = [blob: Blob, fileName: string];

export type SpinSize = 'small' | 'default' | 'large';

// TODO: Handle this better. Messages should be translated on FE
export interface GenericErrorResponse {
  Code: ErrorCode;
  Exception: null;
  Message: string;
  StackTrace: null;
}

export type SurfaceTypes = 'dark' | 'light' | 'transparent';

export enum NavigationBarItemType {
  Default,
  InProgress,
  Done,
}
