import { NodeSpec } from 'prosemirror-model';

const claim: NodeSpec = {
  content: 'block+',
  group: 'block',
  defining: true,
  isolating: true,
  attrs: {
    nodeId: {
      default: null,
    },
    kind: {
      default: null,
    },
    order: {
      default: null,
    },
    checked: {
      default: false,
    },
    collapsed: {
      default: false,
    },
    claimTraceId: {
      default: null,
    },
    calculatedClaimNumber: {
      default: null,
    },
    claimNumber: {
      default: null,
    },
    baseClaimNumber: {
      default: null,
    },
    isDeleted: {
      default: null,
    },
    isInserted: {
      default: null,
    },
    isMachineTranslated: {
      default: false,
    },
    comment: {
      default: null,
    },
    statusIdentifier: {
      default: null,
    },
  },
  toDOM(node) {
    const attrs = {
      class: 'claim',
      'data-claim-number': node.attrs.claimNumber,
      'data-claim-trace-id': node.attrs.claimTraceId,
      'data-base-claim-number': node.attrs.baseClaimNumber,
      'data-calculated-claim-number': node.attrs.calculatedClaimNumber,
      'data-is-deleted': node.attrs.isDeleted,
      'data-is-inserted': node.attrs.isInserted,
    };

    const claimNumber =
      node.attrs.calculatedClaimNumber || node.attrs.claimNumber;

    const numberEl = document.createElement('div');
    numberEl.contentEditable = 'false';
    numberEl.classList.add('number');

    const claimNumberEl = document.createElement('span');
    claimNumberEl.classList.add('claim-number');
    claimNumberEl.textContent = claimNumber;

    const oldClaimNumberEl = document.createElement('span');
    oldClaimNumberEl.contentEditable = 'false';
    oldClaimNumberEl.classList.add('old-claim-number');

    const note = document.createElement('div');
    note.classList.add('note');

    if (
      node.attrs.baseClaimNumber &&
      node.attrs.baseClaimNumber !== claimNumber
    ) {
      oldClaimNumberEl.textContent = node.attrs.baseClaimNumber || '';
      claimNumberEl.classList.add('inserted');
    }

    numberEl.append(oldClaimNumberEl, claimNumberEl);

    if (node.attrs.isDeleted) {
      attrs.class += ' deleted';
    }

    if (node.attrs.isInserted) {
      attrs.class += ' inserted';
    }

    if (node.attrs.isMachineTranslated) {
      note.textContent = node.attrs.comment;
    }

    const content = ['div', { class: 'content' }, 0];

    return ['div', attrs, numberEl, content, note];
  },
};
export default claim;
