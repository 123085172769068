import React from 'react';
import styled from 'styled-components';
import { T } from '../../../..';
import { useTranslation } from '../../../translations';
import { PrimaryButton } from '../../Button';

interface Props {
  item: T.StakeholderListItem;
  setIsAddPopoverOpen: (value: boolean) => void;
  availableRoles: T.PatentApplicationStakeholderRole[];
  onAddStakeholder: (
    item: T.StakeholderListItem,
    newRole: T.PatentApplicationStakeholderRole
  ) => void;
}

const AddStakeholderPopover: React.FC<Props> = ({
  item,
  onAddStakeholder,
  availableRoles,
  setIsAddPopoverOpen,
}) => {
  const t = useTranslation();
  return (
    <Outer>
      {availableRoles.map((r) => (
        <PrimaryButton
          key={r.toString()}
          leftIcon={<i className="icn-add" />}
          style={{
            marginBottom: '5px',
            padding: '8px 12px',
            borderRadius: '3px',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onAddStakeholder(item, r);
            setIsAddPopoverOpen(false);
          }}
        >
          {t(`STAKEHOLDERS.ROLES.${r.toUpperCase()}`)}
        </PrimaryButton>
      ))}
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  z-index: 100;
  border-radius: 8px;
  background-image: linear-gradient(
    140deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end} 100%
  );
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export default AddStakeholderPopover;
