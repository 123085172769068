import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { Components, T } from '@ipos/shared';
import useTranslation from 'translations';

const {
  Atoms: { H1 },
} = Components;

interface Props {
  data: T.CompanyGroup;
}

const CompanyGroupDetails: React.FC<Props> = ({ data }) => {
  const { name, companiesInGroup = [] } = data;
  const t = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('COMPANIES.NAME'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('COMPANIES.APPLICATIONS'),
        key: 'numberOfApplications',
        dataIndex: 'numberOfApplications',
      },
      {
        title: 'PSN ID',
        key: 'psnId',
        dataIndex: 'psnId',
      },
    ],
    []
  );

  return (
    <Outer>
      <H1>{name}</H1>
      <Table
        pagination={false}
        rowKey="id"
        dataSource={companiesInGroup}
        columns={columns}
      />
    </Outer>
  );
};

export default CompanyGroupDetails;

const Outer = styled.div`
  width: 600px;
`;
