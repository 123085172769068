import React, { useEffect, useState } from 'react';
import { Modal, Switch } from 'antd';
import styled from 'styled-components';
import useTranslation from 'translations';

interface Props {
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

const QueryExpertModeSwitch: React.FC<Props> = ({
  checked = false,
  onChange,
}) => {
  const t = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    if (checked === isChecked) {
      return;
    }

    Modal.confirm({
      content: isChecked
        ? t('QUERY.MODE_EXPERT_REQUESTED')
        : t('QUERY.MODE_AUTO_REQUESTED'),
      title: t('QUERY.MODE_CHANGE'),
      okText: t('QUERY.MODE_CHANGE_PROCEED'),
      cancelText: t('QUERY.MODE_CHANGE_CANCEL'),
      onOk() {
        onChange(isChecked);
      },
      onCancel() {
        setIsChecked(checked);
      },
      centered: true,
    });
  }, [checked, isChecked, setIsChecked, Modal, t]);

  return (
    <Outer>
      {t('QUERY.MODE_EXPERT_SWITCH')}
      <StyledSwitch checked={checked} onChange={(v) => setIsChecked(v)} />
    </Outer>
  );
};

export default QueryExpertModeSwitch;

const Outer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledSwitch = styled(Switch)`
  margin-left: 8px;
`;
