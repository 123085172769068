import React from 'react';
import { Select } from 'antd';
import { useQuery } from 'react-query';
import { Api, T, Utils } from '@ipos/shared';
import { QueryKey } from 'queries';
import useTranslation from 'translations';

const { getCurrentLanguageValue } = Utils;

interface Props {
  value?: string;
  onChange: (value: string) => void;
}

const TextModuleTypeSelector: React.FC<Props> = ({ onChange, value }) => {
  const t = useTranslation();
  const appLanguage = getCurrentLanguageValue();

  const { data: textModuleTypes, isLoading } = useQuery(
    QueryKey.TsdElementTextModuleTypes,
    Api.tsd.getTsdElementTextModuleTypes
  );

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  const options = textModuleTypes?.map(({ id, translations }) => {
    const entries = new Map(Object.entries(translations));
    const label =
      entries.get(appLanguage)?.name ||
      entries.get(T.Language.De)?.name ||
      entries.values().next().value.name;
    return (
      <Select.Option key={id} value={id}>
        {label}
      </Select.Option>
    );
  });

  return (
    <Select
      value={value === '' ? undefined : value}
      placeholder={t('TEXT_MODULES.SELECT')}
      style={{ width: '100%', minWidth: 200 }}
      loading={isLoading}
      onChange={handleChange}
    >
      {options}
    </Select>
  );
};

export default TextModuleTypeSelector;
