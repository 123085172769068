import { useState, useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';

const useInvalidateQueries = (): {
  isInvalidating: boolean;
  invalidateQueries: (...queryKeys: QueryKey[]) => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const [isInvalidating, setIsInvalidating] = useState(false);

  const invalidateQueries = useCallback(async (...queryKeys: QueryKey[]) => {
    setIsInvalidating(true);
    const promises = queryKeys.map((key) => queryClient.invalidateQueries(key));
    await Promise.allSettled(promises);
    setIsInvalidating(false);
  }, []);

  return {
    isInvalidating,
    invalidateQueries,
  };
};

export default useInvalidateQueries;
