import styled from 'styled-components';

export const HeaderIcon = styled.i`
  height: 40px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.white70};
  &:hover {
    background: ${(props) => props.theme.colors.primary200_20};
  }
`;
