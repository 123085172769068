import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Modal, notification, Space, Button } from 'antd';
import { initBrowserDownload } from '../../../utils';
import { EPC_LANGUAGES } from '../../../constants';
import {
  Language,
  DocumentFormat,
  BlobFileDownload,
  DownloadTranslationType,
  GenerateTranslationDocument,
  GenerateTranslationDocumentRequest,
} from '../../../types';
import { Api } from '../../../..';
import useTranslation from '../../../translations';
import LanguageItem from './LanguageItem';
import CountryItem from './CountryItem';
import FormatSelection from './FormatSelection';
import EpcLanguageSelection from './EpcLanguageSelection';

const isEpcLanguage = (language: Language) => EPC_LANGUAGES.includes(language);

const generateSelection = (languages: Language[]) =>
  new Map(languages.map((l) => [l, []]));

const updateDocFormats = (
  formats: DocumentFormat[],
  format: DocumentFormat,
  checked: boolean
) => (checked ? [...formats, format] : formats.filter((f) => f !== format));

interface Props {
  authenticLanguage: Language;
  translationLanguages: Language[];
  availableTranslations?: Language[];
  patentRevisionId?: string;
  downloadType?: DownloadTranslationType;
  onTranslationDownload?: (
    translations: GenerateTranslationDocument[]
  ) => Promise<BlobFileDownload>;
  onClose: () => void;
}

const TranslationDownloadModal: React.FC<Props> = ({
  authenticLanguage,
  translationLanguages,
  availableTranslations,
  patentRevisionId,
  downloadType,
  onTranslationDownload,
  onClose,
}) => {
  const t = useTranslation();

  const [selection, setSelection] = useState<Map<Language, DocumentFormat[]>>(
    generateSelection(translationLanguages)
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  const generateDescriptionTranslationsMutation = useMutation(
    (request: GenerateTranslationDocumentRequest) =>
      Api.pr.getDescriptionTranslationDocuments(request)
  );

  const generateClaimTranslationsMutation = useMutation(
    (request: GenerateTranslationDocumentRequest) =>
      Api.pr.getClaimSetTranslationDocuments(request)
  );

  const prepareRequestData = () => {
    const translations: GenerateTranslationDocument[] = [];

    for (const [language, formats] of selection) {
      formats.forEach((format) =>
        translations.push({
          format,
          translation: language,
        })
      );
    }
    return translations;
  };

  const handleDownloadRequest = async () => {
    const translations = prepareRequestData();
    let getTranslatedDocuments = onTranslationDownload;

    if (downloadType && patentRevisionId) {
      switch (downloadType) {
        case DownloadTranslationType.Claims:
          getTranslatedDocuments = () =>
            generateClaimTranslationsMutation.mutateAsync({
              patentRevisionId,
              translations,
            });
          break;
        case DownloadTranslationType.Description:
          getTranslatedDocuments = () =>
            generateDescriptionTranslationsMutation.mutateAsync({
              patentRevisionId,
              translations,
            });
      }
    }

    if (!getTranslatedDocuments) {
      return;
    }

    try {
      setLoading(true);
      const [blob, filename] = await getTranslatedDocuments(translations);
      initBrowserDownload(filename, blob);
      onClose();
    } catch {
      notification.error({
        message: t('TRANSLATION_DOWNLOAD.ERROR_MESSAGE'),
        description: t('TRANSLATION_DOWNLOAD.ERROR_DESCRIPTION'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageFormatCheck = (
    language: Language,
    format: DocumentFormat,
    checked: boolean
  ) => {
    setSelection((prev) => {
      const formats = prev.get(language);
      if (!formats) {
        return prev;
      }
      const updatedFormats = updateDocFormats(formats, format, checked);
      const updatedSelection = new Map(prev);
      updatedSelection.set(language, updatedFormats);

      return updatedSelection;
    });
  };

  const handleAllLanguagesFormatCheck = (
    format: DocumentFormat,
    checked: boolean
  ) => {
    setSelection((prev) => {
      const updatedSelection = new Map(prev);

      for (const [lang] of prev) {
        const formats = prev.get(lang);
        if (!formats) {
          continue;
        }
        const updatedFormats = updateDocFormats(formats, format, checked);
        updatedSelection.set(lang, updatedFormats);
      }

      return updatedSelection;
    });
  };

  const handleClearSelection = () => {
    setSelection(generateSelection(translationLanguages));
  };

  const handleEpcLanguagesCheck = (checked: boolean) => {
    if (!checked) {
      handleClearSelection();
      return;
    }
    setSelection(
      new Map(
        translationLanguages.map((l) =>
          isEpcLanguage(l)
            ? [l, [DocumentFormat.DOCX, DocumentFormat.PDF]]
            : [l, []]
        )
      )
    );
  };

  const [checkCount, allCheckedFormats, indeterminateFormats] = useMemo(() => {
    const languageCount = translationLanguages.length;
    let count = 0;
    let docxCount = 0;
    let pdfCount = 0;

    for (const [, formats] of selection) {
      formats.length > 0 && count++;
      formats.includes(DocumentFormat.DOCX) && docxCount++;
      formats.includes(DocumentFormat.PDF) && pdfCount++;
    }

    const checked: DocumentFormat[] = [];
    const indeterminate: DocumentFormat[] = [];

    if (languageCount === docxCount) {
      checked.push(DocumentFormat.DOCX);
    } else if (docxCount > 0) {
      indeterminate.push(DocumentFormat.DOCX);
    }

    if (languageCount === pdfCount) {
      checked.push(DocumentFormat.PDF);
    } else if (pdfCount > 0) {
      indeterminate.push(DocumentFormat.PDF);
    }

    return [count, checked, indeterminate];
  }, [translationLanguages, selection]);

  const isEpcChecked = useMemo(() => {
    let isChecked = true;

    for (const [lang, formats] of selection) {
      const isEpc = isEpcLanguage(lang);
      const formatCount = new Set(formats).size;

      if ((isEpc && formatCount < 2) || (!isEpc && formatCount > 0)) {
        isChecked = false;
        break;
      }
    }
    return isChecked;
  }, [selection]);

  return (
    <Modal
      title={t('TRANSLATION_DOWNLOAD.TITLE')}
      open
      centered
      onCancel={onClose}
      destroyOnClose
      width={600}
      footer={null}
      bodyStyle={{
        height: 'calc(100vh - 280px)',
        padding: '0 40px',
      }}
    >
      <Outer>
        <AuthenticLanguage>
          <Title>{t('TRANSLATION_DOWNLOAD.AUTHENTIC_LANG')}</Title>
          <CountryItem language={authenticLanguage} style={{ gap: 12 }} />
        </AuthenticLanguage>
        <EpcLanguageSelection
          checked={isEpcChecked}
          onCheck={handleEpcLanguagesCheck}
        />
        <Header>
          <HeaderTitle>
            {t('TRANSLATION_DOWNLOAD.TRANSLATION_LANG')}
          </HeaderTitle>
          <FormatSelection
            checkedFormats={allCheckedFormats}
            indeterminateFormats={indeterminateFormats}
            showLabels
            onFormatCheck={handleAllLanguagesFormatCheck}
          />
        </Header>
        <LanguageContainer>
          {[...selection].map(([lang, formats]) => (
            <LanguageItem
              key={lang}
              language={lang}
              isAuthenticLanguage={lang === authenticLanguage}
              hasTranslation={availableTranslations?.includes(lang)}
              checkedFormats={formats}
              onFormatCheck={handleLanguageFormatCheck}
            />
          ))}
        </LanguageContainer>
        <ModalFooter>
          {checkCount > 0 && !isLoading && (
            <Status>
              <SelectionStatus>
                {t('TRANSLATION_DOWNLOAD.SELECT_STATUS', {
                  checkCount,
                })}
              </SelectionStatus>
              <ClearButton onClick={handleClearSelection}>
                {t('ACTION.CLEAR')}
              </ClearButton>
            </Status>
          )}
          {isLoading && (
            <SelectionStatus>
              {t('TRANSLATION_DOWNLOAD.LOADING_STATUS')}
            </SelectionStatus>
          )}
          <Space style={{ marginLeft: 'auto' }}>
            <Button onClick={onClose} disabled={isLoading}>
              {t('ACTION.CANCEL')}
            </Button>
            <Button
              type="primary"
              onClick={handleDownloadRequest}
              disabled={checkCount === 0}
              loading={isLoading}
            >
              {t('ACTION.DOWNLOAD')}
            </Button>
          </Space>
        </ModalFooter>
      </Outer>
    </Modal>
  );
};

export default TranslationDownloadModal;

const Outer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AuthenticLanguage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Title = styled.div`
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 8px 0 30px;
  border-radius: 12px 12px 0 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.white10};
  background-color: ${(props) => props.theme.colors.primary200_30};
`;

const HeaderTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white60};
`;

const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: -20px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.primary200_20};
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 34px 0;
`;

const SelectionStatus = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.white60};
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ClearButton = styled.div`
  font-size: 13px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue300};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.success};
  }
`;
