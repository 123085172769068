import styled, { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';

export const Void = styled.textarea<{ $hasError?: boolean }>`
  background: none;
  color: ${(props) => props.theme.colors.white87};
  font-size: 12px;
  padding: 2px 10px 2px 0;
  border: none;
  word-wrap: break-word;
  width: 100%;
  display: inline-table;
  resize: none;
  transition: border 0.2s ease-in-out;
  border-radius: 0;
  ${down('lg')} {
    font-size: 13px;
  }
  ${up('lg')} {
    font-size: 15px;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.$hasError &&
    css`
      border-bottom: 1px solid ${props.theme.colors.error};
      &:focus {
        border-bottom: 1px solid ${props.theme.colors.error};
      }
    `};
`;
