import { NodeSpec } from 'prosemirror-model';

const claimPart: NodeSpec = {
  content: 'block+',
  group: `block`,
  defining: true,
  attrs: {
    nodeId: {
      default: null,
    },
    kind: {
      default: null,
    },
    order: {
      default: null,
    },
    checked: {
      default: false,
    },
    collapsed: {
      default: false,
    },
    partType: {
      default: null,
    },
    numberingType: {
      default: null,
    },
    numberingTemplate: {
      default: null,
    },
    partTraceId: {
      default: null,
    },
    indentLevel: {
      default: null,
    },
    calculatedNumberingPrefix: {
      default: null,
    },
    numberingPrefix: {
      default: null,
    },
    baseNumberingPrefix: {
      default: null,
    },
    isDeleted: {
      default: null,
    },
    isInserted: {
      default: null,
    },
    startNewParagraph: {
      default: null,
    },
  },
  toDOM(node) {
    const attrs: Record<string, unknown> = {
      style: '',
      class: 'claim-part',
      'data-part-type': node.attrs.partType,
      'data-numbering-type': node.attrs.numberingType,
      'data-numbering-template': node.attrs.numberingTemplate,
      'data-part-trace-id': node.attrs.partTraceId,
      'data-internal-id': node.attrs.internalId,
      'data-indent-level': node.attrs.indentLevel,
      'data-calculated-numbering-prefix': node.attrs.calculatedNumberingPrefix,
      'data-numbering-prefix': node.attrs.numberingPrefix,
      'data-base-numbering-prefix': node.attrs.baseNumberingPrefix,
      'data-is-deleted': node.attrs.isDeleted,
      'data-is-inserted': node.attrs.isInserted,
      'data-start-new-paragraph': node.attrs.startNewParagraph,
    };

    const numberEl = document.createElement('div');
    numberEl.contentEditable = 'false';
    numberEl.classList.add('number');

    const oldClaimNumberEl = document.createElement('span');
    oldClaimNumberEl.contentEditable = 'false';
    oldClaimNumberEl.classList.add('old-claim-number');

    const claimNumberEl = document.createElement('span');
    claimNumberEl.contentEditable = 'false';
    claimNumberEl.classList.add('claim-number');
    claimNumberEl.innerHTML =
      node.attrs.calculatedNumberingPrefix || node.attrs.numberingPrefix;

    numberEl.append(oldClaimNumberEl, claimNumberEl);

    if (node.attrs.partType === 'PreambleDelimiter') {
      attrs.class += ' preamble-delimiter';
    }

    if (
      !node.attrs.numberingType ||
      node.attrs.numberingType === 'none' ||
      node.attrs.numberingType === 'custom'
    ) {
      attrs['data-hide-prefix'] = 'true';
      numberEl.style.display = 'none';
    }

    attrs['data-indent-level'] = node.attrs.indentLevel ?? '0';

    if (node.attrs.baseNumberingPrefix) {
      if (
        node.attrs.calculatedNumberingPrefix &&
        node.attrs.baseNumberingPrefix !== node.attrs.calculatedNumberingPrefix
      ) {
        oldClaimNumberEl.innerHTML = node.attrs.baseNumberingPrefix || '';
        claimNumberEl.classList.add('inserted');

        // calculate the width of the old claim number and set negative margin-left
        // to the new claim number
        const oldClaimNumberWidth = oldClaimNumberEl.offsetWidth;
        oldClaimNumberEl.style.marginLeft = `-${oldClaimNumberWidth}px`;
      }
    }

    if (node.attrs.isInserted) {
      attrs.class += ' inserted';
    }

    if (node.attrs.isDeleted) {
      attrs.class + ' deleted';
      if (
        oldClaimNumberEl.innerHTML.trim() === '' &&
        claimNumberEl.innerHTML.trim() === ''
      ) {
        oldClaimNumberEl.innerHTML =
          node.attrs.baseNumberingPrefix ||
          node.attrs.numberingPrefix ||
          node.attrs.calculatedNumberingPrefix ||
          node.attrs.numberingTemplate;
      }
    }

    if (node.attrs.numberingType === 'bullet') {
      claimNumberEl.textContent = '-';
    }

    if (node.attrs.numberingType === 'circle') {
      claimNumberEl.textContent = '◦';
    }

    if (node.attrs.numberingType === 'square') {
      claimNumberEl.textContent = '▪';
    }

    if (node.attrs.numberingType === 'disc') {
      claimNumberEl.textContent = '•';
    }

    if (node.attrs.numberingType === 'diamond') {
      claimNumberEl.textContent = '◆';
    }

    const content = ['div', { class: 'content' }, 0];
    return ['div', attrs, numberEl, content];
  },
};
export default claimPart;
