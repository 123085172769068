import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface GridSize {
  rowCount: number;
  colCount: number;
}

interface Props {
  size?: GridSize;
  gap?: number;
  rowOverrides?: [number, string][];
  colOverrides?: [number, string][];
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

const prepareGridTemplateString = (
  count: number,
  overrides: [number, string][]
): string => {
  const items = Array(count).fill('minmax(0, 1fr)');
  if (overrides) {
    for (const x of overrides) {
      items[x[0] - 1] = x[1];
    }
  }
  return items.join(' ');
};

const Grid = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      size = {
        rowCount: 8,
        colCount: 12,
      },
      gap = 30,
      rowOverrides = [],
      colOverrides = [],
      children,
      style,
      className,
    },
    ref
  ) => {
    const { rowCount, colCount } = size;

    return (
      <Outer
        ref={ref}
        style={style}
        className={className}
        gap={gap}
        gridTemplateRows={prepareGridTemplateString(rowCount, rowOverrides)}
        gridTemplateColumns={prepareGridTemplateString(colCount, colOverrides)}
      >
        {children}
      </Outer>
    );
  }
);

export default Grid;

const Outer = styled.div<{
  gap: number;
  gridTemplateRows: string;
  gridTemplateColumns: string;
}>`
  display: grid;
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  column-gap: ${(props) => props.gap}px;
  row-gap: ${(props) => props.gap}px;
  min-height: 0;
  grid-template-rows: ${(props) => props.gridTemplateRows};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  justify-items: stretch;
`;
