import { T as Types } from '../../..';

export const calculateFixedColumnGap = <T>(
  columns: Types.Column<T>[],
  column: Types.Column<T>
) => {
  if (!column.fixed) {
    return 0;
  }
  const fixedColumns = columns.filter(
    (c) => c.fixed && c.fixed === column.fixed
  );

  const columnIndex = fixedColumns.findIndex((c) => c.key === column.key);

  const reduceCallbackFn = (acc: number, c: Types.Column<T>) =>
    acc +
    Number(String(c.style?.minWidth || c.style?.flexBasis).replace('px', ''));

  if (column.fixed === 'left') {
    return fixedColumns.slice(0, columnIndex).reduce(reduceCallbackFn, 0);
  }

  return fixedColumns.slice(columnIndex + 1).reduce(reduceCallbackFn, 0);
};

export const getFirstFixedColumnToLeft = <T>(columns: Types.Column<T>[]) =>
  columns.filter((c) => c.fixed === 'left').shift();

export const getLastFixedColumnToLeft = <T>(columns: Types.Column<T>[]) =>
  columns.filter((c) => c.fixed === 'left').pop();

export const getFirstFixedColumnToRight = <T>(columns: Types.Column<T>[]) =>
  columns.filter((c) => c.fixed === 'right').shift();

export const getLastFixedColumnToRight = <T>(columns: Types.Column<T>[]) =>
  columns.filter((c) => c.fixed === 'right').pop();
