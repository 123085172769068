import { generatePath, matchPath } from 'react-router-dom';

export type IApplicationRoutes = Record<string, RouteDefinition>;

export interface RouteDefinition {
  id: string;
  path: string;
}

const SHARED_ROUTES: IApplicationRoutes = {
  LOGIN: {
    id: 'LOGIN',
    path: '/login',
  },
  SIGNUP: {
    id: 'SIGNUP',
    path: '/signup',
  },
  SIGNUP_VERIFICATION: {
    id: 'SIGNUP_VERIFICATION',
    path: '/signup-verification/:token',
  },
  DOC_VIEWER: {
    id: 'DOC_VIEWER',
    path: '/documents',
  },
  ROOT: {
    id: 'ROOT',
    path: '/',
  },
  AI_TEMPLATES: {
    id: 'AI_TEMPLATES',
    path: '/ai-templates',
  },
  AI_SUPPORT: {
    id: 'AI_SUPPORT',
    path: '/ai-support/:documentId',
  },
};

export default SHARED_ROUTES;

const PUBLIC_PATHS = [
  SHARED_ROUTES.LOGIN.path,
  SHARED_ROUTES.SIGNUP.path,
  SHARED_ROUTES.SIGNUP_VERIFICATION.path,
];

export const checkIsPublicPath = (path = window.location.pathname) =>
  matchPath(path, PUBLIC_PATHS) != null;

export const getMatchingRoutes = (
  routes: IApplicationRoutes,
  currentLocation: string
): RouteDefinition[] => {
  const retVal = Object.values(routes).reduce((acc: RouteDefinition[], c) => {
    const matchDetails = matchPath(currentLocation, {
      path: c.path,
      strict: false,
    });

    if (matchDetails) {
      return [
        {
          ...c,
          path: generatePath(c.path, matchDetails.params),
        },
        ...acc,
      ];
    }

    return acc;
  }, []);

  retVal.sort(
    (x, y) =>
      (x.path.match(/\//g) ?? []).length - (y.path.match(/\//g) ?? []).length
  );

  return retVal;
};

const urlGenerator =
  (routes: IApplicationRoutes) =>
  (
    route: RouteDefinition,
    params?: Record<string, string | number | boolean | undefined>,
    queryParams?: Record<string, string | string[]>
  ): string => {
    const allRoutes = { ...SHARED_ROUTES, ...routes };
    if (!allRoutes[route.id]) {
      console.warn('No matching route found! Returning # as fallback');
      return '#';
    }
    const path = generatePath(route.path, params);
    if (!queryParams) {
      return path;
    }
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => searchParams.append(key, val));
      } else {
        searchParams.append(key, value);
      }
    });
    return `${path}?${searchParams.toString()}`;
  };

// shared app routes generator
const generateUrl = urlGenerator(SHARED_ROUTES);

export { urlGenerator, generateUrl };
