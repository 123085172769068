import { MarkSpec, NodeSpec, Schema } from 'prosemirror-model';
import link from './marks/link';
import {
  MARK_CODE,
  MARK_COMMENT,
  MARK_DELETED,
  MARK_EM,
  MARK_FONT_SIZE,
  MARK_FONT_TYPE,
  MARK_INSERTED,
  MARK_LINK,
  MARK_STRIKE,
  MARK_STRONG,
  MARK_SUB,
  MARK_SUPER,
  MARK_TEXT_COLOR,
  MARK_TEXT_HIGHLIGHT,
  MARK_TEXT_SELECTION,
  MARK_UNDERLINE,
} from './marks/markNames';
import strikethrough from './marks/strikethrough';
import code from './marks/code';
import textHighlight from './marks/textHighlight';
import fontType from './marks/fontType';
import strong from './marks/strong';
import subscript from './marks/subscript';
import superscript from './marks/superscript';
import fontSize from './marks/fontSize';
import textColor from './marks/textColor';
import em from './marks/em';
import underline from './marks/underline';
import inserted from './marks/inserted';
import deleted from './marks/deleted';
import {
  BLOCKQUOTE,
  CODE_BLOCK,
  EMBEDDED_OBJECT,
  HARD_BREAK,
  HEADING,
  HORIZONTAL_RULE,
  IMAGE,
  LIST,
  NUMBERED_PARAGRAPH,
  PARAGRAPH,
  SECTION,
  CLAIM,
  CLAIM_PART,
  LIST_NUMBERING,
  MATH_INLINE,
  MATH_DISPLAY,
  CLAIM_REFERENCE,
  CLAIM_ADVANTAGE,
} from './nodes/nodeNames';
import paragraph from './nodes/paragraph';
import image from './nodes/image';
import embeddedObject from './nodes/embeddedObject';
import TableNodesSpecs from './nodes/table';
import heading from './nodes/heading';
import section from './nodes/section';
import textSelection from './marks/textSelection';
import hardBreak from './nodes/hardBreak';
import horizontalRule from './nodes/horizontalRule';
import codeBlock from './nodes/codeBlock';
import blockquote from './nodes/blockquote';
import numberedParagraph from './nodes/numberedParagraph';
import list from './nodes/list';
import listNumbering from './nodes/listNumbering';
import claim from './nodes/claim';
import claimPart from './nodes/claimPart';
import mathInline from './nodes/mathInline';
import mathDisplay from './nodes/mathDisplay';
import { claimReference } from './nodes/claimReference';
import claimAdvantage from './nodes/claimAdvantage';
import comment from './marks/comment';

// This is a monkey patch to fix the issue with the list spec
// :is(ul, ol) is not supported in JestDOM.

const badTag = list.parseDOM?.findIndex((pd) =>
  pd?.tag?.includes(':is(ul, ol)')
);
if (badTag !== undefined) {
  // @ts-ignore
  list.parseDOM?.splice(badTag, 1);
}
export const nodes: Record<string, NodeSpec> = {
  // :: NodeSpec The top level document node.
  doc: {
    content: 'section*',
  },
  [PARAGRAPH]: paragraph,
  [NUMBERED_PARAGRAPH]: numberedParagraph,
  [HEADING]: heading,
  [SECTION]: section,
  [BLOCKQUOTE]: blockquote,
  [HORIZONTAL_RULE]: horizontalRule,
  [CODE_BLOCK]: codeBlock,
  [IMAGE]: image,
  [EMBEDDED_OBJECT]: embeddedObject,
  [HARD_BREAK]: hardBreak,
  [LIST]: list,
  [CLAIM]: claim,
  [CLAIM_PART]: claimPart,
  [LIST_NUMBERING]: listNumbering,
  [MATH_INLINE]: mathInline,
  [MATH_DISPLAY]: mathDisplay,
  [CLAIM_REFERENCE]: claimReference,
  [CLAIM_ADVANTAGE]: claimAdvantage,
  text: {
    group: 'inline',
  },
};

export const marks: Record<string, MarkSpec> = {
  [MARK_LINK]: link,
  [MARK_CODE]: code,
  [MARK_EM]: em,
  [MARK_FONT_SIZE]: fontSize,
  [MARK_FONT_TYPE]: fontType,
  [MARK_STRIKE]: strikethrough,
  [MARK_STRONG]: strong,
  [MARK_SUPER]: superscript,
  [MARK_SUB]: subscript,
  [MARK_TEXT_COLOR]: textColor,
  [MARK_TEXT_HIGHLIGHT]: textHighlight,
  [MARK_TEXT_SELECTION]: textSelection,
  [MARK_UNDERLINE]: underline,
  [MARK_INSERTED]: inserted,
  [MARK_DELETED]: deleted,
  [MARK_COMMENT]: comment,
};

export const schema = new Schema({
  nodes: { ...nodes, ...TableNodesSpecs },
  marks,
});
