import React from 'react';
import styled from 'styled-components';
import { compileQueryPreview } from './QueryUtils';
import { T } from '@ipos/shared';
import useTranslation from 'translations';

interface Props {
  query: T.TsdQuery;
  style?: React.CSSProperties;
  label?: string;
}

const QueryPreview: React.FC<Props> = ({ query, style }) => {
  const t = useTranslation();
  const [classesString, synonymString] = compileQueryPreview(query);

  return (
    <Outer style={style}>
      <Row>{`${t('QUERY.CPC')} = ${classesString}`}</Row>
      <Row>{`AND ${t('QUERY.SYNONYM')} = ${synonymString}`}</Row>
    </Outer>
  );
};

export default QueryPreview;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  max-height: 165px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Row = styled.div`
  margin-bottom: 5px;
`;
