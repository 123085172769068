import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../translations';
import TranslationPickerTooltip from './TranslationPickerTooltip';

interface Props {
  style?: React.CSSProperties;
}

const TranslatedIcon: React.FC<Props> = ({ style }) => {
  const t = useTranslation();

  return (
    <TranslationPickerTooltip title={t('TRANSLATION_PICKER.TRANSLATED')}>
      <StyledIcon className="icn-checkmark" style={style} />
    </TranslationPickerTooltip>
  );
};

export default TranslatedIcon;

const StyledIcon = styled.i`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white100};
  background: ${(props) => props.theme.colors.secondary500};
`;
