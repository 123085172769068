import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { MemoryRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import IposTheme from '../theme';
import { T } from '../../';
import { Spinner } from '../components';
import { queryClient } from '../services/queryClient';
import { mockUserSessionQuery } from '../__mocks__/session';
import TestSessionProvider from './TestSessionProvider';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);

interface Props {
  userSessionQuery?: T.UserSessionQuery;
}

const TestProviderWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  userSessionQuery,
}) => (
  <Suspense fallback={<Spinner />}>
    <QueryClientProvider client={queryClient}>
      <MemoryRouter>
        <TestSessionProvider
          userSessionQuery={userSessionQuery || mockUserSessionQuery}
        >
          <ThemeProvider theme={IposTheme}>{children}</ThemeProvider>
        </TestSessionProvider>
      </MemoryRouter>
    </QueryClientProvider>
  </Suspense>
);

export default TestProviderWrapper;
