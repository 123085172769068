import React, { useContext } from 'react';
import { T } from '@ipos/shared';

interface Props {
  tsdElement: T.TsdElement;
  tsdNodeId?: string;
  tsdId?: string;
}

interface ContextProps extends Props {
  tsdElementId: string;
  isLibraryView: boolean;
}

const TsdElementContext = React.createContext<Props | undefined>(undefined);
export default TsdElementContext;

export function useTsdElementContext(): ContextProps {
  const context = useContext(TsdElementContext);
  if (!context) {
    throw new Error(
      `useTsdElementContext must be used within a TsdElementContext.Provider`
    );
  }
  return {
    ...context,
    tsdElementId: context.tsdElement.id,
    isLibraryView: context.tsdNodeId == null,
  };
}
