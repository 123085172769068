import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import useTranslation from '../../../translations';
import { QueryKey } from '../../../queries';
import { queryClient } from '../../../services/queryClient';
import { HeaderIcon } from '../common';
import { DocumentPickerItem, DownloadDocuments } from '../types';
import { useMultiDocContext } from '../MultiDocContext';
import { initBrowserDownload } from '../../../utils';
import { document } from '../../../api';
import { BlobFileDownload } from '../../../types';
import { DocumentPicker } from '../DocumentPicker';
import DownloadButton from './DownloadButton';

const downloadDocumentsSchema: yup.ObjectSchema<DownloadDocuments> = yup.object(
  {
    documentIds: yup.array().of(yup.string().required()).min(1).required(),
  }
);

const MultiDocDownload: React.FC = () => {
  const t = useTranslation();
  const { documents, setDownloadActive } = useMultiDocContext();
  const [isLoading, setLoading] = useState(false);

  const [documentItems, setDocumentItems] =
    useState<DocumentPickerItem[]>(documents);

  const methods = useForm({
    defaultValues: {
      documentIds: [],
    },
    resolver: yupResolver(downloadDocumentsSchema),
  });

  const handleSubmit = async (formValues: DownloadDocuments) => {
    setLoading(true);
    const { documentIds } = formValues;

    try {
      const response = await queryClient.fetchQuery<BlobFileDownload>(
        [QueryKey.DocumentsAsZip, documentIds],
        () => document.getDocumentsAsZip(documentIds)
      );
      const [blob, filename] = response;
      initBrowserDownload(filename, blob);
    } catch (error) {
      message.error(t('SOMETHING_WENT_WRONG'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(handleSubmit)}>
        <Inner>
          <DocumentPicker
            docItems={documentItems}
            onItemsChange={setDocumentItems}
          />
          <DownloadButton isLoading={isLoading} />
          <HeaderIcon
            className="icn-clear"
            onClick={() => setDownloadActive(false)}
          />
        </Inner>
      </StyledForm>
    </FormProvider>
  );
};

export default MultiDocDownload;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;
