import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { clearAuthData } from '../../auth/msalInstance';

interface Props {
  message?: string;
}

const ForbiddenNotification: React.FC<Props> = ({ message }) => {
  const t = useTranslation();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((currentCountdown) => currentCountdown - 1);
    }, 1000);

    const timer = setTimeout(() => {
      clearAuthData();
      window.location.reload();
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  return (
    <Outer>
      <Text>{message ?? t('FORBIDDEN_ACCESS', { seconds: countdown })}</Text>
    </Outer>
  );
};

export default ForbiddenNotification;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Text = styled.div`
  text-align: center;
  font-size: 30px;
  white-space: pre-line;
  color: ${(props) => props.theme.colors.white87};
`;
