import { DOMSerializer, Fragment, Node } from 'prosemirror-model';
import { Decoration } from 'prosemirror-view';
import { ChangeSet } from './changeset';

const widget =
  (html: DocumentFragment, attrs: { [key: string]: string }) => () => {
    const element = document.createElement('span');
    element.appendChild(html);
    Object.keys(attrs).forEach((key) => {
      element.setAttribute(key, attrs[key]);
    });
    return element;
  };

const hasTextContent = (node: Node) => {
  let hasText = false;

  node.content.descendants((childNode) => {
    if (hasText) {
      return;
    }

    if (childNode.isText && childNode.textContent.length > 0) {
      hasText = true;
    }
  });

  return hasText;
};

function doesFragmentHaveContent(fragment: Fragment) {
  for (let i = 0; i < fragment.childCount; i++) {
    if (fragment.child(i).isText && fragment.child(i).textContent.length > 0) {
      return true;
    }
    if (fragment.size > 0 && fragment.child(i).isAtom) {
      return true;
    }
    if (hasTextContent(fragment.child(i))) {
      return true;
    }
  }
  return false;
}

export function renderDecorations(
  changeSet: ChangeSet,
  domSerializer: DOMSerializer,
  disabled = false
) {
  const decorations: Decoration[] = [];

  if (disabled) {
    return decorations;
  }

  let allDeletionsLength = 0;
  let allInsertsLength = 0;
  const startState = changeSet.referenceDoc;
  changeSet.changes.forEach((change) => {
    let insertFrom = change.fromB;
    const { inserted, deleted } = change;
    inserted.forEach((span) => {
      // @ts-ignore
      const spanLength = span.length;
      decorations.push(
        Decoration.inline(insertFrom, change.toB, {
          class: 'track-changes-insert',
        })
      );
      insertFrom += spanLength;
      allInsertsLength += spanLength;
    });

    let deletionsLength = 0;
    deleted.forEach((span) => {
      const spanLength = span.length;
      const start = change.fromA + deletionsLength;
      const content = startState.slice(start, start + spanLength);

      const html = domSerializer.serializeFragment(
        content.content
      ) as DocumentFragment;

      // backend adds 'inserted' mark to the text that is inserted.
      // in order to prevent 'inserted' text seems like 'deleted' at the same time,
      // we'll just stop adding 'track-changes-delete' class if the node has 'inserted' mark.
      const elementsToRemove = html.querySelectorAll('.inserted');
      elementsToRemove.forEach((el) => el.parentNode?.removeChild(el));

      const attrs = {
        class: 'track-changes-delete',
      };

      if (!doesFragmentHaveContent(content.content)) {
        allDeletionsLength -= spanLength;
        deletionsLength += spanLength;
        return;
      }

      decorations.push(
        Decoration.widget(
          start + allDeletionsLength + allInsertsLength,
          widget(html, attrs),
          {
            side: 0,
          }
        )
      );

      allDeletionsLength -= spanLength;
      deletionsLength += spanLength;
    });
  });
  return decorations;
}
