import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { useTsdElementContext } from './TsdElementContext';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';

const { LabelValue } = Components;
const { Paragraph } = Typography;

const TsdElementDetails: React.FC = () => {
  const t = useTranslation();
  const { tsdElement } = useTsdElementContext();

  const description = tsdElement.description?.trim().length
    ? tsdElement.description
    : t('TSD_ELEMENT.NO_DESCRIPTION');

  return (
    <Outer>
      <LabelValue label={t('TSD_ELEMENT.DESCRIPTION')}>
        <Description>{description}</Description>
      </LabelValue>
    </Outer>
  );
};

export default TsdElementDetails;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Description = styled(Paragraph)`
  max-height: 200px;
  margin-top: 5px;
  max-width: 850px;
  overflow: auto;
  padding-right: 20px;
  white-space: pre-wrap;
  &.ant-typography {
    margin-bottom: 0;
  }
`;
