/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery as uiq } from 'react-query';
import { useMemo } from 'react';
import * as Types from '../types';
import { isDefined } from '../utils/typescript';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { HookProps, HookResult } from '../types';

function useInfiniteQuery<T, Q extends Record<string, any>>({
  queryParams = {} as Q,
  queryKey,
  apiFunction,
  pageSize,
}: HookProps<T, Q>): HookResult<T> {
  const PAGE_SIZE = pageSize ?? DEFAULT_PAGE_SIZE;

  const {
    isLoading,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: infiniteData,
  } = uiq({
    queryKey: [queryKey, ...Object.values(queryParams)],
    queryFn: async ({ pageParam = 0 }): Promise<Types.InfiniteQueryData<T>> => {
      const apiResponse = await apiFunction({
        skip: pageParam,
        pageSize: PAGE_SIZE + 1,
        ...queryParams,
      });
      const nextPageAvailable = apiResponse.length > PAGE_SIZE;
      if (
        queryParams.pageSize !== undefined &&
        typeof queryParams.pageSize === 'number'
      ) {
        return {
          data: apiResponse.slice(0, queryParams.pageSize),
          nextCursor: nextPageAvailable
            ? pageParam + queryParams.pageSize
            : undefined,
        };
      }
      return {
        data: apiResponse.slice(0, PAGE_SIZE),
        nextCursor: nextPageAvailable ? pageParam + PAGE_SIZE : undefined,
      };
    },
    getNextPageParam: (lastPage: Types.InfiniteQueryData<T>) =>
      lastPage.nextCursor,
  });

  const data = useMemo((): T[] => {
    if (!infiniteData) {
      return [];
    }
    return infiniteData.pages.reduce(
      (result: T[], page: Types.InfiniteQueryData<T>): T[] => [
        ...result,
        ...page.data,
      ],
      []
    );
  }, [infiniteData]);

  return {
    isLoading,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage: isDefined<boolean>(hasNextPage) ?? hasNextPage,
    infiniteData: isDefined<T[]>(data) ? data : [],
  };
}

export default useInfiniteQuery;
