import React from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Modal, message, Tag } from 'antd';
import SaveTsdElementForm from './SaveTsdElementForm';
import { Api, T, Hooks } from '@ipos/shared';
import { QueryKey, useTsdNode } from 'queries';
import useTranslation from 'translations';
import ROUTES, { generateUrl } from 'routes';

const { useInvalidateQueries } = Hooks;

interface Props {
  tsdId?: string;
  parentNodeId?: string;
  isOpen: boolean;
  onCloseRequest: () => void;
}

const CreateTsdElementModal: React.FC<Props> = ({
  tsdId,
  parentNodeId,
  isOpen,
  onCloseRequest,
}) => {
  const t = useTranslation();
  const history = useHistory();
  const { isInvalidating, invalidateQueries } = useInvalidateQueries();

  const tsdElementParent = useTsdNode(tsdId as string, parentNodeId as string, {
    enabled: !!tsdId && !!parentNodeId,
  });

  const updateParentNodeMutation = useMutation((newNode: T.CreateTsdNode) =>
    Api.tsd.createNode(newNode)
  );

  const gotoTsdNode = (tsdNodeId: string) => {
    history.push(
      generateUrl(ROUTES.TSD_NODE, {
        tsdId: tsdId as string,
        tsdNodeId,
      })
    );
  };

  const gotoTsdTsdElement = (tsdElementId: string) => {
    history.push(
      generateUrl(ROUTES.TSD_ELEMENT_ROOT, {
        tsdElementId,
      })
    );
  };

  const promptRedirectToElement = (tsdElementId: string) => {
    Modal.confirm({
      title: t('CREATE_ELEMENT.NOTIFICATION_TITLE'),
      content: t('CREATE_ELEMENT.NOTIFICATION_CONTENT'),
      okText: t('CREATE_ELEMENT.NOTIFICATION_OPEN'),
      cancelText: t('CREATE_ELEMENT.NOTIFICATION_STAY'),
      onOk() {
        gotoTsdTsdElement(tsdElementId);
      },
      centered: true,
    });
  };

  const updateParentNode = (
    tsdElementId: string,
    isNewElementCreated = false
  ) => {
    updateParentNodeMutation.mutate(
      {
        tsdId: tsdId as string,
        tsdElementId,
        parentTsdNodeId: parentNodeId,
      },
      {
        onSuccess: async (tsdNode: T.TsdNode) => {
          message.success(t('CREATE_ELEMENT.NODE_ADD_SUCCESS'));
          await invalidateQueries([QueryKey.TsdData, tsdId as string]);
          gotoTsdNode(tsdNode.id);
          if (isNewElementCreated) {
            promptRedirectToElement(tsdElementId);
          }
        },
      }
    );
  };

  const handleCreateSuccess = (tsdElementId: string) => {
    onCloseRequest();
    if (tsdId !== undefined) {
      updateParentNode(tsdElementId, true);
    } else {
      gotoTsdTsdElement(tsdElementId);
    }
  };

  const handleAddExistingElement = (tsdElementId: string) => {
    onCloseRequest();
    updateParentNode(tsdElementId);
  };

  return (
    <Modal
      title={t(`CREATE_ELEMENT.${tsdId ? 'MODAL_ADD' : 'MODAL_CREATE'}`)}
      style={{ top: 140 }}
      open={isOpen}
      onCancel={onCloseRequest}
      destroyOnClose
      width={600}
      footer={null}
    >
      {tsdElementParent.data && (
        <ParentElement>
          {t('CREATE_ELEMENT.PARENT')}
          <Tag style={{ fontSize: 14, marginLeft: 6 }}>
            {tsdElementParent.data.name}
          </Tag>
        </ParentElement>
      )}
      <SaveTsdElementForm
        isTsdContext={tsdId !== undefined}
        isParentSubmitting={
          updateParentNodeMutation.isLoading || isInvalidating
        }
        onCreateSuccess={handleCreateSuccess}
        onAddExistingElement={handleAddExistingElement}
        onCancel={onCloseRequest}
      />
    </Modal>
  );
};

export default CreateTsdElementModal;

const ParentElement = styled.div`
  margin: -10px 0 20px;
`;
