import React from 'react';
import styled from 'styled-components';

interface Props {
  placeholder?: React.ReactNode;
}

const TsdTreePlaceholder: React.FC<Props> = ({ placeholder }) => (
  <Outer>{placeholder}</Outer>
);

export default TsdTreePlaceholder;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
