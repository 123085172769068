import { Plugin, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

const moveCursorToPrevious = (view: EditorView) => {
  const $pos = view.state.selection.$from;
  const prevPos = $pos.doc.resolve(Math.max(0, $pos.before() - 1)); // Resolve to previous position
  const selection = TextSelection.near(prevPos); // Create a selection at the end of the previous paragraph
  view.dispatch(view.state.tr.setSelection(selection)); //
};

const preventEmptyParagraphRemovalPlugin = new Plugin({
  props: {
    handleKeyDown(view, event) {
      if (event.key === 'Backspace') {
        const { $from } = view.state.selection;
        if (
          $from.parent.isTextblock &&
          $from.parentOffset === 0 &&
          $from.parent.textContent.length === 0 &&
          $from.nodeBefore?.attrs['class']?.includes('track-changes-delete')
        ) {
          event.preventDefault();
          moveCursorToPrevious(view);
          return true;
        }
      }
      return false;
    },
  },
});

export default preventEmptyParagraphRemovalPlugin;
