import React from 'react';
import styled from 'styled-components';
import { useAadUserContext } from './UserContextProvider';

interface Props {
  showEmail?: boolean;
  style?: React.CSSProperties;
}

const UserDetails: React.FC<Props> = ({ showEmail = true, style }) => {
  const { displayName, mail } = useAadUserContext();

  return (
    <Outer style={style}>
      {displayName}
      {showEmail && <Mail>{mail ?? '-'}</Mail>}
    </Outer>
  );
};

export default UserDetails;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Mail = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.white60};
`;
