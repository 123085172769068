import React, { useState } from 'react';
import styled from 'styled-components';
import FlatButton from '../../Button/FlatButton';
import { DocumentMetadata, Language } from '../../../types';
import useTranslation from '../../../translations';
import { TranslationRangeSelectModal } from '../../Translations';

interface Props {
  document: DocumentMetadata;
  onRetranslate: (language: Language, pageNumbers?: string) => void;
}

interface RangeSelectModal {
  show: boolean;
  language: Language | null;
}

const formatTranslatedPages = (pages: number[]) => {
  const sortedPages = pages.map((index) => index + 1).sort((a, b) => a - b);

  const formattedPages: string[] = [];

  const findEndOfRange = (index: number): number => {
    const startPage = sortedPages[index];

    if (startPage + 1 === sortedPages[index + 1]) {
      return findEndOfRange(index + 1);
    }

    return index;
  };

  let index = 0;

  while (index < sortedPages.length) {
    const startPage = sortedPages[index];
    const endPageIndex = findEndOfRange(index);
    const endPage = sortedPages[endPageIndex];

    if (index === endPageIndex) {
      formattedPages.push(startPage.toString());
    } else {
      if (endPage === startPage + 1) {
        formattedPages.push(startPage.toString(), endPage.toString());
      } else {
        formattedPages.push(`${startPage}-${endPage}`);
      }
    }

    index = endPageIndex + 1;
  }

  return formattedPages;
};

const TranslationStateBar: React.FC<Props> = ({ document, onRetranslate }) => {
  const t = useTranslation();

  const authenticLanguage = document.parentDocument?.contentLanguage;

  const [rangeSelectModal, setRangeSelectModal] = useState<RangeSelectModal>({
    show: false,
    language: null,
  });

  const machineTranslatedWarning = t(
    'DOC_VIEWER.TRANSLATION_STATE_BAR.MACHINE_TRANSLATED_BY_DEEPL'
  );

  let translatedPagesLabel = '';

  if (document.translatedPageIndices) {
    translatedPagesLabel =
      document.numPages === document.translatedPageIndices?.length
        ? `${t('DOC_VIEWER.TRANSLATION_STATE_BAR.ALL_PAGES')}`
        : `${t(
            'DOC_VIEWER.TRANSLATION_STATE_BAR.PAGES'
          )}: ${formatTranslatedPages(document.translatedPageIndices)}`;
  }

  if (!document.parentDocument) {
    return null;
  }

  return (
    <>
      <Outer>
        <Warning>
          <i className="icn-info-outlined" />
          <span>
            {machineTranslatedWarning}: <strong>{translatedPagesLabel}</strong>
          </span>
        </Warning>
        <AddPagesButton
          onClick={(e) => {
            e.preventDefault();
            setRangeSelectModal({
              show: true,
              language: document.contentLanguage,
            });
          }}
        >
          <span>{t('DOC_VIEWER.TRANSLATION_STATE_BAR.ADD_PAGES')}</span>
        </AddPagesButton>
      </Outer>
      {authenticLanguage &&
        rangeSelectModal.show &&
        rangeSelectModal.language && (
          <TranslationRangeSelectModal
            authenticLanguage={authenticLanguage}
            targetLanguage={rangeSelectModal.language}
            onTranslate={(pageNumbers?: string) => {
              if (!rangeSelectModal.language) {
                return;
              }

              onRetranslate(rangeSelectModal.language, pageNumbers);
              setRangeSelectModal({ show: false, language: null });
            }}
            onClose={() => setRangeSelectModal({ show: false, language: null })}
          />
        )}
    </>
  );
};

export default TranslationStateBar;

const Outer = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 20px 4px 26px;
  color: black;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-shrink: 0;
  background: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.black20};
`;

const Warning = styled.div`
  color: rgba(0, 0, 0, 0.7);
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddPagesButton = styled(FlatButton)`
  color: black;
  display: flex;
  align-items: center;
  gap: 4px;
`;
