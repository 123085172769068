import React, { useState } from 'react';
import { Button } from 'antd';
import { Permissions, Components } from '@ipos/shared';
import { CreateTsdModal } from 'components/Tsd/index';
import useTranslation from 'translations';

const { Icon } = Components;
const { WithPermission } = Permissions;

const CreateTsdButton: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleClick = () => setModalOpen(true);
  const handleCloseModalRequest = () => setModalOpen(false);

  return (
    <WithPermission permissions={AzureGroupsConfig.PermissionCompanyTsdEdit}>
      <Button
        type="primary"
        onClick={handleClick}
        icon={
          <Icon
            className="icn-add"
            style={{ verticalAlign: 'text-bottom', marginRight: 5 }}
          />
        }
      >
        {t('CREATE_TSD.OPTION')}
      </Button>
      {isModalOpen && (
        <CreateTsdModal
          isOpen={isModalOpen}
          onCloseRequest={handleCloseModalRequest}
        />
      )}
    </WithPermission>
  );
};

export default CreateTsdButton;
