import styled from 'styled-components';
export * from './AppHeader';
export * from './Grid';
export * from './Navigation';
export { default as Surface } from './Surface';
export { default as SideDrawer } from './SideDrawer';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
`;
