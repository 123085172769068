import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Space } from 'antd';
import TestQueryStringButton from './TestQueryStringButton';
import { Components } from '@ipos/shared';
import useTranslation from 'translations';

const { TextArea } = Input;
const { CopyToClipboard } = Components;

interface Props {
  label: string;
  disabled: boolean;
  queryText?: string;
  queryPrefix?: string;
  baseQueryText?: string;
  showTestButton?: boolean;
  rows?: number;
  onChange?: (v: string) => void;
}

const QueryString: React.FC<Props> = ({
  label,
  disabled,
  queryText = '',
  queryPrefix = '',
  baseQueryText = '',
  showTestButton = true,
  rows = 4,
  onChange,
}) => {
  const t = useTranslation();
  const [queryString, setQueryString] = useState<string>(queryText);
  useEffect(() => setQueryString(queryText), [queryText]);

  const handleBlur = () => {
    if (!disabled && onChange) {
      onChange(queryString);
    }
  };

  return (
    <Outer>
      <Actions>
        <span>{label}:</span>
        <Space>
          <CopyToClipboard text={queryText} />
          {showTestButton && (
            <TestQueryStringButton queryString={`${queryPrefix}${queryText}`} />
          )}
        </Space>
      </Actions>
      <TextArea
        disabled={disabled}
        rows={rows}
        value={queryString}
        onBlur={handleBlur}
        onChange={(e) => setQueryString(e.target.value)}
      />
      {baseQueryText && (
        <BaseQueryHolder>
          <Space>
            <BaseQuery>
              {t('QUERY.LIBRARY_QUERY')}: {baseQueryText}
            </BaseQuery>
          </Space>
        </BaseQueryHolder>
      )}
    </Outer>
  );
};

export default QueryString;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
  align-items: flex-end;
`;

const BaseQueryHolder = styled.div`
  margin-top: 5px;
`;

const BaseQuery = styled.div`
  opacity: 0.9;
  font-size: 0.9em;
`;
