import React from 'react';
import styled from 'styled-components';
import { SessionMenu } from '../../Session';
import { useApplicationContext } from '../../../contexts/ApplicationProvider';
import { TranslationSettings } from '../../Translations/TranslationSettings';
import { BugReport } from '../../BugReport';
import TutorialsMenu from './TutorialsMenu';
import AppsMenu from './AppsMenu';
import AppTitle from './AppTitle';
import Logo from './Logo';
import AppVersion from './AppVersion';

interface Props {
  children?: React.ReactNode;
}

const Navigation: React.FC<Props> = ({ children }) => {
  const { appContext } = useApplicationContext();

  return (
    <Outer>
      <Header>
        <Logo appContext={appContext} />
      </Header>
      <AppTitle appContext={appContext} />
      <Options>{children}</Options>
      <Settings>
        <TutorialsMenu />
        <BugReport />
        <AppsMenu />
        <TranslationSettings />
        <SessionMenu />
      </Settings>
      <AppVersion />
    </Outer>
  );
};

export default Navigation;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #1c1e28;
  flex: 0 0 90px;
  max-width: 90px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 30px 0;
`;

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
