import React from 'react';
import styled, { useTheme } from 'styled-components';
import { T } from '../../..';
import useTranslation from '../../translations';
import Notification from '../Notification';
import { BlankNotes } from '../../icons';
import NotesItem from './NotesItem';
import NotesListContainer from './NotesListContainer';

interface Props {
  notesQuery: T.HookResult<T.UserNote>;
  notes: T.UserNote[];
  userId: string;
  onUpdateNote: (
    noteId: string,
    value: string,
    isPublic: boolean
  ) => Promise<void>;
  onDeleteNote: (noteId: string) => void;
}

const NotesList: React.FC<Props> = ({
  notesQuery,
  onUpdateNote,
  onDeleteNote,
  notes,
  userId,
}) => {
  const t = useTranslation();
  const { colors } = useTheme();

  let content = null;
  switch (true) {
    case notesQuery.isLoading:
      content = <Notification spin spinSize="large" />;
      break;
    case notesQuery.isError:
      content = <Notification error />;
      break;
    case !notes.length:
      content = (
        <NotificationWrapper>
          <BlankNotes />
          <Notification
            text={t('NOTES.EMPTY_LIST')}
            style={{ fontSize: '13px', color: colors.white40 }}
          />
        </NotificationWrapper>
      );
      break;
    default:
      content = (
        <ItemsContainer>
          {notes.map((n) => (
            <NotesItem
              key={n.id}
              note={n}
              isOwnedByUser={n.createdById === userId}
              onUpdateNote={onUpdateNote}
              onDeleteNote={onDeleteNote}
            />
          ))}
        </ItemsContainer>
      );
  }

  return (
    <NotesListContainer notesQuery={notesQuery}>{content}</NotesListContainer>
  );
};

export default NotesList;

const ItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;
