import React, { FC } from 'react';
import {
  PagingControls,
  StyledInput,
  TotalNumberOfPages,
  ToolbarIcon,
} from './styled';

interface PaginationToolProps {
  onNextPage: () => void;
  onPageNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentPageNumber: string;
  totalNumberOfPages: number;
  onPreviousPage: () => void;
  showPagesNavigations: boolean;
}

const PaginationTool: FC<PaginationToolProps> = ({
  onNextPage,
  onPageNumberChange,
  showPagesNavigations,
  currentPageNumber,
  totalNumberOfPages,
  onPreviousPage,
}) => (
  <>
    <PagingControls>
      {showPagesNavigations && (
        <ToolbarIcon className="icn-chevron-left" onClick={onPreviousPage} />
      )}
      <StyledInput
        value={currentPageNumber}
        pageCount={totalNumberOfPages}
        onChange={onPageNumberChange}
      />
      /<TotalNumberOfPages>{totalNumberOfPages}</TotalNumberOfPages>
      {showPagesNavigations && (
        <ToolbarIcon className="icn-chevron-right" onClick={onNextPage} />
      )}
    </PagingControls>
  </>
);

export default PaginationTool;
