import styled from 'styled-components';
export { default as AppHeader } from './AppHeader';
export * from './HeaderItems';
export * from './HeaderButtons';

export const AppHeaderCenter = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
