/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { Rect } from '../../util/htmlElementToRect';
import PopUpManager from './PopUpManager';
import { atAnchorBottomLeft, atViewportCenter } from './PopUpPosition';

import type { PopUpDetails } from './PopUpManager';

type PositionHandler = (anchorRect: Rect | null, bodyRect: Rect | null) => Rect;

export type ViewProps = any;

export type PopUpParams = {
  anchor?: any;
  autoDismiss?: boolean | null;
  container?: Element | null;
  modal?: boolean | null;
  onClose?: (val: any) => void | null;
  position?: PositionHandler | null;
};

export type PopUpProps = {
  View: any;
  close: (val: any) => void;
  popUpParams: PopUpParams;
  viewProps: any;
};

export type PopUpHandle = {
  close: (val: any) => void;
  update: (props: any) => void;
};

const PopUp: React.FC<PopUpProps> = ({
  View,
  viewProps,
  close,
  popUpParams,
}) => {
  const dummy = {};
  const bridgeRef = useRef<{ getDetails: () => PopUpDetails } | null>(null);
  const idRef = useRef(uuid());
  useEffect(() => {
    bridgeRef.current = { getDetails: _getDetails };
    PopUpManager.register(bridgeRef.current);
    return () => {
      bridgeRef?.current && PopUpManager.unregister(bridgeRef.current);
    };
  }, []);

  const _getDetails = (): PopUpDetails => {
    const { anchor, autoDismiss, position, modal } = popUpParams;
    return {
      anchor,
      autoDismiss: autoDismiss !== false,
      body: document.getElementById(idRef.current),
      close,
      modal: modal === true,
      position: position || (modal ? atViewportCenter : atAnchorBottomLeft),
    };
  };

  return (
    <div data-pop-up-id={idRef.current} id={idRef.current}>
      <View {...(viewProps || dummy)} close={close} />
    </div>
  );
};

export default PopUp;
