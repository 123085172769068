import styled, { css } from 'styled-components';

export const TOOLBAR_PADDING_LEFT = 12;
export const TOOLBAR_PADDING_RIGHT = 4;

export const Outer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.primary700};
  color: ${(props) => props.theme.colors.white60};
  padding: 6px ${TOOLBAR_PADDING_RIGHT}px 6px ${TOOLBAR_PADDING_LEFT}px;
  width: 100%;
  flex-grow: 0;
  position: relative;
  flex-shrink: 0;
  min-height: 0;
  font-size: 12px;
`;

export const ToggleIcon = styled.i<{ active?: boolean; disabled?: boolean }>`
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  padding: 7px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary200_20};
    color: ${(props) => props.theme.colors.white60};
  }
  ${(props) =>
    props.active &&
    css`
      background: white;
      color: ${() => props.theme.colors.primary100};

      &:hover {
        background: ${() => props.theme.colors.white60};
        color: ${() => props.theme.colors.primary100};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
    `}
`;

export const DropdownAnchor = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 10px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary300};
  }
  border-radius: 6px;
`;

export const DocumentEntry = styled.div`
  cursor: pointer;
  min-width: 70px;
  max-width: 150px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;

export const ExternalLink = styled.i`
  font-size: 20px;
`;

export const StyledInput = styled.input<{ pageCount?: number }>`
  width: 30px;
  ${({ pageCount }) => pageCount && pageCount >= 10 && 'width: 36px;'}
  ${({ pageCount }) => pageCount && pageCount >= 100 && 'width: 43px;'}
  height: 20px;
  max-height: 20px;
  margin: 0 5px 0 3px;
  padding: 5px 10px;
  border-radius: 2px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.white87};
  background: transparent;
  border: solid 1px ${(props) => props.theme.colors.white20};
`;

export const TotalNumberOfPages = styled.div`
  display: flex;
  margin: 0 3px 0 6px;
  font-size: 12px;
`;

export const Toolbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PagingControls = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDivider = styled.div`
  height: 20px;
  margin: 0 2px;
  width: 1px;
  background: ${(props) => props.theme.colors.white05};
`;

export const ToolbarIcon = styled.i`
  font-size: 16px;
  cursor: pointer;
`;
