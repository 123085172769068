import React, { useMemo } from 'react';
import { Pie, PieConfig } from '@ant-design/charts';
import { useTheme } from 'styled-components';
import { ChartConfig } from '../../types/visualizations';

interface Props {
  config: ChartConfig;
}

const PieChart: React.FC<Props> = (props) => {
  const { data } = props.config;

  // TODO: into a custom hook
  // const colorPalette = useColorPalette();

  const theme = useTheme();
  const colorPalette = useMemo(
    () => [
      theme.colors.visualization.aqua300,
      theme.colors.visualization.green300,
      theme.colors.visualization.plum300,
      theme.colors.visualization.red300,
      theme.colors.visualization.gold300,
    ],
    [theme]
  );

  const pieConfig: PieConfig = {
    autoFit: true,
    appendPadding: 10,
    data,
    angleField: 'y',
    colorField: 'name',
    color: colorPalette,
    radius: 1,
    innerRadius: 0.7,
    label: false,
    interactions: [{ type: 'tooltip' }],
    pieStyle: {
      fillOpacity: 1,
      stroke: '#6298C7',
      lineWidth: 0.5,
      lineDash: [],
      strokeOpacity: 0.2,
      cursor: 'pointer',
    },
    legend: false,
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          userSelect: 'none',
          textOverflow: 'ellipsis',
          fontSize: '54px',
          color: '#d1d4dd',
          textShadow: '4px 4px black',
          fontWeight: 'bold',
        },
        formatter: () => data.reduce((prev, c) => prev + c.y, 0).toString(),
      },
    },
  };
  return <Pie {...pieConfig} />;
};
export default PieChart;
