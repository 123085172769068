import React from 'react';
import styled from 'styled-components';

interface Props {
  style?: React.CSSProperties;
}

const HeaderItems: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
}) => <Outer style={style}>{children}</Outer>;

export default HeaderItems;

const Outer = styled.div`
  display: flex;
  height: 100%;
`;
