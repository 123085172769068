import { useMemo, useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import { useSearchDocument, QueryKey } from '../../../../queries';
import { DocumentLocation } from '../../types';
import { DocumentMetadata } from '../../../../types';
import { queryClient } from '../../../../services/queryClient';
import useTranslation from '../../../../translations';
import { useDebounce } from '../../../../hooks';

interface HookShape {
  (props: { document: DocumentMetadata; searchTerm?: string }): {
    searchText: string;
    isSearchActive: boolean;
    isSearching: boolean;
    searchMatches: DocumentLocation[][];
    activeMatchIndex: number | null;
    hasOcrLayer: boolean;
    toggleSearch: () => void;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setActiveMatchIndex: (index: number) => void;
  };
}

const useSingleDocumentSearch: HookShape = ({ document, searchTerm }) => {
  const t = useTranslation();
  const { id: documentId, selectableTextUris } = document;
  const shouldOpenSearch = useRef(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activeMatchIndex, setActiveMatchIndex] = useState<number | null>(null);

  const debouncedSearchText = useDebounce(searchText, 400);

  const { isLoading, data } = useSearchDocument(
    documentId,
    debouncedSearchText,
    true,
    {
      enabled: isSearchActive && debouncedSearchText.trim() !== '',
      onError() {
        message.error(t('SINGLE_DOC_SEARCH.ERROR'));
      },
    }
  );

  const searchResult = data?.[0];
  const hasOcrLayer = searchResult?.hasOcr || !!selectableTextUris?.length;

  const searchMatches = useMemo<DocumentLocation[][]>(() => {
    if (!searchResult || !isSearchActive) {
      return [];
    }
    return searchResult.matches;
  }, [searchResult, isSearchActive]);

  useEffect(() => {
    if (isSearchActive) {
      return;
    }
    setSearchText('');
    setActiveMatchIndex(null);
    queryClient.resetQueries(QueryKey.SearchDocument);
  }, [isSearchActive]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchActive(false);
      return;
    }
    setSearchText(searchTerm);
    shouldOpenSearch.current = true;
  }, [searchTerm, documentId]);

  useEffect(() => {
    if (shouldOpenSearch.current) {
      setSearchActive(true);
      shouldOpenSearch.current = false;
    }
  }, [searchText]);

  useEffect(() => {
    setActiveMatchIndex(searchMatches.length ? 0 : null);
  }, [searchMatches]);

  const toggleSearch = () => setSearchActive((prev) => !prev);

  return {
    searchText,
    isSearchActive,
    isSearching: isLoading,
    searchMatches,
    activeMatchIndex,
    hasOcrLayer,
    toggleSearch,
    setSearchText,
    setActiveMatchIndex,
  };
};

export default useSingleDocumentSearch;
