import { useEffect, useRef, useState } from 'react';
import {
  Language,
  DocumentMetadata,
  DocTranslations,
  TranslationStateErrorCode,
} from '../../../types';
import * as Api from '../../../api';
import { queryClient } from '../../../services/queryClient';
import { QueryKey } from '../../../queries';

const FETCH_TRANSLATION_PROCESS_INTERVAL = 2000;

interface HookProps {
  document: DocumentMetadata;
  onDocumentChange?: (d: DocumentMetadata) => void;
}

type TranslationHook = (props: HookProps) => {
  startTranslate: (language: Language, pageNumbers?: string) => void;
  onLanguageChange: (
    language: Language,
    pageNumbers?: string,
    isRetranslate?: boolean
  ) => void;
  translationProgressPercentage: number;
  isTranslating: boolean;
  isTranslationCompleted: boolean;
  translationErrorCode: TranslationStateErrorCode | null;
};

const useDocumentTranslation: TranslationHook = ({
  document,
  onDocumentChange = () => null,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setForceUpdate] = useState(Date.now);

  // it might seems like an anti-pattern
  // but I need to trigger re-rendering only in specific times
  // so I'm using this function to trigger re-rendering
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const reRender = () => {
    setForceUpdate(Date.now());
  };

  const isTranslating = useRef(false);
  const isTranslationCompleted = useRef(false);
  const translationLanguage = useRef<Language | null>(null);
  const translationProcessPercentage = useRef(0);
  const translations = useRef<DocTranslations>({});
  const translationErrorCode = useRef<TranslationStateErrorCode | null>(null);

  const parentDocument = document.parentDocument || document;

  useEffect(() => {
    loadTranslations();
  }, [document]);

  const loadTranslations = async () => {
    try {
      const response = await Api.document.getTranslations(parentDocument.id);
      translations.current = response;
    } catch (error) {
      //
    }
  };

  const onTranslationCompleted = async () => {
    if (!translationLanguage.current) {
      return;
    }

    await loadTranslations();
    const translation = translations.current[translationLanguage.current];

    isTranslating.current = false;
    isTranslationCompleted.current = true;
    queryClient.invalidateQueries([QueryKey.DocTranslations]);

    reRender();

    if (!translation) {
      return;
    }

    onDocumentChange(translation);
  };

  const fetchTranslationProcess = async () => {
    if (!translationLanguage.current) {
      return;
    }

    try {
      const translationProcess = await Api.document.getTranslationProcess(
        parentDocument.id,
        translationLanguage.current
      );

      if (
        !translationProcess.stages ||
        translationProcess.stages.length === 0
      ) {
        setTimeout(() => {
          fetchTranslationProcess();
        }, FETCH_TRANSLATION_PROCESS_INTERVAL);
        return;
      }

      const stage = translationProcess.stages[0];

      if (stage.errorCode) {
        isTranslating.current = false;
        isTranslationCompleted.current = true;
        translationErrorCode.current = stage.errorCode;
        reRender();
        return;
      }

      translationProcessPercentage.current = stage.progressPercentage;
      reRender();

      if (stage.progressPercentage >= 100) {
        setTimeout(() => {
          onTranslationCompleted();
        }, 4000);
        return;
      }

      setTimeout(() => {
        fetchTranslationProcess();
      }, FETCH_TRANSLATION_PROCESS_INTERVAL);
    } catch (error) {
      //
    }
  };

  const startTranslate = async (language: Language, pageNumbers?: string) => {
    try {
      translationErrorCode.current = null;
      translationLanguage.current = language;
      isTranslationCompleted.current = false;
      isTranslating.current = true;
      translationProcessPercentage.current = 0;

      reRender();

      await Api.document.startTranslationByPage(
        parentDocument.id,
        language,
        pageNumbers
      );

      fetchTranslationProcess();
    } catch (error) {
      //
    }
  };

  const onLanguageChange = async (
    language: Language,
    pageNumbers?: string,
    isRetranslate = false
  ) => {
    const translation = translations.current[language];

    if (translation && !isRetranslate) {
      onDocumentChange(translation);
      return;
    }

    startTranslate(language, pageNumbers);
  };

  return {
    startTranslate,
    onLanguageChange,
    translationErrorCode: translationErrorCode.current,
    translationProgressPercentage: translationProcessPercentage.current,
    isTranslating: isTranslating.current,
    isTranslationCompleted: isTranslationCompleted.current,
  };
};

export default useDocumentTranslation;
