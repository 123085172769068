import { useEffect, useState } from 'react';

const useFeatureToggle = (defaultValue = false, customKey = 'F5'): boolean => {
  const [enabled, setEnabled] = useState(defaultValue);

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.altKey && e.key === customKey) {
        setEnabled((prev) => !prev);
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  return enabled;
};

export default useFeatureToggle;
