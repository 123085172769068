import { NodeSpec } from 'prosemirror-model';

const claimAdvantage: NodeSpec = {
  content: 'block*',
  group: 'block',
  attrs: {
    expanded: { default: true },
    claimNumber: { default: null },
  },
  toDOM() {
    const baseAttrs = {
      class: 'claim-advantage',
    };

    const contentStructure = ['div', { class: 'content' }, 0];

    return ['div', baseAttrs, contentStructure];
  },
};

export default claimAdvantage;
