import React from 'react';
import styled from 'styled-components';
import { Components } from '@ipos/shared';
import {
  TsdsCount,
  TsdElementImageCoverage,
  TsdElementCompanyCoverage,
  TsdElementCpcCoverage,
  TsdElementQueryCoverage,
  TsdElementsCount,
  TsdEditorsCount,
} from 'components/Visualizations';
import { TsdList, ExportAllTsdsHeaderItem } from 'components/Tsd';
import useTranslation from 'translations';

const { Grid, GridItem, AppHeader } = Components.Layout;

const Home: React.FC = () => {
  const t = useTranslation();

  return (
    <>
      <AppHeader
        title={t('HEADER.TITLE.TSD_LIBRARY')}
        justifyContent="flex-end"
      >
        <ExportAllTsdsHeaderItem />
      </AppHeader>
      <Grid>
        <GridItem position={[1, 8, 1, 6]}>
          <TsdList />
        </GridItem>
        <GridItem position={[2, 1, 8, 4]}>
          <StatsCount>
            <TsdElementsCount />
            <TsdsCount />
            <TsdEditorsCount />
          </StatsCount>
        </GridItem>
        <GridItem position={[3, 5, 8, 4]}>
          <StatsCoverage>
            <TsdElementCpcCoverage />
            <TsdElementCompanyCoverage />
            <TsdElementQueryCoverage />
            <TsdElementImageCoverage />
          </StatsCoverage>
        </GridItem>
      </Grid>
    </>
  );
};

export default Home;

const StatsCount = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StatsCoverage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
