import { ContentBlock, ContentState, EditorState } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { Image } from '../../Uploads';

const MediaBlock: React.FC<{
  contentState: ContentState;
  block: ContentBlock;
  blockProps: {
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  };
}> = ({ blockProps, contentState, block }) => {
  const entityKey = block.getEntityAt(0);
  const entity = contentState.getEntity(entityKey);
  const type = entity.getType();
  const { editorState, setEditorState } = blockProps;

  const handleBlockClick = () => {
    const selection = editorState.getSelection().merge({
      anchorKey: block.getKey(),
      anchorOffset: block.getLength(),
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });
    setEditorState(EditorState.forceSelection(editorState, selection));
  };

  let content = null;

  if (type === 'image') {
    const { uploadId } = entity.getData();
    content = <Image uploadId={uploadId} />;
  }

  if (!content) {
    return null;
  }

  return (
    <Block>
      <Inner tabIndex={0} onClick={handleBlockClick}>
        {content}
      </Inner>
    </Block>
  );
};

export default MediaBlock;

export const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Inner = styled.div`
  display: inline-block;
  width: 50%;
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:focus {
    box-shadow: 0 0 10px 3px ${(props) => props.theme.colors.blue100_30};
  }
`;
