import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ModalState, SCREEN } from '../../../types';
import useTranslation from '../../../translations';
import { Icon } from '../../Icon';

interface Props {
  modalState: ModalState;
  onBackToPrevious: () => void;
}
interface CommonFlexContentProps {
  onClick: () => void;
  prevTitle: string;
  currentTitle: string;
}

const CommonFlexContent: React.FC<CommonFlexContentProps> = ({
  prevTitle,
  currentTitle,
  onClick,
}) => {
  const t = useTranslation();
  return (
    <Flex>
      <span onClick={onClick}>{t(prevTitle)}</span>
      <Icon className="icn-chevron-right" size={24} />
      <span>{t(currentTitle)}</span>
    </Flex>
  );
};

const ModalTitle: React.FC<Props> = ({ modalState, onBackToPrevious }) => {
  const t = useTranslation();

  return useMemo(() => {
    switch (modalState.currentScreen) {
      case SCREEN.ADD_INVENTOR_CONTACT:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_INVENTOR"
            currentTitle="STAKEHOLDERS.NEW_INVENTOR"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.ADD_APPLICANT_CONTACT:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_APPLICANT"
            currentTitle="STAKEHOLDERS.NEW_APPLICANT"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.EDIT_INVENTOR_CONTACT:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_INVENTOR"
            currentTitle="STAKEHOLDERS.EDIT_INVENTOR"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.EDIT_APPLICANT:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_APPLICANT"
            currentTitle="STAKEHOLDERS.EDIT_APPLICANT"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.EDIT_IN_HOUSE_COUNSEL:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_IN_HOUSE_COUNSEL"
            currentTitle="STAKEHOLDERS.EDIT_INHOUSE_COUNSEL"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.EDIT_EXTERNAL_COUNSEL:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_EXTERNAL_COUNSEL"
            currentTitle="STAKEHOLDERS.EDIT_EXTERNAL_COUNSEL"
            onClick={onBackToPrevious}
          />
        );

      case SCREEN.EDIT_STAKEHOLDER:
        return (
          <CommonFlexContent
            prevTitle="STAKEHOLDERS.ADD_ATTORNEY"
            currentTitle="STAKEHOLDERS.EDIT_CONTACT"
            onClick={onBackToPrevious}
          />
        );
      case SCREEN.REMOVE_STAKEHOLDER:
      case SCREEN.ADD_INVENTOR:
      case SCREEN.ADD_APPLICANT:
      case SCREEN.ADD_IN_HOUSE_COUNSEL:
      case SCREEN.ADD_EXTERNAL_COUNSEL:
      case SCREEN.ADD_EXAMINER:
        return t(`STAKEHOLDERS.${modalState.currentScreen}`);
      case SCREEN.ADD_STAKEHOLDER:
        return t('STAKEHOLDERS.ADD_ATTORNEY');
      case SCREEN.VIEW_STAKEHOLDERS:
      default:
        return t('STAKEHOLDERS.STAKEHOLDERS');
    }
  }, [modalState.currentScreen]);
};

const Flex = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: ${(props) => props.theme.colors.white50};
    font-size: 20px;
    cursor: pointer;
    font-weight: 400;
    &:last-child {
      font-weight: 700;
      color: ${(props) => props.theme.colors.white87};
    }
  }
`;

export default ModalTitle;
