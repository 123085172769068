import React, { useMemo } from 'react';
import styled from 'styled-components';
import CreateTsdButton from '../CreateTsdButton';
import { DateRenderer, NameRenderer, StatusRenderer } from './Renderers';
import { ContentLanguage } from 'components/common';
import { Api, T, Components } from '@ipos/shared';
import ROUTES, { generateUrl } from 'routes';
import useTranslation from 'translations';
import { QueryKey } from 'queries';
import { useAppContext } from 'contexts';

const { List } = Components;

const TsdList: React.FC = () => {
  const t = useTranslation();
  const { preferredLanguage } = useAppContext();

  const columns: T.Column<T.Tsd>[] = useMemo(
    () => [
      {
        key: 'name',
        title: t('TSD_LIST.NAME'),
        style: { flex: 1 },
        renderer: (_, item) => <NameRenderer item={item} />,
      },
      {
        key: 'nodeCount',
        title: t('TSD_LIST.NODES'),
        style: { flex: '0 0 170px' },
        renderer: (_, item) => <StatusRenderer item={item} />,
      },
      {
        key: 'createdOn',
        title: t('TSD_LIST.CREATED_ON'),
        style: { flex: '0 0 140px' },
        renderer: (value: string) => <DateRenderer date={value} />,
      },
    ],
    []
  );

  const dataSource: T.DataSource<T.Tsd, T.TsdQueryParams> = useMemo(
    () => ({
      queryKey: QueryKey.TsdSearch,
      apiFunction: Api.tsd.searchTsds,
      additionalQueryParams: () => ({
        language: preferredLanguage,
        includeNeverPublished: true,
      }),
    }),
    [preferredLanguage]
  );

  return (
    <List
      linkFn={(item) =>
        generateUrl(ROUTES.TSD_NODE, {
          tsdId: item.id,
          tsdNodeId: item.rootNode.id,
        })
      }
      title={
        <ListTitle>
          {t('TSD_LIST.TITLE')}
          <ContentLanguage />
        </ListTitle>
      }
      headerChildren={<CreateTsdButton />}
      dataSource={dataSource}
      columns={columns}
      searchInSeparateRow
    />
  );
};

export default TsdList;

const ListTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
`;
